import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class InputFile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filename: props.t('input-file.none.selected'),
    }
  }

  handleChange = (event) => {
    const { onChange, t, setErrors, accept = ['.jpg', '.jpeg', '.png'] } = this.props
    const { files } = event.target

    const newFile = files[files.length - 1]
    if (newFile && !accept.includes(`.${newFile.name.split('.').pop().toLowerCase()}`)) return

    let filename = t('input-file.none.selected')
    if (files && files.length) {
      if (files.length > 1) {
        filename = `${files.length} ${t('input-file.selected.files')}`
      } else {
        filename = files[0].name
      }
    }

    if (Array.from(files).every((e) => e.size < 10000000)) {
      this.setState({ filename })
      onChange(event)
    } else {
      setErrors('upload.documents.max.size.supported')
    }
  }

  render() {
    const {
      id,
      name,
      t,
      className,
      sideText,
      disabled,
      inputRef,
      accept = [],
      multiple,
    } = this.props

    const { filename } = this.state

    const cls = classNames('input-file', className)

    const btnCls = classNames('upload-label', { disabled })

    return (
      <div className={cls}>
        <div>
          <input
            className='upload-input'
            id={id || name}
            name={name}
            type='file'
            multiple={multiple}
            onChange={this.handleChange}
            ref={inputRef}
            disabled={disabled}
            accept={accept.join(',')}
          />

          <label htmlFor={id || name} className={btnCls}>
            {t('input-file.select-file')}
          </label>
        </div>

        <span className='filename'>{sideText || filename}</span>
      </div>
    )
  }
}

InputFile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  sideText: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  disabled: PropTypes.bool,
  setErrors: PropTypes.func.isRequired,
  accept: PropTypes.array,
  multiple: PropTypes.bool,
}

export default InputFile
