import API from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import store from '../../../redux/store'
import i18next from 'i18next'
import { Filters, Alert } from '../models'

export default class Indicator {
  indicator = ''

  loading = false

  filters: Filters

  alert: Alert

  constructor(alert: Alert, filters: Filters) {
    this.alert = alert
    this.filters = filters

    this.fetchData()
  }

  get id() {
    return this.alert.id
  }

  get title() {
    return i18next.t(this.alert.title)
  }

  get subtitle() {
    return i18next.t(this.alert.subtitle)
  }

  get parsedFilter() {
    const newFilter = {
      ...this.alert.filters,
    }

    if (!this.filters.allStores) {
      newFilter.lsBranch = this.filters.codLocal
      newFilter.lsPlntCd = this.filters.codLocal.map((store: any) => store.value)
    }

    return newFilter
  }

  async fetchData() {
    this.loading = true

    const branches = this.getBranchs()

    if (branches.length) {
      const payload = {
        type: this.alert.type,
        codLocal: this.filters.allStores ? '' : branches,
      }

      const { data } = await new API(null, store.dispatch).get(API_URL.COCKPIT_INDICATOR, payload)

      if (data) this.indicator = data.indicatorValue
    }

    this.loading = false
  }

  getBranchs() {
    const { codLocal } = this.filters

    if (codLocal) {
      return codLocal.map((codLocalRow: any) => {
        if (codLocalRow) {
          return codLocalRow.value
        }
      })
    }

    return ['99999']
  }
}
