import i18next from 'i18next'
import moment from 'moment-timezone'
import MozaicButton from '../../../../../../components/MozaicButton'
import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import { useDistributeContext } from '../../context'
import { _shifts as shifts } from '../../types'

type FooterButtonsType = {
  selectedDate: any
  selectedPeriod: any
  reason: any
  provider: any
  distributeMode: string
  handleClose: Function
  handleSubmit: Function
}

function FooterButtons(props: FooterButtonsType) {
  const {
    selectedDate,
    selectedPeriod,
    handleClose,
    handleSubmit,
    distributeMode,
    reason,
    provider,
  } = props
  const { allowedDates } = useDistributeContext()
  const { serviceOrder }: any = useServiceOrderContext()

  const cancelLabel = i18next.t('absncPerd.cancel')
  const submitLabel = i18next.t('kpi.distribute.title')

  const shiftCd = shifts[serviceOrder?.idSlotApplication as keyof typeof shifts]

  const isFormValid = () => {
    if (!selectedDate) return false

    const dateIsValid = selectedDate
      ? allowedDates.includes(selectedDate.format('YYYY-MM-DD'))
      : false

    const isSameDate =
      +moment(serviceOrder?.servcOrdSchdlgTs).startOf('day') === +selectedDate.startOf('day')
    const isSamePeriod = shiftCd === selectedPeriod?.value
    const isReasonValid = (isSameDate && isSamePeriod) || !!reason
    const modeIsValid = !!distributeMode

    const hasProviderSelected = distributeMode === 'manual' ? !!provider : true

    return dateIsValid && isReasonValid && hasProviderSelected && modeIsValid
  }

  const disabled = !isFormValid()

  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClose()}>
        {cancelLabel}
      </MozaicButton>
      <MozaicButton
        isDisabled={disabled}
        onClick={() => {
          handleSubmit()
        }}
      >
        {submitLabel}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
