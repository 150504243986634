import { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { linkServiceOrdersFilters } from '../../../../../../redux/actions/serviceOrder'
import { useAdvancedSearchContext } from '../../../../../../contexts/advancedSearch'
import PropTypes from 'prop-types'
import CPIndicator from '../../../../entities/Indicator'

function SubIndicator({ alert, filters }) {
  const { handleSubmitDefault } = useAdvancedSearchContext()
  const Indicator = useMemo(() => new CPIndicator(alert, filters), [alert, filters])

  const loading = Indicator.loading
  const indicatorInfo = Indicator.indicator

  return (
    <section
      id='sub-indicator-content'
      className='sub-indicator-content'
      onClick={() => handleSubmitDefault(Indicator.parsedFilter)}
    >
      {loading ? (
        <SkeletonLoader />
      ) : (
        <section className='content-info'>
          <header>
            <span className='sub-indicator-title'>
              <span>{Indicator.title}</span>
            </span>
            {Indicator.subtitle && (
              <span className='sub-indicator-subtitle'>{Indicator.subtitle}</span>
            )}
          </header>
          <section className='quantity'>{indicatorInfo}</section>
        </section>
      )}
      <hr className='divider-sub-indicators' />
    </section>
  )
}

SubIndicator.propTypes = {
  filters: PropTypes.object,
  alert: PropTypes.object,
}

const mapStateToProps = ({ auth, newCockpitReducer }) => ({
  auth,
  filters: newCockpitReducer.filters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ linkServiceOrdersFilters }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SubIndicator)

function SkeletonLoader() {
  return (
    <>
      <header>
        <span className='sub-indicator-title skeleton skeleton-white skeleton-title' />
      </header>
      <section className='quantity skeleton skeleton-white skeleton-quantity' />
    </>
  )
}
