import { useState } from 'react'
import i18next from 'i18next'
import { useServiceOrderDetailContext } from '../../../../../context'
import Modal from '../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../components/MozaicButton'
import {
  ResponsibleType,
  assignToResponsible,
} from '../../../../../../../services/pages/ServiceOrderDetail/AssignToResponsible'

interface AssignToResponsibleModalProps {
  show: boolean
  setShow: Function
}

function AssignToResponsibleModal(props: AssignToResponsibleModalProps) {
  const { serviceOrder, reloadServiceOrder } = useServiceOrderDetailContext()
  const { show, setShow } = props
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAssign = async (responsibleType: ResponsibleType) => {
    setIsSubmitting(true)
    await assignToResponsible(serviceOrder?.servcOrdSeq!, responsibleType)

    setShow(false)
    setIsSubmitting(false)

    await reloadServiceOrder()
  }

  return (
    <div className='pop-up-modal'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => setShow(false)}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.title')}
      >
        <h4>{i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.content')}</h4>

        <footer>
          <MozaicButton
            variant='ghost'
            theme='primary-02'
            onClick={() => setShow(false)}
            isDisabled={isSubmitting}
          >
            {i18next.t('button.label.cancel')}
          </MozaicButton>

          <MozaicButton
            onClick={() => handleAssign(ResponsibleType.loggedUser)}
            isDisabled={isSubmitting}
          >
            {i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.assignToMe')}
          </MozaicButton>

          <MozaicButton
            onClick={() => handleAssign(ResponsibleType.serviceCore)}
            isDisabled={isSubmitting}
          >
            {i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.assignToServiceCore')}
          </MozaicButton>
        </footer>
      </Modal>
    </div>
  )
}

export default AssignToResponsibleModal
