import i18next from 'i18next'
// @ts-ignore
import Select from 'react-select'

import ServiceOrderStatusType from '../../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServcOrdEvntStusType from '../../../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import { TAG_TYPES } from '../../../../../../../../enums/ServiceOrder/tags/tag-types'

import { acceptanceSalesConditions } from '../../../../../../../../helpers/serviceOrderUtils'

import { useServiceOrderDetailContext } from '../../../../../../context'
import { useDistributeContext } from '../../context'
import { optionType } from '../../../../../../../../models/Distribute'

import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../helpers/react-select-backyard-styles'

import store from '../../../../../../../../redux/store'

type InstallerSelectType = {
  distributeMode: string
  onChange: Function
  value: optionType | null
  compareTodayDate: boolean
  compareDeliveryDate: boolean
}

const OUT_FLOW_REFUSED = 3

function InstallerSelect(props: InstallerSelectType) {
  const { serviceOrderBody, serviceOrder, tags }: any = useServiceOrderDetailContext()
  const { installers, showInstallersHint } = useDistributeContext()
  const { onChange, value, distributeMode, compareTodayDate, compareDeliveryDate } = props
  const auth = store.getState().auth
  const { user } = auth

  const verifyIndStatus = (indStatus: string) => {
    switch (indStatus) {
      case ServiceOrderStatusType.STATUS_ASSOCIADA.id:
      case ServiceOrderStatusType.STATUS_CONFIRMADA.id:
      case ServiceOrderStatusType.STATUS_EM_REALIZACAO.id:
      case ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id:
      case ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id:
      case ServiceOrderStatusType.STATUS_ENCERRADO.id:
      case ServiceOrderStatusType.STATUS_CANCELADA.id:
      case ServiceOrderStatusType.NAO_CONFORME.id:
      case ServiceOrderStatusType.LOJA_AGUARDANDO.id:
      case ServiceOrderStatusType.LOJA_EM_REALIZACAO.id:
      case ServiceOrderStatusType.LOJA_REALIZADO.id:
      case ServiceOrderStatusType.LOJA_ENCERRADO.id:
      case ServiceOrderStatusType.OUT_OF_FLOW.id:
        return true
      default:
        return false
    }
  }

  const isDAP = () => {
    if (
      serviceOrderBody &&
      serviceOrderBody.serviceOrderBodyOccurrence &&
      serviceOrderBody.serviceOrderBodyOccurrence.some(
        (stusInd: any) => stusInd.servcOrdEvntStusInd === ServcOrdEvntStusType.REFUSAL_PROCESS.id,
      )
    ) {
      switch (serviceOrderBody?.servcOrdStusCd) {
        case ServiceOrderStatusType.STATUS_AGENDADA.id:
        case ServiceOrderStatusType.STATUS_PRE_AGENDADA.id:
          return false
        default:
          return null
      }
    } else {
      switch (serviceOrderBody?.servcOrdStusCd) {
        case ServiceOrderStatusType.STATUS_AGENDADA.id:
        case ServiceOrderStatusType.STATUS_PRE_AGENDADA.id:
          return true
        default:
          return null
      }
    }
  }

  const isInstallerDisabled = () => {
    const hasTagServcPrvdrManualDstrbt = tags.some(
      (e: any) => e.tagType === TAG_TYPES.MANUAL_DISTRIBUTED.labelKey,
    )

    const inFuncao01 = user.funcaoAcesso?.inFuncao01
    const inFuncao00 = user.funcaoAcesso?.inFuncao00
    const inFuncao42 = user.funcaoAcesso?.inFuncao42

    if (auth.setupParameters.servcPrvdrManualDistributionIsActive) {
      const manuallyDistributed = hasTagServcPrvdrManualDstrbt && !inFuncao42
      const _isDAP = isDAP()

      if (manuallyDistributed) return true
      if (_isDAP !== null) return _isDAP
    }

    const isOutFlowProcess =
      auth.setupParameters.serviceOrderBodyOutOfFlowEnable &&
      serviceOrderBody &&
      serviceOrderBody.idOutFlowProcess &&
      serviceOrderBody.idOutFlowProcess !== OUT_FLOW_REFUSED

    const isDistributed =
      serviceOrderBody &&
      serviceOrderBody.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id

    const isConformity = serviceOrderBody?.isConformity && auth.setupParameters.conformity

    const editSpecialistOn = serviceOrder?.editSpecialistOn === false && !inFuncao42

    const isPreScheduled =
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      !inFuncao00

    const isScheduled =
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id && !inFuncao01

    const isDistributedAndHasAgent =
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
      serviceOrderBody?.servcPrvdrAgntCd !== null

    const hasAcceptanceSalesConditions =
      serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      auth.setupParameters.acceptanceSalesConditions &&
      acceptanceSalesConditions(serviceOrderBody)

    if (!inFuncao42) return true
    if (isOutFlowProcess || isDistributed) return true
    if (isConformity) return true
    if (editSpecialistOn) return true
    if (isPreScheduled) return true
    if (isScheduled) return true
    if (isDistributedAndHasAgent) return true
    if (hasAcceptanceSalesConditions) return true

    return verifyIndStatus(serviceOrderBody?.servcOrdStusCd as string)
  }

  const label = i18next.t('customer.service.order.details.infos.specialist')
  const placeholder = i18next.t('serviceOrders.specialist.select.placeholder')
  const disabled =
    !installers.length ||
    distributeMode !== 'manual' ||
    isInstallerDisabled() ||
    !(compareDeliveryDate && compareTodayDate)
  const errorHint = i18next.t('select.noOptionsMessage')

  const hasContentClass = value ? '--has-content' : ''
  const disabledClass = disabled ? '--disabled' : ''

  return (
    <div className={`backyard-select-v2 ${hasContentClass} ${disabledClass}`}>
      <label className='backyard-select-label'>
        {label} {showInstallersHint && <label className='error-hint'>- {errorHint}</label>}
      </label>

      <Select
        value={value as any}
        options={installers}
        onChange={(e: any) => onChange(e)}
        isDisabled={disabled}
        placeholder={placeholder}
        isOptionDisabled={(option: any) => option.disabled}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
      />
    </div>
  )
}

export default InstallerSelect
