import i18next from 'i18next'

export const parseResultsText = (term: string, count: number) => {
  if (!count) return i18next.t('itemLoc-list.not-found')

  const prefix = i18next.t('payments.filtered.1')

  const body =
    count === 1
      ? i18next.t('advanced.search.results.label.single')
      : i18next.t('advanced.search.results.label')

  const postfix = term?.length
    ? i18next.t('serviceOrders.results-for-the-term')
    : i18next.t('serviceOrders.results-for-the-term.single')

  const bold = <b>{count}</b>

  if (term?.length) {
    return (
      <span>
        {prefix} {bold} {body} {postfix} "{term}"
      </span>
    )
  }

  return (
    <span>
      {prefix} {bold} {body} {postfix}
    </span>
  )
}
