import { useEffect, useState } from 'react'
import { ProviderStateType, StorageProviderType, optionType } from '../models/provider'

function ProviderState(storageFilters: StorageProviderType) {
  const [provider, setProvider] = useState<optionType | null>(null)
  const [installer, setInstaller] = useState<optionType | null>(null)

  useEffect(() => {
    if (storageFilters) {
      const { servcPrvdrId, servcPrvdrNm, servcPrvdrAgntId, servcPrvdrAgntNm } = storageFilters

      if (servcPrvdrId) setProvider({ value: servcPrvdrId, label: servcPrvdrNm.trim() })
      if (servcPrvdrAgntId)
        setInstaller({ value: servcPrvdrAgntId, label: servcPrvdrAgntNm.trim() })
    }
  }, [storageFilters])

  const getDefaultState = () => ({
    servcPrvdrId: '',
    servcPrvdrNm: '',
    servcPrvdrAgntId: '',
    servcPrvdrAgntNm: '',
  })

  const getCurrentState = () => ({
    servcPrvdrId: provider?.value || '',
    servcPrvdrNm: provider?.label || '',
    servcPrvdrAgntId: installer?.value || '',
    servcPrvdrAgntNm: installer?.label || '',
  })

  const clearState = () => {
    setProvider(null)
    setInstaller(null)
  }

  return {
    provider,
    installer,
    setProvider,
    setInstaller,
    getCurrentState,
    getDefaultState,
    clearState,
  } as ProviderStateType
}

export default ProviderState
