import { useMemo, useState } from 'react'
import usePersistState from '../../../hooks/usePersistState'
import { getColumns } from '../utils'
import { ProviderEval } from '../models'

export const storageKeys = [
  'orderBy',
  'orderBySense',
  'period',
  'periodType',
  'companyInfo',
  'registration',
  'orderReceipt',
  'selectedColumns',
]

export function useFilter() {
  const columnsOptions = useMemo(() => getColumns(), [])

  const [periodType, setPeriodType] = usePersistState('periodType', 'monthly')
  const [period, setPeriod] = usePersistState('period', 0)
  const [companyInfo, setCompanyInfo] = usePersistState('companyInfo', '')
  const [orderBy, setOrderBy] = usePersistState('orderBy', 'registerDate')
  const [orderBySense, setOrderBySense] = usePersistState('orderBySense', 'desc')
  const [registration, setRegistration] = usePersistState('registration', 1)
  const [orderReceipt, setOrderReceipt] = usePersistState('orderReceipt', 1)
  const [selectedColumns, setSelectedColumns] = usePersistState('selectedColumns', columnsOptions)

  const [results, setResults] = useState<ProviderEval[]>([])
  const [tableLoading, setTableLoading] = useState(false)
  const [noHasResults, setNoHasResults] = useState(false)

  const handleClearFilters = () => {
    setPeriodType('monthly')
    setPeriod(0)
    setCompanyInfo('')
    setOrderBy('registerDate')
    setOrderBySense('desc')
    setRegistration(1)
    setOrderReceipt(1)
    setSelectedColumns(columnsOptions)
  }

  return {
    periodType,
    setPeriodType,
    period,
    setPeriod,
    companyInfo,
    setCompanyInfo,
    orderBy,
    setOrderBy,
    orderBySense,
    setOrderBySense,
    results,
    setResults,
    tableLoading,
    setTableLoading,
    noHasResults,
    setNoHasResults,
    registration,
    setRegistration,
    orderReceipt,
    setOrderReceipt,
    columnsOptions,
    selectedColumns,
    setSelectedColumns,
    handleClearFilters,
    handleChangeOrderBy: (e: any) => setOrderBy(e.target.value),
    handleChangeCompanyInfo: (e: any) => setCompanyInfo(e.target.value),
    handleChangePeriod: (e: any) => setPeriod(e.target.value),
    handleChosePeriod: (e: any) => {
      setPeriodType(e)
      setPeriod(0)
    },
  }
}
