export const setInputFocus = (id: string) => {
  const inputText = document.querySelector(`#${id}`) as HTMLElement

  if (inputText) inputText.focus()
}

export const scrollMessages = (id: string) => {
  const chatArea = document.querySelector(`#${id}`) as HTMLElement

  if (chatArea) return chatArea.scrollTo({ behavior: 'smooth', top: chatArea.scrollHeight })
}
