import { useEffect, useState } from 'react'
import {
  Button,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useModals,
  useNotifications,
} from '@mozaic-ds/react'
import i18next from 'i18next'
import ProviderEntity from '../../../../../../models/Provider'
import { useServiceProvidersListV2Context } from '../../../../contexts'
import { deleteServiceProvider } from '../../../../../../services/providers/deleteServiceProvider'

/**
 * @description Modal to confirm the deletion of a service provider
 */

const MODAL_ID = 'providers-list-delete-modal'

interface DeleteModalProps {
  serviceProvider: ProviderEntity | null
}

export function DeleteModal({ serviceProvider }: DeleteModalProps) {
  const [isLoading, setIsLoading] = useState(false)

  const { open, close } = useModals()
  const notification = useNotifications()
  const { handleFetchServiceProviders, filters } = useServiceProvidersListV2Context()

  useEffect(() => {
    if (serviceProvider) open(MODAL_ID)
  }, [serviceProvider])

  const handleOnClickClose = () => {
    close(MODAL_ID)
  }

  const handleOnClickConfirmDelete = async () => {
    if (!serviceProvider) return
    try {
      setIsLoading(true)
      await deleteServiceProvider(serviceProvider.servcPrvdrId)
      handleFetchServiceProviders(filters)
      notification.success(
        {
          title: i18next.t('pwa.toast.success.message'),
          message: i18next.t('serviceProvider.delete.success'),
          duration: 8000,
        },
        'top',
      )
    } catch (error: any) {
      console.error('Error deleting service provider', error)
      notification.danger(
        {
          title: i18next.t('pwa.toast.error.message'),
          message: error?.message?.dsMessage,
          duration: 8000,
        },
        'top',
      )
    } finally {
      setIsLoading(false)
      close(MODAL_ID)
    }
  }

  return (
    <Modal
      id={MODAL_ID}
      onClose={handleOnClickClose}
      focusedElementIndex={-1}
      canCloseOnOverlayClick={false}
      canCloseOnEscapePress={!isLoading}
    >
      <ModalHeader>
        <ModalTitle>{i18next.t('pwa.confirmation')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Text as='p'>{i18next.t('serviceProvider.confirm.delete')}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          size='m'
          variant='bordered'
          theme='primary'
          width='full'
          onClick={handleOnClickClose}
          isDisabled={isLoading}
        >
          {i18next.t('cancel')}
        </Button>
        <Button
          size='m'
          theme='danger'
          width='full'
          onClick={() => {
            handleOnClickConfirmDelete()
          }}
          isDisabled={isLoading}
          className='confirm-btn'
        >
          {isLoading ? <Loader size='s' theme='light' /> : i18next.t('option.yes')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
