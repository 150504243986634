import i18next from 'i18next'

import Accordeon from '../../../../../../../../components/Accordeon'
import CategorySelect from '../../../../../../../../components/CategorySelect'
import StatusSelect from '../../../../../../../../components/StatusSelect'
import LmInput from '../../../../../../../../components/LmInput'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { WithContext } from './context'
import { ServiceInformationBoxType } from '../../models/serviceInfo'

function ServiceInformationBox(props: ServiceInformationBoxType) {
  const {
    showAllDetails,
    handleToggle,
    status,
    setStatus,
    category,
    setCategory,
    LmNumber,
    setLmNumber,
    litigy,
    setLitigy,
  } = props

  const title = {
    title: i18next.t('advanced.search.service.information.title'),
    icon: <MozaicIcon icon='Document32' className='icon-custom' />,
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  const alwaysVisibleContent = (
    <div className='input-group'>
      <StatusSelect value={status} onChange={setStatus} />

      {/** @deprecated */}
      {/* <ServiceTypeSelect value={serviceType} onChange={setServiceType} /> */}

      <CategorySelect value={category} onChange={setCategory} />
      <LmInput value={LmNumber} onChange={setLmNumber} />
    </div>
  )

  return (
    <div className='service-information-box'>
      <Accordeon
        header={title}
        alwaysVisibleContent={alwaysVisibleContent}
        styled={false}
        onToggle={handleToggle}
        defaultOpen={showAllDetails}
      />
    </div>
  )
}

export default WithContext(ServiceInformationBox)
