import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { ClientBoxType } from '../../../models/client'

export function WithContext(Component: FunctionComponent<ClientBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, handleToggle },
      clientDataState: { clientSearchField, handleChangeRadio },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        clientSearchField,
        showAllDetails,
        handleChangeRadio,
        handleToggle,
      }),
      [clientSearchField, showAllDetails],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
