import { ChangeEventHandler } from 'react'
import i18next from 'i18next'

import User from '../../../../../../../../core/User'
import store from '../../../../../../../../redux/store'

import Accordeon from '../../../../../../../../components/Accordeon'
import ServcOrdCategoryType from '../../../../../../../../enums/ServiceOrder/category/serviceCategoryType'
import MozaicCheckBox from '../../../../../../../../components/MozaicCheckBox'
import { isTechVisitAllowed } from '../../../../../../../../helpers/isTechVisit'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { WithContext } from './context'
import { AlertBoxType, AlertType } from '../../models/alerts'

const user = new User().currentUser

function AlertsBox(props: AlertBoxType) {
  const {
    showAllDetails,
    handleToggle,
    indValidateMeasurementSheet,
    setIndValidateMeasurementSheet,
    acceptanceServiceConditions,
    setAcceptanceServiceConditions,
    indWaitingTechReport,
    setIndWaitingTechReport,
    indWaitingLateTechReport,
    setIndWaitingLateTechReport,
    indRunningRework,
    setIndRunningRework,
    indWaitingMeasurementSheet,
    setIndWaitingMeasurementSheet,
    indAlertServices,
    setIndAlertServices,
    sosOutOfFlow,
    setSosOutOfFlow,
    manualDistributed,
    setManualDistributed,
    servcOnTime,
    setServcOnTime,
    serviceOrderHasAttachments,
    setServiceOrderHasAttachments,
    sosPendingBudget,
    setSosPendingBudget,
    sosWaitingQuotation,
    setSosWaitingQuotation,
    conformity,
    setConformity,
    waitingResponsible,
    setWaitingResponsible,
    assignedToMe,
    setAssignedToMe,
    waitingBudget,
    setWaitingBudget,
    recommendInspection,
    setRecommendInspection,
    negativeOv,
    setNegativeOv,
    attentionToDelivery,
    setAttentionToDelivery,
    providerReschedule,
    setProviderReschedule,
    clientRescheduleRefuse,
    setClientRescheduleRefuse,
    salesError,
    setSalesError,
  } = props

  const accessFunctionList = user?.accessFunctionList
  const allowedCategories = user?.allowedCategories
  const setupParameters = store.getState().auth.setupParameters

  const allowed10 = allowedCategories.length === 0 || allowedCategories.includes(10)
  const canSeeWaitingBudget = allowedCategories.includes(
    Number(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id),
  )

  const hasVisitOrWarrantyPermission =
    isTechVisitAllowed() || allowedCategories.includes(Number(ServcOrdCategoryType.WARRANTY.id))

  const title = {
    title: i18next.t('advanced.search.alerts.title'),
    icon: <MozaicIcon icon='NotificationBell24' className='icon-custom' />,
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  const alerts: Array<AlertType> = [
    hasVisitOrWarrantyPermission && {
      value: indWaitingTechReport,
      onChange: setIndWaitingTechReport,
      label: i18next.t('serviceOrders.WaitingTechReport'),
    },
    hasVisitOrWarrantyPermission && {
      value: indWaitingLateTechReport,
      onChange: setIndWaitingLateTechReport,
      label: i18next.t('serviceOrders.WaitingLateTechReport'),
    },
    {
      value: indRunningRework,
      onChange: setIndRunningRework,
      label: i18next.t('serviceOrders.indNewRework'),
    },
    {
      value: indAlertServices,
      onChange: setIndAlertServices,
      label: i18next.t('advanced.search.coordinator.alert'),
    },
    accessFunctionList?.includes(87) && {
      value: sosOutOfFlow,
      onChange: setSosOutOfFlow,
      label: i18next.t('cockpit.alerts.out.of.service'),
    },
    {
      value: manualDistributed,
      onChange: setManualDistributed,
      label: i18next.t('advanced.search.manual.distribution'),
    },
    {
      value: indWaitingMeasurementSheet,
      onChange: setIndWaitingMeasurementSheet,
      label: i18next.t('advanced.search.waitingMeasurementSheet'),
    },
    {
      value: indValidateMeasurementSheet,
      onChange: setIndValidateMeasurementSheet,
      label: i18next.t('advanced.search.validateMeasurementSheet'),
    },
    {
      value: servcOnTime,
      onChange: setServcOnTime,
      label: i18next.t('cockpit.services.on.time'),
    },
    allowed10 && {
      value: serviceOrderHasAttachments,
      onChange: setServiceOrderHasAttachments,
      label: i18next.t('serviceOrders.hasAttachments'),
    },
    allowed10 && {
      value: sosPendingBudget,
      onChange: setSosPendingBudget,
      label: i18next.t('cockpit.service.orders.pending.budget'),
    },
    allowed10 && {
      value: sosWaitingQuotation,
      onChange: setSosWaitingQuotation,
      label: i18next.t('advanced.search.waiting.quotation'),
    },
    setupParameters.conformity && {
      value: conformity,
      onChange: setConformity,
      label: i18next.t('serviceOrders.idConformity'),
    },
    setupParameters.acceptanceSalesConditions && {
      value: acceptanceServiceConditions,
      onChange: setAcceptanceServiceConditions,
      label: i18next.t('cockpit.alerts.acceptance.service.conditions'),
    },
    {
      value: waitingResponsible,
      onChange: setWaitingResponsible,
      label: i18next.t('advanced.search.waitingResponsible'),
    },
    {
      value: assignedToMe,
      onChange: setAssignedToMe,
      label: i18next.t('advanced.search.assignedToMe'),
    },
    canSeeWaitingBudget && {
      value: waitingBudget,
      onChange: setWaitingBudget,
      label: i18next.t('serviceOrdersFragment.badge.budget'),
    },
    {
      value: recommendInspection,
      onChange: setRecommendInspection,
      label: i18next.t('advanced.search.alert.recommend.inspect'),
    },
    {
      value: attentionToDelivery,
      onChange: setAttentionToDelivery,
      label: i18next.t('cockpit.alert.attentionToDelivery.title'),
    },
    accessFunctionList?.includes(139) && {
      value: negativeOv,
      onChange: setNegativeOv,
      label: i18next.t('cockpit.alert.negativeOV.title'),
    },
    {
      value: providerReschedule,
      onChange: setProviderReschedule,
      label: i18next.t('serviceOrder.badge.providerReschedule'),
    },
    {
      value: clientRescheduleRefuse,
      onChange: setClientRescheduleRefuse,
      label: i18next.t('serviceOrder.badge.clientRescheduleRefuse'),
    },
    {
      value: salesError,
      onChange: setSalesError,
      label: i18next.t('access.function141'),
    },
  ].filter(Boolean)

  return (
    <div className='alerts-box'>
      <Accordeon header={title} styled={false} onToggle={handleToggle} defaultOpen={showAllDetails}>
        <div className='input-group'>
          {alerts.map((alert, key) => (
            <div key={`alert-${key}`} className='advanced-search-checkbox'>
              <MozaicCheckBox
                isChecked={alert.value}
                label={alert.label}
                onChange={alert.onChange as ChangeEventHandler<HTMLInputElement>}
                id={`alert-${key}`}
              />
            </div>
          ))}
        </div>
      </Accordeon>
    </div>
  )
}

export default WithContext(AlertsBox)
