import i18next from 'i18next'
import MozaicIcon from '../MozaicIcon'

import Input from '../utils/Input'

type ServiceTypeSelectType = {
  onChange: Function
  value: string
}

function LmInput(props: ServiceTypeSelectType) {
  const { onChange, value } = props

  const handleChange = (e: any) => {
    onChange(e.target.value)
  }

  const handleClear = () => {
    if (value) onChange('')
  }

  const label = i18next.t('serviceOrder.pricereport.servcResaleProdCd')
  const placeholder = i18next.t('subcategoriesByLm.lm.field')
  const icon = value ? 'icon-visible' : 'icon-invisible'

  return (
    <div className='lm-input'>
      <label className='lm-input_label'>{label}</label>

      <div className='lm-input_body'>
        <Input
          name='lm-input'
          id='lm-input'
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          label={i18next.t('serviceOrders.codProdAssociado')}
        />

        <MozaicIcon
          icon='ControlTagCross24'
          className={`lm-input_icon ${icon}`}
          onClick={handleClear}
        />
      </div>
    </div>
  )
}

export default LmInput
