import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'
import { formatDecimalNoCurrency } from '../../../../../../helpers/masks'

function TotalValue() {
  const { loadingBody, serviceOrderBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='total-value-info' className='skeleton' />

  return (
    <div id='total-value-info'>
      <label htmlFor=''>{i18next.t('provider.portal.total.value')}</label>
      <section>
        <span className='total-value-name-text'>
          R$ {formatDecimalNoCurrency((serviceOrderBody?.servcOrdTtlVl || 0) * 100) || '-'}
        </span>
      </section>
    </div>
  )
}

export default TotalValue
