import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getPhoneNumberCountry } from '../../helpers/InternationalPhoneNumberUtils'
import TelephoneInput from './TelephoneInput'

function TelephoneInputField(props) {
  const {
    id,
    name,
    onChange,
    label,
    className,
    labelClassName = '',
    value,
    topLabel,
    valid,
    country = 'BR',
    ...remainingProps
  } = props

  const inputClass = classNames({ input: true }, className)

  const classLabelDisabled =
    className === 'inputDisabled' && remainingProps.disabled ? 'disable-label' : ''

  return (
    <div
      className={`telephone-input-field ${
        (valid === true && 'green') || (valid === false && 'red') || ''
      }`}
    >
      {topLabel && (
        <label htmlFor={id} className={`top-label ${classLabelDisabled}`}>
          {topLabel}
        </label>
      )}

      <TelephoneInput
        id={id}
        name={name}
        value={value}
        country={(value && getPhoneNumberCountry(value)) || country}
        defaultCountry={(value && getPhoneNumberCountry(value)) || country}
        className={inputClass}
        onChange={onChange}
        {...remainingProps}
      />

      {label && (
        <label htmlFor={id} className={`label ${labelClassName}`}>
          {label}
        </label>
      )}
    </div>
  )
}

TelephoneInputField.propTypes = {
  /**
   * The input [id] attribute
   */
  id: PropTypes.string,

  /**
   * The input [name] attribute
   */
  name: PropTypes.string,

  /**
   * Lower label text, leave empty for no label below the input
   */
  label: PropTypes.string,

  /**
   * Top label text, leave empty for no label above the input
   */
  topLabel: PropTypes.string,

  /**
   * Input [class] attribute
   */
  className: PropTypes.string,

  /**
   * Lower label [class] attribute
   */
  labelClassName: PropTypes.string,

  /**
   * The phone number in E164 format.
   * Ex.: `undefined`, `"+55"`, `"+5554999999999"`.
   */
  value: PropTypes.string.isRequired,

  /* Phone number country */
  country: PropTypes.any,

  /**
   * Function triggered when the phone number changes.
   * Receives the new phone number in E164 format as argument.
   */
  onChange: PropTypes.func,

  /**
   * Defines if the input value is or not `valid`
   */
  valid: PropTypes.bool,
}

export default TelephoneInputField
