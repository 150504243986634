import { useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Indicator from '../../../../../entities/ServiceOnTimeIndicator'
import i18next from 'i18next'
import PopoverInfo from '../../../../../../../components/PopoverInfo'

export default function IndicatorBody({
  Indicator,
  showPopover,
}: {
  Indicator: Indicator
  showPopover: boolean
}) {
  const popover = i18next.t(`cockipt.popover.service.on-time.subtitle.text`)
  const popoverContent = useMemo(() => ReactHtmlParser(popover), [popover])
  const hasPopoverBody = popoverContent?.length > 0 || popover !== ''

  return (
    <>
      <header>
        <span className='title'>
          <span>{Indicator.title}</span>

          {showPopover && hasPopoverBody && (
            <PopoverInfo
              content={popoverContent}
              iconWidth={12}
              iconHeight={12}
              iconColor='#FFFFFF'
            />
          )}
        </span>
        {Indicator.subtitle && <span className='subtitle'>{Indicator.subtitle}</span>}
      </header>
      <section className='quantity'>{Indicator.parsedIndicator}</section>
    </>
  )
}
