import PropTypes from 'prop-types'
import classNames from 'classnames'

function Row(props) {
  const { className, children } = props

  const componentClass = classNames('row', {
    [className]: className,
  })

  const newProps = {
    className: componentClass,
    children,
  }

  return <section {...newProps} />
}

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default Row
