import PropTypes from 'prop-types'
import { Component } from 'react'

import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'

import { WithForm } from '../../../helpers/useForm/withForm'

class AttentionOccurrenceModal extends Component {
  handleAttentionOccurrence = () => {
    const { save, values, servcOrd, index, closeModal, servcOrdEvntStusInd } = this.props

    const occurrence = {
      servcOrdSeq: servcOrd.servcOrdSeq,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: values.dsOccurrence,
      servcOrdEvntStusInd,
      indStatusOrdemServicoItem: servcOrd.servcOrdStusCd,
      idPrestadorServico: servcOrd.servcPrvdrCd,
      idEspecialistaServico: servcOrd.servcPrvdrAgntCd,
      indStatusOrdemServico: servcOrd.servcOrdStusCd,
    }

    save(index, occurrence)
    closeModal()
  }

  validateField = (e) => {
    const { t } = this.props

    if (/[a-zA-Z]/.test(e) && e.length >= 10) return false

    return t('serviceOrder.litigation.required.field')
  }

  render() {
    const { t, fields, register, canSubmit, closeModal } = this.props

    return (
      <Modal className='lmi-modal' closeModal={closeModal} size='sm'>
        <form>
          <Row className='padding lmi-modal-header'>
            <span>{t('attentionOccurrenceModal.header')}</span>
          </Row>

          <Row>
            <Col xs={12} className='margin-bottom'>
              <Field {...fields.dsOccurrence}>
                <MozaicTextArea
                  {...register('dsOccurrence', { required: true, validate: this.validateField })}
                  id='dsOccurrence'
                  label={t('attentionOccurrenceModal.text')}
                  rows={6}
                  maxLength={120}
                />
              </Field>
            </Col>
          </Row>

          <Row className='padding-vertical align-items-center'>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button-hollow button-hollow-danger button-full align-center'
                type='button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col xs={6} md={6} className='button-align'>
              <button
                className='button button-primary button-full'
                type='button'
                disabled={!canSubmit}
                onClick={this.handleAttentionOccurrence}
              >
                {t('attentionOccurrenceModal.save')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

AttentionOccurrenceModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  errorFn: PropTypes.func,
  validation: PropTypes.bool,
  values: PropTypes.object.isRequired,
  servcOrd: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  servcOrdEvntStusInd: PropTypes.string.isRequired,
  canSubmit: PropTypes.bool,
  register: PropTypes.func,
}

const FIELDS = { dsOccurrence: '' }

export default WithForm(FIELDS)(AttentionOccurrenceModal)

export { AttentionOccurrenceModal }
