import { useEffect, useMemo } from 'react'
import i18next from 'i18next'

import ConversationList from './components/ConversationList'
import ClientChat from './components/ClientChat'
import CustomCard from '../../../../components/Card'
import MozaicIcon from '../../../../components/MozaicIcon'

import ErrorFallback from '../../../../errors/fallbacks'
import ErrorBoundary from '../../../../errors/boundaries'
import { Conversation, FirestoreStore } from '../../../../contexts/customerChat/models'
import { useFirestoreContext } from '../../../../contexts/customerChat'

function ChannelCustomer({ hasOpenedClientConversation, conversationList }: FirestoreStore) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetErrorBoundary={() => {}}>
      <div id='channel-customer-container'>
        {hasOpenedClientConversation ? (
          <CustomCard
            title={i18next.t('client.chat.title')}
            iconNode={<MozaicIcon icon='AccountProfileView24' />}
          >
            <ClientChat />
          </CustomCard>
        ) : (
          <ConversationList conversationList={conversationList as Array<Conversation>} />
        )}
      </div>
    </ErrorBoundary>
  )
}

function ChannelCustomerWithContext(props: any) {
  const {
    conversationList,
    loadingConversations,
    hasOpenedClientConversation,
    handleClearConversationList,
    handleClearConversation,
  } = useFirestoreContext()

  useEffect(
    () => () => {
      handleClearConversationList()
      handleClearConversation()
    },
    [],
  )

  const newProps = useMemo(
    () => ({
      hasOpenedClientConversation,
      loadingConversations,
      conversationList,
    }),
    [hasOpenedClientConversation, loadingConversations, conversationList],
  )

  return <ChannelCustomer {...newProps} {...props} />
}

export default ChannelCustomerWithContext
