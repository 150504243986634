import i18next from 'i18next'

import FormField from '../../../../../../components/FormField'
import { useServiceOrderDetailContext } from '../../../../context'

function ZoneInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='zone-info' className='skeleton' />

  return (
    <div id='zone-info'>
      <FormField label={i18next.t('serviceRegion.zone')} value={serviceOrderBody?.servcRgnZn} />
    </div>
  )
}

export default ZoneInfo
