import { createRef, Component } from 'react'
import PropTypes from 'prop-types'

import Row from '../../../components/utils/Row'
import Form from '../../../components/utils/Form'

import { getExtension } from '../../../helpers/Toolbox'

import ServicePersonListDocuments from './ServicePersonListDocuments'
import ServicePersonFormDocument from './ServicePersonFormDocument'

class ServicePersonDocuments extends Component {
  state = {
    disableFileSelect: false,
  }

  inputRef = createRef()

  componentDidMount() {
    const { initialValues, setValueToField, personId } = this.props

    if (initialValues[personId]) {
      setValueToField('personId', initialValues[personId])
    }
  }

  shouldRenderList() {
    const { attachments, certificates } = this.props
    return (attachments && !!attachments.length) || (certificates && !!certificates.length)
  }

  onEdit = (attachment) => {
    const { setValueToField, attachmentId } = this.props

    if (this.isSameFile(attachment)) {
      return this.resetForm()
    }

    this.resetInputFile()

    setValueToField('type', attachment.type || '')
    setValueToField('files', [
      {
        file: attachment.descDirAnexo,
        name: attachment.descAnexo,
        id: attachment[attachmentId],
        date: attachment.datInclusaoReg,
      },
    ])

    this.setState({ disableFileSelect: true })
  }

  onDelete = (file) => {
    const { personId, attachmentId } = this.props

    if (this.isSameFile(file)) {
      this.resetForm()
    }

    if (file[personId]) {
      const { onDelete } = this.props
      return onDelete(file[personId], file[attachmentId])
    }

    const { updateLocalFiles, attachments } = this.props
    updateLocalFiles(
      attachments.filter((attachment) => attachment[attachmentId] !== file[attachmentId]),
    )
  }

  onSubmit = (values) => {
    const { personId } = this.props

    if (values[personId]) {
      const { onSubmit } = this.props
      return onSubmit(values)
    }

    if (values.files && values.files.length) {
      const { attachmentId, updateLocalFiles, attachments } = this.props
      const localFiles = [...attachments]

      values.files.forEach((file) => {
        // Checks if it's a new file
        if (!file.id) {
          const tempFile = {
            src: `data:image/${getExtension(file.name)};base64,${file.file}`,
            descAnexo: file.name,
            descType: values.descType,
            [attachmentId]: Date.now() * Math.floor(Math.random() * 1000),
            type: values.atchmtDocmntTyp,
          }

          return localFiles.push(tempFile)
        }

        const fileIndex = localFiles.findIndex((fileToAdd) => fileToAdd[attachmentId] === file.id)

        // Just for sure, but it's likely to always enter here
        if (fileIndex > -1) {
          localFiles[fileIndex] = {
            ...localFiles[fileIndex],
            descType: values.descType,
            type: values.atchmtDocmntTyp,
          }
        }
      })

      updateLocalFiles(localFiles)
    }
  }

  isSameFile = (attachment) => {
    const {
      values: { files },
      attachmentId,
    } = this.props

    if (files && files.length) {
      if (attachment[attachmentId] === files[0].id) {
        return true
      }
    }

    return false
  }

  resetForm = () => {
    const { setValueToField } = this.props

    setValueToField('type', '')
    setValueToField('files', null)
    this.resetInputFile()
    this.setState({ disableFileSelect: false })
  }

  resetInputFile = () => {
    const inputRef = this.inputRef.current

    if (inputRef) {
      inputRef.value = ''
      inputRef.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  render() {
    const {
      t,
      attachments,
      closeModal,
      error,
      downloadFile,
      disabled,
      setValueToField,
      fields,
      values,
      personId,
      attachmentId,
      auth,
      dispatch,
      certificates,
    } = this.props
    const { disableFileSelect } = this.state

    return (
      <div>
        <Row className='padding lmi-modal-header'>
          <span>{t('option.manageDocuments')}</span>
        </Row>

        {this.shouldRenderList() && (
          <ServicePersonListDocuments
            t={t}
            attachments={attachments}
            onDelete={this.onDelete}
            downloadFile={downloadFile}
            disabled={disabled}
            onEdit={this.onEdit}
            attachmentId={attachmentId}
            user={auth.user}
            dispatch={dispatch}
            certificates={certificates}
          />
        )}

        {!disabled && (
          <ServicePersonFormDocument
            t={t}
            onSubmit={this.onSubmit}
            closeModal={closeModal}
            error={error}
            setValueToField={setValueToField}
            disableFileSelect={disableFileSelect}
            fields={fields}
            values={values}
            resetForm={this.resetForm}
            personId={personId}
            inputRef={this.inputRef}
            auth={auth}
            dispatch={dispatch}
          />
        )}
      </div>
    )
  }
}

ServicePersonDocuments.propTypes = {
  t: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  error: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setValueToField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  personId: PropTypes.string.isRequired,
  attachmentId: PropTypes.string.isRequired,
  updateLocalFiles: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  certificates: PropTypes.array,
}

const fields = ['personId', 'files', 'type']

export default Form({ fields })(ServicePersonDocuments)

export { ServicePersonDocuments }
