import PropTypes from 'prop-types'
import { Component } from 'react'
import LogoWithoutSignature from '../../../assets/icons/logoWithoutSignature.svg?react'

class ChatHeader extends Component {
  static parseLastMessageTime(lastMessage) {
    if (!lastMessage) return null

    return lastMessage.createdAt
  }

  render() {
    const { chatRecord, loading, humanizeDate, t } = this.props

    return (
      <div className='chat-header align-items-center justify-content-between'>
        <div className='chat-header-start align-items-center'>
          {chatRecord.inMatrix && (
            <LogoWithoutSignature className='chat-contact-badge-big-selected2 padding-logo-chat' />
          )}

          <div className='chat-contact-start'>
            <div className='chat-header-names'>
              <h4 className='chat-header-name strong'>{`${chatRecord.dsChat}`}</h4>
            </div>

            {chatRecord.lastMessage && (
              <div className='chat-contact-time'>
                {`${t('chatList-header.lastMessage')} ${humanizeDate(
                  ChatHeader.parseLastMessageTime(chatRecord.lastMessage),
                )}`}
              </div>
            )}
          </div>

          {loading && <div className='chat-loading padding-horizontal' />}
        </div>

        {false && ( // Deixar esse ícone desabilitado até segunda ordem
          /* istanbul ignore next */
          <div className='chat-header-end'>
            <div className='glyph glyph-magnifier' />
          </div>
        )}
      </div>
    )
  }
}

ChatHeader.propTypes = {
  chatRecord: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  humanizeDate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default ChatHeader
