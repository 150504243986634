import PropTypes from 'prop-types'
import { Component } from 'react'
import Col from '../../../components/utils/Col'
import Form from '../../../components/utils/Form'
import Input from '../../../components/utils/Input'
import InputNumber from '../../../components/utils/InputNumber'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'
import ServiceSelectV2 from './ServiceSelectV2'

class ServiceModalV2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      values: {
        serviceCode: '',
        serviceDesc: '',
        serviceQuantity: '',
        serviceValue: '',
        serviceAssociatedProductCode: '',
        serviceAssociatedProductName: '',
        serviceAssociatedProductQuantity: '',
      },
    }
  }

  handleChangeService = (value) => {
    const { values } = this.state

    // eslint-disable-next-line no-param-reassign
    value = value || { value: null }
    values.serviceCode = value.value
    values.serviceDesc = value.name

    this.setState({ values })
  }

  handleChangeQuantity = (event) => {
    const { values } = this.state

    values.serviceQuantity = event.target.value

    this.setState({ values })
  }

  handleChangeValue = (event) => {
    const { values } = this.state

    values.serviceValue = event.target.value

    this.setState({ values })
  }

  handleChangeServiceAssociatedProductCode = (event) => {
    const { values } = this.state

    values.serviceAssociatedProductCode = event.target.value

    this.setState({ values })
  }

  handleChangeServiceAssociatedProductName = (event) => {
    const { values } = this.state

    values.serviceAssociatedProductName = event.target.value

    this.setState({ values })
  }

  handleChangeServiceAssociatedProductQuantity = (event) => {
    const { values } = this.state

    values.serviceAssociatedProductQuantity = event.target.value

    this.setState({ values })
  }

  onSubmit = (event) => {
    event.preventDefault()

    const { onSubmit } = this.props

    const { values } = this.state

    onSubmit(values)
  }

  render() {
    const { t, plntCd, user, dispatch, closeModal } = this.props

    const { values } = this.state

    return (
      <Modal closeModal={closeModal} size='sm' showCloseButton className='lmi-modal'>
        <Row className='padding lmi-modal-header'>
          <span>{t('servcOrd.services.create')}</span>
        </Row>

        <Row>
          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceCode ? 'active' : ''}`}>
              <ServiceSelectV2
                id='serviceCode'
                value={values.serviceCode}
                label={t('servcOrd.services.code')}
                onChange={this.handleChangeService}
                plntCd={plntCd}
                user={user}
                t={t}
                dispatch={dispatch}
              />
            </div>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceQuantity ? 'active' : ''}`}>
              <InputNumber
                id='serviceQuantity'
                value={values.serviceQuantity}
                label={t('servcOrd.services.quantity')}
                maxLength={18}
                minLength={1}
                separator={t('separator.currency')}
                delimiter={t('delimiter.currency')}
                onChange={this.handleChangeQuantity}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceValue ? 'active' : ''}`}>
              <InputNumber
                id='serviceValue'
                value={values.serviceValue}
                label={t('servcOrd.services.value')}
                maxLength={18}
                minLength={1}
                separator={t('separator.currency')}
                delimiter={t('delimiter.currency')}
                onChange={this.handleChangeValue}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceAssociatedProductCode ? 'active' : ''}`}>
              <Input
                id='serviceAssociatedProductCode'
                value={values.serviceAssociatedProductCode}
                label={t('servcOrd.services.associatedProductCode')}
                maxLength={18}
                minLength={1}
                onChange={this.handleChangeServiceAssociatedProductCode}
              />
            </div>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceAssociatedProductName ? 'active' : ''}`}>
              <Input
                id='serviceAssociatedProductName'
                value={values.serviceAssociatedProductName}
                label={t('servcOrd.services.associatedProductName')}
                maxLength={40}
                minLength={1}
                onChange={this.handleChangeServiceAssociatedProductName}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} xs={12}>
            <div className={`field ${values.serviceAssociatedProductQuantity ? 'active' : ''}`}>
              <InputNumber
                id='serviceAssociatedProductQuantity'
                value={values.serviceAssociatedProductQuantity}
                label={t('servcOrd.services.associatedProductQuantity')}
                maxLength={18}
                minLength={1}
                separator={t('separator.currency')}
                delimiter={t('delimiter.currency')}
                onChange={this.handleChangeServiceAssociatedProductQuantity}
              />
            </div>
          </Col>
        </Row>

        <Row className='padding-double lmi-modal-content'>
          <div className='padding-vertical-double'>
            <button
              type='button'
              className='button button-primary button-full'
              onClick={this.onSubmit}
            >
              {t('servcOrd.services.save')}
            </button>
          </div>
        </Row>
      </Modal>
    )
  }
}

ServiceModalV2.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  plntCd: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

// Export default conectado ao Form
export default Form()(ServiceModalV2)

// Export somente do componente
export { ServiceModalV2 }
