import i18next from 'i18next'
import { useState } from 'react'
import User from '../../../../../../core/User'
import store from '../../../../../../redux/store'

import { backOrderToSchedule } from '../../../../../../services/pages/ServiceOrderDetail/BackToScheduled'
import { useServiceOrderDetailContext } from '../../../../context'

import { toast } from '@leroy-merlin-br/backyard-react'
import Modal from '../../../../../../components/Modal'
import MozaicText from '../../../../../../components/MozaicText'
import OutlineButton from '../../../../../../components/OutlineButton'
import { hideSpinner, showSpinner } from '../../../../../../redux/actions/spinner'
import FooterButtons from './components/FooterButtons'
import JustifyInput from './components/JustifyInput'

const MANUAL_DISTRIBUTED = '48'

function BackToReschedule() {
  const user = new User().currentUser
  const setupParameters = store.getState().auth.setupParameters
  const accessFunctionList = user?.accessFunctionList
  const { statusConfiguredDisplayScheduleLink } = setupParameters
  const { serviceOrderBody, occurrences, reloadServiceOrder } = useServiceOrderDetailContext()

  const [show, setShow] = useState<boolean>(false)
  const [justify, setJustify] = useState<string>('')

  const isAutoDistributed =
    occurrences.findIndex((ocurrence) => ocurrence.servcOrdEvntStusInd === MANUAL_DISTRIBUTED) ===
    -1

  const allowedStatus = statusConfiguredDisplayScheduleLink.split(',')
  const canSubmit = isAutoDistributed ? justify.length >= 10 : true
  const haveToShow =
    allowedStatus.includes(serviceOrderBody?.servcOrdStusCd) && accessFunctionList?.includes(110)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    if (!serviceOrderBody) return false
    store.dispatch(showSpinner())

    try {
      const payload = {
        servcOrdStusCd: serviceOrderBody.servcOrdStusCd,
        servcOrdSeq: serviceOrderBody.servcOrdSeq,
        justificationBackToScheduled: justify,
      }

      await backOrderToSchedule(payload)
      await reloadServiceOrder()

      setShow(false)
      toast.success('', i18next.t('serviceOrders.saved'), '')
    } catch (error: any) {
      toast.error('', error?.dsMessage || i18next.t('dao.save.error'), '')
    }

    store.dispatch(hideSpinner())
  }

  const labelText = i18next
    .t('serviceOrders.change.os.to.text')
    .replace('{status}', i18next.t('serviceOrders.provider.scheduled').toLowerCase())

  return (
    <>
      {haveToShow && (
        <div id='back-to-rescheduling'>
          <OutlineButton
            label={i18next.t('serviceOrders.change.os.to.reschedule')}
            onClick={() => {
              setShow(true)
            }}
          />

          <Modal
            size='lg'
            isOpen={show}
            onClose={() => setShow(false)}
            className='no-margin-header'
            closeInOverlay={false}
            contentLabel={i18next.t('serviceOrders.change.os.to.reschedule')}
          >
            <MozaicText> {labelText} </MozaicText>

            {isAutoDistributed && <JustifyInput value={justify} onChange={handleChangeJustify} />}

            <FooterButtons
              disabled={!canSubmit}
              handleSubmit={handleSubmit}
              handleClose={() => setShow(false)}
            />
          </Modal>
        </div>
      )}
    </>
  )
}

export default BackToReschedule
