import { memo } from 'react'

import MozaicIcon from '../MozaicIcon'

interface DownloadButtonProps {
  onClickDownloadButton: Function
}

function DownloadButton(props: DownloadButtonProps) {
  const { onClickDownloadButton } = props

  return (
    <button id='download-button' type='button' onClick={() => onClickDownloadButton()}>
      <MozaicIcon icon='DownloadApp24' />
    </button>
  )
}

export default memo(DownloadButton)
