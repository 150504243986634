import { useEffect, useState } from 'react'

import classNames from 'classnames'
import Header from './components/Header'

import { uniqueId } from 'lodash'
import { AccordeonType } from './models'

function Accordeon(props: AccordeonType) {
  const {
    header,
    action,
    children,
    onToggle,
    alwaysVisibleContent,
    canCollapse = true,
    defaultOpen = true,
    styled = true,
  } = props

  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [inTransition, setInTransition] = useState(false)

  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  useEffect(() => {
    if (onToggle) onToggle(!isOpen)
  }, [isOpen])

  const handleToggle = (e: any) => {
    if (!canCollapse) return false

    setIsOpen(!isOpen)
    setInTransition(true)

    setTimeout(() => {
      setInTransition(false)

      if (!isOpen) {
        e.target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }, 250)
  }

  const id = uniqueId('accordeon')

  const classes = {
    styled,
    opened: isOpen,
    collapsed: !isOpen,
    transition: inTransition,
    'can-collapse': canCollapse,
  }

  return (
    <div className={classNames('accordeon', classes)} id={id}>
      <Header
        header={header}
        action={action}
        isOpen={isOpen}
        canCollapse={canCollapse}
        handleToggle={handleToggle}
      />

      {alwaysVisibleContent && <section className='always-visible'>{alwaysVisibleContent}</section>}

      <section className='body'>{children}</section>
    </div>
  )
}

export default Accordeon
