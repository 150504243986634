import { FilterValues } from '../components/Filter'

/**
 * generate URL based in page and filter params
 */
export const buildUrlFilter = (pathname: string, page: number, filters: FilterValues): string => {
  const currentURL = new URL(window.location.href)
  currentURL.searchParams.delete('q')
  currentURL.searchParams.delete('active')
  currentURL.searchParams.delete('receivingOrder')
  currentURL.searchParams.delete('stores')
  currentURL.searchParams.delete('groups')
  currentURL.searchParams.delete('page')

  if (filters.q) {
    currentURL.searchParams.set('q', filters.q)
  }

  if (filters.active) {
    currentURL.searchParams.set('active', filters.active)
  }

  if (filters.receivingOrder) {
    currentURL.searchParams.set('receivingOrder', filters.receivingOrder)
  }

  if (filters.stores.length > 0) {
    currentURL.searchParams.set('stores', filters.stores.join(','))
  }

  if (page > 1) {
    currentURL.searchParams.set('page', String(page))
  }

  return `${pathname}${currentURL.search}`
}

type YesNoValue = '' | '0' | '1'

const getYesNoUrlFilter = (value: string): YesNoValue => {
  if (value === '1') return '1'
  if (value === '0') return '0'
  return ''
}

/**
 * generate filter params based in URL
 */
export const getUrlFilters = (): FilterValues => {
  const currentURL = new URL(window.location.href)
  const { searchParams } = currentURL

  const params: FilterValues = {
    q: searchParams.get('q') || '',
    active: getYesNoUrlFilter(searchParams.get('active') || ''),
    receivingOrder: getYesNoUrlFilter(searchParams.get('receivingOrder') || ''),
    stores: searchParams.has('stores') ? (searchParams.get('stores') || '').split(',') : [],
  }

  return params
}

/**
 * get page number based in URL
 */
export const getUrlPage = (): number => {
  const currentURL = new URL(window.location.href)
  const { searchParams } = currentURL
  return searchParams.has('page') ? parseInt(searchParams.get('page') || '', 10) : 1
}
