import i18next from 'i18next'
import { useState } from 'react'
import Modal from '../../../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../../../components/MozaicButton'
import MozaicTextArea from '../../../../../../../../../components/MozaicTextArea'
import HttpStatusCode from '../../../../../../../../../enums/HttpStatusCode'
import {
  registerHistoricOccurrence,
  RegisterHistoricOccurrenceParams,
} from '../../../../../../../../../services/pages/ServiceOrderDetail/RegisterHistoricOccurrence'
import { useServiceOrderDetailContext } from '../../../../../../../context'

interface NewOccurrenceModalProps {
  isOpen: boolean
  onClose: (newOccurrenceCreated?: boolean) => void
}

export default function NewOccurrenceModal({ isOpen, onClose }: NewOccurrenceModalProps) {
  const { servcOrdCd } = useServiceOrderDetailContext()

  const [occurrenceContent, setOccurrenceContent] = useState<string>()

  const canSubmit = !!occurrenceContent

  const handleSubmit = async () => {
    const params: RegisterHistoricOccurrenceParams = {
      servcOrdSeq: servcOrdCd,
      servcOrdOccurrenceDesc: occurrenceContent!,
    }

    const status = await registerHistoricOccurrence(params)

    if (status === HttpStatusCode.OK) {
      onClose(true)
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      onClose={() => onClose()}
      contentLabel='serviceOrder.historic.occurrences.newOccurrence.register'
      closeInOverlay={false}
    >
      <div id='new-occurrence-modal'>
        <div className='field'>
          <label htmlFor='occurrenceContent'>
            {i18next.t('serviceOrder.historic.occurrences.newOccurrence.occurrence')}
          </label>
          <MozaicTextArea
            id='occurrenceContent'
            placeholder={i18next.t(
              'serviceOrder.historic.occurrences.newOccurrence.enterOccurrence',
            )}
            rows={7}
            value={occurrenceContent}
            onChange={(e) => setOccurrenceContent((e.target as HTMLInputElement).value)}
          />
        </div>

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => onClose()}>
            {i18next.t('serviceOrder.historic.occurrences.newOccurrence.cancel')}
          </MozaicButton>
          <MozaicButton
            className='confirm-button'
            onClick={() => handleSubmit()}
            isDisabled={!canSubmit}
          >
            {i18next.t('serviceOrder.historic.occurrences.newOccurrence.confirm')}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}
