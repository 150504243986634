import { ReactNode } from 'react'
import classNames from 'classnames'

function BodySection({
  title,
  children,
  className = '',
}: {
  title: any
  children: ReactNode
  className?: string
}) {
  return (
    <div className={classNames('body-section', { [className]: !!className })}>
      <div className='info'>
        <div className='title'>{title}</div>

        <div className='details'>{children}</div>
      </div>
    </div>
  )
}

export default BodySection
