import PropTypes from 'prop-types'
import { Component } from 'react'
import copyToClipboard from '../../helpers/toClipboardText'

class CopyToClipboardIcon extends Component {
  render() {
    const { text, color = 'primary', t } = this.props

    return (
      <>
        {text && (
          <a className={`color-${color}`}>
            <i
              className='glyph glyph-clone'
              style={{ marginLeft: '4px' }}
              onClick={() => copyToClipboard(text, t)}
            />
          </a>
        )}
      </>
    )
  }
}

CopyToClipboardIcon.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
}

export default CopyToClipboardIcon
