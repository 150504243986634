import API_URL from '../core/Envs/endpoints'
import API from '../core/Http/FetchAdapter'
import store from '../redux/store'

import { getPages } from '../constants/pagination'
import { getRandomInt } from '../helpers/random'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'

export const fetchPaymentsServiceOrders = async (token, filters, page = 1) => {
  store.dispatch(showSpinner())

  const pagination = {
    page,
    size: 10,
  }

  const query = {
    ...filters,
    hasFilters: true,
  }

  const { auth } = store.getState()

  const url = auth.setupParameters.elasticEnabled
    ? API_URL.PAYMENTS_SERVICE_ORDER_ELASTIC_FIND
    : API_URL.PAYMENTS_SERVICE_ORDER_FIND

  try {
    const { metadata, data } = await new API(token, store.dispatch, pagination).post(url, query)

    store.dispatch(getPages(metadata))

    return { data, filter: query }
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const paymentServiceOrderRemove = async (token, serviceOrder, onSuccess) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.PAYMENTS_SERVICE_ORDER_REMOVE,
      serviceOrder,
    )

    onSuccess()

    return data
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const paymentServiceOrderSave = async (token, payload, onSuccess) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.PAYMENTS_SERVICE_ORDER_SAVE,
      payload,
    )

    onSuccess()

    return data
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.dispatch(hideSpinner())
  }
}

const setupNewEventSource = (notifierUrl, reportKey, callback) => {
  const responseFile = new Promise((resolve, reject) => {
    const eventSourceInitDict = { headers: { Cookie: `reportKey=${reportKey}` } }
    const eventSource = new EventSource(
      `${notifierUrl}?reportKey=${reportKey}`,
      eventSourceInitDict,
    )

    const onopen = (event) => {
      console.log('EventSource connected')
      console.log(event)
    }

    const onmessage = (event) => {
      if (event && event.data !== 'connected') {
        const response = JSON.parse(event.data)

        if (response) {
          store.dispatch(hideSpinner())
          eventSource.close()

          return resolve(response)
        }

        if (response && response.message) {
          store.dispatch(hideSpinner())
          eventSource.close()

          return reject(response)
        }
      }
    }

    const onerror = (e) => {
      if (e.type === 'error') {
        const eventSource = new EventSource(
          `${notifierUrl}?reportKey=${reportKey}`,
          eventSourceInitDict,
        )
        eventSource.onopen = onopen
        eventSource.onmessage = onmessage
        eventSource.onerror = onerror
      } else {
        console.log('EventSource error', e)
        store.dispatch(hideSpinner())
      }
    }

    eventSource.onopen = onopen
    eventSource.onmessage = onmessage
    eventSource.onerror = onerror
    callback()
  })

  return responseFile
}

export const importPaymentOcurrence = (token, params) => {
  store.dispatch(showSpinner())

  const reportKey = getRandomInt(1, 9999999)
  const query = {
    status: 200,
    ...params,
    reportKey,
  }

  return setupNewEventSource(API_URL.ENABLE_NOTIFIER, reportKey, () => {
    const url = API_URL.SERVC_PRVDR_IMPORT_PAYMENT_OCCURRENCE

    return new API(token, store.dispatch).post(url, query)
  })
}
