class BusinessUnitType {
  static LMPT = new BusinessUnitType(3, '10', 'business.unit.3', 'LMPT')

  static LMBR = new BusinessUnitType(7, '508', 'business.unit.7', 'LMBR')

  static LMES = new BusinessUnitType(2, '11', 'business.unit.2', 'LMES')

  static LMFR = new BusinessUnitType(1, '1', 'business.unit.1', 'LMFR')

  static LMIT = new BusinessUnitType(5, '5', 'business.unit.5', 'LMIT')

  buCd = 0

  cntryCd = ''

  translation = ''

  companyCode = ''

  constructor(buCd, cntryCd, translation, companyCode) {
    this.buCd = buCd || 0
    this.cntryCd = cntryCd || ''
    this.translation = translation || ''
    this.companyCode = companyCode || ''
  }

  static getBUByBuCd(buCd) {
    switch (buCd) {
      case BusinessUnitType.LMPT.buCd:
        return BusinessUnitType.LMPT
      case BusinessUnitType.LMBR.buCd:
        return BusinessUnitType.LMBR
      case BusinessUnitType.LMES.buCd:
        return BusinessUnitType.LMES
      case BusinessUnitType.LMFR.buCd:
        return BusinessUnitType.LMFR
      case BusinessUnitType.LMIT.buCd:
        return BusinessUnitType.LMIT
      default:
        return new BusinessUnitType()
    }
  }
}

export default BusinessUnitType
