import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { showSpinner, hideSpinner } from '../redux/actions/spinner'
import store from '../redux/store'

export const fetchBudgets = async (stores) => {
  store.dispatch(showSpinner())

  const query = stores !== undefined ? { plntCd: stores.map((store) => store.value) } : undefined

  try {
    const { data: budgets } = await new API(null, store.dispatch).get(
      API_URL.BUDGETS_DASHBOARD,
      query,
    )

    return budgets
  } catch (err) {
    return err
  } finally {
    store.dispatch(hideSpinner())
  }
}
