import i18next from 'i18next'

import FormField from '../../../../../../../../../../../components/FormField'
import { formatDecimal } from '../../../../../../../../../../../helpers/masks'
import { useServiceOrderDetailContext } from '../../../../../../../../../context'
import ServcOrdCategoryType from '../../../../../../../../../../../enums/ServiceOrder/category/serviceCategoryType'

type TableFooterType = {
  isCanceled: boolean
}

function TableFooter(props: TableFooterType) {
  const { isCanceled } = props
  const { orderDetails, serviceOrderBody, loadingBody, loadingDetails } =
    useServiceOrderDetailContext()

  const isFetching = loadingBody && loadingDetails

  const isWarrantyOrder =
    serviceOrderBody?.servcCatgryTypCd === parseInt(ServcOrdCategoryType.WARRANTY.id)

  const dashStar = isCanceled ? '*' : ''
  const servcOrdAmt = `${formatDecimal(isWarrantyOrder ? 0 : orderDetails?.servcOrdAmt)}${dashStar}`
  const servcOrdPrvdrPymtAmt = `${formatDecimal(orderDetails?.servcOrdPrvdrPymtAmt)}${dashStar}`

  return (
    <div id='detail-table-footer'>
      <div className='first-col custom'>
        <FormField
          isFetching={isFetching}
          label={i18next.t('estimated.total.time')}
          value={`${orderDetails?.slots || 0} slots`}
        />
      </div>

      <div className='second-col custom'>
        <div className='item-info'>
          <FormField
            isFetching={isFetching}
            label={i18next.t('total.sales.value')}
            value={servcOrdAmt}
            isBold
          />
        </div>
        <div className='item-info'>
          <FormField
            isFetching={isFetching}
            label={i18next.t('serviceOrders.detail.totalTransferAmount')}
            value={servcOrdPrvdrPymtAmt}
            isBold
          />
        </div>
      </div>
    </div>
  )
}

export default TableFooter
