import i18next from 'i18next'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import Select from 'react-select'

import Field from '../../../../components/utils/Field'
import { colourStyles } from '../../../../helpers/selectStyle'
import {
  fetchSpecialitiesIdsByProvider,
  updateProviderSpecialities,
} from '../../../../services/pages/ServiceProviderEdit/ProviderSpecialities'
import { Speciality, fetchSpecialities } from '../../../../services/specialities/fetchSpecialities'

type SelectProps = {
  providerId?: string
  disabled?: boolean
}

type optionType = { value: string; label: string }

const SpecialitiesSelect = forwardRef(({ providerId, disabled }: SelectProps, ref: any) => {
  const [loading, setLoading] = useState(false)
  const [specialities, setSpecialities] = useState<Speciality[]>([])
  const [specialitiesIds, setSpecialitiesIds] = useState<Speciality['specialityCd'][]>([])

  const handleFetchSpecialities = async () => {
    setLoading(true)

    await fetchSpecialities()
      .then((response) => {
        setSpecialities(response?.data || [])
      })
      .catch((error) => {
        console.error('error fetching specialities', error)
      })

    if (providerId) {
      await fetchSpecialitiesIdsByProvider(providerId)
        .then((response) => {
          setSpecialitiesIds(response?.data || [])
        })
        .catch((error) => {
          console.error('error fetching provider specialities', error)
        })
    }

    setLoading(false)
  }

  useEffect(() => {
    handleFetchSpecialities()
  }, [providerId])

  const handleChange = (newValue: any) => {
    const newSpecialities = newValue?.map((v: optionType) => v.value)

    setSpecialitiesIds(newSpecialities || [])
  }

  const options = useMemo(
    () =>
      specialities.map((speciality) => ({
        value: speciality.specialityCd,
        label: speciality.specialityNm,
      })),
    [specialities],
  )

  const placeholder = loading ? i18next.t('option.loading') : i18next.t('specialties')

  const selectedSpecialities = specialitiesIds?.map((value) => ({
    value,
    label: options.find((option) => option.value === value)?.label,
  }))

  useImperativeHandle(ref, () => ({
    save: (id: number) => {
      const payload = {
        servcPrvdrId: String(id || providerId),
        specialitiesIds: specialitiesIds || [],
      }

      updateProviderSpecialities(payload)
    },
    validate: () => true,
  }))

  return (
    <Field input={{ value: specialitiesIds }} validate>
      <label htmlFor='specialities-select' className='label dateInputLabel'>
        {placeholder}
      </label>
      <Select
        id='specialities-select'
        onChange={handleChange}
        placeholder={placeholder}
        options={options}
        value={selectedSpecialities || []}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        closeMenuOnSelect={false}
        styles={colourStyles}
        resetValue={[]}
        isDisabled={disabled}
        isClearable
        isMulti
      />
    </Field>
  )
})

export default SpecialitiesSelect
