import { getNumbersOnly } from './Toolbox'

let device

class TwilioUtils {
  static initialize(twilioAccessTokenUrl) {
    return this.getAccessToken(twilioAccessTokenUrl).then(this.setup)
  }

  static async getAccessToken(twilioAccessTokenUrl) {
    const response = await fetch(twilioAccessTokenUrl, { method: 'GET' })

    return response.json()
  }

  static setup(accessToken) {
    // eslint-disable-next-line no-undef
    device = new Twilio.Device(accessToken.token, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true,
    })

    device.on('ready', () => console.log('Device ready!'))

    device.on('error', (error) => console.error(`Device error: ${error.message}`))

    device.on('connect', () => console.log('Successfully established call'))

    device.on('disconnect', () => console.log('Call ended'))

    device.on('incoming', (conn) => {
      console.log(`Incoming connection from ${conn.parameters.From}`)

      conn.accept()
    })
  }

  static call(number) {
    if (device) {
      const formattedNumber = `+55${getNumbersOnly(number)}`

      console.log(`Calling ${formattedNumber}`)

      const params = { To: formattedNumber }

      device.connect(params).on('ringing', () => console.log('Ringing'))
    }
  }

  static destroy() {
    if (device) device.destroy()
  }

  static getTwilioAccessTokenUrl(setupParameters) {
    return setupParameters.twilioFunctionsUrl + setupParameters.twilioAccessTokenUrl
  }
}

export default TwilioUtils
