import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface PostalCodeInfo {
  IBGEcode: string
  city: string
  neighborhood: string
  postalCode: string
  state: string
  streetName: string
  supplement: string
}

export async function getPostalCodeInfo(postalCode: string): Promise<{ dne?: [PostalCodeInfo] }> {
  const filters = { postalCode: postalCode.replace('-', '') }

  return new API_REQUEST(user.dsToken, store.dispatch)
    .get(API_URL.PUBLIC_SERVICE_PROVIDER_POSTAL_CODE, filters)
    .then((response) => response.data)
    .catch((error) => console.error(error))
}
