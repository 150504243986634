import firestoreAdapter from '../firebase'

export default class FirestoreChat {
  private ref: any

  private collection: string

  constructor(collection: string) {
    this.collection = collection
  }

  getCollectionRef() {
    if (this.ref?.get) return this.ref

    this.ref = firestoreAdapter.db?.collection(this.collection)

    return this.ref
  }

  async getChatList() {
    const allRefs = await this.getCollectionRef().get()

    const allChats = allRefs.docs.map((doc: any) => doc.data())

    return allChats
  }

  async getChatRef(id: string) {
    const chatRef = await this.getCollectionRef().doc(id)
    const chat = chatRef.data()

    return chat
  }
}
