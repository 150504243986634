import User from '../core/User'
import ServcOrdCategoryType from '../enums/ServiceOrder/category/serviceCategoryType'
import ServcOrdEvntStusType from '../enums/ServiceOrder/event/ServcOrdEvntStusType'
import { HistoricOccurrence } from '../pages/ServiceOrderDetail/components/Routes/Historic/components/HistoricOccurrences/models/historic-occurrence'

export function isTechVisit(category: any) {
  return [
    ServcOrdCategoryType.TECHNICAL_VISIT.id,
    ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id,
  ].includes(category)
}

export function needTechReport(category: string) {
  return isTechVisit(category) || ServcOrdCategoryType.WARRANTY.id === category
}

export function isPlannedVisit(category: string) {
  return (
    category.toString() === ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id.toString()
  )
}

export function isTechVisitAllowed() {
  const user = new User().currentUser

  return (
    user?.allowedCategories.includes(Number(ServcOrdCategoryType.TECHNICAL_VISIT.id)) ||
    user?.allowedCategories.includes(Number(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id))
  )
}

export function checkMVFiles(occurrences: HistoricOccurrence[]) {
  const lastMvOcurrence = occurrences.find((occur) =>
    [
      ServcOrdEvntStusType.MEASUREMENT_SHEET_SENT.id,
      ServcOrdEvntStusType.MEASUREMENT_SHEET_REPROVED.id,
      ServcOrdEvntStusType.MEASUREMENT_SHEET_APPROVED.id,
    ].includes(occur.servcOrdEvntStusInd),
  )

  const filesWereSent = !!lastMvOcurrence

  const filesWereRejected =
    lastMvOcurrence &&
    lastMvOcurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.MEASUREMENT_SHEET_REPROVED.id

  const filesWereApproved =
    lastMvOcurrence &&
    lastMvOcurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.MEASUREMENT_SHEET_APPROVED.id

  return { filesWereSent, filesWereRejected, filesWereApproved, lastMvOcurrence }
}
