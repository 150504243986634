import PropTypes from 'prop-types'
import Mask from 'vanilla-masker'

const setPhoneMask = (text) => {
  switch (text.length) {
    case 11:
      return '(99) 99999-9999'

    case 10:
      return '(99) 9999-9999'

    case 9:
      return '99999-9999'

    default:
      return '9999-9999'
  }
}

function MaskedText({ maskType, text, rule }) {
  if (!text) {
    return <div />
  }

  let mask

  switch (maskType) {
    case 'phone':
      mask = setPhoneMask(text)
      break

    case 'cpf':
      mask = '999.999.999-99'
      break

    case 'cnpj':
      mask = '99.999.999/9999-99'
      break

    default:
      mask = rule
      break
  }

  const textMasked = Mask.toPattern(text, mask)

  return <div>{textMasked}</div>
}

MaskedText.propTypes = {
  maskType: PropTypes.string,
  text: PropTypes.string.isRequired,
  rule: PropTypes.string,
}

export default MaskedText
