import i18next from 'i18next'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from '../components/utils/Spinner'
import CacheUtils from '../helpers/CacheUtils'
import { allowAccess } from '../redux/actions/auth'
import Routes from '../routes/Routes'

function App({ auth: { setupParameters }, visibleSpinner, allowAccess }) {
  function handleCheckLoginMethod() {
    if (CacheUtils.needReset) {
      CacheUtils.needReset = false

      window.location.reload()
    }
  }

  const handleCheckAuthentication = () => {
    if (!_.isEmpty(setupParameters)) handleCheckLoginMethod()
  }

  useEffect(() => {
    document.title = `${i18next.t('app.name.services-portal')}`

    handleCheckAuthentication()
    allowAccess()
  }, [])

  const haveHotjarId = () => {
    if (setupParameters && setupParameters.hotjarId && setupParameters.hotjarId.length > 0) {
      const header = document.getElementsByTagName('head')[0]
      for (let i = 0, len = header.childNodes.length; i < len; i++) {
        if (
          header.childNodes[i] &&
          header.childNodes[i].src &&
          header.childNodes[i].src.indexOf('hotjar') > 0
        ) {
          return false
        }
      }
      return true
    }

    return false
  }

  const hasHotjarId = haveHotjarId()

  if (hasHotjarId) {
    hotjar.initialize(setupParameters.hotjarId, 6)
  }

  return (
    <>
      {Object.keys(setupParameters).length ? <Routes /> : <Spinner visible />}
      {visibleSpinner && <Spinner visible={visibleSpinner} />}
    </>
  )
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  visibleSpinner: PropTypes.bool.isRequired,
  allowAccess: PropTypes.func.isRequired,
}

function mapStateToProps({ auth, spinnerReducer }) {
  return {
    auth,
    visibleSpinner: spinnerReducer.visible,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ allowAccess }, dispatch)
}

const AppTranslated = withTranslation()(App)

export default connect(mapStateToProps, mapDispatchToProps)(AppTranslated)
