import i18next from 'i18next'
import Select from 'react-select'
import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../helpers/react-select-backyard-styles'

import { useDistributeContext } from '../../context'
import { ProviderType, optionType } from '../../../../../../../../models/Distribute'

type ProviderSelectType = {
  distributeMode: string
  onChange: Function
  value: optionType | null
  compareTodayDate: boolean
  compareDeliveryDate: boolean
}

function ProviderSelect(props: ProviderSelectType) {
  const { providers, showProvidersHint, fetchServiceInstallers } = useDistributeContext()
  const { onChange, value, distributeMode, compareTodayDate, compareDeliveryDate } = props

  const handleChange = (e: any) => {
    onChange(e)
    fetchServiceInstallers(e)
  }

  const disabled =
    !providers.length || distributeMode !== 'manual' || !(compareTodayDate && compareDeliveryDate)

  const label = i18next.t('bankFilter.nomePrestadorServico')
  const placeholder = i18next.t('serviceOrders.provider.select.placeholder')
  const errorHint = i18next.t('select.noOptionsMessage')

  const disabledClass = disabled ? '--disabled' : ''
  const hasContentClass = value ? '--has-content' : ''

  return (
    <div className={`backyard-select-v2 ${hasContentClass} ${disabledClass}`}>
      <label className='backyard-select-label'>
        {label} {showProvidersHint && <label className='error-hint'>- {errorHint}</label>}
      </label>

      <Select
        value={value as any}
        options={providers}
        isOptionDisabled={(option: ProviderType) => option.disabled}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={placeholder}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
      />
    </div>
  )
}

export default ProviderSelect
