import i18next from 'i18next'
import { ClearIcon } from '../../../../../../../../helpers/react-select-backyard-styles'
import MozaicTextArea from '../../../../../../../../components/MozaicTextArea'
import MozaicText from '../../../../../../../../components/MozaicText'

type JustifyInputType = {
  onChange: Function
  value: string
}

function JustifyInput(props: JustifyInputType) {
  const { onChange, value } = props

  const errorHint = value.length < 10

  const label = i18next.t('serviceOrders.reason')
  const errorHintLabel = i18next.t('modal.approveOutOfFlowRequest.justify.hint')
  const placeholder = i18next.t('justificationModal.desc.placeholder')

  const isValid = !errorHint && !!value.length
  const isInvalid = errorHint

  const handleClear = () => {
    onChange({ target: { value: '' } })
  }

  const suffixIcons = () => (
    <>
      {value && (
        <div className='clear-filter' onClick={handleClear}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  const labelJustify = i18next
    .t('serviceOrders.change.os.to.justify')
    .replace('{status}', i18next.t('serviceOrders.provider.scheduled').toLowerCase())
    .replace('?', '.')

  return (
    <div className='justify-input'>
      <MozaicText> {labelJustify} </MozaicText>
      <MozaicTextArea
        label={label}
        value={value}
        isValid={isValid}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
      />
      {suffixIcons()}

      {errorHint && <label className='error-hint'>{errorHintLabel}</label>}
    </div>
  )
}

export default JustifyInput
