import PropTypes from 'prop-types'
import classNames from 'classnames'
import MozaicIcon from '../MozaicIcon'

function FeedbackError({ message, className, text }) {
  const componentClass = classNames({ [className]: className })

  return (
    <div className={componentClass}>
      <div className='feedback-error-icon'>
        <MozaicIcon icon='ControlTagCross24' />
        <div>{message}</div>

        {text && <div>{text}</div>}
      </div>
    </div>
  )
}

FeedbackError.propTypes = {
  message: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
}

export default FeedbackError
