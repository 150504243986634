import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { SORT_BY, SORT_BY_ELASTIC } from '../../../constants/config'
import store from '../../../redux/store'
import { SortStateType, StorageSortType, optionType } from '../models/sort'

function SortState(storageFilters: StorageSortType) {
  const setupParameters = store.getState().auth.setupParameters
  const field = setupParameters.elasticEnabled ? 'servc_ord_schdlg_ts' : 'so.servcOrdSchdlgTs'
  const resource = setupParameters.elasticEnabled ? SORT_BY_ELASTIC : SORT_BY
  const value = field as keyof typeof resource
  const defaultColumn = { value, label: i18next.t(resource[value]) }

  const [column, setColumn] = useState<optionType | null>(defaultColumn)
  const [direction, setDirection] = useState<string>('ascending')

  useEffect(() => {
    if (storageFilters) {
      const { orderBy, descAsc } = storageFilters
      const _field = (orderBy || field) as keyof typeof resource

      setColumn({ value: _field, label: i18next.t(resource[_field]) })
      setDirection(descAsc === 'ASC' ? 'ascending' : 'descending')
    }
  }, [storageFilters])

  const getDefaultState = () => ({
    orderBy: field,
    descAsc: 'ASC',
  })

  const getCurrentState = () => ({
    orderBy: column?.value || '',
    descAsc: direction === 'ascending' ? 'ASC' : 'DESC',
  })

  const clearState = () => {
    setColumn(defaultColumn)
    setDirection('ascending')
  }

  return {
    column,
    direction,
    setColumn,
    setDirection,
    getCurrentState,
    getDefaultState,
    clearState,
  } as SortStateType
}

export default SortState
