import i18next from 'i18next'
import { FormEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react'

import { IconButton, TextField } from '@leroy-merlin-br/backyard-react'
import MozaicButton from '../../../../../../components/MozaicButton'

import InputSwitch from '../../../../../../components/utils/InputSwitch'

import ReceivedMessage from './components/ReceivedMessage'
import SendedMessage from './components/SendedMessage'
import SeparatorByDay from './components/SeparatorByDay'

import { USER_TYPE } from '../../../../../../constants/config'
import { Conversation, MessageProps } from '../../../../../../contexts/customerChat/models'

import moment from 'moment-timezone'
import MozaicIcon from '../../../../../../components/MozaicIcon'
import { getOsRoutePrefix } from '../../../../../../helpers/serviceOrderUtils'
import { WithContext } from './context'

type ClientChatType = {
  isOSDetail?: boolean
  chatRef: any
  chatId: string
  updateChat: Function
  backToList: Function
  resolveChat: Function
  setAsReadedChat: Function
}

function customGroupBy(messages: MessageProps[], key: string) {
  return messages?.reduce((acc: any, arr: any) => {
    ;(acc[moment(arr[key]).format('L')] = acc[moment(arr[key]).format('L')] || []).push(arr)
    return acc
  }, {})
}

function ClientChat(props: ClientChatType) {
  const { isOSDetail, chatRef, chatId, updateChat, backToList, resolveChat, setAsReadedChat } =
    props

  const [newMessage, setNewMessage] = useState('')
  const [wasSolved, setWasSolved] = useState(false)
  const [conversation, setConversation] = useState<Conversation | null>(null)

  const groupedMessages = useMemo(
    () => customGroupBy(conversation?.messageHistory as MessageProps[], 'createdAt'),
    [conversation?.messageHistory],
  )

  useEffect(() => {
    chatRef.doc(String(chatId)).onSnapshot((doc: any) => setConversation(doc.data()))
  }, [])

  useEffect(() => {
    setAsReadedChat(chatId)
  }, [conversation])

  function scrollMessages() {
    const chatArea = document.querySelector('#client-chat-area') as HTMLElement

    if (chatArea) return chatArea.scrollTo({ behavior: 'smooth', top: chatArea.scrollHeight })
  }

  useEffect(() => {
    scrollMessages()
  }, [conversation, groupedMessages])

  function scrollCockpit() {
    const cockPitContainer = document.querySelector('#cockpit-container.small.vertical')

    if (cockPitContainer)
      return window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })

    return window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function setInputFocus() {
    const inputText = document.querySelector('#client-chat-text-field') as HTMLElement

    if (inputText) inputText.focus()
  }

  useEffect(() => {
    if (!isOSDetail) {
      setTimeout(() => setInputFocus(), 300)
      scrollCockpit()
    } else {
      setTimeout(() => setInputFocus(), 300)
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }
  }, [])

  function resolveConversation() {
    resolveChat(conversation as Conversation, !conversation?.solved)
    setWasSolved(!conversation?.solved)
  }

  function handleSendNewMessage() {
    const _conversation = {
      ...conversation,
      solved: wasSolved,
    }
    updateChat(_conversation as Conversation, newMessage)
    setNewMessage('')
    setWasSolved(false)
    setInputFocus()
  }

  function handleSetNewMessage(event: FormEvent<HTMLInputElement>) {
    setNewMessage((event.target as HTMLInputElement).value)
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && newMessage) {
      handleSendNewMessage()
    }
  }

  function handleClearSearchedText() {
    setNewMessage('')
  }

  const suffixIcon = () => (
    <>
      {newMessage !== '' && (
        <div className='clear-filter' onClick={handleClearSearchedText}>
          <MozaicIcon icon='ControlCross16' />
        </div>
      )}
    </>
  )

  const messagesEmpty = !conversation?.messageHistory.length
  const urlPrefix = useMemo(() => getOsRoutePrefix(), [])

  return (
    <div id='client-chat-container'>
      {!isOSDetail && (
        <header>
          <MozaicButton
            className='arrow-button-icon'
            leftIcon={<MozaicIcon icon='ArrowArrowLeft16' />}
            onClick={() => backToList()}
            variant='bordered'
          >
            {i18next.t('back_button_label')}
          </MozaicButton>

          <div className='divider' />

          <div className='order-details'>
            <a
              rel='noreferrer'
              href={`${urlPrefix}/${conversation?.servcOrdCd}`}
              target='_blank'
              className='service-order-code'
            >
              {conversation?.servcOrdCd || '-'}
            </a>
            <span className='client-name'> {conversation?.custFullNm || '-'}</span>
          </div>
        </header>
      )}

      <section className='chat-area' id='client-chat-area'>
        {messagesEmpty && i18next.t('firestore.chat.has.no.messages')}
        {groupedMessages &&
          Object.entries(groupedMessages).map(([date, listMessages]) => (
            <>
              <SeparatorByDay title={date} />

              {(listMessages as MessageProps[]).map((messageItem: MessageProps) =>
                messageItem.userType === USER_TYPE.CUSTOMER ? (
                  <ReceivedMessage message={messageItem} key={messageItem.createdAt} />
                ) : (
                  <SendedMessage message={messageItem} key={messageItem.createdAt} />
                ),
              )}
            </>
          ))}
      </section>

      <section className='chat-footer'>
        <div className='send-message'>
          <div className='inputs-actions'>
            <TextField
              id='client-chat-text-field'
              hasSuffix
              renderSuffix={suffixIcon}
              placeholder={i18next.t('client.chat.submit.input.placeholder')}
              value={newMessage}
              onChange={handleSetNewMessage}
              onKeyDown={handleKeyDown}
            />

            <div className='resolve-conversation'>
              <span>{i18next.t('client.chat.solved.conversation.text')}</span>
              <InputSwitch checked={conversation?.solved} onChange={resolveConversation} />
            </div>
          </div>

          <IconButton label='send' onClick={handleSendNewMessage} disabled={!newMessage}>
            <MozaicIcon icon='PublishSend24' className='send-icon' mt={3} />
          </IconButton>
        </div>
      </section>
    </div>
  )
}

export default WithContext(ClientChat)
