class ServcOrdAllStates {
  static FLUX_CLIENT_PRE_SCHEDULE = new ServcOrdAllStates('00', 'serviceOrders.preScheduled')

  static FLUX_CLIENT_SCHEDULE = new ServcOrdAllStates('01', 'serviceOrders.scheduled')

  static FLUX_CLIENT_DISTRIBUTED = new ServcOrdAllStates('02', 'serviceOrders.distributed')

  static FLUX_CLIENT_ASSOCIATED = new ServcOrdAllStates('03', 'serviceOrders.associated')

  static FLUX_CLIENT_CONFIRMED = new ServcOrdAllStates('04', 'serviceOrders.confirmed')

  static FLUX_CLIENT_DOING = new ServcOrdAllStates('05', 'serviceOrders.doing')

  static FLUX_CLIENT_DONE = new ServcOrdAllStates('06', 'serviceOrders.done')

  static FLUX_CLIENT_PARTIALLY_DONE = new ServcOrdAllStates('07', 'serviceOrders.partiallyDone')

  static FLUX_CLIENT_CLOSED = new ServcOrdAllStates('08', 'serviceOrders.closed')

  static FLUX_CLIENT_CANCELED = new ServcOrdAllStates('09', 'serviceOrders.canceled')

  static FLUX_CLIENT_NOT_ACCORDINGLY = new ServcOrdAllStates('10', 'serviceOrders.notAccordingly')

  static FLUX_STORE_WAITING = new ServcOrdAllStates('11', 'serviceOrders.waiting')

  static FLUX_STORE_DOING = new ServcOrdAllStates('12', 'serviceOrders.doing')

  static FLUX_STORE_DONE = new ServcOrdAllStates('13', 'serviceOrders.done')

  static FLUX_STORE_CLOSED = new ServcOrdAllStates('14', 'serviceOrders.closed')

  static OUT_OF_FLOW = new ServcOrdAllStates('15', 'serviceOrders.outofflow')

  static FLUX_STORE_CANCELED = new ServcOrdAllStates('09', 'serviceOrders.canceled')

  static FLUX_PRVDR_PRE_SCHEDULE = new ServcOrdAllStates('16', 'serviceOrders.preScheduled')

  static FLUX_PRVDR_SCHEDULE = new ServcOrdAllStates('17', 'serviceOrders.scheduled')

  static FLUX_PRVDR_DISTRIBUTED = new ServcOrdAllStates('18', 'serviceOrders.distributed')

  static FLUX_PRVDR_ASSOCIATED = new ServcOrdAllStates('19', 'serviceOrders.associated')

  static FLUX_PRVDR_CONFIRMED = new ServcOrdAllStates('20', 'serviceOrders.confirmed')

  static FLUX_PRVDR_DOING = new ServcOrdAllStates('21', 'serviceOrders.doing')

  static FLUX_PRVDR_DONE = new ServcOrdAllStates('22', 'serviceOrders.done')

  static FLUX_PRVDR_CANCELED = new ServcOrdAllStates('09', 'serviceOrders.canceld')

  static FLUX_PRVDR_CLOSED = new ServcOrdAllStates('24', 'serviceOrders.closed')

  id = ''

  value = ''

  constructor(id, value) {
    this.id = id || ''
    this.value = value || ''
  }
}

export default ServcOrdAllStates
