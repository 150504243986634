import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export async function getServiceOrderDetail(servcOrdSeq: number) {
  const filters = { servcOrdCd: servcOrdSeq }

  return new API_REQUEST(user.dsToken, store.dispatch)
    .get(API_URL.SERVICE_ORDER_FIND, filters)
    .then((response) => response?.data || [])
    .catch((error) => console.error(error))
}
