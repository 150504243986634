import i18next from 'i18next'

import Accordeon from '../../../../../../../../components/Accordeon'
import ProviderSelect from './components/ProviderSelect'
import InstallerSelect from './components/InstallerSelect'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { ProviderBoxType } from '../../models/provider'
import { WithContext } from './context'

function ProviderBox({
  provider,
  setProvider,
  installer,
  setInstaller,
  showAllDetails,
  handleToggle,
}: ProviderBoxType) {
  const title = {
    title: i18next.t('advanced.search.provider.information.title'),
    icon: <MozaicIcon icon='PartnerWorker24' className='icon-custom' />,
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  return (
    <div className='advanced-search-provider-box'>
      <Accordeon header={title} styled={false} onToggle={handleToggle} defaultOpen={showAllDetails}>
        <div className='input-group'>
          <ProviderSelect value={provider} onChange={setProvider} />
          <InstallerSelect value={installer} onChange={setInstaller} />
        </div>
      </Accordeon>
    </div>
  )
}

export default WithContext(ProviderBox)
