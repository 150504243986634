import { onFetchUserError, onFetchUserSuccess, requestLogin } from '.'
import API_URL from '../../../core/Envs/endpoints'
import ApiRequest from '../../../core/Http/FetchAdapter'
import { showSpinner } from '../spinner'

export const loginUser =
  ([{ dsLogin, dsPassword }]) =>
  (dispatch) => {
    dispatch(requestLogin())
    const path = localStorage?.getItem('lastRouteAccessed') || '/cockpit'

    return new ApiRequest()
      .post(API_URL.LOGIN, { dsLogin, dsPassword })
      .then(dispatch(showSpinner()))
      .then((response) => onFetchUserSuccess(response, dispatch, path))
      .catch((error) => onFetchUserError(error, dispatch))
  }
