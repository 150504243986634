import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'

import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { colourStyles } from '../../../helpers/selectStyle'

class BranchSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      branchs: [],
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_STORES)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(branchs) {
    const { loadBranch, t, onlyBranchs, optionsData } = this.props
    const options = branchs.map((branch) => ({
      value: branch.id,
      label: branch.name,
    }))

    if (loadBranch) {
      loadBranch(options)
    }

    !onlyBranchs && options.unshift({ value: 'ALL', label: t('communicated.group.label.all') })

    optionsData && options.unshift(optionsData)

    optionsData && options.unshift(optionsData)

    this.setState({ branchs: options, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, branchs: [] })
  }

  render() {
    const {
      id,
      name,
      value,
      onChange,
      t,
      disabled = false,
      notMultiple,
      label,
      allOption,
      isClearable,
      closeMenuOnSelect,
    } = this.props
    const { branchs, loading } = this.state

    let placeholder = loading ? t('option.loading') : t('serviceProviders.stores')
    placeholder = label ? t(label) : placeholder

    return (
      <div>
        {disabled && <label className='label'>{placeholder}</label>}
        <Select
          id={id}
          name={name}
          value={disabled ? allOption : value}
          onChange={(event) => onChange(event)}
          placeholder={' '}
          options={allOption || branchs}
          isClearable={isClearable}
          resetValue={[]}
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          isMulti={!notMultiple}
          closeMenuOnSelect={closeMenuOnSelect}
          classNamePrefix='react-select'
          styles={colourStyles}
          isDisabled={disabled}
        />
        {!disabled && (
          <label htmlFor={id} className='label branch-input-label'>
            {placeholder}
          </label>
        )}
      </div>
    )
  }
}

BranchSelect.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  notMultiple: PropTypes.bool,
  label: PropTypes.string,
  loadBranch: PropTypes.func,
  allOption: PropTypes.bool,
  onlyBranchs: PropTypes.bool,
  optionsData: PropTypes.array,
  closeMenuOnSelect: PropTypes.bool,
  isClearable: PropTypes.bool,
}

export default BranchSelect
