import i18next from 'i18next'
import Select from 'react-select'
import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../helpers/react-select-backyard-styles'
import { useRescheduleContext } from '../../context'

type PeriodSelectType = {
  selectedDate: any
  onChange: (value: any) => void
  value: any
  compareTodayDate: boolean
  compareDeliveryDate: boolean
}

function PeriodSelect(props: PeriodSelectType) {
  const { value, onChange, selectedDate, compareTodayDate, compareDeliveryDate } = props
  const { periods } = useRescheduleContext()

  const label = i18next.t('customer.service.order.reschedule.shift')
  const disabled = !selectedDate || !(compareTodayDate && compareDeliveryDate)
  const hasSelectedPeriod = value ? 'hasSelectedPeriod' : ''

  return (
    <div className={`period-select-distribute ${hasSelectedPeriod}`}>
      <label className='period-select-label'>{label}</label>

      <Select
        placeholder={label}
        onChange={onChange}
        value={value}
        options={periods}
        resetValue={[]}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        isDisabled={disabled}
      />
    </div>
  )
}

export default PeriodSelect
