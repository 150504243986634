import { memo, useContext, useMemo } from 'react'
import i18next from 'i18next'

import CounterContainer from '../../../../../../../../components/Counter'

import { FirestoreContext } from '../../../../../../../../contexts/customerChat'
import { FirestoreStore, Conversation } from '../../../../../../../../contexts/customerChat/models'

import { getDayAndMonth, getHourAndMinutes } from '../../../../../../../../helpers/format'
import { capitalizeString } from '../../../../../../../../helpers/capitalize'

import { MESSAGE_TYPE, USER_TYPE } from '../../../../../../../../constants/config'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { getOsRoutePrefix } from '../../../../../../../../helpers/serviceOrderUtils'

interface ConversationItemProps {
  conversation: Conversation
}

function ConversationItem(props: ConversationItemProps) {
  const { conversation } = props

  const { handleSetClientConversation } = useContext(FirestoreContext) as FirestoreStore

  function handleRenderMessageContent() {
    switch (conversation?.lastMessage?.type) {
      case MESSAGE_TYPE.IMAGE:
        return `📷 ${i18next.t('pwa.image')}`
      default:
        return conversation?.lastMessage?.message
    }
  }

  const urlPrefix = useMemo(() => getOsRoutePrefix(), [])

  return (
    <div id='conversation-item-container' className='conversation-item-container'>
      <header>
        <a
          rel='noreferrer'
          href={`${urlPrefix}/${conversation?.servcOrdCd}`}
          target='_blank'
          className='service-order-code'
        >
          {conversation?.servcOrdCd || '-'}
        </a>
        <div onClick={() => handleSetClientConversation(conversation?.servcOrdCd)}>
          <span className='date'>{getDayAndMonth(conversation?.lastMessage?.createdAt)}</span>
          <span className='hours'>{getHourAndMinutes(conversation?.lastMessage?.createdAt)}</span>
        </div>
      </header>

      <section
        className='body'
        onClick={() => handleSetClientConversation(conversation?.servcOrdCd)}
      >
        <span className='client-name'>{conversation?.custFullNm || '-'}</span>
        <span className='store-name'>
          {capitalizeString(conversation?.plntNm?.replace('LOJA', '')) || '-'}
        </span>
      </section>

      <div
        className='item-bottom'
        onClick={() => handleSetClientConversation(conversation?.servcOrdCd)}
      >
        <div>
          {conversation?.lastMessage?.isRead &&
            conversation?.lastMessage?.userType === USER_TYPE.BACKOFFICE && (
              <span className='readed-icon'>
                <MozaicIcon icon='NotificationAvailable16' className='readed-icon' />
                <MozaicIcon icon='NotificationAvailable16' className='readed-icon' ml={-12} />
              </span>
            )}
          {conversation?.lastMessage?.isRead === false &&
            conversation?.lastMessage?.userType === USER_TYPE.BACKOFFICE && (
              <span className='unreaded-icon'>
                <MozaicIcon icon='NotificationAvailable16' />
              </span>
            )}
          <div className='last-message'>
            <span className='text'>{handleRenderMessageContent()}</span>
          </div>
        </div>
        {conversation?.solved === false && <CounterContainer text='!' theme='danger' />}
      </div>
    </div>
  )
}

export default memo(ConversationItem)
