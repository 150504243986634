import i18next from 'i18next'
import { Toggle } from '@mozaic-ds/react'

import {
  ACTV_SERVC_PRVDR_IND_TYPES,
  ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES,
  YES_NO,
} from '../../../../../../constants/config'
import User from '../../../../../../core/User'

import './index.scss'

const user = new User()

const FUNCTION_ACCESS_TO_DISABLE_SERVICE_ORDER_RECEIPT = 133

interface ActiveRegistrationRowProps {
  hasEditPermission: boolean
  isServiceOrderReceiptActive: '0' | '1'
  isEnabled: boolean
  onToggle: () => void
}

/**
 * @description Enable/Disable Service Order Receipt row component for Service Providers List V2 page
 */

export function ServiceOrderReceiptRow({
  hasEditPermission,
  isServiceOrderReceiptActive,
  isEnabled,
  onToggle,
}: ActiveRegistrationRowProps) {
  const hasDisablePermission = user?.currentUser.accessFunctionList?.includes(
    FUNCTION_ACCESS_TO_DISABLE_SERVICE_ORDER_RECEIPT,
  )

  return (
    <div id='providers-service-order-receipt-row' data-testid='providers-service-order-receipt-row'>
      <>
        {hasEditPermission || hasDisablePermission ? (
          <>
            {isEnabled ? (
              <Toggle
                isChecked={
                  isServiceOrderReceiptActive === ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES.ACTIVE
                }
                isDisabled={
                  isServiceOrderReceiptActive === ACTV_SERVC_PRVDR_IND_TYPES.INACTIVE &&
                  hasDisablePermission &&
                  !hasEditPermission
                }
                id='actvServcPrvdrOrdRecvInd'
                data-testid='actvServcPrvdrOrdRecvInd'
                onChange={onToggle}
                size='s'
                title={!hasEditPermission ? i18next.t('useraccess.not.allowed') : undefined}
              />
            ) : (
              i18next.t(YES_NO[isServiceOrderReceiptActive])
            )}
          </>
        ) : (
          i18next.t(YES_NO[isServiceOrderReceiptActive])
        )}
      </>
    </div>
  )
}
