import i18next from 'i18next'
import moment from 'moment-timezone'
import { useServiceOrderDetailContext } from '../../../../../../context'
import { ClearIcon } from '../../../../../../../../helpers/react-select-backyard-styles'

import { _shifts as shifts } from '../../../../../../../../models/Distribute'
import MozaicTextArea from '../../../../../../../../components/MozaicTextArea'

type JustifyInputType = {
  selectedPeriod: any
  selectedDate: any
  onChange: Function
  value: string
}

function JustifyInput(props: JustifyInputType) {
  const { onChange, value, selectedDate, selectedPeriod } = props
  const { serviceOrderBody }: any = useServiceOrderDetailContext()

  const shiftCd = shifts[serviceOrderBody?.idSlotApplication as keyof typeof shifts]
  const currentSchedule = moment(serviceOrderBody?.servcOrdSchdlgTs).startOf('day')

  const disabled = +currentSchedule === +selectedDate && shiftCd === selectedPeriod?.value

  const label = i18next.t('serviceOrders.reason')
  const placeholder = i18next.t('justificationModal.desc.placeholder')

  const handleClear = () => {
    if (!disabled) onChange({ target: { value: '' } })
  }

  const suffixIcons = () => (
    <>
      {value && (
        <div className='clear-filter' onClick={handleClear}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  return (
    <div className='justify-input'>
      <MozaicTextArea
        id='justify'
        htmlFor='justify'
        label={label}
        value={value}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={(e) => onChange(e)}
      />
      {suffixIcons()}
    </div>
  )
}

export default JustifyInput
