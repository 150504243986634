import i18next from 'i18next'
import BreadcrumbBar from '../../components/BreadcrumbBar'

import Filter from './components/Filter'
import Table from './components/Table'
import TitleExport from './components/TitleExport'

import { UsableAreaProviderConnected } from '../../contexts/usableArea'

function ProviderAnalysisList() {
  return (
    <>
      <BreadcrumbBar title={i18next.t('lmi.provider.analysis.menu')} />

      <UsableAreaProviderConnected>
        <div id='provider-analysis-list-container'>
          <Filter />

          <TitleExport />

          <Table />
        </div>
      </UsableAreaProviderConnected>
    </>
  )
}

export default ProviderAnalysisList
