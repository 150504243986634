import PropTypes from 'prop-types'
import { Component } from 'react'
import pdfIcon from '../../../assets/icons/icone-pdf.svg'
import xlsIcon from '../../../assets/icons/icone-xls.svg'
import loading from '../../../assets/icons/loading.svg'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import User from '../../../core/User'
import { getExtension } from '../../../helpers/Toolbox'
import store from '../../../redux/store'

const pdfExtensions = ['pdf']
const xlsExtensions = ['csv', 'xls', 'xlsx']

const user = new User().currentUser

class AwsImage extends Component {
  state = {
    thumb: false,
  }

  componentDidMount() {
    this.renderImage()
  }

  getFile = async (token, filePath) => {
    let base64 = ''
    await new Api(user.dsToken, store.dispatch).get(API_URL.AMAZON_AWS_GET_FILE, { filePath }).then(
      (response) => {
        base64 = response.data
        return response.data
      },
      (error) => {
        console.error('Faleid base64 image', error)
        return ''
      },
    )
    return base64
  }

  renderImage = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const src = this.props.src ? this.props.src : null
    const { filePath, token, dispatch } = this.props

    const ext = getExtension(filePath).toLowerCase()

    let thumb = ''
    if (pdfExtensions.includes(ext)) {
      thumb = pdfIcon
    } else if (xlsExtensions.includes(ext)) {
      thumb = xlsIcon
    } else {
      const base64 = await this.getFile(token, filePath, dispatch)
      thumb = `data:image;base64, ${base64}`
      if (!base64 && src) {
        thumb = src
      }
    }
    this.setState({ thumb })
  }

  render() {
    const { className, alt, handleImageLoad, imageRerefence } = this.props
    const { thumb } = this.state
    return (
      <img
        loading='lazy'
        className={className}
        src={thumb || loading}
        alt={alt || 'Image'}
        onClick={() => imageRerefence(thumb)}
        onLoad={() => handleImageLoad?.()}
      />
    )
  }
}

AwsImage.propTypes = {
  filePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  token: PropTypes.string.isRequired,
  alt: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  handleImageLoad: PropTypes.func,
  imageRerefence: PropTypes.func,
}

export default AwsImage
