import classnames from 'classnames'
import PropTypes from 'prop-types'
import MenuLink from '../MenuLink'
import SubMenuItems from '../SubMenuItems'

function MenuItem({ item, onClickMenuItem, onClickSubmenuItem, isOpen, currentMenuLabel }) {
  const hasSubmenu = !!item?.submenus?.length
  const isActiveItem =
    item.label === currentMenuLabel ||
    !!item.submenus?.some((submenu) => submenu.label === currentMenuLabel)
  const isSubmenuOpen = item.isSubMenuOpen && isOpen

  const clickSubmenuItem = (item) => {
    onClickSubmenuItem(item)
  }

  const scrollToMenuItem = (target) => {
    if (hasSubmenu) {
      if (!isOpen) {
        setTimeout(() => {
          target.scrollIntoView({
            behavior: 'smooth',
          })
        }, 400)
      } else {
        setTimeout(() => {
          target.scrollIntoView({
            behavior: 'smooth',
          })
        }, 50)
      }
    }
  }

  const clickMenuItem = (item, e) => {
    onClickMenuItem(item)
    scrollToMenuItem(e.currentTarget)
  }

  return (
    <>
      <li key={item.label} title={item.label} className='menu-item-wrapper'>
        <div
          className={classnames('menu-item', {
            'is-active': isActiveItem,
            'submenu-is-open': isSubmenuOpen && hasSubmenu,
          })}
          onClick={(e) => clickMenuItem(item, e)}
        >
          <MenuLink item={item} isOpen={isOpen} />
        </div>
      </li>
      {!!hasSubmenu && (
        <SubMenuItems
          isSubMenuOpen={isSubmenuOpen}
          subMenuItems={item.submenus}
          onClickSubmenuItem={clickSubmenuItem}
          currentMenuLabel={currentMenuLabel}
        />
      )}
    </>
  )
}

MenuItem.propTypes = {
  item: PropTypes.object,
  onClickMenuItem: PropTypes.func.isRequired,
  onClickSubmenuItem: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentMenuLabel: PropTypes.string.isRequired,
}

export default MenuItem
