import PropTypes from 'prop-types'
import { Component } from 'react'

/*
 * Esse componente é um High Order Component para centralizar as lógicas comuns
 * dos formulário.
 */
const FormHOC =
  (options = {}) =>
  (ComposedComponent) => {
    const { fields = [], validate = () => {} } = options

    return class Form extends Component {
      static propTypes = {
        error: PropTypes.object,
      }

      constructor(props) {
        super(props)

        this.state = {
          fields: this.defineFields(props),
          values: this.defineValues(props),
          changed: false,
        }
      }

      /*
       * Cria um objeto de acordo com initial values, por exemplo:
       * - Caso initialValues seja vazio, será criado um objeto de fields com
       *   valores em branco, como: { username: '' }
       * - Caso initialValues tenha keys, será criado um objeto com os valores
       */
      defineFields({ initialValues = {} }) {
        const reducer = (obj, name) => ({
          ...obj,
          [name]: {
            input: {
              name,
              onChange: this.handleChange(name),
              onBlur: this.handleBlur(name),
              value: initialValues[name],
            },
            error: null,
            touched: false,
            initialValue: initialValues[name],
          },
        })

        return fields.reduce(reducer, {})
      }

      defineValues({ initialValues = {} }) {
        const reducer = (obj, name) => ({
          ...obj,
          [name]: initialValues[name],
        })

        return fields.reduce(reducer, {})
      }

      /*
       * Quando o componente receber props será verificado se tem erro no formulário.
       * - caso tenha erro, será verficado se tem erros em algum campo.
       * - caso tenha erro em algum campo, será setado no estado do fieldErrors qual
       *   campo está com erro e a descrição do erro.
       * - caso não tenha erro no campo, como por exemplo: usuário não encontrado,
       *   os erros nos campos serão limpos e o formulário irá ser resetado.
       */
      static getDerivedStateFromProps(props, state) {
        const { error } = props

        if (!error) {
          return null
        }

        const { fields } = state

        if (error.fields) {
          const reducer = (obj, field) => ({
            ...obj,
            [field.naField]: {
              ...fields[field.naField],
              error: field.dsDetail,
            },
          })

          const newState = error.fields.reduce(reducer, {})

          return { fields: { ...fields, ...newState } }
        }

        return null
      }

      componentDidUpdate(prevProps) {
        const { error } = this.props

        if (!error) {
          return
        }

        if (!error.fields && prevProps.error !== null && error.fields !== prevProps.error.fields) {
          this.resetForm()
        }
      }

      componentWillUnmount() {
        this.resetForm()
      }

      /*
       * Acão de alteração do input
       * Quando houver alguma alteração em algum input ocorrer, essa funcão será
       * chamada e ira adiconar o valor do evento no fields do form.
       */
      handleChange = (key) => (event) => {
        const { fields, values } = this.state
        const value = this.setValue(event.target)

        this.setState({
          fields: {
            ...fields,
            [key]: {
              ...fields[key],
              input: { ...fields[key].input, value },
              error: null,
            },
          },
          changed: true,
          values: { ...values, [key]: value },
        })
      }

      handleBlur = (key) => (event) => {
        const { fields } = this.state
        const field = fields[key]
        const error = validate(key, field.input.value || event.target.value)

        this.setState((prev) => ({
          ...prev,
          fields: {
            ...prev.fields,
            [key]: { ...prev.fields[key], touched: true, error },
          },
        }))
      }

      /*
       * Seta valor do target de acordo com type
       */
      setValue = (target) => {
        // Caso não seja um checkbox, retorna o valor do input
        if (target.type !== 'checkbox') {
          return target.value
        }

        // Caso seja um checkbox, retorna 1 se for estiver marcado ou 0 para
        // desmarcado
        return target.checked
      }

      /*
       * Limpa os campos do formulário
       */
      resetForm = (event) => {
        if (event) {
          event.preventDefault()
        }

        this.setState({
          fields: this.defineFields(this.props),
          values: this.defineValues(this.props),
        })
      }

      /*
       * Seta um valor para o field
       * @param {String} field = campo que receberá o valor
       * @param {String} value = valor do campo
       */

      fieldBatchUpdate = (fieldsValues) => {
        const { fields } = this.state

        const batchFields = Object.entries(fieldsValues).reduce((reduced, [field, value]) => {
          // eslint-disable-next-line no-param-reassign
          reduced[field] = {
            ...fields[field],
            error: !value ? fields[field].error : null,
            input: {
              ...fields[field].input,
              value,
            },
          }
          return reduced
        }, {})

        const newValues = {}

        Object.entries(batchFields).forEach(([nm, vl]) => {
          newValues[nm] = vl.input.value
        })

        this.setState((state) => ({
          fields: {
            ...state.fields,
            ...batchFields,
          },
          values: {
            ...state.values,
            ...newValues,
          },
        }))
      }

      setValueToField = (field, value) => {
        fields[field] &&
          this.setState((state) => ({
            fields: {
              ...state.fields,
              [field]: {
                ...state.fields[field],
                input: {
                  ...state.fields[field].input,
                  value,
                },
              },
            },
            values: {
              ...state.values,
              [field]: value,
            },
          }))
      }

      /*
       * Caso todos os inputs estejam sem erros será retornado true
       * @return {Boolean}
       */
      get isFormValid() {
        const { fields } = this.state

        return !Object.keys(fields).some((field) => !!fields[field].error)
      }

      render() {
        const newProps = {
          resetForm: this.resetForm,
          setValueToField: this.setValueToField,
          isFormValid: this.isFormValid,
          fieldBatchUpdate: this.fieldBatchUpdate,
        }

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ComposedComponent {...newProps} {...this.props} {...this.state} />
        )
      }
    }
  }

export default FormHOC
