import i18next from 'i18next'
import moment from 'moment-timezone'

import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/pt'
import 'moment/dist/locale/pt-br'

moment.tz.setDefault(moment.tz.guess())
moment.locale(navigator.language)

/* CONSTANTE PARA STATUS DA ORDEM DE SERVIÇO */
export const ORDER_STATUS_TYPES = {
  '00': 'serviceOrders.preScheduled',
  '01': 'serviceOrders.scheduled',
  '02': 'serviceOrders.distributed',
  '03': 'serviceOrders.associated',
  '04': 'serviceOrders.confirmed',
  '05': 'serviceOrders.doing',
  '06': 'serviceOrders.done',
  '07': 'serviceOrders.partiallyDone',
  '08': 'serviceOrders.closed',
  '09': 'serviceOrders.canceled',
  10: 'serviceOrders.notAccordingly',
  11: 'serviceOrders.branch.waiting',
  12: 'serviceOrders.branch.doing',
  13: 'serviceOrders.branch.done',
  14: 'serviceOrders.branch.closed',
  15: 'serviceOrders.outofflow',
  16: 'serviceOrders.provider.preScheduled',
  17: 'serviceOrders.provider.scheduled',
  18: 'serviceOrders.provider.distributed',
  19: 'serviceOrders.provider.associated',
  20: 'serviceOrders.provider.confirmed',
  21: 'serviceOrders.provider.inRealization',
  22: 'serviceOrders.provider.succssesfullyDone',
  24: 'serviceOrders.provider.closed',
  26: 'serviceOrders.wcf.waiting',
  27: 'serviceOrders.technical.visit.waiting',
  99: 'serviceOrders.all',
  100: 'serviceOrders.allHouse',
  101: 'serviceOrders.allStore',
  102: 'serviceOrders.allProvider',
}

export const ORDER_STATUS_TYPE = {
  PRE_SCHEDULE: '00',
  SCHEDULED: '01',
  DISTRIBUTED: '02',
  ASSOCIATED: '03',
  CONFIRMED: '04',
  DOING: '05',
  DONE: '06',
  PARTIALLY_DONE: '07',
  CLOSED: '08',
  CANCELED: '09',
  NOT_ACCORDINGLY: '10',
  LOJA_AGUARDANDO: '11',
  LOJA_EM_REALIZACAO: '12',
  LOJA_REALIZADO: '13',
  LOJA_ENCERRADO: '14',
}

/* CONSTANTE PARA TIPO DE CANAL */
export const CHANNEL_TYPES = {
  '01': i18next.t('advanced.search.channel.types.store') || 'Loja',
  '02': i18next.t('advanced.search.channel.types.telesales') || 'Televendas',
  '03': i18next.t('advanced.search.channel.types.websales') || 'e-commerce',
  '04': i18next.t('advanced.search.channel.types.pdv') || 'PDV',
}

/* CONSTANTE PARA TIPO DE ORDENAÇÃO */
export const SORT_BY = {
  'so.custIdNr': 'so.custIdNr.value',
  'so.custFullNm': 'so.custFullNm.value',
  'so.custTrdNm': 'so.custTrdNm.value',
  'so.servcOrdCd': 'so.servcOrdCd.value',
  'so.vaSlsOrdNr': 'so.vaSlsOrdNr.value',
  'so.creatTs': 'so.creatTs.value',
  'so.servcOrdSchdlgTs': 'so.servcOrdSchdlgTs.value',
  'so.servcOrdStusCd': 'so.servcOrdStusCd.value',
  'so.slsChnlNm': 'so.slsChnlNm.value',
  'so.plntNm': 'so.plntNm.value',
}

export const SORT_BY_ELASTIC = {
  cust_id_nr: 'so.custIdNr.value',
  cust_full_nm: 'so.custFullNm.value',
  cust_trd_nm: 'so.custTrdNm.value',
  servc_ord_cd: 'so.servcOrdCd.value',
  va_sls_ord_nr: 'so.vaSlsOrdNr.value',
  creat_ts: 'so.creatTs.value',
  servc_ord_schdlg_ts: 'so.servcOrdSchdlgTs.value',
  servc_ord_stus_cd: 'so.servcOrdStusCd.value',
  sls_chnl_nm: 'so.slsChnlNm.value',
  plnt_nm: 'so.plntNm.value',
}

/* CONSTANTE PARA TIPO DE DOCUMENTO */
export const DOCUMENT_TYPE = {
  1: 'serviceOrders.cpf',
  2: 'serviceOrders.cnpj',
  3: 'serviceOrders.passport',
  4: 'serviceOrders.custId',
}

/* CONSTANTE PARA STATUS SIM ou NÂO */
export const YES_NO = {
  0: 'option.no',
  1: 'option.yes',
}

const longDateFormat = moment.localeData()._config.longDateFormat

/* Padrão de datas do sistema */
export const FORMAT_DATE = `${longDateFormat.L} ${longDateFormat.LTS}`
export const FORMAT_DATE_TRUNC = longDateFormat.L
export const FORMAT_DATE_TRUNC_DOT = 'DD.MM.YYYY'
export const FORMAT_SINGLE_DATE_PICKER = longDateFormat.L
export const DATE_PATTERN = '99/99/9999'



export const EXPERT_TYPE = {
  0: '',
  1: 'CAU',
  2: 'CREA',
}

/* constantes de mapeamento para chamadas no cockpit */
export const COCKIPT_ONLINE_INSTALLERS = 1
export const COCKIPT_NEW_SERVICES = 2
export const COCKIPT_RUNNING_SERVICES = 3
export const COCKIPT_EVE_CONFIRM_SERVICES = 4
export const COCKIPT_TO_DISTRIBUTE_SERVICES = 5
export const COCKIPT_PARTIALLY_DONE_SERVICES = 6
export const COCKIPT_CLOSED_OR_DONE_SERVICES = 7
export const COCKIPT_WITH_WARRANTY_SERVICES = 8
export const COCKIPT_PROGRESS_SERVICES = 9
export const COCKIPT_OPEN_REWORKS = 11
export const COCKIPT_TODAY_LATE = 12
export const COCKIPT_PREVIOUSLY_LATE = 13
export const COCKIPT_REFUSED = 14
export const COCKIPT_ALERTS = 15
export const WAITING_FOR_EVALUATION = 16
export const COCKIPT_SERVICES_PARTIALLY_DONE = 17
export const COCKPIT_SERVICES_DO_NOT_CONFORM_WITH_NO_CHILDREN = 18
export const COCKPIT_ALERTS_FOR_SERVICE_COORDINATOR = 19
export const COCKPIT_TODAY_CONFIRMED_AND_LATE = 20
export const COCKPIT_WAITING_FOR_QUOTATION = 22
export const COCKIPT_ACCEPTANCE_SERVICE_CONDITIONS = 23
export const BUDGET_ORDERS = 26
export const BUDGET_WITHOUT_ANSWER_STRING = '27'
export const BUDGET_WITHOUT_ANSWER = 27
export const QUOTATION_APPROVED = 28
export const BUDGET_DISTRIBUTED = 76
export const QUOTATION_REFUSED_BY_EXPIRATION_JOB = 42
export const COCKIPT_NOT_ALLOCATED = 999
export const COCKPIT_WAITING_MEASUREMENT = 29
export const COOKPIT_VALIDATE_MEASUREMENT = 30
export const COCKIPT_SERVICES_ON_TIME = 31
export const COOKPIT_EMERGENCY_EXPIRED = 32
export const BUDGET_PEDDING = 9

/* Dispositivos possíveis em uma mensagem do chat */
export const MESSAGE_DEVICE = {
  WEB: 'web',
  WEB_BRANCH: 'webBranch',
  MOBILE: 'mobile',
}

/* Conteúdos possíveis em uma mensagem do chat */
export const MESSAGE_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
}

/* Marcadores dos mapas */
export const MAP_MARKER = {
  EXECUTION: 1,
  DISTRIBUTE: 2,
  NEW: 3,
  REWORK: 4,
  EXECUTING: 5,
  CONNECTED: 6,
  FREE: 7,
}

/* Tipo de mapa */
export const MAP_TYPE = {
  SERVICES: 1,
  ONLINE_AGENTS: 2,
}

/* Tags usadas no chat */
const CHAT_TAG_OS_NAME = 'oscd'
export const CHAT_TAG = {
  OS_OPEN: `#${CHAT_TAG_OS_NAME}#`,
  OS_CLOSE: `#/${CHAT_TAG_OS_NAME}#`,
}

/* Theme para ser usado em autocomplete */
export const AUTOCOMPLETE_THEME = {
  container: 'react-autosuggest__container',
  containerOpen: 'react-autosuggest__container--open',
  input: 'react-autosuggest__input input',
  inputOpen: 'react-autosuggest__input--open',
  inputFocused: 'react-autosuggest__input--focused',
  suggestionsContainer: 'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'react-autosuggest__suggestions-list',
  suggestion: 'react-autosuggest__suggestion',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
  sectionContainer: 'react-autosuggest__section-container',
  sectionContainerFirst: 'react-autosuggest__section-container--first',
  sectionTitle: 'react-autosuggest__section-title',
}

/* Filtros da tela de consulta das OS */
export const SERVICE_ORDERS_DEFAULT_FILTERS = {
  orderBy: 'so.servcOrdSchdlgTs',
  descAsc: 'ASC',
  currentSearchField: 'serviceOrderNumber',
  currentSearchFieldValue: '',
  creatTs: null,
  creatTsEnd: null,
  servcOrdSchdlgTs: null,
  servcOrdSchdlgTsEnd: null,
  servcXecutnStrtDt: null,
  servcXecutnStrtDtEnd: null,
  servcOrdItemEvntDt: null,
  servcOrdItemEvntDtFim: null,
  lsCodProd: [],
  lsStatus: [],
  lsServcOrdStusCd: [],
  codProdAssociado: '',
  servcPrvdrAgntId: null,
  servcPrvdrAgntNm: '',
  servcPrvdrId: null,
  servcPrvdrNm: '',
  slsChnlTyp: '',
  lsBranch: [],
  lsPlntCd: [],
  indRunningRework: false,
  indNewRework: false,
  indRefused: false,
  indRefusedLastDay: false,
  indExpired: false,
  indAlertServices: false,
  serviceOrderHasAttachments: false,
  servcOrdPriortyCd: 'so.typeFull',
  withoutChildren: false,
  waitingForEvaluationInNumDays: false,
  hasFilters: false,
  servcOrdType: 'so.typeFull',
  closedOrDoneServices: false,
  servcOrdStatusTypeStore: false,
  servcOrdStatusTypeHouse: false,
  servcOrdStatusTypeProvider: false,
  lsServcOrdStusCockpit: [],
  servcCatgryTypCd: [],
  codProd: '',
  sosWithoutEvaluation: false,
  sosPendingBudget: false,
  sosOutOfFlow: false,
  sosWaitingQuotation: false,
  sosClosedWithoutSatisfactionSurvey: false,
  conformity: false,
  lateTodayInd: false,
  manualDistributed: false,
  waitingForEvaluationForNDays: false,
  indWaitingMeasurementSheet: false,
  indValidateMeasurementSheet: false,
  indWaitingLateTechReport: false,
  indWaitingTechReport: false,
  lsServcOrdCategory: [],
  servcOnTime: false,
}

export const SHIFT_CODES = {
  M: 'service.portal.morning',
  A: 'service.portal.afternoon',
  E: 'service.portal.night',
}

export const CALL_AGENT = {
  ANEW: 1,
  FIVE9: 2,
  TWILIO: 3,
}

export const USER_TYPE = {
  BACKOFFICE: 0,
  BRANCH: 1,
  CUSTOMER: 1,
}

export const ATTACHMENT_CLASSIFICATION = {
  FILE: 3,
  EVALUATION_PHOTO: 6,
  NEED_HELP_PHOTO: 7,
  BUDGET_ATTACHMENT_CUSTOMER: 8,
  BUDGET_ATTACHMENT_STORE: 9,
  MEASUREMENT_SHEET: 12,
  TECHNICAL_REPORT: 14,
}


export const ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

export const ACTV_SERVC_PRVDR_IND_TYPES = {
  ACTIVE: '1',
  INACTIVE: '0',
}

/* ACCOUNT TYPES */
export const ACCOUNT_TYPES = {
  1: 'account.type.checking',
  2: 'account.type.saving',
}


export const BUDGETS = {
  ALL_COCKPIT: 0,
  RECEPTED: 1,
  LACKING: 2,
  FIRTS_WITHOUT_ANSWER: 3,
  SECOND_WITHOUT_ANSWER: 4,
}

export const ALERTS_IDS = {
  CUSTOM: 0,
  PREVIOUSLY_LATE: 2,
  COORDINATOR_ATTENTION: 6,
  WAITING_FOR_EVALUATION: 16,
  BUDGET_PENDING: 9,
  WAITING_FOR_QUOTATION: 11,
  ACCEPTANCE_SERVICE_CONDITIONS: 12,
  VALIDATE_MEASUREMENT: 14,
  ORDERS_WAITING_FOR_MEASUREMENT_SHEET: 29,
  ORDERS_WAITING_FOR_MEASUREMENT_SHEET_VALIDATION: 30,
  SERVICES_ON_TIME: 31,
  EMERGENCY_EXPIRED: 32,
  TODAY_LATE: 41,
  ALMOST_LATE_SERVICES: 42,
  TECHNICAL_REPORT_LATE: 43,
  ORDERS_WITHOUT_RESPONSIBLE: 44,
  WAITING_BUDGET: 45,
  RECOMMEND_INSPECTION: 46,
  ATTENTION_TO_DELIVERY: 50,
  NEGATIVE_OV: 51,
  CLIENT_RESCHEDULE_REFUSE: 52,
}

export const OLD_ALERTS_IDS = {
  CUSTOM: 0,
  COCKIPT_TODAY_LATE: 1,
  PREVIOUSLY_LATE: 2,
  ALERTS_FOR_SERVICE_COORDINATOR: 6,
  WAITING_FOR_EVALUATION: 7,
  BUDGET_PEDDING: 9,
  WAITING_FOR_QUOTATION: 11,
  ACCEPTANCE_SERVICE_CONDITIONS: 12,
  WAITING_MEASUREMENT: 13,
  VALIDATE_MEASUREMENT: 14,
  EMERGENCY_EXPIRED: 15,
}

export const ALERTS_TYPES = {
  0: 'default',
  2: 'previous.late',
  6: 'service.coordinator',
  9: 'budget.pending',
  11: 'waiting.for.quotation',
  12: 'acceptance.service.conditions',
  14: 'validate.measurement',
  16: 'waiting.for.evaluation',
  29: 'waiting.measurement',
  30: 'waiting.measurement.validation',
  32: 'emergency.expired',
  41: 'today.late',
  42: 'almost.late',
  43: 'techreport.late',
  44: 'waitingResponsible',
  45: 'waitingBudget',
  46: 'recommendInspection',
  50: 'attentionToDelivery',
  51: 'negativeOV',
  52: 'clientRescheduleRefuse',
}

export const REASONS_TYPES = {
  REFUSAL: 1,
  PAUSE: 2,
  NOT_STARTED: 3,
  UNREALIZED: 4,
  LITIGATION: 5,
  DISTRIBUTE: 10,
  RESCHEDULE: 10,
}

export const ASSIGNED_TO_SERVICE_CORE = 'ASSIGNED_TO_SERVICE_CORE'
