import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import store from '../../redux/store'

import { ADEOResponseNoData } from '../../models/AdeoResponse'

interface deleteServiceOrderAttachmentProps {
  servcOrdCd: number
  servcOrdItemAtachmtId: number
}

/**
 * @description Delete attachment of a service order
 */

export const deleteServiceOrderAttachment = async (
  payload: deleteServiceOrderAttachmentProps,
): Promise<ADEOResponseNoData> =>
  new API_REQUEST(null, store.dispatch).put(API_URL.ORDER_SERVC_ATTACHMENT, payload)
