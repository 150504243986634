import { memo, useMemo } from 'react'
import store from '../../../../redux/store'
import moment from 'moment-timezone'
import i18next from 'i18next'

import usePageSize from '../../../../hooks/usePageSize'

import { useQueryContext } from '../../context'
import { useAdvancedSearchContext } from '../../../../contexts/advancedSearch'
import { QueryParamsType } from '../../../../models/Project'
import { firstBreakpoint } from '../ProjectRow'
import { ServiceRowBodyDesktop, ServiceRowBodyMobile } from '../ServiceRowBody'
import { ProjectExecProps } from '../../../../services/ProjectList'

type ServiceRowProps = {
  handleSubmitDefault: Function
  vaCheckoutCartUrl: string
  orderNumber: string
  queryParams: QueryParamsType
  service: ProjectExecProps
  sendToDetails: () => void
}

function ServiceRow({
  sendToDetails,
  handleSubmitDefault,
  vaCheckoutCartUrl,
  orderNumber,
  queryParams,
  service,
}: ServiceRowProps) {
  const { width } = usePageSize()

  const onClickPDV = () => {
    if (vaCheckoutCartUrl) {
      const vaLink = `${vaCheckoutCartUrl}?cartNumber=${service.pdvNumber}`
      window.open(vaLink, '_blank')?.focus()
    } else {
      handleSubmitDefault({
        currentSearchField: 'orderNumer',
        currentSearchFieldValue: service.pdvNumber,
      })
    }
  }

  const dateField = () => {
    if (service.cancelTs)
      return {
        label: i18next.t('pwa.service.order.refuse.title'),
        value: moment(service.cancelTs).format('L'),
      }

    if (service.closureTs)
      return {
        label: i18next.t('field.closure'),
        value: moment(service.closureTs).format('L'),
      }

    if (service.completionTs)
      return {
        label: i18next.t('field.completion'),
        value: moment(service.completionTs).format('L'),
      }

    if (service.executionTs)
      return {
        label: i18next.t('field.execution'),
        value: moment(service.executionTs).format('L'),
      }

    return {
      label: i18next.t('field.schedule'),
      value: moment(service.scheduleTs).format('L'),
    }
  }

  if (width < firstBreakpoint)
    return (
      <ServiceRowBodyMobile
        orderNumber={orderNumber}
        queryParams={queryParams}
        service={service}
        onClickPDV={onClickPDV}
        onClickOS={sendToDetails}
        dateField={dateField}
      />
    )

  return (
    <ServiceRowBodyDesktop
      orderNumber={orderNumber}
      queryParams={queryParams}
      service={service}
      onClickPDV={onClickPDV}
      onClickOS={sendToDetails}
      dateField={dateField}
    />
  )
}

const MemoComponent = memo(ServiceRow)

function ServiceRowWC({
  service,
  orderNumber,
  sendToDetails,
}: {
  service: ProjectExecProps
  orderNumber: string
  sendToDetails: () => void
}) {
  const { handleSubmitDefault } = useAdvancedSearchContext()
  const { vaCheckoutCartUrl } = store.getState().auth.setupParameters
  const queryParams = useQueryContext()

  const newProps = useMemo(
    () => ({
      service,
      orderNumber,
      queryParams,
      vaCheckoutCartUrl,
      handleSubmitDefault,
      sendToDetails,
    }),
    [handleSubmitDefault, vaCheckoutCartUrl, queryParams, service, sendToDetails],
  )

  return <MemoComponent {...newProps} />
}

export default ServiceRowWC
