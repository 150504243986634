import Mask from 'vanilla-masker'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import { useServiceOrderContext } from '../../../contexts/serviceOrder'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServiceOrderItem from './ServiceOrderItem'

function ServicesList() {
  const {
    t,
    disabled,
    onSubmitItemObs,
    auth,
    auth: { setupParameters },
    serviceOrder,
  } = useServiceOrderContext()
  const { serviceOrderItem, serviceOrderAuxiliaryItem } = serviceOrder

  const sizeCurrency = t('size.currency')

  const parameterServicePriceOn = auth && setupParameters && setupParameters.acceptingServicePrice

  function renderService(serviceOrderItem) {
    const isCancel = serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_CANCELADA.id
    return (
      <ServiceOrderItem
        key={serviceOrderItem.idOrdemServicoItem}
        serviceOrderItem={serviceOrderItem}
        t={t}
        isCancel={isCancel}
        auth={auth}
        disabled={disabled}
        onSubmitItemObs={onSubmitItemObs}
      />
    )
  }

  function renderAuxiliaryItem(auxiliaryItem, index) {
    return (
      <li key={index}>
        <Row className='padding'>
          <Col xs={4}>
            <h6 className='strong no-margin'>{auxiliaryItem.auxlryResaleProdNm}</h6>
            <span className='color-default small'>
              {t('serviceOrders.services.codProd')}: {auxiliaryItem.auxlryResaleProdCd}
            </span>
          </Col>

          <Col xs={4}>
            <span className='strong'>
              {auxiliaryItem.auxlryResaleProdQty
                ? Mask.toMoney(auxiliaryItem.auxlryResaleProdQty.toFixed(sizeCurrency))
                : ''}
            </span>
          </Col>
        </Row>
      </li>
    )
  }

  function renderHeader() {
    if (parameterServicePriceOn) {
      return (
        <Row className='top-and-bottom-padding top-and-bottom-padding color-primary'>
          <Col xs={3}>
            <h3 className='no-margin arrow-down'>{t('serviceOrders.service')}</h3>
          </Col>
          <Col xs={3}>
            <h3 className='no-margin arrow-down itens'>{t('serviceOrders.quantity')}</h3>
            <h5 className='no-margin sub-itens'>
              {t('serviceOrders.acquired')} / {t('serviceOrders.fulfilled')} /{' '}
              {t('serviceOrders.returned')}
            </h5>
          </Col>
          <Col xs={3}>
            <h3 className='no-margin arrow-down itens'>{t('serviceOrders.value')}</h3>
            <h5 className='no-margin sub-itens'>
              {t('serviceOrders.service.unitary')} /{' '}
              <span className='strong'>{t('serviceOrders.total.item')}</span>
            </h5>
          </Col>
          <Col xs={3}>
            <h3 className='no-margin arrow-down itens'>{t('serviceOrders.valueTransfer')}</h3>
            <h5 className='no-margin sub-itens'>
              {t('serviceOrders.service.unitary')} /{' '}
              <span className='strong'>{t('serviceOrders.total.item')}</span>
            </h5>
          </Col>
        </Row>
      )
    }
    let label = t('serviceOrders.value')
    if (label) {
      label = label.split(' ')[0]
    }
    return (
      <Row className='padding color-primary'>
        <Col xs={4}>
          <h3 className='no-margin padding-vertical-small arrow-down'>
            {t('serviceOrders.service')}
          </h3>
        </Col>
        <Col xs={4}>
          <h3 className='no-margin padding-vertical-small arrow-down'>
            {t('serviceOrders.quantity')}
          </h3>
        </Col>
        <Col xs={4}>
          <h3 className='no-margin padding-vertical-small arrow-down'>{label}</h3>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      {serviceOrderItem && serviceOrderItem.length && (
        <Grid fluid className='border-top no-padding-side'>
          {' '}
          {renderHeader()}{' '}
        </Grid>
      )}

      {serviceOrderItem && serviceOrderItem.length && (
        <ul className='list'>{serviceOrderItem.map((item) => renderService(item))}</ul>
      )}

      {serviceOrderAuxiliaryItem && serviceOrderAuxiliaryItem.length ? (
        <Grid fluid className='border-top'>
          <Row className='padding color-primary'>
            <Col xs={4}>
              <h3 className='no-margin padding-vertical-small arrow-down'>
                {t('serviceOrders.auxiliaryProducts')}
              </h3>
            </Col>
            <Col xs={4}>
              <h3 className='no-margin padding-vertical-small arrow-down'>
                {t('serviceOrders.quantity')}
              </h3>
            </Col>
          </Row>
        </Grid>
      ) : null}

      {serviceOrderAuxiliaryItem && serviceOrderAuxiliaryItem.length ? (
        <ul className='list'>
          <Grid fluid>{serviceOrderAuxiliaryItem.map((item) => renderAuxiliaryItem(item))}</Grid>
        </ul>
      ) : null}
    </div>
  )
}

export default ServicesList
