import PropTypes from 'prop-types'
import { Component } from 'react'

import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Form from '../../../components/utils/Form'
import Input from '../../../components/utils/Input'
import InputNumber from '../../../components/utils/InputNumber'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'

import moment from 'moment-timezone'

export class NfseModal extends Component {
  constructor(props) {
    super(props)

    this.state = { numNfseOld: null }
  }

  componentDidMount() {
    const {
      initialValues: { numNfse },
    } = this.props
    this.setState({ numNfseOld: numNfse })
  }

  handleSaveNfse = (event) => {
    event.preventDefault()

    const { saveNfse, values, initialValues, closeModal } = this.props
    const { numNfseOld } = this.state

    if (values.datEmissNfse) {
      const dateFormated = moment(values.datEmissNfse, 'DD/MM/AAAA')
      const strDateFormated = dateFormated.format('YYYY-MM-DD')
      values.datEmissNfse = strDateFormated
    }

    values.numNfseOld = numNfseOld
    values.buCd = initialValues.buCd
    values.cntryCd = initialValues.cntryCd
    values.servcPrvdrCd = initialValues.servcPrvdrCd

    saveNfse(values)

    closeModal()
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const { fields, setValueToField } = this.props

    setValueToField(name, value)
    fields[name].input.onChange(event)
  }

  handleInputChange = (value) => {
    const { setValueToField } = this.props

    setValueToField('indEnvioPgto', value)

    if (value === '2') {
      setValueToField('numNfse', '')
      setValueToField('datEmissNfse', '')
      setValueToField('valNfse', '')
    }
  }

  render() {
    const { t, fields, closeModal, isEdition, values, removeServiceOrder, initialValues } =
      this.props

    return (
      <Modal closeModal={closeModal} size='sm'>
        <h4 className='margin-bottom-small'>
          <strong>{t('nfServiceOrder.preencherNfse')}</strong>
        </h4>
        {isEdition && (
          <label className='margin-bottom-small margin-top-double'>
            {t('payments.invc.modal.header').replace('{0}', values.servcOrdCd)}
          </label>
        )}
        <form onSubmit={this.handleSaveNfse}>
          <Row>
            <Col xs={6} className='margin-bottom'>
              <Field {...fields.numNfse}>
                <Input
                  id='numNfse'
                  {...fields.numNfse.input}
                  onChange={this.handleChange}
                  label={t('nfServiceOrder.numNfse')}
                  mask='99999999999999999999'
                  disabled={values.indEnvioPgto === '2'}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6} className='margin-bottom'>
              <Field {...fields.datEmissNfse} glyph='glyph glyph-calendar'>
                <Input
                  id='datEmissNfse'
                  {...fields.datEmissNfse.input}
                  onChange={this.handleChange}
                  label={t('nfServiceOrder.datEmissNfse')}
                  mask='99/99/9999'
                  disabled={values.indEnvioPgto === '2'}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6} className='margin-bottom'>
              <Field {...fields.valNfse}>
                <InputNumber
                  id='valNfse'
                  {...fields.valNfse.input}
                  onChange={this.handleChange}
                  label={t('nfServiceOrder.valNfse')}
                  maxLength={18}
                  minLength={1}
                  separator={t('separator.currency')}
                  delimiter={t('delimiter.currency')}
                  disabled={values.indEnvioPgto === '2'}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <label className='label'>{t('nfServiceOrder.indEnvioPgto')}</label>
            </Col>
          </Row>
          <Row>
            <Col xs={2} className='margin-bottom'>
              <div className='field' style={{ marginTop: 0 }}>
                <Input
                  type='radio'
                  checked={values.indEnvioPgto === '1'}
                  name='indEnvioPgto'
                  id='indEnvioPgto'
                  label={t('option.yes')}
                  onChange={() => this.handleInputChange('1')}
                />
              </div>
            </Col>
            <Col xs={2} className='margin-bottom'>
              <div className='field' style={{ marginTop: 0 }}>
                <Input
                  type='radio'
                  checked={values.indEnvioPgto === '0'}
                  name='indEnvioPgto1'
                  id='indEnvioPgto1'
                  label={t('option.no')}
                  onChange={() => this.handleInputChange('0')}
                />
              </div>
            </Col>
            {values.inRework && (
              <Col xs={3} className='margin-bottom'>
                <div className='field' style={{ marginTop: 0 }}>
                  <Input
                    type='radio'
                    checked={values.indEnvioPgto === '2'}
                    name='indEnvioPgto2'
                    id='indEnvioPgto2'
                    label={t('payments.servcOrdNfseAnalysis.rework')}
                    onChange={() => this.handleInputChange('2')}
                    disabled={initialValues.numNfse}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Row className='margin-bottom'>
            <Col xs={6} md={6} className='button-align'>
              {isEdition && initialValues.numNfse !== undefined && (
                <button
                  className='button-hollow button-hollow-danger margin-right-small pull-left'
                  type='button'
                  onClick={removeServiceOrder}
                >
                  {t('payments.remove.invoice.linkage')}
                </button>
              )}
            </Col>

            <Col xs={3} md={3} className='button-align'>
              <button
                className='button-hollow button-hollow-primary button-full'
                type='button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col xs={3} md={3} className='button-align'>
              <button className='button button-primary button-full' type='submit'>
                {t('nfServiceOrder.finalizar')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

NfseModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveNfse: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isEdition: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  setValueToField: PropTypes.func.isRequired,
  removeServiceOrder: PropTypes.func.isRequired,
}

const fields = ['numNfse', 'datEmissNfse', 'valNfse', 'indEnvioPgto', 'inRework', 'servcOrdCd']

export default Form({ fields })(NfseModal)
