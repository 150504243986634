import Resume from '../../Resume'
import ServiceExecutions from './components/ServiceExecutions'
import Observation from './components/Observation'
import Warranty from './components/Warranty'
import Litigy from './components/Litigy'
import ErrorBoundary from '../../../../../errors/boundaries'

function Project() {
  return (
    <div id='project-container'>
      <ErrorBoundary>
        <Resume />

        <section className='project-tab'>
          <ServiceExecutions />
          <Observation />
          <Warranty />
          <Litigy />
        </section>
      </ErrorBoundary>
    </div>
  )
}

export default Project
