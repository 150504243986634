import i18next from 'i18next'
import moment, { isMoment } from 'moment-timezone'
import { useState } from 'react'

import Modal from '../../../../../../components/Modal'
import OutlineButton from '../../../../../../components/OutlineButton'

import DateSelect from './components/DateSelect'
import FooterButtons from './components/FooterButtons'
import InstallerSelect from './components/InstallerSelect'
import JustifyInput from './components/JustifyInput'
import ModeSelect from './components/ModeSelect'
import PeriodSelect from './components/PeriodSelect'
import ProviderSelect from './components/ProviderSelect'

import User from '../../../../../../core/User'
import store from '../../../../../../redux/store'

import { Text } from '@leroy-merlin-br/backyard-react'
import { hideSpinner, showSpinner } from '../../../../../../redux/actions/spinner'
import { DistributeService } from '../../../../../../services/pages/ServiceOrderDetail/DistributeService'
import { useServiceOrderDetailContext } from '../../../../context'
import { DistributeProvider } from './context'

import ServcOrdEvntStusType from '../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import { shifts } from '../../../../../../models/Distribute'
import ReasonSelect from './components/ReasonSelect'

export type DistributedServiceType = {
  statusCd: string
}

const OUT_FLOW_REFUSED = 3

const user = new User().currentUser

function DistributedService(props: DistributedServiceType) {
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState<any>(null)
  const [justify, setJustify] = useState('')
  const [provider, setProvider] = useState<any>(null)
  const [installer, setInstaller] = useState<any>(null)
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
  const [distributeMode, setDistributeMode] = useState('')
  const [distributeModeProvidersOutsideArea, setDistributeModeProvidersOutsideArea] =
    useState(false)
  const { serviceOrderBody, occurrences, reloadServiceOrder } = useServiceOrderDetailContext()

  const { statusCd } = props
  const { auth } = store.getState()
  const { setupParameters } = auth
  const { funcaoAcesso } = user

  const handleJustifyText = (e: any) => setJustify(e.target.value)
  const handleChangeInstaller = (e: any) => setInstaller(e)

  const handleChangeMode = (e: any) => {
    setDistributeMode(e.target.value)

    setProvider(null)
    setInstaller(null)
  }

  const handleChangeModeProvidersOutsideArea = (checked: boolean) => {
    setDistributeModeProvidersOutsideArea(checked)

    setProvider(null)
    setInstaller(null)
  }

  const handleChangeProvider = (e: any) => {
    setProvider(e)
    setInstaller(null)
  }

  const handleChangeDate = (e: any) => {
    if (!isMoment(e)) return setSelectedDate(null)

    const newDate = e.startOf('day')
    if (+newDate === +selectedDate?.startOf('day')) return false

    setSelectedDate(newDate)
    setReason(null)
    setJustify('')
    setProvider(null)
    setInstaller(null)
  }

  const handleChangePeriod = (e: any) => {
    if (e?.value === selectedPeriod?.value) return false

    setSelectedPeriod(e)

    setReason(null)
    setJustify('')
    setProvider(null)
    setInstaller(null)
  }

  const handleClose = () => {
    setShow(false)

    setReason(null)
    setJustify('')
    setProvider(null)
    setInstaller(null)
    setSelectedDate(null)
    setSelectedPeriod(null)
    setDistributeMode('')
    setDistributeModeProvidersOutsideArea(false)
  }

  const handleSubmit = async () => {
    store.dispatch(showSpinner())

    const justification = reason ? `${reason.value}${justify ? ` - ${justify}` : ''}` : null

    const form = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq,
      servcOrdSchdlgTs: selectedDate.format(),
      servcOrdSchdlgShiftCd: shifts[selectedPeriod.value],
      justification,
      distributionType: distributeMode.toUpperCase(),
      servcPrvdrCd: provider?.value || null,
      servcPrvdrTrdNm: provider?.label || null,
      servcPrvdrAgntCd: installer?.value || null,
      servcPrvdrAgntNm: installer?.label || null,
    }

    const response = await DistributeService(form, true)

    if (response.cdStatus === 200) {
      handleClose()
      await reloadServiceOrder()
    }

    store.dispatch(hideSpinner())
  }

  const showDistributeModal = () => {
    const STATUS_PRE_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id
    const STATUS_DISTRIBUIDA = statusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id
    const STATUS_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const hasNoFunction00 = funcaoAcesso.inFuncao00 !== 1
    const hasNoFunction01 = funcaoAcesso.inFuncao01 !== 1
    const hasFunction02 = funcaoAcesso.inFuncao02 === 1

    const isOutFlowProccess = () => {
      if (
        (setupParameters.serviceOrderBodyOutOfFlowEnable &&
          serviceOrderBody &&
          serviceOrderBody.idOutFlowProcess &&
          serviceOrderBody.idOutFlowProcess !== OUT_FLOW_REFUSED) ||
        (serviceOrderBody &&
          serviceOrderBody.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id)
      ) {
        return true
      }

      return false
    }

    if (!setupParameters.allowDispatchBeforeTcSigned && (STATUS_PRE_AGENDADA || STATUS_AGENDADA)) {
      const wasSigned = occurrences.some(
        (ocurrence: any) =>
          ocurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.ACCEPTANCE_SALES_CONDITIONS.id,
      )
      if (!wasSigned) return false
    }

    if (setupParameters.servcPrvdrManualDistributionIsActive) {
      const wasRejected = occurrences.some(
        (ocurrence: any) =>
          ocurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.REFUSAL_PROCESS.id,
      )
      if (!wasRejected) return false
    }
    if (STATUS_DISTRIBUIDA) return false
    if (isOutFlowProccess()) return false

    const isConformity = serviceOrderBody?.isConformity && setupParameters.conformity
    const preScheduleRule = STATUS_PRE_AGENDADA && hasNoFunction00
    const scheduleRule = STATUS_AGENDADA && hasNoFunction01 && hasNoFunction00
    const distributeRule = STATUS_DISTRIBUIDA && (hasFunction02 || hasNoFunction01)

    if (isConformity) return false
    if (distributeRule) return false
    if (scheduleRule) return false
    if (preScheduleRule) return false

    return STATUS_PRE_AGENDADA || STATUS_DISTRIBUIDA || STATUS_AGENDADA
  }

  const modalTitle = 'serviceOrders.occurrences.servcOrdEvntStusInd.52'
  const modalSubtitle = `${i18next.t('service.detail.distribute.modal.subtitle')}.`

  const deliveryDate = moment(serviceOrderBody?.servcOrdPrmsdDlvryDt).startOf('day')

  const hasDeliveryDate =
    setupParameters?.distributeServiceBlockBeforeDelivery && serviceOrderBody?.servcOrdPrmsdDlvryDt

  const deliveryTime = deliveryDate ? +deliveryDate : 0

  const today = moment().startOf('day')
  const todayTime = +today
  const currentTime = selectedDate ? +selectedDate.startOf('day') : 0

  const compareTodayDate = currentTime >= +todayTime
  const compareDeliveryDate = hasDeliveryDate ? currentTime >= +deliveryTime : true

  const providerProps = {
    serviceOrder: serviceOrderBody,
    selectedDate,
    selectedPeriod,
    distributeMode,
    providersOutsideArea: distributeModeProvidersOutsideArea,
    setSelectedDate: handleChangeDate,
    setSelectedPeriod: handleChangePeriod,
    setProvider,
    setInstaller,
    show,
  }

  return (
    <div id='distributed-service'>
      <DistributeProvider {...providerProps}>
        {showDistributeModal() && (
          <OutlineButton onClick={() => setShow(!show)}>
            {i18next.t('kpi.distribute.title')}
          </OutlineButton>
        )}

        {show && (
          <Modal
            size='lg'
            isOpen={show}
            onClose={handleClose}
            className='distributed-service-modal no-margin-header'
            closeInOverlay={false}
            contentLabel={modalTitle}
          >
            <div className='distribute-modal-content'>
              <Text noMargin>{modalSubtitle}</Text>

              <div className='flex-date-selects'>
                <div className='date-select'>
                  <DateSelect value={selectedDate} onChange={handleChangeDate} />
                </div>
                <div className='period-select'>
                  <PeriodSelect
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    selectedDate={selectedDate}
                    compareTodayDate={compareTodayDate}
                    compareDeliveryDate={compareDeliveryDate}
                  />
                </div>
              </div>

              <ReasonSelect
                value={reason}
                onChange={setReason}
                selectedDate={selectedDate}
                selectedPeriod={selectedPeriod}
              />

              <JustifyInput
                value={justify}
                onChange={handleJustifyText}
                selectedDate={selectedDate}
                selectedPeriod={selectedPeriod}
              />

              <ModeSelect
                value={distributeMode}
                onChange={handleChangeMode}
                selectedDate={selectedDate}
                compareTodayDate={compareTodayDate}
                compareDeliveryDate={compareDeliveryDate}
                providersOutsideAreaValue={distributeModeProvidersOutsideArea}
                onChangeProvidersOutsideAreaValue={handleChangeModeProvidersOutsideArea}
              />

              <ProviderSelect
                value={provider}
                onChange={handleChangeProvider}
                distributeMode={distributeMode}
                compareTodayDate={compareTodayDate}
                compareDeliveryDate={compareDeliveryDate}
              />

              <InstallerSelect
                value={installer}
                onChange={handleChangeInstaller}
                distributeMode={distributeMode}
                compareTodayDate={compareTodayDate}
                compareDeliveryDate={compareDeliveryDate}
              />
            </div>

            <FooterButtons
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              selectedDate={selectedDate}
              selectedPeriod={selectedPeriod}
              reason={reason}
              provider={provider}
              distributeMode={distributeMode}
            />
          </Modal>
        )}
      </DistributeProvider>
    </div>
  )
}

export default DistributedService
