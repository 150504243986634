import { useEffect, useState } from 'react'
import i18next from 'i18next'

import { validatePhoneNumber } from '../../../../../../../../../../../helpers/InternationalPhoneNumberUtils'
import { useServiceOrderDetailContext } from '../../../../../../../../../context'
import {
  updatePhones,
  UpdatePhonesType,
} from '../../../../../../../../../../../services/pages/ServiceOrderDetail/UpdatePhones'

import TelephoneInputField from '../../../../../../../../../../../components/utils/InternationalPhoneNumberField'
import Modal from '../../../../../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../../../../../components/MozaicButton'

interface PhoneBoxModalProps {
  isOpen: boolean
  onClose: () => void
}

function PhoneBoxModal(props: PhoneBoxModalProps) {
  const { client, servcOrdCd, fetchClient } = useServiceOrderDetailContext()
  const { isOpen, onClose } = props

  const [businessPhone, setBusinessPhone] = useState<string>('')
  const [mobilePhone, setMobilePhone] = useState<string>('')
  const [homePhone, setHomePhone] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const { custBusnsPhonNr, custMobilePhonNr, custHmPhonNr } = client || {}

  useEffect(() => {
    setBusinessPhone(custBusnsPhonNr || '')
    setMobilePhone(custMobilePhonNr || '')
    setHomePhone(custHmPhonNr || '')
  }, [])

  async function handleSubmit() {
    setIsSubmiting(true)

    const params = {
      custHmPhonNr: homePhone,
      custMobilePhonNr: mobilePhone,
      custBusnsPhonNr: businessPhone,
    }

    await updatePhones(params as UpdatePhonesType, servcOrdCd)
    await fetchClient()

    onClose()

    setIsSubmiting(false)
  }

  const checkMobilePhone = custMobilePhonNr ? custMobilePhonNr !== mobilePhone : !!mobilePhone
  const checkBusinessPhone = custBusnsPhonNr ? custBusnsPhonNr !== businessPhone : !!businessPhone
  const checkHomePhone = custHmPhonNr ? custHmPhonNr !== homePhone : !!homePhone

  const mobilePhoneIsValid =
    validatePhoneNumber(mobilePhone) && ![businessPhone, homePhone].includes(mobilePhone)

  const businessPhoneIsValid =
    businessPhone !== ''
      ? validatePhoneNumber(businessPhone) && ![mobilePhone, homePhone].includes(businessPhone)
      : true

  const homePhoneIsValid =
    homePhone !== ''
      ? validatePhoneNumber(homePhone) && ![mobilePhone, businessPhone].includes(homePhone)
      : true

  const hasChanges = checkMobilePhone || checkBusinessPhone || checkHomePhone

  const canSubmit = hasChanges && mobilePhoneIsValid && businessPhoneIsValid && homePhoneIsValid

  const businessPhoneLabel = i18next.t('serviceOrders.phone2')
  const mobilePhoneLabel = i18next.t('serviceOrders.phone1')
  const homePhoneLabel = i18next.t('serviceOrders.phone3')
  const cancelText = i18next.t('cancel.label')
  const submitText = i18next.t('serviceOrders.confirm')

  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onClose={() => onClose()}
      contentLabel='customer.service.order.details.phones'
      closeInOverlay={false}
    >
      <div id='phone-box-modal'>
        <h4>{i18next.t('phone.modal.aux.text')}</h4>
        <section className='content-inputs'>
          <TelephoneInputField
            topLabel={mobilePhoneLabel}
            value={mobilePhone}
            valid={mobilePhoneIsValid}
            onChange={(value: string) => setMobilePhone(value || '')}
          />

          <TelephoneInputField
            topLabel={businessPhoneLabel}
            value={businessPhone}
            valid={businessPhoneIsValid}
            onChange={(value: string) => setBusinessPhone(value || '')}
          />

          <TelephoneInputField
            topLabel={homePhoneLabel}
            value={homePhone}
            valid={homePhoneIsValid}
            onChange={(value: string) => setHomePhone(value || '')}
          />
        </section>

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => onClose()}>
            {cancelText}
          </MozaicButton>
          <MozaicButton onClick={() => handleSubmit()} isDisabled={!canSubmit || isSubmiting}>
            {submitText}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}

export default PhoneBoxModal
