import i18next from 'i18next'
import { useHistory } from 'react-router'
import { Button, Flex } from '@mozaic-ds/react'
import User from '../../../../core/User'

const user = new User()

const FUNCTION_ACCESS_TO_EDIT = 19

/**
 * @description Header component for Service Providers List V2 page
 */

export function Header() {
  const history = useHistory()

  const hasUserAccessToEdit =
    user.currentUser.accessFunctionList?.indexOf(FUNCTION_ACCESS_TO_EDIT) !== -1

  const handleOnClickNewServiceProvider = () => {
    history.push('/providers/new', {
      from: `/providers${history.location.search}`,
    })
  }

  return (
    <>
      {hasUserAccessToEdit && (
        <header>
          <Flex justifyContent='flex-end' alignItems='flex-start' marginBottom='mu200'>
            <Button onClick={handleOnClickNewServiceProvider}>{i18next.t('add.provider')}</Button>
          </Flex>
        </header>
      )}
    </>
  )
}
