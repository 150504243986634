import { DisplayOptions24 } from '@mozaic-ds/icons/react'
import {
  DataTable,
  DataTableEmptyView,
  DataTableFooter,
  Flex,
  IDataTableColumn,
  Link as MozaicLink,
  Pagination,
  Text,
  Toggle,
  View,
} from '@mozaic-ds/react'
import i18n from 'i18next'
import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ContextMenu } from '../../../components/ContextMenu'
import { ContextMenuOptionProps } from '../../../components/ContextMenu/components/ContextMenuOption'
import { PaginationCounter } from '../../../components/PaginationCounter'
import { YES_NO } from '../../../constants/config'
import User from '../../../core/User'
import { formatNationalOrInternationalPhoneNumber } from '../../../helpers/InternationalPhoneNumberUtils'
import { Installer } from '../../../services/installers/fetchInstallersList'
import InstallersListContext from '../context'
import InactiveJustificationModal from './InactiveJustificationModal'
import { InstallerProviderIsInactive } from './InstallerProviderIsInactive'
import RemoveModal from './RemoveModal'

const user = new User()

const ACCESS_FUNCTION_VIEW_INSTALLERS = 20
const ACCESS_FUNCTION_EDIT_INSTALLERS = 21
const ACCESS_FUNCTION_DELETE_INSTALLERS = 40

/**
 * installers list table
 */
function Table() {
  const [installerToDelete, setInstallerToDelete] = useState<Installer>()
  const [installerToInactive, setInstallerToInactive] = useState<Installer>()
  const [installerToActiveProvider, setInstallerToActiveProvider] = useState<Installer>()
  const { installers, isLoading, pages, page, total, setPage } = useContext(InstallersListContext)

  const history = useHistory()

  const canViewInstallers = user.currentUser.accessFunctionList.includes(
    ACCESS_FUNCTION_VIEW_INSTALLERS,
  )
  const canEditInstallers = user.currentUser.accessFunctionList.includes(
    ACCESS_FUNCTION_EDIT_INSTALLERS,
  )
  const canDeleteInstallers = user.currentUser.accessFunctionList.includes(
    ACCESS_FUNCTION_DELETE_INSTALLERS,
  )

  const renderProviderLinkColumn = (installer: Installer) => {
    const isRegistrationByServicePortal = !installer.servcPrvdrAddRgstrnCurrntStepNr
    return (
      <Link
        to={`/providers/${isRegistrationByServicePortal ? 'edit' : 'view'}/${
          installer.servcPrvdrId
        }`}
      >
        <MozaicLink theme='primary' size='s'>
          {installer.servcPrvdrTrdNm}
        </MozaicLink>
      </Link>
    )
  }

  const renderActiveRegistrationColumn = (installer: Installer) => {
    if (!canEditInstallers) {
      return i18n.t(YES_NO[installer.indEspecialistaServicoAtivo])
    }

    return (
      <Flex justifyContent='center' className='active-installer-toggle'>
        <Toggle
          isChecked={installer.indEspecialistaServicoAtivo === '1'}
          size='s'
          onChange={() => {
            installer.servcProviderIsActive === '1'
              ? setInstallerToInactive({ ...installer })
              : setInstallerToActiveProvider({ ...installer })
          }}
        />
      </Flex>
    )
  }

  const renderActionsColumn = (row: Installer) => {
    const contextMenuOptions: ContextMenuOptionProps[] = [
      {
        icon: 'edit',
        label: i18n.t('option.edit'),
        onClick: () => {
          history.push(`/installers/edit/${row.servcPrvdrAgntId}`, {
            from: `/installers${history.location.search}`,
          })
        },
        isDisabled: !canEditInstallers,
        title: !canEditInstallers ? i18n.t('useraccess.not.allowed') : '',
      },
      {
        icon: 'view',
        label: i18n.t('button.label.view'),
        onClick: () => {
          history.push(`/installers/view/${row.servcPrvdrAgntId}`, {
            from: `/installers${history.location.search}`,
          })
        },
        isDisabled: !canViewInstallers,
        title: !canViewInstallers ? i18n.t('useraccess.not.allowed') : '',
      },
      {
        icon: 'delete',
        label: i18n.t('option.delete'),
        onClick: () => setInstallerToDelete(() => ({ ...row })),
        isDisabled: !canDeleteInstallers,
        title: !canDeleteInstallers ? i18n.t('useraccess.not.allowed') : '',
      },
    ]

    return <ContextMenu options={contextMenuOptions} triggerIcon={<DisplayOptions24 />} />
  }

  const columns: IDataTableColumn<Installer>[] = [
    {
      className: 'col-provider',
      label: i18n.t('serviceSpecialists.nomePrestadorServico'),
      key: 'servcPrvdrId',
      render: renderProviderLinkColumn,
    },
    {
      className: 'col-name',
      label: i18n.t('serviceSpecialists.nomeEspecialistaServico'),
      key: 'servcPrvdrAgntNm',
    },
    {
      className: 'col-phone',
      label: i18n.t('serviveSpecialists.phone1'),
      key: 'lsPhones',
      render: (row) =>
        row.lsPhones && row.lsPhones.length
          ? formatNationalOrInternationalPhoneNumber(null, row.lsPhones[0].cntctMthdValTxt)
          : '',
    },
    {
      className: 'col-active',
      label: i18n.t('serviceSpecialists.indEspecialistaServicoAtivo'),
      key: 'indEspecialistaServicoAtivo',
      render: renderActiveRegistrationColumn,
    },
    {
      className: 'col-receiving-order',
      label: i18n.t('installer.receipt.service.orders.text'),
      key: 'receivingServiceOrder',
      render: (row) => <div className='text-capitalize'>{row.receivingServiceOrder}</div>,
    },
    {
      className: 'col-actions',
      label: i18n.t('actions'),
      render: renderActionsColumn,
    },
  ]

  const paginationOptions = Array.from({
    length: pages,
  }).map((_: any, index: number) => ({
    value: index + 1,
    label: i18n
      .t('pagination.page.range')
      .replace('{start}', String(index + 1))
      .replace('{end}', String(pages)),
  }))

  const getRowKey = (row: Installer) => row.servcPrvdrAgntId

  return (
    <div className='table-section'>
      <DataTable<Installer>
        rows={installers}
        columns={columns}
        getRowKey={getRowKey}
        isPending={isLoading}
      >
        {!isLoading && !installers.length && (
          <DataTableEmptyView>
            <View padding='mu250'>
              <Text size='l' theme='light'>
                {i18n.t('items.not.found')}
              </Text>
            </View>
          </DataTableEmptyView>
        )}
        <DataTableFooter style={{ flex: 1, justifyContent: 'space-between' }}>
          <PaginationCounter total={total} page={page} perPage={10} />
          {pages > 1 && (
            <Pagination
              currentPage={page}
              pagesTotal={pages}
              options={paginationOptions}
              onChange={(page) => setPage(page)}
              isDisabled={isLoading}
            />
          )}
        </DataTableFooter>
      </DataTable>

      <RemoveModal installer={installerToDelete} />
      <InactiveJustificationModal installer={installerToInactive} />
      <InstallerProviderIsInactive installer={installerToActiveProvider} />
    </div>
  )
}

export default Table
