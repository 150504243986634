import i18next from 'i18next'
// @ts-ignore
import MozaicRadio from '../../../../../../../../../../components/MozaicRadio'

type DirectionRadioGroupType = {
  onChange: Function
  value: string
}

function DirectionRadioGroup(props: DirectionRadioGroupType) {
  const { onChange, value } = props

  const label = i18next.t('sense.text')

  return (
    <div className='direction-radio-group'>
      <label className='react-select--label'>{label}</label>

      <div className='d-flex'>
        <MozaicRadio
          name='radio-group-sense'
          value='ascending'
          onChange={() => onChange('ascending')}
          isChecked={value === 'ascending'}
          label={i18next.t('option.ascending')}
        />
        <MozaicRadio
          name='radio-group-sense'
          value='descending'
          onChange={() => onChange('descending')}
          isChecked={value === 'descending'}
          label={i18next.t('option.descending')}
        />
      </div>
    </div>
  )
}

export default DirectionRadioGroup
