import PropTypes from 'prop-types'
import { useServiceOrderContext } from '../../../contexts/serviceOrder'
import EditForm from './EditForm'
import HeaderInfo from './HeaderInfo'

function FormSection({ serviceOrderStatuses }) {
  const {
    fields,
    setFormValue,
    handleChangeStatus,
    showDistributeButton,
    showRescheduleButton,
    t,
    serviceOrder,
    disabled,
    serviceOrderEdit,
    serviceOrderDuplicate,
    initialValues,
    getServiceProviders,
    serviceProviders,
    serviceSpecialists,
    getServiceSpecialists,
    funcaoAcesso,
    clearServiceProvider,
    clearServiceSpecialist,
    indStatusOrdemServicoOrigem,
    currentStatus,
    values,
    oldStatus,
    getDate,
    setDate,
    getShift,
    setShift,
    disabledSpecialist,
    call,
    auth,
    serviceOrderOutOfFlowEnable,
    dispatch,
    register,
    realStatus,
    setServiceOrder,
  } = useServiceOrderContext()

  function handleChange(event) {
    const { name, value } = event.target

    if (name === 'servcOrdStusCd') handleChangeStatus(value, true)
    else setFormValue({ [name]: value })
  }

  function renderStatusOption(status, index) {
    const { id, value } = status

    return (
      <option key={index} value={id}>
        {t(value)}
      </option>
    )
  }

  return (
    <section>
      <HeaderInfo
        fields={fields}
        renderStatusOption={renderStatusOption}
        handleChange={handleChange}
        serviceOrderStatuses={serviceOrderStatuses}
      />

      <EditForm
        t={t}
        setServiceOrder={setServiceOrder}
        realStatus={realStatus}
        showDistributeButton={showDistributeButton}
        showRescheduleButton={showRescheduleButton}
        serviceOrder={serviceOrder}
        serviceOrderEdit={serviceOrderEdit}
        serviceOrderDuplicate={serviceOrderDuplicate}
        initialValues={initialValues}
        getServiceProviders={getServiceProviders}
        serviceProviders={serviceProviders}
        serviceOrderStatuses={serviceOrderStatuses}
        serviceSpecialists={serviceSpecialists}
        getServiceSpecialists={getServiceSpecialists}
        handleChangeStatus={handleChangeStatus}
        funcaoAcesso={funcaoAcesso}
        clearServiceProvider={clearServiceProvider}
        clearServiceSpecialist={clearServiceSpecialist}
        disabled={disabled}
        indStatusOrdemServicoOrigem={indStatusOrdemServicoOrigem}
        currentStatus={currentStatus}
        handleChange={handleChange}
        register={register}
        fields={fields}
        setFormValue={setFormValue}
        values={values}
        oldStatus={oldStatus}
        getDate={getDate}
        setDate={setDate}
        getShift={getShift}
        setShift={setShift}
        disabledSpecialist={disabledSpecialist}
        call={call}
        serviceOrderOutOfFlowEnable={serviceOrderOutOfFlowEnable}
        auth={auth}
        dispatch={dispatch}
      />
    </section>
  )
}

FormSection.propTypes = {
  serviceOrderStatuses: PropTypes.array.isRequired,
}

export default FormSection
