import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import { withTranslation } from 'react-i18next'
import br from 'react-phone-number-input/locale/pt-BR.json'
import fr from 'react-phone-number-input/locale/fr.json'
import en from 'react-phone-number-input/locale/en.json'
import pt from 'react-phone-number-input/locale/pt.json'
import pl from 'react-phone-number-input/locale/pl.json'
import it from 'react-phone-number-input/locale/it.json'

const userLanguage = navigator.language || navigator.userLanguage

const labels =
  {
    'pt-BR': br,
    'fr-FR': fr,
    'en-US': en,
    'pt-PT': pt,
    'pl-PL': pl,
    'it-IT': it,
  }[userLanguage] || br

function TelephoneInput({ label, value, onChange, country, t, disabled }) {
  return (
    <div className='PhoneInputContainer'>
      <PhoneInput
        value={value}
        defaultCountry={country || t('country.code')}
        country={country || ''}
        label={label}
        limitMaxLength
        labels={labels}
        onChange={onChange}
        initialValueFormat='international'
        international
        disabled={disabled}
      />
    </div>
  )
}

TelephoneInput.propTypes = {
  /* The input label that will be displayed, leave empty for no label */
  label: PropTypes.string,

  /* Translation function */
  t: PropTypes.func,

  /**
   * The phone number in E164 format.
   * Ex.: `undefined`, `"+55"`, `"+5554999999999"`.
   */
  value: PropTypes.string.isRequired,

  /* Phone number country */
  country: PropTypes.any,

  /**
   * Function triggered when the phone number changes.
   * Receives the new phone number in E164 format as argument.
   */
  onChange: PropTypes.func,

  /* Disabled option */
  disabled: PropTypes.bool,
}

const TelephoneInputTranslated = withTranslation()(TelephoneInput)

export default TelephoneInputTranslated
