import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'

function SequencialOrderInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='sequencial-order-info' className='skeleton' />

  return (
    <div id='sequencial-order-info'>
      <label htmlFor=''>{i18next.t('accordeon.header.sample1')}</label>
      <section>
        <span className='sequencial-text'>{serviceOrderBody?.servcOrdSeq || '-'}</span>
      </section>
    </div>
  )
}

export default SequencialOrderInfo
