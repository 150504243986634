import MozaicCheckBox from '../../../../../../components/MozaicCheckBox'
import { useSideNavContext } from '../../../../../../contexts/sidenav'

type SideNavContext = {
  stores: any
  handleChangeStores: Function
}

function StoresList() {
  const { stores, handleChangeStores } = useSideNavContext() as SideNavContext

  return (
    <section className='stores-list-container truly-stores-list'>
      {(stores || []).map((store: any, index: any) => (
        <section className='branch-checkbox' key={store.value}>
          <MozaicCheckBox
            name={store.name}
            value={store.value}
            label={store.label}
            isChecked={store.checked}
            isIndeterminate={store.isIndeterminate}
            onChange={(event: any) => handleChangeStores(event, index)}
          />
        </section>
      ))}
    </section>
  )
}

export default StoresList
