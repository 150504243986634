import i18next from 'i18next'
import { Toggle } from '@mozaic-ds/react'

import { ACTV_SERVC_PRVDR_IND_TYPES, YES_NO } from '../../../../../../constants/config'

import './index.scss'

interface ActiveRegistrationRowProps {
  hasEditPermission: boolean
  isRegistrationActive: '0' | '1'
  isEnabled: boolean
  onToggle: () => void
}

/**
 * @description Enable/Disable Registration row component for Service Providers List V2 page
 */

export function ActiveRegistrationRow({
  hasEditPermission,
  isRegistrationActive,
  isEnabled,
  onToggle,
}: ActiveRegistrationRowProps) {
  return (
    <div id='providers-active-registration-row' data-testid='providers-active-registration-row'>
      <>
        {hasEditPermission ? (
          <>
            {isEnabled ? (
              <Toggle
                id='actvServcPrvdrInd'
                data-testid='actvServcPrvdrInd'
                onChange={onToggle}
                isChecked={isRegistrationActive === ACTV_SERVC_PRVDR_IND_TYPES.ACTIVE}
                size='s'
                style={{ width: 'fit-content' }}
              />
            ) : (
              i18next.t(YES_NO[isRegistrationActive])
            )}
          </>
        ) : (
          i18next.t(YES_NO[isRegistrationActive])
        )}
      </>
    </div>
  )
}
