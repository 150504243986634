import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../contexts/advancedSearch'
import { useSideNavContext } from '../../../contexts/sidenav'

export function WithContext(
  Component: FunctionComponent<{ stores: any; handleSubmitDefault: Function }>,
) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { handleSubmitDefault } = useAdvancedSearchContext()
    const { stores, branchesLabel } = useSideNavContext() as any

    const newProps = useMemo(
      () => ({
        stores,
        branchesLabel,
        handleSubmitDefault,
      }),
      [stores, branchesLabel, handleSubmitDefault],
    )

    return <MemoComponent {...props} {...newProps} />
  }
}
