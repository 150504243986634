import { Component } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import Select from '../../../components/utils/Select'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

class ProfileSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      profiles: [],
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    const query = {
      actvSysAcsPrflInd: '1',
    }

    if (user) {
      return new Api(user.dsToken, dispatch)
        .get(API_URL.PROFILE_FIND, query)
        .then((response) => this.onFetchSuccess(response.data))
        .catch((error) => this.onFetchError(error))
    }
  }

  onFetchSuccess(profiles) {
    this.setState({ profiles, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, profiles: [] })
  }

  renderOptions(profile, index) {
    return (
      <option key={index} value={profile.sysAcsPrflId}>
        {profile.sysAcsPrflNm}
      </option>
    )
  }

  render() {
    const { id, name, value, onChange, t, disabled = false } = this.props
    const { profiles, loading } = this.state

    const label = loading ? t('option.loading') : t('serviceSpecialists.choose-profile')

    return (
      <Select
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={(event) => onChange(event)}
        disabled={disabled}
      >
        <option value='' />

        {profiles.map(this.renderOptions)}
      </Select>
    )
  }
}

ProfileSelect.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
}

/* istanbul ignore next */
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
})

/*
 * Connect the component to i18next
 */

const ProfileSelectTranslated = withTranslation()(ProfileSelect)

/*
 * Export the component connected to Redux
 */
export default connect(mapStateToProps)(ProfileSelectTranslated)
