import { memo } from 'react'

interface OutlineButtonProps {
  readonly label?: string
  readonly iconNode?: any
  readonly isDisabled?: boolean
  readonly onClick: () => void
  readonly classname?: string
  readonly children?: any
  readonly id?: string
}

function OutlineButton({
  label,
  id = 'outline-button',
  children,
  classname,
  isDisabled,
  onClick,
  iconNode,
}: OutlineButtonProps) {
  return (
    <button id={id} className={classname} type='button' onClick={onClick} disabled={isDisabled}>
      {iconNode}
      {label}
      {children}
    </button>
  )
}

export default memo(OutlineButton)
