import { useEffect, useState } from 'react'
import i18next from 'i18next'

import { optionType, actionType, SelectInterface } from '../models'
import API_URL from '../../../../../core/Envs/endpoints'

import Field from '../../../../../components/utils/Field'
import StatesSelectDefault from '../../../../../components/StatesSelect'

function SelectStates(props: SelectInterface) {
  const { onChange, value, disabled } = props

  const [field, setField] = useState({
    input: {
      name: 'field',
      value: [],
    },
    error: null,
    touched: false,
    initialValue: '',
  })

  useEffect(() => {
    setField((prevField) => ({
      ...prevField,
      input: {
        ...prevField.input,
        value,
      },
    }))
  }, [value])

  const handleChange = (selected: optionType | any, type: actionType) => onChange(selected, type)
  const id = i18next.t('changeAddressForm.servcAddrStCd')

  return (
    <Field {...field} validate>
      <StatesSelectDefault
        id='states'
        name={id}
        label={id}
        placeholder={id}
        value={value}
        disabled={disabled}
        fetchURL={API_URL.FIND_MACRO_REGION_STATES}
        onChange={handleChange}
        isMulti
        resetValue={[]}
      />
    </Field>
  )
}

export default SelectStates
