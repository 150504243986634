import {
  CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST,
  CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS,
  CREATE_SERVICE_ORDER_OCCURRENCE_ERROR,
} from '../../actionTypes/serviceOccurrence'

const DEFAULT_STATE = {
  edit: {
    record: {},
    error: null,
    isUpdating: false,
  },
}

const record = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: true,
        },
      }

    case CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: null,
          record: action.payload,
        },
      }

    case CREATE_SERVICE_ORDER_OCCURRENCE_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: action.payload,
          record: {},
        },
      }

    default:
      return state
  }
}

export default record
