import { memo, useState } from 'react'
import i18next from 'i18next'

import EditButton from '../../../../../../components/EditButton'
import WorksiteEdit from './components/WorksiteModal'

import { useServiceOrderDetailContext } from '../../../../context'
import { useAdvancedSearchContext } from '../../../../../../contexts/advancedSearch'

function Worksite() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()
  const { handleSubmitDefault } = useAdvancedSearchContext()

  const [openEditModal, setOpenEditModal] = useState(false)

  if (loadingBody) return <div id='worksite-container' className='shimmer-effect' />

  const handleClickWorksite = () => {
    handleSubmitDefault({
      currentSearchField: 'servcOrdWorksiteNumber',
      currentSearchFieldValue: serviceOrderBody?.servcOrdWorksiteNumber.toString(),
    })
  }

  return (
    <div id='worksite-container'>
      <span className='worksite-label'>{i18next.t('serviceOrder.worksite.title')}</span>
      <section>
        <span className='worksite-number' onClick={handleClickWorksite}>
          {serviceOrderBody?.servcOrdWorksiteNumber || '-'}
        </span>
        <span className='count-services'>
          ({serviceOrderBody?.servcOrdWorksiteCount} {i18next.t('homepage.tab.services')})
        </span>
        <EditButton onClickEditButton={() => setOpenEditModal(!openEditModal)} />
      </section>
      {openEditModal && (
        <WorksiteEdit
          title={i18next.t('serviceOrder.worksite.change')}
          onClose={() => setOpenEditModal(false)}
          isOpen={openEditModal}
          servcOrdWorksiteNumber={serviceOrderBody?.servcOrdWorksiteNumber || 0}
        />
      )}
    </div>
  )
}

export default memo(Worksite)
