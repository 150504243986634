/**
 * @fileoverview ServiceOrderAttachmentsEnum
 * @description This file contains the enums of attachments of service orders
 */

export enum ServiceOrderAttachmentsEnum {
  FILE = 3,
  EVALUATION_PHOTO = 6,
  NEED_HELP_PHOTO = 7,
  BUDGET_ATTACHMENT_CUSTOMER = 8,
  BUDGET_ATTACHMENT_STORE = 9,
  MEASUREMENT_SHEET = 12,
  TECHNICAL_REPORT = 14,
  INVOICE = 16,
}
