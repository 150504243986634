import AdvancedSearchInput from '../AdvancedSearchInput'

/*
  Description component: containet to show in mobile screen below app bar
*/
function AdvancedSearchMobile() {
  return (
    <div id='advanced-search-container-mobile'>
      <AdvancedSearchInput mobile />
    </div>
  )
}

export default AdvancedSearchMobile
