import Api from '../../core/Http/FetchAdapter'

import API_URL from '../../core/Envs/endpoints'
import { showSpinner, hideSpinner } from '../../redux/actions/spinner'
import store from '../../redux/store'

export const sendOsMail = async (token, query) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new Api(token, store.dispatch).post(
      API_URL.SERVICE_ORDER_SEND_MAIL,
      query,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
