import store from '../../redux/store'
import FetchAdapter from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

interface UpdateServiceProviderRegistrationStatusParams {
  actvServcPrvdrInd: '0' | '1'
  servcPrvdrId: number
  description: string
  servcPrvdrTmlineEvntDesc: string
}

export const updateServiceProviderRegistrationStatus = (
  params: UpdateServiceProviderRegistrationStatusParams,
): Promise<ADEOResponse> =>
  new FetchAdapter(user.currentUser.dsToken, store.dispatch).post(
    API_URL.SERVICE_PROVIDER_INACTIVE,
    params,
  )
