import PropTypes from 'prop-types'
import { Component } from 'react'
import Grid from '../../../components/utils/Grid'
import TitleSection from '../../../components/utils/TitleSection'

class BudgetList extends Component {
  handleDeleteFile = (file) => {
    const { t } = this.props
    if (window.confirm(t('servc.ord.budget.component.title.exclude.file'))) {
      const { deleteAtchmt } = this.props
      // eslint-disable-next-line no-param-reassign
      file.actvServcOrdItemAtchmtInd = 0
      deleteAtchmt(file)
    }
  }

  handleOpenSendFile = () => {
    const { handleSendFile, files, idFiles, idTypeFile } = this.props
    handleSendFile(files, idFiles, idTypeFile)
  }

  getClassButtonDelete = () => {
    const { blockButtonRemove } = this.props
    if (blockButtonRemove) {
      return 'fileItemDelete'
    }
    return 'fileItemDelete no-visible'
  }

  render() {
    const { title, buttonText, t, files } = this.props
    return (
      <Grid fluid>
        <TitleSection>
          <h1>{t(title)}</h1>
        </TitleSection>
        {(files || []).map((file) => (
          <div className='fileItem'>
            <p className='fileItemName'>{file.servcOrdItemAtchmtDesc}</p>
            <button
              className={this.getClassButtonDelete()}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                this.handleDeleteFile(file)
              }}
            >
              {' '}
              {t('servc.ord.budget.component.title.button.remove')}
              <div className='glyph glyph-trash-can' />
            </button>
          </div>
        ))}
        <button className='fileItemButton button button-primary' onClick={this.handleOpenSendFile}>
          {t(buttonText)}
        </button>
      </Grid>
    )
  }
}

BudgetList.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  files: PropTypes.object.isRequired,
  deleteAtchmt: PropTypes.func.isRequired,
  handleSendFile: PropTypes.func.isRequired,
  idFiles: PropTypes.string.isRequired,
  idTypeFile: PropTypes.string.isRequired,
  blockButtonRemove: PropTypes.bool.isRequired,
}

export default BudgetList
