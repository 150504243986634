import { Component } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import store from '../../../redux/store'
import User from '../../../core/User'

const user = new User().currentUser

class AwsAudio extends Component {
  state = {
    audio: false,
  }

  componentDidMount() {
    this.renderAudio()
  }

  getFile = async (token, filePath, dispatch) => {
    let base64 = ''
    await new Api(token, dispatch).get(API_URL.AMAZON_AWS_GET_FILE, { filePath }).then(
      (response) => {
        base64 = response.data
        return response.data
      },
      (error) => {
        console.error('Faleid to find audio', error)
        return ''
      },
    )

    return base64
  }

  renderAudio = async () => {
    const { filePath, data } = this.props

    if (data) {
      this.setState({ audio: data })
    } else {
      const base64 = await this.getFile(user.dsToken, filePath, store.dispatch)
      const audio = `data:audio/mp4;base64,${base64}`
      this.setState({ audio })
    }
  }

  render() {
    const { audio } = this.state
    return (
      <audio src={audio} controls>
        <i>{i18next.t('serviceOrders.unsupportedAudio')}</i>
      </audio>
    )
  }
}

AwsAudio.propTypes = {
  filePath: PropTypes.string.isRequired,
  data: PropTypes.string,
}

export default AwsAudio
