export function actionSet(type, payload) {
  return payload !== undefined ? { type, payload } : { type }
}

export function actionSetWithoutValidation(type, payload) {
  return { type, payload }
}

export function resolve(dispatch, type, payload) {
  dispatch(actionSet(type, payload))
  return Promise.resolve(payload)
}

export function reject(dispatch, type, payload) {
  dispatch(actionSet(type, payload))
  return Promise.reject(payload)
}
