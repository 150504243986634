import {
  FETCH_I18N_KEYS_ERROR,
  FETCH_I18N_KEYS_SUCCESS,
  I18N_KEYS_REQUEST,
} from '../../actionTypes/i18n'

const DEFAULT_STATE = {
  keys: {},
  error: null,
}

const i18n = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case I18N_KEYS_REQUEST:
      return {
        ...state,
        keys: {},
        error: null,
      }

    case FETCH_I18N_KEYS_SUCCESS:
      return {
        ...state,
        keys: action.payload,
        error: null,
      }

    case FETCH_I18N_KEYS_ERROR:
      return {
        ...state,
        keys: {},
        error: action.payload,
      }

    default:
      return state
  }
}

export default i18n
