import {
  GET_PUBLIC_OS_REQUEST,
  GET_PUBLIC_OS_REQUEST_SUCESS,
  GET_PUBLIC_OS_REQUEST_ERROR,
  GET_PUBLIC_OS_SUCCESS_NAME,
  GET_PUBLIC_OS_SUCCESS_CHANGE_DATE,
} from '../../actionTypes/publicServcOrd'

const DEFAULT_STATE = {
  list: {
    name: '',
    dataLimit: '',
    dataSelect: '',
    plntCd: '',
    record: {},
    error: null,
    isFetching: false,
    isUpdating: false,
  },
}

const list = (state = DEFAULT_STATE, action) => {
  const data = action.payload

  switch (action.type) {
    case GET_PUBLIC_OS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
          isUpdating: false,
        },
      }

    case GET_PUBLIC_OS_REQUEST_SUCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          isUpdating: false,
          error: null,
          record: data,
        },
      }

    case GET_PUBLIC_OS_REQUEST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          isUpdating: false,
          record: {},
          error: data,
        },
      }

    case GET_PUBLIC_OS_SUCCESS_NAME:
      return {
        ...state,
        list: {
          ...state.list,
          name: data.custFullNm,
          dataLimit: data.date,
          plntCd: data.plntCd,
          servcOrdSeq: data.servcOrdSeq,
        },
      }

    case GET_PUBLIC_OS_SUCCESS_CHANGE_DATE:
      return {
        ...state,
        list: {
          ...state.list,
          dataSelect: action.payload,
        },
      }

    default:
      return state
  }
}

export default list
