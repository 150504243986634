import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../context'
import { SummaryMainType } from '../models'

export function WithContext(Component: FunctionComponent<SummaryMainType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        serviceOrderBody,
        reloadServiceOrder,
      }),
      [serviceOrderBody],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
