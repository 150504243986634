import { useState } from 'react'
import { InstallerHandlersType } from '../models'
import {
  fetchServiceSpecialistNameAutocomplete,
  fetchServiceSpecialistFromId,
  saveServiceSpecialistFiles,
  removeSpecialistFile,
  removeServiceSpecialist,
  updateServiceSpecialist,
  fetchServiceSpecialistFiles,
  inactiveSpecialist,
  fetchSysAcsUser,
} from '../../../services/Installer'

function InstallerHandlers({ installersList }: InstallerHandlersType) {
  const [installerEdit, setInstallerEdit] = useState({})
  const [installerDocs, setInstallerDocs] = useState([])
  const [installerAccess, setInstallerAccess] = useState({})

  const getServiceSpecialist = (index: number) => {
    setInstallerEdit(installersList[index])
  }

  const viewServiceSpecialist = (index: number) => {
    setInstallerEdit(installersList[index])
  }

  const viewServiceSpecialistFromId = (installerId: number, serviceSpecialist: any) => {
    setInstallerEdit(serviceSpecialist)
  }

  const getServiceSpecialistFromId = async (
    token: any,
    servcPrvdrAgntId: string,
    editOrView = 'edit',
    noSpinner = false,
  ) => {
    const serviceSpecialist = await fetchServiceSpecialistFromId(token, servcPrvdrAgntId, noSpinner)
    const editing = editOrView === 'view'
    setInstallerEdit({ ...serviceSpecialist, disabled: editing })
    return serviceSpecialist
  }

  const getServiceSpecialistFiles = async (token: any, servcPrvdrAgntId: number) => {
    const docs = await fetchServiceSpecialistFiles(token, servcPrvdrAgntId)
    setInstallerDocs(docs)
  }

  const handleRemoveFile = async (
    token: any,
    servcPrvdrAgntId: number,
    servcPrvdrAgntAtchmtId: number,
  ) => {
    await removeSpecialistFile(token, servcPrvdrAgntId, servcPrvdrAgntAtchmtId)
    const newInstallerDocs = installerDocs.filter(
      (doc: any) => doc.idAnexoEspecialista !== servcPrvdrAgntAtchmtId,
    )
    setInstallerDocs(newInstallerDocs)
  }

  const handleSaveFiles = async (token: any, values: any) => {
    await saveServiceSpecialistFiles(token, values)
    getServiceSpecialistFiles(token, values.idEspecialistaServico)
  }

  const fetchAccessUser = async (token: any, id: number, callback: Function) => {
    const access = await fetchSysAcsUser(token, id, callback)
    setInstallerAccess(access)
  }

  const handleClearInstaller = () => {
    setInstallerEdit({})
    setInstallerDocs([])
    setInstallerAccess({})
  }

  return {
    installerEdit,
    installerDocs,
    installerAccess,
    getServiceSpecialist,
    getServiceSpecialistFromId,
    viewServiceSpecialistFromId,
    fetchServiceSpecialistNameAutocomplete,
    fetchServiceSpecialistFiles: getServiceSpecialistFiles,
    saveServiceSpecialistFiles: handleSaveFiles,
    viewServiceSpecialist,
    removeSpecialistFile: handleRemoveFile,
    updateServiceSpecialist,
    removeServiceSpecialist,
    inactiveSpecialist,
    fetchSysAcsUser: fetchAccessUser,
    handleClearInstaller,
  }
}

export default InstallerHandlers
