/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useEffect, useState } from 'react'

import {
  ServiceOrder,
  ServiceOrderBody,
  ServiceOrderClient,
  ServiceOrderDetailProviderProps,
  ServiceOrderDetailStore,
  ServiceOrderDetails,
  WarrantyType,
} from './models'

import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import { TAG_TYPES, TagItem } from '../../../enums/ServiceOrder/tags/tag-types'
import { getClient } from '../../../services/pages/ServiceOrderDetail/GetClient'
import { getHistoricOccurrences } from '../../../services/pages/ServiceOrderDetail/GetHistoricOccurrences'
import { getOSDetails } from '../../../services/pages/ServiceOrderDetail/GetOSDetails'
import { fetchWarranty } from '../../../services/pages/ServiceOrderDetail/GetOSWarranty'
import { getObservation } from '../../../services/pages/ServiceOrderDetail/GetObservations'
import { getServiceOrderBody } from '../../../services/pages/ServiceOrderDetail/GetServiceOrderBody'
import { getServiceOrderDetail } from '../../../services/pages/ServiceOrderDetail/GetServiceOrderDetail'
import { getServiceOrderTags } from '../../../services/pages/ServiceOrderDetail/GetServiceOrderTags'
import { HistoricOccurrence } from '../components/Routes/Historic/components/HistoricOccurrences/models/historic-occurrence'

import deniedCategories from '../../../constants/noWarrantyCategories'
import { userAllowedToEnter } from '../../../helpers/userAllowedToEnter'

const ServiceOrderDetailContext = createContext<ServiceOrderDetailStore | null>(null)

function ServiceOrderDetailProvider({ children, servcOrdCd }: ServiceOrderDetailProviderProps) {
  const [isFetching, setIsFetching] = useState<boolean>(true)

  /** @deprecated */
  const [serviceOrder, setServiceOrder] = useState<ServiceOrder | null>(null)

  const [loadingBody, setLoadingBody] = useState<boolean>(true)
  const [serviceOrderBody, setServiceOrderBody] = useState<ServiceOrderBody | null>(null)

  const [occurrences, setOccurrences] = useState<Array<HistoricOccurrence>>([])

  const [loadingTags, setLoadingTags] = useState<boolean>(false)
  const [tags, setTags] = useState<TagItem[]>([])

  const [loadingObs, setLoadingObs] = useState<boolean>(false)
  const [observation, setObservation] = useState<string>('')

  const [pyxisDesc, setPyxisDesc] = useState<string>('')

  const [loadingClient, setLoadingClient] = useState<boolean>(false)
  const [client, setClient] = useState<ServiceOrderClient | null>(null)

  const [loadingDetails, setLoadingDetails] = useState<boolean>(false)
  const [orderDetails, setOrderDetails] = useState<ServiceOrderDetails | null>(null)

  const [loadingWarranty, setLoadingWarranty] = useState<boolean>(false)
  const [warranty, setWarranty] = useState<WarrantyType | null>(null)

  async function fetchObservation() {
    setLoadingObs(true)

    const { servcOrdRmrkTxt, pyxisDesc } = await getObservation(servcOrdCd)

    setObservation(servcOrdRmrkTxt)
    setPyxisDesc(pyxisDesc)

    setLoadingObs(false)
  }

  async function fetchClient() {
    setLoadingClient(true)

    const data = await getClient(servcOrdCd)
    setClient(data)

    setLoadingClient(false)
  }

  async function fetchOSDetails() {
    setLoadingDetails(true)

    const orderBody = await getOSDetails(servcOrdCd)
    setOrderDetails(orderBody)

    setLoadingDetails(false)
  }

  async function fetchOrderTags() {
    setLoadingTags(true)

    const data = await getServiceOrderTags(servcOrdCd)
    const parsedTags = data.filter((t) => TAG_TYPES[t.tagType]).map((t) => TAG_TYPES[t.tagType])
    setTags(parsedTags)

    setLoadingTags(false)
  }

  async function fetchOccurrences() {
    const occurrences = await getHistoricOccurrences(servcOrdCd)
    setOccurrences(occurrences)
    return occurrences
  }

  async function fetchServiceOrderBody() {
    setLoadingBody(true)

    const orderBody = await getServiceOrderBody(servcOrdCd)
    setServiceOrderBody(orderBody)

    setLoadingBody(false)

    return orderBody
  }

  async function fetchServiceOrderWarranty() {
    setLoadingWarranty(true)

    const warrant = await fetchWarranty(servcOrdCd)

    setWarranty(warrant)
    setLoadingWarranty(false)
  }

  /** @deprecated */
  async function fetchServiceOrderOLD() {
    setIsFetching(true)

    const [osData] = await getServiceOrderDetail(servcOrdCd)
    setServiceOrder(osData)

    setIsFetching(false)
  }

  async function reloadServiceOrder(starting = false) {
    setWarranty(null)

    const reqBody = await fetchServiceOrderBody()

    if (starting) userAllowedToEnter(reqBody?.servcCatgryTypCd)

    const reqTags = fetchOrderTags()
    const reqOcurrences = fetchOccurrences()
    const reqObservations = fetchObservation()
    const reqClient = fetchClient()
    const reqDetails = fetchOSDetails()
    const reqOldApi = fetchServiceOrderOLD()

    await Promise.all([
      reqBody,
      reqOcurrences,
      reqTags,
      reqDetails,
      reqObservations,
      reqClient,
      reqOldApi,
    ])

    return true
  }

  useEffect(() => {
    reloadServiceOrder(true)
  }, [])

  useEffect(() => {
    if (
      serviceOrderBody &&
      serviceOrderBody.servcOrdStusCd === ServiceOrderStatusType.STATUS_ENCERRADO.id &&
      !warranty
    )
      fetchServiceOrderWarranty()
  }, [serviceOrderBody])

  const canEditStatus =
    serviceOrderBody?.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id
  const isClosedStatus = canEditStatus

  const servcCatgryTypCd = serviceOrderBody?.servcCatgryTypCd || 0
  const showWarrantyContent = !canEditStatus && !deniedCategories.includes(servcCatgryTypCd)
  const showWarrantyReworkContent =
    !!serviceOrder?.warranty && !deniedCategories.includes(servcCatgryTypCd)

  const serviceOrderDetailValues: ServiceOrderDetailStore = {
    servcOrdCd,
    isFetching,
    serviceOrder,
    reloadServiceOrder,
    fetchServiceOrderBody,
    fetchObservation,
    fetchOccurrences,
    fetchOrderTags,
    fetchClient,
    setOccurrences,
    loadingClient,
    loadingWarranty,
    warranty,
    client,
    loadingTags,
    orderDetails,
    loadingDetails,
    tags,
    loadingBody,
    serviceOrderBody,
    observation,
    pyxisDesc,
    loadingObs,
    occurrences,
    canEditStatus,
    isClosedStatus,
    showWarrantyContent,
    showWarrantyReworkContent,
  }

  return (
    <ServiceOrderDetailContext.Provider value={serviceOrderDetailValues}>
      {children}
    </ServiceOrderDetailContext.Provider>
  )
}

const useServiceOrderDetailContext = () =>
  useContext(ServiceOrderDetailContext) as ServiceOrderDetailStore

export { ServiceOrderDetailContext, ServiceOrderDetailProvider, useServiceOrderDetailContext }
