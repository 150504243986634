import { Component } from 'react'
import PropTypes from 'prop-types'

import AwsAudio from '../../../components/utils/audio/AwsAudio'
import Col from '../../../components/utils/Col'
import Row from '../../../components/utils/Row'

import { osCodesToLink } from '../../../helpers/serviceOrderUtils'

const BLANK_VALUE = '-'

class OccurrencesList extends Component {
  constructor(props) {
    super(props)

    this.isAudio = this.isAudio.bind(this)
    this.renderDescOcor = this.renderDescOcor.bind(this)
    this.renderOccurrences = this.renderOccurrences.bind(this)
  }

  isAudio(descOcorOrdemServico) {
    return descOcorOrdemServico ? descOcorOrdemServico.startsWith('data:audio/mp4;base64,') : false
  }

  isCall = (servcOrdItemEvntDesc) =>
    servcOrdItemEvntDesc ? servcOrdItemEvntDesc.includes('api.twilio.com') : false

  renderDescOcor(descOcorOrdemServico) {
    const { t } = this.props

    if (this.isAudio(descOcorOrdemServico)) {
      return <AwsAudio data={descOcorOrdemServico} />
    }

    if (this.isCall(descOcorOrdemServico)) {
      return (
        <audio src={descOcorOrdemServico} controls>
          <i>{t('serviceOrders.unsupportedAudio')}</i>
        </audio>
      )
    }

    return <span>{osCodesToLink(descOcorOrdemServico)}</span>
  }

  renderReasons(reason) {
    return (
      <div key={reason.servcOrdEvntTypRsnDesc} className='strong'>
        {reason.servcOrdEvntTypRsnDesc}
      </div>
    )
  }

  renderStatus(occurrence) {
    const { t } = this.props

    const status = occurrence.servcOrdEvntStusInd
      ? t(`serviceOrders.occurrences.servcOrdEvntStusInd.${occurrence.servcOrdEvntStusInd}`)
      : BLANK_VALUE

    if (
      occurrence.listOrdemServicoOcorrenciaMotivo &&
      !!occurrence.listOrdemServicoOcorrenciaMotivo.length
    ) {
      return (
        <div>
          {status && <span className='word-break'>{status}</span>}
          {occurrence.listOrdemServicoOcorrenciaMotivo.map(this.renderReasons)}
        </div>
      )
    }
    return (
      <div>
        <span>{status}</span>
      </div>
    )
  }

  renderOccurrences(occurrence, index) {
    const getCustomer = () => {
      const { t } = this.props
      if (occurrence.nomUserRegistroOcor) {
        if (occurrence.nomUserRegistroOcor === 'servc.ord.evnt.mobile.usr.cd') {
          return t('servc.ord.evnt.mobile.usr.cd')
        }
        return occurrence.nomUserRegistroOcor
      }
      if (occurrence.codUserRegistroOcor) {
        return occurrence.codUserRegistroOcor
      }
      return BLANK_VALUE
    }

    const date = occurrence.datOcorOrdemServico || BLANK_VALUE

    return (
      <Row key={index} className='padding no-margin-horizontal'>
        <Col xs={2}>
          <span>{getCustomer()}</span>
        </Col>

        <Col xs={2}>
          <span>{date}</span>
        </Col>

        <Col xs={2}>{this.renderStatus(occurrence)}</Col>

        <Col xs={5}>
          {occurrence.descOcorOrdemServico
            ? this.renderDescOcor(occurrence.descOcorOrdemServico)
            : BLANK_VALUE}
        </Col>
      </Row>
    )
  }

  render() {
    const { occurrences, t } = this.props

    return (
      <div className='occurrences-table'>
        <Row className='min-padding-bottom align-items-center'>
          <Col xs={2}>
            <h5 className='no-margin strong'>{t('serviceOrders.codUserRegistroOcor')}</h5>
          </Col>

          <Col xs={2}>
            <h5 className='no-margin strong'>{t('serviceOrders.datOcorOrdemServico')}</h5>
          </Col>

          <Col xs={2}>
            <h5 className='no-margin strong'>{t('serviceOrders.status')}</h5>
          </Col>

          <Col xs={3}>
            <h5 className='no-margin strong'>{t('serviceOrders.descOcorOrdemServico')}</h5>
          </Col>
        </Row>

        <Row className='border-top border-bottom'>{occurrences.map(this.renderOccurrences)}</Row>
      </div>
    )
  }
}

OccurrencesList.propTypes = {
  t: PropTypes.func.isRequired,
  occurrences: PropTypes.array.isRequired,
}

export default OccurrencesList
