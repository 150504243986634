import PropTypes from 'prop-types'
import { Component } from 'react'
import LogoWithoutSignature from '../../../assets/icons/logoWithoutSignature.svg?react'
import Notification from '../../../components/utils/Notification'
import { CHAT_TAG, MESSAGE_TYPE } from '../../../constants/config'

class ChatTable extends Component {
  state = {
    collapseChats: false,
  }

  static parseUnreadQty(unreadQty) {
    if (unreadQty > 99) {
      return '99+'
    }

    if (unreadQty < 0) {
      return ''
    }

    return unreadQty || ''
  }

  static checkUnreadQtyClass(unreadQty) {
    if (unreadQty) {
      if (unreadQty > 99) {
        return 'three-numbers'
      }

      if (unreadQty > 9) {
        return 'two-numbers'
      }
    }

    return ''
  }

  static osTagRegex = new RegExp(`${CHAT_TAG.OS_OPEN}|${CHAT_TAG.OS_CLOSE}`, 'g')

  static parseLastMessage(lastRecord) {
    if (!lastRecord) return ''

    switch (lastRecord.type) {
      case MESSAGE_TYPE.VIDEO:
        return '🎥 Vídeo'
      case MESSAGE_TYPE.IMAGE:
        return '📷 Imagem'
      default:
        return (lastRecord.message || '').replace(ChatTable.osTagRegex, '')
    }
  }

  static parseLastMessageTime(lastRecord) {
    if (!lastRecord) return null

    return lastRecord.createdAt
  }

  renderEmptyNotification = () => {
    const { t } = this.props

    return (
      <div className='padding'>
        <Notification className='notification-warning' message={t('chatList-list.not-found')} />
      </div>
    )
  }

  renderIsFetchingNotification = () => {
    const { t } = this.props

    return (
      <div className='padding'>
        <Notification className='notification-warning' message={t('chatList-list.isFetching')} />
      </div>
    )
  }

  isReadMessage = (chatRecord) => {
    const { chatList } = this.props
    const { lastRecord, uid, groupHeader, idGroup } = chatRecord

    // if is not a store and match an unread message, so is false
    if (!groupHeader && lastRecord && lastRecord.device === 'mobile' && !lastRecord?.isRead)
      return false

    // when there is no message, its ok
    if (!lastRecord && !uid.startsWith('M_')) return true

    // if its a store, filters unread childs
    if (groupHeader)
      return (
        chatList.filter(
          (message) =>
            message.idGroup === idGroup &&
            message?.lastRecord &&
            message?.lastRecord.device === 'mobile' &&
            !message?.lastRecord.isRead,
        ).length === 0
      )

    return true
  }

  renderRows = () => {
    const { chatList } = this.props
    const iteratedStores = []
    const rows = []
    chatList.forEach((chat, index) => {
      if (!iteratedStores.includes(chat?.idGroup)) {
        iteratedStores.push(chat?.idGroup)
        const chatRecord = chatList.find(
          (item) => item.groupHeader && item.idGroup === chat?.idGroup,
        )
        if (chatRecord) rows.push(this.renderChat(chatRecord, index))
      }
    })
    return rows
  }

  handleChangeChat = (selected) => {
    const { changeSelected, selectedChat } = this.props
    const { groupHeader, idGroup } = selected
    if (!selectedChat) {
      this.setState((prev) => ({ ...prev, collapseChats: true }))
    } else if (groupHeader && selectedChat?.idGroup === idGroup) {
      this.setState((prev) => ({ ...prev, collapseChats: !prev.collapseChats }))
    } else {
      this.setState((prev) => ({ ...prev, collapseChats: true }))
    }
    changeSelected(selected)
  }

  renderChat = (chatRecord) => {
    const { selectedUid, humanizeDate, filterChat, chatList, selectedChat } = this.props
    const { collapseChats } = this.state
    const unreadQty = chatRecord?.unreadQty || 0

    return (
      <div
        key={`container-store-${chatRecord.groupHeader}-${chatRecord.dsGroup}-${
          chatRecord.uid
        }-${new Date().getTime()}`}
      >
        <div key={`${chatRecord.dsGroup}-${chatRecord.uid}-${new Date().getTime()}`}>
          <a
            className={`chat-contact padding ${
              chatRecord.uid === selectedChat?.uid ? 'chat-contact-selected' : ''
            }`}
            onClick={() => this.handleChangeChat(chatRecord)}
          >
            {chatRecord.inMatrix ? (
              <LogoWithoutSignature
                className='chat-contact-badge-big-selected2 padding-logo-chat'
                // src={logoWithoutSignature}
              />
            ) : (
              chatRecord.dsInitial && (
                <span
                  className={`${
                    selectedUid === chatRecord.uid
                      ? 'chat-contact-badge-big-selected'
                      : selectedUid
                      ? 'chat-contact-badge-big2'
                      : 'chat-contact-badge-big'
                  }`}
                >
                  {chatRecord.dsInitial}
                </span>
              )
            )}

            <div className='chat-contact-start'>
              {!filterChat || chatRecord.groupHeader ? (
                <div className='chat-contact-name ellipsis strong'>{chatRecord.dsChat}</div>
              ) : (
                <div className='chat-contact-name ellipsis strong'>{`${chatRecord.dsChat} - ${chatRecord.dsGroup}`}</div>
              )}

              {!this.isReadMessage(chatRecord) && <div className='unread-badge' />}

              <div className='chat-contact-message ellipsis'>
                {ChatTable.parseLastMessage(chatRecord.lastRecord)}
              </div>
            </div>

            <div className='chat-contact-time'>
              {humanizeDate(ChatTable.parseLastMessageTime(chatRecord.lastRecord))}
            </div>

            <div className='chat-contact-end'>
              <span className={`chat-contact-badge ${ChatTable.checkUnreadQtyClass(unreadQty)}`}>
                {ChatTable.parseUnreadQty(unreadQty)}
              </span>

              {!filterChat &&
                chatRecord.groupHeader &&
                (chatRecord.idGroup === selectedChat?.idGroup && collapseChats ? (
                  <i className='glyph glyph-double-arrow-up' style={{ marginRight: '5px' }} />
                ) : (
                  <i className='glyph glyph-double-arrow-down' style={{ marginRight: '5px' }} />
                ))}
            </div>
          </a>

          {chatRecord.groupHeader ? (
            <hr
              style={{
                color: '#efefef',
                backgroundColor: '#efefef',
                height: 2,
              }}
            />
          ) : (
            <hr
              style={{
                color: '#efefef',
                backgroundColor: '#efefef',
                height: 1,
              }}
            />
          )}
        </div>

        {chatRecord.groupHeader &&
          chatRecord.idGroup === selectedChat?.idGroup &&
          collapseChats &&
          chatList
            .filter((item) => item.idGroup === chatRecord.idGroup && !item.groupHeader)
            .map((row) => this.renderChat(row))}
      </div>
    )
  }

  render() {
    const { chatList, isFetchingChatList, filterChat } = this.props

    if (Object.keys(chatList).length === 0 && isFetchingChatList) {
      return this.renderIsFetchingNotification()
    }
    if (!Object.keys(chatList).length) {
      return this.renderEmptyNotification()
    }

    return (
      <div className='chat-contact-list'>
        {!filterChat && this.renderRows()}
        {filterChat && chatList.map((chat) => this.renderChat(chat))}
      </div>
    )
  }
}

ChatTable.propTypes = {
  t: PropTypes.func.isRequired,
  chatList: PropTypes.array.isRequired,
  selectedUid: PropTypes.string,
  changeSelected: PropTypes.func.isRequired,
  humanizeDate: PropTypes.func.isRequired,
  isFetchingChatList: PropTypes.bool.isRequired,
  filterChat: PropTypes.string.isRequired,
  selectedChat: PropTypes.object,
}

export default ChatTable
