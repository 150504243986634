import { ChangeEvent, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useModals,
  Loader,
  TextArea,
  Field,
  View,
  useNotifications,
} from '@mozaic-ds/react'
import i18n from 'i18next'
import { useServiceProvidersListV2Context } from '../../../../contexts'
import { updateServiceProviderRegistrationStatus } from '../../../../../../services/providers/updateServiceProviderRegistrationStatus'
import ProviderEntity from '../../../../../../models/Provider'

const MODAL_ID = 'providers-list-registration-status-modal'

interface RegistrationStatusModalProps {
  serviceProvider?: ProviderEntity
}

/**
 * @description Providers list active/inactive registration status
 */

export function RegistrationStatusModal({ serviceProvider }: RegistrationStatusModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [justification, setJustification] = useState('')
  const { open, close } = useModals()
  const { serviceProviders, setServiceProviders } = useServiceProvidersListV2Context()
  const notification = useNotifications()

  const changedRegistrationStatus = serviceProvider?.actvServcPrvdrInd === '1' ? '0' : '1'

  useEffect(() => {
    if (serviceProvider) open(MODAL_ID)
    setJustification('')
    setIsLoading(false)
  }, [serviceProvider])

  const updateServiceProvidersState = () => {
    if (serviceProviders === null) return
    const updatedServiceProviders = [...serviceProviders]

    updatedServiceProviders.forEach((item, index) => {
      if (item.servcPrvdrId === serviceProvider?.servcPrvdrId) {
        updatedServiceProviders[index] = {
          ...item,
          actvServcPrvdrInd: changedRegistrationStatus,
        }

        if (changedRegistrationStatus === '1') {
          updatedServiceProviders[index] = {
            ...item,
            actvServcPrvdrInd: '1',
          }
        } else {
          updatedServiceProviders[index] = {
            ...item,
            actvServcPrvdrInd: '0',
            actvServcPrvdrOrdRecvInd: 0,
          }
        }
      }
    })

    setServiceProviders(updatedServiceProviders)
  }

  const onConfirm = async () => {
    if (!serviceProvider) return

    try {
      setIsLoading(true)
      await updateServiceProviderRegistrationStatus({
        actvServcPrvdrInd: changedRegistrationStatus,
        description: justification,
        servcPrvdrTmlineEvntDesc: justification,
        servcPrvdrId: serviceProvider.servcPrvdrId,
      })
      updateServiceProvidersState()
      notification.success(
        {
          title: i18n.t('pwa.toast.success.message'),
          message: i18n.t('successfullyPerformedOperation'),
          duration: 8000,
        },
        'top',
      )
    } catch (error: any) {
      console.error('Error on inactive/active service provider', error)
      notification.danger(
        {
          title: i18n.t('pwa.toast.error.message'),
          message: error?.message?.dsMessage || '',
          duration: 8000,
        },
        'top',
      )
    } finally {
      setIsLoading(false)
      close(MODAL_ID)
    }
  }

  const onChangeJustification = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    const removedDoubleSpaces = value.replace(/\s\s+/g, ' ')
    setJustification(removedDoubleSpaces.trimStart())
  }

  const isConfirmButtonDisabled = () => {
    if (isLoading) return true

    const withoutSpaceJustification = justification.replace(/\s+/g, '')
    const isJustificationOnlyNumbers = withoutSpaceJustification.match(/^[0-9]+$/) !== null
    const isJustificationOnlySpaces = withoutSpaceJustification.match(/^\s+$/) !== null
    const isJustificationOnlySpecialCharacters =
      withoutSpaceJustification.match(/^[^a-zA-Z0-9]+$/) !== null
    const isJustificationLessThan10Characters = withoutSpaceJustification.length < 10
    const isLastCharacterASpace = justification.slice(-1) === ' '

    const isJustificationInvalid =
      isJustificationOnlyNumbers ||
      isJustificationOnlySpaces ||
      isJustificationOnlySpecialCharacters ||
      isJustificationLessThan10Characters ||
      (isJustificationLessThan10Characters && isLastCharacterASpace)

    return isJustificationInvalid
  }

  return (
    <Modal
      id={MODAL_ID}
      canCloseOnOverlayClick={false}
      canCloseOnEscapePress={!isLoading}
      focusedElementIndex={-1}
    >
      <ModalHeader>
        <ModalTitle>
          {i18n.t(`justificationModal.actvServcPrvdrInd.title.${changedRegistrationStatus}`)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <View marginBottom='mu100'>
          <Text textAlign='center'>
            {i18n.t(`justificationModal.actvServcPrvdrInd.text.${changedRegistrationStatus}`)}
          </Text>
        </View>
        <View marginBottom='mu050'>
          <Field
            label={i18n.t('justificationModal.desc.placeholder')}
            help={i18n.t('field.text.description')}
          >
            <TextArea
              placeholder={i18n.t('justificationModal.desc.placeholder')}
              value={justification}
              onChange={onChangeJustification}
            />
          </Field>
        </View>
      </ModalBody>
      <ModalFooter>
        <Button
          size='m'
          variant='bordered'
          theme='primary'
          width='full'
          onClick={() => close(MODAL_ID)}
          isDisabled={isLoading}
        >
          {i18n.t(`justificationModal.actvServcPrvdrAgntInd.cancel.${changedRegistrationStatus}`)}
        </Button>
        <Button
          size='m'
          width='full'
          onClick={onConfirm}
          isDisabled={isConfirmButtonDisabled()}
          className='confirm-btn'
        >
          {isLoading ? (
            <Loader size='s' theme='light' />
          ) : (
            i18n.t(`justificationModal.actvServcPrvdrInd.confirm.${changedRegistrationStatus}`)
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
