import PropTypes from 'prop-types'
import { Component } from 'react'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import TitleSection from '../../../components/utils/TitleSection'

class AcceptedBudgets extends Component {
  state = {
    selected: { data: null },
    selectedReason: null,
    renderNegativeAnswerReasons: false,
  }

  handleOptionAccepted = (option) => {
    const { budgetAccepted, serviceOrderOccurrenceQuotationRefusedByExpiration } = this.props
    const { selected } = this.state

    if (option === selected.data) {
      this.setState({ selected: { data: null } })
      // eslint-disable-next-line no-param-reassign
      option = null
    } else {
      this.setState({ selected: { data: option } })
    }

    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      this.setState({
        renderNegativeAnswerReasons: !option,
      })
    }

    budgetAccepted(option)
  }

  componentDidMount() {
    const { serviceOrderOccurrenceQuotationRefusedByExpiration } = this.props
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      this.handleOptionAccepted(false)
    }
  }

  getClassName = (type) => {
    const { selected } = this.state
    if (type) {
      return `option ${selected.data === true ? 'selected' : 'no-selected'}`
    }
    return `option ${selected.data === false ? 'selected' : 'no-selected'}`
  }

  getClassNameSubtitle = (type) => {
    const { selected } = this.state
    if (type) {
      return `text-sub-option ${selected.data === true ? 'visible' : 'no-visible'}`
    }
    return `text-sub-option ${selected.data === false ? 'visible' : 'no-visible'}`
  }

  renderOptions = (reason) => {
    const { selectedReason } = this.state
    const { onChange } = this.props

    const checked = selectedReason === reason.servcOrdEvntTypRsnId
    const updateSelectedReason = (selectedReason) => {
      onChange('selectedReason', selectedReason)
      this.setState({ selectedReason })
    }
    return (
      <label className='options-label'>
        <input
          className='option-return'
          type='radio'
          checked={checked}
          onChange={() => updateSelectedReason(reason.servcOrdEvntTypRsnId)}
        />
        <span>{reason.servcOrdEvntTypRsnDesc}</span>
      </label>
    )
  }

  getAcceptedOptionNoSubtitle = () => {
    const { serviceOrderOccurrenceQuotationRefusedByExpiration, t } = this.props
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      return serviceOrderOccurrenceQuotationRefusedByExpiration.descOcorOrdemServico
    }
    return t('servc.ord.budget.component.accepted.option.no.subtitle')
  }

  renderNegativeAnswerReasons = () => {
    const { t, unnacomplishedReasonsList } = this.props
    return (
      <Row>
        <Col className='render-negative-answer'>
          <strong>{t('custEval.reasonOptions')}</strong>
          <span>{unnacomplishedReasonsList.map(this.renderOptions)}</span>
        </Col>
      </Row>
    )
  }

  getClassNameBody = () => {
    const { serviceOrderOccurrenceQuotationRefusedByExpiration } = this.props
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      return 'body-budget-distributed block'
    }
    return ''
  }

  render() {
    const { t } = this.props

    const { renderNegativeAnswerReasons } = this.state

    return (
      <div>
        <Grid fluid className={this.getClassNameBody()}>
          <TitleSection>
            <h1>{t('servc.ord.budget.component.accepted.title')}</h1>
          </TitleSection>
          <div className='body-budgets-accepted'>
            <h2 className='subtitle'>{t('servc.ord.budget.component.accepted.subtile')}</h2>
            <div className='body no-selected'>
              <div
                className={this.getClassName(true)}
                onClick={() => this.handleOptionAccepted(true)}
              >
                <p className='text-option'>{t('servc.ord.budget.component.accepted.option.yes')}</p>
                <p className={this.getClassNameSubtitle(true)}>
                  {t('servc.ord.budget.component.accepted.option.yes.subtitle')}
                </p>
              </div>
              <div
                className={this.getClassName(false)}
                onClick={() => this.handleOptionAccepted(false)}
              >
                <p className='text-option'>{t('servc.ord.budget.component.accepted.option.no')}</p>
                <p className={this.getClassNameSubtitle(false)}>
                  {this.getAcceptedOptionNoSubtitle()}
                </p>
              </div>
            </div>
          </div>
        </Grid>

        <Grid fluid className='body-budget-distributed'>
          {renderNegativeAnswerReasons && this.renderNegativeAnswerReasons()}
        </Grid>
      </div>
    )
  }
}

AcceptedBudgets.propTypes = {
  t: PropTypes.func.isRequired,
  unnacomplishedReasonsList: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  budgetAccepted: PropTypes.func,
  serviceOrderOccurrenceQuotationRefusedByExpiration: PropTypes.object,
}

export default AcceptedBudgets
