import i18next from 'i18next'

import ErrorFallback from '../../../../../../errors/fallbacks'
import ErrorBoundary from '../../../../../../errors/boundaries'

import { useServiceOrderDetailContext } from '../../../../context'
import MozaicBadge from '../../../../../../components/MozaicBadge'

function ServiceOrderTags() {
  const { tags, loadingTags, fetchOrderTags } = useServiceOrderDetailContext()

  if (loadingTags)
    return (
      <>
        <div className='order-tag-skeleton skeleton' />
        <div className='order-tag-skeleton skeleton' />
        <div className='order-tag-skeleton skeleton' />
      </>
    )

  if (tags?.length === 0) return <></>

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetErrorBoundary={() => fetchOrderTags()}>
      <div id='service-order-tags-container'>
        {tags.map((tag) => (
          <MozaicBadge text={i18next.t(tag.labelKey)} key={tag.labelKey} theme={tag.style} />
        ))}
      </div>
    </ErrorBoundary>
  )
}

export default ServiceOrderTags
