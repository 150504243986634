import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import TriangleImg from '../../../../assets/icons/filled-triangle.svg'

function MenuLink({ item, isOpen = false }) {
  const hasSubmenu = item.submenus?.length

  const linkBody = (
    <>
      <div className='icon'>
        {typeof item.icon === 'string' ? <img src={item.icon} alt='' /> : <>{item.icon}</>}
      </div>
      <div
        className={classnames('label-wrapper', {
          'fade-in': isOpen,
        })}
      >
        <span>{item.label}</span>
        {hasSubmenu && <img className='toggle-submenu-icon' src={TriangleImg} alt='' />}
      </div>
    </>
  )

  if (!hasSubmenu) {
    if (item.newTab) {
      return (
        <a href={item.url} target='_blank' rel='noreferrer'>
          {linkBody}
        </a>
      )
    }

    return <Link to={{ pathname: item.url, search: item?.queryParams }}>{linkBody}</Link>
  }
  return <a>{linkBody}</a>
}

MenuLink.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
}

export default MenuLink
