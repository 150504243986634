import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import localforage from 'localforage'

import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import {
  FETCH_ALL_NOTIFICATIONS_ON_FIREBASE,
  FETCH_CHAT_LIST_ERROR,
  FETCH_CHAT_LIST_REQUEST,
  FETCH_CHAT_LIST_SUCCESS,
  FETCH_NOTIFICATIONS_ON_FIREBASE_SUCCESS,
  FILTER_CHAT_LIST,
  UPDATE_CHAT_LIST,
} from '../../actionTypes/chat'

const firebaseRefs = []

/*
 * REQUEST Chat
 */

export const fetchNotificationsSuccess = (uid, qty) => ({
  type: FETCH_NOTIFICATIONS_ON_FIREBASE_SUCCESS,
  payload: { uid, qty },
})

export const filterChatList = (text) => ({
  type: FILTER_CHAT_LIST,
  payload: text,
})

export const updateChatList = (uid, values) => ({
  type: UPDATE_CHAT_LIST,
  payload: { uid, values },
})

export const fetchChatListRequest = () => ({
  type: FETCH_CHAT_LIST_REQUEST,
})

export const fetchChatListSuccess = (data, filter) => ({
  type: FETCH_CHAT_LIST_SUCCESS,
  payload: data,
  filter,
})

export const fetchChatListError = (error) => ({
  type: FETCH_CHAT_LIST_ERROR,
  payload: error,
})

export const fetchNotifications = (chat, device) => (dispatch) => {
  const eventType = 'child_added'
  const path = `messages/${chat.uid}`
  let ref = firebaseRefs[path]

  if (!ref) {
    ref = firebase.database().ref(path).orderByChild('isRead').equalTo(false)
  } else {
    ref.off(eventType)
  }

  ref.on(eventType, (snapshot) => {
    const lastMessage = snapshot.val()

    if (lastMessage.device !== device) {
      dispatch(fetchNotificationsSuccess(chat.uid, 1))
    }

    dispatch(updateChatList(chat.uid, { lastMessage }))
  })

  firebaseRefs[path] = ref
}

export const fetchAllNotifications = (data, device) => (dispatch) => {
  data.forEach((chat) => dispatch(fetchNotifications(chat, device)))

  return {
    type: FETCH_ALL_NOTIFICATIONS_ON_FIREBASE,
  }
}

export const fetchChatList = (token, filter = {}, dispatch, device, chatEnabled) =>
  new Api(token, dispatch)
    .post(API_URL.FIND_CHAT_LIST, filter)
    .then((response) => {
      const data = response.data || []
      localforage.setItem('chatRecords', data)
      dispatch(fetchChatListSuccess(data, filter))
      if (chatEnabled) {
        dispatch(fetchAllNotifications(data, device))
      }
    })
    .catch((error) => {
      dispatch(fetchChatListError(error))
    })

export const fetchChatPageList =
  (token, filter = {}, device, chatEnabled) =>
  (dispatch) => {
    dispatch(fetchChatListRequest())
    localforage
      .getItem('chatRecords')
      .then((data) => {
        if (data == null || !data.length) {
          fetchChatList(token, filter, dispatch, device, chatEnabled)
        } else {
          dispatch(fetchChatListSuccess(data, filter))
          if (chatEnabled) {
            dispatch(fetchAllNotifications(data, device))
          }
        }
      })
      .catch((error) => {
        console.log('find chat records', error)
      })
  }
