import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component, memo, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import JustificationModal from '../../components/utils/JustificationModal'
import Spinner from '../../components/utils/Spinner'
import { FORMAT_SINGLE_DATE_PICKER } from '../../constants/config'
import { useProviderContext } from '../../contexts/provider'
import { formatCEP, formatCNPJ } from '../../helpers/masks'
import store from '../../redux/store'
import { downloadFile } from '../../services/amazonAws/DownloadFile'
import ServicePersonModal, {
  PROVIDER_DOCUMENTS,
} from '../ServiceSpecialistEdit/components/ServicePersonModal'
import Form from './components/EditForm'

class ServiceProviderEditContainer extends Component {
  state = {
    serviceProviderModal: false,
    filesToAdd: { files: [] },
    properties: {},
    justificationModal: { open: false },
    validation: true,
    actvServcPrvdrInd: null,
    loading: false,
    error: {},
  }

  async componentDidMount() {
    const {
      fetchServiceProviderFiles,
      getServiceProvider,
      history,
      auth,
      match: {
        params: { id },
      },
    } = this.props

    if (id) {
      this.setState({ loading: true })

      const isFromProvider = location.pathname.includes('view')
        ? false
        : location.query && location.query.requestOriginPortal === 'provider'

      const provider = await getServiceProvider(auth.user.dsToken, id, isFromProvider)

      if (provider?.servcPrvdrId === undefined) return history.push(`/providers`)

      fetchServiceProviderFiles(auth.user.dsToken, id)

      this.setState({ loading: false })
    }

    this.sortPhone()
  }

  sortPhone = () => {
    const { serviceProvider } = this.props

    if (serviceProvider?.lsPhones !== undefined) {
      const phones = []
      phones[0] = this.findPhone(1)
      phones[1] = this.findPhone(3)
      phones[2] = this.findPhone(4)
      serviceProvider.lsPhones = phones
    }
  }

  resetError = (fields) => {
    const { error } = this.state
    const errorFields = error?.fields

    // eslint-disable-next-line no-param-reassign
    if (typeof fields === 'string') fields = [fields]
    if (!error) return false
    if (!errorFields?.length) return false

    const newFields = errorFields.filter((err) => !fields.includes(err.naField))

    this.setState({ error: { ...error, fields: newFields } })
  }

  findPhone = (typePhone) => {
    const { serviceProvider } = this.props

    if (serviceProvider?.lsPhones !== undefined) {
      const { lsPhones } = serviceProvider
      for (let i = 0; i < lsPhones.length; i++) {
        if (
          lsPhones[i] !== undefined &&
          lsPhones[i].idTipoMeioComunicacao !== undefined &&
          lsPhones[i].idTipoMeioComunicacao === typePhone
        ) {
          return lsPhones[i]
        }
      }
    }
  }

  getPhone = (phone) => {
    let newPhone = {
      valMeioComunicacao: '',
    }

    if (phone) {
      newPhone = {
        idPrestadorServico: phone.idPrestadorServico,
        idTipoMeioComunicacao: phone.idTipoMeioComunicacao,
        idSeqMeioComunicacao: phone.idSeqMeioComunicacao,
        valMeioComunicacao: phone.valMeioComunicacao,
      }
    }

    return newPhone
  }

  toggleServiceProviderModal = () => {
    const {
      t,
      auth: {
        user: { accessFunctionList },
      },
      serviceProvider,
      providerFiles,
    } = this.props
    const { serviceProviderModal } = this.state

    const canEdit = accessFunctionList?.some((accessFunction) => accessFunction === 19)

    if (serviceProvider?.disabled || !canEdit) {
      if (!providerFiles || !providerFiles.length) {
        return alert(t('serviceProvider.noDocuments'))
      }
    }

    this.setState({ serviceProviderModal: !serviceProviderModal })
  }

  isView = () => location.pathname.includes('view')

  renderServiceProviderModal = () => {
    const { serviceProviderModal, filesToAdd } = this.state
    const {
      t,
      auth,
      filesError,
      auth: {
        user: { accessFunctionList },
      },
    } = this.props
    const { serviceProvider, providerFiles } = this.props

    const serviceProviderFiles = providerFiles

    const canEdit = accessFunctionList?.some((accessFunction) => accessFunction === 19)

    if (!serviceProviderModal) return

    const attachments = serviceProvider.servcPrvdrId ? serviceProviderFiles : filesToAdd.files

    const props = {
      t,
      modalType: PROVIDER_DOCUMENTS,
      closeModal: this.toggleServiceProviderModal,
      initialValues: {
        servcPrvdrId: serviceProvider.servcPrvdrId,
      },
      auth,
      attachments,
      error: filesError,
      onSubmit: this.handleSaveServiceProviderFiles,
      onDelete: this.handleRemoveServiceProviderFiles,
      downloadFile: this.handleDownloadFile,
      disabled: this.isView() || serviceProvider?.disabled || !canEdit,
      updateLocalFiles: this.updateLocalFiles,
      dispatch: store.dispatch,
    }

    return <ServicePersonModal {...props} />
  }

  handleDownloadFile = (fileName, attachmentDescription, src = null) => {
    if (!fileName || fileName.length === 0 || fileName === '' || fileName == null) {
      return null
    }

    downloadFile(fileName, attachmentDescription, src)
  }

  handleSaveServiceProviderFiles = (values) => {
    const {
      saveServiceProviderFiles,
      auth: { user },
    } = this.props

    return saveServiceProviderFiles(user.dsToken, values)
  }

  handleRemoveServiceProviderFiles = (servcPrvdrId, idAnexoPrestador) => {
    const {
      removeFile,
      auth: { user },
    } = this.props

    return removeFile(user.dsToken, servcPrvdrId, idAnexoPrestador)
  }

  updateLocalFiles = (files) => {
    this.setState({ filesToAdd: { files } })
  }

  getAddress = (address) => ({
    cnpjDescAddress: typeof address[0] !== 'undefined' ? address[0].descAddress : '',
    cnpjNumAddress: typeof address[0] !== 'undefined' ? address[0].numAddress : '',
    cnpjDescComplAddress: typeof address[0] !== 'undefined' ? address[0].descComplAddress : '',
    cnpjDescNeighborhoodAddress:
      typeof address[0] !== 'undefined' ? address[0].descNeighborhoodAddress : '',
    cnpjNumCepAddress: typeof address[0] !== 'undefined' ? address[0].numCepAddress : '',
    cnpjNameLocaleAddress: typeof address[0] !== 'undefined' ? address[0].nameLocaleAddress : '',
    cnpjDescReferAddress: typeof address[0] !== 'undefined' ? address[0].descReferAddress : '',
    cnpjInitStateAddress: typeof address[0] !== 'undefined' ? address[0].initStateAddress : '',
    cnpjAddrLatNr: typeof address[0] !== 'undefined' ? address[0].servcPrvdrAddrLatNr : '',
    cnpjAddrLongNr: typeof address[0] !== 'undefined' ? address[0].servcPrvdrAddrLongNr : '',
    cnpjAddressId: typeof address[0] !== 'undefined' ? address[0].servcPrvdrAddressId : '',
  })

  openJustificationModal = (servcPrvdrId, field, callback) => {
    if (servcPrvdrId) {
      this.setState({
        justificationModal: { open: true, servcPrvdrId, field, callback },
      })
    } else {
      this.setState({ [field.key]: field.value })
    }
  }

  disablePrvdrOs = async (dsToken, params) => {
    const { inactiveProvider } = this.props
    await inactiveProvider(dsToken, params)
  }

  closeJustificationModal = () => {
    this.setState({ justificationModal: { open: false } })
  }

  renderJustificationModal = () => {
    const { t } = this.props

    const { justificationModal, validation } = this.state

    if (!justificationModal.open) {
      return
    }

    return (
      <JustificationModal
        closeModal={this.closeJustificationModal}
        initialValues={this.getJustificationModalInitialValues()}
        fieldKey={justificationModal.field.key}
        fieldValue={justificationModal.field.value}
        onSubmit={this.handleSubmitJustificationModal}
        validation={validation}
        t={t}
      />
    )
  }

  getJustificationModalInitialValues = () => {
    const { justificationModal } = this.state

    return {
      field: justificationModal.field.value,
      description: '',
    }
  }

  handleSubmitJustificationModal = async (values) => {
    const { auth } = this.props
    const { serviceProvider } = this.props

    const { justificationModal } = this.state

    const params = {
      ...values,
      servcPrvdrId: justificationModal.servcPrvdrId,
    }

    this.closeJustificationModal()

    await justificationModal.callback(auth.user.dsToken, params)

    if (values.actvServcPrvdrInd) {
      serviceProvider.actvServcPrvdrInd = values.actvServcPrvdrInd
    }
  }

  handleSetActvServcPrvdrOrdRecvInd = async (token, values) => {
    const { setActvServcPrvdrOrdRecvInd } = this.props

    await setActvServcPrvdrOrdRecvInd(token, values)

    this.form.setValueToField('actvServcPrvdrOrdRecvInd', values.actvServcPrvdrOrdRecvInd)
  }

  beforeUpdate = (token, params) => {
    const { update } = this.props
    const { serviceProvider } = this.props
    const { handleAddressErrors } = this

    const { filesToAdd } = this.state

    const newParams = {
      ...params,
      filesToAdd,
      servcPrvdrAddRgstrnCurrntStepNr: serviceProvider.servcPrvdrAddRgstrnCurrntStepNr,
    }

    return update(token, newParams, false, false).catch((error) =>
      this.setState({ error: handleAddressErrors(error) }),
    )
  }

  handleAddressErrors(errors) {
    if (errors && Array.isArray(errors.fields)) {
      errors.fields.map((error) => {
        if (error.naField.indexOf('address[0].') !== -1) {
          let field = error.naField.replace('address[0].', '')
          field = `cnpj${field.charAt(0).toUpperCase()}${field.slice(1)}`
          // eslint-disable-next-line no-param-reassign
          error.naField = field
        }
      })
    }

    return errors
  }

  render() {
    const {
      t,
      auth,
      auth: {
        user: { accessFunctionList },
      },
      inactiveProvider,
      match: {
        params: { id },
      },
      serviceProvider,
      history,
    } = this.props

    const { loading, error } = this.state

    if (loading || !serviceProvider) return <Spinner visible />

    const { actvServcPrvdrInd, actvServcPrvdrOrdRecvInd, filesToAdd } = this.state

    const phone1 = serviceProvider?.lsPhones?.length
      ? serviceProvider?.lsPhones[0]
      : {
          idTipoMeioComunicacao: 1,
          valMeioComunicacao: '',
        }

    const phone2 = serviceProvider?.lsPhones?.length
      ? serviceProvider?.lsPhones[1]
      : {
          idTipoMeioComunicacao: 3,
          valMeioComunicacao: '',
        }

    const phone3 = serviceProvider?.lsPhones?.length
      ? serviceProvider?.lsPhones[2]
      : {
          idTipoMeioComunicacao: 4,
          valMeioComunicacao: '',
        }

    const lsPhones = [this.getPhone(phone1), this.getPhone(phone2), this.getPhone(phone3)]

    if (!serviceProvider?.hasOwnProperty('address')) {
      serviceProvider.address = [{}]
    }

    const address = this.getAddress(serviceProvider?.address)

    let initialValues = {
      lsPhones,
      address,
      servcPrvdrAvgGrade: 0.0,
      servcPrvdrCreatDt: moment().format(FORMAT_SINGLE_DATE_PICKER),
      actvServcPrvdrInd: actvServcPrvdrInd || '1',
      actvServcPrvdrOrdRecvInd: actvServcPrvdrOrdRecvInd || '1',
    }

    if (id) {
      initialValues = {
        servcPrvdrId: serviceProvider?.servcPrvdrId,
        servcPrvdrNm: serviceProvider?.servcPrvdrNm,
        servcPrvdrTrdNm: serviceProvider?.servcPrvdrTrdNm,
        servcPrvdrFedrlTaxIdNr: formatCNPJ(serviceProvider?.servcPrvdrFedrlTaxIdNr, t),
        servcPrvdrCreatDt: serviceProvider?.servcPrvdrCreatDt,
        servcPrvdrMainPlntCd: serviceProvider?.servcPrvdrMainPlntCd,
        servcPrvdrAcctntCd: serviceProvider?.servcPrvdrAcctntCd,
        servcPrvdrEmailTxt: serviceProvider?.servcPrvdrEmailTxt,
        actvServcPrvdrInd: actvServcPrvdrInd || serviceProvider?.actvServcPrvdrInd,
        lsBranch: serviceProvider?.lsServcPrvdrMainPlntCd,
        lsPhones,
        phone1: lsPhones[0]?.valMeioComunicacao,
        phone2: lsPhones[1]?.valMeioComunicacao,
        phone3: lsPhones[2]?.valMeioComunicacao,
        address,
        cnpjDescAddress: address.cnpjDescAddress,
        cnpjNumAddress: address.cnpjNumAddress,
        cnpjDescComplAddress: address.cnpjDescComplAddress,
        cnpjDescNeighborhoodAddress: address.cnpjDescNeighborhoodAddress,
        cnpjNumCepAddress: formatCEP(address.cnpjNumCepAddress, t),
        cnpjNameLocaleAddress: address.cnpjNameLocaleAddress,
        cnpjDescReferAddress: address.cnpjDescReferAddress,
        cnpjInitStateAddress: address.cnpjInitStateAddress,
        cnpjAddrLatNr: address.cnpjAddrLatNr,
        cnpjAddrLongNr: address.cnpjAddrLongNr,
        servcPrvdrBankNr: serviceProvider?.servcPrvdrBankNr,
        servcPrvdrBankAcctNr: serviceProvider?.servcPrvdrBankAcctNr,
        servcPrvdrBankBrnchNr: serviceProvider?.servcPrvdrBankBrnchNr,
        servcPrvdrBankAcctTyp: serviceProvider?.servcPrvdrBankAcctTyp,
        servcPrvdrBankBrnchDigtNr: serviceProvider?.servcPrvdrBankBrnchDigtNr,
        servcPrvdrBankAcctDigtNr: serviceProvider?.servcPrvdrBankAcctDigtNr,
        servcPrvdrAvgGrade: serviceProvider?.servcPrvdrAvgGrade,
        actvServcPrvdrOrdRecvInd:
          actvServcPrvdrOrdRecvInd || serviceProvider?.actvServcPrvdrOrdRecvInd,
        filesToAdd,
        servcPrvdrCityCoRgstrnNr: serviceProvider?.servcPrvdrCityCoRgstrnNr,
        servcPrvdrStCoRgstrnNr: serviceProvider?.servcPrvdrStCoRgstrnNr,
        validIntegration: serviceProvider?.validIntegration,
        sysAcsUserLastLognTs: serviceProvider?.sysAcsUserLastLognTs,
        lastLognSysVerCd: serviceProvider?.lastLognSysVerCd,
        sysAcsUserId: serviceProvider?.sysAcsUserId,
        servcPrvdrPaymentMargin: serviceProvider?.servcPrvdrPaymentMargin,
        servcPrvdrAddRgstrnCurrntStepNr: serviceProvider?.servcPrvdrAddRgstrnCurrntStepNr,
        identifyRegistrationOrigin: serviceProvider?.identifyRegistrationOrigin,
      }
    }

    return (
      <section>
        <BreadcrumbBar title={t('lmi.serviceProviders')} />
        <section>
          {(serviceProvider?.servcPrvdrId === parseInt(id) ||
            location.href.indexOf('new') > -1) && (
            <Form
              ref={(instance) => {
                this.form = instance
              }}
              disablePrvdrOs={this.disablePrvdrOs}
              t={t}
              initialValues={initialValues}
              auth={auth}
              error={error}
              disabled={
                this.isView() ||
                serviceProvider?.disabled ||
                !accessFunctionList?.some((accessFunction) => accessFunction === 19)
              }
              dispatch={store.dispatch}
              toggleServiceProviderModal={this.toggleServiceProviderModal}
              openJustificationModal={this.openJustificationModal}
              setActvServcPrvdrOrdRecvInd={this.handleSetActvServcPrvdrOrdRecvInd}
              inactive={inactiveProvider}
              update={this.beforeUpdate}
              index={id}
              resetError={this.resetError}
              canEditBranchsAndGroups={
                !this.isView() &&
                !serviceProvider?.disabled &&
                accessFunctionList?.some(
                  (accessFunction) => accessFunction === 118 || accessFunction === 19,
                )
              }
              serviceProvider={serviceProvider}
              history={history}
            />
          )}
        </section>
        {this.renderServiceProviderModal()}
        {this.renderJustificationModal()}
      </section>
    )
  }
}

ServiceProviderEditContainer.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  providerFiles: PropTypes.array,
  serviceProvider: PropTypes.object.isRequired,
  getServiceProvider: PropTypes.func.isRequired,
  fetchServiceProviderFiles: PropTypes.func.isRequired,
  inactiveProvider: PropTypes.func.isRequired,
  saveServiceProviderFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  setActvServcPrvdrOrdRecvInd: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  filesError: PropTypes.object,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const MemoComponent = memo(ServiceProviderEditContainer)

function ProviderEditWC(props) {
  const {
    provider,
    providerFiles,
    handleFetchProviderFiles,
    handleSaveProviderFiles,
    handleRemoveProviderFile,
    handleActiveProviderToReceiveOs,
    handleFetchProvider,
    handleUpdateProvider,
    inactiveProvider,
  } = useProviderContext()

  const history = useHistory()

  const newProps = useMemo(
    () => ({
      history,
      providerFiles,
      serviceProvider: provider,
      getServiceProvider: handleFetchProvider,
      fetchServiceProviderFiles: handleFetchProviderFiles,
      saveServiceProviderFiles: handleSaveProviderFiles,
      removeFile: handleRemoveProviderFile,
      inactiveProvider,
      setActvServcPrvdrOrdRecvInd: handleActiveProviderToReceiveOs,
      update: handleUpdateProvider,
    }),
    [provider, providerFiles, history],
  )

  return <MemoComponent {...newProps} {...props} />
}

const ServiceProviderEditTranslated = withTranslation()(ProviderEditWC)

export default connect(mapStateToProps, null)(ServiceProviderEditTranslated)

export { ServiceProviderEditContainer as ServiceProviderEdit }
