import { memo } from 'react'
import i18next from 'i18next'

import MozaicIcon from '../../../MozaicIcon'

interface EditButtonProps {
  onClickEditButton: () => void
}

function EditButton(props: EditButtonProps) {
  const { onClickEditButton } = props

  return (
    <button type='button' className='card-edit' onClick={() => onClickEditButton()}>
      <>
        {i18next.t('accordeon.action.edit')}
        <MozaicIcon icon='PublishEdit24' />
      </>
    </button>
  )
}

export default memo(EditButton)
