import styled from '@emotion/styled'

/*
  Description component: reusable component to show render a custom spacer
*/
type SpacerType = {
  width?: string
  height?: string
  marginTop?: string
  marginBottom?: string
  margin?: string
  background?: string
}

const Spacer = styled.section((props: SpacerType) => ({
  width: props.width || '100%',
  height: props.height || '1px',
  marginTop: props.marginTop || '0px',
  marginBottom: props.marginBottom || '0px',
  margin: props.margin || '16px 0px 16px 0px',
  background: props.background || '#A19BA2',
}))

export default Spacer
