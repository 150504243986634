import { useEffect, useState } from 'react'
import { useProviderAnalysisContext } from '../../context'
import { checkChartType, accordeonFilter, chartLines, chartData, chartBars } from './util'

import Accordeon from '../../../../components/Accordeon'
import LineChart from '../../../../components/LineChart'
import BarChart from '../../../../components/BarChart'
import TitleExport from '../TitleExport'
import FilterNew from '../FilterNew'
import Filter from '../Filter'
import Table from '../Table'

function FilterGroup() {
  const { storesOptions, clustersOptions, agregationField, optionsLoaded } =
    useProviderAnalysisContext()

  const [filters, setFilters] = useState([])
  const [loaders, setLoaders] = useState([])
  const [reset, setReset] = useState(false)

  const setFilter = (index, payload) => {
    setFilters(
      [...filters].map((filter, i) => {
        if (i === index) {
          return {
            ...filter,
            ...payload,
          }
        }
        return filter
      }),
    )
  }

  const setLoader = (index, state) => {
    setLoaders(
      [...loaders].map((loader, i) => {
        if (i === index) return state
        return loader
      }),
    )
  }

  const clearFilter = (index) => {
    const newFilters = [...filters].filter((filter, i) => index !== i)
    setFilters(newFilters)

    setReset(true)
    setTimeout(() => setReset(false), 500)
  }

  useEffect(() => {
    if (optionsLoaded) {
      setFilters(
        [...filters].map((filter) => ({
          ...filter,
          stores: storesOptions,
          clusters: clustersOptions,
        })),
      )
    }
  }, [optionsLoaded])

  const addNewFilter = (filter) => {
    const tempFilters = [...filters]
    tempFilters.push(filter)

    const tempLoaders = [...loaders]
    tempLoaders.push(true)

    setFilters(tempFilters)
    setLoaders(tempLoaders)
  }

  const renderChart = (filter) => {
    const chartType = checkChartType(filter)

    const keys =
      filter.data.informationToCompare !== '0'
        ? filter.data
        : { information: filter.data.information }

    keys.agregationField = agregationField

    const ifRating =
      filter.data.information === 'ratingServices' || filter.data.information === 'ratingInstallers'

    const domain = ifRating ? [0, 5] : 'auto'
    const ticks = ifRating ? [1, 2, 3, 4, 5] : null
    const margins = {
      top: 5,
      right: 51,
      left: -15,
      bottom: 5,
    }

    if (chartType === 'lineChart') {
      return (
        <>
          <TitleExport filter={filter} keys={keys} />
          <LineChart
            data={chartData(filter.results, keys)}
            lines={chartLines(keys)}
            domain={domain}
            ticks={ticks}
            margin={margins}
            XAxisDataKey={agregationField}
          />
          <Table filter={filter} keys={keys} />
        </>
      )
    }

    if (chartType === 'barChart') {
      return (
        <>
          <TitleExport filter={filter} keys={keys} />
          <BarChart
            data={chartData(filter.results, keys)}
            bars={chartBars(keys)}
            domain={domain}
            ticks={ticks}
            margin={margins}
            XAxisDataKey={agregationField}
          />
          <Table filter={filter} keys={keys} />
        </>
      )
    }
  }

  const lastFilterIsRendered = !!filters.at(-1)?.results?.length || !filters?.length

  return (
    <div className='filter-group'>
      {filters.map((filter, i) => (
        <div className='analysis' key={`filter-${i}`}>
          <Accordeon header={accordeonFilter}>
            <Filter
              filter={filter}
              index={i}
              reset={reset}
              setFilter={setFilter}
              setLoader={setLoader}
              clearFilter={clearFilter}
            />
            {!!filter?.results?.length && !loaders[i] && renderChart(filter)}
            {filter?.empty && <TitleExport filter={filter} />}
            {loaders[i] && <div className='skeleton skeleton-provider-detail' />}
          </Accordeon>
        </div>
      ))}

      {lastFilterIsRendered && (
        <div className='analysis'>
          <Accordeon header={accordeonFilter}>
            <FilterNew addNewFilter={addNewFilter} />
          </Accordeon>
        </div>
      )}
    </div>
  )
}

export default FilterGroup
