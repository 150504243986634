import i18next from 'i18next'
import MozaicIcon from '../../../../../MozaicIcon'

function EditButton() {
  return (
    <div className='accordeon-edit'>
      <span>{i18next.t('accordeon.action.edit')}</span>
      <MozaicIcon icon='PublishEdit24' color='primary' />
    </div>
  )
}

export default EditButton
