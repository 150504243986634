import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import queryString from 'query-string'

import { fetchServiceProviders } from '../../../services/providers/fetchServiceProviders'
import { FiltersForm } from '../components/Filters'
import { useAdvancedSearchContext } from '../../../contexts/advancedSearch'

const SIZE_LIMIT = 10

type HandleFetchServiceProvidersParams = {
  page?: number
  size?: number
  fields?: FiltersForm
}

export interface ServiceProvidersListV2ProviderType {
  serviceProviders: any[] | null
  setServiceProviders: (any: any) => void
  isFetchServiceProvidersLoading: boolean
  isFetchServiceProvidersError: boolean
  handleFetchServiceProviders: (params: HandleFetchServiceProvidersParams) => Promise<void>
  filters: any
  setFilters: any
  currentPage: number
  setCurrentPage: (page: number) => void
  pagesTotal: number
  total: number
}

interface ServiceProvidersListV2ProviderProps {
  children: ReactNode
}

const ServiceProvidersListV2Context = createContext<ServiceProvidersListV2ProviderType>(
  {} as ServiceProvidersListV2ProviderType,
)

export function ServiceProvidersListV2Provider({ children }: ServiceProvidersListV2ProviderProps) {
  const [serviceProviders, setServiceProviders] = useState(null)
  const [isFetchServiceProvidersLoading, setIsFetchServiceProvidersLoading] = useState(false)
  const [isFetchServiceProvidersError, setIsFetchServiceProvidersError] = useState(false)
  const [filters, setFilters] = useState({} as any)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagesTotal, setPagesTotal] = useState(0)
  const [total, setTotal] = useState(0)

  const { saveQueryParams } = useAdvancedSearchContext()
  const history = useHistory()

  const removeURLSearchParamsDefaultFilterValue = (searchParams: any) => {
    const newSearchParams = { ...searchParams }

    if (newSearchParams.actvServcPrvdrInd === '2') {
      delete newSearchParams.actvServcPrvdrInd
    }

    if (newSearchParams.receivingServiceOrder === 2) {
      delete newSearchParams.receivingServiceOrder
    }

    if (newSearchParams.page === 1) {
      delete newSearchParams.page
    }

    return newSearchParams
  }

  const updateURLSearchParams = (params: any) => {
    const searchParams = queryString.parse(window.location.search)

    const newSearchParams = {
      ...searchParams,
      ...params,
    }

    const newSearchParamsWhitoutDefaultValues =
      removeURLSearchParamsDefaultFilterValue(newSearchParams)

    const searchParamsString = queryString.stringify(newSearchParamsWhitoutDefaultValues, {
      skipEmptyString: true,
      arrayFormat: 'comma',
    })

    if (!searchParamsString) {
      saveQueryParams('/providers', null)

      return history.push({
        pathname: '/providers',
        search: '',
      })
    }

    saveQueryParams('/providers', `?${searchParamsString}${window.location.hash}`)

    return history.push({
      pathname: '/providers',
      search: `?${searchParamsString}${window.location.hash}`,
    })
  }

  const handleFetchServiceProviders = async ({
    page = 1,
    size = SIZE_LIMIT,
    fields,
  }: HandleFetchServiceProvidersParams) => {
    try {
      setIsFetchServiceProvidersLoading(true)
      setIsFetchServiceProvidersError(false)

      updateURLSearchParams({
        ...fields,
        page,
      })

      const response = await fetchServiceProviders({
        page,
        size,
        fields: {
          ...fields,
          genericSearch: fields?.q || '',
        },
      })

      setServiceProviders(response.data || [])
      setFilters(fields)
      setCurrentPage(page)

      if (!response.metadata) {
        setPagesTotal(0)
        setTotal(0)
      } else {
        setPagesTotal(response.metadata?.pageCount)
        setTotal(response.metadata?.totalCount)
      }
    } catch (error) {
      setIsFetchServiceProvidersError(true)
      console.error('Error fetching service providers', error)
    } finally {
      setIsFetchServiceProvidersLoading(false)
    }
  }

  const contextValue = useMemo(
    () => ({
      serviceProviders,
      setServiceProviders,
      isFetchServiceProvidersLoading,
      isFetchServiceProvidersError,
      handleFetchServiceProviders,
      filters,
      setFilters,
      currentPage,
      setCurrentPage,
      pagesTotal,
      total,
    }),
    [
      serviceProviders,
      setServiceProviders,
      isFetchServiceProvidersLoading,
      isFetchServiceProvidersError,
      handleFetchServiceProviders,
      filters,
      setFilters,
      currentPage,
      setCurrentPage,
      pagesTotal,
      total,
    ],
  )

  return (
    <ServiceProvidersListV2Context.Provider value={contextValue}>
      {children}
    </ServiceProvidersListV2Context.Provider>
  )
}

const useServiceProvidersListV2Context = () => {
  const context = useContext(ServiceProvidersListV2Context)

  if (!context) {
    throw new Error(
      'useServiceProvidersListV2Context must be used within a ServiceProvidersListV2Provider',
    )
  }

  return context
}

export { ServiceProvidersListV2Context, useServiceProvidersListV2Context }
