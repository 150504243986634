import { useState } from 'react'
import {
  fetchPaymentsServiceOrders,
  paymentServiceOrderRemove,
  paymentServiceOrderSave,
} from '../../../services/Payments'
import { Payments, PaymentsFilter } from '../models'

export function PaymentsListHandler() {
  const [payments, setPayments] = useState<Payments[]>([])
  const [filter, setFilter] = useState<PaymentsFilter | {}>({})

  const handleFetchPayments = async (
    token: string | null,
    filters: PaymentsFilter,
    page: number,
  ) => {
    const { data, filter } = await fetchPaymentsServiceOrders(token, filters, page)

    setPayments(data)
    setFilter(filter)
  }

  const handleRemovePayment = async (
    token: string | null,
    serviceOrder: Payments,
    onSuccess: Function,
  ) => {
    await paymentServiceOrderRemove(token, serviceOrder, onSuccess)
  }

  const handleSavePayment = async (
    token: string | null,
    serviceOrder: Payments,
    onSuccess: Function,
  ) => {
    await paymentServiceOrderSave(token, serviceOrder, onSuccess)
  }

  const handleClear = () => {
    setPayments([])
    setFilter({})
  }

  const result = {
    serviceOrders: payments,
    filter,
    fetchPaymentsServiceOrders: handleFetchPayments,
    paymentServiceOrderRemove: handleRemovePayment,
    paymentServiceOrderSave: handleSavePayment,
    handleClear,
  }

  return result
}
