import { useMemo, useState } from 'react'
import { findProviderAgentAbsence } from '../../services/findProviderAgentAbsence'
import User from '../../core/User'

export type ProviderAbsenceType = {
  providerAbsenceList: Array<any>
  fetchProviderAbsence: Function
}

export function useProviderAbsence() {
  const [providerAbsenceList, setProviderAbsenceList] = useState([])

  const fetchProviderAbsence = async (user: User, filters: any) => {
    const newList = await findProviderAgentAbsence(user, filters)

    setProviderAbsenceList(newList)
  }

  const result = useMemo(
    () => ({
      fetchProviderAbsence,
      providerAbsenceList,
    }),
    [providerAbsenceList],
  )

  return result as ProviderAbsenceType
}
