import { useEffect, useState } from 'react'
import i18next from 'i18next'

import CustomCard from '../../../../../../../../../components/Card'
import FormField from '../../../../../../../../../components/FormField'

import { useServiceOrderDetailContext } from '../../../../../../../context'
import AddressBoxModal from './components/AddressBoxModal'
import ServiceOrderStatusType from '../../../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'

export type Address = Partial<{
  postalCode: string
  streetName: string
  houseNumber: string
  complementText: string
  referencePoint: string
  districtName: string
  cityName: string
  stateCode: string
}>

function AddressBox() {
  const { client, loadingClient, serviceOrderBody } = useServiceOrderDetailContext()

  const [address, setAddress] = useState<Address>({
    postalCode: client?.servcAddrPstlCd,
    streetName: client?.servcAddrStrNm,
    houseNumber: client?.servcAddrNr,
    complementText: client?.servcAddrCmplmtryTxt,
    referencePoint: client?.servcAddrRefPointDesc,
    districtName: client?.servcAddrDstrctNm,
    cityName: client?.servcAddrCityNm,
    stateCode: client?.servcAddrStCd,
  })

  useEffect(() => {
    setAddress({
      postalCode: client?.servcAddrPstlCd,
      streetName: client?.servcAddrStrNm,
      houseNumber: client?.servcAddrNr,
      complementText: client?.servcAddrCmplmtryTxt,
      referencePoint: client?.servcAddrRefPointDesc,
      districtName: client?.servcAddrDstrctNm,
      cityName: client?.servcAddrCityNm,
      stateCode: client?.servcAddrStCd,
    })
  }, [client])

  const [showModal, setShowModal] = useState<boolean>(false)

  const handleAction = () => {
    setShowModal(true)
  }

  const handleCloseModal = (newAddress?: Address) => {
    setShowModal(false)

    if (newAddress) setAddress(newAddress)
  }

  const openModal = [
    ServiceOrderStatusType.STATUS_AGENDADA.id,
    ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
  ].includes(serviceOrderBody?.servcOrdStusCd as string)
    ? handleAction
    : null

  return (
    <>
      <CustomCard
        title={i18next.t('changeAddressForm.servcAddrStrNm')}
        iconNode={<MozaicIcon icon='Map32' />}
        onClickEditButton={openModal}
      >
        <div className='row' id='address-box-detail'>
          <div className='col-xs-12'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrPstlCd')}
              value={address.postalCode || '-'}
            />
          </div>
          <div className='col-xs-12 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrStrNm')}
              value={address.streetName || '-'}
            />
          </div>
          <div className='col-sm-6 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrNr')}
              value={address.houseNumber || '-'}
            />
          </div>
          <div className='col-sm-6 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrCmplmtryTxt')}
              value={address.complementText || '-'}
            />
          </div>
          <div className='col-xs-12 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrRefPointDesc')}
              value={address.referencePoint || '-'}
            />
          </div>
          <div className='col-xs-12 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrDstrctNm')}
              value={address.districtName || '-'}
            />
          </div>
          <div className='col-sm-6 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrCityNm')}
              value={address.cityName || '-'}
            />
          </div>
          <div className='col-sm-6 mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('changeAddressForm.servcAddrStCd')}
              value={address.stateCode || '-'}
            />
          </div>
        </div>
      </CustomCard>
      {showModal && (
        <AddressBoxModal address={address} isOpen={showModal} onClose={handleCloseModal} />
      )}
    </>
  )
}

export default AddressBox
