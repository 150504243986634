import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from '../../helpers/validations'
import Glyph from './Glyph'
import './index.scss'

function Field({
  error,
  input,
  glyph,
  glyphClickable = false,
  hint,
  validate,
  defaultActive,
  children,
  select,
  onMouseDown = () => {},
  onMouseUp = () => {},
  onClick = () => {},
  className,
}) {
  /*
   * Ira retornar a classe do field de acordo com as condições:
   * - por padrão: .field
   * - caso tenha icon: .field addon-right
   * - caso tenha algum valor: .field active
   * - caso tenha valor e não tenha erro: .field active valid
   * - caso tenha erro: .field error ou .field active error
   */
  const fieldClass = classNames('field', {
    'addon-right': glyph,
    active: !isEmpty(input.value) || defaultActive,
    valid:
      validate && input.value && (Array.isArray(input.value) ? input.value.length : true) && !error,
    invalid: validate && error,
    [className]: className,
  })

  const fieldSelect = classNames('field-select')

  /*
   *  renderiza helper do field:
   * - caso tenha algum erro, será renderizado o erro.
   * - caso tenha alguma sugestão, será rendizado a sugestão.
   */
  const renderHint = () => {
    if (error) {
      return <span className='helper'>{error}</span>
    }

    return <span className='helper'>{hint}</span>
  }

  const handleMouseDown = (event) => {
    if (onMouseDown) {
      onMouseDown(event)
    }
  }

  const handleMouseUp = (event) => {
    if (onMouseUp) {
      onMouseUp(event)
    }
  }

  const handleClick = (event) => {
    if (onClick) {
      onClick(event)
    }
  }

  /*
   * Renderiza glyph quando tem
   */
  const renderGlyph = () => {
    if (!glyph) {
      return
    }

    return (
      <div
        className='addon right'
        style={glyphClickable ? { cursor: 'pointer', pointerEvents: 'visible' } : {}}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
      >
        <Glyph customClass={glyphClickable && 'clickable'} type={glyph} />
      </div>
    )
  }

  return (
    <div className={select ? `${fieldClass} ${fieldSelect}` : fieldClass}>
      {children}

      {renderGlyph()}

      {renderHint()}
    </div>
  )
}

Field.propTypes = {
  input: PropTypes.object.isRequired,
  error: PropTypes.string,
  glyph: PropTypes.string,
  glyphClickable: PropTypes.bool,
  hint: PropTypes.string,
  validate: PropTypes.bool,
  defaultActive: PropTypes.bool,
  children: PropTypes.any.isRequired,
  select: PropTypes.bool,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default Field

export { Field }
