import { useEffect, useRef, useMemo, useState } from 'react'
import { RefType, defaultProps, Title } from '../utils'
import i18next from 'i18next'

export function useProviderClientChat() {
  const [chatSolved, setChatSolved] = useState(false)
  const chatRef = useRef<RefType>(null)

  const chatProps = useMemo(
    () => ({
      ...defaultProps,
      header: (
        <Title
          before={i18next.t('conformity.osData.subtitle')}
          after={i18next.t('bankFilter.nomePrestadorServico')}
          solved={chatSolved}
        />
      ),
      onOpen: () => {
        chatRef.current?.chatRef.markAsRead()
        chatRef.current?.handleScroll()
      },
    }),
    [chatSolved, chatRef],
  )

  useEffect(() => {
    const PCRefCount = chatRef.current?.chatRef
    PCRefCount?.onSnapshot((newChat) => setChatSolved(newChat.solved))
  }, [chatRef])

  return {
    chatRef,
    chatProps,
    chatSolved,
  }
}
