import {
  SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_REQUEST,
  SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_SUCCESS,
  SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_ERROR,
} from '../../actionTypes/servcPrvdrSchdlSgsttn'

const DEFAULT_STATE = {
  isPending: false,
  resultMessage: '',
  error: '',
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_REQUEST:
      return { ...state, isPending: true, error: '' }
    case SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_SUCCESS:
      return { ...state, isPending: false, resultMessage: action.payload }
    case SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_ERROR:
      return { ...state, isPending: false, error: action.payload }
    default:
      return { ...state }
  }
}
