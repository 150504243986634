import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallbackLineChart from '../../fallbacks/components/LineChart'

interface ErrorBoundaryLineChartProps {
  children: React.ReactNode
  onReset: () => void
}

function ErrorBoundaryLineChart({ children, onReset }: ErrorBoundaryLineChartProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackLineChart} onReset={() => onReset()}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryLineChart
