import { useState } from 'react'
import moment, { isMoment } from 'moment-timezone'

import Modal from '../../../../../../../../../components/Modal'
import DateSelect from './components/DateSelect'
import PeriodSelect from './components/PeriodSelect'
import JustifyInput from './components/JustifyInput'
import FooterButtons from './components/FooterButtons'

import { WarrantyProvider } from './context'
import { warrantyActivation } from '../../../../../../../../../services/pages/ServiceOrderDetail/WarrantyActivation'
import { useServiceOrderDetailContext } from '../../../../../../../context'
import ReasonSelect from './components/ReasonSelect'
import ModeSelect from './components/ModeSelect'

type WarrantyModalType = {
  show: boolean
  onClose: Function
  onSubmit: Function
}

function WarrantyModal(props: WarrantyModalType) {
  const { show, onClose, onSubmit } = props
  const [submiting, setSubmiting] = useState(false)
  const [justify, setJustify] = useState('')
  const [reason, setReason] = useState<any>(null)
  const [sameProvider, setSameProvider] = useState<any>('true')
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
  const { serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()

  const handleJustifyText = (e: any) => setJustify(e.target.value)
  const handleChangeDate = (e: any) => {
    if (!isMoment(e)) return setSelectedDate(null)

    const newDate = e.startOf('day')
    if (+newDate === +selectedDate?.startOf('day')) return false

    setSelectedDate(newDate)
  }

  const handleChangePeriod = (e: any) => {
    if (e?.value === selectedPeriod?.value) return false

    setSelectedPeriod(e)
  }

  const handleClose = () => {
    onClose()

    setJustify('')
    setSelectedDate(null)
    setSelectedPeriod(null)
  }

  const handleSubmit = async () => {
    setSubmiting(true)

    const params = {
      servcOrdWrntDesc: justify,
      scheduledDate: selectedDate.format(),
      scheduledTurn: selectedPeriod.value,
      reasonId: reason.value,
      servcOrdOrignSeq: serviceOrderBody?.servcOrdSeq || 0,
      isSameProvider: sameProvider === 'true' ? true : sameProvider,
    }

    await warrantyActivation(params)

    onSubmit()
    setSubmiting(false)
    await reloadServiceOrder()
  }

  const modalTitle = 'order.detail.warranty.modal.title'
  const today = moment().startOf('day')
  const todayTime = +today
  const currentTime = selectedDate ? +selectedDate.startOf('day') : 0
  const compareTodayDate = currentTime >= +todayTime

  return (
    <div id='warranty-service-modal'>
      <WarrantyProvider>
        {show && (
          <Modal
            size='lg'
            isOpen={show}
            onClose={handleClose}
            className='no-margin-header'
            closeInOverlay={false}
            contentLabel={modalTitle}
          >
            <div className='reschedule-modal-content'>
              <ReasonSelect onChange={setReason} value={reason} />

              <JustifyInput value={justify} onChange={handleJustifyText} />

              <div className='flex-date-selects'>
                <div className='date-select'>
                  <DateSelect value={selectedDate} onChange={handleChangeDate} />
                </div>
                <div className='period-select'>
                  <PeriodSelect
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    selectedDate={selectedDate}
                    compareTodayDate={compareTodayDate}
                  />
                </div>
              </div>

              <ModeSelect onChange={setSameProvider} value={sameProvider} />
            </div>

            <FooterButtons
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              selectedDate={selectedDate}
              selectedPeriod={selectedPeriod}
              compareTodayDate={compareTodayDate}
              justify={justify}
              reason={reason}
              sameProvider={sameProvider}
              isDisabled={submiting}
            />
          </Modal>
        )}
      </WarrantyProvider>
    </div>
  )
}

export default WarrantyModal
