import { toast } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import BackyardSelect from '../../../../../../../components/BackyardSelect'
import Modal from '../../../../../../../components/Modal'
import MozaicButton from '../../../../../../../components/MozaicButton'
import MozaicText from '../../../../../../../components/MozaicText'
import OutlineButton from '../../../../../../../components/OutlineButton'
import HttpStatusCode from '../../../../../../../enums/HttpStatusCode'
import {
  addAttachment,
  fileToBase64,
} from '../../../../../../../services/pages/ServiceOrderDetail/AddAttachment'
import {
  DocumentType,
  getServiceOrderDocumentTypes,
} from '../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderDocumentTypes'
import { useServiceOrderDetailContext } from '../../../../../context'

const FILE_INDICATOR = 3

interface AttachmentUploaderModalProps {
  readonly isOpen: boolean
  readonly onClose: (attachmentIsUploaded?: boolean) => void
  readonly fileType: 'pdf' | 'img'
  readonly allowedFileTypes: { mimeType: string; extension: string }[]
}

export default function AttachmentUploaderModal({
  isOpen,
  onClose,
  fileType,
  allowedFileTypes,
}: AttachmentUploaderModalProps) {
  const inputFileRef = useRef<HTMLInputElement>(null)

  const { servcOrdCd } = useServiceOrderDetailContext()

  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])

  const [file, setFile] = useState<File | null>()
  const [documentType, setDocumentType] = useState<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const canSubmit = file && documentType && !isSubmitting
  const allowedMimeTypes = allowedFileTypes.map((type) => type.mimeType)

  const getDocumentTypes = async () => {
    const types = await getServiceOrderDocumentTypes()
    setDocumentTypes(types)
  }

  useEffect(() => {
    getDocumentTypes()
  }, [])

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const fileToSelect = event.target.files?.[0]

    const isFileTypeAllowed = fileToSelect && allowedMimeTypes.includes(fileToSelect.type)

    if (!isFileTypeAllowed) {
      toast.error('', i18next.t('serviceOrder.attachments.add.fileTypeNotAllowed'), '')
      return
    }

    setFile(fileToSelect)
  }

  const handleSubmit = async () => {
    if (!file) return false

    setIsSubmitting(true)

    try {
      const parsedFile = await fileToBase64(file)
      const status = await addAttachment({
        servcOrdSeq: servcOrdCd,
        atchmtDocmntTyp: String(documentType.value),
        atchmtDocmntTypDesc: documentTypes.find((dt) => dt.atchmtDocmntTyp === documentType.value)
          ?.atchmtDocmntTypDesc!,
        isPortalService: true,
        type: fileType,
        files: [
          {
            name: file.name,
            fileIndicador: FILE_INDICATOR,
            file: parsedFile?.split('base64,')[1],
          },
        ],
      })

      if (status === HttpStatusCode.OK) onClose(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isDocModal = fileType === 'pdf'
  const docTypePlaceholder = isDocModal
    ? i18next.t('documentType.select')
    : i18next.t('imgType.select')
  const hasFileClass = file?.name ? 'has-value' : ''
  const captureCamera = isDocModal ? '' : ';capture=camera'
  const fileInputLabel = i18next.t('provider.portal.choose.file')
  const fileInputHint = isDocModal
    ? i18next.t('fileInput.hint.single')
    : i18next.t('imageInput.hint')

  const countAllowedExts = allowedFileTypes.length - 1
  const fileInputAllowed = allowedFileTypes
    .map((extension) => extension.extension.toUpperCase())
    .reduce((result, extension, i) => {
      // eslint-disable-next-line no-param-reassign
      if (i === countAllowedExts) result += ` ${i18next.t('or')} ${extension}`
      // eslint-disable-next-line no-param-reassign
      else result += `, ${extension}`
      return result
    })

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      onClose={() => onClose()}
      className='add-attachment-modal'
      contentLabel={`serviceOrder.attachments.add.title.${fileType}`}
      closeInOverlay={false}
    >
      <div id='attachment-uploader-modal'>
        <MozaicText>{`${i18next.t(`serviceOrder.attachments.add.sub.${fileType}`)}.`}</MozaicText>

        <div className='field'>
          <label htmlFor='file-input'>
            {i18next.t(`serviceOrder.attachments.add.attach.${fileType}`)}
          </label>

          <div className='input-file-container'>
            <div className={`input-file ${hasFileClass}`}>
              <div className='file-description'>{file?.name || ''}</div>
              <input
                id='file-input'
                type='file'
                accept={`${allowedMimeTypes.join(',')}${captureCamera}`}
                onChange={handleFileSelect}
                ref={inputFileRef}
                hidden
              />
              <OutlineButton
                classname='choose-file'
                label={fileInputLabel}
                onClick={() => inputFileRef.current?.click()}
              />
            </div>
          </div>

          <small>{`${fileInputHint} ${fileInputAllowed}.`}</small>
        </div>

        <div className='field'>
          <BackyardSelect
            id='doctype-select'
            label={i18next.t(`serviceOrder.attachments.add.documentType.${fileType}`)}
            value={documentType}
            placeholder={docTypePlaceholder}
            options={documentTypes.map((dt) => ({
              label: dt.atchmtDocmntTypDesc,
              value: dt.atchmtDocmntTyp,
            }))}
            onChange={setDocumentType}
          />
        </div>

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => onClose()}>
            {i18next.t('serviceOrder.attachments.add.cancel')}
          </MozaicButton>
          <MozaicButton onClick={() => handleSubmit()} isDisabled={!canSubmit}>
            {i18next.t('serviceOrder.attachments.add.confirm')}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}
