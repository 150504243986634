import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { ProviderBoxType } from '../../../models/provider'

export function WithContext(Component: FunctionComponent<ProviderBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, handleToggle },
      providerState: { provider, setProvider, installer, setInstaller },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        provider,
        setProvider,
        installer,
        setInstaller,
        showAllDetails,
        handleToggle,
      }),
      [provider, setProvider, installer, setInstaller, showAllDetails, handleToggle],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
