import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

type ParamsType = {
  servcOrdStusCd: string
  servcOrdSeq: number
  justificationBackToScheduled: string
}

export const backOrderToSchedule = (params: ParamsType) =>
  new API_REQUEST(user.dsToken, store.dispatch).post(API_URL.BACK_TO_SCHEDULED, params)
