import { Component } from 'react'
import PropTypes from 'prop-types'
import Input from './Input'
import Field from './Field'

class InputSearch extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
  }

  submit = (event) => {
    event.preventDefault()

    const { onSubmit } = this.props

    if (onSubmit) {
      onSubmit()
    }
  }

  render() {
    const { onSubmit, value } = this.props

    const field = (
      <Field
        className='no-margin input-search-fancy'
        glyph='magnifier'
        glyphClickable={!!onSubmit}
        onClick={this.submit}
        input={{
          value,
        }}
      >
        <Input type='search' {...this.props} />
      </Field>
    )

    if (onSubmit) {
      return <form onSubmit={this.submit}>{field}</form>
    }

    return field
  }
}

export default InputSearch
