import i18next from 'i18next'
import MozaicButton from '../../../components/MozaicButton'
import MozaicText from '../../../components/MozaicText'

interface ErrorFallbackLineChartProps {
  error: Error
  resetErrorBoundary: () => void
}

function ErrorFallbackLineChart({ error, resetErrorBoundary }: ErrorFallbackLineChartProps) {
  return (
    <div role='alert' className='fallback-chart'>
      <MozaicText>{i18next.t('fallback.page.error.subtitle')}</MozaicText>
      <pre>{error.message}</pre>
      <MozaicButton variant='bordered' onClick={resetErrorBoundary}>
        {i18next.t('fallback.page.text.button')}
      </MozaicButton>
    </div>
  )
}
export default ErrorFallbackLineChart
