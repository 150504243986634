import i18next from 'i18next'
import { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'

import MozaicIcon from '../../../../../../../../../../components/MozaicIcon'
import { AUTOCOMPLETE_THEME } from '../../../../../../../../../../constants/config'
import User from '../../../../../../../../../../core/User'
import { Installer } from '../../../../../../../../../../models/Installer'
import { fetchServiceProviderNameAutocomplete } from '../../../../../../../../../../services/ProviderList'

const user = new User().currentUser
let _fetchProvidersByText: any

type optionType = {
  value: any
  label: string
}

type ProviderSelectType = {
  onChange: Function
  value: optionType | null
}

function ProviderSelect(props: ProviderSelectType) {
  const { onChange, value } = props
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState<Array<any>>([])

  const handleClear = () => {
    setSearch('')
    setOptions([])
    onChange(null)
  }

  useEffect(() => {
    if (value) {
      setSearch(value?.label.trim())
    } else {
      handleClear()
    }
  }, [value])

  const serviceProviderFetchRequested = async () => {
    const installers = await fetchServiceProviderNameAutocomplete(user.dsToken, search)

    const parsedInstallers = installers.map(
      (installer: Pick<Installer, 'servcPrvdrNm' | 'servcPrvdrId'>) => ({
        label: installer.servcPrvdrNm,
        value: installer.servcPrvdrId,
      }),
    )

    setOptions(parsedInstallers)
  }

  const handleRequest = () => {
    clearTimeout(_fetchProvidersByText)
    _fetchProvidersByText = setTimeout(() => serviceProviderFetchRequested(), 500)
  }

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value)

    if (!e.target.value) onChange(null)
    if (options.length) setOptions([])
  }

  const handleChange = (e: any, { suggestionValue }: any) => {
    const installer = options.find((installer) => installer.label === suggestionValue)

    onChange(installer)
    setSearch(installer?.label.trim())
  }

  const handleClick = () => {
    if (!options.length) {
      serviceProviderFetchRequested()
    }
  }

  const label = i18next.t('company.info.company')
  const placeholder = `${i18next.t('search')} ${i18next.t('all.text').toLowerCase()}`
  const footer = i18next.t('rating.error')

  const inputProps = {
    placeholder,
    value: search,
    id: 'serviceProviderSelect',
    name: 'provider-select',
    onChange: handleSearchChange,
    onKeyUp: handleRequest,
    onClick: handleClick,
  }

  return (
    <div className='provider-select'>
      <label className='react-select--label' htmlFor={inputProps.name}>
        {label}
      </label>

      <Autosuggest
        suggestions={options}
        onSuggestionsFetchRequested={() => false}
        onSuggestionsClearRequested={() => false}
        getSuggestionValue={(suggestion: any) => suggestion.label}
        renderSuggestion={(suggestion: any) => suggestion.label}
        highlightFirstSuggestion
        onSuggestionSelected={handleChange}
        inputProps={inputProps}
        theme={AUTOCOMPLETE_THEME}
        shouldRenderSuggestions={() => true}
      />

      {search && (
        <div className='close-icon'>
          <MozaicIcon icon='ControlCross32' onClick={handleClear} />
        </div>
      )}

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default ProviderSelect
