import SeparatorByDay from '../SeparatorByDay'
import ReceivedMessage from '../ReceivedMessage'
import SendedMessage from '../SendedMessage'

import { USER_TYPE } from '../../../../constants/config'
import { MessageType } from '../../models'

function Messages({ messages }: { messages: object }) {
  if (!messages) return <></>

  return (
    <>
      {' '}
      {Object.entries(messages).map(([date, listMessages]) => (
        <>
          <SeparatorByDay title={date} />

          {(listMessages as MessageType[]).map((messageItem: MessageType) =>
            messageItem.userType === USER_TYPE.CUSTOMER ? (
              <ReceivedMessage message={messageItem} key={messageItem.createdAt} />
            ) : (
              <SendedMessage message={messageItem} key={messageItem.createdAt} />
            ),
          )}
        </>
      ))}
    </>
  )
}

export default Messages
