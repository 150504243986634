import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallbackBarChart from '../../fallbacks/components/BarChart'

interface ErrorBoundaryBarChartProps {
  children: React.ReactNode
  onReset: () => void
}

function ErrorBoundaryBarChart({ children, onReset }: ErrorBoundaryBarChartProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackBarChart} onReset={() => onReset()}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryBarChart
