import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import i18next from 'i18next'
import { uniqueId } from 'lodash'
import { scrollMessages, setInputFocus } from './utils'

import Messages from './components/Messages'
import CustomerChatV2 from '../../services/firestore/customerChatV2/CustomerChatV2'
import SendMessage from './components/SendMessage'
import { ChatType } from './models'

type ChatProps = {
  docID: string
  collection: string
  initScroll?: boolean
}

const ChatV2 = forwardRef(({ docID, collection, initScroll = false }: ChatProps, ref?: Ref<{}>) => {
  const [chat, setChat] = useState<ChatType>()

  const chatRef = useMemo(() => new CustomerChatV2(docID, collection), [docID, collection])

  const idArea = useMemo(() => uniqueId('chat-area'), [])
  const idInput = useMemo(() => uniqueId('chat-input'), [])
  const groupedMessages = useMemo(
    () => chatRef.groupMessagesByDate(chat?.messageHistory || []),
    [chat],
  )

  const handleScroll = () => {
    setTimeout(() => setInputFocus(idInput), 300)
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }

  useEffect(() => {
    chatRef.onSnapshot((newChat) => {
      setChat(newChat)
    })

    if (initScroll) handleScroll()
  }, [])

  useEffect(() => {
    scrollMessages(idArea)
  }, [chat, groupedMessages])

  useImperativeHandle(
    ref,
    () => ({
      chatRef,
      handleScroll,
    }),
    [chatRef, handleScroll],
  )

  const messagesEmpty = !chat?.messageHistory.length

  return (
    <div className='client-chat-container-v2'>
      <section className='chat-area' id={idArea}>
        {messagesEmpty && i18next.t('firestore.chat.has.no.messages')}

        <Messages messages={groupedMessages} />
      </section>

      {chat && <SendMessage chatRef={chatRef} id={idInput} solved={chat?.solved} />}
    </div>
  )
})

export default ChatV2
