import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import moment from 'moment-timezone'
import User from '../../../core/User'

import { USER_TYPE } from '../../../constants/config'
import { Conversation } from '../../../contexts/customerChat/models'
import { CustomerChatServiceType } from './customerChat'

export default function CustomerChat(CustomerChatService: CustomerChatServiceType) {
  const user = new User().currentUser

  return {
    readClientMessages: async (servcOrdCd: string) => {
      const conversationReference = await CustomerChatService.getReference(servcOrdCd)
      const conversationData = (await CustomerChatService.get(servcOrdCd)) as Conversation

      conversationData?.messageHistory?.forEach((message) => {
        if (message.userType === USER_TYPE.CUSTOMER) {
          // eslint-disable-next-line no-param-reassign
          message.isRead = true
        }
        return message
      })

      if (conversationData?.lastMessage?.userType === USER_TYPE.CUSTOMER) {
        conversationData.lastMessage.isRead = true
      }

      const upgradedConversation = {
        ...conversationData,
      }

      conversationReference?.update(upgradedConversation)
    },

    resolveConversation: async (conversation: Conversation, solved: boolean) => {
      const newConversation = { ...conversation, solved }
      const conversationReference = await CustomerChatService.getReference(conversation?.servcOrdCd)

      conversationReference?.update(newConversation)
    },

    updateConversation: async (conversation: Conversation, message?: string) => {
      const conversationReference = await CustomerChatService.getReference(conversation?.servcOrdCd)
      const conversationData = (await CustomerChatService.get(
        conversation?.servcOrdCd,
      )) as Conversation

      const newMessage = {
        createdAt: +moment(),
        displayName: user?.fullName,
        indSystem: 0,
        isRead: false,
        message,
        type: 'text',
        uid: conversation?.servcOrdCd || conversationData?.lastMessage?.uid,
        userType: USER_TYPE.BACKOFFICE,
      }

      const newConversation = {
        ...conversation,
        messageHistory: firebase.firestore.FieldValue.arrayUnion(newMessage),
        lastMessage: newMessage,
      }

      conversationReference?.update(newConversation)
    },

    updateConversationFields: async (conversation: Conversation) => {
      const conversationReference = await CustomerChatService.getReference(conversation?.servcOrdCd)
      const newConversation = { ...conversation }

      conversationReference?.update(newConversation)
    },

    sortConversations: (list: Conversation[]) => {
      const sortedByDate = list
        .filter((conversation) => !!conversation?.lastMessage?.createdAt)
        .sort((a, b) => (a?.lastMessage?.createdAt > b?.lastMessage?.createdAt ? -1 : 1))

      const sortedByName = list
        .filter((conversation) => !conversation?.lastMessage?.createdAt)
        .sort((a, b) => (a?.custFullNm > b?.custFullNm ? 1 : -1))

      const sortedList = [...sortedByDate, ...sortedByName]

      return sortedList
    },
  }
}
