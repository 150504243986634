import { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useModals,
} from '@mozaic-ds/react'
import i18n from 'i18next'
import { Installer } from '../../../services/installers/fetchInstallersList'

const MODAL_ID = 'installers-list-installer-provider-is-inactive'

interface InstallerProviderIsInactiveProps {
  installer?: Installer
}

/**
 * @description Modal to show when the installer provider is inactive
 */

export function InstallerProviderIsInactive({ installer }: InstallerProviderIsInactiveProps) {
  const { open } = useModals()

  useEffect(() => {
    if (installer) open(MODAL_ID)
  }, [installer])

  const handleOnClickActiveProvider = () => {
    window.open(`/providers/edit/${installer?.servcPrvdrId}`, '_blank', 'noopener noreferrer')
  }

  return (
    <Modal id={MODAL_ID} canCloseOnOverlayClick={false} focusedElementIndex={-1}>
      <ModalHeader>
        <ModalTitle>{i18n.t('inactiveProviderModal.actvServcPrvdrAgntInd.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Text textAlign='center'>{i18n.t('inactiveProviderModal.actvServcPrvdrAgntInd.text')}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          size='m'
          width='full'
          onClick={handleOnClickActiveProvider}
          className='provider-link-btn'
        >
          {i18n.t('inactiveProviderModal.actvServcPrvdrAgntInd.button')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
