import i18next from 'i18next'
import moment from 'moment-timezone'

import MozaicButton from '../../../../../../components/MozaicButton'

import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import { useRescheduleContext } from '../../context'

type FooterButtonsType = {
  selectedDate: any
  selectedPeriod: any
  reason: any
  handleClose: Function
  handleSubmit: Function
}

const shifts: any = {
  M: 1,
  A: 2,
  E: 3,
}

function FooterButtons(props: FooterButtonsType) {
  const { selectedDate, selectedPeriod, handleClose, handleSubmit, reason } = props
  const { allowedDates } = useRescheduleContext()
  const { serviceOrder }: any = useServiceOrderContext()

  const cancelLabel = i18next.t('absncPerd.cancel')
  const submitLabel = i18next.t('customer.service.order.confirm.button')

  const shiftCd = shifts[serviceOrder?.idSlotApplication as keyof typeof shifts]

  const isFormValid = () => {
    if (!selectedDate) return false

    const dateIsValid = selectedDate
      ? allowedDates.includes(selectedDate.format('YYYY-MM-DD'))
      : false

    const isSameDate =
      +moment(serviceOrder?.servcOrdSchdlgTs).startOf('day') === +selectedDate.startOf('day')
    const isSamePeriod = shiftCd === selectedPeriod?.value
    const isReasonValid = (isSameDate && isSamePeriod && !selectedPeriod?.value) || !!reason

    return dateIsValid && isReasonValid
  }

  const disabled = !isFormValid()

  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClose()}>
        {cancelLabel}
      </MozaicButton>
      <MozaicButton
        isDisabled={disabled}
        onClick={() => {
          handleSubmit()
        }}
      >
        {submitLabel}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
