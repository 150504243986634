import { IFlagProps } from '@mozaic-ds/react'
import { ReactNode } from 'react'
import { TagItem } from '../enums/ServiceOrder/tags/tag-types'
import { ServiceExecutionEntity } from './ServiceExecution'

export interface ProjectEntity {
  prjctExctnSeq: number
  prjctExctnEndDt?: string
  cntryCd: string
  buCd: number
  prjctExctnNm: string
  plntCd: string
  plntNm: string
  servcExctnStusCd: string
  custTyp: string
  custFiscalId: string
  custFullNm: string
  custIdType: string
  custIdNr: string
  custEmailTxt: string
  custBusnsPhonNr: string
  custHmPhonNr: string
  custMobilePhonNr: string
  custLoginKey: string
  custId: string
  servcAddrStrNm: string
  servcAddrNr: string
  servcAddrCmplmtryTxt: string
  servcAddrDstrctNm: string
  servcAddrPstlCd: string
  servcAddrCityNm: string
  servcAddrStCd: string
  latitude: number
  longitude: number
  mrktgDataCnsntCustInd: string
  servcDataCnsntCustInd: string
  servcCategory: string
  servcOrdAmt: number
  creatTs: string
  lastUpdtTs: string
  lastUpdtUserCd: string
  privLastUpdt: string
  id: number
  servcRgnZn: string
}

export type TagsType = {
  tags: TagItem[]
  loadingTags: boolean
}
export interface ProjectResume extends TagsType {
  project: ProjectEntity | null
  loadingProject: boolean
  loadingServices: boolean
  services: ServiceExecutionEntity[] | null
  projectID: number
  getProjectExecutions: Function
  getProjectDetails: Function
}

export type ProjectDetailsProviderType = ProjectResume

export type ProjectDetailsContextType = {
  children: ReactNode
  projectCd: number
} | null

export type FlagType = {
  text: string
  color: IFlagProps['theme']
}

export const risks = {
  LOW_RISK: { text: 'low', color: 'primary' } as FlagType,
  MEDIUM_RISK: { text: 'medium', color: 'primary-02' } as FlagType,
  HIGH_RISK: { text: 'high', color: 'danger' } as FlagType,
}

export type QueryParamsType = { q: string }
