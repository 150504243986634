import i18next from 'i18next'

import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

interface Params {
  servcOrdSeq: number
  servcOrdObs: string
}

export const updateObservation = async (params: Params) => {
  try {
    const { cdStatus, message } = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.SERVC_ORD_CHANGE_OBS,
      params,
    )

    toast.success(i18next.t('success.text'), message?.dsMessage, '')
    return cdStatus
  } catch (error: any) {
    const msg = error?.cdStatus
      ? `${i18next.t('service.front.order.detail.observations.edit.error')}: ${error.cdStatus}`
      : i18next.t('empleTempPrfl.error.save')
    toast.error('', msg, '')
  }
}
