import { memo, useEffect, useMemo, useState } from 'react'
import i18next from 'i18next'
import Resume from '../../Resume'
import ErrorBoundary from '../../../../../errors/boundaries'

import { fetchAttachmentsV2 } from '../../../../../services/firestore/gCloudStorage/fetchAttachmentsV2'

import MozaicCard from '../../../../../components/MozaicCard'
import GCloudFile from '../../../../../components/GCloudFile'
import MozaicIcon from '../../../../../components/MozaicIcon'
import MozaicButton from '../../../../../components/MozaicButton'
import GCloudFileView from '../../../../../components/GCloudFileView'
import { GCloudFileRef } from '../../../../../services/firestore/gCloudStorage/GCloudFileRef'
import { GCloudImageRef } from '../../../../../services/firestore/gCloudStorage/GCloudImageRef'
import { getFirebaseConfigs } from '../../../../../services/firestore/repository/getFirebaseParameters'
import { useProjectDetailsContext } from '../../../context'

function Attachments() {
  const [files, setFiles] = useState<Array<GCloudFileRef | GCloudImageRef>>([])
  const [fileToView, setFileToView] = useState<number | null>(null)

  const fetchProjectAttachments = async () => {
    try {
      const configs = await getFirebaseConfigs()
      const os = configs.gcloudStorage === 'gs://lmbr-instala-dev-brlm-files-dev' ? 115993 : 627467
      const pFiles = await fetchAttachmentsV2(os)

      setFiles(pFiles)
    } catch (error) {
      Promise.reject(error)
    }
  }

  useEffect(() => {
    fetchProjectAttachments()
  }, [])

  const addBtnLabel = i18next.t('add.photos.or.docs')
  const filterBtnLabel = i18next.t('filter_button_label')

  return (
    <div id='attachment-container'>
      <ErrorBoundary>
        <Resume />

        <MozaicCard
          title='Anexos'
          className='project-tab'
          leftIcon={<MozaicIcon icon='Imagealt24' />}
        >
          <div className='attachments-header'>
            <MozaicButton size='s' variant='bordered'>
              {addBtnLabel}
            </MozaicButton>

            <MozaicButton
              size='s'
              variant='bordered'
              leftIcon={<MozaicIcon icon='DisplayFilter24' size={24} />}
            >
              {filterBtnLabel}
            </MozaicButton>
          </div>

          <div className='attachments-body'>
            {files.map((file, i) => (
              <GCloudFile
                key={file.attachment.atchmtCloudShortPathTxt}
                file={file}
                onClick={() => setFileToView(i)}
              />
            ))}
          </div>

          {fileToView !== null && (
            <GCloudFileView
              files={files}
              fileIndex={fileToView}
              handleClose={() => setFileToView(null)}
            />
          )}
        </MozaicCard>
      </ErrorBoundary>
    </div>
  )
}

const MemoComponent = memo(Attachments)

function AttachmentsWC(props: any) {
  const { projectID } = useProjectDetailsContext()

  const newProps = useMemo(() => ({ projectID }), [projectID])

  return <MemoComponent {...newProps} {...props} />
}

export default AttachmentsWC
