import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MozaicIcon from '../../../components/MozaicIcon'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import history from '../../../helpers/history'
import { formatDecimal } from '../../../helpers/masks'
import * as serviceOrderActions from '../../../redux/actions/serviceOrder'

class Footer extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    currency: this.props.t('option.currency'),
    // eslint-disable-next-line react/destructuring-assignment
    sizeCurrency: this.props.t('size.currency'),
    formatCurrency: {
      // eslint-disable-next-line react/destructuring-assignment
      separator: this.props.t('separator.currency'),
      // eslint-disable-next-line react/destructuring-assignment
      delimiter: this.props.t('delimiter.currency'),
    },
    lastRouteAccessedInState: '',
  }

  componentDidMount() {
    // Used to catch route with search params
    this.setState({ lastRouteAccessedInState: history.location.state?.lastRouteAccessedInState })
  }

  handleOnClickBack = async () => {
    const { isBackOrder, shouldRestoreScroll } = this.props
    const { lastRouteAccessedInState } = this.state

    await isBackOrder(true)
    shouldRestoreScroll(true)

    if (lastRouteAccessedInState) {
      history.push(lastRouteAccessedInState)
    } else {
      history.push('/serviceOrder')
    }
  }

  renderLabelFooter = () => {
    const { t, auth, totalValue, serviceOrder } = this.props

    const enableShowSlotsOnItem = auth.user.accessFunctionList?.includes(115)
    const parameterServicePriceOn = auth?.setupParameters?.acceptingServicePrice
    const checkHasOrderItens =
      serviceOrder.serviceOrderItem && serviceOrder.serviceOrderItem.length > 0
    const hasServcOrdItemQtyReturned =
      checkHasOrderItens &&
      serviceOrder.serviceOrderItem.some((item) => item.servcOrdItemQtyReturned)

    if (parameterServicePriceOn) {
      return (
        <Col md={12} xs={8} className='text-align-right'>
          <span>
            {enableShowSlotsOnItem && (
              <>
                {t('serviceOrders.estimate.totalTime').replace('{0}', serviceOrder.slots || '0')}
                <span style={{ margin: '2px' }}> / </span>
              </>
            )}

            {t('serviceOrders.totalToPay')}
            {formatDecimal(totalValue, t)}
            {hasServcOrdItemQtyReturned && '*'}
            <span style={{ margin: '2px' }}> / </span>
            <span className='strong'>
              {t('serviceOrders.totalToTransfer')}
              {formatDecimal(serviceOrder.servcOrdPrvdrPymtAmt, t)}
              {hasServcOrdItemQtyReturned && '*'}
            </span>
          </span>
        </Col>
      )
    }
    return (
      <Col md={8} sm={8} xs={8} className='text-align-right'>
        {t('serviceOrders.totalToPay')}
        <span className='strong'>{formatDecimal(totalValue, t)}</span>
      </Col>
    )
  }

  render() {
    const { t, disabled, save, isOpen } = this.props

    const menuIsOpen = isOpen ? 'isOpen' : ''

    return (
      <Grid fluid className={`footer ${menuIsOpen}`}>
        <Row className='padding align-items-center'>
          <Col md={2} xs={2}>
            <button
              className='button button-text-only button-glyph'
              onClick={() => this.handleOnClickBack()}
            >
              <MozaicIcon icon='ArrowArrowLeft24' className='footer-arrow-icon' />
              <span>{t('serviceOrders.return-button')}</span>
            </button>
          </Col>

          {this.renderLabelFooter()}

          <Col md={3} xs={3}>
            <button
              className='button button-primary button-full'
              disabled={disabled}
              onClick={save}
            >
              {t('serviceOrders.save')}
            </button>
          </Col>
        </Row>
      </Grid>
    )
  }
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  totalValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  isBackOrder: PropTypes.func.isRequired,
  shouldRestoreScroll: PropTypes.func,
  serviceOrder: PropTypes.object,
  auth: PropTypes.object,
  isOpen: PropTypes.bool,
}

const mapStateToProps = ({ menuReducer }) => ({
  isOpen: menuReducer.isOpen,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...serviceOrderActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

export { Footer }
