/* eslint-disable react/require-default-props */

import PropTypes from 'prop-types'
import AwsImage from './AwsImageDocument'

function ThumbnailDocument(props) {
  const { filePath, token, src, dispatch } = props
  const imgSrc = src || null

  return (
    <div style={{ height: '3em' }} className='align-items-center justify-content-center'>
      <AwsImage
        className='image image-auto'
        filePath={filePath}
        src={imgSrc}
        token={token}
        dispatch={dispatch}
      />
    </div>
  )
}

ThumbnailDocument.propTypes = {
  filePath: PropTypes.string.isRequired,
  src: PropTypes.string,
  token: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default ThumbnailDocument
