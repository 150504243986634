import i18next from 'i18next'
import FormField from '../../../../../../components/FormField'

import { useServiceOrderDetailContext } from '../../../../context'

function InstallerInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='installer-info' className='skeleton' />

  const servcPrvdrAgntNm = serviceOrderBody?.servcPrvdrAgntNm || '-'

  return (
    <div id='installer-info'>
      <FormField
        label={i18next.t('customer.service.order.details.infos.specialist')}
        value={servcPrvdrAgntNm}
      />
    </div>
  )
}

export default InstallerInfo
