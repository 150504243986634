import OutlineButton from '../../../OutlineButton'
import MozaicIcon from '../../../MozaicIcon'

type FileControlsType = {
  prevFile: () => void
  nextFile: () => void
}

export function FileControls({ prevFile, nextFile }: FileControlsType) {
  return (
    <div className='file-controls'>
      <div className='arrow arrow-left'>
        <OutlineButton
          classname='outline-button'
          iconNode={<MozaicIcon icon='ArrowArrowLeft24' color='primary' size={21} />}
          onClick={prevFile}
        />
      </div>

      <div className='arrow arrow-right'>
        <OutlineButton
          classname='outline-button'
          iconNode={<MozaicIcon icon='ArrowArrowRight24' color='primary' size={21} />}
          onClick={nextFile}
        />
      </div>
    </div>
  )
}
