import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'

import './index.scss'

const options = {
  timing: 200,
}

class Modal extends Component {
  static options = options

  constructor(props) {
    super(props)

    this.state = {
      show: false,
      showContent: false,
      leave: false,
    }

    this.options = options
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount() {
    document.body.classList.add('no-scroll')

    setTimeout(() => {
      this.setState({ show: true, showContent: true })
    }, this.options.timing)
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll')
  }

  handleCloseModal() {
    const { closeModal } = this.props

    this.setState({ leave: true, show: false, showContent: false })

    if (closeModal) {
      setTimeout(() => closeModal(), this.options.timing)
    }
  }

  render() {
    const { children, size, title, showCloseButton, className } = this.props
    const { show, showContent, leave } = this.state

    const componentClass = classNames('modal', 'modal-enter', {
      'modal-show': show,
      'modal-leave': leave,
    })

    const contentClass = classNames(className, 'modal-content', 'modal-content-enter', {
      'modal-content-show': showContent,
      'modal-content-leave': leave,
      [`modal-content-${size}`]: size,
    })

    return (
      <div className={componentClass}>
        <div className={contentClass}>
          <div className='modal-body'>
            {title && <div className='modal-title margin-left'>{title}</div>}
            <div className='container-fluid'>{children}</div>
          </div>
          {showCloseButton && (
            <i className='modal-close glyph glyph-x' onClick={this.handleCloseModal} />
          )}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  closeModal: PropTypes.func,
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
}

Modal.defaultProps = {
  showCloseButton: true,
}

export default Modal
