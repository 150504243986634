import classNames from 'classnames'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import SpinnerCircle from '../SpinnerCircle'

import './index.scss'

function Spinner({ visible }) {
  return (
    <section
      id='spinner-overlay'
      className={classNames('overlay column-direction', {
        'show-spinner': visible,
      })}
    >
      <SpinnerCircle />

      <span className='spinner-label'>{i18next.t('label.loading')}</span>
    </section>
  )
}

Spinner.propTypes = {
  visible: PropTypes.bool,
}

export default Spinner
