import { useMemo } from 'react'
import i18next from 'i18next'
import FormField from '../../../../../../components/FormField'

import { useServiceOrderDetailContext } from '../../../../context'

import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'

function Status() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  const currentStatusText = useMemo(
    () => ServiceOrderStatusType.getStatusWithId(serviceOrderBody?.servcOrdStusCd).value,
    [serviceOrderBody],
  )

  if (loadingBody) return <div id='status-service-order-container' className='skeleton' />

  return (
    <div id='status-service-order-container'>
      <FormField
        label={i18next.t('serviceOrderDetail.lblStatusText')}
        value={i18next.t(currentStatusText) || '-'}
      />
    </div>
  )
}
export default Status
