import i18next from 'i18next'
import moment from 'moment-timezone'

import FormField from '../../../../../../../../../../../components/FormField'
import MozaicButton from '../../../../../../../../../../../components/MozaicButton'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'
import BodySection from '../../BodySection'

function Dates({ service }: { service: ServiceExecutionEntity }) {
  const createTs = moment(service.creatTs).format('L')
  const scheduleTs = moment(service.servcOrdSchdlgTs).format('L')
  const startTs = moment(service.servcXecutnStrtDt).format('L')
  const nextIntervention = '-'
  const finallyTs = moment(service.servcXecutnEndDt).format('L')
  // TODO: Get end date from service order occurrence
  const endTs = moment(service.servcOrdSchdlgTs).format('L')

  const title = (
    <div className='title-container'>
      {i18next.t('serviceOrders.dates')}
      <MozaicButton variant='ghost' className='p-0 m-0'>
        {i18next.t('see.calendary')}
      </MozaicButton>
    </div>
  )

  return (
    <BodySection title={title} className='service-dates'>
      <FormField label={i18next.t('serviceOrders.creationDateSimple')} value={createTs} />
      <FormField label={i18next.t('needHelp.scheduling')} value={scheduleTs} />
      <FormField label={i18next.t('jobExecHistory.start.dt')} value={startTs} />
      <FormField label={i18next.t('service.exec.next.intervention')} value={nextIntervention} />
      <FormField label={i18next.t('finalization')} value={finallyTs} />
      <FormField label={i18next.t('advanced.search.dates.finally')} value={endTs} />
    </BodySection>
  )
}

export default Dates
