import i18next from 'i18next'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'
import ConfirmFooterButtons from '../ConfirmFooterButtons'

type ConfirmFooterButtonsType = {
  handleLeft: Function
  handleRight: Function
  handleClose: Function
  title: string
  beforeMessage?: string
  beforeMessageClassName?: string
  message: string
  leftBtnLabel: string
  rightBtnLabel: string
  actionButton?: string
}

export default function ConfirmModal({
  handleLeft,
  handleRight,
  handleClose,
  title,
  message,
  beforeMessage,
  beforeMessageClassName,
  leftBtnLabel,
  rightBtnLabel,
  actionButton,
}: ConfirmFooterButtonsType) {
  const visibleClass = 'visible'
  const transitionClass = 'to-down'

  return (
    <div className={`custom-modal-overlay overflow-hidden ${visibleClass}`}>
      <div
        className={`custom-modal lg budget-confirm-modal ${transitionClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className='modal-header'>
          <h3>{i18next.t(title)}</h3>
          <div className='modal-header--buttons'>
            <button
              className='modal-close-button'
              onClick={() => {
                handleClose()
              }}
            >
              <MozaicIcon icon='ControlCross32' />
            </button>
          </div>
        </header>

        <section className='budget-confirm-modal-body'>
          {beforeMessage && (
            <span className={beforeMessageClassName}>{i18next.t(beforeMessage)}</span>
          )}
          <span>{i18next.t(message)}</span>
        </section>

        <ConfirmFooterButtons
          handleLeft={handleLeft}
          handleRight={handleRight}
          leftBtnLabel={leftBtnLabel}
          rightBtnLabel={rightBtnLabel}
          actionButton={actionButton}
        />
      </div>
    </div>
  )
}
