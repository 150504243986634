import i18next from 'i18next'

export const getInformationOptions = () => [
  {
    label: i18next.t('provider.detail.information.ratingServices'),
    value: 'ratingServices',
  },
  {
    label: i18next.t('provider.detail.information.ratingInstallers'),
    value: 'ratingInstallers',
  },
  {
    label: i18next.t('provider.detail.information.done'),
    value: 'done',
  },
  {
    label: i18next.t('provider.detail.information.distributedTotal'),
    value: 'distributedTotal',
  },
  {
    label: i18next.t('provider.detail.information.distributedAutomatic'),
    value: 'distributedAutomatic',
  },
  {
    label: i18next.t('provider.detail.information.distributedManual'),
    value: 'distributedManual',
  },
  {
    label: i18next.t('provider.detail.information.accepted'),
    value: 'accepted',
  },
  {
    label: i18next.t('rescheduling'),
    value: 'rescheduling',
  },
  {
    label: i18next.t('provider.detail.information.refused'),
    value: 'refused',
  },
  {
    label: i18next.t('provider.detail.information.expired'),
    value: 'expired',
  },
  {
    label: i18next.t('provider.detail.information.notConform'),
    value: 'notConform',
  }
]
