import { useState } from 'react'
import i18next from 'i18next'

import {
  generateOcurrence,
  ParamsType,
} from '../../../../../../../services/pages/ServiceOrderDetail/GenerateOcurrence'
import { useServiceOrderDetailContext } from '../../../../../context'

import ServcOrdEvntStusType from '../../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'

import Modal from '../../../../../../../components/Modal'
import JustifyInput from '../JustifyInput'
import FooterButtons from '../FooterButtons'

type ManualDistributionModalType = {
  show: boolean
  setShow: Function
}

function ManualDistributionModal(props: ManualDistributionModalType) {
  const { serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()
  const { show, setShow } = props
  const [justify, setJustify] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    setIsSubmiting(true)

    const payload: ParamsType = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq || 0,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: `${i18next.t('serviceOrder.manual.distribution')}: ${justify}`,
      servcOrdEvntStusInd: ServcOrdEvntStusType.REFUSAL_PROCESS.id,
      indStatusOrdemServicoItem: serviceOrderBody?.servcOrdStusCd || '',
      idPrestadorServico: serviceOrderBody?.servcPrvdrCd || 0,
      idEspecialistaServico: serviceOrderBody?.servcPrvdrAgntCd || 0,
      indStatusOrdemServico: serviceOrderBody?.servcOrdStusCd || '',
    }

    await generateOcurrence(payload)

    setShow(false)
    setJustify('')
    setIsSubmiting(false)
    await reloadServiceOrder()
  }

  return (
    <div className='pop-up-modal'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => setShow(false)}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('serviceorder.manual.distribution.confirm')}
      >
        <JustifyInput value={justify} onChange={handleChangeJustify} />

        <FooterButtons
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          justify={justify}
          isSubmiting={isSubmiting}
        />
      </Modal>
    </div>
  )
}

export default ManualDistributionModal
