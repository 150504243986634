import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import i18next from 'i18next'
import { getShiftByKey } from '../../../../helpers/TextUtils'

class Table extends Component {
  renderRows = (servcPrvdrAgntAbsncPerd, index) => (
    <tr key={index}>
      <td className='align-left'>
        {moment(servcPrvdrAgntAbsncPerd.servcPrvdrAgntStrtAbsncDt).format('DD/MM/YYYY')}
      </td>
      <td className='align-left'>
        {moment(servcPrvdrAgntAbsncPerd.servcPrvdrAgntEndAbsncDt).format('DD/MM/YYYY')}
      </td>
      <td className='align-left'>{getShiftByKey(servcPrvdrAgntAbsncPerd.shiftCd || '')}</td>
      <td className='align-center'>
        {moment().isAfter(servcPrvdrAgntAbsncPerd.servcPrvdrAgntEndAbsncDt, 'day') ? (
          <span className='color-default'>{i18next.t('service.portal.finished')}</span>
        ) : (
          <>{i18next.t('service.portal.scheduled')}</>
        )}
      </td>
    </tr>
  )

  render() {
    const { servcPrvdrAgntAbsncPerds } = this.props

    return (
      <table className='table table-zebra'>
        <thead>
          <tr>
            <th className='align-left'>
              {i18next.t('servcPrvdrAgntAbsncPerd.servcPrvdrAgntStrtAbsncDt')}
            </th>
            <th className='align-left'>
              {i18next.t('servcPrvdrAgntAbsncPerd.servcPrvdrAgntEndAbsncDt')}
            </th>
            <th className='align-left'>{i18next.t('service.portal.period')}</th>
            <th className='align-center'>{i18next.t('service.portal.status')}</th>
          </tr>
        </thead>
        <tbody>{servcPrvdrAgntAbsncPerds.map(this.renderRows)}</tbody>
      </table>
    )
  }
}

Table.propTypes = {
  servcPrvdrAgntAbsncPerds: PropTypes.array.isRequired,
}

export default Table
