export default function SkeletonLoader() {
  return (
    <>
      <header>
        <span className='title skeleton skeleton-white skeleton-title' />
        <span className='subtitle skeleton skeleton-white skeleton-subtitle' />
      </header>
      <section className='quantity skeleton skeleton-white skeleton-quantity' />
    </>
  )
}
