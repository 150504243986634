import { ComponentClass, memo, useMemo } from 'react'
import { useInstallerContext } from '../../../contexts/installer'
import { useProviderAbsence } from '../../../hooks/business/useProviderAbsence'
import { useProviderContext } from '../../../contexts/provider'

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      installerEdit,
      installerDocs,
      installerAccess,
      getServiceSpecialist,
      getServiceSpecialistFromId,
      viewServiceSpecialistFromId,
      fetchServiceSpecialistNameAutocomplete,
      fetchServiceSpecialistFiles,
      saveServiceSpecialistFiles,
      viewServiceSpecialist,
      removeSpecialistFile,
      updateServiceSpecialist,
      removeServiceSpecialist,
      inactiveSpecialist,
      fetchSysAcsUser,
      handleClearInstaller,
    } = useInstallerContext()

    const { providerAbsenceList, fetchProviderAbsence } = useProviderAbsence()

    const { providersAutoCompleteList, fetchServiceProviderAutocomplete } = useProviderContext()

    const newProps = useMemo(
      () => ({
        installerEdit,
        installerDocs,
        installerAccess,
        getServiceSpecialist,
        getServiceSpecialistFromId,
        viewServiceSpecialistFromId,
        fetchServiceSpecialistNameAutocomplete,
        fetchServiceSpecialistFiles,
        saveServiceSpecialistFiles,
        viewServiceSpecialist,
        removeSpecialistFile,
        updateServiceSpecialist,
        removeServiceSpecialist,
        inactiveSpecialist,
        fetchSysAcsUser,
        disabled: installerEdit?.disabled,
        servcPrvdrAgntAbsncPerds: providerAbsenceList,
        fetchServcPrvdrAgntAbsncPerd: fetchProviderAbsence,
        serviceProviders: providersAutoCompleteList,
        fetchServiceProviderAutocomplete,
        handleClearInstaller,
      }),
      [
        installerEdit,
        installerDocs,
        installerAccess,
        getServiceSpecialist,
        getServiceSpecialistFromId,
        viewServiceSpecialistFromId,
        inactiveSpecialist,
        providerAbsenceList,
        fetchProviderAbsence,
        providersAutoCompleteList,
        handleClearInstaller,
      ],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
