import classNames from 'classnames'
import PropTypes from 'prop-types'
import Mask from 'vanilla-masker'

function InputNumber(props) {
  const {
    id,
    type = 'text',
    name,
    value,
    onChange,
    onKeyPress = () => {},
    onKeyDown = () => {},
    onKeyUp = () => {},
    onBlur,
    label,
    checked,
    disabled = false,
    minLength,
    maxLength,
    min,
    max,
    className,
    placeholder,
    separator = ',',
    delimiter = '.',
  } = props

  const handleChange = (event) => {
    const str = event.target.value.replace(/[^\d]+/g, '')

    let mask
    switch (str.length) {
      case 3:
        mask = `9${separator}99`
        break
      case 4:
        mask = `99${separator}99`
        break
      case 5:
        mask = `999${separator}99`
        break
      case 6:
        mask = `9${delimiter}999${separator}99`
        break
      case 7:
        mask = `99${delimiter}999${separator}99`
        break
      case 8:
        mask = `999${delimiter}999${separator}99`
        break
      case 9:
        mask = `9${delimiter}999${delimiter}999${separator}99`
        break
      case 10:
        mask = `99${delimiter}999${delimiter}999${separator}99`
        break
      case 11:
        mask = `999${delimiter}999${delimiter}999${separator}99`
        break
      case 12:
        mask = `9${delimiter}999${delimiter}999${delimiter}999${separator}99`
        break
      case 13:
        mask = `99${delimiter}999${delimiter}999${delimiter}999${separator}99`
        break
      case 14:
        mask = `999${delimiter}999${delimiter}999${delimiter}999${separator}99`
        break
      default:
        mask = '99'
        break
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = Mask.toPattern(event.target.value, mask)

    onChange(event)
  }

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }

    let str = event.target.value.replace(/[^\d]+/g, '')

    if (str.length === 1) {
      str = str.concat('00')
      const mask = `9${separator}99`
      // eslint-disable-next-line no-param-reassign
      event.target.value = Mask.toPattern(str, mask)
      onChange(event)
    } else if (str.length === 2) {
      str = str.concat('00')
      const mask = `99${separator}99`
      // eslint-disable-next-line no-param-reassign
      event.target.value = Mask.toPattern(str, mask)
      onChange(event)
    }
  }

  const handleKeyPress = (event) => {
    if (onKeyPress) {
      onKeyPress(event)
    }
  }

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  const handleKeyUp = (event) => {
    if (onKeyUp) {
      onKeyUp(event)
    }
  }

  const inputClass = classNames({
    input: type !== 'radio' && type !== 'checkbox',
  })
  return (
    <div>
      <input
        type={type}
        name={name}
        id={id}
        className={`${inputClass} ${className}`}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        checked={checked}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        placeholder={placeholder}
      />

      {label && (
        <label htmlFor={id} className='label'>
          {label}
        </label>
      )}
    </div>
  )
}

InputNumber.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  separator: PropTypes.string.isRequired,
  delimiter: PropTypes.string.isRequired,
}

export default InputNumber
