import { useState } from 'react'
import i18next from 'i18next'

import {
  generateOcurrence,
  ParamsType,
} from '../../../../../../../services/pages/ServiceOrderDetail/GenerateOcurrence'

import { useServiceOrderDetailContext } from '../../../../../context'

import ServcOrdEvntStusType from '../../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'

import Modal from '../../../../../../../components/Modal'
import JustifyInput from '../JustifyInput'
import FooterButtons from '../FooterButtons'
import MozaicText from '../../../../../../../components/MozaicText'

type SalesErrorModalType = {
  show: boolean
  setShow: Function
}

function SalesErrorModal(props: SalesErrorModalType) {
  const { serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()
  const { show, setShow } = props
  const [justify, setJustify] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    setIsSubmiting(true)

    const payload: ParamsType = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq || 0,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: justify,
      servcOrdEvntStusInd: ServcOrdEvntStusType.SALES_ERROR.id,
      indStatusOrdemServicoItem: serviceOrderBody?.servcOrdStusCd || '',
      indStatusOrdemServico: serviceOrderBody?.servcOrdStusCd || '',
    }

    await generateOcurrence(payload)

    setShow(false)
    setJustify('')
    setIsSubmiting(false)
    await reloadServiceOrder()
  }

  return (
    <div className='pop-up-modal'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => setShow(false)}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('access.function141')}
      >
        <MozaicText ml={1.5}>{i18next.t('attentionOccurrenceModal.text')}</MozaicText>

        <JustifyInput
          value={justify}
          onChange={handleChangeJustify}
          placeholder={i18next.t('attentionOccurrenceModal.text')}
        />

        <FooterButtons
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          justify={justify}
          isSubmiting={isSubmiting}
          confirmText={i18next.t('attentionOccurrenceModal.save')}
        />
      </Modal>
    </div>
  )
}

export default SalesErrorModal
