/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useContext, useMemo } from 'react'

import { getIdValues } from '../../helpers/array'
import { sortByType, sortByDate } from '../../helpers/sort'
import { filterProviderList } from '../../services/pages/ProviderAnalysisList'

import { senseName, getIdValuesClusters, parseRespose } from './utils'
import { useStoreClustersGroups } from './handlers/useStoreClustersGroups'
import { useFilter } from './handlers/useFilter'

const ProviderAnalysisListContext = createContext<any | null>(null)

function ProviderAnalysisListProvider({ children }: { children: ReactNode }) {
  const {
    periodType,
    period,
    companyInfo,

    orderBy,
    setOrderBy,
    orderBySense,
    setOrderBySense,

    registration,
    setRegistration,
    orderReceipt,
    setOrderReceipt,

    columnsOptions,
    selectedColumns,
    setSelectedColumns,

    results,
    setResults,
    noHasResults,
    setNoHasResults,
    tableLoading,
    setTableLoading,

    handleClearFilters,
    handleChangeOrderBy,
    handleChangeCompanyInfo,
    handleChangePeriod,
    handleChosePeriod,
  } = useFilter()

  const {
    stores,
    storesOptions,

    clusters,
    clustersOptions,

    disabledStores,
    disabledClusters,
    selectAllStores,
    selectAllClusters,

    handleClearSCG,
    handleChangeStores,
    handleChangeClusters,
  } = useStoreClustersGroups()

  const handleClear = () => {
    handleClearSCG()
    handleClearFilters()
  }

  const getPayload = () => {
    const payload = {
      registration,
      orderReceipt,
      periodType,
      periodSelected: period,
      companyInfo,
      orderBySense: senseName[orderBySense as keyof typeof senseName],
      orderBy,
      stores: getIdValues(stores, 'value'),
      clusters: getIdValuesClusters(clusters),
      columns: selectedColumns.map((column: { label: string; value: string }) => column.value),
    }

    // retrieve all options send nothing
    if (orderReceipt === 'all') delete payload.orderReceipt
    if (registration === 'all') delete payload.registration

    return payload
  }

  const handleExport = () => {}

  const handleSubmit = async () => {
    setResults([])
    setTableLoading(true)
    setNoHasResults(false)

    const payload = getPayload()
    const newEvals = await filterProviderList(payload)

    if (newEvals?.length) {
      const parsedEvals = parseRespose(newEvals)

      setResults(parsedEvals)
    }

    setNoHasResults(!newEvals?.length)
    setTableLoading(false)
  }

  const handleSortColumn = (column: string, type: string, arr = results) => {
    const sortedResult =
      column === 'registerDate' ? sortByDate(arr, column, type) : sortByType(arr, column, type)

    setResults(sortedResult)
    setOrderBy(column)
    setOrderBySense(type)
  }

  const providing = useMemo(
    () => ({
      periodType,
      period,
      companyInfo,
      stores,
      orderBy,
      clusters,
      orderBySense,
      storesOptions,
      clustersOptions,
      disabledStores,
      disabledClusters,
      results,
      selectAllStores,
      selectAllClusters,
      tableLoading,
      noHasResults,
      setOrderBySense,
      isActive: registration,
      setIsActive: setRegistration,
      receivingOs: orderReceipt,
      setReceivingOs: setOrderReceipt,
      columnsOptions,
      selectedColumns,
      setSelectedColumns,
      handleChangeStores,
      handleChangeClusters,
      handleChangeOrderBy,
      handleChangeCompanyInfo,
      handleChangePeriod,
      handleChosePeriod,
      handleClearFilters: handleClear,
      handleFilterData: handleSubmit,
      handleExportProviderList: handleExport,
      handleSortColumn,
    }),
    [
      periodType,
      period,
      companyInfo,
      stores,
      orderBy,
      clusters,
      orderBySense,
      storesOptions,
      clustersOptions,
      disabledStores,
      disabledClusters,
      results,
      selectAllStores,
      selectAllClusters,
      tableLoading,
      noHasResults,
      setOrderBySense,
      setRegistration,
      orderReceipt,
      setOrderReceipt,
      columnsOptions,
      selectedColumns,
      setSelectedColumns,
      handleChangeStores,
      handleChangeClusters,
      handleChangeOrderBy,
      handleChangeCompanyInfo,
      handleChangePeriod,
      handleChosePeriod,
      handleSubmit,
      handleExport,
      handleSortColumn,
    ],
  )

  return (
    <ProviderAnalysisListContext.Provider value={providing}>
      {children}
    </ProviderAnalysisListContext.Provider>
  )
}

const useProviderAnalysisListContext = () => useContext(ProviderAnalysisListContext)

export { ProviderAnalysisListContext, useProviderAnalysisListContext, ProviderAnalysisListProvider }
