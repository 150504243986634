import i18next from 'i18next'

import { TextField } from '@leroy-merlin-br/backyard-react'
import MozaicIcon from '../../../../../../components/MozaicIcon'

import { WithContext } from './context'
import { AdvancedSearchInputType } from './models'

function AdvancedSearchInput(props: AdvancedSearchInputType) {
  const {
    mobile,
    placeholder = i18next.t('nav.bar.advanced.search.placeholder'),
    mask = null,
    filters,
    hasFilters,
    handleOpen,
    handleSubmit,
    search,
    setSearch,
  } = props

  const handleInput = (e: any) => {
    const value = mask ? mask(e.target.value) : e.target.value
    setSearch(value)
  }

  const keyDownHandler = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleClearSearchedText = () => {
    setSearch('')
  }

  const prefixIcon = () => (
    <div
      className='search-icon'
      onClick={() => {
        handleSubmit()
      }}
    >
      <MozaicIcon icon='DisplaySearch32' />
    </div>
  )

  const hasFiltersClass = hasFilters ? 'has-filters' : ''
  const suffixIcons = () => (
    <>
      {search !== '' && (
        <div className='clear-filter' onClick={() => handleClearSearchedText()}>
          <MozaicIcon icon='ControlTagCross32' />
        </div>
      )}
      <div className='filter-icon' onClick={() => handleOpen()}>
        {hasFiltersClass ? (
          <MozaicIcon icon='DisplayFilter32' color='primary' className='filter-green' />
        ) : (
          <MozaicIcon icon='DisplayFilter32' color='black' />
        )}
      </div>
    </>
  )

  const greenBorder = filters?.currentSearchFieldValue ? 'advanced-search-input-green-border' : ''
  const mobileClass = mobile ? 'mobile' : ''

  return (
    <div id='advanced-search-input' className={`${mobileClass} ${greenBorder}`}>
      <TextField
        hasSuffix
        hasPrefix
        renderSuffix={suffixIcons}
        renderPrefix={prefixIcon}
        value={search}
        placeholder={placeholder}
        hint={i18next.t('nav.bar.advanced.search.hint')}
        onChange={(event: any) => handleInput(event)}
        autoComplete='off'
        type='search'
        id='sidenav-search-input'
        onKeyUp={(e) => keyDownHandler(e)}
      />
    </div>
  )
}

export default WithContext(AdvancedSearchInput)
