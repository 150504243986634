export const shifts: any = {
  1: 'M',
  2: 'A',
  3: 'E',
}

export const _shifts: any = {
  M: 1,
  A: 2,
  E: 3,
}

export type optionType = {
  value: any
  label: string
}
