import ServcOrdCategoryType from '../enums/ServiceOrder/category/serviceCategoryType'

const deniedCategories = [
  ServcOrdCategoryType.STORE_SERVICE.id,
  ServcOrdCategoryType.TECHNICAL_VISIT.id,
  ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id,
  ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id,
  ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.id,
].map((val) => parseInt(val))

export default deniedCategories
