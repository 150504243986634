import ApiAnew from '../core/Http/ApiAnew'

import { showSpinner, hideSpinner } from '../redux/actions/spinner'
import store from '../redux/store'

export const makeCallAnew = async (data) => {
  store.dispatch(showSpinner())

  try {
    const response = await new ApiAnew().request('Discar', data)
    return response
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.dispatch(hideSpinner())
  }
}
