/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { FirestoreProviderProps, FirestoreStore } from './models'

import CustomerChatService from '../../services/firestore/customerChat/customerChat'

import { getStoresOptions } from '../../services/Stores'
import ConversationHandlers from './handlers/conversation'

import firebase from 'firebase/compat/app'
import moment from 'moment-timezone'
import ConversationListHandlers from './handlers/conversationList'

const FirestoreContext = createContext<FirestoreStore | null>(null)
const watchers: Array<any> = []

let _searchMessages: any
let _fetchMessages: any
let _tryCreateWatcher: any

function FirestoreProvider(props: FirestoreProviderProps) {
  const { filters, children } = props
  const { codLocal } = filters

  const chatService = CustomerChatService()

  const [allStores, setAllStores] = useState([])

  const {
    fetchMessages,
    searchMessages,
    setSearchField,
    handleListElRemoved,
    handleListElModified,
    handleClearConversationList,
    searchField,
    chatReference,
    conversationList,
    loadingConversations,
    countNoResolvedConversations,
  } = ConversationListHandlers(chatService, allStores, codLocal)

  const {
    handleClearConversation,
    handleUpdateConversation,
    handleReadClientMessages,
    handleResolveConversation,
    handleSetClientConversation,
    openedClientConversation,
    hasOpenedClientConversation,
  } = ConversationHandlers(chatService)

  function tryCreateAgain(branchs: Array<string>) {
    clearTimeout(_tryCreateWatcher)
    // eslint-disable-next-line no-use-before-define
    _tryCreateWatcher = setTimeout(() => createSnapshotWatcher(branchs), 1000)
  }

  function snapshotWatcher(snapshot: firebase.firestore.QuerySnapshot) {
    const actions = {
      added: handleListElModified,
      removed: handleListElRemoved,
      modified: handleListElModified,
    }

    snapshot.docChanges().forEach((change) => {
      const type: firebase.firestore.DocumentChangeType = change.type
      const message = change.doc.data()

      actions[type](message)
    })

    return snapshot
  }

  async function createSnapshotWatcher(branchs: Array<string>) {
    watchers.splice(0, watchers.length)

    const today = +moment()
    const slices = Math.ceil(branchs.length / 10)

    try {
      for (let i = 0; i < slices; i++) {
        const stores = branchs.slice(i * 9, (i + 1) * 9)

        new Promise((resolve) => {
          const snapshot = chatReference
            .where('lastMessage.createdAt', '>=', today)
            .where('plntCd', 'in', stores)
            .orderBy('lastMessage.createdAt', 'desc')
            .onSnapshot((snapshot: any) => resolve(snapshotWatcher(snapshot)))

          watchers.push(snapshot)
        }).catch(() => tryCreateAgain(branchs))
      }
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

  async function initStoreWatchers() {
    const hasError = await chatService.checkChatV2()

    if (!hasError) {
      const stores = await getStoresOptions()
      const formattedStores = stores.map((store: { value: any }) => store.value)

      setAllStores(formattedStores)
      createSnapshotWatcher(formattedStores)
    }
  }

  useEffect(() => {
    initStoreWatchers()
  }, [])

  useEffect(() => {
    clearTimeout(_searchMessages)

    if (searchField) _searchMessages = setTimeout(() => searchMessages(), 1000)
    else fetchMessages()
  }, [searchField])

  useEffect(() => {
    clearTimeout(_fetchMessages)

    _fetchMessages = setTimeout(() => fetchMessages(), 0)
  }, [filters.codLocal])

  function backToConversationList() {
    handleClearConversation()
    setSearchField('')
    fetchMessages()
  }

  return (
    <FirestoreContext.Provider
      value={{
        searchField,
        chatReference,
        conversationList,
        loadingConversations,
        openedClientConversation,
        hasOpenedClientConversation,
        countNoResolvedConversations,
        fetchMessages,
        handleClearConversation,
        handleUpdateConversation,
        handleReadClientMessages,
        handleResolveConversation,
        handleClearConversationList,
        handleSetClientConversation,
        handleSetSearchField: setSearchField,
        fetchMessagesBySearchText: searchMessages,
        handleBackToConversationList: backToConversationList,
      }}
    >
      {children}
    </FirestoreContext.Provider>
  )
}

const mapStateToProps = ({ newCockpitReducer }: any) => ({
  filters: newCockpitReducer.filters,
})

const FirestoreProviderConnected = connect(mapStateToProps)(FirestoreProvider)

const useFirestoreContext = () => useContext(FirestoreContext) as FirestoreStore

export { FirestoreContext, FirestoreProviderConnected as FirestoreProvider, useFirestoreContext }
