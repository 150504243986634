import { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '../../../components/utils/Grid'
import TitleSection from '../../../components/utils/TitleSection'

class BudgetDistributed extends Component {
  state = {
    selected: false,
  }

  handleOptionAccepted = () => {
    const { budgetDistributed } = this.props
    budgetDistributed()
  }

  getClassName = () => {
    const { block, boxSelected } = this.props

    return `option ${
      boxSelected === true ? 'selected' : block === false ? 'selected' : 'no-selected'
    }`
  }

  getClassNameSubtitle = () => {
    const { boxSelected } = this.props
    return `text-sub-option ${boxSelected ? 'visible' : 'no-visible'}`
  }

  getClassNameBody = () => {
    const { block, boxSelected } = this.props
    if (block) {
      if (boxSelected) {
        return 'body-budget-distributed block'
      }
      return 'body-budget-distributed'
    }
    return 'body-budget-distributed block'
  }

  render() {
    const { t } = this.props
    return (
      <Grid fluid className={this.getClassNameBody()}>
        <TitleSection>
          <h1>{t('servc.ord.budget.component.budget.distributed')}</h1>
        </TitleSection>
        <div className='body-budgets-accepted'>
          <h2 className='subtitle'>{t('servc.ord.budget.component.budget.distributed.subtile')}</h2>
          <div className='body'>
            <div className={this.getClassName()} onClick={() => this.handleOptionAccepted()}>
              <p className='text-option'>{t('servc.ord.budget.component.accepted.option.yes')}</p>
              <p className={this.getClassNameSubtitle()}>
                {t('servc.ord.budget.component.budget.distributed.yes.subtitle')}
              </p>
            </div>
          </div>
        </div>
      </Grid>
    )
  }
}

BudgetDistributed.propTypes = {
  t: PropTypes.func.isRequired,
  budgetDistributed: PropTypes.func,
  block: PropTypes.bool.isRequired,
  boxSelected: PropTypes.bool.isRequired,
}

export default BudgetDistributed
