import i18next from 'i18next'
import moment, { isMoment } from 'moment-timezone'
import { useState } from 'react'
import { connect } from 'react-redux'

import Modal from '../../../../components/Modal'
import OutlineButton from '../../../../components/OutlineButton'
import DateSelect from './components/DateSelect'
import FooterButtons from './components/FooterButtons'
import JustifyInput from './components/JustifyInput'
import PeriodSelect from './components/PeriodSelect'

import User from '../../../../core/User'
import store from '../../../../redux/store'

import { Text } from '@leroy-merlin-br/backyard-react'
import { useServiceOrderContext } from '../../../../contexts/serviceOrder'
import { hideSpinner, showSpinner } from '../../../../redux/actions/spinner'
import { RescheduleService } from '../../../../services/pages/ServiceOrderDetail/RescheduleService'
import { RescheduleProvider } from './context'

import ServiceOrderStatusType from '../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ReasonSelect from './components/ReasonSelect'

const OUT_FLOW_REFUSED = 3

const user = new User().currentUser

type RescheduledServiceType = {
  auth?: any
  statusCd: string
}

const shifts: any = {
  1: 'M',
  2: 'A',
  3: 'E',
}

function RescheduledService(props: RescheduledServiceType) {
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState<any>(null)
  const [justify, setJustify] = useState('')
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
  const { serviceOrder, resetServiceOrder }: any = useServiceOrderContext()

  const { statusCd } = props
  const { auth } = store.getState()
  const { setupParameters } = auth
  const { funcaoAcesso } = user

  const handleJustifyText = (e: any) => setJustify(e.target.value)
  const handleChangeDate = (e: any) => {
    if (!isMoment(e)) return setSelectedDate(null)

    const newDate = e.startOf('day')
    if (+newDate === +selectedDate?.startOf('day')) return false

    setSelectedDate(newDate)
    setReason(null)
  }

  const handleChangePeriod = (e: any) => {
    if (e?.value === selectedPeriod?.value) return false

    setSelectedPeriod(e)
    setReason(null)
  }

  const handleClose = () => {
    setShow(false)

    setReason(null)
    setJustify('')
    setSelectedDate(null)
    setSelectedPeriod(null)
  }

  const handleSubmit = async () => {
    store.dispatch(showSpinner())

    const justification = reason ? `${reason.value}${justify ? ` - ${justify}` : ''}` : null

    const form = {
      servcOrdSeq: serviceOrder?.servcOrdSeq,
      servcOrdSchdlgTs: selectedDate.format(),
      servcOrdSchdlgShiftCd: shifts[selectedPeriod.value],
      justification,
    }

    const response = await RescheduleService(form, true)

    if (response.cdStatus === 200) {
      handleClose()
      resetServiceOrder()
    }

    store.dispatch(hideSpinner())
  }

  const isOutFlowProccess = () => {
    if (
      (setupParameters.serviceOrderOutOfFlowEnable &&
        serviceOrder &&
        serviceOrder.idOutFlowProcess &&
        serviceOrder.idOutFlowProcess !== OUT_FLOW_REFUSED) ||
      (serviceOrder && serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id)
    ) {
      return true
    }

    return false
  }

  const showRescheduleModal = () => {
    const STATUS_PRE_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id
    const STATUS_DISTRIBUIDA = statusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id
    const STATUS_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const hasFunction00 = funcaoAcesso.inFuncao00 === 1
    const hasFunction01 = funcaoAcesso.inFuncao01 === 1
    const hasFunction02 = funcaoAcesso.inFuncao02 === 1

    const preScheduleRule = STATUS_PRE_AGENDADA && !hasFunction00
    const scheduleRule = STATUS_AGENDADA && !hasFunction01 && !hasFunction00
    const distributeRule = STATUS_DISTRIBUIDA && (hasFunction02 || !hasFunction01)
    const isConformity = serviceOrder?.isConformity && setupParameters.conformity

    if (STATUS_DISTRIBUIDA) return true
    if (isOutFlowProccess()) return false

    if (isConformity) return false
    if (distributeRule) return false
    if (scheduleRule) return false
    if (preScheduleRule) return false

    return STATUS_PRE_AGENDADA || STATUS_DISTRIBUIDA || STATUS_AGENDADA
  }

  const modalTitle = 'customer.service.order.details.scheduling.date'
  const modalSubtitle = `${i18next.t('service.detail.reschedule.modal.subtitle')}.`

  const deliveryDate = moment(serviceOrder.servcOrdPrmsdDlvryDt).startOf('day')

  const hasDeliveryDate =
    setupParameters?.distributeServiceBlockBeforeDelivery && serviceOrder?.servcOrdPrmsdDlvryDt

  const deliveryTime = deliveryDate ? +deliveryDate : 0

  const today = moment().startOf('day')
  const todayTime = +today
  const currentTime = selectedDate ? +selectedDate.startOf('day') : 0

  const compareTodayDate = currentTime >= +todayTime
  const compareDeliveryDate = hasDeliveryDate ? currentTime >= +deliveryTime : true

  const providerProps = {
    serviceOrder,
    selectedDate,
    selectedPeriod,
    setSelectedDate: handleChangeDate,
    setSelectedPeriod: handleChangePeriod,
    show,
  }

  return (
    <div id='reschedule-service'>
      <RescheduleProvider {...providerProps}>
        {showRescheduleModal() && (
          <OutlineButton label={i18next.t('cockpit.reschedule')} onClick={() => setShow(!show)} />
        )}

        {show && (
          <Modal
            size='lg'
            isOpen={show}
            onClose={handleClose}
            className='no-margin-header'
            closeInOverlay={false}
            contentLabel={modalTitle}
          >
            <div className='reschedule-modal-content'>
              <Text noMargin>{modalSubtitle}</Text>

              <div className='flex-date-selects'>
                <div className='date-select'>
                  <DateSelect value={selectedDate} onChange={handleChangeDate} />
                </div>
                <div className='period-select'>
                  <PeriodSelect
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    selectedDate={selectedDate}
                    compareTodayDate={compareTodayDate}
                    compareDeliveryDate={compareDeliveryDate}
                  />
                </div>
              </div>

              <ReasonSelect
                value={reason}
                onChange={setReason}
                selectedDate={selectedDate}
                selectedPeriod={selectedPeriod}
              />

              <JustifyInput
                value={justify}
                onChange={handleJustifyText}
                selectedPeriod={selectedPeriod}
                selectedDate={selectedDate}
              />
            </div>

            <FooterButtons
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              selectedDate={selectedDate}
              selectedPeriod={selectedPeriod}
              reason={reason}
            />
          </Modal>
        )}
      </RescheduleProvider>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, null)(RescheduledService)
