import { useMemo } from 'react'
import moment from 'moment-timezone'
import i18next from 'i18next'
import ReactHtmlParser from 'react-html-parser'

import { HistoricOccurrence } from '../../pages/ServiceOrderDetail/components/Routes/Historic/components/HistoricOccurrences/models/historic-occurrence'

import Audio from './Audio'
import FormField from '../FormField'
import ServcOrdEvntStusType from '../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import Storage from '../../services/firestore/storage'
import { CHAT_TAG } from '../../constants/config'
import { getOsRoutePrefix } from '../../helpers/serviceOrderUtils'

export const isCall = (servcOrdItemEvntDesc: string) =>
  servcOrdItemEvntDesc ? servcOrdItemEvntDesc.includes('api.twilio.com') : false

const COLS_WIDTH = ['25%', '75%']

const regexLink =
  // eslint-disable-next-line
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const regexOscd = new RegExp(`${CHAT_TAG.OS_OPEN}(.*?)${CHAT_TAG.OS_CLOSE}`, 'g')

function OcurrencesTable({
  occurrences,
  v2 = false,
}: {
  occurrences: HistoricOccurrence[]
  v2?: boolean
}) {
  const storage = useMemo(() => new Storage(), [])
  const prefix = useMemo(() => getOsRoutePrefix(), [])

  const renderOcurrenceValue = (ocurrence: HistoricOccurrence) => {
    const isAudio = ocurrence.servcOrdEvntStusInd === ServcOrdEvntStusType.PROVIDER_AUDIO.id
    const desc = ocurrence.servcOrdItemEvntDesc

    if (isAudio) {
      return <Audio ocurrence={ocurrence} storage={storage} />
    }

    if (isCall(desc)) {
      return (
        <audio src={desc} controls>
          <i>{i18next.t('serviceOrders.unsupportedAudio')}</i>
        </audio>
      )
    }

    let text = desc.replace(
      regexOscd,
      (_, os) => `<a href="${prefix}/${os}" target="_blank">${os}</a>`,
    )

    text = text.replace(regexLink, (match) => `<a href="${match}" target="_blank">${match}</a>`)

    return <span>{ReactHtmlParser(text)}</span>
  }

  const descLabel = v2
    ? i18next.t('serviceOrders.Occurrence')
    : i18next.t('serviceOrder.historic.occurrences.description')

  return (
    <div className='occurrences-table-v2'>
      <div className='head'>
        <div className='row'>
          {v2 && (
            <div className='col' style={{ width: COLS_WIDTH[0] }}>
              {i18next.t('entity.service')}
            </div>
          )}
          <div className='col' style={{ width: COLS_WIDTH[0] }}>
            {i18next.t('serviceOrder.historic.occurrences.userAndDate')}
          </div>
          <div className='col' style={{ width: COLS_WIDTH[1] }}>
            {i18next.t('serviceOrder.historic.occurrences.statusAndDescription')}
          </div>
        </div>
      </div>

      <div className='body'>
        {occurrences.map((o, i) => (
          <div key={`ocurrence-${o.servcOrdItemEvntId}-${i}`} className='row ocurrence'>
            {v2 && (
              <div className='col' style={{ width: COLS_WIDTH[0] }}>
                <FormField label={i18next.t('entity.service')} value='01 - Mock Service' />
              </div>
            )}

            <div className='col' style={{ width: COLS_WIDTH[0] }}>
              <FormField
                label={i18next.t('serviceOrder.historic.occurrences.user')}
                value={o.nomUserRegistroOcor || '-'}
              />

              <FormField
                label={i18next.t('serviceOrder.historic.occurrences.date')}
                value={moment(o.creatTs).format('L LTS')}
              />
            </div>

            <div className='col third-col' style={{ width: COLS_WIDTH[1] }}>
              <FormField
                label={i18next.t('serviceOrder.historic.occurrences.status')}
                value={
                  o.servcOrdEvntStusInd
                    ? i18next.t(
                        `serviceOrders.occurrences.servcOrdEvntStusInd.${o.servcOrdEvntStusInd}`,
                      )
                    : '-'
                }
              />

              <FormField label={descLabel} value={renderOcurrenceValue(o)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OcurrencesTable
