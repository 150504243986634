import { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import Api from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'

import SpinnerCircle from '../../components/utils/SpinnerCircle'
import Images from '../../components/utils/Images'

import ServiceOrderItem from '../ServiceOrderEdit/components/ServiceOrderItem'
import { renderToastWarn } from '../../helpers/ToastUtils'

class TechnicalReportContainer extends Component {
  constructor(props) {
    super(props)
    const {
      location: { state },
    } = props

    this.state = {
      serviceOrderImages: state && state.serviceOrderImages,
      serviceOrderItem: state && state.serviceOrderItem,
    }
  }

  componentDidMount() {
    const { serviceOrderItem } = this.state

    if (!serviceOrderItem) {
      return this.fetchServiceOrderItem()
    }

    this.handleImages()
  }

  handleImages = () => {
    const { serviceOrderItem, serviceOrderImages } = this.state

    if (!serviceOrderImages) {
      this.fetchServiceOrderImages(serviceOrderItem)
    }
  }

  fetchServiceOrderItem = () => {
    const {
      auth: { user },
      dispatch,
      params: { servcOrdItemNr },
    } = this.props

    const params = {
      servcOrdItemNr,
    }

    return new Api(user.dsToken, dispatch)
      .post(API_URL.SERVICE_ORDER_ITEM_FIND_FILTER, params)
      .then(this.fetchServiceOrderItemSuccess)
      .catch(renderToastWarn)
  }

  fetchServiceOrderItemSuccess = ({ data }) => {
    if (data.length) {
      return this.setState({ serviceOrderItem: data[0] }, this.handleImages)
    }

    const { t } = this.props
    renderToastWarn(t('item.notFound'))
  }

  fetchServiceOrderImages = ({ servcOrdSeq }) => {
    const {
      auth: { user },
      dispatch,
    } = this.props

    const params = {
      servcOrdSeq,
      fileTyp: 'img',
    }

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_SERVICE_ORDER_IMAGES, params)
      .then(this.fetchServiceOrderImagesSuccess)
      .catch(renderToastWarn)
  }

  fetchServiceOrderImagesSuccess = ({ data }) => {
    this.setState({ serviceOrderImages: data })
  }

  renderLoading = () => (
    <div className='padding-double align-center'>
      <SpinnerCircle />
    </div>
  )

  renderServiceOrderItem = () => {
    const { t } = this.props
    const { serviceOrderItem } = this.state

    if (!serviceOrderItem) {
      return this.renderLoading()
    }

    return (
      <div>
        <h4 className='no-margin padding-double no-padding-bottom'>{t('serviceOrders.service')}</h4>

        <div className='padding padding-top-small'>
          <ServiceOrderItem
            className='no-padding-top'
            key={serviceOrderItem.idOrdemServicoItem}
            serviceOrderItem={serviceOrderItem}
            t={t}
            simple
            noDivider
          />
        </div>
      </div>
    )
  }

  renderReportDetails = () => {
    const { serviceOrderItem } = this.state

    if (!serviceOrderItem) {
      return this.renderLoading()
    }

    return <div className='padding-vertical'>{serviceOrderItem.descObservacao}</div>
  }

  renderImages = () => {
    const { t } = this.props
    const { serviceOrderImages } = this.state

    if (!serviceOrderImages) {
      return this.renderLoading()
    }

    return <Images t={t} images={serviceOrderImages} />
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <h1 className='no-margin padding-double'>{t('technical.visit.report')}</h1>

        <div className='divider-top divider-bottom'>{this.renderServiceOrderItem()}</div>

        <div className='padding-double'>
          <h1 className='no-margin'>{t('technical.report.details')}</h1>

          {this.renderReportDetails()}

          {this.renderImages()}
        </div>
      </div>
    )
  }
}

TechnicalReportContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  params: PropTypes.object,
}
// Conecta componente ao I18next
const TechnicalReportTranslated = withTranslation()(TechnicalReportContainer)

// Conecta o componente com o redux
export default TechnicalReportTranslated

// Export default como componente
export { TechnicalReportContainer as TechnicalReport }
