import i18next from 'i18next'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import Select from 'react-select'
import Field from '../../../../components/utils/Field'
import { colourStyles } from '../../../../helpers/selectStyle'
import { fetchSpecialitiesIdsByProvider } from '../../../../services/pages/ServiceProviderEdit/ProviderSpecialities'
import {
  findProviderSpecialistSpecialities,
  findProviderSpecialistSpecialitiesIds,
  updateProviderSpecialistSpecialities,
} from '../../../../services/pages/ServiceSpecialistEdit/SpecialistSpecialities'
import { Speciality, fetchSpecialities } from '../../../../services/specialities/fetchSpecialities'

interface SpecialitiesSelectProps {
  disabled?: boolean
  servcPrvdrId: string
  servcPrvdrAgntId: number | undefined
  label: string
  priority?: number
  servcOrdSpecialtiesPriorityEnabled?: boolean
}

const SpecialitiesSelect = forwardRef(
  (
    {
      servcPrvdrId,
      servcPrvdrAgntId,
      disabled,
      label,
      priority,
      servcOrdSpecialtiesPriorityEnabled,
    }: SpecialitiesSelectProps,
    ref: any,
  ) => {
    const [loading, setLoading] = useState(false)

    const [allSpecialities, setAllSpecialities] = useState<Speciality[]>([])
    const [filteredSpecialities, setFilteredSpecialities] = useState<Speciality[]>([])

    const [specialitiesValue, setSpecialitiesValue] = useState<Speciality['specialityCd'][]>([])

    const [lastServcPrvdrId, setLastServcPrvdrId] = useState<string>()

    useImperativeHandle(ref, () => ({
      save: (agntId: number) =>
        updateProviderSpecialistSpecialities(agntId, specialitiesValue || []),
      getSpecialities: () => specialitiesValue.map((s: any) => ({ code: s, priority })),
      validate: () => true,
    }))

    const setSpecialitiesFieldValue = (providerSpecialitiesIds: string[]) => {
      // set all provider specialities to installer when provider changed or is new register
      if (!servcPrvdrAgntId || servcPrvdrId !== lastServcPrvdrId) {
        setSpecialitiesValue(providerSpecialitiesIds || [])
      }
    }

    const getAllSpecialities = async () => {
      const specialities = (await fetchSpecialities()).data
      setAllSpecialities(specialities || [])
    }

    const getProviderSpecialistSpecialities = async () => {
      let providerSpecialistSpecialitiesIds

      if (servcOrdSpecialtiesPriorityEnabled) {
        const providerSpecialistSpecialities = (
          await findProviderSpecialistSpecialities(servcPrvdrAgntId!)
        ).data
        // Filter by priority
        providerSpecialistSpecialitiesIds = providerSpecialistSpecialities.specialities
          .filter((s: any) => s.priority === priority)
          .map((s: any) => s.specialityCd)
      } else {
        providerSpecialistSpecialitiesIds = (
          await findProviderSpecialistSpecialitiesIds(servcPrvdrAgntId!)
        ).data
      }

      setSpecialitiesValue(providerSpecialistSpecialitiesIds || [])
    }

    const getProviderSpecialities = () => {
      fetchSpecialitiesIdsByProvider(servcPrvdrId).then((providerSpecialitiesIds) => {
        const specialitiesFiltered = allSpecialities.filter((speciality) =>
          providerSpecialitiesIds.data?.includes(speciality.specialityCd),
        )
        setFilteredSpecialities(specialitiesFiltered || [])

        if (servcOrdSpecialtiesPriorityEnabled && priority === 1) {
          setSpecialitiesFieldValue(providerSpecialitiesIds.data || [])
        } else if (!servcOrdSpecialtiesPriorityEnabled) {
          setSpecialitiesFieldValue(providerSpecialitiesIds.data || [])
        }
      })
    }

    // call when render
    useEffect(() => {
      setLoading(true)
      getAllSpecialities().finally(() => setLoading(false))
    }, [])

    // call when servcPrvdrAgntId is loaded
    useEffect(() => {
      setLoading(true)
      if (servcPrvdrAgntId) getProviderSpecialistSpecialities().finally(() => setLoading(false))
    }, [servcPrvdrAgntId])

    // call when set allSpecialities (one time) and when change provider
    useEffect(() => {
      if (allSpecialities.length && servcPrvdrId) {
        getProviderSpecialities()
      }
      setLastServcPrvdrId(servcPrvdrId)
    }, [allSpecialities, servcPrvdrId])

    const placeholder = loading ? i18next.t('option.loading') : i18next.t(label)

    const options = filteredSpecialities.map((speciality) => ({
      value: speciality.specialityCd,
      label: speciality.specialityNm,
    }))

    const selectValue =
      specialitiesValue.map((value) => ({
        value,
        label: options.find((option) => option.value === value)?.label,
      })) || []

    return (
      <Field input={{ value: specialitiesValue }} validate>
        <label htmlFor='specialities-select' className='label'>
          {placeholder}
        </label>
        <Select
          id='specialities-select'
          value={selectValue}
          onChange={(value: any) => {
            setSpecialitiesValue(value?.map((v: { value: string; label: string }) => v.value) || [])
          }}
          placeholder={placeholder}
          options={options}
          isClearable
          resetValue={[]}
          clearValueText={i18next.t('option.removeItem')}
          noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          isMulti
          closeMenuOnSelect={false}
          styles={colourStyles}
          isDisabled={disabled}
        />
      </Field>
    )
  },
)

export default SpecialitiesSelect
