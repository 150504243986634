import ErrorFallback from '../../../../../../../errors/fallbacks'
import ErrorBoundary from '../../../../../../../errors/boundaries'
import CustomCard from '../../../../../../../components/Card'
import ClientBox from './components/ClientBox'
import PhoneBox from './components/PhoneBox'
import AddressBox from './components/AddressBox'

import { WithContext } from './context'

function ClientInfo({ reloadServiceOrder }: any) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetErrorBoundary={() => reloadServiceOrder()}
    >
      <div className='client-information'>
        <CustomCard styled>
          <div className='row'>
            <div className='col-xs-6'>
              <ClientBox />

              <div className='divider' />

              <PhoneBox />
            </div>
            <div className='col-xs-6 mt-3'>
              <AddressBox />
            </div>
          </div>
        </CustomCard>
      </div>
    </ErrorBoundary>
  )
}

export default WithContext(ClientInfo)
