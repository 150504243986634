import API_URL from '../../core/Envs/endpoints'
import API_REQUEST from '../../core/Http/FetchAdapter'

import User from '../../core/User'

import FileSaver from 'file-saver'
import { base64toBlob } from '../../helpers/Toolbox'
import store from '../../redux/store'

const user = new User().currentUser

interface IResponse {
  cdStatus: number
  timestamp: string
  dsToken: string
  tokenExpirationTime: string
  data: string
  type: number
}

export const downloadFileSuccess = (
  response: IResponse,
  attachmentDescription: string,
  src = '',
) => {
  let blob: any
  let data

  if (response.type === 0) {
    blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' })
    FileSaver.saveAs(blob, attachmentDescription)
  } else if (response.type === 1) {
    data = new Blob([base64toBlob(response.data, 'application/pdf')], {
      type: 'application/pdf',
    })
    FileSaver.saveAs(data, attachmentDescription)
  } else if (response.type === 2) {
    data = base64toBlob([response.data], 'image/png')
    FileSaver.saveAs(data, attachmentDescription)
  } else if (response.type === 3) {
    data = base64toBlob([response.data], 'image/jpg')
    FileSaver.saveAs(data, attachmentDescription)
  } else if (response.type === 4) {
    data = base64toBlob([response.data], 'file/xlsx')
    FileSaver.saveAs(data, attachmentDescription)
  } else {
    blob = new Blob([response.data])
    if ((blob || blob?.size === 0) && src) {
      if (src?.includes('data:image/png;base64,')) {
        blob = base64toBlob(src?.replace('data:image/png;base64,', ''), 'image/png')
      } else if (src?.includes('data:image/jpg;base64,')) {
        blob = base64toBlob(src?.replace('data:image/jpg;base64,', ''), 'image/jpg')
      } else if (src?.includes('data:image/jpeg;base64,')) {
        blob = base64toBlob(src?.replace('data:image/jpeg;base64,', ''), 'image/jpeg')
      }
    }
    FileSaver.saveAs(blob, attachmentDescription)
  }
}

export const downloadFile = (fileName: string, attachmentDescription: string, src = '') => {
  const URL = API_URL.AMAZON_AWS_DOWNLOAD_FILE

  const fields = {
    fileName,
  }

  return new API_REQUEST(user.dsToken, store.dispatch)
    .get(URL, fields)
    .then((response) => {
      downloadFileSuccess(response, attachmentDescription, src)
    })
    .catch((error) => {
      console.error(error)
    })
}
