import { memo } from 'react'
import MozaicIcon from '../MozaicIcon'

interface DotsButtonProps {
  onClickDotsButton?: () => void
}

function DotsButton(props: DotsButtonProps) {
  const { onClickDotsButton } = props

  const handleClick = () => {
    onClickDotsButton && onClickDotsButton()
  }

  return (
    <button type='button' className='dots-button' onClick={() => handleClick()}>
      <MozaicIcon icon='DisplayMenu24' />
    </button>
  )
}

export default memo(DotsButton)
