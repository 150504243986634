import { useEffect, useLayoutEffect, useState } from 'react'
import store from '../redux/store'

const menuAnimationTime = 300

export default function usePageSize() {
  const page = document.querySelector('.page-content')

  const { isOpen } = store.getState().menuReducer

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  function updateSize() {
    setSize({ width: page?.clientWidth || 0, height: page?.clientHeight || 0 })
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    setTimeout(() => updateSize(), menuAnimationTime)
  }, [isOpen])

  return size
}
