import i18next from 'i18next'
import { formatCnpj } from '../../../helpers/validations'

import {
  clusterStoreAllSelected,
  getStoresByClusterId,
  handleResolveStoreLabel,
} from '../../../services/Stores'

import { getIdValues, getUniqueListBy } from '../../../helpers/array'
import { Clusters, Store } from '../../../models/StoreClustersGroups'

export const getIsActiveOptions = () => [
  { label: i18next.t('all'), value: 'all' },
  { label: i18next.t('active'), value: 1 },
  { label: i18next.t('option.inactive'), value: 0 },
]

export const getReceivingOsOptions = () => [
  { label: i18next.t('all'), value: 'all' },
  { label: i18next.t('option.yes'), value: 1 },
  { label: i18next.t('option.no'), value: 0 },
]

export const getColumns = () => [
  {
    label: i18next.t('bankDetails.company_label'),
    value: 'CNPJ',
  },
  {
    label: i18next.t('registration.date.text'),
    value: 'registerDate',
  },
  {
    label: i18next.t('menu.link.register'),
    value: 'isActive', // orderReceipt
  },
  {
    label: i18next.t('serviceProvider.actvServcPrvdrOrdRecvInd'),
    value: 'receivingOS',
  },
  {
    label: i18next.t('evaluation.services.text'),
    value: 'ratingServices',
  },
  {
    label: i18next.t('evaluation.specialists.text'),
    value: 'ratingInstallers',
  },
  {
    label: i18next.t('performed.finished.text'),
    value: 'done',
  },
  {
    label: i18next.t('distributed.total.text'),
    value: 'distributedTotal',
  },
  {
    label: i18next.t('distributed.automatically.text'),
    value: 'distributedAutomatic',
  },
  {
    label: i18next.t('distributed.manually.text'),
    value: 'distributedManual',
  },
  {
    label: i18next.t('accepted.text'),
    value: 'accepted',
  },
  {
    label: i18next.t('rescheduling'),
    value: 'rescheduling',
  },
  {
    label: i18next.t('refused.text'),
    value: 'refused',
  },
  {
    label: i18next.t('expired.text'),
    value: 'expired',
  },
  {
    label: i18next.t('not.conformity.text'),
    value: 'notConform',
  }
]

export const senseName = {
  asc: 'ascending',
  desc: 'descending',
}

export function handleResolveClusters(clusters: Clusters[], clusterOpts: any) {
  const options = (clusters || []).map((cluster) => ({
    value: cluster.coreCd,
    label: cluster.name,
    stores: getStoresByClusterId(clusterOpts, cluster.coreCd) || [],
  }))

  return options
}

export function fillClustersByStores(stores: Store[], clusterOpts: any) {
  const cluster: any[] = []

  stores.forEach((store) => {
    cluster.push(...store.clusters)
  })

  const solvedClusters = handleResolveClusters(cluster, clusterOpts)

  const solvedClustersWithoutDuplicates = getUniqueListBy(solvedClusters, 'value')

  const allStoresSelected = clusterStoreAllSelected(
    solvedClustersWithoutDuplicates,
    stores,
    clusterOpts,
  )

  return allStoresSelected
}

export function handleResolveStores(newStores: Store[]) {
  const options = (newStores || []).map((store) => ({
    value: store.code,
    label: handleResolveStoreLabel(store.name),
    name: store.name,
  }))

  return options
}

export function fillStoresByCluster(selectedClusters: Clusters[]) {
  const newStores: Store[] = []

  selectedClusters.forEach((cluster) => {
    newStores.push(...cluster.stores)
  })

  const solvedStores = handleResolveStores(newStores)

  return solvedStores as any[]
}

function getQuantityNotNull(value: number, isDecimal?: boolean) {
  if (isDecimal) return value || '0,0'
  return value || 0
}

function calculateDistributedTotalValue(item: any) {
  const total = item.distributedAutomatic + item.distributedManual
  return total || 0
}

export function parseRespose(items: any[]) {
  const solvedResults = []

  for (const item of items) {
    solvedResults.push({
      servcPrvdrId: item.providerId,
      commercialName: item.commercialName,
      federalNumber: formatCnpj(item.federalNumber),
      registerDate: new Date(item.registerDate),
      ratingServices: getQuantityNotNull(item.ratingServices, true),
      ratingInstallers: getQuantityNotNull(item.ratingInstallers, true),
      done: getQuantityNotNull(item.done),
      distributedTotal: calculateDistributedTotalValue(item),
      distributedAutomatic: getQuantityNotNull(item.distributedAutomatic),
      distributedManual: getQuantityNotNull(item.distributedManual),
      accepted: getQuantityNotNull(item.accepted),
      rescheduling: getQuantityNotNull(item.rescheduling),
      expired: getQuantityNotNull(item.expired),
      notConform: getQuantityNotNull(item.notConform),
      refused: getQuantityNotNull(item.refused),
      orderReceipt: item.isActive === '1' ? i18next.t('active') : i18next.t('inactive'),
      receivingOS: item.receivingOS === '1' ? i18next.t('active') : i18next.t('inactive'),
    })
  }

  return solvedResults
}

export function getIdValuesClusters(clusters: any[]) {
  const stores: Store[] = []

  clusters.forEach((cluster) => {
    stores.push(...cluster.stores)
  })

  const storesByClusters = getIdValues(stores, 'code')

  return storesByClusters
}
