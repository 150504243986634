import { useState } from 'react'
import i18next from 'i18next'

import User from '../../../../../../../core/User'
import CustomCard from '../../../../../../../components/Card'

import ObservationsModal from './components/Modal'

import ErrorFallback from '../../../../../../../errors/fallbacks'
import ErrorBoundary from '../../../../../../../errors/boundaries'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { WithContext } from './context'

function Observations({ observation, loadingObs, reloadServiceOrder }: any) {
  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const [showModal, setShowModal] = useState<boolean>(false)

  const handleAction = accessFunctionList?.includes(91)
    ? () => {
        setShowModal(true)
      }
    : null

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetErrorBoundary={() => reloadServiceOrder()}
      >
        <div id='observations-container'>
          <CustomCard
            styled
            onClickEditButton={handleAction}
            title={i18next.t('serviceOrders.observations')}
            iconNode={<MozaicIcon icon='PublishEdit24' />}
          >
            {loadingObs ? (
              <span className='observation-text shimmer-effect' />
            ) : (
              <span className='observation-text'>{observation || ''}</span>
            )}
            {!loadingObs && observation === '' && (
              <span className='no-has-observations'>{i18next.t('no.has.observations.text')}</span>
            )}
          </CustomCard>
        </div>
      </ErrorBoundary>

      {showModal && (
        <ObservationsModal
          isOpen={showModal}
          onClose={handleCloseModal}
          onSubmit={handleCloseModal}
        />
      )}
    </>
  )
}

export default WithContext(Observations)
