import { useState } from 'react'
import i18next from 'i18next'
import User from '../../../../../../core/User'

import { useServiceOrderDetailContext } from '../../../../context'
import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import OutlineButton from '../../../../../../components/OutlineButton'
import ConfirmOSModal from './components/ConfirmOSModal'

function ConfirmOS() {
  const { serviceOrderBody } = useServiceOrderDetailContext()
  const [show, setShow] = useState<boolean>(false)

  const user = new User().currentUser
  const accessFunctionList: number[] = user.accessFunctionList

  const openConfirmModal = () => {
    setShow(true)
  }

  const closeConfirmModal = () => {
    setShow(false)
  }

  const isAssociated =
    serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_ASSOCIADA.id

  const hasPermission04 = accessFunctionList?.includes(4)

  if (!isAssociated) return <></>

  return (
    <div className='confirm-os'>
      {hasPermission04 && (
        <>
          <OutlineButton label={i18next.t('serviceOrders.confirm')} onClick={openConfirmModal} />
          <ConfirmOSModal handleClose={closeConfirmModal} show={show} />
        </>
      )}
    </div>
  )
}

export default ConfirmOS
