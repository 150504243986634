import i18next from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { GCloudFileRef } from '../../services/firestore/gCloudStorage/GCloudFileRef'
import { GCloudImageRef } from '../../services/firestore/gCloudStorage/GCloudImageRef'
import MozaicIcon from '../MozaicIcon'
import { FileControls } from './components/FileControls'
import ViewIMG from './components/ViewIMG'
import ViewPDF from './components/ViewPDF'

import IconClose from '../../assets/icons/close.svg?react'

export interface GCloudFileViewProps {
  files: Array<GCloudFileRef | GCloudImageRef>
  fileIndex: number
  handleClose: () => void
  canDowload?: boolean
  canDelete?: boolean
  onDelete?: (file: GCloudFileRef | GCloudImageRef) => void
}

export default function GCloudFileView({
  files,
  fileIndex,
  handleClose,
  canDowload = true,
  canDelete = true,
  onDelete,
}: GCloudFileViewProps) {
  const [index, setIndex] = useState(fileIndex)

  const file = files[index]
  const isImg = file instanceof GCloudImageRef

  useEffect(() => {
    setIndex(fileIndex)
  }, [fileIndex])

  const { createdAt, user, title } = useMemo(() => file.getFileInfo(), [file.attachment])

  const { lastIndex, firstIndex, hasMultipleFiles } = useMemo(
    () => ({
      firstIndex: 0,
      lastIndex: files.length - 1,
      hasMultipleFiles: files.length > 1,
    }),
    [files],
  )

  const nextFile = () => {
    const newIndex = index === lastIndex ? firstIndex : index + 1
    setIndex(newIndex)
  }

  const prevFile = () => {
    const newIndex = index === firstIndex ? lastIndex : index - 1
    setIndex(newIndex)
  }

  const handleArrowKeys = (e: any) => {
    const actions = {
      ArrowLeft: prevFile,
      ArrowRight: nextFile,
    }

    actions[e.key as keyof typeof actions]()
  }

  useEffect(() => {
    window.addEventListener('keyup', handleArrowKeys)
    return () => window.removeEventListener('keyup', handleArrowKeys)
  }, [index])

  return (
    <div className='light-box-v2'>
      <div className='backdrop' onClick={handleClose} />

      {hasMultipleFiles && <FileControls prevFile={prevFile} nextFile={nextFile} />}

      <div className='container'>
        <div className='item-header'>
          <button className='close-button' onClick={handleClose}>
            <span>{i18next.t('serviceOrder.attachments.lightBox.close')}</span>
            <IconClose width={12} height={12} />
          </button>
        </div>

        {isImg ? <ViewIMG file={file} /> : <ViewPDF file={file} />}

        <div className='item-footer'>
          <div className='description'>
            <span>{title}</span>
            <span>{createdAt}</span>
            <span>{user}</span>
          </div>

          <div className='actions'>
            {canDelete && (
              <MozaicIcon
                size={28}
                color='white'
                icon='PublishTrashbin32'
                onClick={() => onDelete?.(file)}
              />
            )}

            {canDowload && (
              <MozaicIcon
                mt={4}
                size={30}
                color='white'
                icon='DownloadApp48'
                onClick={() => file.saveAs()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
