import i18next from 'i18next'

import FormField from '../../../../../../../../../../../components/FormField'
import { unitValueText } from '../../../../../../../../../../../helpers/format'
import { useServiceOrderDetailContext } from '../../../../../../../../../context'

export type ResaleProd = {
  servcResaleProdCd: string
  servcResaleProdFullDesc: string
  servcResaleProdQty: number
  servcOrdItemQtyReturned: number
  servcOrdItemQtyAcquired: number
}

type AssociatedProductType = {
  service: ResaleProd
  isCanceled: boolean
  isDesktop: boolean
}

function AssociatedProduct(props: AssociatedProductType) {
  const { loadingBody, loadingDetails } = useServiceOrderDetailContext()
  const { service, isCanceled, isDesktop } = props

  if (!service.servcResaleProdCd) return <></>

  const isFetching = loadingBody && loadingDetails

  const wasRefunded =
    service.servcOrdItemQtyAcquired > 0 &&
    service.servcOrdItemQtyAcquired === service.servcOrdItemQtyReturned

  const productQuantityLabel = isDesktop ? '' : i18next.t('service.order.detatails.quantity')

  return (
    <div id='associated-product-item-container'>
      <div className='first-column custom'>
        <div className='first-area mobile'>
          <FormField
            isFetching={isFetching}
            label={i18next.t('serviceOrderDetail.productTitle')}
            value={service.servcResaleProdFullDesc || '-'}
            isBold
            isDashed={isCanceled || wasRefunded}
          />

          <div className='custom-desk'>
            <FormField
              isFetching={isFetching}
              label={i18next.t('service.order.detatails.product.code')}
              value={service.servcResaleProdCd || '-'}
              isDashed={isCanceled || wasRefunded}
            />
          </div>
        </div>
        <section className='second-area mobile'>
          <div className='information-item custom-mobile'>
            <div className='subdivision'>
              <FormField
                isFetching={isFetching}
                label={i18next.t('service.order.detatails.product.code')}
                value={service.servcResaleProdCd || '-'}
                isDashed={isCanceled || wasRefunded}
              />
            </div>
          </div>
          <div className='information-item'>
            <FormField
              isFetching={isFetching}
              label={productQuantityLabel}
              value={unitValueText(service.servcResaleProdQty) || '-'}
              isDashed={isCanceled || wasRefunded}
            />
          </div>
        </section>
      </div>
      <div className='second-column' />
    </div>
  )
}

export default AssociatedProduct
