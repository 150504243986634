import { Component } from 'react'
import PropTypes from 'prop-types'

import Row from '../../../components/utils/Row'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import InputFile from '../../../components/utils/InputFile'
import FeedbackError from '../../../components/utils/FeedbackError'
import DocumentTypeSelect from '../../../components/utils/DocumentTypeSelect'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import { fileToBase64WithBuffer } from '../../../helpers/Toolbox'

class ServicePersonFormDocument extends Component {
  state = {
    documentTypes: [],
    error: null,
  }

  acceptableFiles = ['.cvs', '.pdf', '.png', '.jpg', '.xlsx', '.jpeg', '.xls']

  componentDidMount() {
    const {
      auth: { user },
      dispatch,
    } = this.props

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_DOCUMENT_TYPES_LIST)
      .then((response) => this.setState({ documentTypes: response.data }))
  }

  getSideText = () => {
    const {
      values: { files },
      inputRef,
    } = this.props

    if (inputRef && inputRef.current && !inputRef.current.value && files && files.length) {
      return files[0].name
    }

    return ''
  }

  submitForm = (event) => {
    event.preventDefault()

    const { values, onSubmit, personId, resetForm } = this.props
    const { type, files } = values

    if (files && files.length && type) {
      let descType = ''

      // Checks if it's from new person
      if (!values.personId) {
        const { documentTypes } = this.state

        if (documentTypes && documentTypes.length) {
          const typeInt = parseInt(type, 10)
          const documentType = documentTypes.find(
            (documentType) => documentType.atchmtDocmntTyp === typeInt,
          )
          descType = documentType ? documentType.atchmtDocmntTypDesc : ''
        }
      }

      const params = {
        [personId]: values.personId,
        files,
        atchmtDocmntTyp: type,
        descType,
      }

      onSubmit(params)
      resetForm()
    }
  }

  handleFilesChange = async (event) => {
    const { setValueToField } = this.props
    const inputFiles = event.target.files
    let files = []

    this.setState({ error: null })

    if (inputFiles && inputFiles.length) {
      const filesPromises = Array.from(inputFiles).map(async (inputFile) => {
        const result = await fileToBase64WithBuffer(inputFile)

        return {
          name: inputFile.name,
          file: result,
        }
      })

      files = await Promise.all(filesPromises)
    }

    setValueToField('files', files)
  }

  canSubmit = () => {
    const {
      values: { files, type },
    } = this.props
    return files && files.length && type
  }

  renderNotification = (error) => {
    const textMessage = !error?.fields ? '' : error?.fields[0]?.dsDetail

    return <FeedbackError message={error?.message?.dsMessage} text={textMessage} />
  }

  renderSupportedTypes = () => {
    const { t } = this.props
    const { error } = this.state

    if (error) {
      return <h6 className='no-margin color-danger'>{t(error)}</h6>
    }
    return <h6 className='no-margin color-default'>{t('UploadDocumentsTypeSupported')}</h6>
  }

  setErrors = (error) => {
    this.setState({ error })
  }

  render() {
    const { t, fields, closeModal, error, disableFileSelect, inputRef } = this.props
    const { documentTypes } = this.state

    return (
      <form onSubmit={this.submitForm}>
        <Row className='bg-shaded margin-top-double padding align-items-center'>
          <Col xs={6}>
            <span>{t('option.addDocument')}</span>

            <InputFile
              {...fields.files.input}
              className='margin-vertical-small'
              t={t}
              inputRef={inputRef}
              disabled={disableFileSelect}
              sideText={this.getSideText()}
              onChange={this.handleFilesChange}
              accept={this.acceptableFiles}
              setErrors={this.setErrors}
            />

            {error ? this.renderNotification(error) : this.renderSupportedTypes()}
          </Col>

          <Col xs={6}>
            <Field {...fields.type}>
              <DocumentTypeSelect
                {...fields.type.input}
                className='transparent label-primary-dark'
                data={documentTypes}
                controlled
              />
            </Field>
          </Col>
        </Row>

        <Row className='padding-horizontal padding-vertical-double'>
          <Col lgOffset={5} mdOffset={4} smOffset={2} xs={4} className='text-align-right'>
            <button
              type='button'
              className='button color-danger button-text-only'
              onClick={closeModal}
            >
              {t('cancel_button_label')}
            </button>
          </Col>

          <Col lg={3} md={4} sm={6} xs={8}>
            <button
              type='submit'
              className='button button-primary button-full'
              disabled={!this.canSubmit()}
            >
              {t('option.sendFiles')}
            </button>
          </Col>
        </Row>
      </form>
    )
  }
}

ServicePersonFormDocument.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  setValueToField: PropTypes.func.isRequired,
  disableFileSelect: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  personId: PropTypes.string.isRequired,
  inputRef: PropTypes.object,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default ServicePersonFormDocument
