import { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import blacklist from 'blacklist'

class InputSwitch extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  static defaultProps = {
    checked: false,
  }

  handleClick() {
    const { onChange, checked, disabled = false } = this.props
    if (!disabled) onChange(!checked)
  }

  render() {
    const { checked, className, name, id, disabled = false } = this.props

    const extraProps = blacklist(this.props, 'className', 'checked', 'onChange', 'name')

    const classNameParsed = cx(
      'u-switch',
      {
        'is-checked': checked,
        'is-disabled': disabled,
      },
      className,
    )

    return (
      <label className={classNameParsed} onClick={this.handleClick} {...extraProps}>
        <input
          type='hidden'
          name={name}
          value={checked ? 'yes' : 'no'}
          id={id}
          disabled={disabled}
        />
        <span className='track' />
        <span className='inputSButton' />
      </label>
    )
  }
}

InputSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
}

export default InputSwitch
