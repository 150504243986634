import { Component } from 'react'
import PropTypes from 'prop-types'

import Row from '../../../../components/utils/Row'
import Col from '../../../../components/utils/Col'
import InputFile from '../../../../components/utils/InputFile'
import FeedbackError from '../../../../components/utils/FeedbackError'

import { ATTACHMENT_CLASSIFICATION } from '../../../../constants/config'
import { fileToBase64WithBuffer } from '../../../../helpers/Toolbox'

class BudgetsForm extends Component {
  acceptableFiles = ['.pdf']

  getSideText = () => {
    const {
      values: { files },
      inputRef,
      relationId,
      t,
    } = this.props

    if (inputRef && inputRef.current && !inputRef.current.value && files && files.length) {
      let preName = null
      if (relationId === ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER) {
        preName = t('budgets.app.details.file.name.client')
      } else {
        preName = t('budgets.app.details.file.name.installer')
      }
      return preName + files[0].name
    }

    return ''
  }

  submitForm = (event) => {
    event.preventDefault()

    const { values, onSubmit, resetForm } = this.props
    const { files } = values

    if (files && files.length) {
      const params = {
        files,
      }

      onSubmit(params)
      resetForm()
    }
  }

  handleFilesChange = async (event) => {
    const { setFormValue, relationId, t } = this.props
    const inputFiles = event.target.files
    let files = []

    if (inputFiles && inputFiles.length) {
      const filesPromises = Array.from(inputFiles).map(async (inputFile) => {
        const result = await fileToBase64WithBuffer(inputFile)
        let preName = null
        if (relationId === ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER) {
          preName = t('budgets.app.details.file.name.client')
        } else {
          preName = t('budgets.app.details.file.name.installer')
        }
        return {
          name: preName + inputFile.name,
          file: result,
          fileIndicador: relationId,
        }
      })

      files = await Promise.all(filesPromises)
    }

    setFormValue({ files })
  }

  canSubmit = () => {
    const {
      values: { files },
    } = this.props
    return files && files.length
  }

  renderNotification = (error) => {
    if (error.message !== undefined) {
      const { t } = this.props
      return <FeedbackError message={t(error.message.dsMessage)} />
    }
  }

  renderSupportedTypes = () => {
    const { t } = this.props

    return (
      <h6 className='no-margin color-default'>
        {t('servc.ord.budget.component.title.type.accepted')}
      </h6>
    )
  }

  setErrors = (error) => {
    this.setState({ error })
  }

  render() {
    const { t, register, closeModal, error, inputRef } = this.props

    return (
      <form onSubmit={this.submitForm}>
        <Row className='bg-shaded margin-top-double padding align-items-center'>
          <Col xs={6}>
            <span>{t('option.addDocument')}</span>
            <InputFile
              {...register('files', { required: true, parser: (e) => e })}
              className='margin-vertical-small'
              t={t}
              inputRef={inputRef}
              sideText={this.getSideText()}
              onChange={this.handleFilesChange}
              accept={this.acceptableFiles}
              setErrors={this.setErrors}
            />

            {error ? this.renderNotification(error) : this.renderSupportedTypes()}
          </Col>
        </Row>

        <Row className='padding-horizontal padding-vertical-double'>
          <Col lgOffset={5} mdOffset={4} smOffset={2} xs={4} className='text-align-right'>
            <button
              type='button'
              className='button color-danger button-text-only'
              onClick={closeModal}
            >
              {t('cancel_button_label')}
            </button>
          </Col>

          <Col lg={3} md={4} sm={6} xs={8}>
            <button
              type='submit'
              className='button button-primary button-full'
              disabled={!this.canSubmit()}
            >
              {t('option.sendFiles')}
            </button>
          </Col>
        </Row>
      </form>
    )
  }
}

BudgetsForm.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  register: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  translationNumber: PropTypes.number,
  inputRef: PropTypes.object,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  relationId: PropTypes.number,
}

export default BudgetsForm
