import { useEffect, useState } from 'react'
import { Document as PdfDocument, Page, pdfjs } from 'react-pdf'

import OutlineButton from '../../../OutlineButton'
import MozaicIcon from '../../../MozaicIcon'
import { GCloudFileRef } from '../../../../services/firestore/gCloudStorage/GCloudFileRef'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface GCloudFilePreviewProps {
  file: GCloudFileRef
}

export default function ViewPDF({ file }: GCloudFilePreviewProps) {
  const [url, setUrl] = useState('')

  const [pdfNumOfPages, setPdfNumOfPages] = useState(0)
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1)

  const previousPdfPage = () => setPdfCurrentPage((i) => (i <= 1 ? pdfNumOfPages : i - 1))
  const nextPdfPage = () => setPdfCurrentPage((i) => (i >= pdfNumOfPages ? 1 : i + 1))

  useEffect(() => {
    file.getDownloadURL().then((cloudUrl) => setUrl(cloudUrl))
  }, [file])

  const handlePdfLoadSuccess = (numPages: number) => {
    setPdfNumOfPages(numPages)
    setPdfCurrentPage(1)
  }

  return (
    <div className='pdf'>
      <PdfDocument file={url} onLoadSuccess={({ numPages }) => handlePdfLoadSuccess(numPages)}>
        <Page pageNumber={pdfCurrentPage} className='pdf-page' />
      </PdfDocument>

      {pdfNumOfPages > 1 && (
        <div className='controls'>
          <OutlineButton
            classname='pdf-control-button prev-page-button'
            iconNode={<MozaicIcon icon='ArrowArrowLeft24' className='icon-arrow-custom' />}
            onClick={previousPdfPage}
          />
          <div className='page-info'>
            {pdfCurrentPage}/{pdfNumOfPages}
          </div>
          <OutlineButton
            classname='pdf-control-button next-page-button'
            iconNode={<MozaicIcon icon='ArrowArrowRight24' className='icon-arrow-custom' />}
            onClick={nextPdfPage}
          />
        </div>
      )}
    </div>
  )
}
