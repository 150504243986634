import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import history from '../../helpers/history'
import store from '../../redux/store'

import AlertsState from './handlers/alerts'
import { StorageAlertsType } from './models/alerts'

import ClientDataState from './handlers/clientData'
import { StorageClientDataType } from './models/clientData'

import DatesState from './handlers/dates'
import { StorageDatesType } from './models/dates'

import ToggleState from './handlers/toggle'

import ProviderState from './handlers/provider'
import { StorageProviderType } from './models/provider'

import SellOriginState from './handlers/sellOrigin'
import { StorageSellOriginType } from './models/sellOrigin'

import ServiceInformationState from './handlers/serviceInformation'
import { StorageServiceInformationType } from './models/serviceInformation'

import SortState from './handlers/sort'
import { StorageSortType } from './models/sort'

import {
  AdvancedSearchContextType,
  AdvancedSearchFilters,
  AdvancedSearchProviderType,
} from './models'

import queryString from 'query-string'
import { useLocation } from 'react-router'
import { QueryParamsType } from '../../models/Project'
import { RESET_PAGES } from '../../redux/actionTypes/pagination'
import { clearServiceOrderResponse } from '../../redux/actions/serviceOrder'

const filtersExceptions = [
  'orderBy',
  'descAsc',
  'elasticEnabled',
  'servcOrdType',
  'currentSearchFieldValue',
]

const storage = sessionStorage.getItem('advanced_search_filters')
const storageFilters = storage !== null ? (JSON.parse(storage) as AdvancedSearchFilters) : null
let storageRestoreTriggered = false

const AdvancedSearchContext = createContext<AdvancedSearchContextType | null>(null)

function AdvancedSearchProvider(props: AdvancedSearchProviderType) {
  const [show, setShow] = useState<boolean>(false)
  const { children, currentRoute, saveQueryParams } = props

  const { search: queryArgs } = useLocation()

  const queryParams = useMemo(() => queryString.parse(queryArgs) as QueryParamsType, [queryArgs])

  const [hasFilters, setHasFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<AdvancedSearchFilters>(null)

  const toggleState = ToggleState()
  const alertsState = AlertsState(filters as StorageAlertsType)
  const clientDataState = ClientDataState(filters as StorageClientDataType)
  const datesState = DatesState(filters as StorageDatesType)
  const providerState = ProviderState(filters as StorageProviderType)
  const sellOriginState = SellOriginState(filters as StorageSellOriginType)
  const serviceInformationState = ServiceInformationState(filters as StorageServiceInformationType)
  const sortState = SortState(filters as StorageSortType)

  const handleOpen = () => setShow(true)
  const handleClose = () => setShow(false)

  const handleClear = () => {
    const { clearState: clearAlerts } = alertsState
    const { clearState: clearClientData } = clientDataState
    const { clearState: clearDates } = datesState
    const { clearState: clearProviders } = providerState
    const { clearState: clearSellOrigins } = sellOriginState
    const { clearState: clearInformation } = serviceInformationState
    const { clearState: clearSort } = sortState

    clearAlerts()
    clearClientData()
    clearDates()
    clearProviders()
    clearSellOrigins()
    clearInformation()
    clearSort()

    store.dispatch(clearServiceOrderResponse())
    store.dispatch({ type: RESET_PAGES })

    setFilters(null)
    sessionStorage.removeItem('advanced_search_filters')

    saveQueryParams('/projects', null)
    saveQueryParams('/serviceOrder', null)
  }

  const handleRedirect = (newFilters: AdvancedSearchFilters) => {
    const q = newFilters?.currentSearchFieldValue
    const query = `q=${q}`

    if (currentRoute === '/projects') {
      history.push({
        pathname: '/projects',
        search: q ? query : undefined,
      })

      return saveQueryParams('/projects', query)
    }

    history.push({
      pathname: '/serviceOrder',
      search: q ? query : undefined,
    })

    return saveQueryParams('/serviceOrder', query)
  }

  const handleSubmitDefault = async (searchFilters = {}) => {
    const { getDefaultState: getDefaultAlerts } = alertsState
    const { getDefaultState: getDefaultClientData } = clientDataState
    const { getDefaultState: getDefaultDates } = datesState
    const { getDefaultState: getDefaultProviders } = providerState
    const { getDefaultState: getDefaultSellOrigins } = sellOriginState
    const { getDefaultState: getDefaultInformation } = serviceInformationState
    const { getDefaultState: getDefaultSort } = sortState

    const newFilters = {
      ...getDefaultAlerts(),
      ...getDefaultClientData(),
      ...getDefaultDates(),
      ...getDefaultProviders(),
      ...getDefaultSellOrigins(),
      ...getDefaultInformation(),
      ...getDefaultSort(),
      serviceOrderHasAttachments: '',
      servcOrdStatusTypeStore: '',
      servcOrdStatusTypeHouse: '',
      servcOrdStatusTypeProvider: '',
      ...searchFilters,
    }

    store.dispatch(clearServiceOrderResponse())
    store.dispatch({ type: RESET_PAGES })

    setFilters(newFilters)
    handleRedirect(newFilters)
    sessionStorage.setItem('advanced_search_filters', JSON.stringify(newFilters))

    handleClose()
  }

  const mergeQueryStorage = (payload: { currentSearchFieldValue?: string }) => {
    const mergedPayload = { ...payload }

    if (queryParams.q) {
      mergedPayload.currentSearchFieldValue = queryParams.q
    }

    return mergedPayload
  }

  const submitQueryAndStorageParams = () => {
    if (storageFilters) {
      const payload = mergeQueryStorage(storageFilters) as AdvancedSearchFilters

      setFilters(payload)
    } else {
      const payload = mergeQueryStorage({})

      if (Object.keys(payload).length) handleSubmitDefault(payload)
    }

    storageRestoreTriggered = true
  }

  useEffect(() => {
    if (currentRoute) {
      const restoreFilters =
        (currentRoute.includes('serviceOrder') && currentRoute !== '/serviceOrder/create') ||
        currentRoute.includes('projects')

      if (queryParams.q && !storageRestoreTriggered) {
        clientDataState.setSearch(queryParams.q)
      }

      if (restoreFilters && !storageRestoreTriggered) {
        submitQueryAndStorageParams()
      }

      if (!restoreFilters) {
        sessionStorage.removeItem('advanced_search_filters')
        handleClear()
      }

      storageRestoreTriggered = true
    }
  }, [currentRoute])

  useEffect(() => {
    const check = filters
      ? Object.keys(filters).some((filter) => {
          const value = filters[filter as keyof typeof filters] as any

          if (filtersExceptions.includes(filter)) return false
          if (typeof value === typeof []) return value.length

          return value
        })
      : false

    setHasFilters(check)
  }, [filters])

  const handleSubmit = async () => {
    const { getCurrentState: getCurrentAlerts } = alertsState
    const { getCurrentState: getCurrentClientData } = clientDataState
    const { getCurrentState: getCurrentDates } = datesState
    const { getCurrentState: getCurrentProviders } = providerState
    const { getCurrentState: getCurrentSellOrigins } = sellOriginState
    const { getCurrentState: getCurrentInformation } = serviceInformationState
    const { getCurrentState: getCurrentSort } = sortState

    const newFilters = {
      ...getCurrentAlerts(),
      ...getCurrentClientData(),
      ...getCurrentDates(),
      ...getCurrentProviders(),
      ...getCurrentSellOrigins(),
      ...getCurrentInformation(),
      ...getCurrentSort(),
      servcOrdStatusTypeStore: '',
      servcOrdStatusTypeHouse: '',
      servcOrdStatusTypeProvider: '',
    }

    store.dispatch(clearServiceOrderResponse())
    store.dispatch({ type: RESET_PAGES })

    setFilters(newFilters)
    handleRedirect(newFilters)
    sessionStorage.setItem('advanced_search_filters', JSON.stringify(newFilters))

    handleClose()
  }

  const result = useMemo(
    () => ({
      show,
      handleOpen,
      handleClose,
      handleSubmit,
      handleSubmitDefault,
      handleClear,
      saveQueryParams,
      alertsState,
      toggleState,
      clientDataState,
      datesState,
      providerState,
      sellOriginState,
      serviceInformationState,
      sortState,
      filters,
      currentRoute,
      hasFilters,
    }),
    [
      show,
      alertsState,
      toggleState,
      clientDataState,
      datesState,
      providerState,
      sellOriginState,
      serviceInformationState,
      sortState,
      filters,
      currentRoute,
      hasFilters,
      handleSubmit,
      handleSubmitDefault,
    ],
  )

  return <AdvancedSearchContext.Provider value={result}>{children}</AdvancedSearchContext.Provider>
}

const useAdvancedSearchContext = () =>
  useContext(AdvancedSearchContext) as AdvancedSearchContextType

export { AdvancedSearchContext, AdvancedSearchProvider, useAdvancedSearchContext }
