import i18next from 'i18next'
import PropTypes from 'prop-types'
import Col from '../../../components/utils/Col'
import Row from '../../../components/utils/Row'
import { BUDGETS } from '../../../constants/config'
import ServcOrdCategoryType from '../../../enums/ServiceOrder/category/serviceCategoryType'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'

import './AlertsBudgets.scss'

function AlertsBudgets({ list, findWithFilter }) {
  const clickOnBox = (index) => {
    let lsStatus
    let filters

    switch (index) {
      case BUDGETS.ALL_COCKPIT:
        lsStatus = [
          {
            value: ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
            label: i18next.t('serviceOrders.preScheduled'),
          },
          {
            value: ServiceOrderStatusType.STATUS_AGENDADA.id,
            label: i18next.t('serviceOrders.scheduled'),
          },
          {
            value: ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
            label: i18next.t('serviceOrders.distributed'),
          },
          {
            value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
            label: i18next.t('serviceOrders.associated'),
          },
          {
            value: ServiceOrderStatusType.STATUS_CONFIRMADA.id,
            label: i18next.t('serviceOrders.confirmed'),
          },
          {
            value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
            label: i18next.t('serviceOrders.doing'),
          },
          {
            value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
            label: i18next.t('serviceOrders.done'),
          },
        ]

        const budgetCategory = {
          value: ServcOrdCategoryType.BUDGET.id,
          label: i18next.t(ServcOrdCategoryType.BUDGET.value),
        }

        filters = {
          lsStatus,
          lsServcOrdStusCd: lsStatus.map((status) => status.value),
          lsServcOrdCategory: [budgetCategory],
          lsServcOrdCategoryCd: [budgetCategory].map((category) => category.value),
          allBudget: true,
        }

        return findWithFilter(filters)
      case BUDGETS.RECEPTED:
        lsStatus = [
          {
            value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
            label: i18next.t('serviceOrders.done'),
          },
          {
            value: ServiceOrderStatusType.LOJA_REALIZADO.id,
            label: i18next.t('serviceOrders.branch.done'),
          },
        ]

        filters = {
          lsStatus,
          lsServcOrdStusCd: lsStatus.map((status) => status.value),
          budgetReceipt: true,
        }

        return findWithFilter(filters)
      case BUDGETS.LACKING:
        lsStatus = [
          {
            value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
            label: i18next.t('serviceOrders.done'),
          },
          {
            value: ServiceOrderStatusType.LOJA_REALIZADO.id,
            label: i18next.t('serviceOrders.branch.done'),
          },
        ]

        filters = {
          lsStatus,
          lsServcOrdStusCd: lsStatus.map((status) => status.value),
          sosPendingBudget: true,
        }

        return findWithFilter(filters)
      case BUDGETS.FIRTS_WITHOUT_ANSWER:
        lsStatus = [
          {
            value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
            label: i18next.t('serviceOrders.done'),
          },
        ]

        filters = {
          lsStatus,
          lsServcOrdStusCd: lsStatus.map((status) => status.value),
          budgetDashboardFirstDate: true,
        }

        return findWithFilter(filters)
      case BUDGETS.SECOND_WITHOUT_ANSWER:
        lsStatus = [
          {
            value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
            label: i18next.t('serviceOrders.done'),
          },
        ]

        filters = {
          lsStatus,
          lsServcOrdStusCd: lsStatus.map((status) => status.value),
          budgetDashboardSecondDate: true,
        }

        return findWithFilter(filters)
    }
  }

  if (!list.length) return <></>

  return (
    <Col xm={12} md={6} className='body-budgets-alerts'>
      <Row className='body-budgets-alerts-container'>
        <Col md={12} sm={12}>
          <div className='title'>{i18next.t(`budgets.alerts.title.1`)}</div>
        </Col>
        <Row className='budgets-container'>
          <Col md={12} sm={12} className='container-box'>
            <div onClick={() => clickOnBox(BUDGETS.ALL_COCKPIT)} id='box-budgets-1'>
              <div className='box-title color-green'>{i18next.t(list[0].nameValeuDashboard)}</div>
              <div className='box-value color-green'>{list[0].valueDashboard}</div>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className='body-budgets-alerts-container'>
        <Col md={12} sm={12}>
          <p className='title'>{i18next.t(`budgets.alerts.title.2`)}</p>
        </Col>
        <Row className='budgets-container'>
          <Col md={6} sm={12} className='container-box'>
            <div onClick={() => clickOnBox(BUDGETS.RECEPTED)} id='box-budgets-2'>
              <div className='box-title color-green'>{i18next.t(list[1].nameValeuDashboard)}</div>
              <div className='box-value color-green'>{list[1].valueDashboard}</div>
            </div>
          </Col>
          <Col md={6} sm={12} className='container-box'>
            <div onClick={() => clickOnBox(BUDGETS.LACKING)} id='box-budgets-3'>
              <div className='box-title color-red'>{i18next.t(list[2].nameValeuDashboard)}</div>
              <div className='box-value color-red'>{list[2].valueDashboard}</div>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className='body-budgets-alerts-container'>
        <Col md={12} sm={12}>
          <p className='title'>{i18next.t(`budgets.alerts.title.3`)}</p>
        </Col>
        <Row className='budgets-container'>
          <Col md={6} sm={12} className='container-box'>
            <div onClick={() => clickOnBox(BUDGETS.FIRTS_WITHOUT_ANSWER)} id='box-budgets-4'>
              <div className='box-title color-red'>{i18next.t(list[3].nameValeuDashboard)}</div>
              <div className='box-value color-red'>{list[3].valueDashboard}</div>
            </div>
          </Col>
          <Col md={6} sm={12} className='container-box'>
            <div onClick={() => clickOnBox(BUDGETS.SECOND_WITHOUT_ANSWER)} id='box-budgets-5'>
              <div className='box-title color-red'>{i18next.t(list[4].nameValeuDashboard)}</div>
              <div className='box-value color-red'>{list[4].valueDashboard}</div>
            </div>
          </Col>
        </Row>
      </Row>
    </Col>
  )
}

AlertsBudgets.propTypes = {
  list: PropTypes.object.isRequired,
  findWithFilter: PropTypes.func.isRequired,
}

export default AlertsBudgets
