import { clusterStoreAllSelected, handleResolveStoreLabel } from '../../../../services/Stores'
import { getUniqueListBy } from '../../../../helpers/array'

const handleResolveClusters = (clusters, clustersOptions) => {
  const options = (clusters || []).map((cluster) => ({
    value: cluster.coreCd,
    label: cluster.name,
    stores: clustersOptions.find((option) => option.value === cluster.coreCd).stores,
  }))

  return options
}

export const getClustersByStores = (stores, clustersOptions) => {
  const clusters = []

  stores.forEach((store) => {
    clusters.push(...store.clusters)
  })

  const solvedClusters = handleResolveClusters(clusters, clustersOptions)

  const solvedClustersWithoutDuplicates = getUniqueListBy(solvedClusters, 'value')

  const allStoresSelected = clusterStoreAllSelected(
    solvedClustersWithoutDuplicates,
    stores,
    clustersOptions,
  )

  return allStoresSelected
}

const handleResolveStores = (stores, storesOptions) => {
  const options = stores.map((store) => ({
    value: store.code,
    label: handleResolveStoreLabel(store.name),
    name: store.name,
    clusters: storesOptions.find((option) => option.value === store.code).clusters,
  }))

  return options
}

export const getStoresByClusters = (clusters, storesOptions) => {
  const stores = []

  clusters.forEach((cluster) => {
    stores.push(...cluster.stores)
  })

  const solvedStores = handleResolveStores(stores, storesOptions)

  return solvedStores
}

export const clustersToStores = (currentFilter) => {
  const clustersStores = []
  currentFilter.clusters.forEach((cluster) =>
    cluster.stores.forEach((store) => clustersStores.push(store.code)),
  )

  return clustersStores
}
