import i18next from 'i18next'
import { ServiceImageType } from '../../../helpers/serviceOrderUtils'

import FileSaver from 'file-saver'
import firestoreAdapter from '../firebase'
import { AttachmentV2, FileMetadata, FileRefType } from './models'

const attachmentImageType = {
  [ServiceImageType.START]: i18next.t('imageSubtitles.label.serviceStart'),
  [ServiceImageType.END]: i18next.t('imageSubtitles.label.serviceEnd'),
  [ServiceImageType.NOT_CONFORM]: i18next.t('imageSubtitles.label.serviceCompletion.nonCompliant'),
  [ServiceImageType.REPORT]: i18next.t('imageSubtitles.label.report'),
}
export class GCloudFileRef {
  ref: FileRefType

  metadata: FileMetadata | null = null

  attachment: AttachmentV2

  downloadUrl: string

  constructor(attachment: AttachmentV2) {
    this.attachment = attachment
    this.ref = firestoreAdapter.storage.ref(attachment.atchmtCloudShortPathTxt)
    this.downloadUrl = ''
  }

  getFileInfo = () => {
    const photo = this.attachment

    return {
      createdAt: photo.creatTs,
      user: photo.lastUpdtUserCd
        ? `${i18next.t('serviceOrder.attachments.photos.user')}: ${photo.lastUpdtUserCd}`
        : '',
      title:
        (photo.servcOrdItemAtchmtClsfctnCd &&
          attachmentImageType[photo.servcOrdItemAtchmtClsfctnCd]) ||
        photo.atchmtDocmntTypDesc ||
        photo.servcOrdItemAtchmtDesc,
      service: `${i18next.t('conformity.osData.installation.service.name')} ${photo.servcOrdSeq}`,
    }
  }

  getMetadata = async () => {
    if (this.metadata) return this.metadata

    this.metadata = await this.ref.getMetadata()

    return this.metadata
  }

  getFile = async () => {
    const url = await this.getDownloadURL()

    const res = await fetch(url)
    const blob = await res.blob()

    return blob
  }

  getDownloadURL = async () => {
    if (this.downloadUrl) return this.downloadUrl

    const url = await this.ref.getDownloadURL()

    this.downloadUrl = url

    return url
  }

  saveAs = async () => {
    const pBlob = this.getFile()
    const pMetadata = this.getMetadata()

    const [blob, metadata] = await Promise.all([pBlob, pMetadata])

    FileSaver.saveAs(blob, metadata.name)

    return { file: blob, metadata }
  }
}
