import { ReactNode, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { uniqueId } from 'lodash'
import MozaicIcon from '../MozaicIcon'

type MozaicAccordeonProps = {
  children: ReactNode
  rIcon?: ReactNode
  group?: string | null
  scroll?: boolean
  header?: ReactNode | null
  onOpen?: Function | undefined
  onClose?: Function | undefined
  onToggle?: Function | undefined
  className?: string | null
  canCollapse?: boolean
  defaultOpen?: boolean
}

function MozaicAccordeon(props: MozaicAccordeonProps) {
  const {
    children,
    rIcon = null,
    group = null,
    header = null,
    scroll = true,
    className = null,
    canCollapse = true,
    defaultOpen = false,
    onOpen,
    onClose,
    onToggle,
  } = props

  const id = useMemo(() => uniqueId('accordeon'), [])
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const [inTransition, setInTransition] = useState(false)

  useEffect(() => {
    if (onToggle) onToggle(!isOpen)
    if (onOpen && isOpen) onOpen(!isOpen)
  }, [isOpen])

  const scrollEvent = () => {
    const el = document.getElementById(id)
    let opened = false

    if (group) {
      const accordeons = document.querySelectorAll(`[data-group=${group}]`)

      accordeons.forEach((accordeon: any) => {
        if (accordeon.id === id) return false

        if (accordeon.classList.contains('opened')) {
          const header = accordeon.querySelector('.header') as HTMLDivElement

          opened = true
          header.click()
        }
      })
    }

    if (!scroll) return false
    if (opened) {
      return window.scroll({
        top: (el?.scrollTop as number) + window.innerHeight / 2,
        behavior: 'smooth',
      })
    }

    return el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleToggle = () => {
    if (!canCollapse) return false

    setIsOpen(!isOpen)
    setInTransition(true)

    setTimeout(() => {
      setInTransition(false)

      if (!isOpen) scrollEvent()
      if (onClose && !isOpen) onClose(!isOpen)
    }, 250)
  }

  const classes: any = {
    opened: isOpen,
    collapsed: !isOpen,
    transition: inTransition,
    'can-collapse': canCollapse,
  }

  return (
    <div
      id={id}
      data-group={group}
      className={classNames(`mozaic-accordeon ${className}`, classes)}
    >
      {header && (
        <section className='header' onClick={handleToggle}>
          <div className='main'>{header}</div>

          {rIcon && <div>{rIcon}</div>}

          <div className='toggle-button'>
            <div className={classNames('show-details_svg', { rotate: isOpen })}>
              <MozaicIcon icon='ArrowArrowBottom16' size={20} />
            </div>
          </div>
        </section>
      )}

      <section className='body'>
        <div className='main'>{children}</div>
      </section>
    </div>
  )
}

export default MozaicAccordeon
