import PropTypes from 'prop-types'
import { memo } from 'react'
import { connect } from 'react-redux'
import MozaicText from '../../../../components/MozaicText'

/**
 * @description Badge to use on app bar of system
 */

function Badge({ auth }) {
  const { setupParameters } = auth

  if (!setupParameters.currentBase) {
    return <></>
  }

  return (
    <section id='badge-content'>
      <MozaicText theme='lightest' weight='semi-bold' size='xl' lineHeight='s'>
        {setupParameters.currentBase}
      </MozaicText>
    </section>
  )
}

Badge.propTypes = {
  auth: PropTypes.object.isRequired,
}

function mapStateToProps({ auth }) {
  return { auth }
}

export default memo(connect(mapStateToProps, {})(Badge))
