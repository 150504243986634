import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

export const backOrderToPreSchedule = (params: {
  servcOrdStusCd: string
  servcOrdSeq: number
  justificationBackToPreScheduled: string
}) => new API_REQUEST(user.dsToken, store.dispatch).put(API_URL.BACK_TO_RESCHEDULED, params)
