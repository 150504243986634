import { Flex } from '@mozaic-ds/react'
import i18next from 'i18next'
import Cache from 'i18next-localstorage-cache'
import { useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from '../components/utils/Spinner'
import ErrorBoundaryI18n from '../errors/boundaries/wrappers/i18nWrapper'
import fetchI18nKeys from '../redux/actions/i18n/fetchKeys'
import LanguageConfig from './Language'

const prefix = 'i18next_res_'
const language = new LanguageConfig(prefix)

interface I18nWrapperContainerProps {
  children: React.ReactNode
  fetchI18nKeys: (language: string) => Promise<any>
}

function I18nWrapperContainer({ children, fetchI18nKeys }: I18nWrapperContainerProps) {
  const [loaded, setLoaded] = useState(false)

  const init = async () => {
    const i18nKeys = await fetchI18nKeys(language.userLanguage)

    i18next.use(Cache).init({
      resources: {
        [language.userLanguage]: {
          translation: i18nKeys,
        },
      },
      lng: language.userLanguage,
      interpolation: {
        escapeValue: false,
      },
      lowerCaseLng: true,
      keySeparator: false,
      nsSeparator: false,
      cache: {
        enabled: true,
        prefix,
      },
    })

    setLoaded(true)
  }

  useEffect(() => {
    init()
  }, [])

  if (!loaded) {
    return (
      <Flex
        // @ts-ignore
        style={{ width: '100vw', height: '100vh' }}
        justifyContent='center'
        alignItems='center'
        direction='column'
        gap='mu150'
      >
        <Spinner visible />
      </Flex>
    )
  }

  return (
    <ErrorBoundaryI18n onReset={() => {}}>
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </ErrorBoundaryI18n>
  )
}

const mapStateToProps = ({ i18nReducer }) => ({
  i18nKeys: i18nReducer.keys,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchI18nKeys,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(I18nWrapperContainer)
