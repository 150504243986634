import ErrorBoundary from '../../../../../../../errors/boundaries'
import ErrorFallback from '../../../../../../../errors/fallbacks'

import Accordeon from '../../../../../../../components/Accordeon'

import TriggerWarranty from './components/TriggerWarranty'
import ActivatedWarranty from './components/ActivatedWarranty'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { ServiceOrderDetailStore } from '../../../../../context/models'
import { WithContext } from './context'

const accordeonHeader = {
  title: 'serviceOrders.warranty',
  icon: <MozaicIcon icon='PromiseWarranty24' className='icon-custom' />,
}

function Warranty({
  showWarrantyContent,
  warranty,
  loadingWarranty,
  reloadServiceOrder,
}: ServiceOrderDetailStore) {
  const activatedWarranty = warranty?.callDate !== null

  if (!showWarrantyContent) return <></>
  if (loadingWarranty) return <></>

  return (
    <div id='warranty-container'>
      <ErrorBoundary resetErrorBoundary={reloadServiceOrder} FallbackComponent={ErrorFallback}>
        <Accordeon header={accordeonHeader}>
          {activatedWarranty ? <ActivatedWarranty /> : <TriggerWarranty />}
        </Accordeon>
      </ErrorBoundary>
    </div>
  )
}

export default WithContext(Warranty)
