/* eslint-disable react/prop-types */
import { memo } from 'react'
import i18next from 'i18next'

import classNames from 'classnames'

import CounterContainer from '../Counter'
import Spacer from '../Spacer'

import EditButton from './components/EditButton'

import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import MozaicIcon from '../MozaicIcon'

function CustomCard(props) {
  const {
    children,
    title,
    counterValue,
    iconNode,
    fullScreen,
    spacerShow = true,
    onClickEditButton = null,
    styled,
  } = props
  const handle = useFullScreenHandle()

  function handleRenderFullScreenIcons() {
    if (handle.active) {
      return <MozaicIcon icon='ReduceScreen24' onClick={handle.exit} />
    }

    return <MozaicIcon icon='FullScreen24' onClick={handle.enter} />
  }

  const showSpacer = spacerShow && title && iconNode

  return (
    <div id='custom-card' className={classNames({ styled: !!styled })}>
      <FullScreen handle={handle}>
        <section className='header'>
          <section className='info'>
            {iconNode}
            <span className='title'>{i18next.t(title) || ''}</span>
          </section>

          <section className='actions'>
            {onClickEditButton && <EditButton onClickEditButton={onClickEditButton} />}
            {counterValue > 0 && <CounterContainer text={counterValue} theme='danger' />}
            {fullScreen && handleRenderFullScreenIcons()}
          </section>
        </section>

        {showSpacer && <Spacer className='custom-spacer' />}

        <section className='body'>{children}</section>
      </FullScreen>
    </div>
  )
}

export default memo(CustomCard)
