import { DisplayOptions24 } from '@mozaic-ds/icons/react'
import { Flex } from '@mozaic-ds/react'
import i18next from 'i18next'
import { useHistory } from 'react-router'

import { ContextMenu } from '../../../../../../components/ContextMenu'
import { ContextMenuOptionProps } from '../../../../../../components/ContextMenu/components/ContextMenuOption'
import User from '../../../../../../core/User'
import ProviderEntity from '../../../../../../models/Provider'

const user = new User()

const FUNCTION_ACCESS_TO_VIEW = 18
const FUNCTION_ACCESS_TO_EDIT = 19
const FUNCTION_ACCESS_TO_DELETE = 39
const FUNCTION_ACCESS_TO_EDIT_STORES_AND_SERVICE_GROUPS = 118

interface ActionsRowProps {
  serviceProvider: ProviderEntity
  onClickDelete: () => void
}

/**
 * @description Row with actions for each service provider in the table of Service Providers List V2 page
 */

export function ActionsRow({ serviceProvider, onClickDelete }: ActionsRowProps) {
  const history = useHistory()

  const hasUserAccessToView =
    user.currentUser.accessFunctionList?.indexOf(FUNCTION_ACCESS_TO_VIEW) !== -1
  const hasUserAccessToEdit =
    user.currentUser.accessFunctionList?.indexOf(FUNCTION_ACCESS_TO_EDIT) !== -1
  const hasUserAccessToDelete =
    user.currentUser.accessFunctionList?.indexOf(FUNCTION_ACCESS_TO_DELETE) !== -1
  const hasUserAcessToEditStoresAndServiceGroups =
    user.currentUser.accessFunctionList?.indexOf(
      FUNCTION_ACCESS_TO_EDIT_STORES_AND_SERVICE_GROUPS,
    ) !== -1

  const getOptions = () => {
    const options: ContextMenuOptionProps[] = [
      {
        icon: 'edit',
        label: i18next.t('option.edit'),
        onClick: () => {
          history.push(`/providers/edit/${serviceProvider.servcPrvdrId}`, {
            from: `/providers${history.location.search}`,
          })
        },
        isDisabled: !hasUserAccessToEdit && !hasUserAcessToEditStoresAndServiceGroups,
        title:
          !hasUserAccessToEdit && !hasUserAcessToEditStoresAndServiceGroups
            ? i18next.t('useraccess.not.allowed')
            : '',
      },
      {
        icon: 'view',
        label: i18next.t('button.label.view'),
        onClick: () =>
          history.push(`/providers/view/${serviceProvider.servcPrvdrId}`, {
            from: `/providers${history.location.search}`,
          }),
        isDisabled: !hasUserAccessToView,
        title: !hasUserAccessToView ? i18next.t('useraccess.not.allowed') : '',
      },
      {
        icon: 'delete',
        label: i18next.t('option.delete'),
        onClick: () => onClickDelete(),
        isDisabled: !hasUserAccessToDelete,
        title: !hasUserAccessToDelete ? i18next.t('useraccess.not.allowed') : '',
      },
    ]

    return options
  }

  return (
    <Flex gap='mu050' justifyContent='center' data-testid='actions-row'>
      <ContextMenu options={getOptions()} triggerIcon={<DisplayOptions24 />} />
    </Flex>
  )
}
