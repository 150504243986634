import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import store from '../../redux/store'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

export interface Speciality {
  specialityCd: string
  buCd: number
  cntryCd: string
  specialityNm: string
  active: boolean
}

export type Params = { limit?: number; page?: number; q?: string }

const user = new User().currentUser

export const fetchSpecialities = (params: Params = {}): Promise<ADEOResponse<Speciality[]>> => {
  const size = params.limit || 99999

  const pagination = {
    page: (params.page || 0) * size,
    size,
  }

  const query = {
    query: params.q || '',
  }

  return new API_REQUEST(user.dsToken, store.dispatch, pagination).get(
    API_URL.FETCH_SPECIALITIES,
    query,
  )
}
