import { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Col from '../../../components/utils/Col'
import Row from '../../../components/utils/Row'
import Thumbnail from '../../../components/utils/images/ThumbnailDocument'
import { FORMAT_DATE } from '../../../constants/config'
import MozaicIcon from '../../../components/MozaicIcon'

class ServicePersonListDocuments extends Component {
  renderRows = (document, index) => {
    const { onDelete, downloadFile, disabled, onEdit, attachmentId, user, dispatch } = this.props
    return (
      <Row key={document[attachmentId]} className='table-like-row padding-horizontal'>
        <Col lg={1} xs={2} className='table-like-data padding-vertical text-align-center'>
          <Thumbnail
            fileName={document.descAnexo}
            filePath={document.descDirAnexo}
            src={document.src}
            token={user.dsToken}
            dispatch={dispatch}
          />
        </Col>

        <Col lg={4} xs={3} className='table-like-data padding-vertical'>
          <a
            role='link'
            target='_blank'
            rel='noopener'
            onClick={() => downloadFile(document.descDirAnexo, document.descAnexo, document.src)}
          >
            {document.descAnexo}
          </a>
        </Col>

        <Col xs={3} className='table-like-data padding-vertical'>
          {document.descType}
        </Col>

        <Col xs={2} className='table-like-data padding-vertical'>
          {document.datInclusaoReg}
        </Col>

        {!disabled && (
          <Col xs={1} className='table-like-data padding-vertical text-align-center'>
            <a id={`edit-document-${index}`} role='button' onClick={() => onEdit(document)}>
              <MozaicIcon icon='PublishEdit32' color='primary' ml={-16} />
            </a>
          </Col>
        )}

        {!disabled && (
          <Col xs={1} className='table-like-data padding-vertical text-align-center'>
            <a id={`edit-document-${index}`} role='button' onClick={() => onDelete(document)}>
              <MozaicIcon icon='PublishTrashbin32' color='primary' ml={-16} />
            </a>
          </Col>
        )}
      </Row>
    )
  }

  getFileName = (path) => {
    const name = path.split('/')

    return name[name.length - 1]
  }

  renderCertificates = () => {
    const { t, certificates, user, dispatch, downloadFile } = this.props

    if (!certificates) return

    return certificates.map((certificate) => (
      <Row
        key={certificate.servcPrvdrAgntCertfctnTypCd}
        className='table-like-row padding-horizontal'
      >
        <Col lg={1} xs={2} className='table-like-data padding-vertical text-align-center'>
          <Thumbnail
            filePath={certificate.agntCertfctnDirctryTxt}
            token={user.dsToken}
            dispatch={dispatch}
          />
        </Col>

        <Col lg={4} xs={3} className='table-like-data padding-vertical'>
          <a
            role='link'
            target='_blank'
            rel='noopener'
            onClick={() =>
              downloadFile(
                certificate.agntCertfctnDirctryTxt,
                this.getFileName(certificate.agntCertfctnDirctryTxt),
              )
            }
          >
            {this.getFileName(certificate.agntCertfctnDirctryTxt)}
          </a>
        </Col>

        <Col xs={3} className='table-like-data padding-vertical'>
          {t('certificate.evaluation.screen.certificate.name')}
        </Col>

        <Col xs={4} className='table-like-data padding-vertical'>
          {moment(certificate.creatTs).format(FORMAT_DATE)}
        </Col>
      </Row>
    ))
  }

  render() {
    const { attachments, t, disabled } = this.props

    return (
      <div className='table-like table-like-odd'>
        <Row className='padding align-items-center'>
          <Col lg={1} xs={2} className='text-align-center'>
            <h5 className='no-margin strong'>{t('option.image')}</h5>
          </Col>
          <Col lg={4} xs={3}>
            <h5 className='no-margin strong'>{t('option.fileName')}</h5>
          </Col>
          <Col xs={3}>
            <h5 className='no-margin strong'>{t('option.documentType')}</h5>
          </Col>
          <Col xs={2}>
            <h5 className='no-margin strong'>{t('option.addDate')}</h5>
          </Col>

          {!disabled && (
            <Col xs={1} className='text-align-center'>
              <h5 className='no-margin strong'>{t('option.edit')}</h5>
            </Col>
          )}
          {!disabled && (
            <Col xs={1} className='text-align-center'>
              <h5 className='no-margin strong'>{t('option.delete')}</h5>
            </Col>
          )}
        </Row>

        <Row className='table-like-rows'>
          {attachments.map(this.renderRows)}
          {this.renderCertificates()}
        </Row>
      </div>
    )
  }
}

ServicePersonListDocuments.propTypes = {
  attachments: PropTypes.array,
  t: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  attachmentId: PropTypes.string.isRequired,
  user: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  certificates: PropTypes.array,
}

export default ServicePersonListDocuments
