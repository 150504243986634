import { useState } from 'react'
import i18next from 'i18next'
import { Flex, Text } from '@mozaic-ds/react'
import User from '../../../../../../../core/User'

import ErrorFallback from '../../../../../../../errors/fallbacks'
import ErrorBoundary from '../../../../../../../errors/boundaries'
import CustomCard from '../../../../../../../components/Card'
import MozaicIcon from '../../../../../../../components/MozaicIcon'

import { useServiceOrderDetailContext } from '../../../../../context'

import { checkMVFiles, isPlannedVisit } from '../../../../../../../helpers/isTechVisit'

import { MeasurementSheetFiles } from './components/MeasurementSheetFiles'
import { Form } from './components/Form'
import ServiceOrderStatusType from '../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'

const user = new User()

const ACCESS_FUNCTION_TO_APPROVE_FILES_IN_TECH_VISIT_PLANNED_FURNITURE = 108

/**
 * @description This component is responsible for rendering the measurement validation section of the service order detail page.
 */

export function MeasurementValidation() {
  const [isLoadingMeasurementSheetFiles, setIsLoadingMeasurementSheetFiles] = useState(true)
  const [hasMeasurementSheet, setHasMeasurementSheet] = useState(false)

  const { occurrences, serviceOrderBody } = useServiceOrderDetailContext()

  const categoryCd = String(serviceOrderBody?.servcCatgryTypCd)
  const isRealized =
    serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id
  const isTechPlannedVisit = isPlannedVisit(categoryCd)
  const hasPermissionToApproveFiles = user.hasAccessFunction(
    ACCESS_FUNCTION_TO_APPROVE_FILES_IN_TECH_VISIT_PLANNED_FURNITURE,
  )

  const { filesWereSent, filesWereApproved, filesWereRejected, lastMvOcurrence } =
    checkMVFiles(occurrences)

  const onUpdatingMeasurementSheetFilesStateFinished = (hasMeasurementSheet: boolean) => {
    setIsLoadingMeasurementSheetFiles(false)
    setHasMeasurementSheet(!!hasMeasurementSheet)
  }

  return isRealized && isTechPlannedVisit && lastMvOcurrence ? (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetErrorBoundary={() => {}}>
      <div className='measurement-validation'>
        <CustomCard
          styled
          title={i18next.t('entity.measuring.furniture')}
          iconNode={<MozaicIcon icon='LessonDIY24' ml={-3} />}
        >
          <Flex direction='column' paddingBottom='mu100'>
            <Text className='title'>{i18next.t('servc.ord.measurement.subtitle')}</Text>
            <Text className='subtitle'>{i18next.t('servc.ord.measurement.text')}</Text>

            <MeasurementSheetFiles
              filesWereSent={filesWereSent}
              filesWereRejected={filesWereRejected}
              onUpdateFileStateFinished={onUpdatingMeasurementSheetFilesStateFinished}
            />

            {!isLoadingMeasurementSheetFiles && (
              <>
                <Text className='title'>{i18next.t('servc.ord.measurement.form.title')}</Text>

                <Form
                  isLoadingMeasurementSheetFiles={isLoadingMeasurementSheetFiles}
                  canEdit={hasPermissionToApproveFiles}
                  lastMvOcurrence={lastMvOcurrence}
                  filesWereApproved={filesWereApproved}
                  filesWereRejected={filesWereRejected}
                  hasMeasurementSheet={hasMeasurementSheet}
                />
              </>
            )}
          </Flex>
        </CustomCard>
      </div>
    </ErrorBoundary>
  ) : (
    <></>
  )
}
