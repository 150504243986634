import i18next from 'i18next'
import { useEffect, useState } from 'react'
// @ts-ignore
import Select from 'react-select'

import API_URL from '../../core/Envs/endpoints'
import Api from '../../core/Http/FetchAdapter'
import User from '../../core/User'
import { colourStyles } from '../../helpers/selectStyle'
import { StoreType } from '../../models/StoreClustersGroups'
import store from '../../redux/store'

const user = new User().currentUser

type optionType = {
  value: any
  label: string
}

type StoreSelectType = {
  onChange: Function
  value: Array<optionType>
}

function StoreSelect(props: StoreSelectType) {
  const { onChange, value } = props
  const [options, setOptions] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchStores = async () => {
    setLoading(true)

    const { data: storesList } = await new Api(user.dsToken, store.dispatch).get(
      API_URL.FIND_STORES,
      {},
    )

    const options = storesList.map((branch: StoreType) => ({
      value: branch.id,
      label: branch.name,
    }))

    setOptions(options)
    setLoading(false)
  }

  const handleChange = (event: any) => {
    onChange(event)
    if (event === null) {
      onChange([])
    }
  }

  useEffect(() => {
    fetchStores()
  }, [])

  const label = i18next.t('bank_statement.store')
  const placeholder = `${i18next.t('search')} ${i18next.t('all.text').toLowerCase()}`
  const footer = i18next.t('advanced.search.category.select.footer')

  return (
    <div className='store-select'>
      <label className='advanced-search-modal_select--label' htmlFor='store-select'>
        {label}
      </label>

      <Select
        value={value}
        options={options}
        onChange={handleChange}
        isDisabled={loading}
        placeholder={placeholder}
        name='store-select'
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        resetValue={[]}
        styles={colourStyles}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isClearable
        isMulti
        menuPosition='absolute'
      />

      <label className='advanced-search-modal_select--footer'>{footer}</label>
    </div>
  )
}

export default StoreSelect
