import { createContext, useContext, useEffect, useState } from 'react'
import i18next from 'i18next'

import { getProviderLogo, findByProvider } from '../../../services/Provider'
import { fetchClustersOptions as getClustersOptions } from '../../../services/Clusters'
import { getStoresOptions } from '../../../services/Stores'
import { getInformationOptions } from '../../Components/samples/ProviderAnalysis'

import { fetchProviderData } from '../../../services/pages/ProviderAnalysis'

const ProviderAnalysisContext = createContext({})
/* eslint-disable */
const ProviderAnalysisProvider = (props) => {
  const { children, id } = props

  const [storesOptions, setStoresOptions] = useState([])
  const [clustersOptions, setClustersOptions] = useState([])
  const [informationOptions, setInformationOptions] = useState([])

  const [providerInfo, setProviderInfo] = useState([])
  const [loadingProviderInfo, setLoadingProviderInfo] = useState(false)
  const [providerLogo, setProviderLogo] = useState('')
  const [loadingProviderLogo, setLoadingProviderLogo] = useState(true)

  const agregationField = 'aggregationField'

  const [optionsLoaded, setOptionsLoaded] = useState(false)

  useEffect(() => {
    const loadingStores = handleFetchStores()
    const loadingClusters = handleFetchClusters()
    const loadingInformation = handleFetchInformation()
    handleFetchProviderInfo()

    Promise.all([loadingStores, loadingClusters, loadingInformation]).then(
      () => setOptionsLoaded(true),
    )
  }, [])

  async function handleFetchProviderInfo() {
    setLoadingProviderInfo(true)
    const data = await findByProvider(id)

    if (data) {
      setProviderInfo(data)
      setLoadingProviderInfo(false)

      if (data?.filePath) handleFetchProviderLogo(data?.filePath)
      else setLoadingProviderLogo(false)
    }
  }

  async function handleFetchProviderLogo(filePath) {
    setLoadingProviderLogo(true)

    const data = await getProviderLogo(filePath)

    if (data) {
      setProviderLogo(data)
      setLoadingProviderLogo(false)
    }
  }

  async function handleFetchStores() {
    const data = await getStoresOptions()
    setStoresOptions(data)
  }

  function removePrefixStore(data) {
    const prefix = i18next.t('provider.detail.remove.store')
    return data.map((cluster) => {
      cluster.stores = cluster.stores.map((store) => {
        store.name = store.name.replace(prefix, '').trim()
        return store
      })
      return cluster
    })
  }

  async function handleFetchClusters() {
    const data = await getClustersOptions()
    const clusters = removePrefixStore(data)
    setClustersOptions(clusters)
  }

  async function handleFetchInformation() {
    const data = await getInformationOptions()
    setInformationOptions(data)
  }

  async function fetchProviderStats(params, keys) {
    const response = await fetchProviderData(params)
    return response
  }

  function handleResolveFilteredData(items, keys) {
    const solvedResults = []

    if (keys.informationToCompare) {
      for (const item of items) {
        solvedResults.push({
          [agregationField]: item.aggregationfield,
          [keys.information]: getQuantityNotNull(item[keys.information], true),
          [keys.informationToCompare]: getQuantityNotNull(item[keys.informationToCompare], true),
        })
      }
    } else {
      for (const item of items) {
        solvedResults.push({
          [agregationField]: item.aggregationfield,
          [keys.information]: getQuantityNotNull(item[keys.information], true),
        })
      }
    }

    return solvedResults
  }

  function getQuantityNotNull(value, isDecimal) {
    if (isDecimal) return value || '0,0'
    return value || 0
  }

  const providerNoHasLogo = !loadingProviderLogo && providerLogo.length === 0

  const filterDefaultState = {
    period: {
      type: 'monthly',
      selected: 0,
    },
    data: {
      information: 'ratingServices',
      informationToCompare: 'ratingInstallers',
    },
    stores: storesOptions,
    clusters: clustersOptions,
    results: [],
    empty: false,
  }

  return (
    <ProviderAnalysisContext.Provider
      value={{
        id,
        optionsLoaded,
        storesOptions,
        clustersOptions,
        informationOptions,
        providerInfo,
        providerLogo,
        loadingProviderLogo,
        providerNoHasLogo,
        loadingProviderInfo,
        filterDefaultState,
        fetchProviderStats,
        agregationField,
        handleResolveFilteredData,
      }}
    >
      {children}
    </ProviderAnalysisContext.Provider>
  )
}

const useProviderAnalysisContext = () => useContext(ProviderAnalysisContext)

export { ProviderAnalysisContext, useProviderAnalysisContext, ProviderAnalysisProvider }
