import Api from '../../core/Http/FetchAdapter'

import API_URL from '../../core/Envs/endpoints'
import { GET_PAGES } from '../../redux/actionTypes/pagination'
import {
  FETCH_SERVICE_ORDERS_ERROR,
  FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE,
  FETCH_SERVICE_ORDERS_REQUEST,
  FETCH_SERVICE_ORDERS_SUCCESS,
} from '../../redux/actionTypes/serviceOrder'

import { hideSpinner, showSpinner } from '../../redux/actions/spinner'

import { reject } from '../../helpers/reduxHelpers'

const getPages = (metadata = {}) => ({
  type: GET_PAGES,
  pages: metadata.pageCount,
  current: metadata.page,
  count: metadata.totalCount,
})

/*
 * REQUEST SERVICE_ORDERS
 */

export const fetchServiceOrdersRequest = () => ({
  type: FETCH_SERVICE_ORDERS_REQUEST,
})

export const serviceOrdersSuccess = (data, params) => ({
  type: FETCH_SERVICE_ORDERS_SUCCESS,
  payload: data,
  filter: params,
})

export const fetchServiceOrderFromSomewhereElse = () => ({
  type: FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE,
})

const onFetchServiceOrdersSuccess = (data, dispatch, query) => {
  if (data) {
    dispatch(serviceOrdersSuccess(data, query))
  }
}

export const servcOrdListElastic =
  (token, fields, page = 1) =>
  (dispatch) => {
    dispatch(showSpinner())
    dispatch(fetchServiceOrdersRequest())
    dispatch(fetchServiceOrderFromSomewhereElse())

    const metadata = {
      page,
      size: 10,
    }

    const query = {
      ...fields,
      searchElastic: fields.elasticEnabled,
      hasFilters: true,
    }

    return new Api(token, dispatch, metadata)
      .get(API_URL.SERVC_ORD_LIST_ELASTIC, query)
      .then((response) => {
        dispatch(getPages(response.metadata))
        onFetchServiceOrdersSuccess(response.data, dispatch, query)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        dispatch(getPages({ pageCount: 0, page: 1, totalCount: 0, size: 0 }))
        reject(dispatch, FETCH_SERVICE_ORDERS_ERROR, error)
        return Promise.reject(error)
      })
      .finally(() => dispatch(hideSpinner()))
  }
