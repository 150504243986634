import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import CanvasDraw from 'react-canvas-draw'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import ReactHtmlParser from 'react-html-parser'
import MozaicIcon from '../../components/MozaicIcon'
import MozaicText from '../../components/MozaicText'
import Preview from '../../components/utils/preview/Link'
import { renderToastWarn } from '../../helpers/ToastUtils'
import { fetchGeralConditions, saveGeralConditions } from '../../services/TermsOfAcceptance'
import Header from './components/HeaderConditions'
import CardSpecificConditions from './components/SpecificConditions'

class GeneralConditions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      consentService: false,
      consentMarketing: false,
      hasSignature: false,
      minLength: 37,
      generalConditions: {
        error: null,
        record: null,
        save: false,
      },
    }
  }

  componentDidMount() {
    const { match } = this.props
    const { generalConditions } = this.state

    fetchGeralConditions(match.params?.id, match.params?.plntCd)
      .then((record) => this.setState({ generalConditions: { ...generalConditions, record } }))
      .catch((error) => this.setState({ generalConditions: { error } }))
  }

  componentDidUpdate(prevProps) {
    const {
      generalConditions: { error },
    } = this.state

    if (error !== null && error !== prevProps.error) {
      renderToastWarn(error)
    }
  }

  renderConsentMarketing() {
    const { t } = this.props
    const { consentMarketing } = this.state
    return (
      <span onClick={() => this.setState({ consentMarketing: !consentMarketing })}>
        <input type='checkbox' checked={consentMarketing} />
        <label style={{ padding: '10px' }}>
          {ReactHtmlParser(t('generalConditions.checkbox.marketing'))}
        </label>
      </span>
    )
  }

  submit(canvasRef) {
    const {
      consentService,
      consentMarketing,
      generalConditions,
      generalConditions: { record: conditionsData },
    } = this.state

    let imageSignature
    if (canvasRef.current) {
      imageSignature = canvasRef.current.canvasContainer.children[1].toDataURL()
    }

    const data = {
      vaSlsOrdNr: conditionsData.vaSlsOrdNr,
      imageSignature,
      consentService,
      consentMarketing,
      plntCd: conditionsData.plntCd,
    }

    saveGeralConditions(data)
      .then((record) =>
        this.setState({ generalConditions: { ...generalConditions, record, saveOS: true } }),
      )
      .catch((error) => this.setState({ generalConditions: { ...generalConditions, error } }))
  }

  clearSign(canvasRef) {
    if (canvasRef.current) {
      canvasRef.current.clear()
    }

    this.setState({ hasSignature: false })
  }

  onSignatureChange = () => {
    this.setState({ hasSignature: true })
  }

  dateInFull() {
    const { t } = this.props
    return moment().format('D %f MMMM %f YYYY').replaceAll('%f', t('label.of'))
  }

  render() {
    const {
      auth: { user },
      dispatch,
      t,
    } = this.props

    const {
      generalConditions: { saveOS, error, record: conditionsData },
    } = this.state

    const { consentService, hasSignature } = this.state
    const noRepeatedServiceOrderNumbers = [
      ...new Set(conditionsData?.serviceConditions.map((item) => item.numberOs) || []),
    ]
    const isAlreadyAcceptedTerms = conditionsData?.serviceConditions.some(
      (order) => order.osStatusOK === true,
    )
    const ref = createRef()

    if (saveOS) {
      return (
        <section className='container-fluid container-generalConditions'>
          <Header
            translate={t}
            dataSlsOrdNr={conditionsData}
            osNumbers={noRepeatedServiceOrderNumbers}
            showBody={false}
          />
          <br />
          <MozaicText>
            {t('conditions.success.content.1')}: <strong>{conditionsData?.custFullNm}</strong>
          </MozaicText>
          <MozaicText>
            {noRepeatedServiceOrderNumbers.length > 1
              ? t('conditions.label.nrOss')
              : t('conditions.label.nrOs')}
            <strong> {noRepeatedServiceOrderNumbers.join(', ')}</strong>
          </MozaicText>
          <MozaicText>
            <strong>{t('conditions.success.content.3')}</strong>
          </MozaicText>
          <MozaicText>{t('conditions.success.content.4')}</MozaicText>
          <MozaicText>
            <strong>{conditionsData?.custEmailTxt}</strong>
          </MozaicText>
          <MozaicText>{t('conditions.success.content.5')}</MozaicText>
          <MozaicText className='download-pdf-container'>
            <Preview
              base64Ready={conditionsData?.pdfContent}
              fileName={conditionsData?.pdfName}
              dispatch={dispatch}
              token={user ? user.dsToken : null}
              pdfName={conditionsData?.pdfName}
              downloadPdf
              t={t}
            >
              <>
                <MozaicIcon icon='Document32' />
                <strong>{conditionsData?.pdfName}</strong>
              </>
            </Preview>
          </MozaicText>
        </section>
      )
    }

    if (!conditionsData || error) {
      return (
        <section className='container-fluid container-generalConditions'>
          <div className='notification notification-warning general-message-full'>
            {t('conditions.message.error')}
          </div>
        </section>
      )
    }

    if (isAlreadyAcceptedTerms) {
      return (
        <section className='container-fluid container-generalConditions'>
          <div className='notification notification-primary general-message-full'>
            {t('conditions.message.terms.accept')}
          </div>
        </section>
      )
    }

    return (
      <section className='container-fluid container-generalConditions'>
        <Header
          translate={t}
          dataSlsOrdNr={conditionsData}
          osNumbers={noRepeatedServiceOrderNumbers}
          showBody
        />

        {conditionsData?.serviceConditions.map((serviceCondition) => (
          <CardSpecificConditions
            key={serviceCondition.serviceCode}
            translate={t}
            dataOS={serviceCondition}
            isSpecificConditions={false}
          />
        ))}

        <MozaicText>
          <span onClick={() => this.setState({ consentService: !consentService })}>
            <input type='checkbox' checked={consentService} />
            <label style={{ padding: '10px' }}>
              {noRepeatedServiceOrderNumbers.length > 1
                ? ReactHtmlParser(t('generalConditions.checkbox.services'))
                : ReactHtmlParser(t('generalConditions.checkbox.service'))}
            </label>
          </span>
        </MozaicText>

        <MozaicText>{this.renderConsentMarketing()}</MozaicText>

        <MozaicText style={{ marginTop: '30px', marginBottom: '30px' }}>
          {t('conditions.general.accept.label')}
        </MozaicText>

        <MozaicText style={{ marginTop: '30px', marginBottom: '5px' }}>
          {t('conditions.client.signature.label')}
        </MozaicText>

        <section className='signature'>
          <CanvasDraw
            style={{
              height: '200px',
              width: '100%',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#666666',
              borderRadius: '4px',
            }}
            brushRadius={1}
            lazyRadius={3}
            hideGrid
            ref={ref}
            onChange={this.onSignatureChange}
          />
          <label>{t('conditions.client.signature.placeholder')}</label>
        </section>

        <button className='button conditions-clear-zone' onClick={() => this.clearSign(ref)}>
          {t('conditions.clear.signature')}
        </button>

        <MozaicText>{t('generalConditions.place.and.date')}:</MozaicText>
        <MozaicText>
          {conditionsData.city}, {this.dateInFull()}
        </MozaicText>

        <button
          className='button button-primary container-button pull-right conditions-save'
          onClick={() => this.submit(ref)}
          disabled={!consentService || !hasSignature}
        >
          {t('save')}
        </button>
      </section>
    )
  }
}

GeneralConditions.propTypes = {
  auth: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  params: PropTypes.any,
  match: PropTypes.any,
  error: PropTypes.any,
}

const mapStateToProps = ({ auth }) => ({
  auth,
})

const GeneralConditionsTranslated = withTranslation()(GeneralConditions)

export default connect(mapStateToProps, null)(GeneralConditionsTranslated)

export { GeneralConditions }
