import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface ServiceOrderTag {
  serviceOrderStatusId: string
  tagType: string
}

export const getServiceOrderTags = async (servcOrdCd: number): Promise<ServiceOrderTag[]> => {
  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(
      `${API_URL.GET_SERVICE_ORDER_TAGS}/${servcOrdCd}`,
      {},
    )
    return data
  } catch (error) {
    console.error('getServiceOrderTags error', error)
  }

  return []
}
