import PropTypes from 'prop-types'
import Row from './Row'
import Col from './Col'

function TitleSection({ children }) {
  return (
    <Row className='padding'>
      <Col xs={12} className='title-section'>
        {children}
      </Col>
    </Row>
  )
}

TitleSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TitleSection
