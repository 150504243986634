/* eslint-disable react/button-has-type */

import Col from '../../../../components/utils/Col'
import Row from '../../../../components/utils/Row'

export default function index(props) {
  const { onError, t } = props

  return (
    <Row className='top-and-bottom-padding'>
      <Col xs={12}>
        <section className='main-fallback-page'>
          <h1 className='title'>{t('fallback.page.error.title')}</h1>
          <h2 className=''>{t('fallback.page.error.subtitle')}</h2>
          <h4 className='reload-page'>{t('fallback.page.error.help.text')}</h4>

          <button className='margin-top button button-primary' onClick={() => onError()}>
            {t('fallback.page.text.button')}
          </button>
        </section>
      </Col>
    </Row>
  )
}
