let currentUser = JSON.parse(localStorage.getItem('user'))

class User {
  loggingOut = false

  /**
   * Seta user no localStorage
   * @param {IUser} user
   */
  set currentUser(user) {
    currentUser = user

    localStorage.setItem('user', JSON.stringify(user))
  }

  /**
   * Recupera user do localStorage
   * @return {IUser} user
   */
  get currentUser() {
    return currentUser
  }

  /**
   * Seta menu do user no localStorage
   * @param {Object} menu
   */
  set menu(menu) {
    localStorage.setItem('userMenu', JSON.stringify(menu))
  }

  /**
   * Recupera menu do user do localStorage
   * @return {Object} menu
   */
  get menu() {
    return JSON.parse(localStorage.getItem('userMenu'))
  }

  hasAccessFunction(accessFunction) {
    if (this.currentUser?.accessFunctionList) {
      const accessFunctionList = Array.isArray(accessFunction) ? accessFunction : [accessFunction]

      return accessFunctionList?.some((access) =>
        this.currentUser?.accessFunctionList?.includes(parseInt(access, 10)),
      )
    }

    return false
  }

  /**
   * Limpa user e o menu do localStorage
   * @param {Object} menu
   */
  clearCache() {
    this.loggingOut = true
    localStorage.removeItem('user')
    localStorage.removeItem('userMenu')
  }
}

export default User

/**
 * @typedef {{
 *   userType: string;
 *   username: string;
 *   employeeID: string;
 *   employeeType: string;
 *   fullName: string;
 *   givenName: string;
 *   surname: string;
 *   title: string;
 *   locationName: string;
 *   locationCode: string;
 *   department: string;
 *   contractStartDate: string;
 *   email: string;
 *   manager: string;
 *   o: string;
 *   ou: string;
 *   modifiedBy: string;
 *   dsToken: string;
 *   dsCustomTokenFirebase: string;
 *   tokenExpirationTime: string;
 *   funcaoAcesso: AccessFunction;
 *   cntryCd: string;
 *   buCd: number;
 *   lsPlants: string[];
 *   accessFunctionList: number[];
 *   allowedCategories: number[];
 *   verificationAllIDWall: boolean;
 * }} IUser
 * @typedef {{
 *   inFuncao00: number;
 *   inFuncao01: number;
 *   inFuncao02: number;
 *   inFuncao03: number;
 *   inFuncao04: number;
 *   inFuncao05: number;
 *   inFuncao06: number;
 *   inFuncao07: number;
 *   inFuncao08: number;
 *   inFuncao09: number;
 *   inFuncao10: number;
 *   inFuncao15: number;
 *   inFuncao16: number;
 *   inFuncao17: number;
 *   inFuncao18: number;
 *   inFuncao19: number;
 *   inFuncao20: number;
 *   inFuncao21: number;
 *   inFuncao22: number;
 *   inFuncao24: number;
 *   inFuncao25: number;
 *   inFuncao26: number;
 *   inFuncao27: number;
 *   inFuncao28: number;
 *   inFuncao29: number;
 *   inFuncao30: number;
 *   inFuncao31: number;
 *   inFuncao32: number;
 *   inFuncao33: number;
 *   inFuncao35: number;
 *   inFuncao36: number;
 *   inFuncao37: number;
 *   inFuncao38: number;
 *   inFuncao39: number;
 *   inFuncao40: number;
 *   inFuncao41: number;
 *   inFuncao42: number;
 *   inFuncao43: number;
 *   inFuncao44: number;
 *   inFuncao45: number;
 *   inFuncao46: number;
 *   inFuncao47: number;
 *   inFuncao48: number;
 *   inFuncao49: number;
 *   inFuncao50: number;
 *   inFuncao51: number;
 *   inFuncao52: number;
 *   inFuncao53: number;
 *   inFuncao54: number;
 *   inFuncao55: number;
 *   inFuncao56: number;
 *   inFuncao57: number;
 *   inFuncao58: number;
 *   inFuncao59: number;
 *   inFuncao60: number;
 *   inFuncao61: number;
 *   inFuncao62: number;
 *   inFuncao63: number;
 *   inFuncao64: number;
 *   inFuncao65: number;
 *   inFuncao66: number;
 *   inFuncao67: number;
 *   inFuncao68: number;
 *   inFuncao69: number;
 *   inFuncao70: number;
 *   inFuncao71: number;
 *   inFuncao72: number;
 *   inFuncao73: number;
 *   inFuncao74: number;
 *   inFuncao75: number;
 *   inFuncao76: number;
 *   inFuncao77: number;
 *   inFuncao78: number;
 *   inFuncao79: number;
 *   inFuncao80: number;
 *   inFuncao83: number;
 *   inFuncao84: number;
 *   inFuncao85: number;
 *   inFuncao90: number;
 *   inFuncao94: number;
 *   inOrdemCorretiva: number;
 *   inApp: number;
 *   inChecklist: number;
 * }} AccessFunction
 */
