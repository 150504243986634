import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../../../../context'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { reloadServiceOrder } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        reloadServiceOrder,
      }),
      [],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
