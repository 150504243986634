import PropTypes from 'prop-types'
import { Component } from 'react'
import pdfIcon from '../../../../assets/icons/icone-xls.svg'
import Col from '../../../../components/utils/Col'
import Row from '../../../../components/utils/Row'

class BudgetsList extends Component {
  handleDeleteFile = (file) => {
    const { t } = this.props
    if (window.confirm(t('servc.ord.budget.component.title.exclude.file'))) {
      const { deleteAtchmt } = this.props
      // eslint-disable-next-line no-param-reassign
      file.actvServcOrdItemAtchmtInd = 0
      deleteAtchmt(file)
    }
  }

  renderThumbnail = () => (
    <div style={{ height: '3em' }} className='align-items-center justify-content-center'>
      <img className='image image-auto' src={pdfIcon} />
    </div>
  )

  renderRows = (document, index) => {
    const { disabled, downloadFile, attachmentId } = this.props

    return (
      <Row key={document[attachmentId]} className='table-like-row padding-horizontal'>
        <Col lg={2} xs={3} className='table-like-data padding-vertical'>
          {this.renderThumbnail()}
        </Col>

        <Col lg={5} xs={4} className='table-like-data padding-vertical'>
          <a
            role='link'
            target='_blank'
            rel='noopener'
            onClick={() =>
              downloadFile(document.atchmtCloudFullPathTxt, document.atchmtCloudFullPathTxt)
            }
          >
            {document.servcOrdItemAtchmtDesc}
          </a>
        </Col>

        <Col xs={3} className='table-like-data padding-vertical'>
          {document.creatTs}
        </Col>

        {!disabled && (
          <Col xs={2} className='table-like-data padding-vertical text-align-center'>
            <a
              id={`edit-document-${index}`}
              role='button'
              onClick={() => {
                this.handleDeleteFile(document, index)
              }}
            >
              <i className='glyph glyph-trash-can' />
            </a>
          </Col>
        )}
      </Row>
    )
  }

  render() {
    const { attachments, t, disabled } = this.props

    return (
      <div className='table-like table-like-odd'>
        <Row className='padding align-items-center'>
          <Col lg={2} xs={3} className='text-align-center'>
            <h5 className='no-margin strong'>{t('option.image')}</h5>
          </Col>
          <Col lg={5} xs={4}>
            <h5 className='no-margin strong'>{t('option.fileName')}</h5>
          </Col>
          <Col xs={3}>
            <h5 className='no-margin strong'>{t('option.addDate')}</h5>
          </Col>
          {!disabled && (
            <Col xs={1} className='text-align-center'>
              <h5 className='no-margin strong'>{t('option.delete')}</h5>
            </Col>
          )}
        </Row>

        <Row className='table-like-rows'>{attachments.map(this.renderRows)}</Row>
      </div>
    )
  }
}

BudgetsList.propTypes = {
  attachments: PropTypes.array,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  attachmentId: PropTypes.string.isRequired,
  deleteAtchmt: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
}

export default BudgetsList
