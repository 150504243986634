import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import store from '../../../redux/store'
import User from '../../../core/User'

const user = new User().currentUser

interface SendNotifications {
  chatMessageUserType: number
  displayName: string
  message: string
  servcOrdSeq: string
}

export async function sendChatNotifications(filters: SendNotifications) {
  try {
    new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.NEW_COCKPIT_CHAT_SEND_MESSAGE,
      filters,
    )
  } catch (error) {
    console.error('error when send notifications chat', error)
  }
}
