import i18next from 'i18next'
import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface RegisterHistoricOccurrenceParams {
  servcOrdSeq: number
  servcOrdOccurrenceDesc: string
}

export const registerHistoricOccurrence = async (params: RegisterHistoricOccurrenceParams) => {
  try {
    const { cdStatus } = await new API_REQUEST(user.dsToken, store.dispatch).post(
      `${API_URL.REGISTER_HISTORIC_OCCURRENCE}/${params.servcOrdSeq}`,
      {
        ...params,
        creatUserCd: user.username,
        creatUserNm: user.fullName,
      },
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('serviceOrder.historic.occurrences.newOccurrence.successfullyRegistered'),
      '',
    )

    return cdStatus
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
