import i18next from 'i18next'
import { AlertTriangle } from '@leroy-merlin-br/backyard-icons'
import { Link } from 'react-router-dom'
import MozaicText from '../../components/MozaicText'

/*
  Description component: container usable when user accessed a non-existent route
*/
function NotFoundContainer() {
  return (
    <section id='not-found-page-container' className='container'>
      <div className='col-sm-10 col-sm-offset-1 text-center content'>
        <header className='four_zero_four_bg'>
          <h1 className='text-center'>404</h1>

          <AlertTriangle height={150} width={150} color='#f8cc1c' />
        </header>

        <section className='box'>
          <h3>{i18next.t('not.found.page.body.title')}</h3>

          <MozaicText>{i18next.t('not.found.page.body.helper.text')}</MozaicText>

          <Link to='/' className='link_404'>
            {i18next.t('go.to.home.text')}
          </Link>
        </section>
      </div>
    </section>
  )
}

export default NotFoundContainer
