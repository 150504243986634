import { memo, useMemo } from 'react'
import i18next from 'i18next'
import MozaicCard from '../../../../../../components/MozaicCard'
import FormField from '../../../../../../components/FormField'
import MozaicButton from '../../../../../../components/MozaicButton'
import MozaicIcon from '../../../../../../components/MozaicIcon'

import { ProjectResume } from '../../../../../../models/Project'
import { formatCPF, format } from '../../../../../../helpers/masks'
import ErrorBoundary from '../../../../../../errors/boundaries'
import { useProjectDetailsContext } from '../../../../context'

function Client({ project, loadingProject, getProjectDetails }: ProjectResume) {
  const clientResume = {
    title: i18next.t('conformity.osData.subtitle'),
    leftIcon: <MozaicIcon icon='AccountProfileView32' mt={2} />,
    rightAction: (
      <MozaicButton id='client-edit-button' variant='ghost'>
        {i18next.t('accordeon.action.edit')}
      </MozaicButton>
    ),
  }

  const custFullNm = project?.custFullNm
  const custFiscalId = formatCPF(project?.custFiscalId)
  const custMobilePhonNr = format(project?.custMobilePhonNr?.substring(3), '(99) 99999-9999')
  const servcAddrPstlCd = project?.servcAddrPstlCd
  const servcRgnZn = project?.servcRgnZn || '-'
  const servcAddrStrNm = project?.servcAddrStrNm
  const servcAddrNr = project?.servcAddrNr
  const servcAddrCmplmtryTxt = project?.servcAddrCmplmtryTxt
  const servcAddrDstrctNm = project?.servcAddrDstrctNm
  const servcAddrCityNm = project?.servcAddrCityNm
  const servcAddrStCd = project?.servcAddrStCd

  return (
    <div id='client-details'>
      <ErrorBoundary resetErrorBoundary={getProjectDetails}>
        <MozaicCard {...clientResume}>
          <div className='resume-fields'>
            <div className='resume-fields'>
              <FormField
                isBold
                label={i18next.t('field.name.name')}
                value={custFullNm}
                className='col-12'
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('passwordFragment.cpf')}
                value={custFiscalId}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('company.info.cellphone')}
                value={custMobilePhonNr}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('company.info.cep')}
                value={servcAddrPstlCd}
                isFetching={loadingProject}
              />

              <FormField
                label={`${i18next.t('serviceOrder.fields.zone')} ${i18next.t(
                  'project.macro.regionality',
                )}`}
                value={servcRgnZn}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('company.info.address')}
                value={servcAddrStrNm}
                className='col-12'
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('company.info.number')}
                value={servcAddrNr}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('provider.portal.complement')}
                value={servcAddrCmplmtryTxt}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('serviceOrders.descReferEndereco')}
                value='-'
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('provider.portal.district')}
                value={servcAddrDstrctNm}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('company.info.city')}
                value={servcAddrCityNm}
                isFetching={loadingProject}
              />

              <FormField
                label={i18next.t('provider.portal.UF')}
                value={servcAddrStCd}
                isFetching={loadingProject}
              />
            </div>
          </div>
        </MozaicCard>
      </ErrorBoundary>
    </div>
  )
}

const MemoComponent = memo(Client)

function ClientWC(props: any) {
  const { project, loadingProject, getProjectDetails } = useProjectDetailsContext()

  const newProps = useMemo(
    () => ({ project, loadingProject, getProjectDetails }),
    [project, loadingProject, getProjectDetails],
  )

  return <MemoComponent {...newProps} {...props} />
}

export default ClientWC
