import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import Mask from 'vanilla-masker'

import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import { formatDecimal, formatDecimalNoCurrency } from '../../../helpers/masks'

import MozaicIcon from '../../../components/MozaicIcon'
import ServiceOrderModal from './ServiceOrderModal'

class ServiceOrderItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      serviceOrderModal: false,
      currency: props.t('option.currency'),
      sizeCurrency: props.t('size.currency'),
      formatCurrency: {
        separator: props.t('separator.currency'),
        delimiter: props.t('delimiter.currency'),
      },
    }
  }

  handleChange = (event) => {
    const { serviceOrderItem } = this.props
    const { value } = event.target

    if (typeof value !== 'undefined' && value !== null) {
      serviceOrderItem.servcOrdRmrkTxt = value
    }
  }

  toggleServiceOrderModal = () => {
    const { serviceOrderModal } = this.state
    this.setState({ serviceOrderModal: !serviceOrderModal })
  }

  renderServiceOrderModal = () => {
    const { serviceOrderModal } = this.state
    const { t, serviceOrderItem, disabled, auth, ...otherProps } = this.props

    if (!serviceOrderModal) {
      return
    }

    if (
      typeof serviceOrderItem.servcOrdRmrkTxt === 'undefined' ||
      serviceOrderItem.servcOrdRmrkTxt === null
    ) {
      serviceOrderItem.servcOrdRmrkTxt = ''
    }

    const initialValues = {
      servcOrdRmrkTxt: serviceOrderItem.servcOrdRmrkTxt,
    }

    const props = {
      t,
      modalType: 'VIEW_OBSERVATION',
      closeModal: this.toggleServiceOrderModal,
      values: serviceOrderItem,
      onChange: this.handleChange,
      initialValues,
      disabled,
      onSubmit: this.onSubmitObs,
      auth,
    }

    return <ServiceOrderModal {...otherProps} {...props} />
  }

  hasObservation = (serviceOrderItem) =>
    serviceOrderItem.descObservacao && !!serviceOrderItem.descObservacao.trim()

  haveAssociatedProduct = () => {
    const { serviceOrderItem, simple } = this.props

    if (serviceOrderItem.nomeProdAssociado || serviceOrderItem.codProdAssociado) {
      return true
    }

    if (!simple) {
      if (serviceOrderItem.qtdProdAssociado) {
        return true
      }
    }

    return false
  }

  isActive = (serviceOrderItem) => {
    const { isCancel } = this.props
    const itemWasReturned =
      serviceOrderItem?.servcOrdItemQtyAcquired !== serviceOrderItem?.servcOrdItemQtyReturned
    const itemStatusCanceled = serviceOrderItem.indStatusOrdemServicoItem !== '09'

    if (isCancel) {
      return false
    }
    return serviceOrderItem?.servcOrdItemQtyReturned ? itemWasReturned : itemStatusCanceled
  }

  onSubmitObs = () => {
    const { onSubmitItemObs, serviceOrderItem } = this.props

    this.toggleServiceOrderModal()
    onSubmitItemObs(serviceOrderItem)
  }

  renderPassThroughValue = (simple, parameterServicePriceOn) => {
    const { t, serviceOrderItem } = this.props

    const totalPassThrough =
      (serviceOrderItem.servcOrdItemQtyAcquired - (serviceOrderItem.servcOrdItemQtyReturned || 0)) *
      serviceOrderItem.servcOrdItemPrvdrPymtAmt

    if (!simple && parameterServicePriceOn) {
      return (
        <Col xs={3} className='itens'>
          {serviceOrderItem.servcOrdItemPrvdrPymtAmt ? (
            <div>
              <span className={` price ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
                {serviceOrderItem.servcOrdItemPrvdrPymtAmt
                  ? formatDecimal(serviceOrderItem.servcOrdItemPrvdrPymtAmt, t)
                  : ''}
              </span>
              <span style={{ margin: '5px' }}>/</span>
              <span className={`strong price ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
                {serviceOrderItem.servcOrdItemPrvdrPymtAmt
                  ? formatDecimal(totalPassThrough, t)
                  : ''}
                {serviceOrderItem.servcOrdItemPrvdrPymtAmt &&
                  serviceOrderItem.servcOrdItemQtyReturned &&
                  '*'}
              </span>
            </div>
          ) : (
            ''
          )}
          {serviceOrderItem.servcOrdItemQtyReturned && (
            <span className='adjusted-value-after-return-label'>
              {t('serviceOrders.adjusted.value.after.return')}
            </span>
          )}
        </Col>
      )
    }
  }

  renderValueItem = (serviceOrderItem, parameterServicePriceOn) => {
    const { t } = this.props
    if (parameterServicePriceOn) {
      return (
        <span>
          <span style={{ margin: '5px' }}>/</span>
          <span className={`strong price ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
            {formatDecimal(serviceOrderItem.valTotal, t)}
            {serviceOrderItem.servcOrdItemQtyReturned && '*'}
          </span>
        </span>
      )
    }
  }

  render() {
    const { t, serviceOrderItem, simple, showReport, noDivider, className, auth } = this.props
    const { sizeCurrency } = this.state

    const cls = classNames(
      'service-order-item',
      'top-and-bottom-padding',
      'no-padding-side',
      { 'divider-top': !noDivider },
      className,
    )

    const enableShowSlotsOnItem = auth.user.accessFunctionList?.includes(115)

    const parameterServicePriceOn =
      auth && auth.setupParameters && auth.setupParameters.acceptingServicePrice

    const checkServcOrdItemQtyRelzdIsMinor =
      serviceOrderItem.servcOrdItemQtyRelzd &&
      serviceOrderItem.servcOrdItemQtyAcquired !== serviceOrderItem.servcOrdItemQtyRelzd

    const servcOrdItemQtyRelzdClassName = `strong ${checkServcOrdItemQtyRelzdIsMinor && 'red'} ${
      this.isActive(serviceOrderItem) ? '' : 'inactive'
    }`

    const prevTime = t('servcOrders.estimated.time.slot.label')
      .replace('{0}', serviceOrderItem.slots || '0')
      .replace('{1}', serviceOrderItem.qtdProdOrdemServico)

    return (
      <Grid fluid className={cls}>
        <Row>
          <Col xs={3}>
            <div>
              <span
                className={`servc-order-item-nome-prod-text margin-right-small ${
                  this.isActive(serviceOrderItem) ? '' : 'inactive'
                }`}
              >
                {serviceOrderItem.nomeProd}
              </span>

              <a onClick={this.toggleServiceOrderModal}>
                <MozaicIcon icon='NotificationWarning24' className='info-icon' />
              </a>
              {!simple && this.hasObservation(serviceOrderItem) ? (
                <a onClick={this.toggleServiceOrderModal}>
                  <i className='icon icon-info' />
                </a>
              ) : null}
            </div>

            <span
              className={`servc-order-item-cod-prod-text no-margin ${
                this.isActive(serviceOrderItem) ? '' : 'inactive'
              }`}
            >
              {t('serviceOrders.services.codService')}: {serviceOrderItem.codProd}
            </span>
            {enableShowSlotsOnItem && (
              <p
                className={`servc-order-item-prev-time no-margin ${
                  this.isActive(serviceOrderItem) ? '' : 'inactive'
                }`}
              >
                {prevTime}
              </p>
            )}
          </Col>

          {!simple && (
            <Col xs={3} className='itens'>
              <span className={`strong ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
                {formatDecimalNoCurrency(serviceOrderItem.servcOrdItemQtyAcquired, t) || ''}
              </span>
              <span style={{ margin: '5px' }}>/</span>
              <span className={servcOrdItemQtyRelzdClassName}>
                {serviceOrderItem.servcOrdItemQtyRelzd
                  ? formatDecimalNoCurrency(serviceOrderItem.servcOrdItemQtyRelzd, t)
                  : '-'}
              </span>

              {serviceOrderItem.servcOrdItemQtyReturned ||
              serviceOrderItem.servcOrdItemQtyReturned === 0 ? (
                <>
                  <span style={{ margin: '5px' }}>/</span>
                  <span className={`strong ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
                    {formatDecimalNoCurrency(serviceOrderItem.servcOrdItemQtyReturned, t) || ''}
                  </span>
                </>
              ) : (
                <></>
              )}
            </Col>
          )}

          {!simple && (
            <Col xs={3} className='itens'>
              {serviceOrderItem.valProdOrdemServico && (
                <div>
                  <span
                    className={`${parameterServicePriceOn ? '' : 'strong'} price ${
                      this.isActive(serviceOrderItem) ? '' : 'inactive'
                    }`}
                  >
                    {formatDecimal(serviceOrderItem.valProdOrdemServico, t)}
                  </span>
                  {this.renderValueItem(serviceOrderItem, parameterServicePriceOn)}
                </div>
              )}
            </Col>
          )}

          {this.renderPassThroughValue(simple, parameterServicePriceOn)}
        </Row>

        {this.haveAssociatedProduct() && (
          <div>
            <Row className='padding-top'>
              <Col xs={3} className='padding-bottom-small'>
                <h4 className='no-margin color-primary'>{t('serviceOrders.associatedProduct')}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <h6
                  className={`strong no-margin ${
                    this.isActive(serviceOrderItem) ? '' : 'inactive'
                  }`}
                >
                  {serviceOrderItem.nomeProdAssociado}
                </h6>
                <span
                  className={`color-default small ${
                    this.isActive(serviceOrderItem) ? '' : 'inactive'
                  }`}
                >
                  {t('serviceOrders.services.codProd')}: {serviceOrderItem.codProdAssociado}
                </span>
              </Col>

              {!simple && (
                <Col xs={3} className='itens'>
                  <span className={`strong ${this.isActive(serviceOrderItem) ? '' : 'inactive'}`}>
                    {serviceOrderItem.qtdProdAssociado
                      ? Mask.toMoney(serviceOrderItem.qtdProdAssociado.toFixed(sizeCurrency))
                      : ''}
                  </span>
                </Col>
              )}
            </Row>
          </div>
        )}

        {showReport && serviceOrderItem.servcOrdRmrkTxt && (
          <div className='align-right'>
            <Link
              to={{
                pathname: `/technicalReport/${serviceOrderItem.idOrdemServicoItem}`,
                state: { serviceOrderItem },
              }}
            >
              {t('serviceOrders.services.viewReport')}
            </Link>
          </div>
        )}

        {this.renderServiceOrderModal()}
      </Grid>
    )
  }

  static propTypes = {
    auth: PropTypes.object,
    t: PropTypes.func.isRequired,
    serviceOrderItem: PropTypes.object.isRequired,
    simple: PropTypes.bool,
    disabled: PropTypes.bool,
    showReport: PropTypes.bool,
    noDivider: PropTypes.bool,
    className: PropTypes.string,
    onSubmitItemObs: PropTypes.func.isRequired,
    isCancel: PropTypes.bool,
  }
}

export default ServiceOrderItem
