import PropTypes from 'prop-types'
import { Component, createRef } from 'react'

import Row from '../../../../components/utils/Row'

import { WithForm } from '../../../../helpers/useForm/withForm'
import BudgetsForm from './Form'
import BudgetsList from './List'

class BudgetsUploadFile extends Component {
  inputRef = createRef()

  shouldRenderList() {
    const { attachments } = this.props
    return attachments && attachments.length > 0
  }

  onSubmitUpload = (values) => {
    const { onSubmit } = this.props
    return onSubmit(values)
  }

  isSameFile = (attachment) => {
    const {
      values: { files },
      attachmentId,
    } = this.props

    if (files && files.length) {
      if (attachment[attachmentId] === files[0].id) {
        return true
      }
    }

    return false
  }

  resetForm = () => {
    const { setFormValue } = this.props

    setFormValue({ files: null })

    this.resetInputFile()
  }

  resetInputFile = () => {
    const inputRef = this.inputRef.current

    if (inputRef) {
      inputRef.value = ''
      inputRef.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  render() {
    const {
      t,
      attachments,
      closeModal,
      error,
      disabled,
      setFormValue,
      downloadFile,
      register,
      fields,
      values,
      relationId,
      attachmentId,
      auth,
      dispatch,
      translationNumber,
      deleteAtchmt,
      blockButtonRemove,
    } = this.props

    return (
      <div>
        <Row className='padding lmi-modal-header'>
          <span>{t('option.manageDocuments')}</span>
        </Row>

        {this.shouldRenderList() && (
          <BudgetsList
            t={t}
            attachments={attachments}
            attachmentId={attachmentId}
            deleteAtchmt={deleteAtchmt}
            downloadFile={downloadFile}
            disabled={blockButtonRemove}
          />
        )}

        {!disabled && (
          <BudgetsForm
            t={t}
            register={register}
            onSubmit={this.onSubmitUpload}
            closeModal={closeModal}
            error={error}
            setFormValue={setFormValue}
            fields={fields}
            values={values}
            resetForm={this.resetForm}
            inputRef={this.inputRef}
            relationId={relationId}
            auth={auth}
            dispatch={dispatch}
            translationNumber={translationNumber}
          />
        )}
      </div>
    )
  }
}

BudgetsUploadFile.propTypes = {
  t: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setFormValue: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  translationNumber: PropTypes.number,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  attachmentId: PropTypes.string.isRequired,
  relationId: PropTypes.number.isRequired,
  deleteAtchmt: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  blockButtonRemove: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
}

const FIELDS = { translationNumber: '', files: null }

export default WithForm(FIELDS)(BudgetsUploadFile)

export { BudgetsUploadFile }
