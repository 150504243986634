import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import Col from '../../../../components/utils/Col'
import Modal from '../../../../components/utils/Modal'
import Row from '../../../../components/utils/Row'
import store from '../../../../redux/store'

function ModalComponent({
  titleModal,
  closeModal,
  servcOrdSeq,
  vaSlsOrdNr,
  plntCd,
  t,
  sendMsg,
  ...props
}) {
  const title = titleModal || 'link.sales.conditions'

  const qrcode = store.getState().serviceOrderReducer.qrcode

  if (!qrcode.shortLink.data) return <></>

  return (
    <Modal closeModal={closeModal} showCloseButton className='qrcode-modal no-scroll' {...props}>
      <Row className='flex align-items-star padding-bottom'>
        <header>
          <span className='title'>{t(title).replace('{0}', servcOrdSeq)}</span>
        </header>
      </Row>
      <Row className='flex align-items-start padding-bottom'>
        <span className='subtitle'>{t('point.cell.phone')}</span>
      </Row>
      <Row>
        <Col className='flex-column align-items-center padding'>
          <div>
            <QRCode
              id={`qrcode-${vaSlsOrdNr}-${plntCd}`}
              value={qrcode.shortLink.data}
              size={290}
              level='H'
              includeMargin
              renderAs='canvas'
            />
          </div>
          <button
            className='button button-primary padding send-message'
            type='button'
            onClick={() => {
              sendMsg(vaSlsOrdNr, plntCd)
            }}
          >
            {t('send.sms.email')}
          </button>
        </Col>
      </Row>
    </Modal>
  )
}

ModalComponent.propTypes = {
  sendMsg: PropTypes.func.isRequired,
  shortLink: PropTypes.object.isRequired,
  servcOrdSeq: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  titleModal: PropTypes.string,
  vaSlsOrdNr: PropTypes.string,
  ordersCode: PropTypes.string,
  plntCd: PropTypes.string,
}

export default ModalComponent
