import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import Row from './Row'
import Col from './Col'
import MozaicText from '../MozaicText'

class InactiveProviderModal extends PureComponent {
  render() {
    const { closeModal, t, servcPrvdrCd } = this.props

    return (
      <Modal className='inactiveprovider-modal' closeModal={closeModal} size='sm'>
        <Row>
          <Col xs={12} className='justify-content-center'>
            <h3>{t('inactiveProviderModal.actvServcPrvdrAgntInd.title')}</h3>
          </Col>

          <Col xs={12} className='justify-content-center'>
            <MozaicText>{t('inactiveProviderModal.actvServcPrvdrAgntInd.text')}</MozaicText>
          </Col>

          <Col xs={12}>
            <a
              className='button button-primary button-full text-center'
              target='_blank'
              href={`/providers/edit/${servcPrvdrCd}`}
              rel='noreferrer'
            >
              {t('inactiveProviderModal.actvServcPrvdrAgntInd.button')}
            </a>
          </Col>
        </Row>
      </Modal>
    )
  }
}

InactiveProviderModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  servcPrvdrCd: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default InactiveProviderModal

export { InactiveProviderModal }
