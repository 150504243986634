import { memo, useMemo } from 'react'
import i18next from 'i18next'

import Resume from '../../Resume'
import ErrorBoundary from '../../../../../errors/boundaries'
import OcurrencesTable from '../../../../../components/OcurrencesTable'
import MozaicAccordeon from '../../../../../components/MozaicAccordeon'
import MozaicButton from '../../../../../components/MozaicButton'
import MozaicIcon from '../../../../../components/MozaicIcon'
import MozaicCard from '../../../../../components/MozaicCard'

import { HistoricOccurrence } from '../../../../ServiceOrderDetail/components/Routes/Historic/components/HistoricOccurrences/models/historic-occurrence'
import { fetchProjectOcurrences } from '../../../../../services/Project'
import { useQuery } from '@tanstack/react-query'
import { Flex } from '@mozaic-ds/react'
import SpinnerCircle from '../../../../../components/utils/SpinnerCircle'
import { useProjectDetailsContext } from '../../../context'

function Ocurrences({ projectID }: { projectID: number }) {
  const { data: occurrences, isLoading } = useQuery({
    queryKey: ['occurrencesList', projectID],
    queryFn: () => fetchProjectOcurrences(118631),
  })

  const addOcurrenceText = `${i18next.t('button.label.add')} ${i18next
    .t('serviceOrders.Occurrence')
    .toLowerCase()}`
  const filterText = i18next.t('filter_button_label')

  return (
    <div id='ocurrences-container'>
      <ErrorBoundary>
        <Resume />
        <section className='project-tab'>
          <MozaicAccordeon
            className='evaluation'
            group='evaluation'
            header={
              <h4 className='project-mozaic-header'>
                <MozaicIcon icon='ViewStarHalf24' size={24} mt={-0.5} />
                Avaliações
              </h4>
            }
          >
            <Flex className='eval-body'>Nothing here yet</Flex>
          </MozaicAccordeon>

          <MozaicCard title='Ocorrências' leftIcon={<MozaicIcon icon='TimeRead24' />}>
            <Flex paddingLeft='mu150' paddingRight='mu150' justifyContent='space-between'>
              <MozaicButton size='s' variant='bordered'>
                {addOcurrenceText}
              </MozaicButton>
              <MozaicButton size='s' variant='bordered'>
                <MozaicIcon icon='DisplayFilter48' className='filter-icon' size={16} />
                {filterText}
              </MozaicButton>
            </Flex>

            <Flex padding='mu150'>
              {!isLoading ? (
                <OcurrencesTable v2 occurrences={occurrences as HistoricOccurrence[]} />
              ) : (
                <SpinnerCircle />
              )}
            </Flex>
          </MozaicCard>
        </section>
      </ErrorBoundary>
    </div>
  )
}

const MemoComponent = memo(Ocurrences)

function OcurrencesWC() {
  const { projectID } = useProjectDetailsContext()

  const newProps = useMemo(() => ({ projectID }), [projectID])

  return <MemoComponent {...newProps} />
}

export default OcurrencesWC
