import i18next from 'i18next'
import { KeyboardEvent, useState } from 'react'

import { IconButton, TextField } from '@leroy-merlin-br/backyard-react'
import { Toggle } from '@mozaic-ds/react'
import { setInputFocus } from '../../utils'

import CustomerChatV2 from '../../../../services/firestore/customerChatV2/CustomerChatV2'
import MozaicIcon from '../../../MozaicIcon'

type SendMessageProps = {
  chatRef: CustomerChatV2
  id: string
  solved: boolean
}

function SendMessage({ chatRef, id, solved }: SendMessageProps) {
  const [newMessage, setNewMessage] = useState('')
  const [solve, setSolve] = useState(false)

  function handleResolveChat() {
    chatRef.resolveChat(!solved)

    setSolve(true)
  }

  function handleSendNewMessage() {
    chatRef.updateChat(newMessage, solve)
    setNewMessage('')
    setInputFocus(id)
    setSolve(false)
  }

  function handleSetNewMessage(event: any) {
    setNewMessage(event.target.value)
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && newMessage) {
      handleSendNewMessage()
    }
  }

  function handleClearSearchedText() {
    setNewMessage('')
  }

  const suffixIcon = () => (
    <>
      {newMessage !== '' && (
        <div className='clear-filter' onClick={handleClearSearchedText}>
          <MozaicIcon icon='ControlCross16' />
        </div>
      )}
    </>
  )

  return (
    <section className='chat-footer'>
      <div className='send-message'>
        <div className='inputs-actions'>
          <TextField
            id={id}
            className='client-chat-text-field-v2'
            hasSuffix
            renderSuffix={suffixIcon}
            placeholder={i18next.t('client.chat.submit.input.placeholder')}
            value={newMessage}
            onChange={handleSetNewMessage}
            onKeyDown={handleKeyDown}
          />

          <div className='resolve-chat'>
            <Toggle isChecked={solved} onChange={handleResolveChat} size='s' />

            <span>{i18next.t('client.chat.solved.conversation.text')}</span>
          </div>
        </div>

        <IconButton label='send' onClick={handleSendNewMessage} disabled={!newMessage}>
          <MozaicIcon icon='PublishSend24' className='send-icon' mt={3} />
        </IconButton>
      </div>
    </section>
  )
}

export default SendMessage
