/* eslint-disable react/prop-types */

import i18next from 'i18next'
import MozaicButton from '../../components/MozaicButton'

interface ErrorFallbackProps {
  error: { message: string }
  resetErrorBoundary: () => void
}

function ErrorFallback({
  error,
  resetErrorBoundary = () => window.location.reload(),
}: ErrorFallbackProps) {
  return (
    <div role='alert' id='generic-fallback'>
      <p className='subtitle'>{i18next.t('fallback.page.error.subtitle')}</p>

      <p className='message'>{error.message}</p>

      <MozaicButton variant='bordered' onClick={resetErrorBoundary}>
        {i18next.t('fallback.page.text.button')}
      </MozaicButton>
    </div>
  )
}
export default ErrorFallback
