import { renderToastSuccessMsg } from './ToastUtils'

const copyToClipboard = (text, t) => {
  const clipboardText = text || ''

  const textField = document.createElement('textarea')

  textField.innerText = clipboardText

  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()

  renderToastSuccessMsg(t('copyToClipboard.message'))
}

export default copyToClipboard
