import { useEffect, useMemo, useState } from 'react'
import {
  getProviderZones,
  getRegionsOptions,
  getZonesOptions,
} from '../../../../../services/pages/ServiceProviderEdit/MacroRegionality'

import { RegionType, ZoneType, actionType, optionType } from '../models'

const checkOptionExists = (options: Array<optionType | any>, value: any) =>
  options.some((option) => option.value === value)

const optionLabelByValue = (options: Array<optionType | any>, value: any) =>
  options.find((option) => option.value === value).label

const handleRemovedOptions = (removedOption: any, selectedZones: any[]) => {
  const newSecondaryZones =
    removedOption instanceof Array
      ? selectedZones.filter((zone) => !checkOptionExists(removedOption, zone.microRegionId))
      : selectedZones.filter((zone) => zone.microRegionId !== removedOption.value)

  const newZonesOptions = newSecondaryZones.map((zone: any) => ({
    value: zone.cdCity,
    label: zone.nmCity,
  }))

  return newZonesOptions
}

function ZonesHandler(props: any) {
  const { servcPrvdrId } = props
  const [zones, setZones] = useState<ZoneType[]>([])
  const [regions, setRegions] = useState<RegionType[]>([])

  const [states, setStates] = useState<any[]>([])
  const [middleZones, setMiddleZones] = useState<any[]>([])
  const [primaryZones, setPrimaryZones] = useState<any[]>([])
  const [secondaryZones, handleChangeSecondaryZones] = useState<any[]>([])
  const [errors, setErrors] = useState<any>({
    middleZones: '',
    primaryZones: '',
  })

  const fetchRegionsOptions = async () => {
    const newZones = await getRegionsOptions(states)

    if (newZones?.length) return setRegions(newZones)

    return setRegions([])
  }

  const fetchZonesOptions = async () => {
    const newZones = await getZonesOptions(middleZones)

    if (newZones?.length) return setZones(newZones)

    return setZones([])
  }

  const fetchProviderZones = async () => {
    const providerStates: Array<optionType | any> = []
    const providerMiddleZones: Array<optionType | any> = []
    const providerPrimaryZones: Array<optionType | any> = []
    const providerSecondaryZones: Array<optionType | any> = []

    if (servcPrvdrId !== undefined) {
      const providerZones = await getProviderZones(servcPrvdrId)

      providerZones?.forEach((zone: any) => {
        if (!checkOptionExists(providerStates, zone.stateCd))
          providerStates.push({ value: zone.stateCd, label: zone.stateNm })

        if (!checkOptionExists(providerMiddleZones, zone.servcIntrmdrCd))
          providerMiddleZones.push({ value: zone.servcIntrmdrCd, label: zone.servcIntrmdrNm })

        const option = {
          label: zone.servcRgnNm,
          value: zone.servcRgnCd,
        }

        if (zone?.primaryInd) providerPrimaryZones.push(option)
        if (!zone?.primaryInd) providerSecondaryZones.push(option)
      })
    }

    setStates(providerStates)
    setMiddleZones(providerMiddleZones)
    setPrimaryZones(providerPrimaryZones)
    handleChangeSecondaryZones(providerSecondaryZones)
  }

  useEffect(() => {
    fetchProviderZones()
  }, [])

  useEffect(() => {
    if (middleZones?.length) fetchZonesOptions()
  }, [middleZones])

  useEffect(() => {
    if (states?.length) fetchRegionsOptions()
  }, [states])

  const zonesOptions = useMemo(
    () =>
      zones?.map((zone: any) => ({
        value: zone.cdCity,
        label: zone.nmCity,
      })),
    [zones],
  )

  const regionsOptions = useMemo(
    () =>
      regions?.map((zone: any) => ({
        value: zone.id,
        label: zone.name,
      })),
    [regions],
  )

  const primaryBlockedZones = useMemo(
    () => secondaryZones?.map((zone) => zone.value) || [],
    [zonesOptions, secondaryZones],
  )
  const secondaryBlockedZones = useMemo(
    () => primaryZones?.map((zone) => zone.value) || [],
    [zonesOptions, primaryZones],
  )

  const primaryZonesOptions = useMemo(
    () => zonesOptions.filter((zone) => !primaryBlockedZones.includes(zone.value)),
    [zonesOptions, primaryBlockedZones],
  )

  const secondaryZonesOptions = useMemo(
    () => zonesOptions.filter((zone) => !secondaryBlockedZones.includes(zone.value)),
    [zonesOptions, secondaryBlockedZones],
  )

  const setError = (error: any) =>
    setErrors((prevState: any) => ({
      ...prevState,
      ...error,
    }))

  const removePrimaryZones = (removedOption: optionType | Array<optionType>) => {
    const selectedPrimaryZones = zones.filter((zone) =>
      checkOptionExists(primaryZones, zone.cdCity),
    )

    const newPrimaryZonesOptions = handleRemovedOptions(removedOption, selectedPrimaryZones)

    setPrimaryZones(newPrimaryZonesOptions)
  }

  const removeSecondaryZones = (removedOption: optionType | Array<optionType>) => {
    const selectedSecondaryZones = zones.filter((zone) =>
      checkOptionExists(secondaryZones, zone.cdCity),
    )

    const newSecondaryZonesOptions = handleRemovedOptions(removedOption, selectedSecondaryZones)

    handleChangeSecondaryZones(newSecondaryZonesOptions)
  }

  const removeRelatedRegions = (option: optionType | any) => {
    const removedOption = states.find((state) => checkOptionExists(option, state.value))
    const selectedMiddleZones = regions.filter((zone) => checkOptionExists(middleZones, zone.id))
    const removedRegions: RegionType[] = []

    const newRegions = selectedMiddleZones.filter((zone) => {
      if (zone.uf === removedOption.value) return true

      removedRegions.push(zone)

      return false
    })

    const newMiddleZonesOptions = newRegions.map((zone) => ({
      value: zone.id,
      label: zone.name,
    }))

    const removedZonesOptions = removedRegions.map((zone) => ({
      value: zone.id,
      label: zone.name,
    }))

    setMiddleZones(newMiddleZonesOptions)
    removePrimaryZones(removedZonesOptions)
    removeSecondaryZones(removedZonesOptions)
  }

  const removeRelatedZones = (option: optionType | any) => {
    const removedOption = middleZones.find((zone) => !checkOptionExists(option, zone.value))

    removePrimaryZones(removedOption)
    removeSecondaryZones(removedOption)
  }

  const clearStateData = () => {
    setMiddleZones([])
    setPrimaryZones([])
    handleChangeSecondaryZones([])
    setError({ middleZones: '', primaryZones: '' })
  }

  const clearRegionData = () => {
    setPrimaryZones([])
    handleChangeSecondaryZones([])
    setError({ primaryZones: '' })
  }

  const handleChangeState = (option: optionType | any, action: actionType) => {
    if (action.action === 'clear' || (action.action === 'remove-value' && !option)) clearStateData()
    if (action.action === 'remove-value' && middleZones && option) removeRelatedRegions(option)

    setStates(option)
  }

  const handleChangeRegion = (option: optionType | any, action: actionType) => {
    if (action.action === 'clear' || (action.action === 'remove-value' && !option))
      clearRegionData()

    if (action.action === 'remove-value' && primaryZones && option) removeRelatedZones(option)

    setMiddleZones(option)

    if (errors.middleZones) setError({ middleZones: '' })
  }

  const handleChangePrimaryZones = (option: optionType | any, action: actionType) => {
    if (action.action === 'clear') handleChangeSecondaryZones([])

    setPrimaryZones(option)

    if (errors.primaryZones) setError({ primaryZones: '' })
  }

  return {
    zones,
    errors,
    states,
    regions,
    middleZones,
    primaryZones,
    secondaryZones,
    regionsOptions,
    primaryZonesOptions,
    secondaryZonesOptions,
    handleChangePrimaryZones,
    handleChangeRegion,
    handleChangeState,
    handleChangeSecondaryZones,
    optionLabelByValue,
    checkOptionExists,
    setError,
  }
}

export default ZonesHandler
