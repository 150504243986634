import PropTypes from 'prop-types'
import { Component } from 'react'
import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'
import { WithForm } from '../../../helpers/useForm/withForm'

class OutOfFlowModal extends Component {
  handleRequestApproval = () => {
    const { save, values, servcOrd, index, closeModal, servcOrdEvntStusInd } = this.props

    const occurrence = {
      servcOrdSeq: servcOrd.servcOrdSeq,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: values.flowMessage,
      servcOrdEvntStusInd,
      indStatusOrdemServicoItem: servcOrd.servcOrdStusCd,
      idPrestadorServico: servcOrd.servcPrvdrCd,
      idEspecialistaServico: servcOrd.servcPrvdrAgntCd,
      indStatusOrdemServico: servcOrd.servcOrdStusCd,
    }

    save(index, occurrence)
    closeModal()
  }

  handleEnabledSubmit = () => {
    const {
      fields: { flowMessage },
    } = this.props
    return !!flowMessage.input.value && flowMessage.input.value.length >= 10
  }

  validateField = (e) => {
    const { t } = this.props

    if (/[a-zA-Z]/.test(e) && e.length >= 10) return false

    return t('serviceOrder.litigation.required.field')
  }

  render() {
    const { t, fields, closeModal, register, canSubmit } = this.props

    return (
      <Modal closeModal={closeModal} size='sm'>
        <form>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <legend className='margin-top margin-bottom-small'>
                {t('outOfFlowRequest.title')}
              </legend>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <span>{t('outOfFlowRequest.description')}</span>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className='margin-bottom'>
              <Field {...fields.flowMessage}>
                <MozaicTextArea
                  {...register('flowMessage', {
                    validate: this.validateField,
                    required: true,
                  })}
                  className='resize-none'
                  id='flowMessage'
                  label={<i>{t('outOfFlowRequest.label')}</i>}
                  rows={6}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button-hollow button-hollow-danger button-full align-center'
                type='button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col xs={6} md={6} className='button-align'>
              <button
                className='button button-primary button-full'
                type='button'
                disabled={!canSubmit}
                onClick={this.handleRequestApproval}
              >
                {t('outOfFlowRequest.request')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

OutOfFlowModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  servcOrd: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  servcOrdEvntStusInd: PropTypes.string.isRequired,
  validation: PropTypes.bool,
  register: PropTypes.func,
  canSubmit: PropTypes.bool,
}

const FIELDS = { flowMessage: '' }

export default WithForm(FIELDS)(OutOfFlowModal)

export { OutOfFlowModal }
