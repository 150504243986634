import checkboxDisabled from '../../../../../../../../../../assets/icons/checkbox-unchecked.svg'
import checkboxChecked from '../../../../../../../../../../assets/icons/checkbox-checked.svg'

import CounterContainer from '../../../../../../../../../../components/Counter'

function LGPDCheckBox(props: any) {
  const { isFetching, isChecked, label, warning = false } = props

  const checkBox = isChecked ? checkboxChecked : checkboxDisabled

  if (isFetching) return <div className='lgpd-box mt-2 skeleton' />

  return (
    <>
      <div className='lgpd-box mt-2'>
        <img src={checkBox} alt={label} />
        <span className='lgpd-label'>
          {label}
          <sup>{!isChecked && warning && <CounterContainer text='!' theme='danger' />}</sup>
        </span>
      </div>
    </>
  )
}

export default LGPDCheckBox
