import i18next from 'i18next'
import MozaicIcon from '../../components/MozaicIcon'
import FeedbackError from '../../components/utils/FeedbackError'
import store from '../../redux/store'

import './index.scss'

function Home() {
  const { auth } = store.getState()

  const renderError = (error) => (
    <div className='margin-bottom-double'>
      <FeedbackError message={i18next.t('user-access.not-allowed')} text={error} />
    </div>
  )

  const renderUserInfo = (user) => (
    <section className='content-centralized'>
      {auth.error && renderError(auth.error)}

      <div className='home-user-content'>
        <MozaicIcon icon='AccountProfileView32' size={130} color='primary' />

        <div className='user-info'>
          <span className='user-name'>{user.fullName}</span>
          <span className='user-default-text margin-bottom'>{user.email}</span>
          <span className='user-company'>{user.locationName}</span>
          <span className='user-default-text'>{user.department}</span>
        </div>
      </div>
    </section>
  )

  return <main>{auth?.hasOwnProperty('user') && auth.user && renderUserInfo(auth.user)}</main>
}

export default Home
