import classNames from 'classnames'
import i18next from 'i18next'
import propTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import MozaicButton from '../../../../components/MozaicButton'
import { useProviderAnalysisListContext } from '../../../../contexts/providerAnalysis'
import { months } from '../../../../helpers/periods'

function Title({
  results,
  noHasResults,
  tableLoading,
  period,
  periodType,
  stores,
  storesOptions,
  handleExportProviderList,
}) {
  function getTitle() {
    if (periodType === 'yearly' && (period === 0 || period === '0' || period === ''))
      return i18next.t('page.service.provider.analysis.title.export.yearly.default')

    if (periodType === 'monthly' && (period === 0 || period === '0' || period === ''))
      return i18next.t('page.service.provider.analysis.title.export.monthly.default')

    if (periodType === 'monthly') {
      const splitedPeriod = period.split('/')

      const monthText = months[splitedPeriod[0]]

      const periodText = `${i18next.t(monthText)}/${splitedPeriod[1]}`

      return i18next
        .t(`page.service.provider.analysis.title.export.others`)
        .replace('{0}', periodText)
    }

    return i18next.t(`page.service.provider.analysis.title.export.others`).replace('{0}', period)
  }

  function getStoreText() {
    const selectStoresLength = stores.length
    const allOptionsStoresLength = storesOptions.length

    if (selectStoresLength === allOptionsStoresLength) return i18next.t('branches.all.text')

    if (selectStoresLength === 1) return stores[0].label

    return i18next
      .t('page.service.provider.analysis.subtitle.stores.counter')
      .replace('{0}', selectStoresLength)
  }

  function getSubtitle() {
    const storeText = getStoreText()

    return `${storeText}.`
  }

  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')

  useEffect(() => {
    const title = getTitle()
    const subtitle = getSubtitle()

    setTitle(title)
    setSubtitle(subtitle)
  }, [results])

  if (noHasResults) return <div />

  if (results.length === 0 && !tableLoading) return <div />

  if (results.length === 0 && tableLoading)
    return (
      <div
        id='provider-analysis-list-title-export'
        className={classNames({ skeleton: tableLoading })}
      />
    )

  return (
    <div id='provider-analysis-list-title-export'>
      <section className='labels'>
        <span className='title'>{title || ''}</span>
        <span className='subtitle'>{subtitle || ''}</span>
      </section>
      <MozaicButton variant='bordered' onClick={() => handleExportProviderList()}>
        {i18next.t('option.export')}
      </MozaicButton>
    </div>
  )
}

function TitleWithContext(props) {
  const {
    results,
    noHasResults,
    tableLoading,
    period,
    periodType,
    stores,
    storesOptions,
    handleExportProviderList,
  } = useProviderAnalysisListContext()

  const newProps = useMemo(
    () => ({
      results,
      noHasResults,
      tableLoading,
      period,
      periodType,
      stores,
      storesOptions,
      handleExportProviderList,
    }),
    [results, noHasResults, tableLoading, handleExportProviderList],
  )

  return <Title {...props} {...newProps} />
}

Title.propTypes = {
  results: propTypes.array,
  noHasResults: propTypes.bool,
  tableLoading: propTypes.bool,
  period: propTypes.string,
  periodType: propTypes.string,
  stores: propTypes.array,
  storesOptions: propTypes.array,
  handleExportProviderList: propTypes.func,
}

export default TitleWithContext
