import localforage from 'localforage'
import { useEffect, useState } from 'react'

type ReactState = [any, (newState: any) => void]

export default (key: string, defaultState: any): ReactState => {
  const [state, setState] = useState(defaultState)

  const getStorageState = async () => {
    const storageState = await localforage.getItem(key)

    if (storageState) setState(storageState)
  }

  useEffect(() => {
    getStorageState()
  }, [])

  const handleChange = (newState: any) => {
    localforage.setItem(key, newState)

    setState(newState)
  }

  return [state, handleChange] as ReactState
}
