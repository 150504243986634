import { memo, useState } from 'react'

import i18next from 'i18next'

import { MESSAGE_TYPE } from '../../../../constants/config'
import { getHourAndMinutes } from '../../../../helpers/format'
import { MessageType } from '../../models'

import MozaicIcon from '../../../MozaicIcon'
import AwsImage from '../../../utils/images/AwsImage'
import AwsLightbox from '../../../utils/images/AwsLightbox'

interface SendedMessageProps {
  message: MessageType
}

function SendedMessage(props: SendedMessageProps) {
  const { message } = props

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [base64Img, setBase64Img] = useState('')

  function openLightbox(thumb: string) {
    setBase64Img(thumb)
  }

  function handleRenderImage(imgUrl: string) {
    return (
      <div className='chat-message-image-box'>
        <AwsImage
          className='chat-message-image'
          filePath={imgUrl}
          imageRerefence={(thumb: string) => openLightbox(thumb)}
        />
      </div>
    )
  }

  function renderMessageContent() {
    switch (message.type) {
      case MESSAGE_TYPE.IMAGE:
        return handleRenderImage(message.message)
      default:
        return message.message
    }
  }

  return (
    <div id='sended-message-container' className='tri-right right-bottom'>
      <span className='message-text'>{renderMessageContent()}</span>

      <div>
        <span className='hour-message'>{getHourAndMinutes(message.createdAt)}</span>
        <span>
          {message.isRead ? (
            <>
              <MozaicIcon icon='NotificationAvailable16' className='readed-icon' />
              <MozaicIcon icon='NotificationAvailable16' className='readed-icon' ml={-12} />
            </>
          ) : (
            <MozaicIcon icon='NotificationAvailable16' className='unreaded-icon' />
          )}
        </span>
      </div>

      {lightboxIsOpen && (
        <AwsLightbox
          isOpen={lightboxIsOpen}
          onClose={() => setLightboxIsOpen(!lightboxIsOpen)}
          spinnerColor='green'
          imageCountSeparator={i18next.t('option.of')}
          backdropClosesModal
          showCloseButton={false}
          data={base64Img}
        />
      )}
    </div>
  )
}

export default memo(SendedMessage)
