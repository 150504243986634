import { useMemo } from 'react'
import { indicatorsData } from '../../../../constants/alerts'
import store from '../../../../redux/store'
import Indicator from './components/Indicator'

interface AlertsProps {
  class: string
}

/**
 * @description Container to list all alerts of system
 */

function Alerts({ class: classText }: AlertsProps) {
  const parameters = useMemo(() => store.getState().auth.setupParameters, [])
  const data = useMemo(() => indicatorsData(parameters), [parameters])

  return (
    <div id='alerts-list-container'>
      {(data || []).map(
        (indicator, i) =>
          indicator.render && (
            <Indicator
              showPopover
              index={i}
              key={`indicator-${i}`}
              alert={indicator}
              classText={classText}
            />
          ),
      )}
    </div>
  )
}

export default Alerts
