import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { HusbandRentService } from '../../../../../../../../../../../../models/HusbandRent'
import { useServiceOrderDetailContext } from '../../../../../../../../../../context'

interface HusbandRentContextType {
  currentServices: HusbandRentService[]
  currentServicesDuration: string
  loadCurrentServices: () => Promise<void>
  isFetchingCurrentServices: boolean
}

interface HusbandRentProviderProps {
  readonly children: ReactNode
}

const HusbandRentContext = createContext<HusbandRentContextType | null>(null)

function HusbandRentProvider({ children }: HusbandRentProviderProps) {
  const { serviceOrderBody } = useServiceOrderDetailContext()

  const [currentServices, setCurrentServices] = useState<HusbandRentService[]>([])

  const [isFetchingCurrentServices, setIsFetchingCurrentServices] = useState(false)

  const loadCurrentServices = async (): Promise<void> => {
    setIsFetchingCurrentServices(true)
    setIsFetchingCurrentServices(false)
  }

  useEffect(() => {
    if (serviceOrderBody?.servcOrdSeq) loadCurrentServices()
  }, [serviceOrderBody])

  useEffect(() => {
  }, [currentServices])

  const contextValue = useMemo(
    () => ({
      currentServices,
      loadCurrentServices,
      isFetchingCurrentServices,
    }),
    [
      currentServices,
      loadCurrentServices,
      isFetchingCurrentServices,
    ],
  )

  return <HusbandRentContext.Provider value={contextValue}>{children}</HusbandRentContext.Provider>
}

const useHusbandRentContext = () => useContext(HusbandRentContext)

export { HusbandRentContext, HusbandRentProvider, useHusbandRentContext }
