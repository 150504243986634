import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PopoverInfo from '../../../../../../components/PopoverInfo'
import { ALERTS_TYPES as alertTypes } from '../../../../../../constants/config'
import { useAdvancedSearchContext } from '../../../../../../contexts/advancedSearch'
import { linkServiceOrdersFilters } from '../../../../../../redux/actions/serviceOrder'
import CPIndicator from '../../../../entities/Indicator'

function Indicator({ filters, showPopover, classText, alert, index }) {
  const { handleSubmitDefault } = useAdvancedSearchContext()
  const Indicator = useMemo(() => new CPIndicator(alert, filters), [alert, filters])

  const renderAfterFetch = Indicator.alert?.renderAfterFetch
  const indicatorInfo = Indicator.indicator
  const loading = Indicator.loading

  const currentIndicatorType = alertTypes[Indicator.id]
  const popoverContent = i18next.t(`cockpit.popover.${currentIndicatorType}.subtitle.text`)
  const popoverTitle = i18next.t(`cockpit.popover.${currentIndicatorType}.title.text`)
  const hasPopoverBody = popoverTitle !== '' || popoverContent !== ''

  if (renderAfterFetch && !renderAfterFetch(indicatorInfo)) {
    return <></>
  }

  return (
    <section
      id='alert-indicator'
      className={`alert-indicator alert-indicator-${index} ${classText}`}
      onClick={() => handleSubmitDefault(Indicator.parsedFilter)}
    >
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <header>
            <span className='labels'>
              <span className='title'>{Indicator.title}</span>
              {showPopover && hasPopoverBody && (
                <PopoverInfo
                  title={popoverTitle}
                  content={popoverContent}
                  iconWidth={12}
                  iconHeight={12}
                  iconColor='#1D1D1B'
                />
              )}
            </span>
            {Indicator.subtitle && <span className='subtitle'>{Indicator.subtitle}</span>}
          </header>
          <section className='quantity'>{indicatorInfo}</section>
        </>
      )}
    </section>
  )
}

Indicator.propTypes = {
  filters: PropTypes.object,
  alert: PropTypes.object,
  showPopover: PropTypes.bool,
  classText: PropTypes.string,
  index: PropTypes.number,
}

const mapStateToProps = ({ auth, newCockpitReducer }) => ({
  auth,
  filters: newCockpitReducer.filters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ linkServiceOrdersFilters }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Indicator)

function SkeletonLoader() {
  return (
    <>
      <header>
        <span className='labels'>
          <span className='title skeleton skeleton-title' />
        </span>
        <span className='subtitle skeleton skeleton-subtitle' />
      </header>
      <section className=' quantity skeleton skeleton-quantity' />
    </>
  )
}
