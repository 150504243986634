import { TextArea, ITextAreaProps, Field, IFieldProps } from '@mozaic-ds/react'

function MozaicTextArea(props: ITextAreaProps & IFieldProps) {
  const { placeholder, value, isValid, isInvalid, readOnly, isDisabled, ...otherProps } = props

  return (
    <Field {...otherProps}>
      <TextArea
        placeholder={placeholder}
        readOnly={readOnly}
        isValid={isValid}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        value={value}
        {...otherProps}
      />
    </Field>
  )
}

export default MozaicTextArea
