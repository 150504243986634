import { ProviderType as Provider } from '../../../../../models/Provider'

export type ProviderType = Provider

export const shifts: any = {
  1: 'M',
  2: 'A',
  3: 'E',
}

export const _shifts: any = {
  M: 1,
  A: 2,
  E: 3,
}

export type optionType = {
  value: any
  label: string
}

export type InstallerType = {
  value: string
  label: string
  disabled: boolean
}

export enum DistributeModeOptions {
  bid = 'bid',
  matchmaking = 'matchmaking',
  manual = 'manual',
}

export interface Reason {
  actvOrdEvntTypRsnInd: string
  lastUpdtTs: string
  lastUpdtUserCd: string
  servcOrdEvntTyp: number
  servcOrdEvntTypDesc: string
  servcOrdEvntTypRsnDesc: string
  servcOrdEvntTypRsnDetail: string
  servcOrdEvntTypRsnId: number
}
