import _ from 'lodash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { renderToastSuccessMsg, renderToastWarn } from '../../helpers/ToastUtils'
import { acceptanceSalesConditions } from '../../helpers/serviceOrderUtils'
import {
  getServiceOrder,
  getShortLink,
  sendMsgTermsAcceptance,
} from '../../redux/actions/serviceOrder'
import { fetchPdvs } from '../../services/Pdvs'
import { downloadFile } from '../../services/amazonAws/DownloadFile'
import { createServcOrd, getPyxisInformations } from '../../services/createServiceOrder'
import Modal from '../ServiceOrder/components/qrcode/Modal'
import Form from './components/EditForm'

class CreateServiceOrder extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      openModal: false,
      servcOrdSeq: null,
      createdOrders: '',

      pyxisInformations: {},
      pyxisError: null,

      pdvRecords: {
        pagination: {},
        records: [],
      },
    }
  }

  fetchPyxisInformations = (token, values) => {
    getPyxisInformations(token, values)
      .then((data) => this.setState({ pyxisInformations: data }))
      .catch((error) => this.setState({ pyxisError: error }))
  }

  componentDidMount() {
    this.checkPdvRequest()
  }

  checkPdvRequest() {
    const { params } = this.props

    const servcRqstCd = params && params.servcRqstCd ? params.servcRqstCd : null

    if (servcRqstCd) {
      fetchPdvs({ servcRequestCd: servcRqstCd })
    }
  }

  componentDidUpdate(prevProps) {
    const { sendMsgTermsSuccess, sendMsgTermsError, t } = this.props
    const { servcOrdError, pyxisError } = this.state

    if (servcOrdError && servcOrdError !== prevProps.servcOrdError) {
      renderToastWarn(servcOrdError)
    } else if (pyxisError && pyxisError !== prevProps.pyxisError) {
      renderToastWarn(pyxisError)
    }

    if (sendMsgTermsSuccess && sendMsgTermsSuccess !== prevProps.sendMsgTermsSuccess) {
      renderToastSuccessMsg(t('send.msg.terms.success'))
    }

    if (sendMsgTermsError && sendMsgTermsError !== prevProps.sendMsgTermsError) {
      renderToastWarn(sendMsgTermsError)
    }
  }

  callbackSuccess = (data) => {
    const {
      auth: { user, setupParameters },
      getShortLink,
      getServiceOrder,
    } = this.props

    if (setupParameters.acceptanceSalesConditions) {
      getServiceOrder(user.dsToken, { servcOrdCd: data.sourceOrderSeq }).then((data = []) => {
        if (acceptanceSalesConditions(data[0])) {
          const { vaSlsOrdNr } = data[0]
          const { encodedVaSlsOrdNr } = data[0]
          const { plntCd } = data[0]
          const { encodedPlntCd } = data[0]
          const { servcOrdSeq } = data[0]
          getShortLink(user.dsToken, {
            path: `terms-acceptance/${encodedVaSlsOrdNr}/${encodedPlntCd}`,
          }).then(() => {
            this.setState({
              openModal: true,
              vaSlsOrdNr,
              plntCd,
              servcOrdSeq,
            })
          })
        }
      })
    }
  }

  closeModal = () => {
    this.setState({ openModal: false })
  }

  renderModal = () => {
    const { t, qrcode } = this.props

    const { openModal, servcOrdSeq, vaSlsOrdNr, createdOrders, plntCd } = this.state
    if (!openModal) {
      return
    }

    return (
      <Modal
        {...qrcode}
        size='sm'
        ordersCode={createdOrders}
        servcOrdSeq={servcOrdSeq}
        vaSlsOrdNr={vaSlsOrdNr}
        plntCd={plntCd}
        closeModal={this.closeModal}
        t={t}
        sendMsg={this.handleSendMsg}
      />
    )
  }

  handleSendMsg = (vaSlsOrdNr, plntCd) => {
    const {
      auth: { user },
      sendMsgTermsAcceptance,
    } = this.props

    sendMsgTermsAcceptance(user.dsToken, { vaSlsOrdNr, plntCd }).then(() => {
      this.closeModal()
    })
  }

  async handleSubmit(values) {
    const {
      auth: { user },
    } = this.props

    return createServcOrd(user.dsToken, values, this.callbackSuccess)
  }

  handleDownloadFile = (fileName) => {
    downloadFile(fileName, fileName)
  }

  render() {
    const {
      t,
      auth: { user },
      dispatch,
      auth,
      params,
    } = this.props

    const { pyxisInformations, pyxisError, pdvRecords } = this.state

    const initialValues = {
      customerFiscalId: '',
      customerName: '',
      customerEmail: '',
      customerPhoneNumber: '',
      customerPhoneNumber2: '',
      customerPhoneNumber3: '',
      customerAddressCountry: '',
      customerAddressState: '',
      customerAddressCity: '',
      customerDistrict: '',
      customerAddressStreetName: '',
      customerAddressStreetNumber: '',
      customerAddressPostalCode: '',
      issuerPlant: '',
      serviceOrderNumber: '',
      sourceOrderNumber: '',
      value: '',
      scheduleDate: '',
      scheduleShift: '',
    }

    const servcRqstCd = params && params.servcRqstCd ? params.servcRqstCd : null

    return (
      <section>
        <BreadcrumbBar title={t('lmi.createServcOrd')} />

        <section className='container-fluid'>
          <Form
            t={t}
            user={user}
            auth={auth}
            dispatch={dispatch}
            pdvRequest={!_.isEmpty(pdvRecords) ? pdvRecords[0] : null}
            pyxisError={pyxisError}
            servcRqstCd={servcRqstCd}
            initialValues={initialValues}
            pyxisInformations={pyxisInformations}
            getPyxisInformations={this.fetchPyxisInformations}
            downloadFile={this.handleDownloadFile}
            onSubmit={this.handleSubmit}
          />
        </section>

        {this.renderModal()}
      </section>
    )
  }
}

CreateServiceOrder.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  pyxisInformations: PropTypes.object,
  pyxisError: PropTypes.object,
  getShortLink: PropTypes.func.isRequired,
  sendMsgTermsAcceptance: PropTypes.func.isRequired,
  qrcode: PropTypes.object,
  getServiceOrder: PropTypes.func.isRequired,
  sendMsgTermsSuccess: PropTypes.object,
  sendMsgTermsError: PropTypes.object,
  params: PropTypes.object,
  servcOrdError: PropTypes.string,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  qrcode: state.serviceOrderReducer.qrcode,
  sendMsgTermsSuccess: state.serviceOrderReducer.qrcode.sendMsg.success,
  sendMsgTermsError: state.serviceOrderReducer.qrcode.sendMsg.error,
})

const CreateServiceOrderTranslated = withTranslation()(CreateServiceOrder)

export default connect(mapStateToProps, {
  getShortLink,
  sendMsgTermsAcceptance,
  getServiceOrder,
})(CreateServiceOrderTranslated)
