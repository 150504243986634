import PropTypes from 'prop-types'
import { Component } from 'react'
import MozaicTextArea from '../../../components/MozaicTextArea'
import Row from '../../../components/utils/Row'

class ServiceOrderAddOccurrence extends Component {
  constructor(props) {
    super(props)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(e) {
    const { sendOccurrence } = this.props
    if (e.key === 'Enter') {
      sendOccurrence(e)
    }
  }

  componentWillUnmount() {
    const { setFormValue } = this.props
    setFormValue({ descOcorOrdemServico: '' })
  }

  render() {
    const { t, closeModal, sendOccurrence, register } = this.props

    return (
      <form onSubmit={sendOccurrence}>
        <input id='servcOrdSeq' type='hidden' {...register('servcOrdSeq')} />

        <Row className='padding lmi-modal-header'>
          <span>{t('serviceOrders.newOccurrence')}</span>
        </Row>

        <Row className='os-modal-content padding padding-bottom-small'>
          <MozaicTextArea
            {...register('descOcorOrdemServico')}
            placeholder={t('serviceOrders.newOccurrencePlaceholder')}
          />
        </Row>

        <Row className='text-align-right padding padding-top-small'>
          <button
            className='button button-text-only margin-right-double danger'
            onClick={closeModal}
          >
            {t('serviceOrders.cancelRegisterOccurrence')}
          </button>

          <button type='submit' className='button button-primary'>
            {t('serviceOrders.registerOccurrence')}
          </button>
        </Row>
      </form>
    )
  }
}

ServiceOrderAddOccurrence.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendOccurrence: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  register: PropTypes.func,
  setFormValue: PropTypes.func,
}

export default ServiceOrderAddOccurrence
