import PropTypes from 'prop-types'
import classNames from 'classnames'

function FeedbackSuccess({ message, className, text }) {
  const componentClass = classNames({ [className]: className })

  return (
    <div className={componentClass}>
      <div className='feedback'>
        <div className='feedback-title'>{message}</div>

        {text && <div>{text}</div>}
      </div>
    </div>
  )
}

FeedbackSuccess.propTypes = {
  message: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
}

export default FeedbackSuccess
