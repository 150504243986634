import { useEffect, useMemo, useState } from 'react'
import { GCloudImageRef } from '../../services/firestore/gCloudStorage/GCloudImageRef'
import { GCloudFileRef } from '../../services/firestore/gCloudStorage/GCloudFileRef'

import MozaicIcon from '../MozaicIcon'
import { Flex, Loader, Text } from '@mozaic-ds/react'
import MozaicButton from '../MozaicButton'

import './index.scss'

export type File = GCloudFileRef | GCloudImageRef

type PhotoV2Props = {
  file: File
  onClick?: () => void
  onDeleteRequest: (file: File) => void
  canDowload?: boolean
  canDelete?: boolean
}

function GCloudFileLegacy({
  file,
  onClick,
  onDeleteRequest,
  canDowload = true,
  canDelete = true,
}: PhotoV2Props) {
  const [url, setUrl] = useState('')

  const isImg = file instanceof GCloudImageRef

  useEffect(() => {
    file.getDownloadURL().then((url) => setUrl(url))
  }, [file.downloadUrl])

  const { title } = useMemo(() => file.getFileInfo(), [file.attachment])

  return (
    <div className='photo-legacy'>
      <div className='photo-v2__image' onClick={onClick}>
        <div className='thumb'>
          {!url && <Loader />}
          {url && isImg && <img src={url} />}
          {url && !isImg && <MozaicIcon icon='Pdf64' className='icon-pdf' size={64} />}
          {url && (
            <>
              {/* @ts-ignore Ignoring because of the `title` prop is not in the `Text` component props, but it works */}
              <Text className='title' title={title}>
                {title}
              </Text>
            </>
          )}
        </div>
      </div>

      <Flex justifyContent={canDelete ? 'space-between' : 'flex-end'} paddingTop='mu050'>
        {canDelete && (
          <MozaicButton
            variant='bordered'
            theme='danger'
            size='s'
            onClick={onDeleteRequest ? () => onDeleteRequest(file) : undefined}
          >
            <MozaicIcon size={24} color='danger' icon='PublishTrashbin24' />
          </MozaicButton>
        )}

        {canDowload && (
          <MozaicButton variant='bordered' theme='primary' size='s' onClick={() => file.saveAs()}>
            <MozaicIcon size={24} color='green' icon='DownloadApp24' />
          </MozaicButton>
        )}
      </Flex>
    </div>
  )
}

export default GCloudFileLegacy
