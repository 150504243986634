import i18next from 'i18next'
import { useEffect, useState } from 'react'
import User from '../../../../../../../core/User'

import {
  getServiceOrderAttachments,
  ServiceOrderAttachment,
} from '../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderAttachments'

import { toast } from '@leroy-merlin-br/backyard-react'
import Accordeon from '../../../../../../../components/Accordeon'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import OutlineButton from '../../../../../../../components/OutlineButton'
import { downloadFile } from '../../../../../../../services/amazonAws/DownloadFile'
import { useServiceOrderDetailContext } from '../../../../../context'
import AttachmentDeleteModal from '../AttachmentDeleteModal'
import AttachmentUploaderModal from '../AttachmentUploaderModal'
import LightBox from '../LightBox'

type Document = ServiceOrderAttachment

export default function Documents() {
  const { servcOrdCd } = useServiceOrderDetailContext()

  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const [documents, setDocuments] = useState<Document[]>([])
  const [documentsAlreadyLoaded, setDocumentsAlreadyLoaded] = useState(false)

  const [lightBoxIndex, setLightBoxIndex] = useState<number | null>(null)
  const [showAttachmentUploaderModal, setShowAttachmentUploaderModal] = useState(false)

  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null)

  const accordeonProps = {
    header: {
      title: i18next.t('serviceOrder.attachments.documents.title'),
      icon: <MozaicIcon icon='Pdf32' size={23} ml={-3} mr={-4} mt={1} />,
    },
  }

  const hasAccess75 = accessFunctionList?.includes(75)
  const canShowLightBox = !!documents.length && lightBoxIndex !== null

  const getDocuments = async () => {
    setDocumentsAlreadyLoaded(false)
    setDocuments([])

    try {
      const documentList = await getServiceOrderAttachments(servcOrdCd, 'pdf')

      setDocuments(documentList)
      setDocumentsAlreadyLoaded(true)
    } catch (error: any) {
      const errorMsg =
        error?.message?.dsMessage || i18next.t('serviceOrder.attachments.documents.fetchError')
      toast.error('', errorMsg, '')
    }
  }

  useEffect(() => {
    if (servcOrdCd && !documents.length) getDocuments()
  }, [servcOrdCd])

  const getDocumentName = (d: Document) => d.servcOrdItemAtchmtDesc

  const getDocumentType = (d: Document) => d.atchmtDocmntTypDesc

  const getDocumentCreatedAt = (d: Document) => d.creatTs

  const getDocumentUser = (d: Document) =>
    d.lastUpdtUserCd
      ? `${i18next.t('serviceOrder.attachments.documents.user')}: ${d.lastUpdtUserCd}`
      : ''

  const getDocumentData = (d: Document) =>
    `data:application/pdf;base64,${d.servcOrdAtchmtDcmntIdNr}`

  const getExtension = (d: Document) => d.fileTyp

  const handleCloseLightBox = () => {
    setLightBoxIndex(null)
  }

  const handleCloseAttachmentUploaderModal = (attachmentIsUploaded?: boolean) => {
    attachmentIsUploaded && getDocuments()
    setShowAttachmentUploaderModal(false)
  }

  const handleCloseAttachmentDeleteModal = (attachmentIsDeleted?: boolean) => {
    attachmentIsDeleted && getDocuments()
    setDocumentToDelete(null)
  }

  const handleOpenPreview = (index: number) => {
    setLightBoxIndex(index)
  }

  const handleDocumentDownload = (d: Document) => {
    const docType = d.atchmtDocmntTypDesc ? `${getDocumentType(d)}_` : ''

    downloadFile(d.atchmtCloudShortPathTxt, `${docType}${getDocumentName(d)}`)
  }

  const handleDocumentDelete = (d: Document) => {
    setDocumentToDelete(d)
  }

  return (
    <div id='attachments-documents'>
      <Accordeon {...accordeonProps}>
        {!!documents.length && (
          <div className='documents'>
            {documents.map((d, i) => (
              <div key={d.servcOrdItemAtachmtId} className='document'>
                <div className='document-content'>
                  <div className='thumbnail'>
                    <MozaicIcon icon='Document32' className='icon-document-custom' />
                  </div>

                  <div className='description'>
                    <div className='description__title' title={getDocumentName(d)}>
                      {getDocumentName(d)}
                    </div>
                    <div className='description__type' title={getDocumentType(d)}>
                      {getDocumentType(d)}
                    </div>
                    <div className='description__created-at' title={getDocumentCreatedAt(d)}>
                      {getDocumentCreatedAt(d)}
                    </div>
                    <div className='description__user' title={getDocumentUser(d)}>
                      {getDocumentUser(d)}
                    </div>
                  </div>
                </div>

                <div className='document-actions'>
                  <OutlineButton
                    classname='btn-action'
                    iconNode={<MozaicIcon icon='DisplayView32' className='icon-actions-custom' />}
                    onClick={() => handleOpenPreview(i)}
                  />
                  <OutlineButton
                    classname={`download-${d.servcOrdItemAtachmtId} btn-action`}
                    iconNode={<MozaicIcon icon='DownloadApp48' className='icon-actions-custom' />}
                    onClick={() => handleDocumentDownload(d)}
                  />
                  <OutlineButton
                    classname={`delete-${d.servcOrdItemAtachmtId} btn-action`}
                    iconNode={
                      <MozaicIcon icon='PublishTrashbin32' className='icon-actions-custom' />
                    }
                    onClick={() => handleDocumentDelete(d)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {!documents.length && documentsAlreadyLoaded && (
          <div className='photos-empty'>
            {i18next.t('serviceOrder.attachments.documents.emptyDocuments')}
          </div>
        )}

        {!documentsAlreadyLoaded && (
          <div className='skeleton-box'>
            {Array.from({ length: 2 }).map((_, i) => (
              <div key={i} className='evaluation-item'>
                <div className='skeleton document' />
                <div className='description'>
                  <div className='skeleton sub1' />
                  <div className='skeleton sub2' />
                  <div className='skeleton sub3' />
                </div>
              </div>
            ))}
          </div>
        )}

        {hasAccess75 && (
          <OutlineButton
            label={i18next.t('serviceOrder.attachments.documents.addDocument')}
            iconNode={<MozaicIcon icon='Document32' className='icon-actions-custom' />}
            onClick={() => setShowAttachmentUploaderModal(true)}
          />
        )}
      </Accordeon>

      {canShowLightBox && (
        <LightBox
          items={documents.map((d) => ({
            src: getDocumentData(d),
            title: getDocumentName(d),
            createdAt: getDocumentCreatedAt(d),
            user: getDocumentUser(d),
            type: getExtension(d) as 'img' | 'pdf',
          }))}
          initialIndex={lightBoxIndex}
          onClose={handleCloseLightBox}
        />
      )}

      {showAttachmentUploaderModal && (
        <AttachmentUploaderModal
          isOpen={showAttachmentUploaderModal}
          onClose={handleCloseAttachmentUploaderModal}
          fileType='pdf'
          allowedFileTypes={[{ mimeType: 'application/pdf', extension: 'pdf' }]}
        />
      )}

      {!!documentToDelete && (
        <AttachmentDeleteModal
          attachment={documentToDelete}
          onClose={handleCloseAttachmentDeleteModal}
          isOpen={!!documentToDelete}
        />
      )}
    </div>
  )
}
