/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react'
import i18next from 'i18next'

import DownloadButton from '../../../../../../../../../../../components/DownloadButton'
import EyeButton from '../../../../../../../../../../../components/EyeButton'

import AwsLightbox from '../../../../../../../../../../../components/utils/images/AwsLightbox'

import { downloadFile } from '../../../../../../../../../../../services/amazonAws/DownloadFile'

import downloadImage from '../../../../../../../../../../../helpers/downloadImage'
import openPdfOnNewTab from '../../../../../../../../../../../helpers/openPdfOnNewTab'

import pdfIcon from '../../../../../../../../../../../assets/images/pdf.png'

export interface ItemReportProps {
  servcOrdCd: number
  servcOrdItemAtchmtDesc: string
  fileTyp: string
  servcOrdAtchmtDcmntIdNr: string
  servcOrdItemAtachmtId: number
  atchmtCloudFullPathTxt: string
  atchmtCloudShortPathTxt: string
  servcOrdItemAtchmtClsfctnCd: number
  servcOrdSeq: number
  creatTs: string
  lastUpdtUserCd: string
  atchmtDocmntTyp: number
  atchmtDocmntTypDesc: string
}

function ItemReport(props: ItemReportProps) {
  const {
    fileTyp,
    servcOrdAtchmtDcmntIdNr,
    atchmtCloudShortPathTxt,
    servcOrdItemAtchmtDesc,
    lastUpdtUserCd,
    creatTs,
  } = props

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [base64Img, setBase64Img] = useState('')
  const isPdfFile = fileTyp?.toLocaleLowerCase().includes('pdf')

  function handleDownloadFile() {
    if (isPdfFile) {
      return downloadFile(atchmtCloudShortPathTxt, atchmtCloudShortPathTxt, atchmtCloudShortPathTxt)
    }

    downloadImage(servcOrdAtchmtDcmntIdNr, servcOrdItemAtchmtDesc)
  }

  function openLightbox(thumb: string) {
    if (isPdfFile) {
      return openPdfOnNewTab(servcOrdAtchmtDcmntIdNr)
    }

    setLightboxIsOpen((prev) => !prev)
    setBase64Img(`data:image;base64, ${thumb}`)
  }

  const resolveImageThumbNail = isPdfFile
    ? pdfIcon
    : `data:image;base64, ${servcOrdAtchmtDcmntIdNr}`

  const lastUpdateUserName =
    lastUpdtUserCd.length > 8 ? i18next.t('options.registration.provider') : lastUpdtUserCd

  return (
    <>
      <div id='item-techinical-report'>
        <div className='report-body'>
          <div className='img-content'>
            <img src={resolveImageThumbNail} alt='thumbnail report' />
          </div>

          <div className='info'>
            <span className='title'>{`${servcOrdItemAtchmtDesc} ` || '-'}</span>
            <span className='type'>{i18next.t('technical.reports.of.the.technical.visit')}</span>
            <span className='photo-date'>{creatTs}</span>
            <span className='sended-by'>{lastUpdateUserName || '-'}</span>
          </div>
        </div>

        <section className='action-buttons'>
          <EyeButton onClickEyeButton={() => openLightbox(servcOrdAtchmtDcmntIdNr)} />
          <DownloadButton onClickDownloadButton={() => handleDownloadFile()} />
        </section>
      </div>

      <AwsLightbox
        isOpen={lightboxIsOpen}
        onClose={() => setLightboxIsOpen(!lightboxIsOpen)}
        spinnerColor='green'
        imageCountSeparator={i18next.t('option.of')}
        backdropClosesModal
        showCloseButton={false}
        data={base64Img}
      />
    </>
  )
}

export default ItemReport
