import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/index'

/**
 * Configura store para produção
 * @param {Object} preloadedState - estado inicial da aplicaçao
 * @return {Object} store
 */
const middleware = applyMiddleware(thunk)

const store = createStore(rootReducer, {}, middleware)

export default store
