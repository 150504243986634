import i18next from 'i18next'
import { memo, useState } from 'react'

import { MESSAGE_TYPE } from '../../../../constants/config'
import { getHourAndMinutes } from '../../../../helpers/format'
import { MessageType } from '../../models'

import AwsImage from '../../../utils/images/AwsImage'
import AwsLightbox from '../../../utils/images/AwsLightbox'

interface ReceivedMessageProps {
  message: MessageType
}

function ReceivedMessage(props: ReceivedMessageProps) {
  const { message } = props

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [base64Img, setBase64Img] = useState('')

  function openLightbox(thumb: string) {
    setBase64Img(thumb)
  }

  function handleRenderImage(imgUrl: string) {
    return (
      <div className='chat-message-image-box' onClick={() => setLightboxIsOpen(!lightboxIsOpen)}>
        <AwsImage
          className='chat-message-image'
          filePath={imgUrl}
          imageRerefence={(thumb: string) => openLightbox(thumb)}
        />
        <div className='chat-message-time-shadow' />
      </div>
    )
  }

  function renderMessageContent() {
    switch (message.type) {
      case MESSAGE_TYPE.IMAGE:
        return handleRenderImage(message.message)
      default:
        return message.message
    }
  }

  return (
    <div id='received-message-container' className='tri-left left-bottom'>
      <span className='message-text'>{renderMessageContent()}</span>
      <div>
        <span className='hour-message'>{getHourAndMinutes(message.createdAt)}</span>
      </div>

      {lightboxIsOpen && (
        <AwsLightbox
          isOpen={lightboxIsOpen}
          onClose={() => setLightboxIsOpen(!lightboxIsOpen)}
          spinnerColor='green'
          imageCountSeparator={i18next.t('option.of')}
          backdropClosesModal
          showCloseButton={false}
          data={base64Img}
        />
      )}
    </div>
  )
}

export default memo(ReceivedMessage)
