import { useReducer } from 'react'

const forceUpdateReducer = (i) => i + 1

function useForceUpdate() {
  const [, forceUpdate] = useReducer(forceUpdateReducer, 0)
  return forceUpdate
}

export default useForceUpdate
