import ReactHtmlParser from 'react-html-parser'
import i18next from 'i18next'
import { SHIFT_CODES } from '../constants/config'

export const ellipsis = (text = '', size = 50, ellipsis = '&#8230;') => {
  if (text.length <= size) return text
  return text.slice(0, size - 1) + ReactHtmlParser(ellipsis)
}

export const nl2br = (text = '') => ReactHtmlParser(text.replaceAll('\n', '<br />'))

export const removeAccentsAndSpecialChars = (text = '') => {
  let newText = text
  const chars = [
    [/ä|æ|ǽ/, 'ae'],
    [/ö|œ/, 'oe'],
    [/ü/, 'ue'],
    [/Ä/, 'Ae'],
    [/Ü/, 'Ue'],
    [/Ö/, 'Oe'],
    [/À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ/, 'A'],
    [/à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª/, 'a'],
    [/Ç|Ć|Ĉ|Ċ|Č/, 'C'],
    [/ç|ć|ĉ|ċ|č/, 'c'],
    [/Ð|Ď|Đ/, 'D'],
    [/ð|ď|đ/, 'd'],
    [/È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě/, 'E'],
    [/è|é|ê|ë|ē|ĕ|ė|ę|ě/, 'e'],
    [/Ĝ|Ğ|Ġ|Ģ/, 'G'],
    [/ĝ|ğ|ġ|ģ/, 'g'],
    [/Ĥ|Ħ/, 'H'],
    [/ĥ|ħ/, 'h'],
    [/Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ/, 'I'],
    [/ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı/, 'i'],
    [/Ĵ/, 'J'],
    [/ĵ/, 'j'],
    [/Ķ/, 'K'],
    [/ķ/, 'k'],
    [/Ĺ|Ļ|Ľ|Ŀ|Ł/, 'L'],
    [/ĺ|ļ|ľ|ŀ|ł/, 'l'],
    [/Ñ|Ń|Ņ|Ň/, 'N'],
    [/ñ|ń|ņ|ň|ŉ/, 'n'],
    [/Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ/, 'O'],
    [/ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º/, 'o'],
    [/Ŕ|Ŗ|Ř/, 'R'],
    [/ŕ|ŗ|ř/, 'r'],
    [/Ś|Ŝ|Ş|Š/, 'S'],
    [/ś|ŝ|ş|š|ſ/, 's'],
    [/Ţ|Ť|Ŧ/, 'T'],
    [/ţ|ť|ŧ/, 't'],
    [/Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ/, 'U'],
    [/ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ/, 'u'],
    [/Ý|Ÿ|Ŷ/, 'Y'],
    [/ý|ÿ|ŷ/, 'y'],
    [/Ŵ/, 'W'],
    [/ŵ/, 'w'],
    [/Ź|Ż|Ž/, 'Z'],
    [/ź|ż|ž/, 'z'],
    [/Æ|Ǽ/, 'AE'],
    [/ß/, 'ss'],
    [/Ĳ/, 'IJ'],
    [/ĳ/, 'ij'],
    [/Œ/, 'OE'],
    [/ƒ/, 'f]'],
    [/`/, ''],
    [/´/, ''],
    [/-/, ''],
  ]

  chars.forEach((char) => {
    newText = newText.replace(char[0], char[1])
  })

  return newText
}

export const getShiftByKey = (key) => {
  switch (key.toUpperCase()) {
    case 'M':
    case 'A':
    case 'E':
      return i18next.t(SHIFT_CODES[key])
    case '':
      return i18next.t('service.portal.all.day')
    default:
      return i18next.t(SHIFT_CODES.M)
  }
}
