import { ServiceExecutionEntity } from '../../../../../../../../../../models/ServiceExecution'

import ServiceData from './ServiceData'
import Quantities from './Quantities'
import Dates from './Dates'
import Provider from './Provider'
import Values from './Values'
import ProductData from './ProductData'
import SaleData from './SaleData'

function ServiceDetails({ service }: { service: ServiceExecutionEntity }) {
  return (
    <div id='services-details'>
      <ServiceData service={service} />
      <Quantities service={service} />
      <Dates service={service} />
      <Provider service={service} />
      <Values service={service} />
      <ProductData service={service} />
      <SaleData service={service} />
    </div>
  )
}

export default ServiceDetails
