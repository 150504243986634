import { useEffect, useState } from 'react'
import i18next from 'i18next'

import MozaicButton from '../../../../../../../../../components/MozaicButton'
import Modal from '../../../../../../../../../components/Modal'

import { useServiceOrderDetailContext } from '../../../../../../../context'
import { updateObservation } from '../../../../../../../../../services/pages/ServiceOrderDetail/UpdateObservation'
import MozaicTextArea from '../../../../../../../../../components/MozaicTextArea'

interface ObservationsModalProps {
  isOpen: boolean
  onSubmit: Function
  onClose: Function
}

function ObservationsModal(props: ObservationsModalProps) {
  const { isOpen, onClose, onSubmit } = props
  const { serviceOrderBody, fetchObservation, observation } = useServiceOrderDetailContext()

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [observationText, setObservationText] = useState<string>('')

  useEffect(() => {
    setObservationText(observation)
  }, [])

  async function handleUpdateObservation() {
    setIsSubmiting(true)

    const params = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq as number,
      servcOrdObs: observationText,
    }

    await updateObservation(params)
    await fetchObservation()

    setIsSubmiting(false)
    onSubmit()
  }

  const hasChanges = observation ? observation !== observationText : !!observationText
  const canSubmit = hasChanges

  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onClose={() => onClose()}
      contentLabel='serviceOrders.observations'
      closeInOverlay={false}
    >
      <div id='observations-modal'>
        <h4>{i18next.t('observation.modal.aux.text')}</h4>

        <MozaicTextArea
          value={observationText}
          label={i18next.t('serviceOrders.observations')}
          placeholder={i18next.t('observations.input.placeholder')}
          onChange={(event) => setObservationText((event.target as HTMLInputElement).value)}
          maxLength={65534}
        />

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => onClose()}>
            {i18next.t('cancel.label')}
          </MozaicButton>
          <MozaicButton
            onClick={() => handleUpdateObservation()}
            isDisabled={!canSubmit || isSubmiting}
          >
            {i18next.t('serviceOrders.confirm')}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}

export default ObservationsModal
