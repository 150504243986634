import i18next from 'i18next'
import moment from 'moment-timezone'
import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { toast } from '@leroy-merlin-br/backyard-react'
import { Flex, Loader, Text } from '@mozaic-ds/react'
import MozaicButton from '../../../../../../../../../components/MozaicButton'
import MozaicRadio from '../../../../../../../../../components/MozaicRadio'
import MozaicTextArea from '../../../../../../../../../components/MozaicTextArea'
import { approvalMeasurementSheetFile } from '../../../../../../../../../services/orders/approvalMeasurementSheetFile'
import { useServiceOrderDetailContext } from '../../../../../../../context'
import { HistoricOccurrence } from '../../../../../Historic/components/HistoricOccurrences/models/historic-occurrence'

interface FormProps {
  isLoadingMeasurementSheetFiles: boolean
  canEdit: boolean
  lastMvOcurrence: HistoricOccurrence | undefined
  filesWereApproved: boolean | undefined
  filesWereRejected: boolean | undefined
  hasMeasurementSheet: boolean
}

/**
 * @description This component is responsible for rendering the measurement validation form of the service order detail page.
 */

type BodyProps = {
  ocurrence: HistoricOccurrence
  filesWereApproved: boolean | undefined
}

function ApprovedRejectedBody({ ocurrence, filesWereApproved }: BodyProps) {
  const createTs = moment(ocurrence.creatTs)
  const eventDesc = ocurrence.servcOrdItemEvntDesc

  const createTsText = `${createTs.format('L')} - ${createTs.format('LT')}`
  const docsText = i18next.t('documents')
  const statusText = filesWereApproved ? i18next.t('docs.approved') : i18next.t('docs.reproved')
  const motiveText = i18next.t('pdp.reprovedDocument.mail.body.reason')

  return (
    <Text className='subtitle'>
      {ReactHtmlParser(
        `${createTsText} - <b>${docsText} ${statusText}. ${motiveText}</b> ${eventDesc}`,
      )}
    </Text>
  )
}

export function Form({
  isLoadingMeasurementSheetFiles,
  canEdit,
  filesWereApproved,
  filesWereRejected,
  lastMvOcurrence,
  hasMeasurementSheet,
}: FormProps) {
  const [approved, setApproved] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [obs, setObs] = useState('')

  const { servcOrdCd, serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()

  const obsLength = obs.trim().length
  const canSubmit =
    approved !== null && ((!approved && obsLength >= 10 && obsLength <= 255) || approved)
  const showJustify = approved !== null && !approved
  const storeStepFinished = filesWereApproved || filesWereRejected

  const isSaveButtonDisabled = !canSubmit || isLoading || isLoadingMeasurementSheetFiles

  const handleOnClickSave = async () => {
    try {
      setIsLoading(true)

      await approvalMeasurementSheetFile({
        servcOrdSeq: servcOrdCd,
        servcOrdStusCd: serviceOrderBody?.servcOrdStusCd || '',
        measurementSheetMessage: obs,
        measurementSheetApproved: approved,
      })

      toast.success('', i18next.t('evaluate.provider.label.success'), '')
      reloadServiceOrder()
    } catch (error: any) {
      console.error("Error to save measurement's sheet validation", error)
      toast.error('', error?.message?.dsMessage || i18next.t('application.fatal.error'), '')
    } finally {
      setIsLoading(false)
    }
  }

  if (!canEdit) return <Text className='subtitle'>{i18next.t('measurement.wait')}</Text>

  if (storeStepFinished && lastMvOcurrence) {
    return (
      <ApprovedRejectedBody ocurrence={lastMvOcurrence} filesWereApproved={filesWereApproved} />
    )
  }

  const isJustificationTextValid = obsLength >= 10 && obsLength <= 255

  return (
    <Flex direction='column'>
      <Text className='subtitle'>{i18next.t('measuring.furniture.subtitle')}</Text>
      <Flex direction='column' gap='mu100' paddingTop='mu050' paddingBottom='mu150'>
        <MozaicRadio
          label={i18next.t('servc.ord.measurement.form.approved')}
          isChecked={approved !== null ? approved : false}
          onChange={() => setApproved(true)}
          isDisabled={isLoadingMeasurementSheetFiles || !hasMeasurementSheet}
        />

        <MozaicRadio
          label={i18next.t('servc.ord.measurement.form.reproved')}
          isChecked={approved !== null ? !approved : false}
          onChange={() => setApproved(false)}
          isDisabled={isLoadingMeasurementSheetFiles}
        />

        {showJustify && (
          <MozaicTextArea
            rows={3}
            value={obs}
            maxLength={255}
            error={!isJustificationTextValid ? i18next.t('justification.input.hint') : undefined}
            isValid={isJustificationTextValid}
            isInvalid={!isJustificationTextValid}
            onChange={(e: any) => setObs(e.target.value)}
            placeholder={i18next.t('measuring.furniture.justify')}
          />
        )}
      </Flex>

      <MozaicButton
        className='btn-save'
        onClick={handleOnClickSave}
        isDisabled={isSaveButtonDisabled}
      >
        {isLoading ? <Loader size='s' /> : i18next.t('save')}
      </MozaicButton>
    </Flex>
  )
}
