import { createRef, ReactNode, useEffect, useState } from 'react'
import classNames from 'classnames'
import { IconButton } from '@mozaic-ds/react'
import { ContextMenuOption, ContextMenuOptionProps } from './components/ContextMenuOption'

import './index.scss'

/**
 * @description Context Menu for actions
 */

interface ContextMenuProps {
  triggerIcon: ReactNode
  options: ContextMenuOptionProps[]
}

export function ContextMenu({ triggerIcon, options }: ContextMenuProps) {
  const [showContextMenu, setShowContextMenu] = useState(false)
  const contextMenuRef = createRef<HTMLDivElement>()
  const contextMenuOptionsRef = createRef<HTMLDivElement>()

  const handleClick = () => {
    setShowContextMenu(!showContextMenu)
  }

  const calculateOptionsPosition = () => {
    const contextMenuRect = contextMenuRef.current?.getBoundingClientRect()
    const contextMenuRectHeight = contextMenuRect?.height || 0
    const contextMenuRectWidth = contextMenuRect?.width || 0

    const contextMenuOptionsRect = contextMenuOptionsRef.current?.getBoundingClientRect()
    const contextMenuOptionsRectWidth = contextMenuOptionsRect?.width || 0

    return {
      top: (contextMenuRect?.top || 0) + contextMenuRectHeight,
      left: (contextMenuRect?.left || 0) - contextMenuOptionsRectWidth + contextMenuRectWidth + 5,
    }
  }

  const setOptionsPosition = () => {
    const optionsPosition = calculateOptionsPosition()
    contextMenuOptionsRef.current?.setAttribute(
      'style',
      `top: ${optionsPosition.top}px; left: ${optionsPosition.left}px;`,
    )
  }

  useEffect(() => {
    if (!showContextMenu) {
      window.removeEventListener('resize', setOptionsPosition)
      window.removeEventListener('scroll', setOptionsPosition)
      return
    }
    setOptionsPosition()
    window.addEventListener('resize', setOptionsPosition)
    window.addEventListener('scroll', setOptionsPosition)
  }, [showContextMenu])

  const handleClickOutside = (e: any) => {
    if (showContextMenu && contextMenuRef.current && !contextMenuRef.current.contains(e.target)) {
      setShowContextMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [contextMenuRef])

  const className = classNames('context-options', {
    show: showContextMenu,
  })

  return (
    <div id='context-menu' ref={contextMenuRef}>
      <IconButton size='s' theme='primary' variant='bordered' onClick={handleClick}>
        {triggerIcon}
      </IconButton>
      <div className={className} ref={contextMenuOptionsRef}>
        {options.map((option) => (
          <ContextMenuOption
            key={option.label}
            icon={option.icon}
            label={option.label}
            onClick={() => {
              setShowContextMenu(false)
              if (option.onClick) option.onClick()
            }}
            isDisabled={option.isDisabled}
            title={option.title}
          />
        ))}
      </div>
    </div>
  )
}
