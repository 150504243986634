import { useMemo } from 'react'
import i18next from 'i18next'
// @ts-ignore
import Select from 'react-select'
import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../helpers/react-select-backyard-styles'
import { useDistributeContext } from '../../context'
import { optionType, _shifts as shifts } from '../../../../../../../../models/Distribute'
import { useServiceOrderDetailContext } from '../../../../../../context'
import moment from 'moment-timezone'

interface ReasonSelectProps {
  onChange: Function
  value: optionType | null
  selectedPeriod: any
  selectedDate: any
}

function ReasonSelect({ onChange, value, selectedPeriod, selectedDate }: ReasonSelectProps) {
  const { reasons } = useDistributeContext()

  const { serviceOrderBody } = useServiceOrderDetailContext()

  const handleChange = (e: any) => {
    onChange(e)
  }

  const shiftCd = shifts[serviceOrderBody?.idSlotApplication as keyof typeof shifts]
  const currentSchedule = moment(serviceOrderBody?.servcOrdSchdlgTs).startOf('day')

  const disabled = +currentSchedule === +selectedDate && shiftCd === selectedPeriod?.value

  const errorHint = !disabled && !value

  const label = i18next.t('serviceOrder.distribute.reason')
  const errorHintLabel = i18next.t('serviceOrders.reason.select.errorHint')
  const placeholder = i18next.t('serviceOrders.reason.select.placeholder')

  const disabledClass = disabled ? '--disabled' : ''
  const hasContentClass = value ? '--has-content' : ''

  const reasonsOptions = useMemo(
    () =>
      reasons.map((r) => ({
        value: r.servcOrdEvntTypRsnDesc,
        label: r.servcOrdEvntTypRsnDesc,
      })),
    [reasons],
  )

  return (
    <div className={`backyard-select-v2 ${hasContentClass} ${disabledClass}`}>
      <label className='backyard-select-label'>{label}</label>

      <Select
        value={value}
        options={reasonsOptions}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={placeholder}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
      />

      {errorHint && <label className='error-hint'>{errorHintLabel}</label>}
    </div>
  )
}

export default ReasonSelect
