import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'

function Provider({ service }: { service: ServiceExecutionEntity }) {
  const companyNm = service.servcPrvdrTrdNm || '-'
  const providerPhone = '-'
  const installerNm = service.servcPrvdrAgntNm || '-'

  const providerContactLabel = `${i18next.t('provider.setup.nav.contact')} ${i18next.t(
    'options.registration.provider',
  )}`

  return (
    <BodySection title={i18next.t('options.registration.provider')} className='provider-data'>
      <FormField
        className='width-50'
        label={i18next.t('company')}
        value={companyNm}
        color='green'
      />
      <FormField label={providerContactLabel} value={providerPhone} />
      <FormField label={i18next.t('field.name.installer')} value={installerNm} />
    </BodySection>
  )
}

export default Provider
