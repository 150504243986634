import store from '../../redux/store'
import FetchAdapter from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

interface InactiveInstallerParams {
  servcPrvdrAgntId: number
  actvServcPrvdrAgntInd: '0' | '1'
  description: string
  servcPrvdrTmlineEvntDesc: string
}

export const inactiveInstaller = (params: InactiveInstallerParams): Promise<ADEOResponse<any>> =>
  new FetchAdapter(user.currentUser?.dsToken, store.dispatch).post(
    API_URL.INACTIVE_SERVICE_SPECIALIST,
    params,
  )
