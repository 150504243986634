/* eslint-disable react/jsx-no-constructed-context-values */
import i18next from 'i18next'
import moment from 'moment-timezone'
import { createContext, useContext, useEffect, useState } from 'react'
import API_URL from '../../../../../core/Envs/endpoints'
import Api from '../../../../../core/Http/FetchAdapter'
import ServiceOrderStatusType from '../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import store from '../../../../../redux/store'

import { fetchInstallers } from '../../../../../services/pages/ServiceOrderDetail/fetchInstallers'
import { fetchProvidersManualDistribution } from '../../../../../services/pages/ServiceOrderDetail/fetchProvidersManualDistribution'

import {
  ACTV_SERVC_PRVDR_IND_TYPES,
  ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES,
  REASONS_TYPES,
} from '../../../../../constants/config'

import { getReasonsList } from '../../../../../services/pages/ServiceOrderDetail/getReasonsList'
import { _shifts, InstallerType, ProviderType, Reason, shifts } from '../types'

export type DistributeContextType = {
  periods: Array<string>
  allowedDates: Array<any>
  fetchServiceInstallers: Function
  loadProviderOptions: Function
  providers: Array<ProviderType>
  setProviders: Function
  showProvidersHint: boolean
  installers: Array<InstallerType>
  setInstallers: Function
  showInstallersHint: boolean
  reasons: Reason[]
}

export type DistributeProviderType = {
  children: any
  serviceOrder: any
  selectedDate: any
  distributeMode: string
  selectedPeriod: any
  providersOutsideArea: boolean
  setSelectedDate: Function
  setSelectedPeriod: Function
  setProvider: Function
  setInstaller: Function
  show: boolean
}

const DistributeContext = createContext<DistributeContextType | null>(null)

function DistributeProvider(props: DistributeProviderType) {
  const {
    children,
    serviceOrder,
    distributeMode,
    selectedDate,
    selectedPeriod,
    providersOutsideArea,
    setSelectedDate,
    setSelectedPeriod,
    setProvider,
    setInstaller,
    show,
  } = props

  const [allowedDates, setAllowedDates] = useState<Array<any>>([])
  const [periods, setPeriods] = useState<Array<string>>([])
  const [dates, setDates] = useState<Array<any>>([])

  const [providers, setProviders] = useState<Array<ProviderType>>([])
  const [showProvidersHint, setShowProvidersHint] = useState<boolean>(false)

  const [installers, setInstallers] = useState<Array<InstallerType>>([])
  const [showInstallersHint, setShowInstallersHint] = useState<boolean>(false)

  const [reasons, setReasons] = useState<Reason[]>([])

  const currentSchedule = moment(serviceOrder?.servcOrdSchdlgTs).startOf('day')
  const statusCd = serviceOrder?.servcOrdStusCd || ''

  const auth = store.getState().auth

  const STATUS_DISTRIBUIDA = statusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id

  const currentProvider = {
    label: serviceOrder?.servcPrvdrNm,
    value: serviceOrder?.servcPrvdrCd,
  }

  const currentInstaller = {
    label: serviceOrder?.servcPrvdrAgntNm,
    value: serviceOrder?.servcPrvdrAgntCd,
  }

  const currentSlot = {
    label: serviceOrder?.dsSlot,
    value: _shifts[serviceOrder?.idSlotApplication as keyof typeof _shifts],
  }

  const loadReasons = async () => {
    const list: Reason[] = (await getReasonsList()).data
    const filteredReasons = list.filter(
      (r) => r.actvOrdEvntTypRsnInd === '1' && r.servcOrdEvntTyp === REASONS_TYPES.DISTRIBUTE,
    )
    setReasons(filteredReasons)
  }

  const fetchServiceInstallers = async (provider: ProviderType) => {
    if (!provider) return setInstallers([])

    const providerCd = String(provider.value)
    const providerInstallers = await fetchInstallers(serviceOrder, providerCd, selectedDate)

    const parsedInstallers =
      providerInstallers?.map((installer: any) => ({
        value: installer.servcPrvdrAgntId,
        label: installer.servcPrvdrAgntNm,
        disabled: installer.notAvailable,
      })) || []

    setShowInstallersHint(!parsedInstallers.length)
    setInstallers(parsedInstallers)
  }

  const createProviderOptions = (provider: any) => {
    const hasSlots = provider.slotsAvailability !== undefined
    const hasAbsence = provider.absent

    const slotsAvailability = () => {
      if (hasAbsence)
        return `${provider.servcPrvdrTrdNm} ${i18next.t('servcPrvdrTrdNm.has.absence')}`
      if (hasSlots && !provider.slotsAvailability)
        return `${provider.servcPrvdrTrdNm} ${i18next.t('servcPrvdrTrdNm.no.availability.label')}`

      return provider.servcPrvdrTrdNm
    }

    const servcPrvdrTrdNmLabel = !hasSlots
      ? i18next.t('select.noOptionsMessage')
      : slotsAvailability()

    return {
      value: provider.servcPrvdrId,
      label: servcPrvdrTrdNmLabel,
      servcPrvdrTrdNm: provider.servcPrvdrTrdNm,
      slotsAvailability: provider.slotsAvailability,
      absent: provider.absent,
      disabled: !provider.slotsAvailability || hasAbsence,
    } as ProviderType
  }

  const loadProviderOptions = async (
    scheduleDate = selectedDate,
    period = selectedPeriod,
    providersOutsideArea?: boolean,
  ) => {
    if (!scheduleDate || !period) return false

    setProviders([])
    setInstallers([])

    const {
      setupParameters: { enableSelectBranchsOnServcOrder },
    } = auth

    let filters: any = {}
    let providers: Array<any> = []

    const shiftCd = shifts[period.value as keyof typeof shifts]

    const verifyStatus =
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const branchIsSelected =
      serviceOrder.servcPrvdrPlntCd !== undefined &&
      serviceOrder.servcPrvdrPlntCd.length > 1 &&
      enableSelectBranchsOnServcOrder &&
      verifyStatus

    const servcPrvdrMainPlntCd = branchIsSelected
      ? serviceOrder.servcPrvdrPlntCd
      : serviceOrder.plntCd

    filters.actvServcPrvdrInd = ACTV_SERVC_PRVDR_IND_TYPES.ACTIVE
    filters.servcPrvdrAddRgstrnCurrntStepNr = -1
    filters.actvServcPrvdrOrdRecvInd = ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES.ACTIVE
    filters.servcOrdSeq = serviceOrder.servcOrdSeq
    filters.state = serviceOrder.servcAddrStCd
    filters.servcRgnCd = serviceOrder.servcRgnCd

    if (
      serviceOrder.servcOrdStusCd &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_AGENDADA.id
    ) {
      filters = {
        servcPrvdrId: serviceOrder.servcPrvdrCd,
        servcPrvdrNm: serviceOrder.servcPrvdrNm,
      }

      providers = (await fetchProvidersManualDistribution(
        null,
        filters,
        scheduleDate,
        shiftCd,
        providersOutsideArea,
      )) as Array<any>
    } else {
      providers = (await fetchProvidersManualDistribution(
        servcPrvdrMainPlntCd,
        filters,
        scheduleDate,
        shiftCd,
        providersOutsideArea,
      )) as Array<any>
    }

    const providersOptions = providers.map((provider) => createProviderOptions(provider))

    setShowProvidersHint(!providersOptions.length)
    setProviders(providersOptions)
    return true
  }

  const fetchDatesSuccess = async (data: any) => {
    const allowedDates = data.map((date: any) => date.date)

    let dateFormated: any = null

    if (currentSchedule) dateFormated = currentSchedule.format('YYYY-MM-DD')

    const dateSelected = data.find((item: any) => item.date === dateFormated)

    if (!dateSelected) {
      allowedDates.push(dateFormated)
      data.push({
        date: dateFormated,
        slots: [
          {
            idSlotApplication: serviceOrder?.idSlotApplication,
            idSlot: serviceOrder?.idSlot,
            dsSlot: serviceOrder?.dsSlot,
          },
        ],
      })
    }
    setAllowedDates(allowedDates)
    setDates(data)
  }

  const loadCalendarDates = async () => {
    const day = currentSchedule.format('DD')
    const year = currentSchedule.format('YYYY')
    const month = currentSchedule.format('MM')
    const query = {
      dtStart: `${day}/${month}/${year}`,
      idCalendarApplication: serviceOrder?.plntServcCalndrCd,
    }

    try {
      const response = await new Api().get(API_URL.GET_SCHEDULE_DATES, query)
      fetchDatesSuccess(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const parseSlots = (slots: any) =>
    slots.map((slot: any) => ({
      label: slot.dsSlot,
      value: slot.idSlot,
    }))

  const onChangeDate = (date: any) => {
    const dateFormated = moment(date).format('YYYY-MM-DD')
    const { slots } = dates.find((date) => (date.date === dateFormated ? date : false))
    const parsedSlots = parseSlots(slots)

    setPeriods(parsedSlots)
    if (distributeMode === 'manual') loadProviderOptions(date, undefined, providersOutsideArea)
  }

  const onChangePeriod = (period: any) => {
    if (distributeMode === 'manual') loadProviderOptions(selectedDate, period, providersOutsideArea)
  }

  useEffect(() => {
    if (serviceOrder && show) {
      if (STATUS_DISTRIBUIDA) {
        setProvider(currentProvider)
        setInstaller(currentInstaller)
      }
      loadCalendarDates()
      loadProviderOptions()
      loadReasons()
    }
    if (serviceOrder && !show) {
      setProviders([])
      setInstallers([])
    }
  }, [serviceOrder, show])

  useEffect(() => {
    if (dates.length && !selectedDate) setSelectedDate(currentSchedule)
  }, [dates])

  useEffect(() => {
    if (periods.length) {
      if (selectedPeriod) setSelectedPeriod(periods[0])
      if (!selectedPeriod) setSelectedPeriod(currentSlot)
    }
  }, [periods])

  useEffect(() => {
    if (selectedDate) onChangeDate(selectedDate)
  }, [selectedDate])

  useEffect(() => {
    if (selectedPeriod) onChangePeriod(selectedPeriod)
  }, [selectedPeriod])

  return (
    <DistributeContext.Provider
      value={{
        allowedDates,
        periods,
        fetchServiceInstallers,
        loadProviderOptions,
        providers,
        setProviders,
        showProvidersHint,
        installers,
        setInstallers,
        showInstallersHint,
        reasons,
      }}
    >
      {children}
    </DistributeContext.Provider>
  )
}
const useDistributeContext = () => useContext(DistributeContext) as DistributeContextType

export { DistributeContext, DistributeProvider, useDistributeContext }
