import firebase from 'firebase/compat/app'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { USER_TYPE } from '../../../constants/config'
import store from '../../../redux/store'
import CustomerChat from '../../../services/firestore/customerChat/conversation'
import { CustomerChatServiceType } from '../../../services/firestore/customerChat/customerChat'
import { Conversation, MessageProps } from '../models'

function ConversationListHandlers(
  chatRepository: CustomerChatServiceType,
  allStores: any,
  codLocal: any,
) {
  const [conversationList, setConversationList] = useState<Conversation[]>([])
  const [loadingConversations, setLoadingConversations] = useState(false)
  const [searchField, setSearchField] = useState('')

  const chatUsecases = useMemo(() => CustomerChat(chatRepository), [chatRepository])
  const chatReference = useMemo(() => chatRepository.getReferenceChat(), [chatRepository])

  const setupParameters = store.getState().auth.setupParameters

  const { clientChatDaysBefore } = setupParameters

  function getBranchs() {
    if (codLocal) {
      return codLocal.map((codLocalRow: { value: any }) => {
        if (codLocalRow) {
          return codLocalRow.value
        }
      })
    }
    return ['-']
  }

  function handleListElModified(message: firebase.firestore.DocumentData) {
    setConversationList((messages: any) => {
      const index = messages.findIndex(
        (chat: Conversation) => chat.servcOrdCd === message.servcOrdCd,
      )
      const hasMessage = index !== -1

      if (hasMessage) {
        const newMessages = messages
        const modifiedMessage = messages[index]

        newMessages.splice(index, 1)

        modifiedMessage.lastMessage = message.lastMessage
        modifiedMessage.messageHistory = message.messageHistory
        modifiedMessage.solved = message.solved
        return [modifiedMessage, ...newMessages]
      }
      return [message, ...messages]
    })
  }

  function handleListElRemoved() {
    return false
  }

  async function querySearchSlices(slices: number, branchs: any) {
    const orderCdRequests: Array<Promise<firebase.firestore.QuerySnapshot>> = []
    const nameRequests: Array<Promise<firebase.firestore.QuerySnapshot>> = []

    try {
      for (let i = 0; i < slices; i++) {
        const stores = branchs.slice(i * 9, (i + 1) * 9)

        orderCdRequests.push(
          chatReference
            .where('servcOrdCd', '>=', `${searchField}`)
            .where('servcOrdCd', '<=', `${searchField}\uf8ff`)
            .where('plntCd', 'in', stores)
            .get(),
        )

        nameRequests.push(
          chatReference
            .where('custSearchNm', '>=', `${searchField.toUpperCase()}`)
            .where('custSearchNm', '<=', `${searchField.toUpperCase()}\uf8ff`)
            .where('plntCd', 'in', stores)
            .get(),
        )
      }

      return { orderCdRequests, nameRequests }
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

  async function searchMessages() {
    if (searchField) {
      setConversationList([])
      setLoadingConversations(true)

      let collections: Array<firebase.firestore.DocumentData> = []

      const branchs = allStores
      const slices = Math.ceil(branchs.length / 9)

      try {
        const { orderCdRequests, nameRequests } = await querySearchSlices(slices, branchs)

        const filledNameRequests = await Promise.all(nameRequests)
        const filledOrderCdRequests = await Promise.all(orderCdRequests)

        for (let i = 0; i < slices; i++) {
          const nameMessages = filledNameRequests[i].docs
          const orderCdMessages = filledOrderCdRequests[i].docs

          const collection = nameMessages.concat(orderCdMessages)

          collections = collections.concat(collection)
        }

        const messages = collections.map((doc) => doc.data()) as Conversation[]
        const sortedData = await chatUsecases.sortConversations(messages)

        setConversationList(sortedData)
      } catch (error: any) {
        return error
      } finally {
        setLoadingConversations(false)
      }
    }
  }

  function queryListSlices(slices: number, branchs: any, rangedDay: any) {
    const afterDateRequests: Array<Promise<firebase.firestore.QuerySnapshot>> = []
    const notSolvedRequests: Array<Promise<firebase.firestore.QuerySnapshot>> = []

    try {
      for (let i = 0; i < slices; i++) {
        const stores = branchs.slice(i * 9, (i + 1) * 9)

        afterDateRequests.push(
          chatReference
            .where('lastMessage.createdAt', '>=', +rangedDay)
            .where('plntCd', 'in', stores)
            .orderBy('lastMessage.createdAt', 'desc')
            .get(),
        )

        notSolvedRequests.push(
          chatReference
            .where('solved', '==', false)
            .where('plntCd', 'in', stores)
            .orderBy('lastMessage.createdAt', 'desc')
            .limit(100)
            .get(),
        )
      }

      return { afterDateRequests, notSolvedRequests }
    } catch (error: any) {
      return Promise.reject(error)
    }
  }

  async function fetchMessagesList() {
    setConversationList([])
    setLoadingConversations(true)

    let collections: Array<firebase.firestore.DocumentData> = []

    const range = +moment().locale('pt-br').startOf('day').subtract(clientChatDaysBefore, 'days')
    const branchs = getBranchs()
    const slices = Math.ceil(branchs.length / 9)

    try {
      const { afterDateRequests, notSolvedRequests } = await queryListSlices(slices, branchs, range)

      const filledAfterDateRequests = await Promise.all(afterDateRequests)
      const filledNotSolvedRequests = await Promise.all(notSolvedRequests)

      for (let i = 0; i < slices; i++) {
        const afterDateMessages = filledAfterDateRequests[i].docs.map((message) => message.data())
        const notSolvedMessages = filledNotSolvedRequests[i].docs.map((message) => message.data())

        const collection = afterDateMessages.concat(
          notSolvedMessages.filter(
            (message) => !afterDateMessages.some((msg) => msg.servcOrdCd === message.servcOrdCd),
          ),
        )

        collections = collections.concat(collection)
      }

      const messages = collections.filter((doc) => doc.messageHistory?.length) as Conversation[]
      const sortedData = chatUsecases.sortConversations(messages)

      setConversationList(sortedData)
    } catch (error: any) {
      return error
    } finally {
      setLoadingConversations(false)
    }
  }

  function countUnreadMessages(chat: Conversation) {
    return chat.messageHistory.reduce((count: number, message: MessageProps) => {
      const isNotRead = message.isRead === false
      const isCustomerMessage = message.userType === USER_TYPE.CUSTOMER

      // eslint-disable-next-line no-param-reassign
      if (isNotRead && isCustomerMessage) count += 1

      return count
    }, 0)
  }

  function countPendingChat() {
    return conversationList?.reduce((count: number, chat: Conversation) => {
      // eslint-disable-next-line no-param-reassign
      if (chat?.solved === false) count += 1
      // eslint-disable-next-line no-param-reassign
      else if (chat.messageHistory) count += countUnreadMessages(chat)

      return count
    }, 0)
  }

  const handleClearConversationList = () => {
    setConversationList([])
    setLoadingConversations(false)
    setSearchField('')
  }

  const countNoResolvedConversations = useMemo(() => countPendingChat(), [conversationList])

  return {
    handleListElModified,
    handleListElRemoved,
    setSearchField,
    searchMessages,
    fetchMessages: fetchMessagesList,
    handleClearConversationList,
    searchField,
    chatReference,
    conversationList,
    loadingConversations,
    countNoResolvedConversations,
  }
}

export default ConversationListHandlers
