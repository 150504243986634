export const providerCurrentStepNumberOptions = [
  {
    label: 'option.all',
    value: '-1',
  },
  {
    label: 'company.under.review.text',
    value: '0',
  },
  {
    label: 'disapproved',
    value: '1',
  },
  {
    label: 'pending.documents.text',
    value: '2',
  },
  {
    label: 'documents.under.analysis',
    value: '3',
  },
  {
    label: 'store.validation.text',
    value: '4',
  },
  {
    label: 'disapproved.by.the.store',
    value: '5',
  },
  {
    label: 'contract.in.production',
    value: '6',
  },
  {
    label: 'contract.sent.text',
    value: '7',
  },
  {
    label: 'registration.finalized.text',
    value: '8',
  },
]

export const SERVICE_PROVIDER_REGISTRATION_STATUS_OPTIONS = [
  {
    label: 'company.under.review.text',
    value: '0',
  },
  {
    label: 'disapproved',
    value: '1',
  },
  {
    label: 'pending.documents.text',
    value: '2',
  },
  {
    label: 'documents.under.analysis',
    value: '3',
  },
  {
    label: 'store.validation.text',
    value: '4',
  },
  {
    label: 'disapproved.by.the.store',
    value: '5',
  },
  {
    label: 'contract.in.production',
    value: '6',
  },
  {
    label: 'contract.sent.text',
    value: '7',
  },
  {
    label: 'registration.finalized.text',
    value: '8',
  },
]

export const PROVIDER_REGISTRATION_STATUS = {
  COMPANY_UNDER_REVIEW: {
    id: '0',
    label: 'company.under.review.text',
  },
  DISAPPROVED: {
    id: '1',
    label: 'disapproved',
  },
  PENDING_DOCUMENTS: {
    id: '2',
    label: 'pending.documents.text',
  },
  DOCUMENTS_UNDER_ANALYSIS: {
    id: '3',
    label: 'documents.under.analysis',
  },
  STORE_VALIDATION: {
    id: '4',
    label: 'store.validation.text',
  },
  DISAPPROVED_BY_STORE: {
    id: '5',
    label: 'disapproved.by.the.store',
  },
  CONTRACT_IN_PRODUCTION: {
    id: '6',
    label: 'contract.in.production',
  },
  CONTRACT_SENT: {
    id: '7',
    label: 'contract.sent.text',
  },
  REGISTRATION_FINALIZED: {
    id: '8',
    label: 'registration.finalized.text',
  },
}

export const STATUS_OPTIONS = {
  '': 'option.all',
  0: 'company.under.review.text',
  1: 'disapproved',
  2: 'pending.documents.text',
  3: 'documents.under.analysis',
  4: 'store.validation.text',
  5: 'disapproved.by.the.store',
  6: 'contract.in.production',
  7: 'contract.sent.text',
  8: 'registration.finalized.text',
}
