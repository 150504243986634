import i18next from 'i18next'
import { getPages } from '../constants/pagination'
import API_URL from '../core/Envs/endpoints'
import API from '../core/Http/FetchAdapter'
import { renderToastError, renderToastSuccessMsg } from '../helpers/ToastUtils'
import history from '../helpers/history'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'
import store from '../redux/store'

export const fetchServiceProviderAutocomplete = async (
  token,
  filters,
  servcPrvdrMainPlntCd,
  distribution,
  servcOrdSchdlgTs,
  servcOrdSchdlgShiftCd,
  defaultValues = [],
  providersOutsideArea,
) => {
  const query = {
    ...filters,
    servcPrvdrMainPlntCd,
    distribution,
    servcOrdSchdlgTs,
    servcOrdSchdlgShiftCd,
    requestOriginPortal: 'SERVICES',
    providersOutsideArea,
  }

  if (defaultValues.length !== 0) {
    // eslint-disable-next-line no-param-reassign
    defaultValues[0] = { ...defaultValues[0], slotsAvailability: true }
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.FIND_SERVICE_PROVIDERS_AUTOCOMPLETE,
      query,
    )

    return data || []
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchServiceProviderManualDistribution = async (
  token,
  filters,
  servcPrvdrMainPlntCd,
  distribution,
  servcOrdSchdlgTs,
  servcOrdSchdlgShiftCd,
  defaultValues = [],
  providersOutsideArea,
) => {
  const query = {
    ...filters,
    servcPrvdrMainPlntCd,
    distribution,
    servcOrdSchdlgTs,
    servcOrdSchdlgShiftCd,
    requestOriginPortal: 'SERVICES',
    providersOutsideArea,
  }

  if (defaultValues.length !== 0) {
    // eslint-disable-next-line no-param-reassign
    defaultValues[0] = { ...defaultValues[0], slotsAvailability: true }
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.FIND_SERVICE_PROVIDERS_MANUAL_DISTRIBUTION,
      query,
    )

    return data || []
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchServiceProviders = async (token, fields, page = 1) => {
  const pagination = {
    page,
    size: 10,
  }

  const query = {
    ...fields,
    lsServcPrvdrMainPlntCd: Array.isArray(fields?.branchs)
      ? fields.branchs.map((it) => it.value)
      : [],
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data, metadata } = await new API(token, store.dispatch, pagination).post(
      API_URL.SERVICE_PROVIDER_FIND,
      query,
    )

    store.dispatch(getPages(metadata))

    return { data: data || [], query }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getServiceProvider = async (token, index, isFromProvider = false) => {
  const requestData = {
    servcPrvdrId: index,
  }

  if (!isFromProvider) {
    requestData.servcPrvdrOnApproval = 'NOT'
    requestData.requestOriginPortal = 'SERVICES'
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_FIND,
      requestData,
    )

    return data[0]
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const getServiceProviderNew = () => {
  history.push(`/providers/new`)
  window.scrollTo(0, 0)
}

export const viewServiceProvider = (index) => {
  history.push(`/providers/view/${index}`)
  window.scrollTo(0, 0)
}

export const editServiceProvider = (servcPrvdrId) => {
  history.push(`/providers/edit/${servcPrvdrId}`)
  window.scrollTo(0, 0)
}

export const deleteServiceProvider = async (token, value) => {
  store.dispatch(showSpinner())

  const fields = {
    servcPrvdrId: value.servcPrvdrId,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.DELETE_SERVICE_PROVIDER,
      fields,
    )

    renderToastSuccessMsg(i18next.t('serviceProvider.delete.success'))

    return data
  } catch (error) {
    renderToastError(error?.message?.dsMessage)
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const removeProvider = async (token, servcPrvdrId, onRemoveSuccess, index) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrId,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_REMOVE,
      query,
    )

    onRemoveSuccess(index)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const inactiveProvider = async (token, param) => {
  store.dispatch(showSpinner())

  try {
    const { data, message } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_INACTIVE,
      {
        ...param,
        servcPrvdrTmlineEvntDesc: param.description,
      },
    )

    renderToastSuccessMsg(message?.dsMessage)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const activeProviderToReceiveOs = async (token, param) => {
  store.dispatch(showSpinner())

  try {
    await new API(token, store.dispatch).post(
      API_URL.SERVICE_PROVIDER_SET_ACTV_SERVC_PRVDR_ORD_RECV_IND,
      {
        ...param,
        servcPrvdrTmlineEvntDesc: param.description,
      },
    )

    return param.index
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchServiceProviderFiles = async (token, servcPrvdrId) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrId,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).get(API_URL.SERVICE_PROVIDER_FILES, query)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const sendSmsApp = async (token, values) => {
  store.dispatch(showSpinner())

  const query = {
    ...values,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data, message } = await new API(token, store.dispatch).post(API_URL.SEND_SMS_APP, query)

    renderToastSuccessMsg(message?.dsMessage)

    return data
  } catch (error) {
    renderToastError(error?.message?.dsMessage)
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchServiceProviderNameAutocomplete = async (token, partialText, onSuccess) => {
  const params = {
    partialText,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).get(
      API_URL.FIND_SERVICE_PROVIDERS_NAME_AUTOCOMPLETE,
      params,
    )

    if (onSuccess) onSuccess(data)

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const feathServcPrvdrTrdNm = async (token, name, onSuccess) => {
  store.dispatch(showSpinner())

  const params = {
    name,
    requestOriginPortal: 'SERVICES',
  }

  try {
    const { data } = await new API(token, store.dispatch).get(
      API_URL.FIND_SERVC_PRVDR_TRD_NM,
      params,
    )

    if (onSuccess) onSuccess(data)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
