import { Component } from 'react'
import PropTypes from 'prop-types'
import Row from '../../../components/utils/Row'
import Col from '../../../components/utils/Col'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import MozaicText from '../../../components/MozaicText'

class SectionQRCode extends Component {
  render() {
    const { t, openModalQrTerms, openModalQrConformTerms, serviceOrder, auth } = this.props

    const checkSalesConditions =
      serviceOrder &&
      ServiceOrderStatusType.STATUS_PRE_AGENDADA.id === serviceOrder.servcOrdStusCd &&
      auth &&
      auth.user &&
      auth.setupParameters &&
      auth.setupParameters.acceptanceSalesConditions

    const checkConformity =
      serviceOrder &&
      ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id === serviceOrder.servcOrdStusCd &&
      auth &&
      auth.setupParameters &&
      auth.setupParameters.conformity &&
      !!serviceOrder.isConformity

    return (
      (checkSalesConditions || checkConformity) && (
        <Row className='divider-top no-padding-side'>
          <Col xs={4}>
            <h1> {t('section.qr.code.title')} </h1>
            {checkSalesConditions && (
              <div>
                <MozaicText>{t('section.acceptSales.serviceConditions')}</MozaicText>
                <button
                  className='button-hollow button-hollow-primary'
                  onClick={() => openModalQrTerms()}
                >
                  {t('section.acceptQR.btn1')}
                </button>
              </div>
            )}
            {checkConformity && (
              <div>
                <MozaicText>{t('section.acceptQR.complianceTerms')}</MozaicText>
                <button
                  className='button-hollow button-hollow-primary'
                  onClick={() => openModalQrConformTerms()}
                >
                  {t('section.acceptQR.btn2')}
                </button>
              </div>
            )}
          </Col>
        </Row>
      )
    )
  }
}

export default SectionQRCode

SectionQRCode.propTypes = {
  t: PropTypes.func,
  openModalQrTerms: PropTypes.func,
  openModalQrConformTerms: PropTypes.func,
  auth: PropTypes.object,
  serviceOrder: PropTypes.object,
}
