import i18next from 'i18next'

import MozaicButton from '../../../../../../../../../../../components/MozaicButton'

import { optionType } from '../../types'

type FooterButtonsType = {
  selectedDate: any
  selectedPeriod: any
  compareTodayDate: boolean
  justify: string
  handleClose: Function
  handleSubmit: Function
  reason: optionType
  isDisabled: boolean
  sameProvider: boolean | null
}

function FooterButtons(props: FooterButtonsType) {
  const {
    selectedDate,
    selectedPeriod,
    handleClose,
    handleSubmit,
    justify,
    compareTodayDate,
    reason,
    sameProvider,
    isDisabled,
  } = props

  const cancelLabel = i18next.t('absncPerd.cancel')
  const submitLabel = i18next.t('customer.service.order.confirm.button')

  const isFormValid = () => {
    if (!selectedDate) return false
    if (!selectedPeriod) return false
    if (!reason) return false
    if (sameProvider === null) return false

    const parsedJustify = justify.trim()
    const justifyIsValid = parsedJustify.length >= 10

    return compareTodayDate && justifyIsValid
  }

  const disabled = !isFormValid() || isDisabled

  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClose()}>
        {cancelLabel}
      </MozaicButton>
      <MozaicButton isDisabled={disabled} onClick={() => handleSubmit()}>
        {submitLabel}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
