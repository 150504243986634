import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { DatesBoxType } from '../../../models/dates'

export function WithContext(Component: FunctionComponent<DatesBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, handleToggle },
      datesState: {
        createStartDate,
        setCreateStartDate,
        createEndDate,
        setCreateEndDate,
        scheduleStartDate,
        setScheduleStartDate,
        scheduleEndDate,
        setScheduleEndDate,
        executeStartDate,
        setExecuteStartDate,
        executeEndDate,
        setExecuteEndDate,
        finallyStartDate,
        setFinallyStartDate,
        finallyEndDate,
        setFinallyEndDate,
      },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        createStartDate,
        setCreateStartDate,
        createEndDate,
        setCreateEndDate,
        scheduleStartDate,
        setScheduleStartDate,
        scheduleEndDate,
        setScheduleEndDate,
        executeStartDate,
        setExecuteStartDate,
        executeEndDate,
        setExecuteEndDate,
        finallyStartDate,
        setFinallyStartDate,
        finallyEndDate,
        setFinallyEndDate,
        showAllDetails,
        handleToggle,
      }),
      [
        createStartDate,
        createEndDate,
        scheduleStartDate,
        scheduleEndDate,
        executeStartDate,
        executeEndDate,
        finallyStartDate,
        finallyEndDate,
        showAllDetails,
      ],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
