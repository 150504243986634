import { Select } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import MozaicButton from '../../../../components/MozaicButton'
import MozaicRadio from '../../../../components/MozaicRadio'
import MultiSelectClusters from '../../../../components/MultiSelectClusters'
import MultiSelectStore from '../../../../components/MultiSelectStore'
import SelectPeriod from '../../../../components/SelectPeriod'
import { useProviderAnalysisContext } from '../../context'
import { getClustersByStores, getStoresByClusters } from '../Filter/util'

function FilterAdd({ addNewFilter }) {
  const {
    optionsLoaded,
    storesOptions,
    clustersOptions,
    informationOptions,
    filterDefaultState,
  } = useProviderAnalysisContext()

  const [filter, setFilter] = useState(filterDefaultState)
  const [isDefaultState, setDefaultState] = useState(true)
  const [informationToCompare, setInformationToCompare] = useState([])

  const checkDefaultState = () =>
    Object.keys(filterDefaultState).every((key) => {
      if (Array.isArray(filterDefaultState[key])) {
        return filterDefaultState[key].length === filter[key].length
      }

      if (typeof filterDefaultState[key] === 'object') {
        return Object.keys(filterDefaultState[key]).every(
          (key2) => filterDefaultState[key][key2].toString() === filter[key][key2]?.toString(),
        )
      }

      return filterDefaultState[key] === filter[key]
    })

  useEffect(() => {
    setDefaultState(checkDefaultState())
  }, [filter])

  const generateInformationToCompare = (selectedInformation) => {
    switch (selectedInformation) {
      case 'ratingServices':
        return informationOptions.filter((information) => information.value === 'ratingInstallers')
      case 'ratingInstallers':
        return informationOptions.filter((information) => information.value === 'ratingServices')
      default:
        const data = informationOptions.filter(
          (information) =>
            information.value !== 'ratingServices' &&
            information.value !== 'ratingInstallers' &&
            information.value !== filter.data.information,
        )
        data.unshift({ value: '0', label: 'Selecione uma informação' })
        return data
    }
  }

  const restoreInformationToCompare = (newOptions) => {
    const value =
      filter.data.information === 'ratingServices' || filter.data.information === 'ratingInstallers'
        ? newOptions[0]?.value
        : '0'

    setFilter({
      ...filter,
      data: {
        ...filter.data,
        informationToCompare: value,
      },
    })
  }

  useEffect(() => {
    const selectedInformation = filter.data.information
    const newOptions = generateInformationToCompare(selectedInformation)

    setInformationToCompare(newOptions)
    restoreInformationToCompare(newOptions)
  }, [filter.data.information, informationOptions])

  useEffect(() => {
    if (optionsLoaded) {
      setFilter({
        ...filter,
        stores: storesOptions,
        clusters: clustersOptions,
      })
    }
  }, [optionsLoaded])

  const handleChangePeriodType = (value) => {
    setFilter({
      ...filter,
      period: {
        type: value,
        selected: '0',
      },
    })
  }

  const handleChangePeriod = (e) => {
    const value = e.target.value

    setFilter({
      ...filter,
      period: {
        type: filter.period.type,
        selected: value,
      },
    })
  }

  const handleChangeInformation = (e) => {
    const value = e.target.value

    setFilter({
      ...filter,
      data: {
        ...filter.data,
        information: value,
      },
    })
  }

  const handleChangeInformationToCompare = (e) => {
    const value = e.target.value

    setFilter({
      ...filter,
      data: {
        ...filter.data,
        informationToCompare: value,
      },
    })
  }

  const handleChangeStores = (value) => {
    const clusters = getClustersByStores(value, clustersOptions)
    setFilter({
      ...filter,
      stores: value,
      clusters,
    })
  }

  const handleChangeClusters = (value) => {
    const stores = getStoresByClusters(value, storesOptions)
    setFilter({
      ...filter,
      clusters: value,
      stores,
    })
  }

  const handleClearFilters = () => {
    setFilter(filterDefaultState)
  }

  const handleNewFilter = () => {
    addNewFilter(filter)
    handleClearFilters()
  }

  return (
    <div id='provider-analysis-filter'>
      {filter && (
        <section className='content'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-12'>
              <span className='css-fat8q2-label'>{i18next.t('data.aggregation.text')}</span>
              <section className='radio-groups'>
                <MozaicRadio
                  value='yearly'
                  onChange={() => handleChangePeriodType('yearly')}
                  isChecked={filter.period.type === 'yearly'}
                  label={i18next.t('yearly.text')}
                />
                <MozaicRadio
                  value='monthly'
                  onChange={() => handleChangePeriodType('monthly')}
                  isChecked={filter.period.type === 'monthly'}
                  label={i18next.t('monthly.text')}
                />
              </section>
            </div>
            <div className='col-lg-3 col-md-6 col-12 period'>
              <SelectPeriod
                label={i18next.t('select.period.label.text')}
                optionType={filter.period.type}
                value={filter.period.selected}
                placeholder={i18next.t('select.period.placeholder.text')}
                onChange={(e) => handleChangePeriod(e)}
              />
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <Select
                label={i18next.t('select.information.label.text')}
                options={informationOptions}
                value={filter.data.information}
                onChange={(e) => handleChangeInformation(e)}
                className='info-1'
              />
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              {!!informationToCompare.length && (
                <Select
                  label={i18next.t('select.information.compare.label.text')}
                  options={informationToCompare}
                  value={filter.data.informationToCompare}
                  onChange={(e) => handleChangeInformationToCompare(e)}
                  className='info-2'
                />
              )}
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-3 col-md-6 col-12'>
              <MultiSelectStore
                label={i18next.t('branches.text')}
                onChange={(e) => handleChangeStores(e)}
                value={filter.stores}
                options={storesOptions}
                disabled={storesOptions.length === 1 || storesOptions.length === 0}
                hasSelectAll={storesOptions.length > 1}
              />
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <MultiSelectClusters
                label={i18next.t('lmi.cores')}
                onChange={(e) => handleChangeClusters(e)}
                value={filter.clusters}
                options={clustersOptions}
                disabled={clustersOptions.length === 1 || clustersOptions.length === 0}
                hasSelectAll={clustersOptions.length > 1}
              />
            </div>
          </div>

          <div className='row mt-buttons'>
            <div className='col-12 right-buttons'>
              <MozaicButton
                variant='bordered'
                onClick={() => handleClearFilters()}
                isDisabled={isDefaultState}
              >
                {i18next.t('button.label.reset.filters')}
              </MozaicButton>
              <MozaicButton className='ml-4' onClick={() => handleNewFilter()}>
                {i18next.t('button.label.add.filters')}
              </MozaicButton>
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

FilterAdd.propTypes = {
  addNewFilter: PropTypes.func.isRequired,
}

export default FilterAdd
