import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import Col from '../../components/utils/Col'
import ConfirmModal from '../../components/utils/ConfirmModal'
import FeedbackError from '../../components/utils/FeedbackError'
import Grid from '../../components/utils/Grid'
import Notification from '../../components/utils/Notification'
import PushModal from '../../components/utils/PushModal'
import Row from '../../components/utils/Row'
import Spinner from '../../components/utils/Spinner'
import Pagination from '../../components/utils/pagination/Pagination'
import { CALL_AGENT } from '../../constants/config'
import ServcOrdEvntStusType from '../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServcOrdAllStates from '../../enums/ServiceOrder/flux/ServcOrdAllStates'
import ServcOrdFluxType from '../../enums/ServiceOrder/flux/ServcOrdFluxType'
import ServiceOrderStatusType from '../../enums/ServiceOrder/status/ServiceOrderStatusType'
import {
  TOAST_DEFAULT_OPTIONS,
  renderToastSuccessMsg,
  renderToastWarn,
} from '../../helpers/ToastUtils'
import TwilioUtils from '../../helpers/TwilioUtils'
import getQueryVariable from '../../helpers/getQueryVariable'
import history from '../../helpers/history'
import { getOsRoutePrefix } from '../../helpers/serviceOrderUtils'
import { isEmpty } from '../../helpers/validations'
import * as serviceOrderActions from '../../redux/actions/serviceOrder'
import { fetchReportsPost } from '../../services/Reports'
import { downloadFile } from '../../services/amazonAws/DownloadFile'
import { makeCallAnew } from '../../services/makeCallAnew'
import { makeCallFive9 } from '../../services/makeCallFive9'
import * as elasticCoreActions from '../../services/pages/getServiceOrderListElastic'
import { sendOsMail } from '../../services/pages/sendOsMail'
import ApproveOutOfFlowModal from '../ServiceOrderEdit/components/ApproveOutOfFlowModal'
import AssignToMeModal from './components/AssignToMeModal'
import AttachBudgetModal from './components/AttachBudgetModal'
import AttachInspectionModal from './components/AttachInspectionModal'
import AttentionOccurrenceModal from './components/AttentionOccurrenceModal'
import OutOfFlowModal from './components/OutOfFlowModal'
import SalesErrorModal from './components/SalesErrorModal'
import ServiceOrderTable from './components/Table'
import ModalQRCode from './components/qrcode/Modal'
import { WithContext } from './contexts'

const defaultState = {
  record: {},
  error: null,
  isFetching: false,
}

class ServiceOrderListContainer extends Component {
  state = {
    loading: true,
    searched: false,
    term: '',
    advancedSearch: false,
    sendPushModal: false,
    confirmModal: false,
    validation: true,
    action: null,
    message: '',
    params: [],
    properties: {},
    serviceProviderSuggestions: [],
    serviceSpecialistNameSuggestions: [],
    isFirstSearch: true,
    attentionOccurrenceModal: false,
    index: 0,
    servcOrdEvntStusInd: '',
    outOfFlow: false,
    approveOutOfFlow: false,
    selectedRadio: '',
    acceptanceSalesConditions: false,
    conformity: false,
    modalQrConformity: false,
    searchElastic: false,
    salesErrorOS: { servcOrdCd: 0, servcOrdStusCd: 0 },
    attachBudgetOS: 0,
    attachInspectionOS: null,
    showSalesErrorModal: false,
    showAttachBudgetModal: false,
    showAttachInspectionModal: false,
    callAnew: defaultState,
    callFive9: defaultState,
    mail: defaultState,
    hasServiceOrders: false,
  }

  componentDidMount() {
    const { auth, filters } = this.props

    if (auth.setupParameters.callAgent === CALL_AGENT.TWILIO) {
      TwilioUtils.initialize(TwilioUtils.getTwilioAccessTokenUrl(auth.setupParameters)).then(
        (TwilioDevice) => (TwilioDevice ? this.twilioSetupListeners : false),
      )
    }

    this.setState({ current: this.getCurrentPage() })

    if (filters) this.handleSubmitFilter()
  }

  componentWillUnmount() {
    const { pushClear, isBackOrder } = this.props

    pushClear()

    TwilioUtils.destroy()
    isBackOrder(false)
    this.setState({ isFirstSearch: true })
  }

  getCurrentPage = () => {
    const { current } = this.state
    return Number(getQueryVariable('page')) || current || 1
  }

  handleSubmitFilter = () => {
    this.setState({ searchElastic: false })

    const {
      auth: {
        setupParameters: { elasticEnabled },
      },
    } = this.props

    if (elasticEnabled) this.setState({ searchElastic: true })
    this.handleSubmit()
  }

  handleSubmit = () => {
    const { filters, clearServiceOrderResponse } = this.props

    this.setState(
      {
        isEvaluated: false,
        searched: true,
        term: filters?.currentSearchFieldValue,
        advancedSearch: false,
      },
      () => {
        clearServiceOrderResponse()
        this.handleFetchServiceOrders()
      },
    )
  }

  componentDidUpdate(prevProps) {
    const { current, filters } = this.props

    if (current !== prevProps.current) {
      this.updateSearchParamPage(current)
    }

    if (
      (filters && !prevProps.filters) ||
      (filters && Object.keys(filters).some((key) => filters[key] !== prevProps.filters[key])) ||
      current !== prevProps.current
    ) {
      this.handleSubmitFilter()
    }

    const { loading, hasServiceOrders } = this.state

    // Prevents the page from going to the previous page when the search returns no results
    if (!loading && !hasServiceOrders) {
      const hasPageInUrl = getQueryVariable('page')
      const isPageMoreThanOne = this.getCurrentPage() > 1

      if (hasPageInUrl && isPageMoreThanOne) {
        const newPage = this.getCurrentPage() - 1
        this.updateSearchParamPage(newPage)
        this.setState({ current: newPage })
        this.handleFetchServiceOrders(newPage)
      }
    }
  }

  fetchDataAfterMount = () => {
    const { clearServiceOrderResponse } = this.props

    clearServiceOrderResponse()
    this.handleFetchServiceOrders()
  }

  handleFetchServiceOrders = async (pageNumber) => {
    const { searchElastic } = this.state
    const {
      fetchServiceOrdersV2,
      servcOrdListElastic,
      auth: { user },
      filters,
      selectedStores,
    } = this.props

    if (filters) {
      this.setState({ loading: true })

      const page = pageNumber || this.getCurrentPage()

      const stores =
        (filters.lsPlntCd || []).length > 0 ? filters.lsPlntCd : selectedStores.map((s) => s.value)

      try {
        if (searchElastic) {
          const response = await servcOrdListElastic(
            user.dsToken,
            { ...filters, lsPlntCd: stores },
            page,
          )
          this.setState({ hasServiceOrders: response.length > 0 })
        } else {
          const response = await fetchServiceOrdersV2(user.dsToken, filters, page)
          this.setState({ hasServiceOrders: response.length > 0 })
        }
      } catch (error) {
        console.error('Error fetching service orders', error)
      }

      this.restoreScroll()
      this.setState({ loading: false, isFirstSearch: false })
    }
  }

  restoreScroll = () => {
    const { scrollPosition, shouldRestoreScroll, restoreScroll } = this.props

    if (restoreScroll) {
      window.scrollTo(0, scrollPosition)
      shouldRestoreScroll(false)
    }
  }

  getPdf = (servcOrdSeq) => {
    const values = {
      tpReport: 1,
      filters: {
        servcOrdSeq,
      },
    }

    fetchReportsPost(values)
  }

  getServiceOrder = (servcOrdCd) => {
    const { saveScrollPosition, setServiceOrder } = this.props

    saveScrollPosition()
    setServiceOrder({})

    history.push(`${getOsRoutePrefix()}/${servcOrdCd}`)
  }

  backStatus = (index, servcOrdSeq, servcOrdStusCd) => {
    const {
      backStatusWithOccurrence,
      auth: { user },
    } = this.props

    const values = {
      servcOrdSeq,
      servcOrdStusCd,
    }

    backStatusWithOccurrence(user.dsToken, values, index).then(() => {
      this.handleSubmit()
    })
  }

  updateOsStatusMenuShorcut = (index, serviceOrder, servcOrdStusCd) => {
    const {
      updateOsStatusMenuShorcut,
      auth: { user },
      t,
    } = this.props

    const serviceOrderParam = { ...serviceOrder, servcOrdStusCd }

    updateOsStatusMenuShorcut(user.dsToken, serviceOrderParam, index, () =>
      renderToastSuccessMsg(t('serviceOrders.updated')),
    )
  }

  generateAttentionOccurrence = (index, serviceOrder) => {
    const {
      auth: { user },
      generateOccurrenceSimple,
    } = this.props
    generateOccurrenceSimple(user.dsToken, serviceOrder, index).then(() =>
      this.closeAttentionOccurrence(),
    )
  }

  handleAttentionOccurrence = (index, serviceOrder, servcOrdEvntStusInd) => {
    this.setState({
      attentionOccurrenceModal: true,
      serviceOrder,
      index,
      servcOrdEvntStusInd,
    })
  }

  closeAttentionOccurrence = () => {
    this.setState({ attentionOccurrenceModal: false })
  }

  handleDownloadFile = (fileName) => {
    downloadFile(fileName, fileName)
  }

  handleSubmitNewBid = async (ordSeq) => {
    const {
      onSubmitNewBid,
      auth: { user },
    } = this.props
    const bidParams = { servcOrdSeq: ordSeq }

    await onSubmitNewBid(user.dsToken, bidParams)
    this.fetchDataAfterMount()
  }

  handleAssignToMe = async (servcOrd) => {
    this.openModalAssignToMe(servcOrd)
  }

  renderAttentionOccurrenceModal = () => {
    const { serviceOrder, index, attentionOccurrenceModal, servcOrdEvntStusInd, validation } =
      this.state
    const { t, updateOsMenuShorcutError } = this.props

    if (!attentionOccurrenceModal) {
      return
    }
    return (
      <AttentionOccurrenceModal
        t={t}
        closeModal={this.closeAttentionOccurrence}
        servcOrd={serviceOrder}
        save={this.generateAttentionOccurrence}
        index={index}
        validation={validation}
        errorFn={updateOsMenuShorcutError}
        servcOrdEvntStusInd={servcOrdEvntStusInd}
      />
    )
  }

  cancel = (serviceOrder, index, manualOccurrence = false, servcOrdItemEvntDesc) => {
    const {
      auth: { user },
      cancelServiceOrder,
    } = this.props

    const values = {
      ...serviceOrder,
      servcOrdStusCd: ServiceOrderStatusType.STATUS_CANCELADA.id,
      manualOccurrence,
      justificativa: servcOrdItemEvntDesc,
    }

    cancelServiceOrder(user.dsToken, values, index)
  }

  closeOldOrder = (serviceOrder, index, servcOrdItemEvntDesc) => {
    const {
      auth: { user },
      updateOsMenuShorcut,
    } = this.props

    if (serviceOrder.indFluxType === ServcOrdFluxType.FLUX_CLIENT.id) {
      // eslint-disable-next-line no-param-reassign
      serviceOrder = {
        ...serviceOrder,
        servcOrdStusCd: ServcOrdAllStates.FLUX_CLIENT_CLOSED.id,
        notRequestEvaluation: true,
      }
    } else if (serviceOrder.indFluxType === ServcOrdFluxType.FLUX_STORE.id) {
      // eslint-disable-next-line no-param-reassign
      serviceOrder = {
        ...serviceOrder,
        servcOrdStusCd: ServcOrdAllStates.FLUX_STORE_CLOSED.id,
        notRequestEvaluation: true,
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      serviceOrder = {
        ...serviceOrder,
        servcOrdStusCd: ServcOrdAllStates.FLUX_PRVDR_CLOSED.id,
        notRequestEvaluation: true,
      }
    }

    // eslint-disable-next-line no-param-reassign
    serviceOrder = {
      ...serviceOrder,
      manualOccurrence: true,
      actionJustificationOnly: true,
      justificativa: servcOrdItemEvntDesc,
      closeOldServcOrd: true,
    }

    updateOsMenuShorcut(user.dsToken, serviceOrder, index)
  }

  sendMail = (servcOrdSeq) => {
    const {
      auth: { user },
    } = this.props

    const values = {
      servcOrdSeq,
    }

    sendOsMail(user.dsToken, values)
      .then((record) => this.setState({ mail: { ...defaultState, record } }))
      .catch((error) => this.setState({ mail: { ...defaultState, error } }))
  }

  renderNotification = () => {
    const { t } = this.props
    const { isFirstSearch } = this.state

    if (isFirstSearch) {
      return (
        <Notification
          className='notification-warning margin-top'
          message={t('serviceOrders-list-Add.filter.search')}
        />
      )
    }
    return (
      <Notification
        className='notification-warning margin-top'
        message={t('serviceOrders-list.not-found')}
      />
    )
  }

  handleSendPush = (serviceOrder) => {
    this.setState({ sendPushModal: true, serviceOrder })
  }

  closeSendPushModal = () => {
    this.setState({ sendPushModal: false })
  }

  renderPushModal = () => {
    const { sendPushModal, serviceOrder } = this.state
    const { t } = this.props

    if (!sendPushModal) {
      return
    }

    return (
      <PushModal
        t={t}
        closeModal={this.closeSendPushModal}
        idPrestadorServico={serviceOrder.servcPrvdrCd}
        sendPush={this.handlePushRequest}
      />
    )
  }

  handleOutOfFlow = (index, serviceOrder, servcOrdEvntStusInd) => {
    this.setState({
      outOfFlowModal: true,
      serviceOrder,
      index,
      servcOrdEvntStusInd,
    })
  }

  closeOutOfFlowModal = () => {
    this.setState({ outOfFlowModal: false })
  }

  renderOutOfFlowModal = () => {
    const { outOfFlowModal, serviceOrder, index, servcOrdEvntStusInd, validation } = this.state
    const { t } = this.props

    if (!outOfFlowModal) {
      return
    }

    return (
      <OutOfFlowModal
        t={t}
        closeModal={this.closeOutOfFlowModal}
        servcOrd={serviceOrder}
        index={index}
        validation={validation}
        save={this.generateOutOfFlowOccurrence}
        servcOrdEvntStusInd={servcOrdEvntStusInd}
      />
    )
  }

  generateOutOfFlowOccurrence = (index, serviceOrder) => {
    const {
      auth: { user },
      generateOccurrenceSimple,
    } = this.props
    generateOccurrenceSimple(user.dsToken, serviceOrder, index).then(() =>
      this.closeAttentionOccurrence(),
    )
  }

  generateManualDistributionOccurrence = (serviceOrder, index, text) => {
    const {
      auth: { user },
      t,
      generateOccurrenceSimple,
    } = this.props

    generateOccurrenceSimple(
      user.dsToken,
      {
        ...serviceOrder,
        idOrdemServicoItem: 0,
        descOcorOrdemServico: `${t('serviceOrder.manual.distribution')}: ${text}`,
        servcOrdEvntStusInd: ServcOrdEvntStusType.REFUSAL_PROCESS.id,
        indStatusOrdemServicoItem: serviceOrder.servcOrdStusCd,
        idPrestadorServico: serviceOrder.servcPrvdrCd,
        idEspecialistaServico: serviceOrder.servcPrvdrAgntCd,
        indStatusOrdemServico: serviceOrder.servcOrdStusCd,
      },
      index,
    ).then(() => this.closeConfirmModal())
  }

  handleApproveOutOfFlow = (index, serviceOrder) => {
    this.setState({ approveOutOfFlow: true, serviceOrder, index })
  }

  closeApproveOutOfFlowModal = () => {
    this.setState({ approveOutOfFlow: false, selectedRadio: '' })
  }

  handleRadioChange = (e) => {
    const tempVal = e.target.id
    this.setState({
      selectedRadio: tempVal,
    })
  }

  renderApproveOutOfFlowModal = () => {
    const { approveOutOfFlow, serviceOrder, index, servcOrdEvntStusInd, selectedRadio } = this.state

    const { t } = this.props

    if (!approveOutOfFlow) {
      return
    }

    return (
      <ApproveOutOfFlowModal
        t={t}
        closeModal={this.closeApproveOutOfFlowModal}
        servcOrd={serviceOrder}
        index={index}
        save={this.generateApproveOutOfFlowOccurrence}
        servcOrdEvntStusInd={servcOrdEvntStusInd}
        selectedRadio={selectedRadio}
        handleRadioChange={this.handleRadioChange}
      />
    )
  }

  generateApproveOutOfFlowOccurrence = (data, index) => {
    const {
      auth: { user },
      updateOsMenuShorcut,
      generateOccurrenceSimple,
    } = this.props

    if (data.servcOrdEvntStusInd === ServcOrdEvntStusType.APPROVED_OUT_OF_FLOW_REQUEST.id) {
      const serviceOrder = {
        ...data,
        servcOrdStusCd: ServiceOrderStatusType.OUT_OF_FLOW.id,
      }

      updateOsMenuShorcut(user.dsToken, serviceOrder, index)
    } else {
      const occurance = {
        servcOrdSeq: data.servcOrdSeq,
        idOrdemServicoItem: 0,
        descOcorOrdemServico: data.actionJustification,
        servcOrdEvntStusInd: data.servcOrdEvntStusInd,
        indStatusOrdemServicoItem: data.servcOrdStusCd,
        idPrestadorServico: data.servcPrvdrCd,
        idEspecialistaServico: data.servcPrvdrAgntCd,
        indStatusOrdemServico: data.servcOrdStusCd,
      }

      generateOccurrenceSimple(user.dsToken, occurance, index)
    }
  }

  handleConfirmModal = (message, action, params = [], properties = {}) => {
    this.setState({
      confirmModal: true,
      message,
      action,
      params,
      properties,
    })
  }

  closeConfirmModal = () => {
    this.setState({
      confirmModal: false,
      message: null,
      action: null,
      params: [],
      properties: {},
    })
  }

  renderConfirmModal = () => {
    const { confirmModal, action, message, params, properties, validation } = this.state
    const { t, updateOsMenuShorcutError } = this.props

    if (!confirmModal) {
      return
    }

    return (
      <ConfirmModal
        {...properties}
        t={t}
        closeModal={this.closeConfirmModal}
        action={action}
        messageHeader={message}
        params={params}
        validation={validation}
        errorFn={updateOsMenuShorcutError}
      />
    )
  }

  closeModalAssignToMe = () => {
    this.setState({ modalAssignToMe: false })
  }

  openModalAssignToMe = (serviceOrder) => {
    this.setState({ modalAssignToMe: true, serviceOrder })
  }

  renderAssignToMeModal = () => {
    const { serviceOrder, modalAssignToMe } = this.state

    if (!modalAssignToMe) return

    return (
      <AssignToMeModal
        closeModal={this.closeModalAssignToMe}
        serviceOrder={serviceOrder}
        reloadServiceOrder={() => {
          document.body.click()
          this.handleFetchServiceOrders()
        }}
      />
    )
  }

  handlePushRequest = (servcPrvdrCd, servcPrvdrAgntCd, pushText) => {
    const {
      pushRequest,
      auth: { user },
    } = this.props

    this.closeSendPushModal()
    pushRequest(user.dsToken, servcPrvdrCd, servcPrvdrAgntCd, pushText)
  }

  handleMakeCall = (number, servcOrder) => {
    const { auth } = this.props
    const { callAnew } = this.state

    switch (auth.setupParameters.callAgent) {
      case CALL_AGENT.ANEW:
        const dataAnew = {
          agente: auth.setupParameters.anewAgente,
          ramal: auth.setupParameters.anewRamal,
          campanha: auth.setupParameters.anewCampanha,
          telefone: `0${number}`,
        }

        makeCallAnew(dataAnew)
          .then((data) => this.setState({ callAnew: { ...callAnew, record: data } }))
          .catch((error) => this.setState({ callAnew: { record: {}, error } }))
        break

      case CALL_AGENT.FIVE9:
        const dataFive9 = {
          number: `01155${number}`,
          campaignId: auth.setupParameters.five9Campanha,
          checkDnc: auth.setupParameters.five9CheckDnc,
          callbackId: '',
        }

        makeCallFive9(dataFive9)
          .then((data) => this.setState({ callFive9: { ...callAnew, record: data } }))
          .catch((error) => this.setState({ callFive9: { record: {}, error } }))
        break

      case CALL_AGENT.TWILIO:
        TwilioUtils.call(`${number}`, servcOrder)
        break

      default:
        break
    }
  }

  twilioSetupListeners = (TwilioDevice) => {
    const {
      t,
      recordCall,
      auth: { user },
    } = this.props

    console.log(TwilioDevice)

    let callToast = null
    let calledNumber = null

    function getCallToast() {
      function getMessageWithNumber(label) {
        return t(label).replace('{1}', calledNumber)
      }

      return (
        <div>
          {getMessageWithNumber('twilio.connectedTo')}
          <button
            type='button'
            className='button button-danger margin-top-small'
            onClick={TwilioDevice.disconnectAll}
          >
            {t('twilio.hangUp')}
          </button>
        </div>
      )
    }

    TwilioDevice.addListener('error', (error) => {
      renderToastWarn(`(${error.code}) ${error.message}`)
    })

    TwilioDevice.addListener('connect', (data) => {
      calledNumber = data.customParameters.get('To')
      callToast = toast.success(getCallToast(), {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        draggablePercent: 0,
        onClose: () => {
          toast.info(getMessageWithNumber('twilio.disconnectedTo'), TOAST_DEFAULT_OPTIONS)
        },
      })
    })

    TwilioDevice.addListener('disconnect', (data) => {
      const { customParameters } = data
      recordCall(user.dsToken, {
        servcOrdSeq: customParameters.get('servcOrdSeq'),
        dstntnPhonNr: customParameters.get('To'),
        phonCallngToknCaTxt: data.parameters.CallSid,
      })

      if (callToast) {
        toast.dismiss(callToast)
      }
    })
  }

  handleSelectWorksite = (servcOrdWorksiteNumber) => {
    const { handleSubmitDefault } = this.props

    handleSubmitDefault({
      currentSearchField: 'servcOrdWorksiteNumber',
      currentSearchFieldValue: servcOrdWorksiteNumber.toString(),
    })
  }

  renderErrorMakeCallFive9() {
    const { t } = this.props
    const { callFive9 } = this.state

    if (callFive9.error) {
      return (
        <Grid fluid>
          <Row className='padding margin-bottom-small'>
            <Col xs={12}>
              <FeedbackError
                message={
                  callFive9.error.httpCode !== null
                    ? t('serviceOrder.call.error')
                    : callFive9.error.Fault.faultstring
                }
              />
            </Col>
          </Row>
        </Grid>
      )
    }
  }

  renderErrorMakeCallAnew() {
    const { t } = this.props
    const { callAnew } = this.state

    if (callAnew.error) {
      return (
        <Grid fluid>
          <Row className='padding margin-bottom-small'>
            <Col xs={12}>
              <FeedbackError
                message={
                  callAnew.error.httpCode !== null
                    ? t('serviceOrder.call.error')
                    : callAnew.error.Fault.faultstring
                }
              />
            </Col>
          </Row>
        </Grid>
      )
    }
  }

  renderMessageMakeCall() {
    const { t } = this.props
    const { callAnew, callFive9 } = this.state

    if (callAnew.record.httpCode === 200 || callFive9.record.httpCode === 200) {
      return (
        <div className='margin-top margin-bottom notification notification-primary'>
          <span className='margin-left'>{t('serviceOrder.call.success')}</span>
        </div>
      )
    }
  }

  renderFeedbackNotification = (obj, fromDuplication) => {
    const { t } = this.props

    if (obj?.error) {
      return (
        <div className='margin-top margin-bottom notification notification-warning'>
          {obj.error.message.dsMessage}
        </div>
      )
    }

    if (Object.keys(obj?.record ?? {}).length) {
      const firstRecord = obj.record[0]

      return (
        <div className='margin-top margin-bottom notification notification-primary'>
          <span className='margin-left'>{firstRecord.message}</span>

          {fromDuplication && (
            <div>
              <li className='margin-top margin-left'>
                <strong>{t('serviceOrders.duplicated.number')}</strong>
                {firstRecord.orderNumber}
              </li>
              <li className='margin-left'>
                <strong>{t('serviceOrders.duplicated.source')}</strong>
                {firstRecord.sourceOrderCode}
              </li>
            </div>
          )}
        </div>
      )
    }

    return false
  }

  openAcceptanceSalesConditions = (index, serviceOrder) => {
    const {
      auth: { user },
      getShortLink,
    } = this.props

    getShortLink(user.dsToken, {
      path: `terms-acceptance/${serviceOrder.encodedVaSlsOrdNr}/${serviceOrder.encodedPlntCd}`,
    }).then(() => {
      this.setState({ acceptanceSalesConditions: true, index, serviceOrder })
    })
  }

  closeApenAcceptanceSalesConditions = () => {
    this.setState({ acceptanceSalesConditions: false })
  }

  handleSendMsg = (vaSlsOrdNr, plntCd) => {
    const {
      auth: { user },
      sendMsgTermsAcceptance,
    } = this.props

    sendMsgTermsAcceptance(user.dsToken, { vaSlsOrdNr, plntCd }).then(() => {
      this.closeApenAcceptanceSalesConditions()
    })
  }

  renderModalAcceptanceSalesConditions = () => {
    const { t, qrcode } = this.props

    const { acceptanceSalesConditions, serviceOrder } = this.state
    if (!acceptanceSalesConditions) {
      return
    }

    return (
      <ModalQRCode
        {...qrcode}
        size='sm'
        servcOrdSeq={serviceOrder.servcOrdSeq}
        closeModal={this.closeApenAcceptanceSalesConditions}
        vaSlsOrdNr={serviceOrder.vaSlsOrdNr}
        plntCd={serviceOrder.plntCd}
        t={t}
        sendMsg={this.handleSendMsg}
      />
    )
  }

  openModalQrConformTerms = (index, serviceOrder) => {
    const {
      auth: { user },
      getShortLink,
    } = this.props

    getShortLink(user.dsToken, {
      path: `conformity/${serviceOrder.servcOrdSeq}`,
    }).then(() => this.setState({ modalQrConformity: true, index, serviceOrder }))
  }

  closeModalQrConformTerms = () => this.setState({ modalQrConformity: false })

  handleSendMsgConformity = (servcOrdSeq) => {
    const {
      auth: { user },
      sendMsgConformity,
    } = this.props

    sendMsgConformity(user.dsToken, { servcOrdSeq }).then(() => {
      this.closeModalQrConformTerms()
    })
  }

  renderModalConformityQRCode = () => {
    const { t, qrcode } = this.props
    const { serviceOrder, modalQrConformity } = this.state

    if (modalQrConformity) {
      return (
        <ModalQRCode
          {...qrcode}
          size='sm'
          servcOrdSeq={serviceOrder.servcOrdSeq}
          closeModal={this.closeModalQrConformTerms}
          t={t}
          sendMsg={this.handleSendMsgConformity}
          titleModal='title.modal.conformity'
        />
      )
    }
  }

  handleClosingNoConfirmity = (os) => {
    const {
      t,
      handleClosingNoConfirmity,
      auth: { user },
    } = this.props
    handleClosingNoConfirmity(user.dsToken, os).then(() => {
      this.handleSubmit()
      renderToastSuccessMsg(t('bank.save.success')) // traduction is the same
    })
  }

  parseResultsText = () => {
    const { term } = this.state
    const { t, count } = this.props

    if (!count) return t('itemLoc-list.not-found')

    const prefix = t('payments.filtered.1')

    const body =
      count === 1 ? t('advanced.search.results.label.single') : t('advanced.search.results.label')

    const postfix = term?.length
      ? t('serviceOrders.results-for-the-term')
      : t('serviceOrders.results-for-the-term.single')

    const bold = (
      <b>
        {count} {body}
      </b>
    )

    if (term?.length) {
      return (
        <span>
          {prefix} {bold} {postfix} "{term}"
        </span>
      )
    }

    return (
      <span>
        {prefix} {bold} {postfix}
      </span>
    )
  }

  closeAttachBudgetModal = (reload = false) => {
    const { filters } = this.props

    this.setState({ showAttachBudgetModal: false, attachBudgetOS: 0 }, () => {
      if (filters && reload) this.handleSubmitFilter()
    })
  }

  closeAttachInspectionModal = (reload = false) => {
    const { filters } = this.props

    this.setState({ showAttachInspectionModal: false, attachInspectionOS: 0 }, () => {
      if (filters && reload) this.handleSubmitFilter()
    })
  }

  closeSalesErrorModal = (reload = false) => {
    const { filters } = this.props

    this.setState(
      { showSalesErrorModal: false, salesErrorOS: { servcOrdCd: 0, servcOrdStusCd: '' } },
      () => {
        if (filters && reload) this.handleSubmitFilter()
      },
    )
  }

  openAttachBudgetModal = (servcOrdCd) => {
    this.setState({ showAttachBudgetModal: true, attachBudgetOS: servcOrdCd })
  }

  openAttachInspectionModal = (servcOrdCd) => {
    this.setState({ showAttachInspectionModal: true, attachInspectionOS: servcOrdCd })
  }

  openSalesErrorModal = (servcOrdCd, servcOrdStusCd) => {
    this.setState({ showSalesErrorModal: true, salesErrorOS: { servcOrdCd, servcOrdStusCd } })
  }

  updateSearchParamPage = (page) => {
    history.push(`${history.location.pathname}?page=${page}`)
  }

  render() {
    const {
      t,
      serviceOrders,
      pages,
      current,
      count,
      viewServiceOrder,
      serviceOrderTechnicalVisit,
      pushResult,
      auth,
      auth: {
        setupParameters: { serviceOrderShowAmount },
      },
    } = this.props

    const { mail, loading, showAttachBudgetModal, showSalesErrorModal, showAttachInspectionModal } =
      this.state

    if (loading) return <Spinner visible />

    const resultsText = this.parseResultsText()

    return (
      <section>
        <BreadcrumbBar title={t('lmi.serviceOrders')} />

        <section className='container-fluid service-order-list-container'>
          <Row>
            <Col xs={12} md={6} className='results-text'>
              <span id='results-text' className='color-default'>
                {resultsText}
              </span>
            </Col>
          </Row>

          {/* show notification after duplicate (as technical visit) */}
          {this.renderFeedbackNotification(serviceOrderTechnicalVisit, true)}

          {/* show notification after sendMail */}
          {this.renderFeedbackNotification(mail)}

          {/* show notification after sendMail */}
          {this.renderFeedbackNotification(pushResult)}

          {this.renderErrorMakeCallFive9()}
          {this.renderErrorMakeCallAnew()}
          {this.renderMessageMakeCall()}

          <Row>
            <Col xs={12}>
              {isEmpty(serviceOrders) ? (
                this.renderNotification()
              ) : (
                <ServiceOrderTable
                  serviceOrders={serviceOrders}
                  t={t}
                  getServiceOrder={this.getServiceOrder}
                  viewServiceOrder={viewServiceOrder}
                  funcaoAcesso={auth.user.funcaoAcesso}
                  accessFunctionList={auth.user.accessFunctionList}
                  getPdf={this.getPdf}
                  backStatus={this.backStatus}
                  updateOsStatusMenuShorcut={this.updateOsStatusMenuShorcut}
                  sendMail={this.sendMail}
                  sendPush={this.handleSendPush}
                  outOfFlow={this.handleOutOfFlow}
                  approveOutOfFlow={this.handleApproveOutOfFlow}
                  confirmModal={this.handleConfirmModal}
                  cancel={this.cancel}
                  makeCall={this.handleMakeCall}
                  generateAttentionOccurrence={this.handleAttentionOccurrence}
                  generateManualDistributionOccurrence={this.generateManualDistributionOccurrence}
                  closeOldOrder={this.closeOldOrder}
                  downloadFile={this.handleDownloadFile}
                  serviceOrderShowAmount={serviceOrderShowAmount}
                  auth={auth}
                  openAcceptanceSalesConditions={this.openAcceptanceSalesConditions}
                  openModalQrConformTerms={this.openModalQrConformTerms}
                  handleClosingNoConfirmity={this.handleClosingNoConfirmity}
                  handleSelectWorksite={this.handleSelectWorksite}
                  openAttachBudgetModal={this.openAttachBudgetModal}
                  handleSubmitNewBid={this.handleSubmitNewBid}
                  handleAssignToMe={this.handleAssignToMe}
                  openAttachInspectionModal={this.openAttachInspectionModal}
                  openSalesErrorModal={this.openSalesErrorModal}
                />
              )}
            </Col>
          </Row>

          {pages > 1 && serviceOrders.length > 0 && (
            <div className='margin-top margin-bottom-double'>
              <div className='align-center'>
                <Pagination
                  getPage={this.handleFetchServiceOrders}
                  pages={pages}
                  current={current}
                  count={count}
                />
              </div>
            </div>
          )}
        </section>

        {this.renderPushModal()}
        {this.renderOutOfFlowModal()}
        {this.renderApproveOutOfFlowModal()}
        {this.renderConfirmModal()}
        {this.renderAttentionOccurrenceModal()}
        {this.renderModalAcceptanceSalesConditions()}
        {this.renderModalConformityQRCode()}
        {this.renderAssignToMeModal()}
        {showAttachBudgetModal && (
          <AttachBudgetModal
            show={showAttachBudgetModal}
            onClose={this.closeAttachBudgetModal}
            servcOrdSeq={attachBudgetOS}
          />
        )}
        {showAttachInspectionModal && (
          <AttachInspectionModal
            show={showAttachInspectionModal}
            onClose={this.closeAttachInspectionModal}
            servcOrdSeq={attachInspectionOS}
          />
        )}
        {showSalesErrorModal && (
          <SalesErrorModal
            show={showSalesErrorModal}
            onClose={this.closeSalesErrorModal}
            serviceOrder={salesErrorOS}
          />
        )}
      </section>
    )
  }
}

ServiceOrderListContainer.propTypes = {
  t: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  serviceOrders: PropTypes.array.isRequired,
  fetchServiceOrdersV2: PropTypes.func.isRequired,
  getServiceOrder: PropTypes.func.isRequired,
  viewServiceOrder: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  backStatusWithOccurrence: PropTypes.func.isRequired,
  clearServiceOrderResponse: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  pushRequest: PropTypes.func.isRequired,
  pushResult: PropTypes.object,
  isFromMenu: PropTypes.bool.isRequired,
  validation: PropTypes.bool,
  isBackOrders: PropTypes.bool.isRequired,
  isBackOrder: PropTypes.func.isRequired,
  cancelServiceOrder: PropTypes.func.isRequired,
  error: PropTypes.object,
  fetchServiceProviderNameAutocomplete: PropTypes.func.isRequired,
  fetchServiceSpecialistNameAutocomplete: PropTypes.func.isRequired,
  generateOccurrenceSimple: PropTypes.func.isRequired,
  recordCall: PropTypes.func.isRequired,
  setServiceOrder: PropTypes.func.isRequired,
  updateOsMenuShorcutError: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number,
  shouldRestoreScroll: PropTypes.func.isRequired,
  saveScrollPosition: PropTypes.func.isRequired,
  restoreScroll: PropTypes.bool,
  updateOsMenuShorcut: PropTypes.func.isRequired,
  updateOsStatusMenuShorcut: PropTypes.func.isRequired,
  serviceOrderTechnicalVisit: PropTypes.object,
  location: PropTypes.object,
  qrcode: PropTypes.object.isRequired,
  sendMsgTermsAcceptance: PropTypes.func.isRequired,
  sendMsgConformity: PropTypes.func.isRequired,
  getShortLink: PropTypes.func.isRequired,
  handleClosingNoConfirmity: PropTypes.func,
  servcOrdListElastic: PropTypes.func,
  onSubmitNewBid: PropTypes.func,
  filters: PropTypes.object,
  hasFilters: PropTypes.bool,
  handleSubmitDefault: PropTypes.func,
  selectedStores: PropTypes.array,
  pushClear: PropTypes.func,
}

/*
 * Mapeia state do redux para props do componente
 */
/* istanbul ignore next */
const mapStateToProps = ({ serviceOrderReducer, paginateReducer, newCockpitReducer, auth }) => ({
  auth,
  isBackOrders: serviceOrderReducer.isBackOrder,
  serviceOrders: serviceOrderReducer.list.records,
  error: serviceOrderReducer.list.error,
  serviceOrderDuplicate: serviceOrderReducer.duplicate,
  serviceOrderTechnicalVisit: serviceOrderReducer.technicalVisit,
  pages: paginateReducer.pages,
  count: paginateReducer.count,
  current: paginateReducer.current,
  isFromMenu: serviceOrderReducer.isFromMenu,
  scrollPosition: serviceOrderReducer.scroll.scrollPosition,
  restoreScroll: serviceOrderReducer.scroll.restoreScroll,
  attentionOccurrence: serviceOrderReducer.occurrence,
  qrcode: serviceOrderReducer.qrcode,
  selectedStores: newCockpitReducer.filters !== undefined ? newCockpitReducer.filters.codLocal : [],
})

/*
 * Mapeia actions e dispatch para props do componente
 */
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...serviceOrderActions,
      ...elasticCoreActions,
      dispatch,
    },
    dispatch,
  )

// Conecta componente ao I18next
const ServiceOrderListTranslated = withTranslation()(WithContext(ServiceOrderListContainer))

// Conecta o componente com o redux
export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderListTranslated)

// Export default como componente
export { ServiceOrderListContainer as ServiceOrderList }
