import i18next from 'i18next'
import FormField from '../../../../../../components/FormField'

import { useServiceOrderDetailContext } from '../../../../context'

function ProviderInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='provider-info' className='skeleton' />

  const servcPrvdrAgntNm = serviceOrderBody?.servcPrvdrTrdNm || '-'

  return (
    <div id='provider-info'>
      <FormField label={i18next.t('serviceOrders.provider')} value={servcPrvdrAgntNm} />
    </div>
  )
}

export default ProviderInfo
