import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUserInfo } from '../../../../redux/actions/auth/getUserInfo'

function PingAuth({ getUserInfo, location }) {
  const [error, setError] = useState(null)

  useEffect(() => {
    if (location && location.search) {
      if (location.search.includes('token')) {
        const token = location.search.replace('?token=', '')

        getUserInfo(token)
      }

      if (location.query) setError(location.query.error)
    }
  }, [])

  const renderError = () => <div className='notification notification-warning margin'>{error}</div>

  return error ? renderError() : null
}

PingAuth.propTypes = {
  location: PropTypes.object,
  getUserInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getUserInfo }, dispatch)

export default connect(null, mapDispatchToProps)(withTranslation()(PingAuth))

export { PingAuth }
