import { FunctionComponent, memo, useMemo } from 'react'
import { useFirestoreContext } from '../../../../../../../contexts/customerChat'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      chatReference,
      openedClientConversation,
      handleResolveConversation,
      handleUpdateConversation,
      handleBackToConversationList,
      handleReadClientMessages,
    } = useFirestoreContext()

    const newProps = useMemo(
      () => ({
        chatRef: chatReference,
        chatId: openedClientConversation,
        resolveChat: handleResolveConversation,
        updateChat: handleUpdateConversation,
        backToList: handleBackToConversationList,
        setAsReadedChat: handleReadClientMessages,
      }),
      [
        chatReference,
        openedClientConversation,
        handleResolveConversation,
        handleUpdateConversation,
        handleBackToConversationList,
        handleReadClientMessages,
      ],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
