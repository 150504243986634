import { Buffer } from 'buffer'

/**
 *
 * Converte uma string base64 para um blob
 * Baseado em: https://stackoverflow.com/a/16245768
 *
 * @param base64
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
export function base64toBlob(base64, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64)
  const byteArrays = []
  const len = byteCharacters.length

  for (let offset = 0; offset < len; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    byteArrays.push(new Uint8Array(byteNumbers))
  }

  return new Blob(byteArrays, { type: contentType })
}

/**
 * Converte uma string base64 para uma ObjectURL
 *
 * @param base64
 * @param contentType
 * @param sliceSize
 * @returns {string}
 */
export function base64toBlobUrl(base64, contentType = '', sliceSize = 512) {
  return URL.createObjectURL(base64toBlob(base64, contentType, sliceSize))
}

/**
 * Converte a URL de uma imagem em uma string base64 correspondente
 *
 * @param url
 * @returns {Promise<string>}
 */
export function imgUrlToBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('GET', url)
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.onerror = reject
    xhr.send()
  })
}

/**
 * Converts a file into a base64 string
 * Source: https://stackoverflow.com/a/46639837/6262097
 *
 * @param file
 * @returns {Promise<string>}
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}

/**
 * Converts a file into a base64 string using Buffer
 *
 * @param file
 * @returns {Promise<string>}
 */
export function fileToBase64WithBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const buffer = Buffer.from(reader.result)
      resolve(buffer.toString('base64'))
    }
    reader.readAsArrayBuffer(file)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * Converts a file into a String
 *
 * @param file
 * @returns {Promise<string>}
 */
export function readAsText(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    if (file) {
      reader.readAsText(file)
    } else {
      resolve('')
    }
  })
}

/**
 * Acha onde inserir um {val} em um {sortedArr} para mantê-lo ordenado, usando busca binária
 *
 * @param sortedArr
 * @param val
 * @param comparator
 * @returns {Promise<string>}
 */
export function findInsertionPoint(sortedArr, val, comparator) {
  let low = 0
  let high = sortedArr.length
  let mid = -1
  let c = 0

  while (low < high) {
    // eslint-disable-next-line radix
    mid = parseInt((low + high) / 2)
    c = comparator(sortedArr[mid], val)

    if (c < 0) {
      low = mid + 1
    } else if (c > 0) {
      high = mid
    } else {
      return mid
    }
  }

  return low
}

/**
 * Comparador genérico para se usar com o {findInsertionPoint}
 *
 * @param key
 * @returns {Function<int>}
 */
export function genericObjectComparator(key) {
  return function (itemA, itemB) {
    return itemA[key] - itemB[key]
  }
}

/**
 * Extrai apenas os numeros de uma string
 *
 * @param str
 * @returns {String}
 */
export function getNumbersOnly(str) {
  return (str || '').replace(/\D/g, '')
}

/**
 * Get extension of a filename
 *
 * @param txt
 * @returns {String}
 */
export function getExtension(txt) {
  return /(?:\.([^.]+))?$/.exec(txt)[1] || ''
}
