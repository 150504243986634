/* eslint-disable react/no-unused-prop-types */

function ItemReportSkeleton() {
  return (
    <>
      <div id='item-techinical-report-skeleton'>
        <div className='report-body'>
          <div className='img-content skeleton' />

          <div className='info'>
            <span className='title skeleton' />
            <span className='type skeleton' />
            <span className='photo-date skeleton' />
            <span className='sended-by skeleton' />
          </div>
        </div>

        <section className='action-buttons'>
          <div className='eye skeleton' />
          <div className='download skeleton' />
        </section>
      </div>
      <hr />
    </>
  )
}

export default ItemReportSkeleton
