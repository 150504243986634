import PropTypes from 'prop-types'
import { Component } from 'react'

import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'

import ServcOrdEvntStusType from '../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import { WithForm } from '../../../helpers/useForm/withForm'

class ApproveOutOfFlowModal extends Component {
  handleRequestApproval = () => {
    const { save, values, servcOrd, index, closeModal, selectedRadio } = this.props

    const data = {
      ...servcOrd,
      actionJustification: values.flowMessage,
      servcOrdEvntStusInd: selectedRadio,
    }

    save(data, index)
    closeModal()
  }

  handleEnabledSubmit = () => {
    const {
      fields: { flowMessage },
    } = this.props
    const atLeastOneAlfhabeticLetter = /[a-zA-Z]/

    return (
      flowMessage.input.value &&
      flowMessage.input.value.length >= 10 &&
      RegExp(atLeastOneAlfhabeticLetter).test(flowMessage.input.value)
    )
  }

  getJustification = () => {
    const { servcOrd } = this.props

    const occurance = servcOrd.serviceOrderOccurrence.filter(
      (occ) => occ.servcOrdEvntStusInd === ServcOrdEvntStusType.OUT_OF_FLOW_REQUEST.id,
    )

    return occurance[0].descOcorOrdemServico
  }

  render() {
    const { t, fields, closeModal, handleRadioChange, selectedRadio, register } = this.props

    return (
      <Modal closeModal={closeModal} size='sm'>
        <form>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <div className='heading-3 strong' style={{ color: '#666666' }}>
                {t('modal.approveOutOfFlowRequest.title')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='margin-bottom heading-5' style={{ color: '#666666' }}>
              <span>{t('modal.approveOutOfFlowRequest.description')}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div
                className='heading-5'
                style={{
                  color: '#666666',
                  border: '1px solid #e5e5e5',
                  borderRadius: '4px',
                  padding: '0.5rem',
                }}
              >
                {this.getJustification()}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <p className='heading-5 strong' style={{ color: '#666666' }}>
                {t('modal.approveOutOfFlowRequest.question')}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className='margin-bottom'>
              <span className='check-input'>
                <input
                  checked={selectedRadio === ServcOrdEvntStusType.REFUSED_OUT_OF_FLOW_REQUEST.id}
                  onChange={handleRadioChange}
                  type='radio'
                  name='refuse'
                  id='32'
                />
                <label
                  className='heading-5'
                  htmlFor='32'
                  style={{ marginLeft: '.5rem', color: '#333333' }}
                >
                  {t('modal.approveOutOfFlowRequest.refuse.option')}
                </label>
              </span>
            </Col>
            <Col xs={4} className='margin-bottom'>
              <span className='check-input'>
                <input
                  checked={selectedRadio === ServcOrdEvntStusType.APPROVED_OUT_OF_FLOW_REQUEST.id}
                  onChange={handleRadioChange}
                  type='radio'
                  name='approve'
                  id='31'
                />
                <label
                  className='heading-5'
                  htmlFor='31'
                  style={{ marginLeft: '.5rem', color: '#333333' }}
                >
                  {t('modal.approveOutOfFlowRequest.approve.option')}
                </label>
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p
                className='heading-5 strong'
                style={selectedRadio ? { color: '#666666' } : { color: '#a6a6a6' }}
              >
                {t('modal.approveOutOfFlowRequest.justify')}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <Field {...fields.flowMessage}>
                <MozaicTextArea
                  {...register('flowMessage')}
                  className='resize-none'
                  label={<i>{t('modal.approveOutOfFlowRequest.justify.hint')}</i>}
                  rows={6}
                  isDisabled={!selectedRadio}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button-hollow button-hollow-primary button-full align-center'
                type='button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col xs={6} md={6} className='button-align'>
              <button
                className='button button-primary button-full'
                type='button'
                disabled={!this.handleEnabledSubmit()}
                onClick={this.handleRequestApproval}
              >
                {t('modal.approveOutOfFlowRequest.submit')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

ApproveOutOfFlowModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  servcOrd: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedRadio: PropTypes.string.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
}

const FIELDS = { flowMessage: '' }

export default WithForm(FIELDS)(ApproveOutOfFlowModal)

export { ApproveOutOfFlowModal }
