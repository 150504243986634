import {
  Button,
  Flex,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  View,
  useModals,
  useNotifications,
} from '@mozaic-ds/react'
import i18next from 'i18next'
import { useEffect, useRef, useState } from 'react'
import {
  ProviderPendingDocuments,
  fetchProviderPendingDocuments,
} from '../../../../../../services/pages/ServiceProviderList/fetchProviderPendingDocuments'

import './index.scss'

const MODAL_ID = 'provider-pending-documents-modal'
interface PendingDocumentsModalProps {
  servcPrvdrId: number | null
  isOpen: boolean
  onClose: () => void
}

/**
 * @description Modal to show provider and agents pending documents
 */

export function PendingDocumentsModal({
  servcPrvdrId,
  isOpen,
  onClose,
}: PendingDocumentsModalProps) {
  const [providerPendingDocuments, setProviderPendingDocuments] =
    useState<ProviderPendingDocuments>()
  const { open, close } = useModals()
  const abortController = useRef<AbortController>()
  const notification = useNotifications()

  const onCloseAction = () => {
    close(MODAL_ID)
    onClose()
  }

  useEffect(() => {
    setProviderPendingDocuments(undefined)
    if (isOpen && servcPrvdrId) {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = undefined
      }
      open(MODAL_ID)
      abortController.current = new AbortController()
      fetchProviderPendingDocuments(servcPrvdrId, abortController.current)
        .then((data) => setProviderPendingDocuments(data))
        .catch((error) => {
          if (!String(error).includes('aborted')) {
            console.error('error fetching provider pending documents', error)
            notification.danger(
              {
                title: i18next.t('pwa.toast.error.message'),
                message: i18next.t('provider.pending.documents.modal.error'),
                duration: 8000,
              },
              'top',
            )
            onCloseAction()
          }
        })
    }
  }, [isOpen])

  return (
    <Modal id={MODAL_ID} onClose={onCloseAction}>
      <ModalHeader>
        <ModalTitle>{i18next.t('provider.status.pending.documents')}</ModalTitle>
      </ModalHeader>
      <ModalBody id='providers-list-pending-documents-modal'>
        {!providerPendingDocuments && (
          <Flex justifyContent='center' margin='mu100'>
            <Loader size='l' theme='primary' />
          </Flex>
        )}
        {!!providerPendingDocuments && (
          <>
            {!!providerPendingDocuments.pendingDocuments.length && (
              <View marginBottom='mu150'>
                <Text theme='success' weight='semi-bold'>
                  {i18next.t('provider.pending.documents.modal.company')}
                </Text>
                <Text theme='darkest'>
                  <ul>
                    {providerPendingDocuments.pendingDocuments.map((doc) => (
                      <li key={doc}>{doc}</li>
                    ))}
                  </ul>
                </Text>
              </View>
            )}
            {!!providerPendingDocuments?.agents && (
              <>
                {providerPendingDocuments.agents.map((agent) => (
                  <View marginBottom='mu150' key={agent.servcPrvdrAgntId}>
                    <Text theme='success' weight='semi-bold'>
                      {i18next
                        .t('provider.pending.documents.modal.installer')
                        .replace('{0}', agent.servcPrvdrAgntNm)}
                    </Text>
                    <Text>
                      <ul>
                        {agent.pendingDocuments.map((doc) => (
                          <li key={doc}>{doc}</li>
                        ))}
                      </ul>
                    </Text>
                  </View>
                ))}
              </>
            )}
            {providerPendingDocuments.hasNoOwnerAgents && (
              <View marginBottom='mu150'>
                <Text theme='success' weight='semi-bold'>
                  {i18next.t('provider.pending.documents.modal.no.owners')}
                </Text>
              </View>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter className='provider-pending-documents-modal-footer'>
        <Button onClick={onCloseAction} className='close-btn'>
          {i18next.t('option.close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
