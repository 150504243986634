import {
  Button,
  Field,
  Flex,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Radio,
  RadioGroup,
  TextArea,
  View,
  useModals,
  useNotifications,
} from '@mozaic-ds/react'
import i18next from 'i18next'
import { ChangeEvent, useEffect, useState } from 'react'

import { formatCNPJ } from '../../../../../../helpers/masks'
import ProviderEntity from '../../../../../../models/Provider'
import { storeApprovalServiceProviderRegistration } from '../../../../../../services/providers/storeApprovalServiceProviderRegistration'
import { useServiceProvidersListV2Context } from '../../../../contexts'

const MODAL_ID = 'providers-list-store-validation-modal'

interface StoreValidationModalProps {
  serviceProvider: ProviderEntity | undefined
}

/**
 * @description Store Validation Modal component for Service Providers List V2 page
 */

export function StoreValidationModal({ serviceProvider }: StoreValidationModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [justification, setJustification] = useState('')
  const [approveOperation, setApproveOperation] = useState('')

  const { open, close } = useModals()
  const notification = useNotifications()

  const { handleFetchServiceProviders, filters } = useServiceProvidersListV2Context()

  function handleChangeApproveOperation(event: ChangeEvent<HTMLInputElement>) {
    setApproveOperation(event.target.value)
  }

  function handleChangeJustificationText(event: ChangeEvent<HTMLTextAreaElement>) {
    setJustification(event.target.value.trimStart().replace('  ', ' '))
  }

  function clearJustification() {
    setJustification('')
  }

  function clearState() {
    setJustification('')
    setApproveOperation('')
  }

  useEffect(() => {
    if (approveOperation === 'approve') clearJustification()
  }, [approveOperation])

  useEffect(() => {
    if (serviceProvider) open(MODAL_ID)
  }, [serviceProvider])

  function isConfirmButtonDisabled() {
    if (isLoading) return true
    if (approveOperation === '') return true
    if (approveOperation === 'approve') return false

    const withoutSpaceJustification = justification.replace(/\s+/g, '')
    const isJustificationOnlyNumbers = withoutSpaceJustification.match(/^[0-9]+$/) !== null
    const isJustificationOnlySpaces = withoutSpaceJustification.match(/^\s+$/) !== null
    const isJustificationOnlySpecialCharacters =
      withoutSpaceJustification.match(/^[^a-zA-Z0-9]+$/) !== null
    const isJustificationLessThan10Characters = withoutSpaceJustification.length < 10
    const isLastCharacterASpace = justification.slice(-1) === ' '

    const isJustificationInvalid =
      isJustificationOnlyNumbers ||
      isJustificationOnlySpaces ||
      isJustificationOnlySpecialCharacters ||
      isJustificationLessThan10Characters ||
      (isJustificationLessThan10Characters && isLastCharacterASpace)

    return isJustificationInvalid
  }

  const handleOnClickClose = () => {
    if (isLoading) return
    clearState()
    close(MODAL_ID)
  }

  async function handleOnClickConfirm() {
    if (!serviceProvider) return
    try {
      setIsLoading(true)

      await storeApprovalServiceProviderRegistration({
        providerId: serviceProvider.servcPrvdrId,
        approved: approveOperation === 'approve',
        justificationText: justification.trim(),
      })

      const successMessageByOperation =
        approveOperation === 'approve'
          ? i18next.t('provider.approved')
          : i18next.t('provider.reproved')

      notification.success(
        {
          title: i18next.t('pwa.toast.success.message'),
          message: successMessageByOperation as string,
          duration: 8000,
        },
        'top',
      )

      handleFetchServiceProviders({ fields: filters })
    } catch (error) {
      console.error('Error in store approval', error)
      notification.danger(
        {
          title: i18next.t('pwa.toast.error.message'),
          message: i18next.t('dao.save.error'),
          duration: 8000,
        },
        'top',
      )
    } finally {
      setIsLoading(false)
      close(MODAL_ID)
    }
  }

  const isTextAreaDisabled = approveOperation !== 'reprove'

  return (
    <Modal
      id={MODAL_ID}
      onClose={handleOnClickClose}
      canCloseOnOverlayClick={false}
      canCloseOnEscapePress={!isLoading}
      focusedElementIndex={-1}
    >
      <ModalHeader>
        <ModalTitle>{i18next.t('supplier.approval.text')}</ModalTitle>
      </ModalHeader>
      {serviceProvider && (
        <>
          <ModalBody>
            <Flex gap='mu050' direction='column' marginBottom='mu050'>
              <View>
                {i18next.t('evaluate.provider.initial.label.ftsName')}:{' '}
                {serviceProvider?.servcPrvdrTrdNm || `-`}{' '}
              </View>
              <View>
                {i18next.t('company.info.cnpj')}:{' '}
                {formatCNPJ(serviceProvider?.servcPrvdrFedrlTaxIdNr) || `-`}
              </View>
              <Field label={i18next.t('service.confirmation.nav.approval')}>
                {/* @ts-ignore The type of RadioGroup is not working for children */}
                <RadioGroup
                  name='approvation-operation'
                  value={approveOperation}
                  onChange={(event) => handleChangeApproveOperation(event)}
                >
                  <Flex gap='mu250'>
                    <Radio value='reprove'>
                      {i18next.t('installer.document.validation.option.1')}
                    </Radio>
                    <Radio value='approve'>
                      {i18next.t('installer.document.validation.option.3')}
                    </Radio>
                  </Flex>
                </RadioGroup>
              </Field>
              <Field
                label={i18next.t('servcPrvdrTmlineEvntTyp.justification')}
                help={i18next.t('justification.input.hint')}
              >
                <TextArea
                  placeholder={i18next.t('justificationModal.desc.placeholder')}
                  onChange={(event) => handleChangeJustificationText(event)}
                  value={justification}
                  isDisabled={isTextAreaDisabled}
                  style={{ resize: 'none' }}
                />
              </Field>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              size='m'
              width='full'
              onClick={handleOnClickClose}
              variant='bordered'
              isDisabled={isLoading}
            >
              {i18next.t('provider.portal.cancel')}
            </Button>
            <Button
              size='m'
              width='full'
              onClick={handleOnClickConfirm}
              isDisabled={isConfirmButtonDisabled()}
              className='confirm-btn'
            >
              {isLoading ? (
                <Loader size='s' theme='light' />
              ) : (
                <>{i18next.t('provider.portal.confirm')}</>
              )}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
