import {
  API_INSTALA_AUTH,
  API_INSTALA,
  API_INSTALA_PROVIDER,
  API_INSTALA_I18N,
  API_INSTALA_PARAM,
  API_INSTALA_BUDGET,
  API_INSTALA_CORE_ELASTIC,
  API_SERVICES_REGIONS,
} from './envs'

const endpoints = {
  URL: API_INSTALA,

  // Access
  LOGIN: `${API_INSTALA_AUTH}/public/user-validation-ldap`,
  LOGIN_PINGONE: `${API_INSTALA_AUTH}/oauth2/authorize/ping-service?portal=service`,
  LOGOUT_PINGONE: `${API_INSTALA_AUTH}/oauth2/logout`,
  GET_USER_INFO: `${API_INSTALA_AUTH}/get-user-info`,

  SET_BU_CD: `${API_INSTALA_AUTH}/user/set-bu-cd`,

  SETUP_PARAMETERS: `${API_INSTALA_PARAM}/public/find-setup-parameter`,

  // Access Profiles
  PROFILE_FIND: `${API_INSTALA_AUTH}/perfilAcesso/list`,

  SYS_ACS_USER_GET: `${API_INSTALA_AUTH}/sysAcsUser/users/:id`,

  SERVICE_ORDER_REPORT_PDF: `${API_INSTALA}/orders/services/reportAsPdf`,

  // Service Orders
  DISTRIBUTE_SERVICE: `${API_INSTALA}/orderDetail/distribute`,
  RESCHEDULE_SERVICE: `${API_INSTALA}/orderDetail/reschedule`,
  SERVICE_ORDER_FIND: `${API_INSTALA}/orders/services/list-filters`,
  SERVICE_ORDER_FIND_V2: `${API_INSTALA}/orders/services/new/list-filters`,
  SERVICE_ORDER_REMOVE: `${API_INSTALA}/orders/services/remove`,
  SERVICE_ORDER_UPDATE: `${API_INSTALA}/orders/services/update`,
  SERVICE_ORDER_CANCEL: `${API_INSTALA}/orders/services/cancel`,
  SERVICE_ORDER_STATUS: `${API_INSTALA}/orders/services/status`,
  SERVICE_ORDER_DATES: `${API_INSTALA}/orderDetail/findServiceOrderDates`,
  FIND_SERVICE_ORDER_WITH_REPORT: `${API_INSTALA}/orders/services/findServiceOrderWithReport`,
  FIND_SERVICE_ORDER_IMAGES: `${API_INSTALA}/service-order-attachment`,
  SERVICE_ORDER_FIND_FILTER: `${API_INSTALA}/orders/findFilterPresenter`,
  CREATE_OCCURRENCE: `${API_INSTALA}/orders/createOccurrence`,
  CREATE_OCCURRENCE_SIMPLE: `${API_INSTALA}/orders/createOccurrenceSimple`,
  CREATE_SERVC_ORD: `${API_INSTALA}/orders/services`,
  RECORD_CALL: `${API_INSTALA}/record-call`,
  SERVICE_ORDER_SEND_MAIL: `${API_INSTALA}/orders/services/sendMail`,
  ORDER_SERVC_ATTACHMENT: `${API_INSTALA}/service-order-attachment`,
  ORDER_SERVC_ATTACHMENT_V2: `${API_INSTALA}/service-order-attachment`,
  DELETE_ORDER_SERVC_ATTACHMENT: `${API_INSTALA}/service-order-attachment/delete`,
  FIND_ORDER_SERVC_ATTACHMENT: `${API_INSTALA}/service-order-attachment/documents`,
  FIND_SHORT_LINK: `${API_INSTALA}/short-link`,
  SEND_MSG_TERMS: `${API_INSTALA}/orders/msg/acceptance-sales-conditions`,
  SEND_MSG_CONFORMITY: `${API_INSTALA}/orders/sendSmsEmailExecutionConformity`,
  PUBLIC_SERVICE_ORDER_EMAIL_CHECK: `${API_INSTALA}/public/order/email-check`,
  SERVICE_ORDER_CLOSING_NO_CONFORMITY: `${API_INSTALA}/orders/close-non-conforming`,
  ADD_REWORK_VALUE: `${API_INSTALA}/warranty/rework/{numberOs}`,
  WARRANTY_ACTIVATION: `${API_INSTALA}/warranty/create`,
  UPDATE_CONTACT_INFORMATION: `${API_INSTALA}/orders/update-address-client`,
  BACK_TO_RESCHEDULED: `${API_INSTALA}/orders/services/return-pre-schedule`,
  BACK_TO_SCHEDULED: `${API_INSTALA}/orderDetail/backToScheduled`,
  DOWNLOAD_SERVICE_ORDER_FILES: `${API_INSTALA}/public/service-order-attachment/download-files-report`,
  HISTORIC_OCCURRENCES: `${API_INSTALA}/orderDetail/history`,
  GET_RATING: `${API_INSTALA}/orderDetail/findServiceOrderRating`,
  REGISTER_HISTORIC_OCCURRENCE: `${API_INSTALA}/orderDetail/occurrence`,
  GET_SERVICE_ORDER_TAGS: `${API_INSTALA}/orderDetail/findServiceOrderTags`,
  GET_SERVICE_ORDER_BODY: `${API_INSTALA}/orderDetail/findServiceOrderBody`,
  GET_SERVICE_ORDER_OBSERVATIONS: `${API_INSTALA}/orderDetail/observation`,
  GET_SERVICE_ORDER_CLIENT: `${API_INSTALA}/orderDetail/fetchServiceOrderClient`,
  GET_SERVICE_ORDER_DETAILS: `${API_INSTALA}/orderDetail/details`,
  ORDER_DETAIL_WARRANTY: `${API_INSTALA}/orderDetail/findServiceOrderWarranty`,
  CONFIRM_SERVICE_ORDER: `${API_INSTALA}/orderDetail/confirmation`,
  V1_ORDERS: `${API_INSTALA}/v1/orders`,

  // Service Orders Item
  SERVICE_ORDER_ITEM_FIND_BY_CD: `${API_INSTALA}/serviceOrderItem/findByServcOrdCd`,
  SERVICE_ORDER_ITEM_FIND_FILTER: `${API_INSTALA}/serviceOrderItem/findFilterPresenter`,

  // Stores
  FIND_STORES: `${API_INSTALA}/branch/list`,
  FIND_STORES_ALL: `${API_INSTALA}/branch/list-all`,
  FIND_STORES_CLUSTERS: `${API_INSTALA}/branch/core/list`,

  // States
  FIND_STATES: `${API_INSTALA}/state/list`,
  FIND_MACRO_REGION_STATES: `${API_INSTALA_PROVIDER}/public/provider/states`,

  // Service Order statuses
  FIND_SERVICE_ORDER_STATUSES: `${API_INSTALA}/orders/services/get-service-order-status`,

  // Service Order statuses -- backStatusWithOccurrence
  BACK_STATUS_WITH_OCCURRENCE: `${API_INSTALA}/orders/services/back-status-with-occurrence`,

  // Occurrences
  SERVICE_ORDER_OCCURRENCES: `${API_INSTALA}/serviceOrderOccurrence/v3/create`,

  // Schedule
  GET_SCHEDULE_DATES: `${API_INSTALA}/public/calendar/schedule/available/list`,

  // Cockpit
  COCKPIT_INDICATOR: `${API_INSTALA_CORE_ELASTIC}/cockpit/indicator/elastic`,

  // New cockpit
  NEW_COCKPIT_CHAT_SEND_MESSAGE: `${API_INSTALA}/chat/cockpit/sendMessage`,

  // AmazonAws
  AMAZON_AWS_DOWNLOAD_FILE: `${API_INSTALA}/amazon/downloadFile`,
  AMAZON_AWS_GET_FILE: `${API_INSTALA}/amazon/file`,

  // Payments
  PAYMENTS_SERVICE_ORDER_FIND: `${API_INSTALA}/payments/services/list-filters`,
  PAYMENTS_SERVICE_ORDER_ELASTIC_FIND: `${API_INSTALA_CORE_ELASTIC}/payments/services/list-filters`,

  PAYMENTS_SERVICE_ORDER_REMOVE: `${API_INSTALA}/payments/remove-nf`,
  PAYMENTS_SERVICE_ORDER_SAVE: `${API_INSTALA}/payments/save-nf`,

  // Reasons
  FIND_REASONS_LIST: `${API_INSTALA}/reasons/list`,

  // Product price
  SERVC_TP_FIND: `${API_INSTALA}/servcTp/list`,

  FIND_PUBLIC_OS_SEQ: `${API_INSTALA}/public/order`,

  GET_PUBLIC_OS: `${API_INSTALA}/public/order/get`,
  FIND_NEED_HELP: `${API_INSTALA}/public/order/findNeedHelp`,

  SERVC_ORD_CHANGE_OBS: `${API_INSTALA}/orders/saveObs`,
  SERVC_ORD_ITEM_CHANGE_OBS: `${API_INSTALA}/serviceOrderItem/saveObs`,

  // Customer chat
  FIND_CHAT_LIST: `${API_INSTALA}/chat/list`,

  // Pyxis
  GET_PYXIS_INFORMATIONS: `${API_INSTALA}/pyxis/getCommandDetails`,

  // SSE-Emiter
  ENABLE_NOTIFIER: `${API_INSTALA}/public/sse-emiter/enable-notifier`,

  // Service Providers
  FIND_SERVICE_PROVIDERS_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/service-provider/listAutocomplete`,
  FIND_SERVICE_PROVIDERS_MANUAL_DISTRIBUTION: `${API_INSTALA_PROVIDER}/v1/provider:manualDistribution`,
  SERVICE_PROVIDER_FIND: `${API_INSTALA_PROVIDER}/service-provider/list-filters`,
  SERVICE_PROVIDER_SAVE: `${API_INSTALA_PROVIDER}/service-provider/saveAll`,
  SERVICE_PROVIDER_REMOVE: `${API_INSTALA_PROVIDER}/service-provider/deleteLogic`,
  DELETE_SERVICE_PROVIDER: `${API_INSTALA_PROVIDER}/service-provider/delete`,
  SERVICE_PROVIDER_FILES: `${API_INSTALA_PROVIDER}/service-provider/attachment`,
  SERVICE_PROVIDER_INACTIVE: `${API_INSTALA_PROVIDER}/service-provider/inactive`,
  SERVICE_PROVIDER_SET_ACTV_SERVC_PRVDR_ORD_RECV_IND: `${API_INSTALA_PROVIDER}/service-provider/setActvServcPrvdrOrdRecvInd`,
  SEND_SMS_APP: `${API_INSTALA_PROVIDER}/service-provider/sendConfirmationSms`,
  FIND_SERVICE_PROVIDERS_NAME_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/service-provider/autocompleteProvider`,
  FIND_SERVC_PRVDR_TRD_NM: `${API_INSTALA_PROVIDER}/service-provider/autocompleteServcPrvdrTrdNm`,
  SERVICE_PROVIDER_POSTAL_CODE: `${API_INSTALA_PROVIDER}/service-provider/postalCode`,
  PUBLIC_SERVICE_PROVIDER_POSTAL_CODE: `${API_INSTALA_PROVIDER}/public/servcPrvdr/postalCode`,
  GET_PROVIDER_PENDING_DOCUMENTS: `${API_INSTALA_PROVIDER}/v1/providers/{id}:pendingDocuments`,

  // Service Provider Macro Regionality
  PROVIDER_ZONES: `${API_INSTALA_PROVIDER}/provider/{servcPrvdrId}/region`,
  FIND_REGIONS: `${API_SERVICES_REGIONS}/regions/v1/region`,
  FIND_MICRO_REGIONS: `${API_SERVICES_REGIONS}/regions/v1/micro-region`,

  // Service Provider/Specialist Specialities
  UPDATE_PROVIDER_SPECIALITIES: `${API_INSTALA_PROVIDER}/service-provider/updateSpecialities`,
  FIND_PROVIDER_SPECIALITIES_IDS: `${API_INSTALA_PROVIDER}/service-provider/listSpecialitiesIds`,
  UPDATE_SERVICE_SPECIALIST_SPECIALITIES: `${API_INSTALA_PROVIDER}/servcPrvdr/updateServcSpecialistSpecialities`,
  FIND_SERVICE_SPECIALIST_SPECIALITIES_IDS: `${API_INSTALA_PROVIDER}/servcPrvdr/listSpecialitiesIds`,
  FIND_SERVICE_SPECIALIST_SPECIALITIES: `${API_INSTALA_PROVIDER}/v1/peoples/##servcPrvdrAgntId##?relations=specialities&fields=servcPrvdrAgntId`,

  // Service Provider Plant
  FIND_PROVIDER_STORES: `${API_INSTALA_PROVIDER}/service-provider-plant/list`,

  GENERAL_CONDITIONS: `${API_INSTALA}/public/order/generalConditions`,

  // Service Specialists
  FIND_SERVICE_SPECIALIST_LIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/list-filters`,
  FIND_WORKER_MANUAL_DISTRIBUTION: `${API_INSTALA_PROVIDER}/v1/worker:manualDistribution`,
  FIND_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/find-agent`,
  REMOVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/service-specialist/deleteLogic`,
  DELETE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/delete`,
  INACTIVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/inactive`,
  SAVE_SERVICE_SPECIALIST: `${API_INSTALA_PROVIDER}/service-specialist/save`,
  SERVICE_SPECIALIST_FILES: `${API_INSTALA_PROVIDER}/servcPrvdrAgntAtchmnt/attachment`,
  SERVICE_SPECIALIST_SEND_MAIL: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/sendConfirmationMail`,
  FIND_SERVICE_SPECIALIST_GROUPS: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/list-service-group`,
  FIND_SERVICE_SPECIALIST_NAME_AUTOCOMPLETE: `${API_INSTALA_PROVIDER}/servcPrvdr/autocomplete`,
  SEND_SMS_APP_SPECIALIST: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/sendConfirmationSmsSpecialist`,

  // Report
  SERVICE_SPECIALIST_REPORT_CSV: `${API_INSTALA_PROVIDER}/servcPrvdr/v2/report/csv`,
  SERVICE_PROVIDER_REPORT_CSV: `${API_INSTALA_PROVIDER}/service-provider/report/csv`,

  // Document Types
  FIND_DOCUMENT_TYPES_LIST: `${API_INSTALA_PROVIDER}/documentType/list`,

  // ServcPrvdrAgntAbsncPerd
  SERVC_PRVDR_AGNT_ABSNC_PERD_FIND: `${API_INSTALA_PROVIDER}/availability/findFilter`,

  // Banks
  BANK_FIND: `${API_INSTALA_PROVIDER}/bank/list`,

  // Push
  PUSH: `${API_INSTALA_PROVIDER}/push/sendPush`,

  // Provider log
  FETCH_SERVC_PRVDR_TMLINE_EVNT: `${API_INSTALA_PROVIDER}/servcPrvdrTmlineEvnt/customFindFilter`,

  // Craftsman log
  FETCH_SERVC_PRVDR_AGNT_TMLINE_EVNT: `${API_INSTALA_PROVIDER}/servcPrvdrAgntTmlineEvnt/customFindFilter`,

  // Async Report
  ENABLE_NOTIFIER_PROVIDER: `${API_INSTALA_PROVIDER}/public/sse-emiter/enable-notifier`,

  // Translation
  LOCALES_LIST: `${API_INSTALA_I18N}/public/locales/list`,

  // Suggest schedule
  SAVE_SERVC_PRVDR_SCHDL_SGSTTN_CUSTOMER_REQUEST: `${API_INSTALA}/public/suggest-schedule/customer/save`,

  CUSTOMER_FETCH_IMG: `${API_INSTALA}/public/order/file`,

  CUSTOMER_REQUEST_SERVICE: `${API_INSTALA}/public/service-request`,

  // Subcategories
  SUBCATEGORIES_FETCH_FILTERED_ITENS: `${API_INSTALA}/servc-catgry-typ/categories`,

  // Budgets
  BUDGET_ATTACHMENT: `${API_INSTALA_BUDGET}/soBudget/budget-attachment`,
  BUDGETS_DASHBOARD: `${API_INSTALA}/soBudget/dashboard`,

  BUDGET_DISTRIBUTED: `${API_INSTALA_BUDGET}/soBudget/budget-ditributed`,

  FETCH_CUSTOMER_PDVS: `${API_INSTALA}/service-request/findFilter`,

  // Elastic
  SERVC_ORD_LIST_ELASTIC: `${API_INSTALA_CORE_ELASTIC}/servcOrd/list-filters-elastic`,

  SERVC_PRVDR_IMPORT_PAYMENT_OCCURRENCE: `${API_INSTALA}/service-provider-registration-payment-event/importProviderPaymentOccurrence`,

  // worksite
  COUNT_TOTAL_WORKSITE: `${API_INSTALA}/orders/worksite-number/count`,
  REMOVE_FROM_CURRENT: `${API_INSTALA}/orders/worksite-number/remove`,
  MOVE_TO_EXISTING: `${API_INSTALA}/orders/worksite-number/update`,

  FETCH_CORES_BY_USER: `${API_INSTALA}/servc-core/byUser`,

  // Bid Mode
  CREATE_BID: `${API_INSTALA}/bid/create`,

  // Provider Analysis
  PROVIDER_ANALYSIS_FILTER: `{API_INSTALA_PROVIDER_REPORTS}/report/provider/list-filter-agg`, // TODO REMOVE
  PROVIDER_EVENT_ANALYSIS_FILTER: `{API_INSTALA_PROVIDER_REPORTS}/report/provider/event/list-filter-agg`, // TODO REMOVE

  // Company
  GET_COMPANY: `${API_INSTALA_PROVIDER}/servcPrvdrReportAnalysis`,

  // Huband Rent
  FETCH_SERVICES: `{API_INSTALA_CONTRACT_CALCULATOR}/service`, // TODO REMOVE
  FETCH_SECTIONS: `{API_INSTALA_CONTRACT_CALCULATOR}/section`, // TODO REMOVE
  HOURLY_SERVICES: `${API_INSTALA}/v1/orders/hourlyServices`,

  // Firebase
  GET_FIREBASE_PARAMETERS: `${API_INSTALA_PARAM}/public/parameter/firebase`,

  SAVE_STORE_APPROVAL: `${API_INSTALA_PROVIDER}/provider/store-approval`,
  SENT_CONTRACT: `${API_INSTALA_PROVIDER}/provider/sent-contract`,

  UPDATE_OS_PARTIAL: `${API_INSTALA}/v1/orders`,

  PROJECT: `${API_INSTALA}/project-executions`,

  // Specialities
  FETCH_SPECIALITIES: `${API_INSTALA}/specialities`,

  // Measurement Sheet
  PUT_MEASUREMENT_SHEET_APPROVAL: `${API_INSTALA}/orders/approval`,
}

export default endpoints
