import API_URL from '../../../core/Envs/endpoints'
import ApiRequest from '../../../core/Http/FetchAdapter'
import store from '../../../redux/store'
// eslint-disable-next-line import/namespace
import { onFetchUserError, onFetchUserSuccess, requestLogin } from '../../../redux/actions/auth'

export async function reloadUser(token: string) {
  store.dispatch(requestLogin())

  return new ApiRequest(token, store.dispatch).get(API_URL.GET_USER_INFO, null).then((response) => {
    if (response.cdStatus !== 200) onFetchUserError('TypeError: Fail to fetch', store.dispatch)

    const path = localStorage?.getItem('lastRouteAccessed') || '/cockpit'
    onFetchUserSuccess(response, store.dispatch, path)
  })
}
