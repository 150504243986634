import { memo } from 'react'
import { useSideNavContext } from '../../../../../../contexts/sidenav'
import MozaicCheckBox from '../../../../../../components/MozaicCheckBox'

type SideNavContext = {
  clusters: any
  handleChangeClusters: Function
}

function ClustersList() {
  const { clusters, handleChangeClusters } = useSideNavContext() as SideNavContext

  return (
    <section className='stores-list-container truly-clusters-list'>
      {(clusters || []).map((cluster: any, index: any) => (
        <section className='branch-checkbox' key={cluster.value}>
          <MozaicCheckBox
            name={cluster.label}
            value={cluster.value}
            label={cluster.label}
            isChecked={cluster.checked}
            isIndeterminate={cluster.isIndeterminate}
            onChange={() => handleChangeClusters(cluster, index)}
          />
        </section>
      ))}
    </section>
  )
}

export default memo(ClustersList)
