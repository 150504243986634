import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import { colourStyles } from '../../../helpers/selectStyle'

class ServiceSpecialistSelect extends Component {
  renderOptions() {
    const { serviceSpecialists } = this.props

    if (serviceSpecialists) {
      return serviceSpecialists.map(this.createOptions)
    }

    return []
  }

  createOptions(serviceSpecialist) {
    return {
      value: `${serviceSpecialist.idEspecialistaServico}`,
      label: serviceSpecialist.nomeEspecialistaServico,
      disabled: !!serviceSpecialist?.notAvailable,
    }
  }

  getValue = (options, servcPrvdrAgntCd) => {
    if (servcPrvdrAgntCd) {
      return options.find((option) => parseInt(option.value) === servcPrvdrAgntCd)
    }
    return null
  }

  render() {
    const { className, value, placeholder, id, disabled, t, onChange, label } = this.props

    const options = this.renderOptions()
    const selected = options.filter((option) => parseInt(option.value) === parseInt(value))

    return (
      <div>
        {typeof (value !== 'undefined' && value !== null) && (
          <label htmlFor={id} className='label up-label'>
            {label}
          </label>
        )}
        <Select
          defaultValue={selected}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          id={id}
          name={id}
          isClearable
          clearValueText={t('option.removeItem')}
          isDisabled={disabled}
          isOptionDisabled={(option) => option.disabled}
          value={selected}
          classNamePrefix='react-select'
          styles={colourStyles}
          noOptionsMessage={() => t('select.noOptionsMessage')}
        />
      </div>
    )
  }
}

ServiceSpecialistSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serviceSpecialists: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default ServiceSpecialistSelect
