import { image1, image2, image3 } from './images'

export const dailyReportsItems = [
  {
    order: 254324,
    type: 'checkIn',
    date: '2023-05-22',
    shift: 'M',
    provider: 'Abcd e Fgh LDTA',
    installer: 'Olandir da Cruz',
    description:
      'Dolore ad est veniam aliquip. Excepteur ut aliquip ex dolor duis eiusmod. Irure voluptate aliquip ad fugiat culpa culpa ullamco qui cupidatat. Ut elit ut reprehenderit pariatur nisi ea in adipisicing sunt.',
    photos: [
      {
        data: image1,
        datetime: '2023-05-22 09:23:42',
      },
      {
        data: image2,
        datetime: '2023-05-22 09:23:57',
      },
      {
        data: image3,
        datetime: '2023-05-22 09:24:12',
      },
    ],
  },
  {
    order: 254324,
    type: 'checkOut',
    date: '2023-05-22',
    shift: 'A',
    provider: 'Abcd e Fgh LDTA',
    installer: 'Olandir da Cruz',
    description:
      'Dolore ad est veniam aliquip. Excepteur ut aliquip ex dolor duis eiusmod. Irure voluptate aliquip ad fugiat culpa culpa ullamco qui cupidatat. Ut elit ut reprehenderit pariatur nisi ea in adipisicing sunt.',
    photos: [
      {
        data: image2,
        datetime: '2023-05-22 18:12:45',
      },
    ],
  },
  {
    order: 254324,
    type: 'checkIn',
    date: '2023-05-22',
    shift: 'M',
    provider: 'Abcd e Fgh LDTA',
    installer: 'Olandir da Cruz',
    photos: [
      {
        data: image1,
        datetime: '2023-05-22 11:23:42',
      },
      {
        data: image3,
        datetime: '2023-05-22 11:24:25',
      },
    ],
  },
]
