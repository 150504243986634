import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'
import { ItemReportProps } from '../../../pages/ServiceOrderDetail/components/Routes/Service/components/ReworkWarranty/components/ActivatedWarranty/components/ItemReport'

const user = new User().currentUser

export const getTechinicalReportImages = async (servcOrdCd: number) => {
  const query = {
    servcOrdSeq: servcOrdCd,
    servcOrdItemAtchmtClsfctnCd: [3, 14],
    actvServcOrdItemAtchmtInd: 1,
  }

  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.FIND_ORDER_SERVC_ATTACHMENT,
      query,
    )

    const techinicalReports = response?.data?.filter(
      (attachment: ItemReportProps) =>
        attachment?.atchmtDocmntTyp === 16 || attachment?.servcOrdItemAtchmtClsfctnCd === 14,
    )

    return techinicalReports
  } catch (error) {
    return console.error(error)
  }
}
