/* eslint-disable react/jsx-no-constructed-context-values */
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import useForceUpdate from '../../hooks/useForceUpdate'
import { fetchClustersOptions } from '../../services/Clusters'
import { getStoresOptions } from '../../services/Stores'
import { setNewCockpitFilters } from '../../redux/actions/newCockpit'
import store from '../../redux/store'

const SideNavContext = createContext({})

function SideNavProvider({ children }) {
  const [openedStores, setOpenedStores] = useState(false)
  const [openedProfileInfo, setOpenedProfileInfo] = useState(false)
  const [openedBuCdModal, setOpenedBuCdModal] = useState(false)
  const [stores, setStores] = useState([])
  const [storesLength, setStoresLength] = useState(0)
  const [clusters, setClusters] = useState([])

  const forceUpdate = useForceUpdate()

  async function handleFetchStores() {
    const data = await getStoresOptions()

    setStoresLength(data.length)

    data.unshift({
      value: 'select-all',
      label: i18next.t('all.text'),
      name: i18next.t('all.text'),
      checked: true,
      isIndeterminate: false,
    })

    setStores(data)
    store.dispatch(setNewCockpitFilters({ codLocal: data }))
  }

  async function handleFetchClusters() {
    const data = await fetchClustersOptions()

    data.unshift({
      value: 'select-all',
      label: i18next.t('all'),
      name: i18next.t('all'),
      checked: true,
      isIndeterminate: false,
    })

    setClusters(data)
  }

  useEffect(() => {
    handleFetchStores()
    handleFetchClusters()
  }, [])

  function handleResolveStoresFiltered() {
    return (stores || []).filter(
        (store) => store.value !== i18next.t('select-all') && store.checked === true,
    )
  }

  function handleFilterCockpitStores() {
    const filteredStores = handleResolveStoresFiltered()

    const allStores = filteredStores.length + 1 === stores.length

    store.dispatch(setNewCockpitFilters({ codLocal: [...filteredStores], allStores }))
  }

  function handleCloseProfileInfo() {
    openedProfileInfo && setOpenedProfileInfo(false)
  }

  function handleCloseStores() {
    openedStores && setOpenedStores(false)
  }

  function handleCloseBuCdModal() {
    setOpenedBuCdModal(false)
  }

  function handleOpenBuCdModal() {
    setOpenedProfileInfo(false)
    setOpenedBuCdModal(true)
  }

  function handleSumStoresSelecteds() {
    return (stores || [])
      .filter((item) => item.value !== i18next.t('select-all'))
      .reduce((accumulator, item) => {
        if (item.checked === true) {
          // eslint-disable-next-line no-param-reassign
          accumulator += 1
        }
        return accumulator
      }, 0)
  }

  function handleSumStoresNotSelecteds() {
    return (stores || [])
      .filter((item) => item.value !== i18next.t('select-all'))
      .reduce((accumulator, item) => {
        if (!item.checked) {
          // eslint-disable-next-line no-param-reassign
          accumulator += 1
        }
        return accumulator
      }, 0)
  }

  function handleToggleProfileInfo() {
    handleCloseStores()
    setOpenedProfileInfo(!openedProfileInfo)
  }

  function handleToggleStores() {
    handleCloseProfileInfo()
    setOpenedStores(!openedStores)
  }

  function handleCheckIndeterminated() {
    const copyStores = stores

    if (copyStores[0].isIndeterminate === null) {
      copyStores[0].isIndeterminate = false
      copyStores[0].checked = false
    }

    setStores(copyStores)
    forceUpdate()
  }

  function verifySelectAllClusters(copyClusters) {
    let isIndeterminate = false

    const count = clusters.length - 1
    const selectAll = copyClusters[0]
    const clustersCount = copyClusters.filter((cluster, i) => {
      if (i) {
        if (cluster.isIndeterminate === null) isIndeterminate = true
        return cluster.checked
      }
    }).length

    switch (clustersCount) {
      case count:
        selectAll.checked = true
        selectAll.isIndeterminate = false
        break
      case 0:
        selectAll.checked = false
        selectAll.isIndeterminate = false
        if (isIndeterminate) {
          selectAll.checked = null
          selectAll.isIndeterminate = null
        }
        break
      default:
        selectAll.checked = null
        selectAll.isIndeterminate = null
        break
    }

    return copyClusters
  }

  function verifyClustersState() {
    let copyClusters = clusters

    copyClusters = copyClusters.map((cluster) => {
      if (cluster.stores) {
        const count = cluster.stores.length
        const coreCd = cluster.value
        const storesCount = stores.filter((store) => {
          if (store?.clusters?.length) {
            if (store?.clusters[0].coreCd === coreCd) return store.checked
          }
        }).length

        switch (storesCount) {
          case count:
            // eslint-disable-next-line no-param-reassign
            cluster.checked = true
            // eslint-disable-next-line no-param-reassign
            cluster.isIndeterminate = false
            break
          case 0:
            // eslint-disable-next-line no-param-reassign
            cluster.checked = false
            // eslint-disable-next-line no-param-reassign
            cluster.isIndeterminate = false
            break
          default:
            // eslint-disable-next-line no-param-reassign
            cluster.checked = null
            // eslint-disable-next-line no-param-reassign
            cluster.isIndeterminate = null
            break
        }
      }
      return cluster
    })

    copyClusters = verifySelectAllClusters(copyClusters)

    setClusters(copyClusters)
    forceUpdate()
  }

  function handleCheckAllStores() {
    const copyStores = stores

    handleCheckIndeterminated()

    const solvedCheck = !copyStores[0].checked

    copyStores.forEach((store) => {
      // eslint-disable-next-line no-param-reassign
      store.checked = solvedCheck
    })

    forceUpdate()
    setStores(copyStores)
    verifyClustersState()
  }

  function handleSetIndeterminated() {
    const copyStores = stores

    if (copyStores[0]) {
      copyStores[0].checked = null
      copyStores[0].isIndeterminate = null
    }

    setStores(copyStores)
    forceUpdate()
  }

  function customSumStoresSelecteds(values) {
    return (values || [])
      .filter((item) => item.value !== i18next.t('select-all'))
      .reduce((accumulator, item) => {
        if (item.checked === true) {
          // eslint-disable-next-line no-param-reassign
          accumulator += 1
        }
        return accumulator
      }, 0)
  }

  function handleSetFirstValue() {
    const copyStores = stores
    const totalStoresChecked = customSumStoresSelecteds(copyStores)

    if (copyStores[0] && totalStoresChecked === storesLength) {
      copyStores[0].checked = true
      copyStores[0].isIndeterminate = false

      setStores(copyStores)
      forceUpdate()
    }

    if (copyStores[0] && totalStoresChecked === 0) {
      copyStores[0].checked = false
      copyStores[0].isIndeterminate = false

      setStores(copyStores)
      forceUpdate()
    }
  }

  function handleCheckOneStore(index) {
    const copyStores = stores

    handleSetIndeterminated()

    copyStores[index].checked = !copyStores[index].checked

    handleSetFirstValue()

    setStores(copyStores)
    verifyClustersState()
    forceUpdate()
  }

  function handleChangeStores(event, index) {
    if (index === 0) handleCheckAllStores()
    else handleCheckOneStore(index)
    handleFilterCockpitStores()
  }

  function verifySelectAllStores(copyStores) {
    const count = stores.length - 1
    const selectAll = copyStores[0]
    const storesCount = copyStores.filter((store, i) => (i ? store.checked : false)).length

    switch (storesCount) {
      case count:
        selectAll.checked = true
        selectAll.isIndeterminate = false
        break
      case 0:
        selectAll.checked = false
        selectAll.isIndeterminate = false
        break
      default:
        selectAll.checked = null
        selectAll.isIndeterminate = null
        break
    }

    return copyStores
  }

  function selectAllClusters(check) {
    const copyClusters = clusters
    let copyStores = stores

    copyClusters.forEach((cluster) => {
      // eslint-disable-next-line no-param-reassign
      cluster.checked = check
      // eslint-disable-next-line no-param-reassign
      cluster.isIndeterminate = false
    })

    copyStores.forEach((store, i) => {
      if (!i) return
      if (store?.clusters?.length) {
        // eslint-disable-next-line no-param-reassign
        store.checked = check
        // eslint-disable-next-line no-param-reassign
        store.isIndeterminate = false
      }
    })

    copyStores = verifySelectAllStores(copyStores)

    setClusters(copyClusters)
    setStores(copyStores)
    handleFilterCockpitStores()
    forceUpdate()
  }

  function handleChangeClusters(cluster, index) {
    if (index === 0) return selectAllClusters(!cluster.checked)

    // eslint-disable-next-line no-param-reassign
    cluster.checked = !cluster.checked
    // eslint-disable-next-line no-param-reassign
    cluster.isIndeterminate = false

    let copyClusters = clusters
    copyClusters[index] = {
      ...cluster,
    }

    const coreCd = cluster.value
    let newStores = stores.map((store) => {
      if (store?.clusters?.length) {
        if (store?.clusters[0].coreCd === coreCd) {
          // eslint-disable-next-line no-param-reassign
          store.checked = cluster.checked
          return store
        }
      }
      return store
    })

    copyClusters = verifySelectAllClusters(copyClusters)
    newStores = verifySelectAllStores(newStores)

    setClusters(copyClusters)
    setStores(newStores)
    handleFilterCockpitStores()
    forceUpdate()
  }

  function handleResolveStoresLabel() {
    const filteredStores = (stores || []).filter(
      (item) => item.value !== i18next.t('select-all') && item.checked,
    )

    if (filteredStores.length === 1) return filteredStores[0].label
    if (filteredStores.length === storesLength) return i18next.t('branches.all.text')
    if (filteredStores.length > 1 && filteredStores.length < storesLength)
      return i18next.t(`count.branches.text`).replace('{total}', filteredStores.length)
    return '-'
  }

  function handleResolveCoresLabel() {
    const all = clusters.length - 1
    const filteredClusters = clusters?.filter((cluster, i) =>
      i ? cluster.checked || cluster.isIndeterminate === null : false,
    )

    switch (filteredClusters.length) {
      case 0:
        return ''
      case 1:
        return filteredClusters[0].label
      case all:
        return i18next.t('cores.all.text')
      default:
        return `${filteredClusters.length} ${i18next.t('lmi.cores').toLowerCase()}`
    }
  }

  const totalBranches = handleSumStoresSelecteds()
  const branchesLabel = handleResolveStoresLabel()
  const coresLabel = handleResolveCoresLabel()

  const providerData = useMemo(
    () => ({
      openedStores,
      setOpenedStores,
      openedProfileInfo,
      setOpenedProfileInfo,
      handleCloseStores,
      handleCloseProfileInfo,
      handleToggleProfileInfo,
      handleToggleStores,
      openedBuCdModal,
      handleOpenBuCdModal,
      handleCloseBuCdModal,
      handleSumStoresSelecteds,
      handleSumStoresNotSelecteds,
      handleResolveStoresLabel,
      handleResolveCoresLabel,
      handleChangeStores,
      handleChangeClusters,
      stores,
      setStores,
      clusters,
      setClusters,
      totalBranches,
      branchesLabel,
      coresLabel,
    }),
    [
      openedStores,
      openedProfileInfo,
      openedBuCdModal,
      stores,
      clusters,
      totalBranches,
      branchesLabel,
      coresLabel,
    ],
  )

  return <SideNavContext.Provider value={providerData}>{children}</SideNavContext.Provider>
}

SideNavProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSideNavContext = () => useContext(SideNavContext)

export { SideNavContext, SideNavProvider, useSideNavContext }
