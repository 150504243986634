import moment from 'moment-timezone'
import i18next from 'i18next'

import FormField from '../../../../../../../../../../components/FormField'
import MozaicBadge from '../../../../../../../../../../components/MozaicBadge'

import {
  ServicesHeaderType,
  statusBadges,
} from '../../../../../../../../../../models/ServiceExecution'
import { TAG_TYPES } from '../../../../../../../../../../enums/ServiceOrder/tags/tag-types'

function Header({ index, service }: ServicesHeaderType) {
  const order = index >= 10 ? index : `0${index}`

  const tags = service.serviceOrderTags
    .map((tag) => TAG_TYPES[tag.tagType])
    .filter((tag) => tag?.labelKey)

  const osNumber = service.servcOrdCd
  const askNumber = service.vaSlsOrdNr
  const scheduleDate = moment(service.servcOrdSchdlgTs).format('L')
  const nextIntervention = '-'

  const statusBadge = statusBadges['01' || service.servcOrdStusCd]

  return (
    <div className='services-header'>
      <div className='info'>
        <div className='title'>
          <div className='number-badge'>{order}</div>
        </div>

        <div className='details'>
          <FormField label={i18next.t('filter.field.os.number')} value={osNumber} />
          <FormField label={i18next.t('pdv.text')} value={askNumber} color='green' />
          <FormField label={i18next.t('filter.field.scheduling.date')} value={scheduleDate} />
          <FormField label={i18next.t('service.exec.next.intervention')} value={nextIntervention} />
        </div>
      </div>

      <div className='services-tags'>
        {tags.map((tag, key) => (
          <MozaicBadge
            text={i18next.t(tag.labelKey)}
            key={`services-tag-${key}-${index}`}
            theme={tag.style}
          />
        ))}
      </div>

      <div className='status'>
        <MozaicBadge text={i18next.t(statusBadge.text)} theme={statusBadge.theme} />
      </div>
    </div>
  )
}

export default Header
