import i18next from 'i18next'
import MozaicButton from '../../../../../../../../components/MozaicButton'

type FooterButtonsType = {
  handleSubmit: Function
  handleClose: Function
  disabled: boolean
}

function FooterButtons(props: FooterButtonsType) {
  const { handleSubmit, handleClose, disabled } = props
  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='neutral' onClick={() => handleClose()}>
        {i18next.t('button.label.cancel')}
      </MozaicButton>
      <MozaicButton isDisabled={disabled} onClick={() => handleSubmit()}>
        {i18next.t('serviceOrders.menuToConfirm')}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
