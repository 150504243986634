import API_URL from '../../../core/Envs/endpoints'
import API_REQUEST from '../../../core/Http/FetchAdapter'
import store from '../../../redux/store'

import { GCloudFileRef } from './GCloudFileRef'
import { GCloudImageRef } from './GCloudImageRef'
import { AttachmentV2, extToMime, imgExts } from './models'

export const getFileRef = (file: AttachmentV2) => {
  const name = file.servcOrdItemAtchmtDesc

  const splitName = name.split('.')
  const ext = splitName.pop() as keyof typeof extToMime
  const isImg = imgExts.includes(ext)

  if (isImg) return new GCloudImageRef(file)

  return new GCloudFileRef(file)
}

export const fetchAttachmentsV2 = async (osNumber: number) => {
  const payload = {
    servcOrdSeq: osNumber,
    fileTyp: '',
    downloadFiles: false,
  }

  try {
    const { data: files } = await new API_REQUEST(null, store.dispatch).get(
      API_URL.ORDER_SERVC_ATTACHMENT_V2,
      payload,
    )

    const fileRefs = files.map((file: AttachmentV2) => getFileRef(file))

    return fileRefs as GCloudFileRef[]
  } catch (err) {
    return Promise.reject(err)
  }
}
