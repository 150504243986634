import { keyBy } from 'lodash'
import { MAP_MARKER, MAP_TYPE } from '../../../constants/config'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import {
  CLEAR_COCKPIT_ALERT_VALUES,
  CLEAR_COCKPIT_CHAT_VALUES,
  CLEAR_COCKPIT_SERVICE_ON_TIME_VALUES,
  COCKPIT_UPDATE_CHAT_LIST,
  FETCH_COCKPIT_ALERTS_ERROR,
  FETCH_COCKPIT_ALERTS_REQUEST,
  FETCH_COCKPIT_ALERTS_SUCCESS,
  FETCH_COCKPIT_ALERT_ELASTIC_ERROR,
  FETCH_COCKPIT_ALERT_ELASTIC_REQUEST,
  FETCH_COCKPIT_ALERT_ELASTIC_SUCCESS,
  FETCH_COCKPIT_CHAT_LIST_ERROR,
  FETCH_COCKPIT_CHAT_LIST_REQUEST,
  FETCH_COCKPIT_CHAT_LIST_SUCCESS,
  FETCH_COCKPIT_CHAT_NOTIFICATIONS_SUCCESS,
  FETCH_COCKPIT_CHAT_SOLVED_SUCCESS,
  FETCH_COCKPIT_SERVICE_ON_TIME_ERROR,
  FETCH_COCKPIT_SERVICE_ON_TIME_REQUEST,
  FETCH_COCKPIT_SERVICE_ON_TIME_SUCCESS,
  FETCH_NEW_COCKPIT_MARKERS_ERROR,
  FETCH_NEW_COCKPIT_MARKERS_REQUEST,
  FETCH_NEW_COCKPIT_MARKERS_SUCCESS,
  NEW_COCKPIT_FILTERS_CHANGED,
} from '../../actionTypes/newCockpit'

function getIcon(type) {
  // tipo (1-novos, 2-distribuir, 3-em execução, 4-retrabalhos, 5-executando, 6-aguardando)
  let pathIcon = './../../assets/images/'
  switch (type) {
    case MAP_MARKER.EXECUTION:
      pathIcon += 'orange_dot.png'
      break
    case MAP_MARKER.DISTRIBUTE:
      pathIcon += 'blue_dot.png'
      break
    case MAP_MARKER.NEW:
      pathIcon += 'green_dot.png'
      break
    case MAP_MARKER.REWORK:
      pathIcon += 'red_dot.png'
      break
    case MAP_MARKER.EXECUTING:
      pathIcon += 'ltblue_dot.png'
      break
    case MAP_MARKER.CONNECTED:
      pathIcon += 'pink_dot.png'
      break
    case MAP_MARKER.FREE:
      pathIcon += 'ltgreen_dot.png'
      break
  }

  return pathIcon
}

const DEFAULT_STATE = {
  list: {
    records: {},
    filter: '',
    error: null,
    isFetching: false,
  },
  filters: {
    codLocal: [],
    allStores: true,
  },
  servicesOnTime: {
    data: {},
    error: null,
    isFetching: false,
  },
  mapMarkers: {
    records: [],
    error: null,
    isFetching: false,
  },
  alerts: {
    data: [],
    error: null,
    isFetching: false,
  },
  alertsElastic: {
    data: [],
    error: null,
    isFetching: false,
  },
}

//* *@deprecated */
const list = (state = DEFAULT_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_COCKPIT_CHAT_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case FETCH_COCKPIT_CHAT_LIST_SUCCESS:
      const records = keyBy(payload, (chatRecord) => chatRecord.uid)

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: null,
          records,
        },
      }

    case FETCH_COCKPIT_CHAT_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          records: {},
          error: payload,
        },
      }

    case CLEAR_COCKPIT_CHAT_VALUES:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: null,
          records: {},
        },
      }

    case FETCH_COCKPIT_CHAT_NOTIFICATIONS_SUCCESS:
      const chatRecord = state.list.records[payload.uid]

      return {
        ...state,
        list: {
          ...state.list,
          records: {
            ...state.list.records,
            [payload.uid]: {
              ...chatRecord,
              solved: false,
              unreadQty: (chatRecord.unreadQty || 0) + payload.qty,
            },
          },
        },
      }

    case FETCH_COCKPIT_CHAT_SOLVED_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          records: {
            ...state.list.records,
            [payload.uid]: {
              ...state.list.records[payload.uid],
              solved: payload.solved,
            },
          },
        },
      }

    case COCKPIT_UPDATE_CHAT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          records: {
            ...state.list.records,
            [payload.uid]: {
              ...state.list.records[payload.uid],
              ...payload.values,
            },
          },
        },
      }

    case NEW_COCKPIT_FILTERS_CHANGED:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case FETCH_COCKPIT_SERVICE_ON_TIME_REQUEST:
      return {
        ...state,
        servicesOnTime: {
          ...state.servicesOnTime,
          isFetching: true,
        },
      }

    case FETCH_COCKPIT_SERVICE_ON_TIME_SUCCESS:
      return {
        ...state,
        servicesOnTime: {
          ...state.servicesOnTime,
          isFetching: false,
          data: payload,
          error: null,
        },
      }

    case FETCH_COCKPIT_SERVICE_ON_TIME_ERROR:
      return {
        ...state,
        servicesOnTime: {
          ...state.servicesOnTime,
          isFetching: false,
          error: payload,
          data: {},
        },
      }

    case FETCH_NEW_COCKPIT_MARKERS_REQUEST:
      return {
        ...state,
        mapMarkers: {
          ...state.mapMarkers,
          isFetching: true,
        },
      }

    case FETCH_NEW_COCKPIT_MARKERS_SUCCESS:
      payload.forEach((record) => {
        if (record.type === MAP_TYPE.SERVICES) {
          if (record.serviceOrder.servcOrdPriortyCd === '1') {
            // eslint-disable-next-line no-param-reassign
            record.serviceOrder.type = MAP_MARKER.REWORK
          } else {
            switch (record.serviceOrder.servcOrdStusCd) {
              case '00':
                // eslint-disable-next-line no-param-reassign
                record.serviceOrder.type = MAP_MARKER.NEW
                break
              case '01':
                // eslint-disable-next-line no-param-reassign
                record.serviceOrder.type = MAP_MARKER.DISTRIBUTE
                break
              case '05':
                // eslint-disable-next-line no-param-reassign
                record.serviceOrder.type = MAP_MARKER.EXECUTION
                break
              case '07':
                // eslint-disable-next-line no-param-reassign
                record.serviceOrder.type = MAP_MARKER.REWORK
                break
              default:
                break
            }
          }

          // eslint-disable-next-line no-param-reassign
          record.serviceOrder.icon = getIcon(record.serviceOrder.type)
          // eslint-disable-next-line no-param-reassign
          record.serviceOrder.visible = true
          // eslint-disable-next-line no-param-reassign
          record.serviceOrder.position = {
            lat: record.serviceOrder.latitudeEndereco,
            lng: record.serviceOrder.longitudeEndereco,
          }
        } else {
          if (record.installer.isFree) {
            // eslint-disable-next-line no-param-reassign
            record.installer.type = MAP_MARKER.FREE
          } else {
            switch (record.installer.servcOrdStusCd) {
              case ServiceOrderStatusType.STATUS_EM_REALIZACAO.id:
                // eslint-disable-next-line no-param-reassign
                record.installer.type = MAP_MARKER.EXECUTING
                break
              default:
                // eslint-disable-next-line no-param-reassign
                record.installer.type = MAP_MARKER.CONNECTED
                break
            }
          }

          // eslint-disable-next-line no-param-reassign
          record.installer.icon = getIcon(record.installer.type)
          // eslint-disable-next-line no-param-reassign
          record.installer.visible = true
          // eslint-disable-next-line no-param-reassign
          record.installer.position = {
            lat: record.installer.latitude,
            lng: record.installer.longitude,
          }
        }
      })

      return {
        ...state,
        mapMarkers: {
          ...state.mapMarkers,
          isFetching: false,
          error: null,
          records: payload,
        },
      }

    case FETCH_NEW_COCKPIT_MARKERS_ERROR:
      return {
        ...state,
        mapMarkers: {
          ...state.mapMarkers,
          isFetching: false,
          records: [],
          error: action.payload,
        },
      }

    case CLEAR_COCKPIT_SERVICE_ON_TIME_VALUES:
      return {
        ...state,
        servicesOnTime: {
          ...state.servicesOnTime,
          isFetching: false,
          data: {},
          error: null,
        },
      }

    case CLEAR_COCKPIT_ALERT_VALUES:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: false,
          data: [],
          error: null,
        },
      }

    case FETCH_COCKPIT_ALERTS_REQUEST:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: true,
        },
      }

    case FETCH_COCKPIT_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: false,
          data: payload,
          error: null,
        },
      }

    case FETCH_COCKPIT_ALERTS_ERROR:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: false,
          error: payload,
          data: [],
        },
      }

    case FETCH_COCKPIT_ALERT_ELASTIC_REQUEST:
      return {
        ...state,
        alertsElastic: {
          ...state.alertsElastic,
          isFetching: true,
        },
      }

    case FETCH_COCKPIT_ALERT_ELASTIC_SUCCESS:
      return {
        ...state,
        alertsElastic: {
          ...state.alertsElastic,
          isFetching: false,
          error: null,
          data: action.payload,
        },
      }

    case FETCH_COCKPIT_ALERT_ELASTIC_ERROR:
      return {
        ...state,
        alertsElastic: {
          ...state.alertsElastic,
          isFetching: false,
          data: [],
          error: action.payload,
        },
      }

    default:
      return state
  }
}



export default list
