import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import { DatesStateType, StorageDatesType } from '../models/dates'

function DatesState(storageFilters: StorageDatesType) {
  const [createStartDate, setCreateStartDate] = useState<Moment | null>(null)
  const [createEndDate, setCreateEndDate] = useState<Moment | null>(null)

  const [scheduleStartDate, setScheduleStartDate] = useState<Moment | null>(null)
  const [scheduleEndDate, setScheduleEndDate] = useState<Moment | null>(null)

  const [executeStartDate, setExecuteStartDate] = useState<Moment | null>(null)
  const [executeEndDate, setExecuteEndDate] = useState<Moment | null>(null)

  const [finallyStartDate, setFinallyStartDate] = useState<Moment | null>(null)
  const [finallyEndDate, setFinallyEndDate] = useState<Moment | null>(null)

  useEffect(() => {
    if (storageFilters) {
      const createTs = storageFilters.creatTs ? moment(storageFilters.creatTs) : null
      const creatTsEnd = storageFilters.creatTsEnd ? moment(storageFilters.creatTsEnd) : null
      const servcOrdSchdlgTs = storageFilters.servcOrdSchdlgTs
        ? moment(storageFilters.servcOrdSchdlgTs)
        : null
      const servcOrdSchdlgTsEnd = storageFilters.servcOrdSchdlgTsEnd
        ? moment(storageFilters.servcOrdSchdlgTsEnd)
        : null
      const servcXecutnStrtDt = storageFilters.servcXecutnStrtDt
        ? moment(storageFilters.servcXecutnStrtDt)
        : null
      const servcXecutnStrtDtEnd = storageFilters.servcXecutnStrtDtEnd
        ? moment(storageFilters.servcXecutnStrtDtEnd)
        : null
      const servcOrdItemEvntDt = storageFilters.servcOrdItemEvntDt
        ? moment(storageFilters.servcOrdItemEvntDt)
        : null
      const servcOrdItemEvntDtFim = storageFilters.servcOrdItemEvntDtFim
        ? moment(storageFilters.servcOrdItemEvntDtFim)
        : null

      setCreateStartDate(createTs)
      setCreateEndDate(creatTsEnd)
      setScheduleStartDate(servcOrdSchdlgTs)
      setScheduleEndDate(servcOrdSchdlgTsEnd)
      setExecuteStartDate(servcXecutnStrtDt)
      setExecuteEndDate(servcXecutnStrtDtEnd)
      setFinallyStartDate(servcOrdItemEvntDt)
      setFinallyEndDate(servcOrdItemEvntDtFim)
    }
  }, [storageFilters])

  const getDefaultState = () => ({
    creatTs: '',
    creatTsEnd: '',
    servcOrdSchdlgTs: '',
    servcOrdSchdlgTsEnd: '',
    servcXecutnStrtDt: '',
    servcXecutnStrtDtEnd: '',
    servcOrdItemEvntDt: '',
    servcOrdItemEvntDtFim: '',
  })

  const getCurrentState = () => ({
    creatTs: createStartDate?.format() || '',
    creatTsEnd: createEndDate?.format() || '',
    servcOrdSchdlgTs: scheduleStartDate?.format() || '',
    servcOrdSchdlgTsEnd: scheduleEndDate?.format() || '',
    servcXecutnStrtDt: executeStartDate?.format() || '',
    servcXecutnStrtDtEnd: executeEndDate?.format() || '',
    servcOrdItemEvntDt: finallyStartDate?.format() || '',
    servcOrdItemEvntDtFim: finallyEndDate?.format() || '',
  })

  const clearState = () => {
    setCreateStartDate(null)
    setCreateEndDate(null)
    setScheduleStartDate(null)
    setScheduleEndDate(null)
    setExecuteStartDate(null)
    setExecuteEndDate(null)
    setFinallyStartDate(null)
    setFinallyEndDate(null)
  }

  return {
    createStartDate,
    createEndDate,
    scheduleStartDate,
    scheduleEndDate,
    executeStartDate,
    executeEndDate,
    finallyStartDate,
    finallyEndDate,
    setCreateStartDate,
    setCreateEndDate,
    setScheduleStartDate,
    setScheduleEndDate,
    setExecuteStartDate,
    setExecuteEndDate,
    setFinallyStartDate,
    setFinallyEndDate,
    getCurrentState,
    getDefaultState,
    clearState,
  } as DatesStateType
}

export default DatesState
