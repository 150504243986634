import { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import { colourStyles } from '../../../helpers/selectStyle'

class ServiceSelect extends Component {
  state = {
    options: [],
    error: null,
    loading: false,
  }

  findTechnicalVisits = () => {
    const { user, dispatch, plntCd, productTypeId } = this.props

    this.setState({ loading: true })

    const params = {
      locationId: plntCd,
      productTypeId,
    }

    return new Api(user.dsToken, dispatch)
      .post(API_URL.SERVC_TP_FIND, params)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(technicalVisits) {
    const options = technicalVisits.map((technicalVisit) => ({
      value: technicalVisit.value,
      label: technicalVisit.label,
      name: technicalVisit.label,
    }))

    this.setState({ options, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, options: [] })
  }

  componentDidMount() {
    this.findTechnicalVisits()
  }

  render() {
    const { t, id, value, name, onChange, label } = this.props
    const { options, loading } = this.state

    const placeholder = loading ? t('option.loading') : label

    return (
      <div>
        <Select
          id={id}
          name={name}
          value={options.filter((option) => option.value === value)}
          onChange={(event) => onChange(event)}
          placeholder={' '}
          options={options}
          isClearable
          resetValue={[]}
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          styles={colourStyles}
        />

        <label htmlFor={id} className='label dateInputLabel'>
          {placeholder}
        </label>
      </div>
    )
  }
}

ServiceSelect.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  plntCd: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  value: PropTypes.any,
  productTypeId: PropTypes.string,
}

export default ServiceSelect
