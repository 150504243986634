import { useEffect, useState } from 'react'
import { CHANNEL_TYPES } from '../../../constants/config'
import { SellOriginStateType, StorageSellOriginType } from '../models/sellOrigin'
import { optionType } from '../models/provider'

function SellOriginState(storageFilters: StorageSellOriginType) {
  const [stores, setStores] = useState<Array<optionType>>([])
  const [channel, setChannel] = useState<optionType | null>(null)

  useEffect(() => {
    if (storageFilters) {
      const { slsChnlTyp, lsBranch } = storageFilters

      if (slsChnlTyp)
        setChannel({
          value: slsChnlTyp,
          label: CHANNEL_TYPES[slsChnlTyp as keyof typeof CHANNEL_TYPES],
        })

      setStores(lsBranch)
    }
  }, [storageFilters])

  const getDefaultState = () => ({
    lsBranch: [],
    lsPlntCd: [],
    slsChnlTyp: '',
  })

  const getCurrentState = () => ({
    lsBranch: stores,
    lsPlntCd: stores.map((store) => store.value),
    slsChnlTyp: channel?.value || '',
  })

  const clearState = () => {
    setStores([])
    setChannel(null)
  }

  return {
    stores,
    channel,
    setStores,
    setChannel,
    getCurrentState,
    getDefaultState,
    clearState,
  } as SellOriginStateType
}

export default SellOriginState
