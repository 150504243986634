import Accordeon from '../../../../../../../components/Accordeon'
import DetailsTable from './components/DetailsTable'
import DetailsOrder from './components/DetailsOrder'

import MozaicIcon from '../../../../../../../components/MozaicIcon'

const accordeonHeader = {
  title: 'service.details.title.text',
  icon: <MozaicIcon icon='ListStandard24' size={25} mr={-5} />,
}

function OrderDetails() {
  return (
    <div id='order-details-container'>
      <Accordeon header={accordeonHeader}>
        <DetailsOrder />
        <DetailsTable />
      </Accordeon>
    </div>
  )
}

export default OrderDetails
