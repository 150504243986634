import i18next from 'i18next'
import PropTypes from 'prop-types'
import { months } from '../../../../helpers/periods'
import { useProviderAnalysisContext } from '../../context'

function TitleExport({ filter, keys }) {
  const { storesOptions } = useProviderAnalysisContext()

  function handleResolveStoreText() {
    const stores = filter.stores
    const selectStoresLength = stores.length
    const allOptionsStoresLength = storesOptions.length

    if (selectStoresLength === allOptionsStoresLength) return i18next.t('branches.all.text')

    if (selectStoresLength === 1) return stores[0].label

    return i18next
      .t('page.service.provider.analysis.subtitle.stores.counter')
      .replace('{0}', selectStoresLength)
  }

  function handleGetTitleExport() {
    const periodSelected = filter.period.selected
    const periodType = filter.period.type

    let periodText

    if (keys.informationToCompare) {
      periodText = `${i18next.t(`provider.detail.information.${keys.information}`)} x ${i18next.t(
        `provider.detail.information.${keys.informationToCompare}`,
      )}`
    } else {
      periodText = `${i18next.t(`provider.detail.information.${keys.information}`)}`
    }

    if (!periodSelected || periodSelected === '0') {
      periodText +=
        periodType === 'yearly'
          ? ` - ${i18next.t('last.twelve.months.text')}`
          : ` - ${i18next.t('last.thirty.days')}`
      return periodText
    }

    if (periodType === 'monthly') {
      const splitedPeriod = periodSelected.split('/')
      const monthText = months[splitedPeriod[0]]
      periodText += ` - ${i18next.t(monthText)}/${splitedPeriod[1]}`
    } else {
      periodText += ` - ${periodSelected}`
    }

    return periodText
  }

  function handleGetSubtitleExport() {
    const storeText = handleResolveStoreText()

    return `${storeText}.`
  }

  const title = handleGetTitleExport()
  const subtitle = handleGetSubtitleExport()

  if (filter.results.length === 0)
    return (
      <div id='provider-analysis-list-title-export'>
        <section className='labels'>
          <span className='title'>{i18next.t('provider.detail.title.no.results')}</span>
        </section>
      </div>
    )

  return (
    <div id='provider-analysis-list-title-export'>
      <section className='labels'>
        <span className='title'>{title || ''}</span>
        <span className='subtitle'>{subtitle || ''}</span>
      </section>
    </div>
  )
}

TitleExport.propTypes = {
  filter: PropTypes.object.isRequired,
  keys: PropTypes.object.isRequired,
}

export default TitleExport
