import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useModals,
  Loader,
  useNotifications,
} from '@mozaic-ds/react'
import i18n from 'i18next'
import InstallersListContext from '../context'
import { Installer } from '../../../services/installers/fetchInstallersList'
import { deleteInstaller } from '../../../services/installers/deleteInstaller'
import { ADEOResponseError } from '../../../models/AdeoResponse'

interface RemoveModalProps {
  installer?: Installer
}

/**
 * installers list remove modal
 */
function RemoveModal({ installer }: RemoveModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { open, close } = useModals()
  const { setPage, setFilter, filter } = useContext(InstallersListContext)
  const notification = useNotifications()

  const modalId = 'installers-list-delete-modal'

  useEffect(() => {
    if (installer) open(modalId)
  }, [installer])

  const onRemove = () => {
    if (!installer) return
    setIsLoading(true)

    deleteInstaller(installer.servcPrvdrAgntId)
      .then(() => {
        notification.success(
          {
            title: i18n.t('pwa.toast.success.message'),
            message: i18n.t('serviceSpecialist.delete.success'),
            duration: 8000,
          },
          'top',
        )
        close(modalId)
        setFilter({ ...filter })
        setPage(1)
      })
      .catch((error: ADEOResponseError) => {
        console.error('error on delete installer', error)
        notification.danger(
          {
            title: i18n.t('pwa.toast.error.message'),
            message: error?.message?.dsMessage || '',
            duration: 8000,
          },
          'top',
        )
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Modal id={modalId} canCloseOnOverlayClick={false} focusedElementIndex={-1}>
      <ModalHeader>
        <ModalTitle>{i18n.t('button.label.remove')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Text>{i18n.t('serviceSpecilaist.confirm.delete')}</Text>
      </ModalBody>
      <ModalFooter>
        <Button
          size='m'
          variant='bordered'
          theme='primary'
          width='full'
          onClick={() => {
            close(modalId)
          }}
        >
          {i18n.t('cancel.label')}
        </Button>
        <Button
          size='m'
          theme='danger'
          width='full'
          onClick={onRemove}
          isDisabled={isLoading}
          className='remove-btn'
        >
          {isLoading ? <Loader size='m' theme='light' /> : i18n.t('button.label.remove')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RemoveModal
