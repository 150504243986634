import { useState } from 'react'
import i18next from 'i18next'

import User from '../../../../../../../../../core/User'
import CustomCard from '../../../../../../../../../components/Card'
import FormField from '../../../../../../../../../components/FormField'

import { formatNationalOrInternationalPhoneNumber } from '../../../../../../../../../helpers/InternationalPhoneNumberUtils'
import { useServiceOrderDetailContext } from '../../../../../../../context'
import PhoneBoxModal from './components/Modal'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'

const formatPhone = (phone?: string) => formatNationalOrInternationalPhoneNumber(null, phone)

function PhoneBox() {
  const { client, loadingClient } = useServiceOrderDetailContext()

  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const [showModal, setShowModal] = useState<boolean>(false)

  const homePhone = formatPhone(client?.custHmPhonNr) || '-'
  const mobilePhone = formatPhone(client?.custMobilePhonNr) || '-'
  const businessPhone = formatPhone(client?.custBusnsPhonNr) || '-'

  const handleAction = accessFunctionList?.includes(84) ? () => setShowModal(true) : null

  const handleCloseModal = () => setShowModal(false)

  return (
    <>
      <CustomCard
        title={i18next.t('customer.service.order.details.phones')}
        iconNode={<MozaicIcon icon='ContactPhone24' />}
        onClickEditButton={handleAction}
      >
        <div className='row' id='phone-box-detail'>
          <div className='col-xs-12'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('serviceOrders.phone1')}
              value={mobilePhone}
            />
          </div>
          <div className='col-xs-12  mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('serviceOrders.phone3')}
              value={homePhone}
            />
          </div>
          <div className='col-xs-12  mt-2'>
            <FormField
              isFetching={loadingClient}
              label={i18next.t('serviceOrders.phone2')}
              value={businessPhone}
            />
          </div>
        </div>
      </CustomCard>
      {showModal && <PhoneBoxModal isOpen={showModal} onClose={handleCloseModal} />}
    </>
  )
}

export default PhoneBox
