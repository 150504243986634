import JQuerySoap from 'jquery.soap'

/*
 * Class para lidar com as requests para API da Five9
 */
class ApiFive9 {
  request(method, data) {
    return new Promise((resolve, reject) => {
      JQuerySoap({
        url: 'http://localhost:8080/agent/v2',
        appendMethodToURL: false,
        noPrefix: true,

        method,

        namespaceQualifier: 'urn',
        namespaceURL: 'urn:core_v2.agent.api.five9.com',

        data: {
          ...data,
        },

        success: resolve,
        error: reject,
      })
    })
  }
}

export default ApiFive9
