import { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { colourStyles } from '../../../helpers/selectStyle'

class ServiceProviderSelect extends Component {
  renderOptions() {
    const { serviceProviders, t } = this.props

    if (serviceProviders) {
      if (serviceProviders.length > 0)
        return serviceProviders.map((prvdr) => this.createCustomOptions(prvdr, t))
    }
    return []
  }

  createCustomOptions(provider, t) {
    const hasSlots = provider.slotsAvailability !== undefined
    const hasAbsence = provider.absent

    const slotsAvailability = () => {
      if (hasSlots && !provider.slotsAvailability)
        return `${provider.servcPrvdrTrdNm} ${t('servcPrvdrTrdNm.no.availability.label')}`
      if (hasAbsence) return `${provider.servcPrvdrTrdNm} ${t('servcPrvdrTrdNm.has.absence')}`

      return provider.servcPrvdrTrdNm
    }

    const servcPrvdrTrdNmLabel = !hasSlots ? t('select.noOptionsMessage') : slotsAvailability()

    return {
      value: provider.servcPrvdrId,
      label: servcPrvdrTrdNmLabel,
      servcPrvdrTrdNm: provider.servcPrvdrTrdNm,
      slotsAvailability: provider.slotsAvailability,
      absent: provider.absent,
      disabled: !provider.slotsAvailability || provider.absent,
    }
  }

  getValue = (options, servcPrvdrCd) => {
    if (servcPrvdrCd) {
      return options.find((option) => parseInt(option.value) === parseInt(servcPrvdrCd))
    }
    return null
  }

  render() {
    const {
      className,
      value,
      onChange,
      placeholder,
      label,
      id,
      onInputChange,
      disabled,
      t,
      handleOpenServiceProviderAdvancedSearch,
      style,
    } = this.props
    const options = this.renderOptions()

    const selected = options.filter((option) => parseInt(option.value) === parseInt(value))
    return (
      <div>
        {typeof (value !== 'undefined' && value !== null) && (
          <label htmlFor={id} className='label up-label'>
            {label}
          </label>
        )}
        <Select
          defaultValue={selected}
          className={className}
          placeholder={placeholder}
          label={label}
          onChange={onChange}
          options={options}
          id={id}
          name={id}
          isClearable
          resetValue=''
          clearValueText={t('option.removeItem')}
          onInputChange={onInputChange}
          isDisabled={disabled}
          isOptionDisabled={(option) => option.disabled}
          value={selected}
          classNamePrefix='react-select'
          styles={style || colourStyles}
          noOptionsMessage={() => t('select.noOptionsMessage')}
        />
        {!disabled && handleOpenServiceProviderAdvancedSearch && (
          <a className='small' onClick={handleOpenServiceProviderAdvancedSearch}>
            {t('serviceOrders.advancedSearch')}
          </a>
        )}
      </div>
    )
  }
}

ServiceProviderSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  serviceProviders: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleOpenServiceProviderAdvancedSearch: PropTypes.func,
  t: PropTypes.func.isRequired,
  style: PropTypes.any,
}

export default ServiceProviderSelect
