import {
  FETCH_SERVICE_ORDER_MAIL_REQUEST,
  FETCH_SERVICE_ORDER_MAIL_SUCCESS,
  FETCH_SERVICE_ORDER_MAIL_ERROR,
} from '../../actionTypes/serviceOrderMail'

const DEFAULT_STATE = {
  get: {
    record: {},
    error: null,
    isFetching: false,
  },
}

const record = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SERVICE_ORDER_MAIL_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          isFetching: true,
        },
      }

    case FETCH_SERVICE_ORDER_MAIL_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isFetching: false,
          error: null,
          record: action.payload,
        },
      }

    case FETCH_SERVICE_ORDER_MAIL_ERROR:
      return {
        ...state,
        get: {
          ...state.get,
          isFetching: false,
          record: {},
          error: action.payload,
        },
      }

    default:
      return state
  }
}

export default record
