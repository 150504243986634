import i18next from 'i18next'

import Accordeon from '../../../../../../../../components/Accordeon'

import ChannelSelect from '../../../../../../../../components/ChannelSelect'
import StoreSelect from '../../../../../../../../components/StoreSelect'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { SellOriginBoxType } from '../../models/sellOrigin'
import { WithContext } from './context'

function SellOriginBox({ stores, setStores, channel, setChannel }: SellOriginBoxType) {
  const title = {
    title: i18next.t('advanced.search.sell.origin.title'),
    icon: <MozaicIcon icon='BasketStandard24' className='icon-custom' />,
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  return (
    <div className='sell-origin-box'>
      <Accordeon header={title} styled={false} canCollapse={false}>
        <div className='input-group'>
          <ChannelSelect value={channel} onChange={setChannel} />
          <StoreSelect value={stores} onChange={setStores} />
        </div>
      </Accordeon>
    </div>
  )
}

export default WithContext(SellOriginBox)
