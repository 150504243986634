import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

export const getClient = async (servcOrdCd: number) => {
  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).get(
      `${API_URL.GET_SERVICE_ORDER_CLIENT}/${servcOrdCd}`,
      {},
    )

    return response.data
  } catch (error) {
    return console.error(error)
  }
}
