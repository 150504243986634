import i18next from 'i18next'
import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MozaicIcon from '../../../../components/MozaicIcon'
import { statusBarIndicators } from '../../../../constants/statusBarIndicators'
import StatusIndicator from './components/StatusIndicator'

/**
 * @description container to show for the statuses data on top page cockipt
 */

function StatusBarContainer({ auth }) {
  const indicators = useMemo(
    () => statusBarIndicators(i18next, auth.setupParameters),
    [auth.setupParameters],
  )

  const lastIndex = indicators.length - 1

  return (
    <div id='status-bar-container'>
      {indicators.map((status, index) => (
        <Fragment key={status.type}>
          <StatusIndicator alert={status} key={status.type} />

          {!(lastIndex === index) && (
            <MozaicIcon icon='ArrowArrowRight24' className='arrow-icon separator-status' />
          )}
        </Fragment>
      ))}
    </div>
  )
}

StatusBarContainer.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StatusBarContainer)
