import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

export async function setBuCd(params: { buCd: number }) {
  return new API_REQUEST(user.dsToken, store.dispatch).post(API_URL.SET_BU_CD, params)
}
