import PropTypes from 'prop-types'
import { Component } from 'react'
import { renderToastWarn } from '../../helpers/ToastUtils'
import { WithForm } from '../../helpers/useForm/withForm'
import MozaicTextArea from '../MozaicTextArea'
import Col from './Col'
import Modal from './Modal'
import Row from './Row'

class ConfirmModal extends Component {
  handleAction = () => {
    const {
      closeModal,
      action,
      values,
      textArea,
      errorFn,
      t,
      textAreaCheckIsEmpty,
      validation,
      params,
    } = this.props

    if (params) {
      const updatedParams = [...params]

      if (textArea) {
        if (!values.dsText && textAreaCheckIsEmpty) {
          return errorFn(t('confirmModal.dsText.isEmpty'))
        }
        if (validation) {
          const result = this.handleValidation(values.dsText)
          if (!result) {
            return renderToastWarn({ message: t('textValidation') })
          }
        }
        updatedParams.push(values.dsText)
      }
      action(...updatedParams)
    } else {
      action()
    }
    closeModal()
  }

  handleValidation = (text) => {
    const atLeastOneAlfhabeticLetter = /[a-zA-Z]/
    // Tests if text have at least one alphabetic letter and text length is greater than 10
    if (RegExp(atLeastOneAlfhabeticLetter).test(text) && text.length >= 10) {
      return true
    }
  }

  render() {
    const {
      t,
      closeModal,
      message,
      messageHeader,
      textArea,
      textAreaLabel,
      cancelText,
      submitText,
      register,
    } = this.props

    return (
      <Modal closeModal={closeModal} size='sm'>
        <form>
          {messageHeader && (
            <Row>
              <Col xs={12} className='margin-bottom'>
                <h4 className='margin-top margin-bottom-small'>{t(messageHeader)}</h4>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} className='margin-bottom'>
              <span className='margin-top margin-bottom-small'>{t(message)}</span>
            </Col>
          </Row>

          {textArea && (
            <Row>
              <Col xs={12} className='margin-bottom'>
                <MozaicTextArea {...register('dsText')} label={t(textAreaLabel)} rows={6} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button-hollow button-hollow-primary button-full align-center'
                type='button'
                onClick={closeModal}
              >
                {cancelText || t('cancel_button_label')}
              </button>
            </Col>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button button-primary button-full'
                type='button'
                onClick={this.handleAction}
              >
                {submitText || t('option.yes')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

ConfirmModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.func,
  message: PropTypes.string,
  messageHeader: PropTypes.string,
  params: PropTypes.array,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errorFn: PropTypes.func,
  textArea: PropTypes.bool,
  validation: PropTypes.bool,
  textAreaCheckIsEmpty: PropTypes.bool,
  textAreaLabel: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  register: PropTypes.func,
}

const FIELDS = { dsText: '' }

export default WithForm(FIELDS)(ConfirmModal)

export { ConfirmModal }
