export const FETCH_COCKPIT_ALERT_ELASTIC_REQUEST = 'FETCH_COCKPIT_ALERT_ELASTIC_REQUEST'
export const FETCH_COCKPIT_ALERT_ELASTIC_SUCCESS = 'FETCH_COCKPIT_ALERT_ELASTIC_SUCCESS'
export const FETCH_COCKPIT_ALERT_ELASTIC_ERROR = 'FETCH_COCKPIT_ALERT_ELASTIC_ERROR'


export const FETCH_COCKPIT_CHAT_LIST_REQUEST = 'FETCH_COCKPIT_CHAT_LIST_REQUEST'
export const FETCH_COCKPIT_CHAT_LIST_SUCCESS = 'FETCH_COCKPIT_CHAT_LIST_SUCCESS'
export const FETCH_COCKPIT_CHAT_LIST_ERROR = 'FETCH_COCKPIT_CHAT_LIST_ERROR'
export const CLEAR_COCKPIT_CHAT_VALUES = 'CLEAR_COCKPIT_CHAT_VALUES'
export const FETCH_COCKPIT_CHAT_NOTIFICATIONS = 'FETCH_COCKPIT_CHAT_NOTIFICATIONS'
export const FETCH_COCKPIT_CHAT_NOTIFICATIONS_SUCCESS = 'FETCH_COCKPIT_CHAT_NOTIFICATIONS_SUCCESS'
export const FETCH_COCKPIT_CHAT_SOLVED_SUCCESS = 'FETCH_COCKPIT_CHAT_SOLVED_SUCCESS'
export const COCKPIT_UPDATE_CHAT_LIST = 'COCKPIT_UPDATE_CHAT_LIST'
export const NEW_COCKPIT_FILTERS_CHANGED = 'NEW_COCKPIT_FILTERS_CHANGED'
export const FETCH_COCKPIT_SERVICE_ON_TIME_REQUEST = 'FETCH_COCKPIT_SERVICE_ON_TIME_REQUEST'
export const FETCH_COCKPIT_SERVICE_ON_TIME_SUCCESS = 'FETCH_COCKPIT_SERVICE_ON_TIME_SUCCESS'
export const FETCH_COCKPIT_SERVICE_ON_TIME_ERROR = 'FETCH_COCKPIT_SERVICE_ON_TIME_ERROR'
export const FETCH_COCKPIT_ALERTS_REQUEST = 'FETCH_COCKPIT_ALERTS_REQUEST'
export const FETCH_COCKPIT_ALERTS_SUCCESS = 'FETCH_COCKPIT_ALERTS_SUCCESS'
export const FETCH_COCKPIT_ALERTS_ERROR = 'FETCH_COCKPIT_ALERTS_ERROR'
export const CLEAR_COCKPIT_SERVICE_ON_TIME_VALUES = 'CLEAR_COCKPIT_SERVICE_ON_TIME_VALUES'
export const CLEAR_COCKPIT_ALERT_VALUES = 'CLEAR_COCKPIT_ALERT_VALUES'

export const FETCH_NEW_COCKPIT_MARKERS_REQUEST = 'FETCH_NEW_COCKPIT_MARKERS_REQUEST'
export const FETCH_NEW_COCKPIT_MARKERS_SUCCESS = 'FETCH_NEW_COCKPIT_MARKERS_SUCCESS'
export const FETCH_NEW_COCKPIT_MARKERS_ERROR = 'FETCH_NEW_COCKPIT_MARKERS_ERROR'
