import { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import { colourStyles } from '../../../helpers/selectStyle'

class BranchSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      branchs: [],
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_STORES)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(branchs) {
    const options = branchs.map((branch) => ({
      value: branch.id,
      label: branch.name,
    }))

    this.setState({ branchs: options, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, branchs: [] })
  }

  render() {
    const {
      id,
      name,
      value,
      onChange,
      t,
      showAllOption,
      disabled = false,
      isClearable = true,
      hasLabel,
    } = this.props
    const { branchs, loading } = this.state

    const placeholder = loading ? t('option.loading') : t('servcOrd.issuerPlant')
    const branchesOptions = showAllOption ? [{ value: null, label: 'Todos' }, ...branchs] : branchs

    return (
      <div>
        <Select
          id={id}
          name={name}
          value={branchesOptions.filter((option) => option.value === value)}
          onChange={(event) => onChange(event)}
          classNamePrefix='react-select'
          placeholder={placeholder}
          options={branchesOptions}
          isClearable={isClearable}
          resetValue={[]}
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          isDisabled={disabled}
          styles={colourStyles}
        />
        {!disabled && hasLabel && (
          <label htmlFor={id} className='select-box-label'>
            {placeholder}
          </label>
        )}
      </div>
    )
  }
}

BranchSelect.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showAllOption: PropTypes.bool,
  isClearable: PropTypes.bool,
  hasLabel: PropTypes.bool,
}

export default BranchSelect
