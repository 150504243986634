import i18next from 'i18next'
import moment from 'moment-timezone'
import Mask from 'vanilla-masker'
import { FORMAT_DATE, FORMAT_DATE_TRUNC } from '../constants/config'

export const isDefined = (value) => value !== null && value !== undefined

export const isFilled = (value) => value.trim() !== ''

export const getBankIdMask = (t = (value) => value) => t('bankid.mask')

export const removeMask = (value) => (value ? value.replace(/[^\d]+/g, '') : null)

export const toPatternMultiple = (input, mask) => {
  if (input) {
    const regex = /^\[.*\]$/
    const isArrayOfMasks = regex.test(mask)
    if (!isArrayOfMasks) {
      return Mask.toPattern(input, mask)
    }
    // Masks array is only working for numeric masks (9 ... 9)
    const maskArray = mask
      .replace(/^\[|\]$/g, '') // remove square brackets
      .split(';') // create an array based on semicolon
      .map((mask) => mask.replace(/^\s+|\s+$/g, '')) // remove espaços no inicio ou final do item
      .map((mask) => ({
        mask,
        length: mask.replace(/[^9]/g, '').length,
      })) // transform into an object with a length property (length of valid input numbers)
      .sort((a, b) => a.length - b.length) // sort based on length
    const value = input.replace(/\D/g, '') // keep only numeric values
    const currentMask = maskArray.find(
      (mask, i) => mask.length >= value.length || i === maskArray.length - 1,
    ) // take the first mask of length greater than or equal to the length of the value
    return Mask.toPattern(value, currentMask.mask)
  }
}

export const format = (value, regex, disableMultipleMask = false) => {
  // eslint-disable-next-line no-param-reassign
  value = `${value}`

  if (!isDefined(value) || !isFilled(value)) {
    return ''
  }

  // eslint-disable-next-line no-param-reassign
  value = removeMask(value)

  return disableMultipleMask ? Mask.toPattern(value, regex) : toPatternMultiple(value, regex)
}

export const getCPFMask = () => i18next.t('cpf.mask')

export const formatCPF = (value) => {
  if (value && value.match(/[a-z]/i)) return value

  return format(value, getCPFMask())
}

export const getPostalCodeMask = (t = null) => {
  if (t === null) {
    return i18next.t('postal.code.mask')
  }
  return t('postal.code.mask')
}

export const formatPostalCode = (value, t = null) => format(value, getPostalCodeMask(t))

export const getCNPJMask = () => i18next.t('cnpj.mask')

export const formatCNPJ = (value) => format(value, getCNPJMask())

export const getPhoneMask = () => i18next.t('phone.mask')

export const formatPhone = (value) => format(value, getPhoneMask())

export const getCEPMask = (t = (value) => value) => t('cep.mask')

export const formatCEP = (value, t = (value) => value) => format(value, getCEPMask(t))

export const getServiceOrderMask = () => i18next.t('999.999.999')

export const formatServiceOrderNumber = (value) =>
  parseInt(value || 0)
    .toLocaleString('pt-BR')
    .replace(',', '.')

export const formatProjectId = (value) =>
  parseInt(value || 0)
    .toLocaleString('pt-BR')
    .replace(',', '.')

export const formatPdvInfo = (value) =>
  parseInt(value || 0)
    .toLocaleString('pt-BR')
    .replace(',', '.')

export const formatDecimal = (value) => {
  if (!isDefined(value)) {
    // eslint-disable-next-line no-param-reassign
    value = 0
  }

  return `${i18next.t('option.currency')} ${Mask.toMoney(
    value.toFixed(i18next.t('size.currency')),
    {
      separator: i18next.t('separator.currency'),
      delimiter: i18next.t('delimiter.currency'),
    },
  )}`
}

export const formatDecimalNoCurrency = (value, t = (value) => value) => {
  // eslint-disable-next-line no-param-reassign
  if (!isDefined(value)) value = 0

  return `${Mask.toMoney(value.toFixed(t('size.currency')), {
    separator: i18next.t('separator.currency'),
    delimiter: i18next.t('delimiter.currency'),
  })}`
}

export const formatDate = (value) => moment(value).format(FORMAT_DATE)

export const formatDateTrunc = (value) => moment(value).format(FORMAT_DATE_TRUNC)
