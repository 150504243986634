import i18next from 'i18next'
import { useState } from 'react'
import Modal from '../../../../../../../components/Modal'
import ServcOrdEvntStusType from '../../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import {
  generateOcurrence,
  ParamsType,
} from '../../../../../../../services/pages/ServiceOrderDetail/GenerateOcurrence'
import { useServiceOrderDetailContext } from '../../../../../context'
import FooterButtons from '../FooterButtons'
import JustifyInput from '../JustifyInput'

type CoordinatorAttentionModalType = {
  show: boolean
  setShow: Function
  hasAttentionTag: boolean
}

function CoordinatorAttentionModal({
  show,
  setShow,
  hasAttentionTag,
}: CoordinatorAttentionModalType) {
  const { serviceOrderBody, reloadServiceOrder } = useServiceOrderDetailContext()
  const [justify, setJustify] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    setIsSubmiting(true)

    const payload: ParamsType = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq || 0,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: justify,
      servcOrdEvntStusInd: hasAttentionTag
        ? ServcOrdEvntStusType.REMOVED_ATTENTION.id
        : ServcOrdEvntStusType.ATTENTION.id,
      indStatusOrdemServicoItem: serviceOrderBody?.servcOrdStusCd || '',
      indStatusOrdemServico: serviceOrderBody?.servcOrdStusCd || '',
    }

    await generateOcurrence(payload)

    setShow(false)
    setJustify('')
    setIsSubmiting(false)
    await reloadServiceOrder()
  }

  return (
    <div className='pop-up-modal'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => setShow(false)}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={
          hasAttentionTag
            ? i18next.t('serviceOrder.popupMenu.removeCoordinatorAttention')
            : i18next.t('serviceOrder.popupMenu.coordinatorAttention')
        }
      >
        <JustifyInput value={justify} onChange={handleChangeJustify} />

        <FooterButtons
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          justify={justify}
          isSubmiting={isSubmiting}
        />
      </Modal>
    </div>
  )
}

export default CoordinatorAttentionModal
