import Api from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'
import store from '../redux/store'

import { showSpinner, hideSpinner } from '../redux/actions/spinner'

export const fetchGeralConditions = async (vaSlsOrdNr, plntCd) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new Api(null, store.dispatch).get(API_URL.GENERAL_CONDITIONS, {
      vaSlsOrdNr,
      plntCd,
    })

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const saveGeralConditions = async (Data) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new Api(null, store.dispatch).post(API_URL.GENERAL_CONDITIONS, Data)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
