import store from '../../redux/store'
import FetchAdapter from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

export interface FetchInstallersFilter {
  dynamicSearch?: string
  actvServcPrvdrAgntInd?: '0' | '1' | ''
  receivingServiceOrder?: '0' | '1' | ''
  lsPlntCd?: string[]
  descAsc?: 'asc' | 'desc'
  orderBy?: string
}

interface Phone {
  cntctMthdTypId: number
  cntctMthdValTxt: string
  contactMethodValue: string
}

export interface Installer {
  servcPrvdrAgntId: number
  servcPrvdrAgntNm: string
  servcPrvdrAddRgstrnCurrntStepNr: null | '' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'
  indEspecialistaServicoAtivo: '0' | '1'
  receivingServiceOrder?: string
  servcPrvdrId: number
  servcPrvdrTrdNm: string
  lsPhones?: Phone[]
  servcProviderIsActive: '0' | '1'
}

export const fetchInstallersList = (
  page = 1,
  filter: FetchInstallersFilter,
): Promise<ADEOResponse<Installer[]>> => {
  const pagination = {
    page,
    size: 10,
  }

  const params: FetchInstallersFilter = {
    ...filter,
    orderBy: 'se.servcPrvdrAgntId',
    descAsc: 'asc',
  }

  return new FetchAdapter(user.currentUser?.dsToken, store.dispatch, pagination).post(
    API_URL.FIND_SERVICE_SPECIALIST_LIST,
    params,
  )
}
