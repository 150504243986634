import i18next from 'i18next'
import { ClearIcon } from '../../../../../../helpers/react-select-backyard-styles'
import MozaicTextArea from '../../../../../../components/MozaicTextArea'

type JustifyInputType = {
  onChange: Function
  value: string
  placeholder?: string
}

function JustifyInput(props: JustifyInputType) {
  const { onChange, value, placeholder = i18next.t('justificationModal.desc.placeholder') } = props

  const label = i18next.t('serviceOrders.answers.textPlaceHolder')
  const errorHintLabel = i18next.t('modal.approveOutOfFlowRequest.justify.hint')

  const errorHint = value.trim().length < 10
  const isValid = !errorHint && !!value.trim().length
  const isInvalid = errorHint

  const handleClear = () => {
    onChange({ target: { value: '' } })
  }

  const suffixIcons = () => (
    <>
      {value && (
        <div className='clear-filter' onClick={handleClear}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  return (
    <div className='justify-input'>
      <MozaicTextArea
        id='justify'
        htmlFor='justify'
        label={label}
        value={value}
        isValid={isValid}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        maxLength={120}
      />
      {suffixIcons()}

      {errorHint && <label className='error-hint'>{errorHintLabel}</label>}
    </div>
  )
}

export default JustifyInput
