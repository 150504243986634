import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Col from '../../components/utils/Col'
import ContentHeader from '../../components/utils/ContentHeader'
import Glyph from '../../components/utils/Glyph'
import Grid from '../../components/utils/Grid'
import Notification from '../../components/utils/Notification'
import Row from '../../components/utils/Row'
import Pagination from '../../components/utils/pagination/Pagination'
import getQueryVariable from '../../helpers/getQueryVariable'
import Table from './components/Table'

import { withRouter } from 'react-router'
import { renderToastWarn } from '../../helpers/ToastUtils'
import { fetchProviderRecords } from '../../services/Provider'

class ServcPrvdrTmlineEvnt extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      records: [],
    }
  }

  componentDidMount() {
    this.fetchRecords()
  }

  componentDidUpdate(prevProps) {
    const { error } = this.state

    if (error && error !== prevProps.error) {
      renderToastWarn(error)
    }
  }

  fetchRecords = (page) => {
    const {
      auth: {
        user: { dsToken },
      },
    } = this.props
    const id = getQueryVariable('id')

    if (!!id && !isNaN(id))
      fetchProviderRecords(dsToken, { servcPrvdrId: id }, page)
        .then((records) => this.setState({ records }))
        .catch((error) => this.setState({ error }))
  }

  handleOnClickBack = () => {
    const { history } = this.props

    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.push(`/providers/view/${getQueryVariable('id')}`)
    }
  }

  render() {
    const { count, current, pages, t } = this.props
    const { records } = this.state

    return (
      <>
        <ContentHeader>{t('lmi.serviceProviders')}</ContentHeader>
        <Grid className='servcPrvdrTmlineEvnt'>
          <Row>
            <Col xs={12}>
              <button
                className='button-hollow button-hollow-primary margin-top'
                onClick={this.handleOnClickBack}
                type='button'
              >
                <Glyph type='arrow-left' />{' '}
                <span style={{ verticalAlign: 'top' }}>{t('servcPrvdrTmlineEvntTyp.back')}</span>
              </button>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <header>
                <h2>
                  <strong>{t('servcPrvdrTmlineEvntTyp.providerLog')}</strong>
                </h2>
                <hr className='pull-right' />
              </header>
            </Col>
          </Row>

          {records.length ? (
            <Table records={records} t={t} />
          ) : (
            <Notification
              className='notification-warning margin-top'
              message={t('select.noResultsText')}
            />
          )}

          {pages > 1 && (
            <Row>
              <Col xs={12} className='align-center'>
                <Pagination
                  count={count}
                  current={current}
                  getPage={this.fetchRecords}
                  pages={pages}
                />
              </Col>
            </Row>
          )}
        </Grid>
      </>
    )
  }
}

ServcPrvdrTmlineEvnt.propTypes = {
  auth: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  error: PropTypes.object,
  pages: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

/* istanbul ignore next */
const mapStateToProps = ({ paginateReducer, auth }) => ({
  auth,
  count: paginateReducer.count,
  current: paginateReducer.current,
  pages: paginateReducer.pages,
})

const ServcPrvdrTmlineEvntTranslated = withTranslation()(ServcPrvdrTmlineEvnt)

export default withRouter(connect(mapStateToProps, null)(ServcPrvdrTmlineEvntTranslated))

export { ServcPrvdrTmlineEvnt }
