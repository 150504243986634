import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { linkServiceOrdersFilters } from '../../../../../../redux/actions/serviceOrder'
import { WithContext } from '../../../../context/indicator'
import SoTIndicator from '../../../../entities/ServiceOnTimeIndicator'
import SubIndicator from '../SubIndicator'
import IndicatorBody from './components/IndicatorBody'
import SkeletonLoader from './components/SkeletonLoader'

function ServiceOnTimeIndicator({
  classText,
  auth: { setupParameters },
  filters,
  alert,
  showPopover,
  handleSubmitDefault,
}) {
  const Indicator = useMemo(() => new SoTIndicator(alert, filters), [alert, filters])

  const bgColor = Indicator.getBgColor(setupParameters.cockpitIndicatorServiceOnTime)
  const loading = Indicator.loading
  const hasSubIndicators = Indicator.subIndicators && Indicator.subIndicators.length > 0

  return (
    <section id='service-on-time-indicator' className={`${bgColor} ${classText}`}>
      <section
        className='principal-indicator'
        onClick={() => handleSubmitDefault(Indicator.parsedFilter)}
      >
        {loading ? (
          <SkeletonLoader />
        ) : (
          <IndicatorBody Indicator={Indicator} showPopover={showPopover} />
        )}
      </section>

      {hasSubIndicators && <hr className='divider-content' />}

      {Indicator.subIndicators.map(
        (indicator) => indicator.render && <SubIndicator alert={indicator} key={indicator.type} />,
      )}
    </section>
  )
}

ServiceOnTimeIndicator.propTypes = {
  classText: PropTypes.string,
  auth: PropTypes.object.isRequired,
  filters: PropTypes.object,
  alert: PropTypes.object,
  showPopover: PropTypes.bool,
  handleSubmitDefault: PropTypes.func,
}

const mapStateToProps = ({ auth, newCockpitReducer }) => ({
  auth,
  filters: newCockpitReducer.filters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ linkServiceOrdersFilters }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WithContext(ServiceOnTimeIndicator))
