import i18next from 'i18next'

import Select from 'react-select'

import ServcOrdEvntStusType from '../../../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServiceOrderTagType from '../../../../../../enums/ServiceOrder/tags/ServiceOrderTagType'

import { acceptanceSalesConditions } from '../../../../../../helpers/serviceOrderUtils'

import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import { useDistributeContext } from '../../context'
import { optionType } from '../../types'

import {
  ClearIndicator,
  DropdownIndicator,
} from '../../../../../../helpers/react-select-backyard-styles'

import User from '../../../../../../core/User'
import store from '../../../../../../redux/store'

type InstallerSelectType = {
  distributeMode: string
  onChange: Function
  value: optionType | null
  compareTodayDate: boolean
  compareDeliveryDate: boolean
}

const OUT_FLOW_REFUSED = 3
const auth = store.getState().auth
const user = new User().currentUser

function InstallerSelect(props: InstallerSelectType) {
  const { serviceOrder }: any = useServiceOrderContext()
  const { installers, showInstallersHint } = useDistributeContext()
  const { onChange, value, distributeMode, compareTodayDate, compareDeliveryDate } = props

  const verifyIndStatus = (indStatus: string) => {
    switch (indStatus) {
      case ServiceOrderStatusType.STATUS_ASSOCIADA.id:
      case ServiceOrderStatusType.STATUS_CONFIRMADA.id:
      case ServiceOrderStatusType.STATUS_EM_REALIZACAO.id:
      case ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id:
      case ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id:
      case ServiceOrderStatusType.STATUS_ENCERRADO.id:
      case ServiceOrderStatusType.STATUS_CANCELADA.id:
      case ServiceOrderStatusType.NAO_CONFORME.id:
      case ServiceOrderStatusType.LOJA_AGUARDANDO.id:
      case ServiceOrderStatusType.LOJA_EM_REALIZACAO.id:
      case ServiceOrderStatusType.LOJA_REALIZADO.id:
      case ServiceOrderStatusType.LOJA_ENCERRADO.id:
      case ServiceOrderStatusType.OUT_OF_FLOW.id:
        return true
      default:
        return false
    }
  }

  const isDAP = () => {
    if (
      serviceOrder &&
      serviceOrder.serviceOrderOccurrence &&
      serviceOrder.serviceOrderOccurrence.some(
        (stusInd: any) => stusInd.servcOrdEvntStusInd === ServcOrdEvntStusType.REFUSAL_PROCESS.id,
      )
    ) {
      switch (serviceOrder?.servcOrdStusCd) {
        case ServiceOrderStatusType.STATUS_AGENDADA.id:
        case ServiceOrderStatusType.STATUS_PRE_AGENDADA.id:
          return false
        default:
          return null
      }
    } else {
      switch (serviceOrder?.servcOrdStusCd) {
        case ServiceOrderStatusType.STATUS_AGENDADA.id:
        case ServiceOrderStatusType.STATUS_PRE_AGENDADA.id:
          return true
        default:
          return null
      }
    }
  }

  const isInstallerDisabled = () => {
    const hasTagServcPrvdrManualDstrbt = serviceOrder?.serviceOrderTags.some(
      (e: any) => e.tagType === ServiceOrderTagType.MANUAL_DISTRIBUTED.tagName,
    )

    if (auth.setupParameters.servcPrvdrManualDistributionIsActive) {
      if (hasTagServcPrvdrManualDstrbt && user.funcaoAcesso.inFuncao42 !== 1) {
        return true
      }

      const _isDAP = isDAP()
      if (_isDAP !== null) {
        return _isDAP
      }
    }

    if (
      (auth.setupParameters.serviceOrderOutOfFlowEnable &&
        serviceOrder &&
        serviceOrder.idOutFlowProcess &&
        serviceOrder.idOutFlowProcess !== OUT_FLOW_REFUSED) ||
      (serviceOrder && serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id)
    ) {
      return true
    }

    if (serviceOrder?.isConformity && auth.setupParameters.conformity) {
      return true
    }

    if (serviceOrder?.editSpecialistOn === false && user.funcaoAcesso.inFuncao42 !== 1) {
      return true
    }

    if (
      serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      user.funcaoAcesso.inFuncao00 !== 1
    ) {
      return true
    }

    if (
      serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id &&
      user.funcaoAcesso.inFuncao01 !== 1
    ) {
      return true
    }

    if (
      serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
      serviceOrder?.servcPrvdrAgntCd !== null
    ) {
      return true
    }

    if (
      serviceOrder?.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      auth.setupParameters.acceptanceSalesConditions &&
      acceptanceSalesConditions(serviceOrder)
    ) {
      return true
    }

    return verifyIndStatus(serviceOrder?.servcOrdStusCd as string)
  }

  const label = i18next.t('customer.service.order.details.infos.specialist')
  const placeholder = i18next.t('serviceOrders.specialist.select.placeholder')
  const disabled =
    !installers.length ||
    distributeMode !== 'manual' ||
    isInstallerDisabled() ||
    !(compareDeliveryDate && compareTodayDate)
  const errorHint = i18next.t('select.noOptionsMessage')

  const hasContentClass = value ? 'hasContent' : ''
  const disabledClass = disabled ? '--disabled' : ''

  return (
    <div className={`installer-select-distribute ${hasContentClass} ${disabledClass}`}>
      <label className='installer-select-label'>
        {label} {showInstallersHint && <label className='error-hint'>- {errorHint}</label>}
      </label>

      <Select
        value={value as any}
        options={installers}
        onChange={(e: any) => onChange(e)}
        isDisabled={disabled}
        placeholder={placeholder}
        isOptionDisabled={(option: any) => option.disabled}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
      />
    </div>
  )
}

export default InstallerSelect
