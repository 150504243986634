import SummaryMain from '../../SummaryMain'
import HistoricDates from './components/HistoricDates'
import HistoricOccurrences from './components/HistoricOccurrences'
import HistoricDailyReports from './components/HistoricDailyReports'

export default function Historic() {
  return (
    <div id='historic-container'>
      <SummaryMain />
      <HistoricDates />
      <HistoricDailyReports />
      <HistoricOccurrences />
    </div>
  )
}
