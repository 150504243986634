import PropTypes from 'prop-types'

import Col from '../../../components/utils/Col'
import Row from '../../../components/utils/Row'

import logoWithoutSignature from '../../../assets/icons/logoWithoutSignatureSmall.svg'

import { toPatternMultiple } from '../../../helpers/masks'
import MozaicText from '../../../components/MozaicText'

function Headers({ translate, osNumbers, dataSlsOrdNr, showBody }) {
  return (
    <div className='conditions-headers'>
      <header className='conditions-content-images'>
        <Row>
          <Col xs={3}>
            <img src={logoWithoutSignature} alt='leroy merlin logo' className='leroy' />
          </Col>
        </Row>
      </header>
      {showBody && (
        <section>
          <MozaicText>
            {translate('conditions.label.name')} <strong>{dataSlsOrdNr.name}</strong>
          </MozaicText>
          <MozaicText>
            {translate('conditions.label.address')} <strong>{dataSlsOrdNr.address}</strong>
          </MozaicText>
          <MozaicText>
            {translate('conditions.label.phone')}{' '}
            <strong>{toPatternMultiple(dataSlsOrdNr.phone, translate('phone.mask'))}</strong>
          </MozaicText>
          <MozaicText>
            {osNumbers.length > 1
              ? translate('conditions.label.nrOss')
              : translate('conditions.label.nrOs')}{' '}
            <strong>{osNumbers.join(', ')}</strong>
          </MozaicText>
        </section>
      )}
    </div>
  )
}

Headers.propTypes = {
  translate: PropTypes.func,
  osNumbers: PropTypes.array,
  dataSlsOrdNr: PropTypes.any,
  showBody: PropTypes.bool,
}

export default Headers
