import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import { SHIFT_CODES } from '../../../constants/config'
import { colourStyles } from '../../../helpers/selectStyle'

class SlotSelect extends Component {
  createOptions(resource) {
    const { auth, t } = this.props

    const strSlots = auth.setupParameters.configShiftOptions
    const lsSlots = strSlots?.split(',')

    const options = []
    if (typeof resource[0] === 'object') {
      resource.map((item) => {
        for (let i = 0; i < lsSlots.length; i++) {
          if (item.id === lsSlots[i]) {
            options.push({ value: item.id, label: t(item.name) })
            break
          }
        }
      })
    } else {
      for (const item in resource) {
        for (let i = 0; i < lsSlots.length; i++) {
          if (resource.hasOwnProperty(item)) {
            if (item === lsSlots[i]) {
              options.push({
                value: item,
                label: t(resource[item]),
              })
              break
            }
          }
        }
      }
    }

    return options
  }

  render() {
    const { id, name, value, onChange, t, disabled = false, isClearable = true } = this.props

    return (
      <div>
        <Select
          id={id}
          name={name}
          value={this.createOptions(SHIFT_CODES).filter((option) => option.value === value)}
          onChange={(event) => onChange(event)}
          placeholder={t('cust.request.shift')}
          options={this.createOptions(SHIFT_CODES)}
          isClearable={isClearable}
          resetValue={[]}
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          isDisabled={disabled}
          styles={colourStyles}
        />
      </div>
    )
  }
}

SlotSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  isClearable: PropTypes.bool,
}

export default SlotSelect
