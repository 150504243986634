import PropTypes from 'prop-types'
import { Component } from 'react'
import pdfIcon from '../../../assets/icons/icone-pdf.svg'
import xlsIcon from '../../../assets/icons/icone-xls.svg'
import loading from '../../../assets/icons/loading.svg'
import defaultIcon from '../../../assets/icons/paper-note.svg'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { getExtension } from '../../../helpers/Toolbox'

const pdfExtensions = ['pdf']
const xlsExtensions = ['csv', 'xls', 'xlsx']

class AwsImageDocument extends Component {
  state = {
    thumb: false,
  }

  componentDidMount() {
    this.renderImage()
  }

  getFile = async (token, filePath, dispatch) => {
    let base64 = ''
    await new Api(token, dispatch).get(API_URL.AMAZON_AWS_GET_FILE, { filePath }).then(
      (response) => {
        base64 = response.data
        return response.data
      },
      (error) => {
        console.error('Faleid base64 image', error)
        return ''
      },
    )
    return base64
  }

  renderImage = async () => {
    const { filePath, token, dispatch, src: srcProps } = this.props

    const src = srcProps || null

    const ext = getExtension(filePath).toLowerCase()

    let thumb = ''
    if (pdfExtensions.includes(ext)) {
      thumb = pdfIcon
    } else if (xlsExtensions.includes(ext)) {
      thumb = xlsIcon
    } else {
      const base64 = await this.getFile(token, filePath, dispatch)
      thumb = `data:image;base64, ${base64}`
      if (!base64 && src) {
        thumb = src
      }
    }
    this.setState({ thumb })
  }

  render() {
    const { className, alt, handleImageLoad } = this.props
    const { thumb } = this.state
    return (
      <img
        loading='lazy'
        className={className}
        src={thumb || loading}
        alt={alt || 'Image'}
        onLoad={() => handleImageLoad?.()}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = defaultIcon
        }}
      />
    )
  }
}

AwsImageDocument.propTypes = {
  filePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  src: PropTypes.string,
  token: PropTypes.string.isRequired,
  alt: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  handleImageLoad: PropTypes.func,
}

export default AwsImageDocument
