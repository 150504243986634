import ApiFive9 from '../core/Http/ApiFive9'

import store from '../redux/store'
import { showSpinner, hideSpinner } from '../redux/actions/spinner'

export const makeCallFive9 = async (data) => {
  store.dispatch(showSpinner())

  try {
    const call = await new ApiFive9().request('urn:makeCall', data)

    return call
  } catch (err) {
    return Promise.reject(err)
  } finally {
    store.dispatch(hideSpinner())
  }
}
