import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../../../../../../context'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { serviceOrder, isFetching, reloadServiceOrder } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        serviceOrder,
        reloadServiceOrder,
      }),
      [serviceOrder, isFetching],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
