import i18next from 'i18next'
import MozaicButton from '../../../../../../components/MozaicButton'

type FooterButtonsType = {
  disabled: boolean
  handleSubmit: Function
  handleClose: Function
}

function FooterButtons(props: FooterButtonsType) {
  const { disabled, handleSubmit, handleClose } = props
  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClose()}>
        {i18next.t('button.label.cancel')}
      </MozaicButton>
      <MozaicButton isDisabled={disabled} onClick={() => handleSubmit()}>
        {i18next.t('button.label.save')}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
