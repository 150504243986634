import i18next from 'i18next'
import { useEffect, useState } from 'react'
// @ts-ignore
import Select from 'react-select'
import store from '../../../../../../../../../../redux/store'

import { SORT_BY, SORT_BY_ELASTIC } from '../../../../../../../../../../constants/config'
import { colourStyles } from '../../../../../../../../../../helpers/selectStyle'

type optionType = {
  value: any
  label: string
}

type ColumnSelectType = {
  onChange: Function
  value: optionType | null
}

function ColumnSelect(props: ColumnSelectType) {
  const { onChange, value } = props
  const [options, setOptions] = useState<Array<any>>([])
  const [isOpen, setIsOpen] = useState<string>('')

  const setupParameters = store.getState().auth.setupParameters

  const createOptions = () => {
    const options: Array<any> = []
    const resource = setupParameters.elasticEnabled ? SORT_BY_ELASTIC : SORT_BY

    Object.keys(resource).forEach((item) => {
      const index = item as keyof typeof resource

      options.push({
        value: item,
        label: i18next.t(resource[index]),
      })
    })

    setOptions(options)
  }

  useEffect(() => {
    createOptions()
  }, [])

  const handleChange = (e: any) => {
    onChange(e)
  }

  const handleOpen = () => {
    setIsOpen('is-open')
    const element = document.getElementById('column-select')
    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 125)
  }

  const handleClose = () => {
    setIsOpen('')
  }

  const label = i18next.t('order.by.text')
  const placeholder = i18next.t('rating.error')
  const footer = i18next.t('rating.error')

  const resetValue = setupParameters.elasticEnabled
    ? SORT_BY_ELASTIC.servc_ord_schdlg_ts
    : SORT_BY['so.servcOrdSchdlgTs']

  return (
    <div className={`column-select ${isOpen}`} id='column-select'>
      <label className='react-select--label' htmlFor='column-select'>
        {label}
      </label>

      <Select
        value={value}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        name='column-select'
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        resetValue={resetValue}
        styles={colourStyles}
        isClearable
        menuPosition='absolute'
        onMenuOpen={handleOpen}
        onMenuClose={handleClose}
      />

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default ColumnSelect
