import i18next from 'i18next'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'

import User from '../../../../../../core/User'
import store from '../../../../../../redux/store'

import { useServiceOrderDetailContext } from '../../../../context'

import FormField from '../../../../../../components/FormField'
import RescheduleService from '../RescheduleService'

import ServiceOrderStatusType from '../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'

const user = new User().currentUser
const OUT_FLOW_REFUSED = 3

function SchedulingDate() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()
  const { auth } = store.getState()
  const { setupParameters } = auth
  const { funcaoAcesso } = user
  const statusCd = serviceOrderBody?.servcOrdStusCd

  const [show, setShow] = useState<boolean>(false)

  function handleGetExtensiveDate() {
    const currentDate = moment(serviceOrderBody?.servcOrdSchdlgTs)
    const fullDate = currentDate.format('L')
    const dayDate = currentDate.format('dddd')
    const turnDate = i18next.t(serviceOrderBody?.dsSlot as string).toLocaleLowerCase()

    return `${fullDate}, ${dayDate}, ${turnDate}`
  }

  const isOutFlowProccess = () => {
    if (
      (setupParameters.serviceOrderBodyOutOfFlowEnable &&
        serviceOrderBody &&
        serviceOrderBody.idOutFlowProcess &&
        serviceOrderBody.idOutFlowProcess !== OUT_FLOW_REFUSED) ||
      (serviceOrderBody &&
        serviceOrderBody.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id)
    ) {
      return true
    }

    return false
  }

  const showRescheduleModal = () => {
    const STATUS_PRE_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id
    const STATUS_DISTRIBUIDA = statusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id
    const STATUS_AGENDADA = statusCd === ServiceOrderStatusType.STATUS_AGENDADA.id

    const hasFunction00 = funcaoAcesso.inFuncao00 === 1
    const hasFunction01 = funcaoAcesso.inFuncao01 === 1
    const hasFunction02 = funcaoAcesso.inFuncao02 === 1

    const preScheduleRule = STATUS_PRE_AGENDADA && !hasFunction00
    const scheduleRule = STATUS_AGENDADA && !hasFunction01 && !hasFunction00
    const distributeRule = STATUS_DISTRIBUIDA && (hasFunction02 || !hasFunction01)
    const isConformity = serviceOrderBody?.isConformity && setupParameters.conformity

    if (STATUS_DISTRIBUIDA) return true
    if (isOutFlowProccess()) return false

    if (isConformity) return false
    if (distributeRule) return false
    if (scheduleRule) return false
    if (preScheduleRule) return false

    return STATUS_PRE_AGENDADA || STATUS_DISTRIBUIDA || STATUS_AGENDADA
  }

  const fullDateText = useMemo(() => handleGetExtensiveDate(), [serviceOrderBody])
  const canEdit = showRescheduleModal()
  const color = canEdit ? 'green' : 'neutral'
  const onEditField = canEdit ? () => setShow(!show) : undefined

  return (
    <>
      <FormField
        label={i18next.t('empleTempPrfl.date')}
        color={color}
        value={fullDateText}
        isFetching={loadingBody}
        onEditField={onEditField}
      />
      {serviceOrderBody && <RescheduleService show={show} setShow={setShow} />}
    </>
  )
}
export default SchedulingDate
