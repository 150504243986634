import { GET_PAGES, RESET_PAGES } from '../../actionTypes/pagination'

const DEFAULT_STATE = {
  pages: 0,
  count: 0,
  current: 1,
}

const paginate = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_PAGES:
      return {
        ...state,
        pages: action.pages,
        current: action.current,
        count: action.count,
      }

    case RESET_PAGES:
      return DEFAULT_STATE

    default:
      return state
  }
}

export default paginate
