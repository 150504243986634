import { useCallback, useState } from 'react'

import useEventListener from './useEventListener'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

import { menuWidthDifferencce } from '../constants/menu'
import { screen } from '../constants/breakpoints'

function useElementSize(isOpen) {
  const [ref, setRef] = useState(null)
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })
  const widthWindow = window?.innerWidth || 0

  const widthContainer =
    isOpen && widthWindow > screen.large ? size.width - menuWidthDifferencce : size.width

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    })
  }, [isOpen, ref?.offsetHeight, ref?.offsetWidth])

  useIsomorphicLayoutEffect(() => {
    setTimeout(() => handleSize(), 200)
  }, [isOpen, ref?.offsetHeight, ref?.offsetWidth])

  useEventListener('resize', handleSize)

  return [setRef, widthContainer, size]
}

export default useElementSize
