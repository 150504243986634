import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export const checkWorksiteQuantity = async (worksiteNumber: number) => {
  const query = {
    servcOrdWorksiteNumber: worksiteNumber,
  }

  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.COUNT_TOTAL_WORKSITE,
      query,
    )
    return response.data
  } catch (error) {
    return error
  }
}
