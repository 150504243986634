/* eslint-disable react/jsx-no-constructed-context-values */
import i18next from 'i18next'
import { createContext, useContext, useEffect, useState } from 'react'
import { getReasonsList } from '../../../../../../../../../../services/pages/ServiceOrderDetail/getReasonsList'

type WarrantyContextType = {
  periods: Array<any>
  reasons: Array<any>
}

type WarrantyProviderType = {
  children: any
}

const WarrantyContext = createContext<WarrantyContextType | null>(null)

function WarrantyProvider(props: WarrantyProviderType) {
  const { children } = props

  const slots = [
    {
      value: 'M',
      label: i18next.t('service.order.calendar.morning'),
    },
    {
      value: 'A',
      label: i18next.t('service.order.calendar.afternoon'),
    },
    {
      value: 'E',
      label: i18next.t('service.order.calendar.night'),
    },
  ]

  const [periods] = useState<Array<any>>(slots)
  const [reasons, setReasons] = useState([])

  const fetchReasonsList = async () => {
    const { data: list } = await getReasonsList()

    const parsedReasons = list
      .filter(
        ({ actvOrdEvntTypRsnInd, servcOrdEvntTyp }: any) =>
          actvOrdEvntTypRsnInd === '1' && servcOrdEvntTyp === 6,
      )
      .map(({ servcOrdEvntTypRsnDesc, servcOrdEvntTypRsnId }: any) => ({
        label: servcOrdEvntTypRsnDesc,
        value: servcOrdEvntTypRsnId,
      }))

    setReasons(parsedReasons)
  }

  useEffect(() => {
    fetchReasonsList()
  }, [])

  return (
    <WarrantyContext.Provider value={{ periods, reasons }}>{children}</WarrantyContext.Provider>
  )
}

const useWarrantyContext = () => useContext(WarrantyContext) as WarrantyContextType

export { WarrantyContext, WarrantyProvider, useWarrantyContext }
