import { useState } from 'react'
import {
  getServiceProvider,
  fetchServiceProviderFiles,
  activeProviderToReceiveOs,
} from '../../../services/ProviderList'

import {
  updateProvider,
  removeProviderFile,
  saveServiceProviderFiles,
} from '../../../services/Provider'
import ProviderEntity, { ProviderAttachment } from '../../../models/Provider'

function ProviderHandlers() {
  const [provider, setProvider] = useState<ProviderEntity | {}>({})
  const [providerFiles, setProviderFiles] = useState<ProviderAttachment[]>([])

  const handleFetchProvider = async (token: string, providerId: string, isFromProvider = false) => {
    const newProvider = await getServiceProvider(token, providerId, isFromProvider)

    setProvider(newProvider)

    return newProvider
  }

  const handleFetchProviderFiles = async (token: string, servcPrvdrId: number) => {
    const files = await fetchServiceProviderFiles(token, servcPrvdrId)

    setProviderFiles(files)

    return files
  }

  const handleSaveProviderFiles = async (token: string, values: any) => {
    await saveServiceProviderFiles(token, values)

    handleFetchProviderFiles(token, values.servcPrvdrId)
  }

  const handleRemoveProviderFile = async (
    token: string,
    servcPrvdrId: number,
    servcPrvdrAtchmtId: number,
  ) => {
    await removeProviderFile(token, servcPrvdrId, servcPrvdrAtchmtId)

    handleFetchProviderFiles(token, servcPrvdrId)
  }

  const handleActiveProviderToReceiveOs = async (token: string, params: any) => {
    await activeProviderToReceiveOs(token, params)

    handleFetchProvider(token, params.servcPrvdrId)
  }

  const handleUpdateProvider = async (
    token: string,
    provider: any,
    redirect = '/providers',
    toast = true,
    spinner = true,
  ) => {
    const data = await updateProvider(token, provider, { redirect, toast, spinner })
    handleFetchProvider(token, provider.servcPrvdrId)

    return data
  }

  const handleClearProvider = () => {
    setProvider({})
    setProviderFiles([])
  }

  return {
    provider,
    providerFiles,
    handleFetchProvider,
    handleFetchProviderFiles,
    handleSaveProviderFiles,
    handleRemoveProviderFile,
    handleUpdateProvider,
    handleActiveProviderToReceiveOs,
    handleClearProvider,
  }
}

export default ProviderHandlers
