/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */

import { CHAT_TAG } from '../constants/config'
import User from '../core/User'
import ServcOrdEvntStusType from '../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../enums/ServiceOrder/status/ServiceOrderStatusType'

let routePrefix = null

export function getOsRoutePrefix() {
  if (!routePrefix) {
    const user = new User()

    const hasFunction129 = user.currentUser?.accessFunctionList.includes(129)

    routePrefix = hasFunction129 ? 'detail' : 'edit'
  }

  return `/serviceOrder/${routePrefix}`
}

/**
 * Transforma uma mensagem de texto com códigos de OS em links para as OS.
 *
 * @param message
 * @returns {any[]}
 */
export function osCodesToLink(message) {
  if (!message) return []

  // Dá um split na mensagem, deixando nos índices ímpares os códigos das OS
  const msgSplited = message.split(new RegExp(`${CHAT_TAG.OS_OPEN}(.*?)${CHAT_TAG.OS_CLOSE}`, 'g'))

  // Percorre cada índice ímpar, que se espera que seja um ID e coloca em uma âncora
  for (let i = 1, len = msgSplited.length; i < len; i += 2) {
    const servcOrdCd = msgSplited[i]
    const prefix = getOsRoutePrefix()
    const href = `${prefix}/${servcOrdCd}`
    const onClick = null

    msgSplited[i] = (
      <a key={i} href={href} onClick={onClick} target='_blank' rel='noreferrer'>
        {servcOrdCd}
      </a>
    )
  }

  return msgSplited
}

export const ServiceImageType = {
  START: 4,
  END: 5,
  NOT_CONFORM: 13,
  REPORT: 14,
}

export function acceptanceSalesConditions(serviceOrder) {
  if (
    !serviceOrder ||
    serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id
  ) {
    return false
  }

  const { serviceOrderOccurrence } = serviceOrder

  if (!serviceOrderOccurrence || !serviceOrderOccurrence.length) {
    return false
  }

  return !!serviceOrderOccurrence.find(
    (e) => e.servcOrdEvntStusInd === ServcOrdEvntStusType.AWAITING_ACCEPTANCE_SALES_CONDITIONS.id,
  )
}

export const maskCep = (cep) => {
  if (!cep) return
  return cep
    .replace(/[^\d]+/g, '')
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export const maskRg = (v) => {
  v = v.replace(/\D/g, '')
  v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')
  return v
}
