import { ReactNode } from 'react'
import i18next from 'i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import BreadcrumbBar from '../BreadcrumbBar'

import './index.scss'

interface PageProps {
  id?: string
  title: string
  children: ReactNode
  className?: string
}

/**
 * @description Renders a page with a breadcrumb bar and a content area
 */

export function Page({ title, children, className, id }: PageProps) {
  const isLeftSideBarOpen = useSelector((state: any) => state.menuReducer.isOpen)

  const classList = classNames({
    'service-portal-page': true,
    [className || '']: className,
    'service-portal-page--left-side-bar-open': isLeftSideBarOpen,
  })

  return (
    <main id={id} className={classList}>
      <BreadcrumbBar title={i18next.t(title)} />
      <div id='service-portal-page-content'>{children}</div>
    </main>
  )
}
