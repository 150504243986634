import { getRandomNumber } from '../../../../../../../../helpers/random'

export default function SkeletonItem() {
  const items = [1, 2, 3, 4]

  return (
    <>
      {(items || []).map(() => (
        <div id='conversation-item-container' key={getRandomNumber(1, 999999999)}>
          <header>
            <div className='service-order-code skeleton' />
            <div className='time skeleton' />
          </header>

          <section className='body skeleton' />

          <div className='item-bottom'>
            <div>
              <span className='readed-icon skeleton' />
              <div className='last-message'>
                <span className='text skeleton' />
              </div>
            </div>
            <div className='counter skeleton' />
          </div>
        </div>
      ))}
    </>
  )
}
