import i18next from 'i18next'
import { useEffect, useState } from 'react'
// @ts-ignore
import Select from 'react-select'

import API_URL from '../../core/Envs/endpoints'
import Api from '../../core/Http/FetchAdapter'
import User from '../../core/User'
import { colourStyles } from '../../helpers/selectStyle'
import { Status } from '../../models/Status'
import store from '../../redux/store'

const user = new User().currentUser

type optionType = {
  value: any
  label: string
}

type StatusSelectType = {
  onChange: Function
  value: Array<optionType>
}

function StatusSelect(props: StatusSelectType) {
  const { onChange, value } = props
  const [options, setOptions] = useState<Array<optionType>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchStatus = async () => {
    setLoading(true)

    const { data: statusList } = await new Api(user.dsToken, store.dispatch).get(
      API_URL.FIND_SERVICE_ORDER_STATUSES,
      { idCurrentStatus: '99' },
    )

    const options = statusList.map((status: Status) => ({
      value: status.id,
      label: i18next.t(status.value),
    }))

    setOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    fetchStatus()
  }, [])

  const handleChange = (e: any) => {
    onChange(e)
    if (e === null) onChange([])
  }

  const label = i18next.t('bank_statement.status')
  const placeholder = `${i18next.t('search')} ${i18next.t('all').toLowerCase()}`
  const footer = i18next.t('advanced.search.status.select.footer')

  return (
    <div className='status-select'>
      <label className='react-select--label'>{label}</label>

      <Select
        value={value}
        options={options}
        onChange={handleChange}
        isDisabled={loading}
        placeholder={placeholder}
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        styles={colourStyles}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isClearable
        isMulti
        menuPosition='absolute'
      />

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default StatusSelect
