import Resume from '../../Resume'
import ErrorBoundary from '../../../../../errors/boundaries'
import ChatV2 from '../../../../../components/ChatV2'
import MozaicAccordeon from '../../../../../components/MozaicAccordeon'

import { useCoreClientChat } from './handlers/useCoreClientChat'
import { useCoreProviderChat } from './handlers/useCoreProviderChat'
import { useProviderClientChat } from './handlers/useProviderClientChat'

function Chat() {
  const { chatRef: PCRef, chatProps: PCProps } = useProviderClientChat()

  const { chatRef: CCRef, chatProps: CCProps } = useCoreClientChat()

  const { chatRef: CPRef, chatProps: CPProps } = useCoreProviderChat()

  return (
    <div id='chat-container'>
      <ErrorBoundary>
        <Resume />
        <section className='project-tab'>
          <MozaicAccordeon {...CCProps}>
            <ChatV2 collection='customerChatV2' docID='6633' ref={CCRef} initScroll />
          </MozaicAccordeon>

          <MozaicAccordeon {...CPProps}>
            <ChatV2 collection='customerChatV2' docID='6633' ref={CPRef} />
          </MozaicAccordeon>

          <MozaicAccordeon {...PCProps}>
            <ChatV2 collection='customerChatV2' docID='6633' ref={PCRef} />
          </MozaicAccordeon>
        </section>
      </ErrorBoundary>
    </div>
  )
}

export default Chat
