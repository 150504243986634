/* eslint-disable no-unsafe-optional-chaining */

export function getRandomFloat(min, max, decimals) {
  const str = (Math.random() * (max - min) + min).toFixed(decimals)

  return parseFloat(str)
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function getRandomDecimalValue(min, max) {
  const str = (Math.random() * (max - min) + min).toFixed(1)
  const newValue = str?.replace('.', ',')
  return newValue
}

export function capFirst(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export function generateName() {
  const firstName = [
    'abandoned',
    'cable',
    'dabsolute',
    'sadorable',
    'edventurous',
    'fcademic',
    'gacceptable',
    'kacclaimed',
  ]

  const lastName = [
    'people',
    'history',
    'way',
    'art',
    'world',
    'information',
    'mapi',
    'family',
    'government',
  ]

  const name = `${capFirst(firstName[getRandomInt(0, firstName.length + 1)])} ${capFirst(
    lastName[getRandomInt(0, lastName.length + 1)],
  )}`

  return name
}

export function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}
