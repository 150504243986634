import { Button, IButtonProps } from '@mozaic-ds/react'
import classNames from 'classnames'

interface MozaicButtonProps extends Omit<IButtonProps, 'variant'> {
  variant?: 'solid' | 'bordered' | 'ghost'
}

function MozaicButton(props: MozaicButtonProps) {
  const { variant, className, children, ...otherProps } = props

  const realVariant = variant === 'ghost' ? 'bordered' : variant

  return (
    <Button
      id='mozaic-button'
      variant={realVariant}
      className={classNames(className, { 'ghost-button': variant === 'ghost' })}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export default MozaicButton
