import FileSaver from 'file-saver'
import i18next from 'i18next'
import API_URL from '../core/Envs/endpoints'
import API_REQUEST from '../core/Http/FetchAdapter'
import store from '../redux/store'

import { toast } from '@leroy-merlin-br/backyard-react'
import { renderToastError } from '../helpers/ToastUtils'
import { base64toBlob } from '../helpers/Toolbox'
import { getRandomInt } from '../helpers/random'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'

const reportUrls = {
  1: API_URL.SERVICE_ORDER_REPORT_PDF, // 1
  2: API_URL.SERVICE_SPECIALIST_REPORT_CSV, // 2
  3: API_URL.SERVICE_PROVIDER_REPORT_CSV, // 3
}

const reportTypes = {
  CSV: 0,
  PDF: 1,
  EXCEL: 2,
}

type ParamsType = {
  tpReport: keyof typeof reportUrls
  filters: any
}

const fetchReportsSuccess = (response: any) => {
  if (response.type === reportTypes.CSV) {
    const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' })
    return FileSaver.saveAs(blob, 'report.csv')
  }

  if (response.type === reportTypes.PDF) {
    const blob = new Blob([base64toBlob(response.data, 'application/pdf')], {
      type: 'application/pdf',
    })
    return FileSaver.saveAs(blob, 'report')
  }

  if (response.type === reportTypes.EXCEL) {
    const blob = new Blob([base64toBlob(response.data, 'application/vnd.ms-excel')], {
      type: 'application/vnd.ms-excel',
    })
    return FileSaver.saveAs(blob, response.fileName ? response.fileName : 'report.xlsx')
  }

  const blob = new Blob([response], { type: 'application/pdf' })
  const objectUrl = URL.createObjectURL(blob)
  return window.open(objectUrl)
}

const setupNewEventSource = (notifierUrl: string, reportKey: number, callback: Function) => {
  const p = new Promise((resolve, reject) => {
    const eventSourceInit = { headers: { Cookie: `reportKey=${reportKey}` } } as any
    const eventSource = new EventSource(`${notifierUrl}?reportKey=${reportKey}`, eventSourceInit)

    const onopen = (event: Event) => {
      console.log('EventSource connected')
      console.log(event)
    }

    const onmessage = (event: MessageEvent) => {
      if (event && event.data !== 'connected') {
        const response = JSON.parse(event.data)

        if (response && response.data) {
          eventSource.close()

          fetchReportsSuccess(response)

          return resolve(response)
        }

        if (response && response.message) {
          eventSource.close()

          renderToastError(i18next.t('report.generation.error'))

          return reject(response)
        }
      }
    }

    const onerror = (e: Event) => {
      if (e.type === 'error') {
        const eventSource = new EventSource(
          `${notifierUrl}?reportKey=${reportKey}`,
          eventSourceInit,
        )
        eventSource.onopen = onopen
        eventSource.onmessage = onmessage
        eventSource.onerror = onerror
      } else {
        console.log('EventSource error', e)
        store.dispatch(hideSpinner())
      }
    }

    eventSource.onopen = onopen
    eventSource.onmessage = onmessage
    eventSource.onerror = onerror
    callback()
  })

  return p
}

export const fetchReportsPost = async (payload: ParamsType) => {
  const url = reportUrls[payload.tpReport]
  const query = {
    ...payload.filters,
  }

  store.dispatch(showSpinner())

  try {
    const response = await new API_REQUEST(null, store.dispatch).post(url, query)

    return fetchReportsSuccess(response)
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')

    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchAsyncReports = async (
  token: any,
  payload: ParamsType,
  endpoint: string = API_URL.ENABLE_NOTIFIER,
) => {
  const reportKey = getRandomInt(1, 9999999)

  const query = {
    status: 200,
    ...payload?.filters,
    reportKey,
  }

  store.dispatch(showSpinner())

  return setupNewEventSource(endpoint, reportKey, () => {
    const url = reportUrls[payload.tpReport]

    return new API_REQUEST(null, store.dispatch).post(url, query)
  }).finally(() => store.dispatch(hideSpinner()))
}

export const fetchAsyncReportsProvider = (token: any, payload: ParamsType) =>
  fetchAsyncReports(token, payload, API_URL.ENABLE_NOTIFIER_PROVIDER)
