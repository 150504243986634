import { Ref, ReactNode } from 'react'
import i18next from 'i18next'
import classNames from 'classnames'
import MozaicIcon from '../MozaicIcon'

interface ModalProps {
  children: ReactNode
  isOpen: boolean
  ref?: Ref<any>
  size?: 'ds' | 'sm' | 'md' | 'lg'
  closeInOverlay?: boolean
  contentLabel?: string
  className?: string
  onClose?: Function
}

function Modal(props: ModalProps) {
  const {
    children,
    isOpen = false,
    ref,
    size = 'md',
    closeInOverlay = true,
    contentLabel = '',
    className = '',
    onClose = null,
  } = props

  function handleClickOverlay() {
    if (!closeInOverlay) return
    if (onClose) onClose()
  }

  const title = i18next.t(contentLabel)

  if (!isOpen) return <></>

  return (
    <div
      className={classNames('custom-modal-overlay', { visible: isOpen })}
      onClick={() => handleClickOverlay()}
    >
      <div
        className={`custom-modal ${size} ${className}`}
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      >
        <header>
          <h3>{title}</h3>
          {onClose && (
            <MozaicIcon icon='ControlCross24' cursor='pointer' onClick={() => onClose()} />
          )}
        </header>

        <section className='modal-body'>{children}</section>
      </div>
    </div>
  )
}

export default Modal
