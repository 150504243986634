import {
  SETUP_PARAMETERS_ERROR,
  SETUP_PARAMETERS_SUCCESS,
  SIGNIN_FIREBASE_SUCCESS,
} from '../../actionTypes/auth'

import API_URL from '../../../core/Envs/endpoints'
import ApiRequest from '../../../core/Http/FetchAdapter'
import store from '../../store'

import 'firebase/compat/auth'
import 'firebase/database'

const globalSetupParameters = (data) => ({
  type: SETUP_PARAMETERS_SUCCESS,
  payload: data,
})

const SetupParametersError = (data) => ({
  type: SETUP_PARAMETERS_ERROR,
  payload: data,
})

const signinFirebaseSuccess = () => ({
  type: SIGNIN_FIREBASE_SUCCESS,
})

export const mockSetupParameters = (mock) => {
  store.dispatch(globalSetupParameters(mock))
}

const onFetchSetupParamatersSuccess = (response, dispatch) => {
  dispatch(globalSetupParameters(response.data))
  dispatch(signinFirebaseSuccess())
}

const onFetchSetupParametersError = (error, dispatch) => {
  dispatch(SetupParametersError(error))
}

export const setupParameters = (dispatch) =>
  new ApiRequest()
    .get(API_URL.SETUP_PARAMETERS)
    .then((response) => onFetchSetupParamatersSuccess(response, dispatch))
    .catch((error) => onFetchSetupParametersError(error, dispatch))
