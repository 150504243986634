import i18next from 'i18next'
import { useMemo } from 'react'
// @ts-ignore
import moment from 'moment-timezone'
import Select, { ValueType } from 'react-select'
import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import {
  ClearIndicator,
  DropdownIndicator,
} from '../../../../../../helpers/react-select-backyard-styles'
import { useDistributeContext } from '../../context'
import { optionType, _shifts as shifts } from '../../types'

interface ReasonSelectProps {
  onChange: (value: ValueType<{ value: any; label: string }, false>) => void
  value: optionType | null
  selectedPeriod: any
  selectedDate: any
}

function ReasonSelect({ onChange, value, selectedPeriod, selectedDate }: ReasonSelectProps) {
  const { reasons } = useDistributeContext()

  const { serviceOrder }: any = useServiceOrderContext()

  const handleChange = (e: any) => {
    onChange(e)
  }

  const shiftCd = shifts[serviceOrder?.idSlotApplication as keyof typeof shifts]
  const currentSchedule = moment(serviceOrder?.servcOrdSchdlgTs).startOf('day')

  const disabled = +currentSchedule === +selectedDate && shiftCd === selectedPeriod?.value

  const errorHint = !disabled && !value

  const label = i18next.t('serviceOrder.distribute.reason')
  const errorHintLabel = i18next.t('serviceOrders.reason.select.errorHint')
  const placeholder = i18next.t('serviceOrders.reason.select.placeholder')

  const disabledClass = disabled ? '--disabled' : ''
  const hasContentClass = value ? 'hasContent' : ''

  const reasonsOptions = useMemo(
    () =>
      reasons.map((r) => ({
        value: r.servcOrdEvntTypRsnDesc,
        label: r.servcOrdEvntTypRsnDesc,
      })),
    [reasons],
  )

  return (
    <div className={`reason-select-distribute ${hasContentClass} ${disabledClass}`}>
      <label className='reason-select-label'>{label}</label>

      <Select
        value={value}
        options={reasonsOptions}
        onChange={handleChange}
        isDisabled={disabled}
        placeholder={placeholder}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
      />

      {errorHint && <label className='error-hint'>{errorHintLabel}</label>}
    </div>
  )
}

export default ReasonSelect
