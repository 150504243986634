import { useEffect, useState } from 'react'
import { fetchClustersOptions } from '../../../services/Clusters'
import { getStoresOptions } from '../../../services/Stores'

import { Clusters, Store } from '../../../models/StoreClustersGroups'
import { fillClustersByStores, fillStoresByCluster } from '../utils'

export function useStoreClustersGroups() {
  const [stores, setStores] = useState<Store[]>([])
  const [storesOptions, setStoresOptions] = useState<Store[]>([])

  const [clusters, setClusters] = useState<Clusters[]>([])
  const [clustersOptions, setClustersOptions] = useState<Clusters[]>([])

  async function fetchStores() {
    const newStores = await getStoresOptions()

    setStores(newStores)
    setStoresOptions(newStores)
  }

  async function fetchClusters() {
    const newClusters = await fetchClustersOptions()

    setClusters(newClusters)
    setClustersOptions(newClusters)
  }

  useEffect(() => {
    fetchStores()
    fetchClusters()
  }, [])

  function handleChangeStores(selectedStores: Store[]) {
    setStores(selectedStores)
    setClusters(fillClustersByStores(selectedStores, clustersOptions))
  }

  function handleChangeClusters(selectedClusters: Clusters[]) {
    setClusters(selectedClusters)
    setStores(fillStoresByCluster(selectedClusters))
  }

  function handleClearSCG() {
    setClusters(clustersOptions)
    setStores(storesOptions)
  }

  const disabledStores = storesOptions.length === 1 || storesOptions.length === 0
  const disabledClusters = clustersOptions.length === 1 || clustersOptions.length === 0

  const selectAllStores = storesOptions.length > 1
  const selectAllClusters = clustersOptions.length > 1

  return {
    stores,
    setStores,
    storesOptions,
    fetchStores,

    clusters,
    setClusters,
    clustersOptions,
    fetchClusters,

    handleClearSCG,
    handleChangeStores,
    handleChangeClusters,

    disabledStores,
    disabledClusters,
    selectAllStores,
    selectAllClusters,
  }
}
