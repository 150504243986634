import PropTypes from 'prop-types'
import { Component } from 'react'

import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'
import AwsLightbox from '../../../components/utils/images/AwsLightbox'

import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'

import PdfImage from '../../../assets/images/pdf.png'
import MozaicIcon from '../../../components/MozaicIcon'
import MozaicText from '../../../components/MozaicText'

class MeasurementValidation extends Component {
  state = {
    blobs: [],
    lightboxOpen: false,
    currentFile: false,
    removeModalOpen: false,
  }

  componentDidMount() {
    this.createBlobs()
  }

  componentDidUpdate(prevProps) {
    const { attachments } = this.props

    if (attachments.length !== prevProps.attachments.length) {
      this.createBlobs()
    }
  }

  createBlobs() {
    const { attachments = [] } = this.props

    this.setState({ blobs: [], removeModalOpen: false, lightboxOpen: false }, () => {
      attachments.forEach((file, index) => {
        let extension = file.atchmtCloudShortPathTxt.toLowerCase().split('.')
        extension = extension[extension.length - 1]

        const url = `data:${
          extension === 'pdf' ? 'application/pdf' : `image/${extension}`
        };base64,${file.servcOrdAtchmtDcmntIdNr}`

        fetch(url)
          .then((res) => res.blob())
          .then((res) => {
            const { blobs } = this.state
            blobs[index] = URL.createObjectURL(res)
            this.setState({ blobs })
          })
      })
    })
  }

  downloadBlob(blob, name) {
    const blobUrl = blob

    const link = document.createElement('a')

    link.href = blobUrl
    link.download = name

    document.body.appendChild(link)

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )

    document.body.removeChild(link)
  }

  renderLightBox() {
    const { t } = this.props
    const { lightboxOpen, currentFile, blobs } = this.state

    return (
      <AwsLightbox
        isOpen={lightboxOpen}
        onClose={() => this.setState({ lightboxOpen: false })}
        spinnerColor='green'
        imageCountSeparator={t('option.of')}
        backdropClosesModal
        showCloseButton
        data={typeof blobs[currentFile] !== 'undefined' ? blobs[currentFile] : ''}
        showImageCount={false}
      />
    )
  }

  renderRemoveModal() {
    const { t, attachments, onRemoveFile = () => {} } = this.props
    const { removeModalOpen, currentFile, blobs } = this.state

    if (!removeModalOpen) return

    let extension = attachments[currentFile].atchmtCloudShortPathTxt.toLowerCase().split('.')
    extension = extension[extension.length - 1]

    let name = attachments[currentFile].atchmtCloudShortPathTxt.split('/')
    name = name[name.length - 1]

    return (
      <Modal
        closeModal={() => this.setState({ removeModalOpen: false })}
        size='sm'
        showCloseButton
        className='measurement-remove-file'
      >
        <h3>
          <b>
            {extension === 'pdf'
              ? t('servc.ord.measurement.removeDocument.title')
              : t('servc.ord.measurement.removeImage.title')}
          </b>
        </h3>
        {extension === 'pdf' ? (
          <p>{t('servc.ord.measurement.removeDocument.text').replace('__DOC__', name)}</p>
        ) : (
          <>
            <p>{t('servc.ord.measurement.removeImage.text')}</p>
            <div className='img text-center'>
              <img src={blobs[currentFile]} />
            </div>
          </>
        )}
        <div className='btns text-right'>
          <button
            className='button-hollow button-hollow-primary margin-right'
            onClick={() => this.setState({ removeModalOpen: false })}
          >
            {t('cancel.label')}
          </button>
          <button
            className='button button-primary'
            onClick={() =>
              this.setState({ removeModalOpen: false }, () =>
                onRemoveFile(attachments[currentFile]),
              )
            }
          >
            {t('button.label.remove')}
          </button>
        </div>
      </Modal>
    )
  }

  render() {
    const {
      t,
      user,
      validation,
      validationText,
      onChange = () => {},
      onChangeText = () => {},
      attachments,
      error,
      hasBeenSentMeasurement,
      hasBeenRejectedMeansurement,
      currentStatus,
    } = this.props

    const { blobs } = this.state

    const disabled =
      ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id !== currentStatus ||
      hasBeenRejectedMeansurement

    return (
      <>
        <hr />
        <Grid fluid className='furniture-measurement'>
          <Row className='padding'>
            <Col xs={12}>
              <h1>{t('servc.ord.measurement.title')}</h1>
              <b>{t('servc.ord.measurement.subtitle')}</b>
              {hasBeenSentMeasurement ? (
                <>
                  <p>{t('servc.ord.measurement.text')}</p>
                  <div className='file-list'>
                    {attachments.map((file, k) => {
                      let extension = file.atchmtCloudShortPathTxt.toLowerCase().split('.')
                      extension = extension[extension.length - 1]

                      let name = file.atchmtCloudShortPathTxt.split('/')
                      name = name[name.length - 1]

                      return (
                        <div className='file-item'>
                          {extension === 'pdf' ? (
                            <div
                              className='pdf'
                              onClick={() =>
                                typeof blobs[k] !== 'undefined'
                                  ? window.open(blobs[k], '_blank').focus()
                                  : false
                              }
                            >
                              <img src={PdfImage} />
                              <span>{name}</span>
                            </div>
                          ) : (
                            <div
                              className='img'
                              style={{
                                backgroundImage:
                                  typeof blobs[k] !== 'undefined' ? `url('${blobs[k]}')` : '',
                              }}
                              onClick={() =>
                                this.setState({
                                  lightboxOpen: true,
                                  currentFile: k,
                                })
                              }
                            />
                          )}
                          <div className='btns'>
                            <div
                              className='button-hollow button-hollow-primary flex-center'
                              onClick={() =>
                                typeof blobs[k] !== 'undefined'
                                  ? this.downloadBlob(blobs[k], name)
                                  : false
                              }
                            >
                              <MozaicIcon icon='DownloadApp32' color='primary' />
                              <span>{t('servc.ord.measurement.download')}</span>
                            </div>
                            <div
                              className={`button-hollow button-hollow-primary pull-right ${
                                !user.accessFunctionList?.includes(107) || disabled
                                  ? 'disabled'
                                  : ''
                              }`}
                              onClick={() =>
                                this.setState({
                                  currentFile: k,
                                  removeModalOpen: true,
                                })
                              }
                            >
                              <MozaicIcon icon='PublishTrashbin32' color='primary' />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='measurement-form'>
                    <b>{t('servc.ord.measurement.form.title')}</b>
                    <MozaicText>{t('servc.ord.measurement.form.text')}</MozaicText>

                    <label className='radio'>
                      <input
                        type='radio'
                        name='measurementValidation'
                        onChange={() => onChange(true)}
                        checked={validation === true}
                        disabled={!user.accessFunctionList?.includes(108) || disabled}
                      />
                      <span>{t('servc.ord.measurement.form.approved')}</span>
                    </label>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='measurementValidation'
                        onChange={() => onChange(false)}
                        checked={validation === false}
                        disabled={!user.accessFunctionList?.includes(108) || disabled}
                      />
                      <span>{t('servc.ord.measurement.form.reproved')}</span>
                    </label>
                    <MozaicTextArea
                      placeholder={t('servc.ord.measurement.justify')}
                      readOnly={validation !== false}
                      isDisabled={validation !== false}
                      onChange={(e) => onChangeText(e.target.value)}
                      value={validationText}
                    />
                    {error && validation === false && (
                      <div className='error-container'>
                        <small className='color-danger'>
                          {t('servc.ord.measurement.justify.error')}
                        </small>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='color-danger margin-top'>
                  <b>{t('servc.ord.measurement.no-content')}</b>
                </div>
              )}
            </Col>
          </Row>
        </Grid>
        {this.renderLightBox()}
        {this.renderRemoveModal()}
      </>
    )
  }
}

MeasurementValidation.propTypes = {
  t: PropTypes.func,
  user: PropTypes.func,
  validation: PropTypes.bool,
  validationText: PropTypes.string,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onRemoveFile: PropTypes.func,
  attachments: PropTypes.array,
  error: PropTypes.bool,
  hasBeenSentMeasurement: PropTypes.bool,
  hasBeenRejectedMeansurement: PropTypes.bool,
  currentStatus: PropTypes.number,
}

export default MeasurementValidation
export { MeasurementValidation }
