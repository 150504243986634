import PropTypes from 'prop-types'
import i18next from 'i18next'

import Field from '../../../components/utils/Field'
import Row from '../../../components/utils/Row'
import Col from '../../../components/utils/Col'
import MozaicTextArea from '../../../components/MozaicTextArea'
import MozaicIcon from '../../../components/MozaicIcon'
import MozaicText from '../../../components/MozaicText'

import { useForm } from '../../../helpers/useForm'

const FIELDS = { justification: '' }

function ServiceOrderPreScheduled({ t, closeModal, onSubmit, isManualDistribution }) {
  const { register, values, fields, canSubmit } = useForm(FIELDS)

  function handleSubmitForm() {
    onSubmit(values?.justification)
  }

  function validate(text) {
    if (/[a-zA-Z]/.test(text) && text.length >= 10) return false

    return i18next.t('serviceOrder.litigation.required.field')
  }

  return (
    <div className='pre-rescheduling-modal'>
      <header>
        <h4 className='flex-between'>
          <MozaicText className='title'>
            {t('serviceOrders.change.os.to.pre.scheduled.title')}
          </MozaicText>
          <MozaicIcon icon='ControlCross32' onClick={() => closeModal()} />
        </h4>

        {isManualDistribution ? (
          <div className='content'>
            <p>{t('serviceOrders.change.os.to.pre.scheduled.confirm.text')}</p>
          </div>
        ) : null}
      </header>

      {!isManualDistribution ? (
        <section className='content margin-top'>
          <Field {...fields.justification}>
            <MozaicTextArea
              {...register('justification', { required: true, validate })}
              label={t('enblMtchngMkngPlnt.fields.enblMtchngMkngPlntDesc.placeholder')}
            />
          </Field>

          <Row className='margin-top margin-bottom'>
            <Col lg={6} md={6} sm={6} xs={6}>
              <button
                className='button-hollow button-hollow-primary margin-top  pull-right full-button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col lg={6} md={6} sm={6} xs={6}>
              <button
                className='button button-primary margin-top margin-left pull-right full-button'
                disabled={!canSubmit}
                onClick={() => handleSubmitForm()}
              >
                {t('option.yes')}
              </button>
            </Col>
          </Row>
        </section>
      ) : (
        <Row className='margin-top'>
          <Col lg={12} md={12} sm={12} xs={12} className='content-buttons'>
            <button
              className='modal-button margin-right-double primary button-hollow button-hollow-primary'
              onClick={() => closeModal()}
            >
              {t('cancel.label')}
            </button>

            <button type='submit' className='modal-button button button-primary' onClick={onSubmit}>
              {t('serviceOrders.confirm')}
            </button>
          </Col>
        </Row>
      )}
    </div>
  )
}

ServiceOrderPreScheduled.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.object,
  isManualDistribution: PropTypes.bool,
}

export default ServiceOrderPreScheduled

export { ServiceOrderPreScheduled }
