export function sortByKey(arr, key) {
  const ordenedList = arr?.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0))

  return ordenedList
}

export function sortByType(arr, key, type = 'asc') {
  if (type === 'asc') {
    const ascendingArr = arr?.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0))
    return ascendingArr
  }

  if (type === 'desc') {
    const descendingArr = arr?.sort((a, b) => (a[key] > b[key] ? -1 : a[key] > b[key] ? 1 : 0))
    return descendingArr
  }

  return []
}

export function sortByDate(arr, key, type = 'asc') {
  if (type === 'asc') {
    return arr.sort((a, b) => {
      const dateA = new Date(a[key])
      const dateB = new Date(b[key])

      return dateA - dateB
    })
  }

  if (type === 'desc') {
    const sortedResult = arr.sort((a, b) => {
      const dateA = new Date(a[key])
      const dateB = new Date(b[key])

      return dateA - dateB
    })

    return sortedResult.reverse()
  }

  return []
}

export function sortTextWithSpaces(arr, key) {
  return arr.sort((a, b) =>
    a[key].toUpperCase().localeCompare(b[key].toUpperCase(), 'pt-BR', { sensitivity: 'base' }),
  )
}

export function sortLettersNumbersSymbols(arr, key) {
  return arr.sort((a, b) => {
    const numA = !isNaN(a[key])
    const numB = !isNaN(b[key])

    if (numA && numB) {
      return Number(a) - Number(b)
    }

    if (numA) return -1
    if (numB) return 1

    const wordA = /^[a-zA-Z]+$/.test(a[key])
    const wordB = /^[a-zA-Z]+$/.test(b[key])

    if (wordA && wordB) {
      return a[key].toUpperCase().localeCompare(b[key])
    }

    if (wordA) return -1
    if (wordB) return 1

    return 1
  })
}
