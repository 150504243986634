import { useEffect, useState } from 'react'
import store from '../../../redux/store'

import { ClientDataStateType, StorageClientDataType } from '../models/clientData'

function ClientDataState(storageFilters: StorageClientDataType) {
  const [search, setSearch] = useState<string>('')
  const [clientSearchField, setClientSearchField] = useState<string>('allData')

  useEffect(() => {
    if (storageFilters) {
      const searchField = storageFilters.currentSearchField || 'allData'
      const searchValue = storageFilters.currentSearchFieldValue.toString().trim()

      setSearch(searchValue)
      setClientSearchField(searchField)
    }
  }, [storageFilters])

  const setupParameters = store.getState().auth.setupParameters
  const elasticEnabled = setupParameters.elasticEnabled

  const handleChangeRadio = (value: string) => {
    setClientSearchField(value)
    setSearch('')
  }

  const getDefaultState = () => ({
    currentSearchField: '',
    currentSearchFieldValue: '',
    elasticEnabled: true,
  })

  const getCurrentState = () => ({
    currentSearchField: clientSearchField === 'allData' ? '' : clientSearchField,
    currentSearchFieldValue: search.toString().trim(),
    elasticEnabled,
  })

  const clearState = () => {
    setSearch('')
    setClientSearchField('allData')
  }

  return {
    elasticEnabled,
    clientSearchField,
    setClientSearchField,
    search,
    handleChangeRadio,
    getCurrentState,
    getDefaultState,
    clearState,
    setSearch,
  } as ClientDataStateType
}

export default ClientDataState
