import moment from 'moment-timezone'
import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'

function DeliveryDate() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='delivery-date-info' className='skeleton' />

  return (
    <div id='delivery-date-info'>
      <label htmlFor=''>{i18next.t('delivery.date.text')}</label>
      <section>
        <span className='delivery-date-name-text'>
          {serviceOrderBody?.servcOrdPrmsdDlvryDt
            ? moment(serviceOrderBody.servcOrdPrmsdDlvryDt).format('L')
            : '-'}
        </span>
      </section>
    </div>
  )
}

export default DeliveryDate
