import i18next from 'i18next'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import CustomCard from '../../../../../../../components/Card'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { getServiceOrderHistoricDates } from '../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderHistoricDates'
import { useServiceOrderDetailContext } from '../../../../../context'
import { ServiceOrderHistoricDates } from './models/historic-dates'

type ItemType = Record<keyof ServiceOrderHistoricDates, { description: string; value?: string }>

export default function HistoricDates() {
  const defaultItems: ItemType = {
    servcOrdCreateDt: { description: i18next.t('serviceOrder.historic.createDate') },
    servcXecutnStrtDt: {
      description: i18next.t('serviceOrder.historic.serviceExecutionStartDate'),
    },
    servcXecutnEndDt: { description: i18next.t('serviceOrder.historic.serviceExecutionEndDate') },
    servcOrdEndDt: { description: i18next.t('serviceOrder.historic.endDate') },
  }

  const [items, setItems] = useState<ItemType>(defaultItems)

  const { servcOrdCd, isFetching } = useServiceOrderDetailContext()

  const fetchServiceOrderHistoryDates = async () => {
    const dates: ServiceOrderHistoricDates = await getServiceOrderHistoricDates(servcOrdCd)

    setItems((its) => ({
      servcOrdCreateDt: {
        ...its.servcOrdCreateDt,
        value: dates.servcOrdCreateDt,
      },
      servcXecutnStrtDt: {
        ...its.servcXecutnStrtDt,
        value: dates.servcXecutnStrtDt,
      },
      servcXecutnEndDt: {
        ...its.servcXecutnEndDt,
        value: dates.servcXecutnEndDt,
      },
      servcOrdEndDt: {
        ...its.servcOrdEndDt,
        value: dates.servcOrdEndDt,
      },
    }))
  }

  useEffect(() => {
    if (!isFetching) fetchServiceOrderHistoryDates()
  }, [isFetching])

  return (
    <div id='historic-dates'>
      <CustomCard
        styled
        title={i18next.t('serviceOrder.historic.dates')}
        iconNode={<MozaicIcon icon='DisplayCalendar24' />}
      >
        <div className='items-wrapper'>
          {Object.values(items).map((it, i) => (
            <div className='evaluation-item' key={i}>
              <label className='item__description'>{it.description}</label>
              <span className='item__value'>
                {it.value ? moment(it.value).format('DD/MM/YYYY HH:mm') : '-'}
              </span>
            </div>
          ))}
        </div>
      </CustomCard>
    </div>
  )
}
