import PropTypes from 'prop-types'
import classNames from 'classnames'

function InputButton(props) {
  const {
    id,
    name,
    className,
    labelAdditionalClass,
    inputAdditionalClass,
    type,
    text,
    value,
    checked,
    onChange,
    disabled = false,
    srcImgLabel,
  } = props

  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
  }

  const componentClass = classNames('field input-button', {
    [className]: className,
  })

  const labelClass = classNames('label clickable', {
    [labelAdditionalClass]: labelAdditionalClass,
  })

  const inputClass = classNames({
    [inputAdditionalClass]: inputAdditionalClass,
  })
  return (
    <div className={componentClass}>
      <input
        type={type || 'checkbox'}
        name={name}
        id={id}
        className={inputClass}
        value={value}
        checked={checked}
        onChange={(event) => handleChange(event)}
        disabled={disabled}
      />

      <label htmlFor={id} className={labelClass}>
        <div>{srcImgLabel && <img src={srcImgLabel} alt='' />}</div>
        {text}
      </label>
    </div>
  )
}

InputButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  labelAdditionalClass: PropTypes.string,
  inputAdditionalClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  srcImgLabel: PropTypes.string,
  disabled: PropTypes.bool,
}

export default InputButton
