import store from '../redux/store'
import i18next from 'i18next'
import history from '../helpers/history'
import { getPages } from '../constants/pagination'

import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { hideSpinner, showSpinner } from '../redux/actions/spinner'
import { renderToastError, renderToastSuccessMsg } from '../helpers/ToastUtils'

export const fetchServiceSpecialistFromId = async (token, servcPrvdrAgntId, noSpinner = false) => {
  const query = {
    servcPrvdrAgntId,
  }

  if (!noSpinner) store.dispatch(showSpinner())

  try {
    const { data } = await new API(token, store.dispatch).get(
      API_URL.FIND_SERVICE_SPECIALIST,
      query,
    )

    const serviceSpecialist = data[0]

    return serviceSpecialist
  } catch (error) {
    return error
  } finally {
    if (!noSpinner) store.dispatch(hideSpinner())
  }
}

export const fetchServiceSpecialistNameAutocomplete = async (token, partialText, onSuccess) => {
  const params = {
    partialText,
  }

  try {
    const { data } = await new API(token, store.dispatch).get(
      API_URL.FIND_SERVICE_SPECIALIST_NAME_AUTOCOMPLETE,
      params,
    )

    if (onSuccess) onSuccess(data)

    return data
  } catch (error) {
    return error
  }
}

export const removeServiceSpecialist = async (
  token,
  idEspecialistaServico,
  onRemoveSuccess,
  index,
) => {
  store.dispatch(showSpinner())

  const query = {
    idEspecialistaServico,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.REMOVE_SERVICE_SPECIALIST,
      query,
    )

    onRemoveSuccess(index)

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const updateServiceSpecialist = async (
  token,
  values,
  { redirectTo = '/installers', showToastSuccess = true },
) => {
  store.dispatch(showSpinner())

  const fields = {
    ...values,
  }

  if (!values.servcPrvdrAgntId) {
    fields.identifyRegistrationOrigin = 0
  }

  if (fields.specialities) {
    fields.specialities = fields.specialities.map((e) => ({ code: e }))
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SAVE_SERVICE_SPECIALIST,
      fields,
    )

    if (showToastSuccess) renderToastSuccessMsg(i18next.t('company.info.change.stores.success'))

    if (redirectTo) history.push(redirectTo)

    return data
  } catch (error) {
    renderToastError(error)

    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const saveNewSpecialistFiles = async (token, values, specialist) => {
  store.dispatch(showSpinner())

  const fields = {
    ...values,
  }

  fields.servcPrvdrAgntId = specialist.servcPrvdrAgntId

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_SPECIALIST_FILES,
      fields,
    )

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchServiceSpecialistFiles = async (token, servcPrvdrAgntId) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrAgntId,
  }

  try {
    const { data } = await new API(token, store.dispatch).get(
      API_URL.SERVICE_SPECIALIST_FILES,
      query,
    )

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const removeSpecialistFile = async (token, servcPrvdrAgntId, servcPrvdrAgntAtchmtId) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrAgntId,
    servcPrvdrAgntAtchmtId,
  }

  try {
    const { data } = await new API(token, store.dispatch).put(
      API_URL.SERVICE_SPECIALIST_FILES,
      query,
    )

    renderToastSuccessMsg(i18next.t('service.specialist.removefile.success'))

    return data
  } catch (error) {
    renderToastError(i18next.t('service.specialist.removefile.Error'))

    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchSysAcsUser = async (token, id, callBack) => {
  const url = API_URL.SYS_ACS_USER_GET.replace(':id', id)

  try {
    const { data } = await new API(token, store.dispatch).get(url)

    if (callBack) callBack(data)

    return data
  } catch (error) {
    return error
  }
}

export const inactiveSpecialist = async (token, param, onSuccess = null, onError = null) => {
  store.dispatch(showSpinner())

  const newParam = {
    servcPrvdrAgntId: param.servcAgntId,
    actvServcPrvdrAgntInd: param.actvServcPrvdrAgntInd,
    index: param.index,
    description: param.description,
    servcPrvdrTmlineEvntDesc: param.description,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.INACTIVE_SERVICE_SPECIALIST,
      newParam,
    )

    if (onSuccess) onSuccess(param.actvServcPrvdrAgntInd)

    return data
  } catch (error) {
    if (onError) onError(error)

    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const saveServiceSpecialistFiles = async (token, values) => {
  store.dispatch(showSpinner())

  const fields = {
    ...values,
    servcPrvdrAgntId: values.idEspecialistaServico,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_SPECIALIST_FILES,
      fields,
    )

    return data[0]
  } catch (error) {
    if (error.fields && error.fields[0].dsDetail != null && error.fields[0].dsDetail !== '') {
      renderToastError(error.fields[0].dsDetail)
    }

    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchInstallerRecords = async (token, params, page = 1, size = 10) => {
  store.dispatch(showSpinner())

  try {
    const { data, metadata } = await new API(token, store.dispatch, { page, size }).get(
      API_URL.FETCH_SERVC_PRVDR_AGNT_TMLINE_EVNT,
      params,
    )

    store.dispatch(getPages(metadata))

    return data
  } catch (error) {
    Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
