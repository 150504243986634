import { useEffect, useState } from 'react'
import { fetchProjectAttachments } from '../../../../../../../../../../services/Project'
import Photo from '../../../../../../../../../../components/Photo'
import { Flex } from '@mozaic-ds/react'
import BodySection from '../BodySection'
import i18next from 'i18next'

function Attachments() {
  const [attachments, setAttahments] = useState<any[]>([])

  const handleFetchAttachments = async () => {
    const files = await fetchProjectAttachments()

    setAttahments(files)
  }

  useEffect(() => {
    handleFetchAttachments()
  }, [])

  return (
    <div className='service-attachments'>
      <BodySection title={i18next.t('attachments.text')}>
        <Flex gap='mu075' wrap='wrap'>
          {attachments.map((photo, i) => (
            <Photo photo={photo} i={i} size={150} />
          ))}
        </Flex>
      </BodySection>
    </div>
  )
}

export default Attachments
