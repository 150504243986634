import { ComponentClass, memo, useEffect, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../contexts/advancedSearch'

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { handleSubmitDefault, saveQueryParams } = useAdvancedSearchContext()

    useEffect(() => {
      saveQueryParams('/providers', '?actvServcPrvdrInd=1')
      saveQueryParams('/installers', '?active=1&receivingOrder=1')
    }, [])

    const newProps = useMemo(() => ({ handleSubmitDefault }), [])

    return <MemoComponent {...props} {...newProps} />
  }
}
