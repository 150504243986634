import User from '../../../core/User'

import { fetchServiceProviderManualDistribution } from '../../ProviderList'

const user = new User().currentUser

export const fetchProvidersManualDistribution = async (
  servcPrvdrMainPlntCd: string | null,
  filters: any,
  selectedDate: any,
  selectedPeriod: string,
  providersOutsideArea?: boolean,
) => {
  const providers = await fetchServiceProviderManualDistribution(
    user.dsToken,
    filters,
    servcPrvdrMainPlntCd,
    true,
    selectedDate,
    selectedPeriod,
    [],
    providersOutsideArea,
  )

  return providers
}
