import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallbackI18n from '../../fallbacks/wrappers/i18nWrapper'

interface ErrorBoundaryI18nProps {
  children: React.ReactNode
  onReset: () => void
}

function ErrorBoundaryI18n({ children, onReset }: ErrorBoundaryI18nProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackI18n} onReset={() => onReset()}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryI18n
