import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'
import ServcOrdCategoryType from '../../../../../../../../../../../enums/ServiceOrder/category/serviceCategoryType'
import MozaicIcon from '../../../../../../../../../../../components/MozaicIcon'
import MozaicButton from '../../../../../../../../../../../components/MozaicButton'

function ServiceData({ service }: { service: ServiceExecutionEntity }) {
  const item = service?.serviceOrderItem?.at(0)
  const lmProduct = item?.codProd
  const lmProductDesc = item?.nomeProd
  const category = i18next.t(
    ServcOrdCategoryType.getCatgryTypDesc(service.servcCatgryTypCd) as string,
  )

  const title = (
    <>
      {i18next.t('service.exec.data')}

      <MozaicButton
        size='s'
        variant='bordered'
        className='project-display-menu'
        onClick={() => console.log('open menu')}
      >
        <MozaicIcon icon='DisplayOptions24' />
      </MozaicButton>
    </>
  )

  return (
    <BodySection title={title} className='service-data'>
      <FormField label={i18next.t('service.exec.lm.product')} value={lmProduct} />
      <FormField
        label={i18next.t('service.exec.lm.desc')}
        value={lmProductDesc}
        className='desktop'
      />
      <FormField label={i18next.t('faq.header.table.category')} value={category} />

      <FormField
        label={i18next.t('service.exec.lm.desc')}
        value={lmProductDesc}
        className='mobile'
      />
    </BodySection>
  )
}

export default ServiceData
