/* eslint-disable prefer-regex-literals */

import { CNPJ } from 'cpf_cnpj'
import moment from 'moment-timezone'

const EMAIL_REGEXP = /\S+@\S+\.\S+/
const EMPTY_STRING_REGEXT = /^\s*$/

export const validateEmail = (email) => EMAIL_REGEXP.test(email)

export const isString = (value) => typeof value === 'string'

export const isArray = (value) => Array.isArray(value)

export const isObject = (value) => typeof value === 'object'

export const isFunction = (value) => typeof value === 'function'

export const isDefined = (value) => value !== null && value !== undefined

// eslint-disable-next-line consistent-return
export const isEmpty = (value) => {
  // Null and undefined is blank
  if (!isDefined(value)) {
    return true
  }

  // Functions is not blank
  if (isFunction(value)) {
    return false
  }

  // Empty strings is blanks
  if (isString(value)) {
    return EMPTY_STRING_REGEXT.test(value)
  }

  // Arrays without values is blank
  if (isArray(value)) {
    return value.length === 0
  }

  // Object without keys is blank
  if (isObject(value)) {
    return Object.keys(value).length === 0
  }
}

export const isKeys = (value) =>
  Object.keys(value).reduce((obj, name) => {
    const field = value[name]

    if (!isEmpty(field)) {
      // eslint-disable-next-line no-param-reassign
      obj[name] = field
    }

    return obj
  }, {})

export const isCnpjValid = (cnpj) => CNPJ.isValid(cnpj)

export const isNumberSeparetedByComma = (string) => {
  const REGEXP = /^\d+(,\d+)*$/

  return REGEXP.test(string)
}

export const isRange1to10 = (value) => {
  const REGEXP = new RegExp(/^([1-9]|10)$/)

  return REGEXP.test(value)
}

export const isKeyMinus = (keyCode) => {
  const keys = [189, 109]

  return keys.includes(keyCode)
}

export const isKeyZero = (keyCode) => {
  const keys = [48, 96]

  return keys.includes(keyCode)
}

export const getNumbers = (value) => {
  const numbers = value.match(/\d+/g)
  return numbers ? numbers.join([]) : ''
}

export const formatCnpj = (cnpj) =>
  cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')

export const formatCurrency = (value = 0, t = (value) => value) => {
  const number = Math.abs(Number(value)).toFixed(2).split('.')
  number[0] = `${t('currency-unit')} ${number[0].split(/(?=(?:...)*$)/).join('.')}`

  return number.join(',')
}

export const formatDate = (date) => (!date ? '-' : moment(date).format('L'))

/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable curly */

export const validateCpf = function (cpf) {
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(10, 11))) return false
  return true
}

export const getNumberCEP = function (country) {
  if (country === 'PT') {
    return 8
  }
  return 9
}

/* eslint-enable no-redeclare */
/* eslint-enable eqeqeq */
/* eslint-disable curly */
