import i18next from 'i18next'
import { memo, useEffect, useMemo, useState } from 'react'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import ProjectRow from './components/ProjectRow'

import { Flex, Text } from '@mozaic-ds/react'
import { useQuery } from '@tanstack/react-query'
import { useAdvancedSearchContext } from '../../contexts/advancedSearch'
import { ProjectEntity, QueryParamsType } from '../../models/Project'
import { fetchProjects } from '../../services/ProjectList'
import { QueryContext } from './context'
import { parseResultsText } from './utils'

type ProjectsListProps = {
  search: string | undefined
  searchParams: QueryParamsType
  projects: ProjectEntity[]
  isLoading: boolean
}

function ProjectsList({ search, searchParams, projects, isLoading }: ProjectsListProps) {
  const [lazyIndex, setLazyIndex] = useState<number>(10)

  useEffect(() => {
    setLazyIndex(10)
  }, [projects])

  function checkLastRow() {
    const el = document.getElementsByClassName('project-row-header')[lazyIndex - 1]

    if (el) {
      const rect = el.getBoundingClientRect()

      if (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setLazyIndex(lazyIndex + 10)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkLastRow)

    return () => window.removeEventListener('scroll', checkLastRow)
  }, [checkLastRow])

  if (isLoading)
    return (
      <>
        <BreadcrumbBar title={i18next.t('entities.project')} />
        <div className='projects-skeleton'>
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
        </div>
      </>
    )

  const parsedProjects = projects.slice(0, lazyIndex)
  const searchLabel = parseResultsText(search as string, projects?.length || 0)

  return (
    <QueryContext.Provider value={searchParams}>
      <BreadcrumbBar title={i18next.t('entities.project')} />

      <Flex direction='column' padding='mu200' className='projects-list'>
        <Text>{searchLabel}</Text>

        <Flex direction='column' marginTop='mu075'>
          {parsedProjects.map((project) => (
            <ProjectRow project={project} key={project.prjctExctnSeq} />
          ))}
        </Flex>
      </Flex>
    </QueryContext.Provider>
  )
}

const MemoComponent = memo(ProjectsList)

function ProjectsWithContext() {
  const { filters } = useAdvancedSearchContext()

  const search = filters?.currentSearchFieldValue
  const searchParams = useMemo(() => ({ q: search }), [search]) as QueryParamsType

  const { data, isLoading } = useQuery({
    queryKey: ['projectsList', search],
    queryFn: () => fetchProjects(search),
  })

  const projects = data?.content || []

  const newProps = useMemo(
    () => ({
      projects,
      isLoading,
      search,
      searchParams,
    }),
    [projects, isLoading, search, searchParams],
  )

  return <MemoComponent {...newProps} />
}

export default ProjectsWithContext
