import PropTypes from 'prop-types'
import loading from '../../../assets/icons/loading.svg'
import Lightbox from 'react-images'

function AwsLightbox(props) {
  const {
    isOpen,
    spinnerColor,
    imageCountSeparator,
    showCloseButton,
    backdropClosesModal,
    onClose,
    data,
    showImageCount = true,
  } = props

  const images = [{ src: data || loading }]

  return (
    <Lightbox
      images={images || []}
      isOpen={isOpen}
      onClose={onClose}
      spinnerColor={spinnerColor}
      imageCountSeparator={imageCountSeparator}
      backdropClosesModal={backdropClosesModal}
      showCloseButton={showCloseButton}
      showImageCount={showImageCount}
    />
  )
}

AwsLightbox.propTypes = {
  data: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  spinnerColor: PropTypes.string,
  imageCountSeparator: PropTypes.string,
  showCloseButton: PropTypes.bool,
  backdropClosesModal: PropTypes.bool,
  showImageCount: PropTypes.bool,
}

export default AwsLightbox
