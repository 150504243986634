import { memo } from 'react'
import i18next from 'i18next'

import moment from 'moment-timezone'

interface SeparatorByDayProps {
  title: string
}

function SeparatorByDay(props: SeparatorByDayProps) {
  const { title } = props

  function handleResolveSeparatorTitle() {
    const todayDate = moment().format('L')
    const yesterdayDate = moment().subtract(1, 'days').format('L')

    if (title === todayDate) {
      return i18next.t('chat.today')
    }

    if (title === yesterdayDate) {
      return i18next.t('chat.yesterday')
    }

    return title
  }

  return <div id='separator-by-day-container'>{handleResolveSeparatorTitle()}</div>
}

export default memo(SeparatorByDay)
