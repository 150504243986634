import i18next from 'i18next'
import PropTypes from 'prop-types'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { UsableAreaProviderConnected } from '../../contexts/usableArea'
import CompanyInfo from './components/Company'
import FilterGroup from './components/FilterGroup'
import { ProviderAnalysisProvider } from './context'

function ProviderAnalysis(props) {
  const {
    match: {
      params: { id },
    },
  } = props

  return (
    <>
      <BreadcrumbBar goBack title={i18next.t('lmi.provider.analysis.menu')} />

      <UsableAreaProviderConnected>
        <ProviderAnalysisProvider id={id}>
          <div id='provider-analysis-container'>
            <CompanyInfo />

            <FilterGroup />
          </div>
        </ProviderAnalysisProvider>
      </UsableAreaProviderConnected>
    </>
  )
}

ProviderAnalysis.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ProviderAnalysis
