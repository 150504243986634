import i18next from 'i18next'
import Grid from '../../../../../../../../../components/utils/Grid'

type BudgetDistributedProps = {
  readonly budgetDistributed: Function
  readonly block?: boolean
  readonly boxSelected?: boolean
}

export default function BudgetDistributed({
  budgetDistributed,
  block,
  boxSelected,
}: BudgetDistributedProps) {
  const handleOptionAccepted = () => {
    budgetDistributed()
  }

  const getClassName = () =>
    `option ${boxSelected === true ? 'selected' : block === false ? 'selected' : 'no-selected'}`

  const getClassNameBody = () => {
    if (block) {
      if (boxSelected) {
        return 'body block'
      }
      return 'body'
    }
    return 'body block'
  }

  return (
    <Grid fluid>
      <div className='body-so-budget-distributed'>
        <span className='title'>{i18next.t('servc.ord.budget.component.budget.distributed')}</span>
        <div className={getClassNameBody()}>
          <div className={getClassName()} onClick={() => handleOptionAccepted()}>
            <p className='text-option'>
              {i18next.t('servc.ord.budget.component.distributed.option')}
            </p>
          </div>
        </div>
      </div>
    </Grid>
  )
}
