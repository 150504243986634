import { Component } from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { colourStyles } from '../../helpers/selectStyle'
import Api from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'

import Select from 'react-select'

const user = new User().currentUser

class StatesSelect extends Component {
  constructor() {
    super()

    this.state = {
      options: [],
      error: null,
    }
  }

  componentDidMount() {
    this.getStates()
  }

  getStates() {
    const { dispatch, fetchURL = API_URL.FIND_STATES } = this.props

    new Api(user.dsToken, dispatch)
      .get(fetchURL)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(states) {
    const options = states.map((state) => ({
      value: state.stateCd,
      label: state.stateNm,
    }))

    this.setState({ options, error: null })
  }

  onFetchError(error) {
    this.setState({ error })
  }

  handleChange = (option) => {
    const { onChange } = this.props

    onChange(option ? option.value : null)
  }

  render() {
    const {
      id,
      placeholder,
      label,
      name,
      value,
      onChange,
      disabled = false,
      isMulti,
      resetValue,
    } = this.props

    const { options } = this.state

    const selected = isMulti ? value : options.find((state) => state.value === value)
    const hasLabel = value !== undefined || value !== null

    return (
      <div>
        {hasLabel && (
          <label htmlFor={id} className='label up-label'>
            {placeholder}
          </label>
        )}
        <Select
          id={id}
          name={name}
          label={label}
          value={selected}
          onChange={(event, type) => onChange(event, type)}
          isDisabled={disabled}
          options={options}
          placeholder={placeholder}
          isClearable
          resetValue={resetValue || ''}
          noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          styles={colourStyles}
          isMulti={isMulti}
        />
      </div>
    )
  }
}

StatesSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.string,
  fetchURL: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  resetValue: PropTypes.any,
}

function mapStateToProps({ auth }) {
  return {
    auth,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dispatch }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StatesSelect)
