import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'

import User from '../../core/User'
import { getFirebaseConfigs } from './repository/getFirebaseParameters'

const user = new User()

class FirestoreAdapter {
  db: any

  storage: any

  authUser = async (auth: any) => {
    try {
      await auth.signInWithCustomToken(user.currentUser.dsCustomTokenFirebase)
    } catch {
      return 'Firebase token expired (re-autenticate)'
    }
  }

  init = async () => {
    if (this.db) return Promise.reject(new Error('Firebase not initialized'))

    return new Promise((resolve, reject) => {
      this.initFirestore(resolve, reject)
    })
  }

  // Reject is not used, but in the future it can be used to catch errors
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initFirestore = async (resolve: Function, reject: Function) => {
    try {
      const configs = await getFirebaseConfigs()
      const app = firebase.initializeApp(configs)
      const auth = firebase.auth(app)

      auth.onAuthStateChanged((fbUser) => {
        if (!fbUser) return this.authUser(auth)

        this.db = firebase.firestore()
        this.storage = firebase.app().storage(configs.gcloudStorage)

        setTimeout(() => resolve(fbUser), 50)
      })
    } catch (error: any) {
      return error
    }
  }
}

const firestoreAdapter = new FirestoreAdapter()

export default firestoreAdapter
