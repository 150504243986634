import { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { ACCOUNT_TYPES } from '../../../constants/config'
import { colourStyles } from '../../../helpers/selectStyle'

class AccountTypeSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: undefined,
    }
  }

  createOptions = (resource) => {
    const { t } = this.props
    const options = []

    if (typeof resource[0] === 'object') {
      resource.map((item) => {
        options.push({ value: item.id, label: t(item.name) })
      })
    } else {
      Object.keys(resource).forEach((item) => {
        options.push({ value: item, label: t(resource[item]) })
      })
    }

    return options
  }

  value = () => {
    const { value } = this.props
    return this.createOptions(ACCOUNT_TYPES).filter((option) => option.value === value)
  }

  render() {
    const { id, placeholder, name, value, onChange, t, disabled = false } = this.props

    return (
      <div>
        {typeof (value !== 'undefined' && value !== null) && (
          <label htmlFor={id} className='label up-label label-alignment'>
            {placeholder}
          </label>
        )}
        <Select
          id={id}
          name={name}
          value={this.createOptions(ACCOUNT_TYPES).filter((option) => option.value === value)}
          onChange={(event) => {
            this.setState({ value: event })
            onChange(event)
          }}
          isDisabled={disabled}
          options={this.createOptions(ACCOUNT_TYPES)}
          placeholder={placeholder}
          isClearable
          resetValue=''
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          styles={colourStyles}
        />
      </div>
    )
  }
}

AccountTypeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
}

export { AccountTypeSelect }
