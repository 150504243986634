import PropTypes from 'prop-types'
import classNames from 'classnames'

function Select(props) {
  const { id, name, value, onChange, onBlur, label, children, disabled = false, className } = props

  const handleChange = (event) => {
    onChange(event)
  }

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  const cls = classNames('select', className)

  return (
    <div>
      <select
        {...props}
        id={id}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cls}
        value={value}
        disabled={disabled}
      >
        {children}
      </select>

      {label && (
        <label htmlFor={id} className='label'>
          {label}
        </label>
      )}
    </div>
  )
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  children: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default Select
