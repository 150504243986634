import { useState } from 'react'
import i18next from 'i18next'

import MozaicText from '../../../../../../../../components/MozaicText'
import Modal from '../../../../../../../../components/Modal'
import FooterButtons from '../FooterButtons'

import { confirmOS } from '../../../../../../../../services/pages/ServiceOrderDetail/ConfirmOS'
import { useServiceOrderDetailContext } from '../../../../../../context'

type ConfirmOSModalType = {
  show: boolean
  handleClose: Function
}

function ConfirmOSModal(props: ConfirmOSModalType) {
  const { reloadServiceOrder, serviceOrderBody } = useServiceOrderDetailContext()
  const { show, handleClose } = props
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const handleSubmit = async () => {
    setIsSubmiting(true)

    await confirmOS(serviceOrderBody?.servcOrdSeq || 0)

    handleClose()
    setIsSubmiting(false)
    await reloadServiceOrder()
  }

  const labelText = `${i18next.t('order.detail.confirm.os.modal.text')}?`

  return (
    <Modal
      isOpen={show}
      onClose={handleClose}
      className='no-margin-header confirm-os-modal'
      closeInOverlay={false}
      contentLabel={i18next.t('order.detail.confirm.os.modal.title')}
    >
      <MozaicText> {labelText} </MozaicText>

      <FooterButtons handleSubmit={handleSubmit} handleClose={handleClose} disabled={isSubmiting} />
    </Modal>
  )
}

export default ConfirmOSModal
