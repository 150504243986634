import store from '../../redux/store'
import FetchAdapter from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

interface UpdateServiceProviderReceiptServiceOrderStatusParams {
  actvServcPrvdrOrdRecvInd: '0' | '1'
  servcPrvdrId: number
  description: string
  servcPrvdrTmlineEvntDesc: string
}

export const updateServiceProviderReceiptServiceOrder = async (
  params: UpdateServiceProviderReceiptServiceOrderStatusParams,
): Promise<ADEOResponse> =>
  new FetchAdapter(user.currentUser.dsToken, store.dispatch).post(
    API_URL.SERVICE_PROVIDER_SET_ACTV_SERVC_PRVDR_ORD_RECV_IND,
    params,
  )
