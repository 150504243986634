import i18next from 'i18next'
import { useState } from 'react'
import { Page, Document as PdfDocument, pdfjs } from 'react-pdf'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import OutlineButton from '../../../../../../../components/OutlineButton'

import IconClose from '../../../../../../../assets/icons/close.svg?react'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Item {
  src: string
  title: string
  createdAt: string
  user: string
  type: 'img' | 'pdf'
}

export interface LightBoxProps {
  readonly items: Item[]
  readonly initialIndex: number
  readonly onClose: () => void
}

export default function LightBox({ items, initialIndex, onClose }: LightBoxProps) {
  const [index, setIndex] = useState(initialIndex)

  const [pdfNumOfPages, setPdfNumOfPages] = useState(0)
  const [pdfCurrentPage, setPdfCurrentPage] = useState(1)

  const canShowArrow = items.length > 1

  const nextItem = () => {
    setIndex((i) => (i + 1 >= items.length ? 0 : i + 1))
  }

  const previousItem = () => {
    setIndex((i) => (i <= 0 ? items.length - 1 : i - 1))
  }

  const handlePdfLoadSuccess = (numPages: number) => {
    setPdfNumOfPages(numPages)
    setPdfCurrentPage(1)
  }

  const previousPdfPage = () => setPdfCurrentPage((i) => (i <= 1 ? pdfNumOfPages : i - 1))
  const nextPdfPage = () => setPdfCurrentPage((i) => (i >= pdfNumOfPages ? 1 : i + 1))

  const renderItem = () => {
    const item = items[index]

    switch (item.type) {
      case 'img':
        return <img id='image-viewer' src={item.src} alt={item.title} />
      case 'pdf':
        return (
          <div className='pdf'>
            <PdfDocument
              file={item.src}
              onLoadSuccess={({ numPages }) => handlePdfLoadSuccess(numPages)}
            >
              <Page pageNumber={pdfCurrentPage} className='pdf-page' />
            </PdfDocument>

            {pdfNumOfPages > 1 && (
              <div className='controls'>
                <OutlineButton
                  classname='pdf-control-button prev-page-button'
                  iconNode={<MozaicIcon icon='ArrowArrowLeft24' className='icon-arrow-custom' />}
                  onClick={previousPdfPage}
                />
                <div className='page-info'>
                  {pdfCurrentPage}/{pdfNumOfPages}
                </div>
                <OutlineButton
                  classname='pdf-control-button next-page-button'
                  iconNode={<MozaicIcon icon='ArrowArrowRight24' className='icon-arrow-custom' />}
                  onClick={nextPdfPage}
                />
              </div>
            )}
          </div>
        )
    }
  }

  return (
    <div id='light-box'>
      <div className='backdrop' onClick={onClose} />

      {canShowArrow && (
        <div className='arrow arrow-left'>
          <OutlineButton
            classname='outline-button prev-file-button'
            iconNode={<MozaicIcon icon='ArrowArrowLeft24' className='icon-arrow-custom' />}
            onClick={previousItem}
          />
        </div>
      )}

      {canShowArrow && (
        <div className='arrow arrow-right'>
          <OutlineButton
            classname='outline-button next-file-button'
            iconNode={<MozaicIcon icon='ArrowArrowRight24' className='icon-arrow-custom' />}
            onClick={nextItem}
          />
        </div>
      )}

      <div className='container'>
        <div className='item-header'>
          <button className='close-button' onClick={onClose}>
            <span>{i18next.t('serviceOrder.attachments.lightBox.close')}</span>
            <IconClose width={12} height={12} />
          </button>
        </div>

        {renderItem()}

        <div className='item-footer'>
          <div className='description'>
            <span>{items[index]?.title}</span>
            <span>{items[index]?.createdAt}</span>
            <span>{items[index]?.user}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
