import { Text, toast } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { ChangeEvent, useRef, useState } from 'react'
import Modal from '../../../../../../../components/Modal'
import OutlineButton from '../../../../../../../components/OutlineButton'
import { hideSpinner, showSpinner } from '../../../../../../../redux/actions/spinner'
import store from '../../../../../../../redux/store'
import {
  addAttachment,
  fileToBase64,
} from '../../../../../../../services/pages/ServiceOrderDetail/AddAttachment'
import { useServiceOrderDetailContext } from '../../../../../context'
import FooterButtons from './components/FooterButtons'

type AttachBudgetModalType = {
  show: boolean
  setShow: Function
}

const FILE_INDICATOR = 3
const imgTypes = ['jpg', 'jpeg', 'png']
const allowedFileTypes = [
  { mimeType: 'application/pdf', extension: 'pdf' },
  { mimeType: 'image/jpg', extension: 'jpg' },
  { mimeType: 'image/jpeg', extension: 'jpeg' },
  { mimeType: 'image/png', extension: 'png' },
]

function AttachBudgetModal(props: AttachBudgetModalType) {
  const { show, setShow } = props
  const { servcOrdCd, reloadServiceOrder } = useServiceOrderDetailContext()

  const inputFileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const fileToSelect = event.target.files && event.target.files[0]

    if (!fileToSelect) return false

    const isFileTypeAllowed =
      fileToSelect && allowedFileTypes.some((type) => type.mimeType === fileToSelect.type)

    if (!isFileTypeAllowed) {
      toast.error('', i18next.t('serviceOrder.attachments.add.fileTypeNotAllowed'), '')
      return
    }

    setFile(fileToSelect)
  }

  const parsedFileType = (type: string) => (imgTypes.includes(type) ? 'img' : 'pdf')

  const onClose = () => setShow(false)

  const handleSubmit = async () => {
    if (!file) return false

    store.dispatch(showSpinner())
    setIsSubmitting(true)

    const parsedFile = (await fileToBase64(file)).split('base64,')[1]
    const fileType = parsedFileType(file.type.split('/')[1])

    try {
      await addAttachment({
        servcOrdSeq: servcOrdCd,
        atchmtDocmntTyp: '17',
        atchmtDocmntTypDesc: i18next.t('serviceOrders.category.type.budget'),
        isPortalService: true,
        type: fileType,
        files: [
          {
            name: file.name,
            fileIndicador: FILE_INDICATOR,
            file: parsedFile,
          },
        ],
      })

      onClose()
      await reloadServiceOrder()
    } catch (error: any) {
      toast.error('', error?.dsMessage || i18next.t('dao.save.error'), '')
    }

    setIsSubmitting(false)
    store.dispatch(hideSpinner())
  }

  const canSubmit = file && !isSubmitting
  const fileInputLabel = i18next.t('provider.portal.choose.file')
  const fileInputHint = i18next.t('fileInput.hint.single')
  const hasFileClass = file?.name ? 'has-value' : ''
  const labelText = i18next.t('serviceOrder.budget.modal.text')

  const countAllowedExts = allowedFileTypes.length - 1
  const fileInputAllowed = allowedFileTypes
    .map((extension) => extension.extension.toUpperCase())
    .reduce((result, extension, i) => {
      // eslint-disable-next-line no-param-reassign
      if (i === countAllowedExts) result += ` ${i18next.t('or')} ${extension}`
      // eslint-disable-next-line no-param-reassign
      else result += `, ${extension}`
      return result
    })

  return (
    <div id='attach-budget-modal'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => onClose()}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('serviceOrders.category.type.budget')}
      >
        <Text> {labelText} </Text>

        <div className='field'>
          <label>{i18next.t(`serviceOrder.context.menu.attach.budget`)}</label>

          <div className='input-file-container'>
            <div className={`input-file ${hasFileClass}`}>
              <div className='file-description'>{file?.name || ''}</div>
              <input
                id='file-attach-input'
                type='file'
                accept={`${allowedFileTypes.map((type) => type.mimeType).join(',')}`}
                onChange={handleFileSelect}
                ref={inputFileRef}
                hidden
              />
              <OutlineButton
                classname='choose-file'
                label={fileInputLabel}
                onClick={() => inputFileRef.current?.click()}
              />
            </div>
          </div>

          <small>{`${fileInputHint} ${fileInputAllowed}.`}</small>
        </div>

        <FooterButtons
          disabled={!canSubmit}
          handleSubmit={handleSubmit}
          handleClose={() => onClose()}
        />
      </Modal>
    </div>
  )
}

export default AttachBudgetModal
