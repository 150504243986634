import i18next from 'i18next'
import classNames from 'classnames'
import {
  BookStore24,
  PublishTrashbin24,
  DisplayView24,
  PublishEdit24,
} from '@mozaic-ds/icons/react'

import './index.scss'

/**
 * @description Context Menu Option for actions
 */

export interface ContextMenuOptionProps {
  icon: 'view' | 'edit' | 'book' | 'delete'
  label: string
  onClick?: () => void
  isDisabled?: boolean
  title?: string
}

export function ContextMenuOption({
  icon,
  label,
  onClick,
  isDisabled = false,
  title,
}: ContextMenuOptionProps) {
  const className = classNames('context-menu-option', {
    disabled: isDisabled,
  })
  const labelTranslated = i18next.t(label)
  const iconChoosed = {
    view: <DisplayView24 className='view-icon' />,
    edit: <PublishEdit24 className='edit-icon' />,
    book: <BookStore24 />,
    delete: <PublishTrashbin24 className='delete-icon' />,
  }[icon]

  const handleOnClick = () => {
    if (!isDisabled && onClick !== undefined) onClick()
  }

  return (
    <div className={className} onClick={handleOnClick} title={title}>
      {iconChoosed}
      <p>{labelTranslated}</p>
    </div>
  )
}
