import { memo } from 'react'
import i18next from 'i18next'
import MozaicIcon from '../MozaicIcon'

interface EditButtonProps {
  onClickEditButton: () => void | undefined
  showTitle?: boolean
}

function EditButton(props: EditButtonProps) {
  const { showTitle = true, onClickEditButton } = props

  return (
    <button type='button' className='card-edit' onClick={() => onClickEditButton()}>
      <>
        {showTitle && i18next.t('accordeon.action.edit')}
        <MozaicIcon icon='PublishEdit24' />
      </>
    </button>
  )
}

export default memo(EditButton)
