import i18next from 'i18next'
import { memo, useEffect, useState } from 'react'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import MozaicIcon from '../../components/MozaicIcon'
import Tabs from '../../components/Tabs'
import { UsableAreaProviderConnected as UsableArea } from '../../contexts/usableArea'
import history from '../../helpers/history'
import AttachmentsContainer from './components/Routes/Attachments/index'
import HistoricContainer from './components/Routes/Historic/index'
import ServiceContainer from './components/Routes/Service/index'
import { ServiceOrderDetailProvider } from './context'

interface ServiceOrderDetailProps {
  match: { params: { servcOrdCd: string } }
}

function ServiceOrderDetail({
  match: {
    params: { servcOrdCd },
  },
}: Readonly<ServiceOrderDetailProps>) {
  const [lastRouteAccessed, setLastRouteAccessed] = useState<null | string>(null)

  /**
   * Used to catch the first "lastRouteAccessed" value and not lose search params
   * from service order list when user access the service order detail page
   */
  useEffect(() => {
    const lastRouteAccessed = localStorage.getItem('lastRouteAccessed')
    setLastRouteAccessed(lastRouteAccessed)
  }, [])

  const routes = [
    {
      path: '/service',
      label: i18next.t('serviceOrders.services'),
      icon: <MozaicIcon icon='Document32' size={22} />,
      component: <ServiceContainer />,
    },
    {
      path: '/attachments',
      label: i18next.t('attachments.text'),
      icon: <MozaicIcon icon='Inspiration64' size={22} />,
      component: <AttachmentsContainer />,
    },
    {
      path: '/history',
      label: i18next.t('historic.text'),
      icon: <MozaicIcon icon='TimeTimeDecline32' size={22} />,
      component: <HistoricContainer />,
    },
  ]

  const findRouteToBack = () => {
    const actualRoute = window.location.pathname
    const isToBackToServiceOrderList =
      actualRoute === lastRouteAccessed ||
      !lastRouteAccessed ||
      lastRouteAccessed.includes('serviceOrder/edit') ||
      lastRouteAccessed.includes('serviceOrder/detail')

    return isToBackToServiceOrderList ? '/serviceOrder' : lastRouteAccessed
  }

  return (
    <>
      <BreadcrumbBar
        title={`${i18next.t('lmi.serviceOrders')} ${servcOrdCd}`}
        buttons={[
          {
            label: i18next.t('serviceOrder.goToOldLayout'),
            variant: 'bordered',
            onClick: () => {
              history.push(`/serviceOrder/edit/${servcOrdCd}`, {
                lastRouteAccessedInState: lastRouteAccessed,
              })
            },
          },
        ]}
        goBack={() => history.push(findRouteToBack())}
      />

      <UsableArea>
        <ServiceOrderDetailProvider servcOrdCd={parseInt(servcOrdCd)}>
          <div id='service-order-detail-container'>
            <Tabs routes={routes} />
          </div>
        </ServiceOrderDetailProvider>
      </UsableArea>
    </>
  )
}

export default memo(ServiceOrderDetail)
