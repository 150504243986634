import firestoreAdapter from '../firebase'

const version = 3

export default function CustomerChatService() {
  function getReferenceChat() {
    return firestoreAdapter.db?.collection('customerChat')
  }

  async function getAll() {
    const collection = await getReferenceChat().get()

    const documents = collection.docs.map((doc: any) => doc.data())

    return documents
  }

  async function get(conversation: string) {
    const collection = await getReferenceChat().doc(String(conversation)).get()
    const data = collection.data()

    return data
  }

  function getReference(conversation: string) {
    const collection = getReferenceChat().doc(String(conversation))

    return collection
  }

  async function transferColumns(
    col = 'custFullNm',
    target = 'custSearchNm',
    convert = (value: any) => value.toUpperCase(),
  ) {
    const snapshot = await getReferenceChat().get()

    if (snapshot.empty) return

    snapshot.forEach(async (doc: any) => {
      const colValue = doc.data()[col]
      getReferenceChat()
        .doc(doc.id)
        .update({ [target]: convert(colValue) })
    })
  }
  async function restoreServcOrdCd() {
    const snapshot = await getReferenceChat().get()

    if (snapshot.empty) return

    snapshot.forEach(async (doc: any) => {
      getReferenceChat()
        .doc(doc.id)
        .update({ servcOrdCd: String(doc.id) })
    })
  }

  async function checkChat(resolve: Function) {
    try {
      const snapshot = await getReferenceChat().doc('version').get()

      if (!snapshot.exists) getReferenceChat().doc('version').set({ version: 1 })

      resolve(false)
    } catch (err) {
      setTimeout(() => checkChat(resolve), 1000)
    }
  }

  async function checkChatV2() {
    return new Promise((resolve) => {
      checkChat(resolve)
    })
  }

  return {
    getReferenceChat,
    getAll,
    get,
    getReference,
    transferColumns,
    restoreServcOrdCd,
    checkChat,
    checkChatV2,
  }
}

export type CustomerChatServiceType = {
  getReferenceChat: Function
  getAll: Function
  get: Function
  getReference: Function
  transferColumns: Function
  restoreServcOrdCd: Function
  checkChat: Function
}
