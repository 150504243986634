import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../../../../context'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { observation, loadingObs, reloadServiceOrder } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        observation,
        loadingObs,
        reloadServiceOrder,
      }),
      [observation, loadingObs],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
