import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'

function ClientInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='client-info' className='skeleton' />

  return (
    <div id='client-info'>
      <label htmlFor=''>{i18next.t('service.order.detatails.client')}</label>
      <section>
        <span className='client-name-text'>{serviceOrderBody?.custFullNm || '-'}</span>
      </section>
    </div>
  )
}

export default ClientInfo
