import i18next from 'i18next'
import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import store from '../../../redux/store'

export interface UpdatePhonesType {
  custHmPhonNr: string
  custMobilePhonNr: string
  custBusnsPhonNr: string
}

export const updatePhones = async (params: UpdatePhonesType, servcOrdSeq: number) => {
  try {
    const { cdStatus } = await new API_REQUEST(null, store.dispatch).patch(
      `${API_URL.V1_ORDERS}/${servcOrdSeq}`,
      { ...params },
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('service.order.update.phones.success.message'),
      '',
    )

    return cdStatus
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
