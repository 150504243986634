import { useEffect, useMemo, useState } from 'react'
import { GCloudImageRef } from '../../services/firestore/gCloudStorage/GCloudImageRef'
import { GCloudFileRef } from '../../services/firestore/gCloudStorage/GCloudFileRef'

import MozaicIcon from '../MozaicIcon'
import { Loader } from '@mozaic-ds/react'

type PhotoV2Props = {
  file: GCloudImageRef | GCloudFileRef
  onClick?: () => void
}

function GCloudFile({ file, onClick }: PhotoV2Props) {
  const [url, setUrl] = useState('')

  const isImg = file instanceof GCloudImageRef

  useEffect(() => {
    file.getDownloadURL().then((url) => setUrl(url))
  }, [file.downloadUrl])

  const { createdAt, user, title, service } = useMemo(() => file.getFileInfo(), [file.attachment])

  return (
    <div className='photo-v2'>
      <div className='photo-v2__image' onClick={onClick}>
        {!url && (
          <div className='loading'>
            <Loader />
          </div>
        )}
        {url && isImg && <img src={url} />}
        {url && !isImg && (
          <div className='thumb'>
            <MozaicIcon icon='Document32' className='icon-document-custom' size={64} />
          </div>
        )}
      </div>

      <div className='photo-v2__content'>
        <div className='description'>
          <div className='description__title' title={title}>
            {title}
          </div>
          <div className='description__created-at' title={createdAt}>
            {createdAt}
          </div>
          <div className='description__user' title={user}>
            {user}
          </div>
          <div className='description__user' title={user}>
            {service}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GCloudFile
