import { useEffect, useState } from 'react'
import { AlertsStateType, StorageAlertsType } from '../models/alerts'

function AlertsState(currentFilters: StorageAlertsType) {
  const [indValidateMeasurementSheet, setIndValidateMeasurementSheet] = useState<boolean>()
  const [acceptanceServiceConditions, setAcceptanceServiceConditions] = useState<boolean>(false)
  const [indWaitingTechReport, setIndWaitingTechReport] = useState<boolean>(false)
  const [indWaitingLateTechReport, setIndWaitingLateTechReport] = useState<boolean>(false)
  const [indRunningRework, setIndRunningRework] = useState<boolean>(false)
  const [indWaitingMeasurementSheet, setIndWaitingMeasurementSheet] = useState<boolean>(false)
  const [indAlertServices, setIndAlertServices] = useState<boolean>(false)
  const [sosOutOfFlow, setSosOutOfFlow] = useState<boolean>(false)
  const [manualDistributed, setManualDistributed] = useState<boolean>(false)
  const [servcOnTime, setServcOnTime] = useState<boolean>(false)
  const [serviceOrderHasAttachments, setServiceOrderHasAttachments] = useState<boolean>(false)
  const [sosPendingBudget, setSosPendingBudget] = useState<boolean>(false)
  const [sosWaitingQuotation, setSosWaitingQuotation] = useState<boolean>(false)
  const [conformity, setConformity] = useState<boolean>(false)
  const [waitingResponsible, setWaitingResponsible] = useState<boolean>(false)
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false)
  const [waitingBudget, setWaitingBudget] = useState<boolean>(false)
  const [recommendInspection, setRecommendInspection] = useState<boolean>(false)
  const [attentionToDelivery, setAttentionToDelivery] = useState<boolean>(false)
  const [negativeOv, setNegativeOv] = useState<boolean>(false)
  const [providerReschedule, setProviderReschedule] = useState<boolean>(false)
  const [clientRescheduleRefuse, setClientRescheduleRefuse] = useState<boolean>(false)
  const [salesError, setSalesError] = useState<boolean>(false)

  const clearState = () => {
    setIndValidateMeasurementSheet(false)
    setAcceptanceServiceConditions(false)
    setIndWaitingTechReport(false)
    setIndWaitingLateTechReport(false)
    setIndRunningRework(false)
    setIndWaitingMeasurementSheet(false)
    setIndAlertServices(false)
    setSosOutOfFlow(false)
    setManualDistributed(false)
    setServcOnTime(false)
    setServiceOrderHasAttachments(false)
    setSosPendingBudget(false)
    setSosWaitingQuotation(false)
    setConformity(false)
    setWaitingResponsible(false)
    setAssignedToMe(false)
    setWaitingBudget(false)
    setRecommendInspection(false)
    setAttentionToDelivery(false)
    setNegativeOv(false)
    setProviderReschedule(false)
    setClientRescheduleRefuse(false)
    setSalesError(false)
  }

  useEffect(() => {
    if (currentFilters) {
      setIndValidateMeasurementSheet(currentFilters.indValidateMeasurementSheet)
      setAcceptanceServiceConditions(currentFilters.acceptanceServiceConditions)
      setIndWaitingTechReport(currentFilters.indWaitingTechReport)
      setIndWaitingLateTechReport(currentFilters.indWaitingLateTechReport)
      setIndRunningRework(currentFilters.indRunningRework)
      setIndWaitingMeasurementSheet(currentFilters.indWaitingMeasurementSheet)
      setIndAlertServices(currentFilters.indAlertServices)
      setSosOutOfFlow(currentFilters.sosOutOfFlow)
      setManualDistributed(currentFilters.manualDistributed)
      setServcOnTime(currentFilters.servcOnTime)
      setServiceOrderHasAttachments(currentFilters.serviceOrderHasAttachments)
      setSosPendingBudget(currentFilters.sosPendingBudget)
      setSosWaitingQuotation(currentFilters.sosWaitingQuotation)
      setConformity(currentFilters.conformity)
      setWaitingResponsible(currentFilters.waitingResponsible)
      setAssignedToMe(currentFilters.assignedToMe)
      setWaitingBudget(currentFilters.waitingBudget)
      setRecommendInspection(currentFilters.recommendInspection)
      setAttentionToDelivery(currentFilters.attentionToDelivery)
      setNegativeOv(currentFilters.negativeOv)
      setProviderReschedule(currentFilters.providerReschedule)
      setClientRescheduleRefuse(currentFilters.clientRescheduleRefuse)
      setSalesError(currentFilters.salesError)
    } else {
      clearState()
    }
  }, [currentFilters])

  const getDefaultState = () => ({
    indValidateMeasurementSheet: '',
    acceptanceServiceConditions: '',
    indWaitingTechReport: '',
    indWaitingLateTechReport: '',
    indRunningRework: '',
    indWaitingMeasurementSheet: '',
    indAlertServices: '',
    sosOutOfFlow: '',
    manualDistributed: '',
    servcOnTime: '',
    serviceOrderHasAttachments: '',
    sosPendingBudget: '',
    sosWaitingQuotation: '',
    conformity: '',
    waitingResponsible: '',
    assignedToMe: '',
    waitingBudget: '',
    recommendInspection: '',
    attentionToDelivery: '',
    negativeOv: '',
    providerReschedule: '',
    clientRescheduleRefuse: '',
    salesError: '',
  })

  const getCurrentState = () => ({
    indValidateMeasurementSheet: indValidateMeasurementSheet ? true : '',
    acceptanceServiceConditions: acceptanceServiceConditions ? true : '',
    indWaitingTechReport: indWaitingTechReport ? true : '',
    indWaitingLateTechReport: indWaitingLateTechReport ? true : '',
    indRunningRework: indRunningRework ? true : '',
    indWaitingMeasurementSheet: indWaitingMeasurementSheet ? true : '',
    indAlertServices: indAlertServices ? true : '',
    sosOutOfFlow: sosOutOfFlow ? true : '',
    manualDistributed: manualDistributed ? true : '',
    servcOnTime: servcOnTime ? true : '',
    serviceOrderHasAttachments: serviceOrderHasAttachments ? true : '',
    sosPendingBudget: sosPendingBudget ? true : '',
    sosWaitingQuotation: sosWaitingQuotation ? true : '',
    conformity: conformity ? true : '',
    waitingResponsible: waitingResponsible ? true : '',
    assignedToMe: assignedToMe ? true : '',
    waitingBudget: waitingBudget ? true : '',
    recommendInspection: recommendInspection ? true : '',
    attentionToDelivery: attentionToDelivery ? true : '',
    negativeOv: negativeOv ? true : '',
    providerReschedule: providerReschedule ? true : '',
    clientRescheduleRefuse: clientRescheduleRefuse ? true : '',
    salesError: salesError ? true : '',
  })

  return {
    indValidateMeasurementSheet,
    acceptanceServiceConditions,
    indWaitingTechReport,
    indWaitingLateTechReport,
    indRunningRework,
    indWaitingMeasurementSheet,
    indAlertServices,
    sosOutOfFlow,
    manualDistributed,
    servcOnTime,
    serviceOrderHasAttachments,
    sosPendingBudget,
    sosWaitingQuotation,
    conformity,
    waitingResponsible,
    assignedToMe,
    waitingBudget,
    recommendInspection,
    attentionToDelivery,
    negativeOv,
    providerReschedule,
    clientRescheduleRefuse,
    salesError,
    setIndValidateMeasurementSheet,
    setAcceptanceServiceConditions,
    setIndWaitingTechReport,
    setIndWaitingLateTechReport,
    setIndRunningRework,
    setIndWaitingMeasurementSheet,
    setIndAlertServices,
    setSosOutOfFlow,
    setManualDistributed,
    setServcOnTime,
    setServiceOrderHasAttachments,
    setSosPendingBudget,
    setSosWaitingQuotation,
    setConformity,
    setWaitingResponsible,
    setAssignedToMe,
    setWaitingBudget,
    setRecommendInspection,
    setAttentionToDelivery,
    setNegativeOv,
    setProviderReschedule,
    setClientRescheduleRefuse,
    setSalesError,
    getCurrentState,
    getDefaultState,
    clearState,
  } as AlertsStateType
}

export default AlertsState
