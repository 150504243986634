import i18next from 'i18next'

import FormField from '../../../../../../../../../../../components/FormField'

import { useServiceOrderDetailContext } from '../../../../../../../../../context'
import { unitValueText } from '../../../../../../../../../../../helpers/format'

export type AuxResaleProd = {
  servcOrdSeq: number
  auxlryResaleProdId: number
  auxlryResaleProdCd: string
  auxlryResaleProdNm: string
  auxlryResaleProdQty: number
  creatTs: string
  lastUpdtTs: string
  auxilryRelaseProdUomCd: string | null
  auxlryProdNetUnitAmt: string | null
  servcOrdItemQtyReturned : number
  servcOrdItemQtyAcquired : number
}

interface ComplementaryProductProps {
  service: AuxResaleProd
  isCanceled: boolean
  isDesktop: boolean
  servcOrdItemQtyReturned : number
  servcOrdItemQtyAcquired : number
}

function ComplementaryProduct(props: ComplementaryProductProps) {
  const { service, isCanceled, isDesktop } = props
  const { loadingBody, loadingDetails } = useServiceOrderDetailContext()

  const isFetching = loadingBody && loadingDetails

  const productQuantityLabel = isDesktop ? '' : i18next.t('service.order.detatails.quantity')

  const wasRefunded =
      service.servcOrdItemQtyAcquired > 0 &&
      service.servcOrdItemQtyAcquired === service.servcOrdItemQtyReturned

  return (
    <div id='associated-product-item-container'>
      <div className='first-column custom'>
        <div className='first-area mobile'>
          <FormField
            isFetching={isFetching}
            label={i18next.t('complementary.product.text')}
            value={service?.auxlryResaleProdNm}
            isBold
            isDashed={isCanceled || wasRefunded}
          />

          <div className='custom-desk'>
            <FormField
              isFetching={isFetching}
              label={i18next.t('service.order.detatails.product.code')}
              value={service?.auxlryResaleProdCd}
              isDashed={isCanceled || wasRefunded}
            />
          </div>
        </div>
        <section className='second-area mobile'>
          <div className='information-item custom-mobile'>
            <div className='subdivision'>
              <FormField
                isFetching={isFetching}
                label={i18next.t('service.order.detatails.product.code')}
                value={service?.auxlryResaleProdCd}
                isDashed={isCanceled || wasRefunded}
              />
            </div>
          </div>
          <div className='information-item'>
            <FormField
              isFetching={isFetching}
              label={productQuantityLabel}
              value={unitValueText(service?.auxlryResaleProdQty)}
              isDashed={isCanceled || wasRefunded}
            />
          </div>
        </section>
      </div>
      <div className='second-column' />
    </div>
  )
}

export default ComplementaryProduct
