import i18next from 'i18next'
import EditButton from './components/EditButton'
import ShowDetails from './components/ShowDetails'
import { HeaderType } from '../../models'

function Header(props: HeaderType) {
  const { header, action, isOpen, canCollapse = true, handleToggle } = props

  const hideDetailsLabel = header?.hideDetails || 'accordeon.collapse.hidedetails'
  const showDetailsLabel = header?.showDetails || 'accordeon.collapse.showdetails'
  const ActionComponent = action?.component || EditButton

  const hasIcon = !!header?.icon
  const hasClick = !!action?.handleClick

  return (
    <div className='header'>
      <section className='info'>
        {hasIcon && header?.icon}

        <span className='title' onClick={handleToggle}>
          {i18next.t(header?.title)}
        </span>
      </section>

      <div className='right'>
        {canCollapse && (
          <span className='actions show-details'>
            <ShowDetails
              isOpen={isOpen}
              hideDetails={hideDetailsLabel}
              showDetails={showDetailsLabel}
              handleClick={handleToggle}
            />
          </span>
        )}

        {hasClick && (
          <span className='actions edit' onClick={action.handleClick}>
            <ActionComponent {...action.props} />
          </span>
        )}
      </div>
    </div>
  )
}

export default Header
