import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

import moment from 'moment-timezone'
import User from '../../../core/User'
import FirestoreChat from '../repository/FirestoreChat'

import { ChatType, MessageType } from '../../../components/ChatV2/models'
import { USER_TYPE } from '../../../constants/config'
import { runMigration } from './migrations'

export const version = 1

export default class CustomerChatV2 extends FirestoreChat {
  private user: User

  private chat: ChatType | null = null

  private chatID: string

  constructor(chatID: string, collection: string) {
    super(collection)

    this.chatID = chatID
    this.user = new User()
  }

  groupMessagesByDate(chat: MessageType[]) {
    return chat.reduce((res: any, message: any) => {
      const pKey = moment(message.createdAt).format('L')

      if (!res[pKey]) res[pKey] = []

      res[pKey].push(message)

      return res
    }, {})
  }

  async onSnapshot(callback: (chat: any) => void) {
    return this.getCollectionRef()
      .doc(this.chatID)
      .onSnapshot((doc: any) => {
        this.chat = doc.data()

        callback(this.chat)
      })
  }

  async markAsRead() {
    const chatRef = await this.getCollectionRef().doc(this.chatID)
    const chat = (await chatRef.get()).data()

    chat?.messageHistory?.forEach((message: any) => {
      // eslint-disable-next-line no-param-reassign
      if (message.userType === USER_TYPE.CUSTOMER) message.isRead = true

      return message
    })

    if (chat?.lastMessage?.userType === USER_TYPE.CUSTOMER) {
      chat.lastMessage.isRead = true
    }

    return chatRef.update(chat)
  }

  async resolveChat(solved: boolean) {
    const chatRef = await this.getCollectionRef().doc(this.chatID)
    const newConversation = { ...this.chat, solved }

    return chatRef.update(newConversation)
  }

  async updateChat(message: string, solve: boolean) {
    const chatRef = await this.getCollectionRef().doc(this.chatID)

    const newMessage = {
      createdAt: +moment(),
      displayName: this.user.currentUser?.fullName,
      indSystem: 0,
      isRead: false,
      message,
      type: 'text',
      uid: this.chat?.servcOrdCd || this.chat?.lastMessage?.uid,
      userType: USER_TYPE.BACKOFFICE,
    }

    const newChat = {
      ...this.chat,
      messageHistory: firebase.firestore.FieldValue.arrayUnion(newMessage),
      lastMessage: newMessage,
      solved: solve,
    }

    return chatRef.update(newChat)
  }

  async checkChat() {
    try {
      const snapshot = await this.getCollectionRef().doc('version').get()

      if (!snapshot.exists) this.getCollectionRef().doc('version').set({ version: 1 })

      const document = snapshot.data()

      if (version > document.version) await runMigration(version, this.getCollectionRef())

      return false
    } catch (err) {
      setTimeout(() => this.checkChat(), 2000)
    }
  }

  countUnreadMessages() {
    if (!this.chat) return 0

    return this.chat.messageHistory.reduce((count: number, message: MessageType) => {
      const isNotRead = message.isRead === false
      const isCustomerMessage = message.userType === USER_TYPE.CUSTOMER

      // eslint-disable-next-line no-param-reassign
      if (isNotRead && isCustomerMessage) count += 1

      return count
    }, 0)
  }
}
