import { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { connect } from 'react-redux'

import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import { colourStyles } from '../../../helpers/selectStyle'

class BankSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      banks: [],
      error: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    if (user) {
      return new Api(user.dsToken, dispatch)
        .get(API_URL.BANK_FIND)
        .then((response) => this.onFetchSuccess(response.data))
        .catch((error) => this.onFetchError(error))
    }
  }

  onFetchSuccess(banks) {
    this.setState({ banks, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, banks: [] })
  }

  getOptions(banks) {
    const banksList = []
    for (let i = 0, len = banks.length; i < len; i++) {
      const bank = banks[i]
      banksList.push({
        label: bank.name,
        value: bank.bankId,
      })
    }
    return banksList
  }

  render() {
    const { id, placeholder, name, value, label, onChange, t, disabled = false } = this.props
    const { banks } = this.state

    return (
      <div>
        {typeof (value !== 'undefined' && value !== null) && (
          <label htmlFor={id} className='label up-label label-alignment'>
            {placeholder}
          </label>
        )}
        <Select
          id={id}
          name={name}
          value={this.getOptions(banks).filter((option) => option.value === value)}
          onChange={(event) => onChange(event)}
          isDisabled={disabled}
          options={this.getOptions(banks)}
          label={label}
          placeholder={placeholder}
          isClearable
          resetValue=''
          clearValueText='Remover item'
          noOptionsMessage={() => t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          styles={colourStyles}
        />
      </div>
    )
  }
}

BankSelect.propTypes = {
  user: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
})

/*
 * Conecta componente ao I18next
 */
const BankSelectTranslated = withTranslation()(BankSelect)

/*
 * Export componente conectado ao redux
 */
export default connect(mapStateToProps)(BankSelectTranslated)

/*
 * Export somente do componente
 */
export { BankSelect }
