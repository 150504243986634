import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { MESSAGE_DEVICE, MESSAGE_TYPE } from '../../../constants/config'
import {
  CLEAR_MESSAGES,
  RETRIEVE_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_SUCCESS,
} from '../../actionTypes/messages'

export const retrieveMessage = ({
  createdAt,
  uid,
  displayName,
  message,
  isRead,
  device,
  type,
  key,
  userType,
  indSystem,
}) => ({
  type: RETRIEVE_MESSAGE,
  createdAt,
  uid,
  displayName,
  message,
  isRead,
  device,
  messageType: type,
  key,
  userType,
  indSystem,
})

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
})

export const sendMessageInProgress = (payload) => ({
  type: SEND_MESSAGE,
  ...payload,
})

export const sendMessageSuccess = () => ({
  type: SEND_MESSAGE_SUCCESS,
})

export const sendMessageError = () => ({
  type: SEND_MESSAGE_ERROR,
})

export const sendMessage = (message, receiverUid) => (dispatch, getState) => {
  const { uid } = firebase.auth().currentUser

  const displayName = getState().auth.user?.fullName
  const isRead = false
  const type = MESSAGE_TYPE.TEXT

  const { inFuncao17 } = getState().auth.user.funcaoAcesso
  let device = MESSAGE_DEVICE.WEB_BRANCH
  if (inFuncao17 && inFuncao17 === 1) {
    device = MESSAGE_DEVICE.WEB
  }

  if (uid !== 0) {
    dispatch(sendMessageInProgress({ uid, displayName, message, isRead, device }))

    firebase.database().ref(`messages/${receiverUid}`).push({
      uid,
      displayName,
      message,
      isRead,
      device,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      type,
    })
  } else {
    dispatch(sendMessageError())
  }
}
