import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  useModals,
  Loader,
  TextArea,
  Field,
  View,
  useNotifications,
} from '@mozaic-ds/react'
import i18n from 'i18next'
import InstallersListContext from '../context'
import { Installer } from '../../../services/installers/fetchInstallersList'
import { inactiveInstaller } from '../../../services/installers/inactiveInstaller'
import { ADEOResponseError } from '../../../models/AdeoResponse'

interface InactiveJustificationModalProps {
  installer?: Installer
}

/**
 * installers list active/inactive justification modal
 */
function InactiveJustificationModal({ installer }: InactiveJustificationModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [justification, setJustification] = useState('')
  const { open, close } = useModals()
  const { installers, setInstallers } = useContext(InstallersListContext)
  const notification = useNotifications()

  const modalId = 'installers-list-inactive-modal'

  const translationIndex = installer?.indEspecialistaServicoAtivo === '1' ? '0' : '1'

  useEffect(() => {
    if (installer) open(modalId)
    setJustification('')
    setIsLoading(false)
  }, [installer])

  const updateInstallerState = () => {
    const newInstallers = [...installers]

    newInstallers.forEach((item, index) => {
      if (item.servcPrvdrAgntId === installer?.servcPrvdrAgntId) {
        newInstallers[index] = {
          ...item,
          indEspecialistaServicoAtivo: translationIndex,
        }
      }
    })

    setInstallers(newInstallers)
  }

  const onConfirm = () => {
    if (!installer) return
    setIsLoading(true)

    inactiveInstaller({
      actvServcPrvdrAgntInd: translationIndex,
      description: justification,
      servcPrvdrTmlineEvntDesc: justification,
      servcPrvdrAgntId: installer.servcPrvdrAgntId,
    })
      .then(() => {
        updateInstallerState()
        notification.success(
          {
            title: i18n.t('pwa.toast.success.message'),
            message: i18n.t('successfullyPerformedOperation'),
            duration: 8000,
          },
          'top',
        )
        close(modalId)
      })
      .catch((error: ADEOResponseError) => {
        console.error('error on inactive/active installer', error)
        notification.danger(
          {
            title: i18n.t('pwa.toast.error.message'),
            message: error?.message?.dsMessage || '',
            duration: 8000,
          },
          'top',
        )
      })
      .finally(() => setIsLoading(false))
  }

  const onChangeJustification = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    const removedDoubleSpaces = value.replace(/\s\s+/g, ' ')
    setJustification(removedDoubleSpaces.trimStart())
  }

  const isConfirmButtonDisabled = () => {
    if (isLoading) return true

    const withoutSpaceJustification = justification.replace(/\s+/g, '')
    const isJustificationOnlyNumbers = withoutSpaceJustification.match(/^[0-9]+$/) !== null
    const isJustificationOnlySpaces = withoutSpaceJustification.match(/^\s+$/) !== null
    const isJustificationOnlySpecialCharacters =
      withoutSpaceJustification.match(/^[^a-zA-Z0-9]+$/) !== null
    const isJustificationLessThan10Characters = withoutSpaceJustification.length < 10
    const isLastCharacterASpace = justification.slice(-1) === ' '

    const isJustificationInvalid =
      isJustificationOnlyNumbers ||
      isJustificationOnlySpaces ||
      isJustificationOnlySpecialCharacters ||
      isJustificationLessThan10Characters ||
      (isJustificationLessThan10Characters && isLastCharacterASpace)

    return isJustificationInvalid
  }

  return (
    <Modal id={modalId} canCloseOnOverlayClick={false} focusedElementIndex={-1}>
      <ModalHeader>
        <ModalTitle>
          {i18n.t(`justificationModal.actvServcPrvdrAgntInd.title.${translationIndex}`)}
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <View marginBottom='mu100'>
          <Text textAlign='center'>
            {i18n.t(`justificationModal.actvServcPrvdrAgntInd.text.${translationIndex}`)}
          </Text>
        </View>
        <Field
          label={i18n.t('justificationModal.desc.placeholder')}
          help={i18n.t('field.text.description')}
        >
          <TextArea
            placeholder={i18n.t('justificationModal.desc.placeholder')}
            value={justification}
            onChange={onChangeJustification}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button
          size='m'
          variant='bordered'
          theme='primary'
          width='full'
          onClick={() => {
            close(modalId)
          }}
        >
          {i18n.t(`justificationModal.actvServcPrvdrAgntInd.cancel.${translationIndex}`)}
        </Button>
        <Button
          size='m'
          width='full'
          onClick={onConfirm}
          isDisabled={isConfirmButtonDisabled()}
          className='confirm-btn'
        >
          {isLoading ? (
            <Loader size='m' theme='light' />
          ) : (
            i18n.t(`justificationModal.actvServcPrvdrAgntInd.confirm.${translationIndex}`)
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default InactiveJustificationModal
