import i18next from 'i18next'
import { useDispatch } from 'react-redux'
import { Button, useNotifications } from '@mozaic-ds/react'

import User from '../../../../../../core/User'
import {
  PROVIDER_REGISTRATION_STATUS,
  STATUS_OPTIONS,
} from '../../../../../../constants/pages/ServiceProviderList'
import ProviderEntity from '../../../../../../models/Provider'
import { useServiceProvidersListV2Context } from '../../../../contexts'
import { hideSpinner, showSpinner } from '../../../../../../redux/actions/spinner'
import { sendContractServiceProvider } from '../../../../../../services/providers/sendContractServiceProvider'

import { NotificationInformation24 } from '@mozaic-ds/icons/react'

import './index.scss'

const user = new User()

const FUNCTION_ACCESS_TO_SEND_CONTRACT = 19
const FUNCTION_ACCESS_TO_STORE_VALIDATION = 132

/**
 * @description Renders the registration status row of the Service Providers List V2 datatable
 */

interface RegistrationStatusRowProps {
  serviceProvider: ProviderEntity
  onClickStoreValidation: (serviceProvider: ProviderEntity) => void
  onClickPendingDocumentsIcon: (serviceProviderId: ProviderEntity['servcPrvdrId']) => void
}

export function RegistrationStatusRow({
  serviceProvider,
  onClickStoreValidation,
  onClickPendingDocumentsIcon,
}: RegistrationStatusRowProps) {
  const { handleFetchServiceProviders, filters } = useServiceProvidersListV2Context()

  const dispatch = useDispatch()
  const notification = useNotifications()

  const isStoreValidationStatus =
    serviceProvider.servcPrvdrAddRgstrnCurrntStepNr ===
    PROVIDER_REGISTRATION_STATUS.STORE_VALIDATION.id
  const isContractInProduction =
    serviceProvider.servcPrvdrAddRgstrnCurrntStepNr ===
    PROVIDER_REGISTRATION_STATUS.CONTRACT_IN_PRODUCTION.id
  const isPendingDocuments =
    serviceProvider.servcPrvdrAddRgstrnCurrntStepNr ===
    PROVIDER_REGISTRATION_STATUS.PENDING_DOCUMENTS.id
  const hasPermissionToStoreValidation = user?.currentUser.accessFunctionList?.includes(
    FUNCTION_ACCESS_TO_STORE_VALIDATION,
  )
  const hasPermissionToSendContract = user?.currentUser.accessFunctionList?.includes(
    FUNCTION_ACCESS_TO_SEND_CONTRACT,
  )
  const statusIndex = serviceProvider.servcPrvdrAddRgstrnCurrntStepNr as keyof typeof STATUS_OPTIONS
  const statusValue = STATUS_OPTIONS[statusIndex]

  const handleSendContractServiceProvider = async (serviceProvider: ProviderEntity) => {
    try {
      dispatch(showSpinner())
      const params = {
        providerId: serviceProvider.servcPrvdrId,
        contractSent: true,
      }
      await sendContractServiceProvider(params)
      notification.success(
        {
          title: i18next.t('pwa.toast.success.message'),
          message: i18next.t('successfullyPerformedOperation'),
          duration: 8000,
        },
        'top',
      )
      handleFetchServiceProviders({ fields: filters })
    } catch (error) {
      console.error('Error on sent contract', error)
      notification.danger(
        {
          title: i18next.t('pwa.toast.error.message'),
          message: i18next.t('dao.save.error'),
          duration: 8000,
        },
        'top',
      )
    } finally {
      dispatch(hideSpinner())
    }
  }

  return (
    <div id='providers-registration-status-row'>
      {isPendingDocuments && (
        <div className='status-pending'>
          <p>{i18next.t(statusValue)}</p>
          <NotificationInformation24
            role='button'
            onClick={() => onClickPendingDocumentsIcon(serviceProvider.servcPrvdrId)}
          />
        </div>
      )}
      {isStoreValidationStatus && (
        <>
          {hasPermissionToStoreValidation ? (
            <Button
              data-testid='store-validation-button'
              onClick={() => onClickStoreValidation(serviceProvider)}
              size='s'
            >
              {i18next.t('store.validation.text')}
            </Button>
          ) : (
            <>{i18next.t(statusValue)}</>
          )}
        </>
      )}
      {isContractInProduction && (
        <>
          {hasPermissionToSendContract ? (
            <Button
              data-testid='send-contract-button'
              onClick={() => handleSendContractServiceProvider(serviceProvider)}
              size='s'
            >
              {i18next.t('contract.in.production.button.label')}
            </Button>
          ) : (
            <>{i18next.t(statusValue)}</>
          )}
        </>
      )}
      {!isPendingDocuments &&
        !isStoreValidationStatus &&
        !isContractInProduction &&
        i18next.t(statusValue)}
    </div>
  )
}
