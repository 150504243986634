import moment from 'moment-timezone'
import IndicatorType from './indicators'
import ServiceOrderStatusType from '../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServcOrdCategoryType from '../enums/ServiceOrder/category/serviceCategoryType'

export const statusBarIndicators = (i18next, setupParameters) => [
  {
    type: IndicatorType.PRE_SCHEDULED,
    value: 0,
    title: 'kpi.schedule.title',
    subtitle: 'kpi.schedule.subtitle',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
          label: i18next.t('serviceOrders.preScheduled'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
          label: i18next.t('serviceOrders.preScheduled'),
        },
      ].map((status) => status.value),
    },
  },
  {
    type: IndicatorType.SCHEDULED,
    value: 0,
    title: 'kpi.distribute.title',
    subtitle: 'kpi.distribute.subtitle',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_AGENDADA.id,
          label: i18next.t('serviceOrders.scheduled'),
        },
      ].map((status) => status.value),
    },
  },
  {
    type: IndicatorType.ASSOCIATED,
    value: 0,
    title: 'kpi.confirm.title',
    subtitle: 'kpi.confirm.subtitle',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_ASSOCIADA.id,
          label: i18next.t('serviceOrders.associated'),
        },
      ].map((status) => status.value),
      servcOrdStatusTypeHouse: true,
      servcOrdSchdlgTsEnd: moment()
        .add(setupParameters.cockpitServicesDayBeforeDays, 'days')
        .format(),
    },
  },

  {
    type: IndicatorType.DOING,
    value: 0,
    title: 'kpi.accompany.title',
    subtitle: 'kpi.accompany.subtitle',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
          label: i18next.t('serviceOrders.doing'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
          label: i18next.t('serviceOrders.doing'),
        },
      ].map((status) => status.value),
      servcOrdStatusTypeHouse: true,
      servcOrdStatusTypeStore: true,
    },
  },
  {
    type: IndicatorType.REALIZED,
    value: 0,
    title: 'kpi.evaluate.title',
    subtitle: 'kpi.evaluate.subtitle',
    render: true,
    filters: {
      lsStatus: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
      ],
      lsServcOrdStusCd: [
        {
          value: ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
          label: i18next.t('serviceOrders.done'),
        },
      ].map((status) => status.value),
      lsServcOrdCategory: [
        {
          value: ServcOrdCategoryType.INSTALATION.id,
          label: i18next.t(ServcOrdCategoryType.INSTALATION.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.value),
        },
        {
          value: ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.id,
          label: i18next.t(ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.value),
        },
        {
          value: ServcOrdCategoryType.EMERGENCY_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.EMERGENCY_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.RECURRING_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.RECURRING_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.HOURLY_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.HOURLY_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.id,
          label: i18next.t(ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.value),
        },
        {
          value: ServcOrdCategoryType.BUDGET.id,
          label: i18next.t(ServcOrdCategoryType.BUDGET.value),
        },
        {
          value: ServcOrdCategoryType.WARRANTY.id,
          label: i18next.t(ServcOrdCategoryType.WARRANTY.value),
        },
        {
          value: ServcOrdCategoryType.DISPLACEMENT.id,
          label: i18next.t(ServcOrdCategoryType.DISPLACEMENT.value),
        },
        {
          value: ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.id,
          label: i18next.t(ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.value),
        },
      ],
      lsServcOrdCategoryCd: [
        {
          value: ServcOrdCategoryType.INSTALATION.id,
          label: i18next.t(ServcOrdCategoryType.INSTALATION.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT.value),
        },
        {
          value: ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id,
          label: i18next.t(ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.value),
        },
        {
          value: ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.id,
          label: i18next.t(ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.value),
        },
        {
          value: ServcOrdCategoryType.EMERGENCY_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.EMERGENCY_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.RECURRING_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.RECURRING_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.HOURLY_SERVICE.id,
          label: i18next.t(ServcOrdCategoryType.HOURLY_SERVICE.value),
        },
        {
          value: ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.id,
          label: i18next.t(ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.value),
        },
        {
          value: ServcOrdCategoryType.BUDGET.id,
          label: i18next.t(ServcOrdCategoryType.BUDGET.value),
        },
        {
          value: ServcOrdCategoryType.WARRANTY.id,
          label: i18next.t(ServcOrdCategoryType.WARRANTY.value),
        },
        {
          value: ServcOrdCategoryType.DISPLACEMENT.id,
          label: i18next.t(ServcOrdCategoryType.DISPLACEMENT.value),
        },
        {
          value: ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.id,
          label: i18next.t(ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.value),
        },
      ].map((category) => category.value),
      servcOrdType: 'so.typeClient',
      closedOrDoneServices: true,
      servcOrdStatusTypeHouse: true,
    },
  },
]
