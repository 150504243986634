import CustomerChatV2 from '../../../../../../services/firestore/customerChatV2/CustomerChatV2'
import MozaicIcon from '../../../../../../components/MozaicIcon'
import CounterContainer from '../../../../../../components/Counter'

import { Text } from '@mozaic-ds/react'

export const defaultProps = {
  group: 'store-chat',
  className: 'store-chat',
}

export type RefType = {
  chatRef: CustomerChatV2
  handleScroll: Function
}

export function Title({
  before,
  after,
  solved,
}: {
  before: string
  after: string
  solved?: boolean
}) {
  return (
    <Text className='store-chat-header'>
      {before} <MozaicIcon icon='CommentCommunity32' size={24} /> {after}
      {!solved && <CounterContainer text='!' theme='danger' />}
    </Text>
  )
}
