import { CSSProperties, MouseEventHandler } from 'react'
import * as Icons from '@mozaic-ds/icons/react'

export const colors = {
  primary: '#72bf44',
  green: '#158110',
  danger: '#df382b',
  black: '#554f52',
  white: '#ffffff',
  ghost: '#BAB6BC',
  'green-backyard': '#188803',
}

type MozaicIconType = {
  icon: keyof typeof Icons
  className?: string
  onClick?: MouseEventHandler
  cursor?: 'pointer' | 'default'
  color?: keyof typeof colors
  title?: string
  size?: number
  ml?: number
  mr?: number
  mt?: number
  mb?: number
  id?: string
}

function MozaicIcon(props: MozaicIconType) {
  const {
    id = undefined,
    icon,
    className,
    ml,
    mr,
    mt,
    mb,
    color,
    size,
    title,
    cursor,
    onClick = undefined,
  } = props

  const Icon = Icons[icon]
  const iconSize = size ? `${size}px` : '21px'

  const iconProps = {
    id,
    onClick: onClick || undefined,
    className: 'mozaic-icon',
  }

  const svgStyle: CSSProperties = {
    fill: color ? colors[color] : undefined,
    width: `${iconSize}`,
    height: `${iconSize}`,
    cursor: cursor || undefined,
  }

  const iconStyle: CSSProperties = {
    marginTop: mt ? `${mt}px` : undefined,
    marginLeft: ml ? `${ml}px` : undefined,
    marginRight: mr ? `${mr}px` : undefined,
    marginBottom: mb ? `${mb}px` : undefined,
  }

  if (!Icon) return <img style={iconStyle} title={title} {...iconProps} />

  return (
    <i style={iconStyle} title={title} {...iconProps}>
      <Icon className={className} alt={icon} {...svgStyle} />
    </i>
  )
}

export default MozaicIcon
