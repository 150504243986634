import i18next from 'i18next'
import { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'

import MozaicIcon from '../../../../../../../../../../components/MozaicIcon'
import { AUTOCOMPLETE_THEME } from '../../../../../../../../../../constants/config'
import User from '../../../../../../../../../../core/User'
import { fetchServiceSpecialistNameAutocomplete } from '../../../../../../../../../../services/Installer'

const user = new User().currentUser
let _fetchInstallersByText: any

type optionType = {
  value: any
  label: string
}

type InstallerSelectType = {
  onChange: Function
  value: optionType | null
}

function InstallerSelect(props: InstallerSelectType) {
  const { onChange, value } = props
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState<Array<any>>([])

  const serviceSpecialistNameFetchRequested = async () => {
    const installers = await fetchServiceSpecialistNameAutocomplete(user.dsToken, search)

    const parsedInstallers = installers.map((installer: any) => ({
      label: installer.servcPrvdrAgntNm,
      value: installer.servcPrvdrAgntId,
    }))

    setOptions(parsedInstallers)
  }

  const handleRequest = () => {
    clearTimeout(_fetchInstallersByText)
    _fetchInstallersByText = setTimeout(() => serviceSpecialistNameFetchRequested(), 500)
  }

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value)

    if (!e.target.value) onChange(null)
    if (options.length) setOptions([])
  }

  const handleChange = (e: any, { suggestionValue }: any) => {
    const installer = options.find((installer) => installer.label === suggestionValue)

    onChange(installer)
    setSearch(installer?.label.trim())
  }

  const handleClear = () => {
    setSearch('')
    setOptions([])
    onChange(null)
  }

  const handleClick = () => {
    if (!options.length) {
      serviceSpecialistNameFetchRequested()
    }
  }

  useEffect(() => {
    if (value) {
      setSearch(value?.label.trim())
    } else {
      handleClear()
    }
  }, [value])

  const label = i18next.t('customer.service.order.details.infos.specialist')
  const placeholder = `${i18next.t('search')} ${i18next.t('all.text').toLowerCase()}`
  const footer = i18next.t('rating.error')

  const inputProps = {
    placeholder,
    value: search,
    id: 'serviceInstallerSelect',
    name: 'installer-select',
    onChange: handleSearchChange,
    onKeyUp: handleRequest,
    onClick: handleClick,
  }

  return (
    <div className='installer-select'>
      <label className='react-select--label' htmlFor={inputProps.name}>
        {label}
      </label>

      <Autosuggest
        suggestions={options}
        onSuggestionsFetchRequested={() => false}
        onSuggestionsClearRequested={() => false}
        getSuggestionValue={(suggestion: any) => suggestion.label}
        renderSuggestion={(suggestion: any) => suggestion.label}
        highlightFirstSuggestion
        onSuggestionSelected={handleChange}
        inputProps={inputProps}
        theme={AUTOCOMPLETE_THEME}
        shouldRenderSuggestions={() => true}
      />

      {search && (
        <div className='close-icon'>
          <MozaicIcon icon='ControlCross32' onClick={handleClear} />
        </div>
      )}

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default InstallerSelect
