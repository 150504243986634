import { memo, useMemo } from 'react'
import i18next from 'i18next'

import MozaicButton from '../../../../../../components/MozaicButton'
import MozaicCard from '../../../../../../components/MozaicCard'
import MozaicIcon from '../../../../../../components/MozaicIcon'
import FormField from '../../../../../../components/FormField'
import { Flag } from '@mozaic-ds/react'
import Tags from './components/Tags'

import { ProjectResume, risks } from '../../../../../../models/Project'
import { formatDecimalNoCurrency, formatProjectId } from '../../../../../../helpers/masks'
import moment from 'moment-timezone'
import ErrorBoundary from '../../../../../../errors/boundaries'
import { useProjectDetailsContext } from '../../../../context'

function Project({ project, loadingProject, loadingTags, tags, getProjectDetails }: ProjectResume) {
  const handleToggleMenu = () => {
    console.log('open-menu')
  }

  const handleReschedule = () => {
    console.log('reschedule')
  }

  const handleDistribute = () => {
    console.log('distribute')
  }

  const projectResume = {
    title: i18next.t('project.resume'),
    leftIcon: <MozaicIcon icon='BookStore24' mt={2} />,
    rightAction: <Flag theme='primary'>{i18next.t('bank_statement.status')}</Flag>,
  }

  const prjctExctnSeq = formatProjectId(project?.prjctExctnSeq)
  const servcOrdAmt = `${i18next.t('currencySymbol')} ${formatDecimalNoCurrency(
    project?.servcOrdAmt,
  )}`
  const risk = risks[project?.servcCategory as keyof typeof risks]
  const creatTs = moment(project?.creatTs).format('L')

  return (
    <div id='project-details'>
      <ErrorBoundary resetErrorBoundary={getProjectDetails}>
        <MozaicCard {...projectResume}>
          <div className='resume-fields'>
            <FormField
              label={i18next.t('entity.project')}
              value={prjctExctnSeq}
              isFetching={loadingProject}
            />

            <FormField label={i18next.t('count.services')} value='-' isFetching={loadingProject} />

            <FormField
              label={i18next.t('provider.portal.total.value')}
              value={servcOrdAmt}
              isFetching={loadingProject}
            />

            <FormField
              label={i18next.t('risk')}
              value={<Flag theme={risk?.color}>{i18next.t(risk?.text)}</Flag>}
              isFetching={loadingProject}
            />

            <FormField
              label={i18next.t('parameters.dscreatTs')}
              value={creatTs}
              isFetching={loadingProject}
            />

            <FormField label={i18next.t('finish.date')} value='-' isFetching={loadingProject} />
          </div>

          <div className='footer'>
            <Tags loadingTags={loadingTags} tags={tags} />

            <div className='footer-buttons'>
              <MozaicButton
                size='s'
                variant='bordered'
                className='project-display-menu'
                onClick={handleToggleMenu}
              >
                <MozaicIcon icon='DisplayOptions24' />
              </MozaicButton>

              <MozaicButton size='s' variant='bordered' onClick={handleReschedule}>
                {i18next.t('cockpit.reschedule')}
              </MozaicButton>

              <MozaicButton size='s' onClick={handleDistribute}>
                {i18next.t('kpi.distribute.title')}
              </MozaicButton>
            </div>
          </div>
        </MozaicCard>
      </ErrorBoundary>
    </div>
  )
}

const MemoComponent = memo(Project)

function ProjectWC(props: any) {
  const { project, loadingProject, loadingTags, tags, getProjectDetails } =
    useProjectDetailsContext()

  const newProps = useMemo(
    () => ({ project, loadingProject, tags, loadingTags, getProjectDetails }),
    [project, loadingProject, tags, loadingTags],
  )

  return <MemoComponent {...newProps} {...props} />
}

export default ProjectWC
