import { useState } from 'react'
import i18next from 'i18next'

import {
  generateOcurrence,
  ParamsType,
} from '../../../../services/pages/ServiceOrderDetail/GenerateOcurrence'

import ServcOrdEvntStusType from '../../../../enums/ServiceOrder/event/ServcOrdEvntStusType'

import Modal from '../../../../components/Modal'
import JustifyInput from './components/JustifyInput'
import FooterButtons from './components/FooterButtons'
import MozaicText from '../../../../components/MozaicText'

type SalesErrorModalType = {
  show: boolean
  onClose: Function
  serviceOrder: { servcOrdStusCd: string; servcOrdCd: number }
}

function SalesErrorModal(props: SalesErrorModalType) {
  const { show, onClose, serviceOrder } = props

  const [justify, setJustify] = useState<string>('')
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const handleChangeJustify = (e: any) => {
    setJustify(e.target.value)
  }

  const handleSubmit = async () => {
    setIsSubmiting(true)

    const payload: ParamsType = {
      servcOrdSeq: serviceOrder.servcOrdCd,
      idOrdemServicoItem: 0,
      descOcorOrdemServico: justify,
      servcOrdEvntStusInd: ServcOrdEvntStusType.SALES_ERROR.id,
      indStatusOrdemServicoItem: serviceOrder?.servcOrdStusCd || '',
      indStatusOrdemServico: serviceOrder?.servcOrdStusCd || '',
    }

    await generateOcurrence(payload)

    onClose(true)
    setJustify('')
    setIsSubmiting(false)
  }

  return (
    <div id='sales-error-modal-v1'>
      <Modal
        size='lg'
        isOpen={show}
        onClose={() => onClose()}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('access.function141')}
      >
        <MozaicText ml={1.5}>{i18next.t('attentionOccurrenceModal.text')}</MozaicText>

        <JustifyInput
          value={justify}
          onChange={handleChangeJustify}
          placeholder={i18next.t('attentionOccurrenceModal.text')}
        />

        <FooterButtons
          handleSubmit={handleSubmit}
          handleClose={() => onClose()}
          justify={justify}
          isSubmiting={isSubmiting}
          confirmText={i18next.t('attentionOccurrenceModal.save')}
        />
      </Modal>
    </div>
  )
}

export default SalesErrorModal
