import { createContext, ReactNode, useContext } from 'react'

import { connect } from 'react-redux'

import { usableArea } from '../../constants/breakpoints'
import useElementSize from '../../hooks/useElementSize'

export type UsableAreaStore = {
  widthContainer: number
  size: { width: number; height: number }
}

type UsableAreaProviderProps = {
  children: ReactNode
  isOpen: boolean
}

const UsableAreaContext = createContext<UsableAreaStore | null>(null)

function UsableAreaProvider({ children, className = '', isOpen }: any | UsableAreaProviderProps) {
  const [ref, widthContainer, size] = useElementSize(isOpen) as any

  const containerClass =
    size?.width <= usableArea.medium ? 'small' : size?.width <= usableArea.large ? 'medium' : ''

  const directionWrapper = size?.width >= usableArea.large ? 'horizontal' : 'vertical'

  return (
    <UsableAreaContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ widthContainer, size }}
    >
      <main
        id='wrapper-container'
        className={`${containerClass} ${directionWrapper} ${className}`}
        ref={ref}
      >
        {children}
      </main>
    </UsableAreaContext.Provider>
  )
}

const mapStateToProps = ({ menuReducer }: any) => ({
  isOpen: menuReducer.isOpen,
})

const UsableAreaProviderConnected = connect(mapStateToProps)(UsableAreaProvider)

const useUsableAreaContext = () => useContext(UsableAreaContext) as UsableAreaStore

export { useUsableAreaContext, UsableAreaProvider, UsableAreaProviderConnected }
