import Api from '../../../core/Http/FetchAdapter'

import API_URL from '../../../core/Envs/endpoints'
import {
  CREATE_SERVICE_ORDER_OCCURRENCE_ERROR,
  CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST,
  CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS,
} from '../../actionTypes/serviceOccurrence'
import { hideSpinner, showSpinner } from '../spinner'

/*
 * SAVE or UPDATE SERVICE_OCCURRENCE
 */

export const updateServiceOccurrenceRequest = () => ({
  type: CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST,
})

export const updateServiceOccurrenceSuccess = (data, onSuccess) => {
  onSuccess(data)

  return {
    type: CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS,
    payload: data,
  }
}

export const updateServiceOccurrenceError = (error) => ({
  type: CREATE_SERVICE_ORDER_OCCURRENCE_ERROR,
  payload: error,
})

export const updateServiceOccurrence = (token, values, onSuccess) => (dispatch) => {
  dispatch(updateServiceOccurrenceRequest())

  const URL = API_URL.SERVICE_ORDER_OCCURRENCES

  const fields = {
    ...values,
    creatUserNm: values.nomUserRegistroOcor,
    servcOrdOccurrenceId: values.idOrdemServicoOcor,
    creatUserCd: values.codUserRegistroOcor,
    servcOrdOccurrenceDesc: values.descOcorOrdemServico,

    servcOrdOccurrenceDt: values.datOcorOrdemServico,
  }

  return new Api(token, dispatch)
    .post(URL, fields)
    .then(dispatch(showSpinner()))
    .then((response) => {
      dispatch(updateServiceOccurrenceSuccess(response.data, onSuccess))
      dispatch(hideSpinner())
    })
    .catch((error) => {
      dispatch(updateServiceOccurrenceError(error))
      dispatch(hideSpinner())
    })
}
