/** INST-7036 - Clone from version https://github.com/adeo/lm-instala-front/commit/81ea5e2a33441e176091df6483b98d87fe652e00 */
import 'firebase/auth'
import firebase from 'firebase/compat/app'
import localforage from 'localforage'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { debounce } from 'throttle-debounce'
import Grid from '../../components/utils/Grid'
import InputSearch from '../../components/utils/InputSearch'
import Row from '../../components/utils/Row'
import TitleSection from '../../components/utils/TitleSection'
import {
  MESSAGE_DEVICE,
  MESSAGE_TYPE,
  SERVICE_ORDERS_DEFAULT_FILTERS,
} from '../../constants/config'
import { findInsertionPoint, genericObjectComparator } from '../../helpers/Toolbox'
import * as chatActions from '../../redux/actions/chat'
import * as messageActions from '../../redux/actions/messages'
import { linkServiceOrdersFilters } from '../../redux/actions/serviceOrder'
import { getChatList } from '../../redux/reducers/chat'
import ChatHeader from '../Chat/components/ChatHeader'
import ChatTable from '../Chat/components/Table'
import { MessageInput } from '../Chat/components/message/MessageInput'
import MessageList from '../Chat/components/message/MessageList'

const LIMIT_MESSAGE_MAX = 100
class ChatRealtimeContainer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    chatList: PropTypes.object.isRequired,
    auth: PropTypes.object,
    retrieveMessage: PropTypes.func,
    sendMessage: PropTypes.func,
    clearMessages: PropTypes.func,
    messages: PropTypes.array,
    linkServiceOrdersFilters: PropTypes.func.isRequired,
    updateChatList: PropTypes.func.isRequired,
    isFetchingChatList: PropTypes.bool.isRequired,
    filterChatList: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
    fetchNotificationsSuccess: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static isMediaMessage(message) {
    return message.type === MESSAGE_TYPE.IMAGE || message.type === MESSAGE_TYPE.VIDEO
  }

  static getStorageKey(uidFirebase) {
    return btoa(`chat_${uidFirebase}`)
  }

  state = {
    chatFilter: '',
    selectedUid: '',
    uid: undefined,
    chatRecord: null,
    searchingOldMessages: false, // Check if you are looking for old messages
    loadingMessages: false, // Check if you are loading the first messages
    storageKey: '', // Chat current person key in browser storage
    storageMessages: [], // Messages that are saved in the browser
    selectedIndex: null,
  }

  timeOut = null

  chatRecord = null

  chunkQty = 10 // Number of messages to load at one time. It is changed later according to the user's screen.

  firebaseRef = null // Listener for new messages

  componentWillUnmount() {
    this.clearChat()
  }

  componentDidUpdate(prevProps) {
    const { isFetchingChatList, chatList } = this.props

    // Search for the latest messages when you finish picking up contacts
    if (prevProps.isFetchingChatList && !isFetchingChatList) {
      this.searchLastMessages(chatList)
    }
  }

  searchLastMessages = (chatList) => {
    const { updateChatList } = this.props

    // For each contact...
    Object.keys(chatList).forEach((uid) => {
      const storageKey = ChatRealtimeContainer.getStorageKey(uid)
      // Firebase reference already catching only the last record
      let firebaseRef = firebase.database().ref(`messages/${uid}`).limitToLast(1)

      // ...we look for your local messages
      localforage.getItem(storageKey).then((localMessages) => {
        // eslint-disable-next-line no-param-reassign
        localMessages = localMessages || []

        const localMessagesLen = localMessages.length
        let lastMessage = null

        // If you have local messages...
        if (localMessagesLen) {
          lastMessage = localMessages[localMessagesLen - 1]

          // ...we added a filter to fetch from the last one we have
          // O '+ 1' it is for him not to bring the last one we already have
          firebaseRef = firebaseRef.orderByChild('createdAt').startAt(lastMessage.createdAt + 1)
        }

        // We are looking for the last message we have in the firebase (already filtered, if it has fallen in the 'if' above)
        firebaseRef.once('value', (snapshot) => {
          const messages = snapshot.val()

          // If there is a newer message in firebase
          if (messages) {
            // Let her be the last message
            const key = Object.keys(messages)[0]
            lastMessage = messages[key]
          }

          // If there is any message to that contact, in cache or coming from firebase
          if (lastMessage) {
            // We've updated the contact list
            updateChatList(uid, { lastMessage })
          }
        })
      })
    })
  }

  searchOldMessages = () => {
    const { retrieveMessage } = this.props
    const { storageMessages, messages, uid } = this.state

    // We copied the local message list so we could make some changes later
    const localMessages = storageMessages.slice()
    // Variable to get the date of the last message that we have saved
    let lastMsgRetrievedDate = null
    // We search where the first chat message is in our mailing list
    const firstOnChatCreatedAt = messages[0].createdAt
    const firstOnChatIndex = localMessages.findIndex(
      (message) => message.createdAt === firstOnChatCreatedAt,
    )

    // If we have found the first message on our list ...
    if (firstOnChatIndex > -1) {
      // ...we remove from the list those that are already on the screen
      localMessages.splice(firstOnChatIndex)

      // ...and if there are still local messages left...
      if (localMessages.length) {
        // ...we get the date of the last message we have saved before the first screen
        lastMsgRetrievedDate = localMessages[localMessages.length - 1].createdAt
      }
    }

    // Creates the reference to retrieve messages...
    let firebaseRef = firebase
      .database()
      .ref(`messages/${uid}`)
      .limitToLast(this.chunkQty) // ...limiting what we need to the screen
      .orderByChild('createdAt') // ...ordering by shipment
      .endAt(messages[0].createdAt - 1) // ...and is older than the first one on the screen

    // If we find a local message previously...
    if (lastMsgRetrievedDate) {
      // ...set for the reference to bring only the one we already have
      firebaseRef = firebaseRef.startAt(lastMsgRetrievedDate + 1)
    }

    // Search the firebase for the messages between what we have and what is in the chat
    firebaseRef.once('value', (snapshot) => {
      const firebaseMessages = snapshot.val()
      this.firebaseMessagesParser(firebaseMessages)

      // Calculate how many local messages we need to complete the screen taking into account what firebase has brought
      let remainingMessages = firebaseMessages
        ? this.chunkQty - Object.keys(firebaseMessages).length
        : this.chunkQty

      // We display local messages until you complete the screen or exhaust the local messages
      for (
        let i = localMessages.length - 1;
        i > -1 && remainingMessages > 0;
        i--, remainingMessages--
      ) {
        retrieveMessage(this.storageMessageParser(localMessages[i]))
      }

      // If we still do not have as many messages as 'chunky' expects, and we had local messages to fetch before
      if (remainingMessages > 0 && localMessages.length) {
        // Creates the reference to retrieve messages...
        firebaseRef = firebase
          .database()
          .ref(`messages/${uid}`)
          .limitToLast(remainingMessages) // ...limiting what is missing
          .orderByChild('createdAt') // ...ordering by shipment
          .endAt(localMessages[0].createdAt - 1) // ...and which is older than our oldest location

        // Search firebase for missing messages and are older than what we have locally
        firebaseRef.once('value', (oldsSnapshot) => {
          // Displays messages that came from firebase
          this.firebaseMessagesParser(oldsSnapshot.val())

          // Tells you that we've finished searching for messages
          this.setState({ searchingOldMessages: false })
        })
      } else {
        // Tells you that we've finished searching for messages
        this.setState({ searchingOldMessages: false })
      }
    })
  }

  openServiceOrderListFiltered = (servcOrdCd) => {
    const { linkServiceOrdersFilters } = this.props

    linkServiceOrdersFilters({
      ...SERVICE_ORDERS_DEFAULT_FILTERS,
      currentSearchFieldValue: servcOrdCd,
      hasFilters: true,
    })
  }

  setChunkQty = (qty) => {
    this.chunkQty = qty
  }

  isLoading = () => {
    const { searchingOldMessages, loadingMessages } = this.state
    return searchingOldMessages || loadingMessages
  }

  humanizeDate = (date, useToday) => {
    if (!date) return ''

    const { t } = this.props

    const today = moment()
    const dateFromTime = moment(date)

    if (dateFromTime.isSame(today, 'day')) {
      return useToday ? t('chat.today') : dateFromTime.format('HH:mm')
    }
    if (dateFromTime.isSame(today.add(-1, 'day'), 'day')) {
      return t('chat.yesterday')
    }
    return dateFromTime.format('L')
  }

  handleFilterChange = (event) => {
    const { filterChatList } = this.props

    this.setState({ chatFilter: event.target.value })

    filterChatList(event.target.value)
  }

  handleSendMessages = (message) => {
    const { t, sendMessage, auth } = this.props
    const { uid } = this.state

    if (auth.user.locationCode === '999') {
      sendMessage(`${t('chat.locationName')} - ${message}`, uid)
    } else {
      sendMessage(`${auth.user.locationName} - ${message}`, uid)
    }

    if (this.chatRecord.groupHeader) {
      this.setState({ selectedIndex: 0 })
    }
  }

  onChatScroll = (container) => {
    const { searchingOldMessages, messages } = this.state

    // We check if the scroll is near the top and we are no longer searching for new messages
    if (container.scrollTop <= 50 && !searchingOldMessages) {
      // We ensured that the scroll did not reach the top just because the messages were reset
      if (messages.length) {
        // We notify the state that we are looking for new messages
        this.setState(
          {
            searchingOldMessages: true,
          },
          this.searchOldMessages,
        )
      }
    }
  }

  handleChangeSelected = (chatRecord, index) => {
    const { retrieveMessage, auth, fetchNotificationsSuccess } = this.props
    const { selectedIndex, selectedUid, storageKey, storageMessages } = this.state

    if (selectedIndex === index && selectedUid === chatRecord.uid) {
      this.setState({ selectedIndex: null })
    } else {
      this.setState({ selectedIndex: index })
    }

    if (selectedUid === chatRecord.uid) return

    this.clearChat().then(() => {
      this.chatRecord = chatRecord
      this.chatRecord.unreadQty = 0

      this.setState(
        {
          selectedUid: this.chatRecord.uid,
          uid: this.chatRecord.uid,
          chatRecord: this.chatRecord,
          loadingMessages: true,
          storageKey: ChatRealtimeContainer.getStorageKey(this.chatRecord.uid),
        },
        () => {
          // Search for local messages in browser storage
          localforage.getItem(storageKey).then((localMessages) => {
            this.state.storageMessages = localMessages || storageMessages
            let lastMsgRetrievedDate = null // To get the date of the last message we have saved

            // If you have local messages...
            if (storageMessages.length) {
              // ...find the last saved date
              lastMsgRetrievedDate = storageMessages[storageMessages.length - 1].createdAt
            }

            // Create the reference for the search of the messages, limiting by the quantity of the chunk
            let firebaseRef = firebase
              .database()
              .ref(`messages/${this.chatRecord.uid}`)
              .limitToLast(LIMIT_MESSAGE_MAX)

            // If we already have local messages, add a filter to get only the last one we have on
            if (lastMsgRetrievedDate) {
              // The '+ 1' is for him not to bring the last one we already have
              firebaseRef = firebaseRef.orderByChild('createdAt').startAt(lastMsgRetrievedDate + 1)
            }

            // Search for the latest firebase messages, based on the filters we passed
            firebaseRef.once('value', (snapshot) => {
              const firebaseMessages = snapshot.val()

              // Calculate how many local messages we need to complete the screen taking into account what firebase has brought
              const remainingMessages = firebaseMessages
                ? this.chunkQty - Object.keys(firebaseMessages).length
                : this.chunkQty

              // If we need to use local messages...
              if (remainingMessages > 0) {
                // ...we make an array only with the amount we need
                const lastLocalMessages = storageMessages.slice(remainingMessages * -1)

                // ...We display local messages in chat
                for (let i = 0, len = lastLocalMessages.length; i < len; i++) {
                  retrieveMessage(this.storageMessageParser(lastLocalMessages[i]))
                }
              }

              // Displays messages that came from firebase
              this.firebaseMessagesParser(firebaseMessages)

              // Creates the reference to listen for new messages in the firebase, ordering the message to be sent
              this.firebaseRef = firebase
                .database()
                .ref(`messages/${this.chatRecord.uid}`)
                .orderByChild('createdAt')

              // if, but by that time we should already have the messages in that storage array
              if (storageMessages.length) {
                // Set the reference to fetch messages from what we already have
                this.firebaseRef = this.firebaseRef.startAt(
                  storageMessages[storageMessages.length - 1].createdAt + 1,
                )
              }

              // Adds listener to wait for new messages
              this.firebaseRef.on('child_added', (addedSnapshot) => {
                const lastMessage = addedSnapshot.val()

                let device = MESSAGE_DEVICE.WEB_BRANCH
                if (
                  auth.user?.funcaoAcesso?.inFuncao17 &&
                  auth.user?.funcaoAcesso?.inFuncao17 === 1
                ) {
                  device = MESSAGE_DEVICE.WEB
                }

                // Remove notification already selected
                if (lastMessage.device !== device) {
                  fetchNotificationsSuccess(this.chatRecord.uid, -1)
                }

                // Add the message in the chat when it arrives
                this.handleNewMessage(lastMessage, addedSnapshot.key)
              })

              // Adds listener listening for changes in messages ...
              this.firebaseRef.on('child_changed', (changedSnapshot) => {
                // ...and update it where you need it
                this.handleChangedMessage(changedSnapshot.val(), changedSnapshot.key)
              })

              // Warns you that we've finished fetching the startup messages
              this.setState({ loadingMessages: false })
            })
          })
        },
      )
    })
  }

  handleNewMessage = (message, key) => {
    const { auth, retrieveMessage } = this.props
    const { uid, storageMessages } = this.state

    // Put the firebase key together in the object, so you can differentiate in the render
    // eslint-disable-next-line no-param-reassign
    message.key = key

    let device = MESSAGE_DEVICE.WEB_BRANCH
    if (auth.user?.funcaoAcesso?.inFuncao17 && auth.user?.funcaoAcesso?.inFuncao17 === 1) {
      device = MESSAGE_DEVICE.WEB
    }

    // Arrow as read if not
    if (message.device !== device && !message.isRead) {
      firebase.database().ref(`messages/${uid}/${message.key}`).update({ isRead: true })
    }

    // Post the message on chat
    retrieveMessage(message)

    // Search for the shipping date where this message fits into the list
    const insertionPoint = findInsertionPoint(
      storageMessages,
      message,
      genericObjectComparator('createdAt'),
    )
    storageMessages.splice(insertionPoint, 0, message)
    // Save message locally
    this.saveStorageMessages()
  }

  handleChangedMessage = (changedMessage, key) => {
    const { storageMessages } = this.state

    // Checks whether the message that was changed is saved locally
    const storageMessage = storageMessages.find((message) => message.key === key)

    // If we own her...
    if (storageMessage) {
      // ...we update to properties that we know can change
      storageMessage.createdAt = changedMessage.createdAt
      storageMessage.isRead = changedMessage.isRead
      // ...we saved the message
      this.saveStorageMessages()
    }
  }

  firebaseMessagesParser = (firebaseMessages) => {
    // Places messages in the chat that firebase returned
    for (const uid in firebaseMessages) {
      if (firebaseMessages.hasOwnProperty(uid)) {
        this.handleNewMessage(firebaseMessages[uid], uid)
      }
    }
  }

  storageMessageParser = (storageMessage) => {
    // We took the reference from the original object
    const message = { ...storageMessage }
    return message
  }

  saveStorageMessages = () => {
    const { storageKey, storageMessages } = this.state
    localforage.setItem(storageKey, storageMessages)
  }

  clearState = () =>
    new Promise((resolve) => {
      this.setState(
        {
          selectedUid: '',
          uid: undefined,
          chatRecord: null,
          storageKey: '',
          storageMessages: [],
        },
        resolve,
      )
    })

  removeExistingFirebaseListener = () => {
    if (this.firebaseRef) {
      this.firebaseRef.off('child_added')
    }
  }

  clearChat = () => {
    const { clearMessages } = this.props
    clearMessages()
    this.removeExistingFirebaseListener()
    return this.clearState()
  }

  render() {
    const { auth, chatList, filter, isFetchingChatList, messages, dispatch, t } = this.props
    const { chatFilter, selectedUid, selectedIndex, chatRecord, uid } = this.state

    return (
      <Grid fluid className='chat'>
        <TitleSection>
          <h1>{t('lmi.chat')}</h1>
        </TitleSection>

        <Row className='chat-container'>
          <div className='chat-start'>
            <div className='padding chat-contact-search-box'>
              <InputSearch
                id='chatFilter'
                name='chatFilter'
                className='chat-contact-search width-100'
                label={t('chat.searchContact')}
                value={chatFilter}
                onChange={this.handleFilterChange}
              />
            </div>

            <ChatTable
              chatList={chatList}
              t={t}
              selectedUid={selectedUid}
              changeSelected={this.handleChangeSelected}
              humanizeDate={this.humanizeDate}
              selectedIndex={selectedIndex}
              isFetchingChatList={isFetchingChatList}
              filterChat={filter}
              auth={auth}
            />
          </div>

          {chatRecord && (
            <div className='chat-end'>
              <ChatHeader
                chatRecord={chatRecord}
                loading={this.isLoading()}
                humanizeDate={this.humanizeDate}
                t={t}
                auth={auth}
              />

              <MessageList
                auth={auth}
                t={t}
                messages={messages}
                openServiceOrderListFiltered={this.openServiceOrderListFiltered}
                onChatScroll={debounce(250, false, this.onChatScroll)}
                setChunkQty={this.setChunkQty}
                receiverUid={uid}
                humanizeDate={this.humanizeDate}
                dispatch={dispatch}
              />

              <MessageInput t={t} sendMessages={this.handleSendMessages} />
            </div>
          )}
        </Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const { chatReducer, userMessage, messages } = state

  return {
    chatList: getChatList(state),
    userMessage,
    messages,
    isFetchingChatList: chatReducer.list.isFetching,
    filter: chatReducer.list.filter,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...chatActions, ...messageActions, linkServiceOrdersFilters }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ChatRealtimeContainer))

export { ChatRealtimeContainer }
