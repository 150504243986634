import { useState } from 'react'
import i18next from 'i18next'
import store from '../../../../redux/store'
import User from '../../../../core/User'

import { hideSpinner, showSpinner } from '../../../../redux/actions/spinner'
import { setBuCd } from '../../../../services/pages/BuCd/SetBuCd'
import { reloadUser } from '../../../../services/pages/BuCd/ReloadUser'
import { toast } from '@leroy-merlin-br/backyard-react'
import MozaicText from '../../../../components/MozaicText'

import FooterButtons from './components/FooterButtons'
import BuCdSelect from './components/BuCdSelect'
import Modal from '../../../../components/Modal'
import { useSideNavContext } from '../../../../contexts/sidenav'

function BuCdModal() {
  const { openedBuCdModal, handleCloseBuCdModal } = useSideNavContext() as any

  const user = new User().currentUser

  const [buCd, handleBuCd] = useState<number>(user?.buCd)

  const handleChangeBuCd = (e: any) => {
    handleBuCd(e.value)
  }

  const handleSubmit = async () => {
    store.dispatch(showSpinner())

    try {
      const payload = {
        buCd,
      }

      const data = await setBuCd(payload)
      await reloadUser(data.dsToken)

      handleCloseBuCdModal()
      toast.success('', i18next.t('serviceOrders.saved'), '')
    } catch (error: any) {
      toast.error('', error?.dsMessage || i18next.t('dao.save.error'), '')
    }

    store.dispatch(hideSpinner())
  }

  const labelText = i18next.t('user.changeBuCd.modal.text')

  return (
    <>
      <Modal
        size='lg'
        isOpen={openedBuCdModal}
        onClose={handleCloseBuCdModal}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('user.changeBuCd.modal.title')}
      >
        <MozaicText> {labelText} </MozaicText>

        <BuCdSelect value={buCd} onChange={handleChangeBuCd} />

        <FooterButtons handleSubmit={handleSubmit} handleClose={handleCloseBuCdModal} />
      </Modal>
    </>
  )
}

export default BuCdModal
