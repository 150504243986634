import i18next from 'i18next'
import { connect } from 'react-redux'

import CustomCard from '../../../../../../../../../components/Card'
import FormField from '../../../../../../../../../components/FormField'
import LGPDCheckBox from './components/LGPDCheckBox'

import { formatCPF } from '../../../../../../../../../helpers/masks'
import { formatNationalOrInternationalPhoneNumber } from '../../../../../../../../../helpers/InternationalPhoneNumberUtils'
import { useServiceOrderDetailContext } from '../../../../../../../context'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'

export const formatPhone = (phone?: string) => formatNationalOrInternationalPhoneNumber(null, phone)

function ClientBox(props: any) {
  const { client, loadingClient } = useServiceOrderDetailContext()

  const {
    auth: {
      setupParameters: { acceptanceSalesConditions },
    },
  } = props

  const mobilePhone = formatPhone(client?.custMobilePhonNr) || '-'
  const custFullNm = client?.custFullNm || '-'
  const userCd = formatCPF(client?.custIdNr) || '-'

  const isCheckedInstala = client?.servcDataCnsntCustInd === '1'
  const isCheckedMarketing = client?.mrktgDataCnsntCustInd === '1'

  const labelInstala = i18next.t('service.order.rgpd.service')
  const labelMarketing = i18next.t('service.order.rgpd.marketing')

  return (
    <CustomCard
      title={i18next.t('service.order.details.client')}
      iconNode={<MozaicIcon icon='AccountProfileView24' />}
    >
      <div className='row' id='client-box-detail'>
        <div className='col-xs-12'>
          <FormField
            isFetching={loadingClient}
            label={i18next.t('service.order.details.client')}
            value={custFullNm}
            isBold
          />
        </div>
        <div className='col-xs-6 mt-2'>
          <FormField
            isFetching={loadingClient}
            label={`${i18next.t('field.name.person.main.document')} /
                        ${i18next.t('company.info.cnpj')}`}
            value={userCd}
          />
        </div>
        <div className='col-xs-6 mt-2'>
          <FormField
            isFetching={loadingClient}
            label={i18next.t('serviceOrders.phone1')}
            value={mobilePhone}
          />
        </div>
        {acceptanceSalesConditions && (
          <>
            <div className='col-xs-12 d-flex'>
              <LGPDCheckBox
                warning
                isFetching={loadingClient}
                isChecked={isCheckedInstala}
                label={labelInstala}
              />
              <LGPDCheckBox
                isFetching={loadingClient}
                isChecked={isCheckedMarketing}
                label={labelMarketing}
              />
            </div>
          </>
        )}
        <div className='col-xs-12 mt-2'>
          <FormField
            isFetching={loadingClient}
            label={i18next.t('Email')}
            value={client?.custEmailTxt}
          />
        </div>
      </div>
    </CustomCard>
  )
}

function mapStateToProps({ auth }: any) {
  return {
    auth,
  }
}

export default connect(mapStateToProps, null)(ClientBox)
