import { useEffect, useState } from 'react'
import history from '../../helpers/history'

import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

export type RouteType = {
  path: string
  label: string
  icon: any
  component: any
}

export type TabsType = {
  routes: Array<RouteType>
  style?: 'mozaic'
}

function Tabs(props: TabsType) {
  const { routes, style } = props
  const { path, url } = useRouteMatch()

  const [highlight, setHighlight] = useState(0)

  const onChangeRoute = (location: any) => {
    const currentPath = location.pathname

    routes.some((route, i) => {
      if (currentPath.endsWith(route.path) || currentPath.includes(`${route.path}/`)) {
        setHighlight(i)

        return true
      }
    })
  }

  useEffect(() => {
    onChangeRoute(history.location)

    const unlisten = history.listen(onChangeRoute)

    return () => unlisten()
  }, [])

  return (
    <div id='custom-tabs-container' className={`${style}-tabs`}>
      <div className='tabs-content'>
        <ul className='tab-list'>
          {routes.map((item, i) => (
            <Link
              className={i === highlight ? 'highlight tab' : 'tab'}
              key={item.label}
              to={`${url}${item.path}`}
            >
              <span className='tab-link'>
                {item.icon} <span>{item.label}</span>
              </span>
            </Link>
          ))}
        </ul>
      </div>

      <Switch>
        <Route path={path} exact>
          <Redirect to={url + routes[0].path} />
        </Route>

        {routes.map((item) => (
          <Route path={`${path}${item.path}`} key={item.path}>
            {item.component}
          </Route>
        ))}

        <Redirect path='*' exact to={url + routes[0].path} />
      </Switch>
    </div>
  )
}

export default Tabs
