import PropTypes from 'prop-types'
import classNames from 'classnames'

function Glyph(props) {
  const { type, color, customClass } = props

  const glyphClass = classNames(
    'glyph',
    `glyph-${type}`,
    {
      [`color-${color}`]: color,
    },
    customClass,
  )

  return <i className={glyphClass} />
}

Glyph.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  customClass: PropTypes.string,
}

export default Glyph
