import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Grid from '../../components/utils/Grid'
import InputSearch from '../../components/utils/InputSearch'
import Row from '../../components/utils/Row'
import TitleSection from '../../components/utils/TitleSection'

import 'firebase/auth'
import firebase from 'firebase/compat/app'

import {
  MESSAGE_DEVICE,
  MESSAGE_TYPE,
  SERVICE_ORDERS_DEFAULT_FILTERS,
} from '../../constants/config'
import * as chatActions from '../../redux/actions/chat'
import * as messageActions from '../../redux/actions/messages'
import { linkServiceOrdersFilters } from '../../redux/actions/serviceOrder'
import ChatHeader from './components/ChatHeader'
import ChatTable from './components/Table'
import { MessageInput } from './components/message/MessageInput'
import MessageList from './components/message/MessageList'

import { getChatList, getUnreadMessages } from '../../redux/reducers/chat'

class ChatContainer extends Component {
  constructor(props) {
    super(props)
    this.chatReference = null
    this.unsubChat = () => {}

    this.state = {
      chatFilter: '',
      selectedUid: '',
      uid: undefined,
      searchingOldMessages: false, // Check if you are looking for old messages
      loadingMessages: false, // Check if you are loading the first messages
      storageKey: '', // Chat current person key in browser storage
      storageMessages: [], // Messages that are saved in the browser
      selectedIndex: null,
      lastRecords: [],
      messages: [],
      hasDb: false,
      firestoreChat: [],
      selectedChat: null,
    }
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    chatList: PropTypes.object.isRequired,
    auth: PropTypes.object,
    linkServiceOrdersFilters: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    signinFirebaseSuccess: PropTypes.bool,
    fetchChatPageList: PropTypes.func,
    chatIsFetching: PropTypes.bool,
  }

  static isMediaMessage(message) {
    return message.type === MESSAGE_TYPE.IMAGE || message.type === MESSAGE_TYPE.VIDEO
  }

  componentWillUnmount() {
    this.unsubChat()
  }

  componentDidMount() {
    const {
      fetchChatPageList,
      chatIsFetching,
      auth: { setupParameters, user },
    } = this.props

    if (!chatIsFetching) {
      if (user) {
        let device = MESSAGE_DEVICE.WEB_BRANCH
        if (
          user.funcaoAcesso &&
          user.funcaoAcesso.inFuncao17 &&
          user.funcaoAcesso.inFuncao17 === 1
        ) {
          device = MESSAGE_DEVICE.WEB
        }
        if (
          setupParameters.serviceOrderChatMultiStoreUser &&
          user.accessFunctionList &&
          user.accessFunctionList.includes(17) &&
          user.accessFunctionList.includes(29)
        ) {
          fetchChatPageList(user.dsToken, {}, device, setupParameters.chatEnabled)
        } else if (user.accessFunctionList && user.accessFunctionList.includes(29)) {
          fetchChatPageList(user.dsToken, {}, device, setupParameters.chatEnabled)
        }
      }
    }
  }

  componentDidUpdate() {
    this.checkCreateDb()
  }

  sendChatRecord = async (message) => {
    const { selectedChat } = this.state
    const {
      t,
      auth: { user },
    } = this.props

    const chat = this.chatReference.doc(selectedChat.uid)
    const fetchedChat = await chat.get()
    const inFunc17 = user?.funcaoAcesso?.inFuncao17

    const record = {
      displayName: user.fullName,
      message:
        user.locationCode === '999'
          ? `${t('chat.locationName')} - ${message}`
          : `${user.locationName} - ${message}`,
      uid: selectedChat.uid,
      isRead: false,
      device: inFunc17 === 1 ? MESSAGE_DEVICE.WEB : MESSAGE_DEVICE.WEB_BRANCH,
      type: MESSAGE_TYPE.TEXT,
      createdAt: +moment(),
    }

    if (fetchedChat.exists) {
      this.setState((prev) => ({
        ...prev,
        firestoreChat: prev.firestoreChat.map((chat) => {
          if (chat.uid === selectedChat.uid) {
            const messageHistory = chat.messageHistory !== undefined ? chat.messageHistory : []
            return {
              ...chat,
              lastRecord: record,
              messageHistory: [...messageHistory, record],
            }
          }
          return chat
        }),
      }))
      chat.update({
        messageHistory: firebase.firestore.FieldValue.arrayUnion(record),
        lastRecord: record,
        unreadStore: false,
        unreadEndUser: true,
      })
    } else {
      this.setState((prev) => ({
        ...prev,
        firestoreChat: prev.firestoreChat.map((chat) => {
          if (chat.uid === selectedChat.uid) {
            return {
              ...chat,
              lastRecord: record,
              messageHistory: [record],
            }
          }
          return chat
        }),
      }))
      chat.set({
        messageHistory: [record],
        lastRecord: record,
      })
    }
  }

  openServiceOrderListFiltered = (servcOrdCd) => {
    const { linkServiceOrdersFilters } = this.props

    linkServiceOrdersFilters({
      ...SERVICE_ORDERS_DEFAULT_FILTERS,
      currentSearchFieldValue: servcOrdCd,
      hasFilters: true,
    })
  }

  get isLoading() {
    const { loadingMessages } = this.state

    return loadingMessages
  }

  humanizeDate = (date, useToday) => {
    if (!date) return ''

    const { t } = this.props

    const today = moment()
    const dateFromTime = moment(date)

    if (dateFromTime.isSame(today, 'day')) {
      return useToday ? t('chat.today') : dateFromTime.format('HH:mm')
    }
    if (dateFromTime.isSame(today.add(-1, 'day'), 'day')) {
      return t('chat.yesterday')
    }
    return dateFromTime.format('L')
  }

  handleFilterChange = (event) => this.setState({ chatFilter: event.target.value })

  // Check if the db exists and mount it
  checkCreateDb = async () => {
    const { hasDb } = this.state
    const { signinFirebaseSuccess, chatList } = this.props

    if (hasDb || !signinFirebaseSuccess) return

    this.chatReference = firebase.firestore().collection('newChat')
    const chatMessages = await this.chatReference
      .where('lastRecord.createdAt', '>', moment().subtract(15, 'days').valueOf())
      .orderBy('lastRecord.createdAt', 'desc')
      .get()

    const chatByUid = chatList
    chatMessages.forEach((item) => (chatByUid[item.id] = { ...chatByUid[item.id], ...item.data() }))

    const arrayChat = Object.keys(chatByUid).map((k) => chatByUid[k])
    this.setState({ firestoreChat: this.sortedChatList(arrayChat) })

    // listener for new messages
    this.unsubChat = this.chatReference
      .where('lastRecord.device', '==', 'mobile')
      .where('lastRecord.isRead', '==', false)
      .orderBy('lastRecord.createdAt', 'desc')
      .limit(1)
      .onSnapshot((snap) => this.handleNewMessage(snap.docs[0]))

    this.setState({ hasDb: true })
  }

  handleNewMessage = (firestoreDoc) => {
    const { firestoreChat } = this.state

    if (!firestoreDoc) return
    let chatContent = firestoreDoc.data()
    // checks if new message is from currently selected chat and sets as readed
    if (this.isDocFromCurrentSelectedChat(firestoreDoc.id))
      chatContent = {
        ...firestoreDoc.data(),
        lastRecord: { ...firestoreDoc.data().lastRecord, isRead: true },
      }

    const updatedList = firestoreChat.map((chat) => {
      if (chat.uid === firestoreDoc.id) return { ...chat, ...chatContent }

      return chat
    })
    this.setState(
      (prev) => ({
        ...prev,
        firestoreChat: updatedList,
      }),
      () => {
        if (this.isDocFromCurrentSelectedChat(firestoreDoc.id))
          this.updateReadStatus({
            ...firestoreDoc.data(),
            uid: firestoreDoc.id,
          })
      },
    )
  }

  sortedChatList = (unsortedChatList) => {
    if (unsortedChatList.length) {
      const orderedByDate = unsortedChatList
        .filter((message) => message.lastRecord)
        .sort((a, b) => (a.lastRecord.createdAt > b.lastRecord.createdAt ? -1 : 1))

      const orderedByName = unsortedChatList
        .filter((message) => !message.lastRecord)
        .sort((a, b) =>
          a?.dsChat?.trim()?.toLowerCase() > b.dsChat?.trim()?.toLowerCase() ? 1 : -1,
        )
      return [...orderedByDate, ...orderedByName]
    }
    return unsortedChatList
  }

  isDocFromCurrentSelectedChat = (docUid) => {
    const { selectedChat } = this.state
    return docUid === selectedChat?.uid
  }

  updateReadStatus = ({ lastRecord, uid }) => {
    if (!uid || !lastRecord || lastRecord.isRead) return

    this.chatReference.doc(uid).set(
      {
        lastRecord: {
          isRead: true,
        },
      },
      { merge: true },
    )
  }

  handleChangeSelected = (newSelected) => {
    const { selectedChat } = this.state
    if (selectedChat?.uid !== newSelected?.uid) {
      let readedMessage = newSelected
      // checks if new selected chat has conversation and set it as read
      if (readedMessage?.lastRecord)
        readedMessage = {
          ...newSelected,
          lastRecord: { ...newSelected.lastRecord, isRead: true },
        }

      this.setState((prev) => ({
        ...prev,
        selectedUid: selectedChat?.uid,
        selectedChat: readedMessage,
        firestoreChat: prev.firestoreChat.map((chat) => {
          if (chat.uid === readedMessage.uid) return readedMessage

          return chat
        }),
      }))
      this.updateReadStatus(newSelected)
    }
  }

  render() {
    const { auth, dispatch, t } = this.props
    const { firestoreChat, hasDb, selectedChat, chatFilter, selectedUid, uid } = this.state

    const messagesCurrentSelected =
      firestoreChat.find(
        (chat) => chat.uid === selectedChat?.uid,
      )?.messageHistory || []

    const chatList = chatFilter
      ? firestoreChat.filter((chat) =>
          chat?.dsChat?.toLowerCase().includes(chatFilter.toLowerCase()),
        )
      : firestoreChat

    return (
      <Grid fluid className='chat'>
        <TitleSection>
          <h1>{t('lmi.chat')}</h1>
        </TitleSection>

        <Row className='chat-container'>
          <div className='chat-start'>
            <div className='padding chat-contact-search-box'>
              <InputSearch
                id='chatFilter'
                name='chatFilter'
                className='chat-contact-search width-100'
                label={t('chat.searchContact')}
                value={chatFilter}
                onChange={this.handleFilterChange}
              />
            </div>

            <ChatTable
              chatList={this.sortedChatList(chatList)}
              selectedChat={selectedChat}
              t={t}
              selectedUid={selectedUid}
              changeSelected={this.handleChangeSelected}
              humanizeDate={this.humanizeDate}
              isFetchingChatList={!hasDb}
              filterChat={chatFilter}
              auth={auth}
            />
          </div>

          {selectedChat && (
            <div className='chat-end'>
              <ChatHeader
                chatRecord={selectedChat}
                loading={this.isLoading}
                humanizeDate={this.humanizeDate}
                t={t}
                auth={auth}
              />

              <MessageList
                auth={auth}
                t={t}
                messages={messagesCurrentSelected}
                openServiceOrderListFiltered={this.openServiceOrderListFiltered}
                onChatScroll={() => {}}
                setChunkQty={() => {}}
                receiverUid={uid}
                humanizeDate={this.humanizeDate}
                dispatch={dispatch}
              />

              <MessageInput t={t} sendMessages={this.sendChatRecord} />
            </div>
          )}
        </Row>
      </Grid>
    )
  }
}

/*
 * Mapes redux state for component props
 */
/* istanbul ignore next */
const mapStateToProps = (state) => {
  const { chatReducer, userMessage, messages, auth } = state

  return {
    chatList: getChatList(state),
    userMessage,
    messages,
    auth,
    filter: chatReducer.list.filter,
    signinFirebaseSuccess: state.auth.signinFirebaseSuccess,
    unreadQty: getUnreadMessages(state),
    chatIsFetching: chatReducer.list.isFetching,
  }
}

/*
 * Map actions and dispatch to component props
 */
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...chatActions, ...messageActions, linkServiceOrdersFilters, dispatch },
    dispatch,
  )

/**
 * Connects component to I18next and Redux
 */
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatContainer))

// Export as component
export { ChatContainer }
