import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import MaskedText from '../../../components/utils/MaskedText'

import moment from 'moment-timezone'
import { FORMAT_DATE_TRUNC_DOT } from '../../../constants/config'

const DAY = 'day'

function Table({ records, t }) {
  const getDate = (creatTs) => {
    if (moment(creatTs).isSame(moment(), DAY)) {
      return t('servcPrvdrAgntTmlineEvnt.today')
    }
    if (moment(creatTs).isSame(moment().subtract(1, DAY), DAY)) {
      return t('servcPrvdrAgntTmlineEvnt.yesterday')
    }
    return moment(creatTs).format(FORMAT_DATE_TRUNC_DOT)
  }

  const renderRows = (record) => (
    <tr key={record.servcPrvdrAgntTmlineEvntCd}>
      <td>
        <div>
          <span>
            <strong>{getDate(record.creatTs)}</strong>
          </span>
          <span>{moment(record.creatTs).format('LT')}</span>
        </div>
      </td>
      <td>
        <div>
          <div>
            <span>-</span>
            <span style={record.servcPrvdrAgntColrInd === '1' ? { color: '#f00' } : null}>
              <strong>{t(record.servcPrvdrAgntTmlineEvntTypDesc)}</strong>
            </span>
          </div>
          <div>
            <span>{t('servcPrvdrAgntTmlineEvnt.changedBy')}</span>
            <span>{record.lastUpdtLdapUserNm}</span>
            <span>{record.lastUpdtLdapUserCd}</span>
          </div>
          <div>
            <span>{t('servcPrvdrAgntTmlineEvnt.justification')}</span>
            <span>{ReactHtmlParser(record.servcPrvdrAgntTmlineEvntDesc)}</span>
          </div>
        </div>
      </td>
    </tr>
  )

  return (
    <table>
      <thead>
        <tr>
          <th colSpan='2'>{t('servcPrvdrAgntTmlineEvnt.craftsmanName')}</th>
        </tr>
        <tr>
          <th colSpan='2'>
            <strong>{records[0].servcPrvdrAgntNm}</strong>
          </th>
        </tr>
        <tr>
          <th colSpan='2'>{t('servcPrvdrAgntTmlineEvnt.craftsmanCPF')}</th>
        </tr>
        <tr>
          <th colSpan='2'>
            <strong>
              <MaskedText text={records[0].natrlPrsnFedrlTaxIdNr} maskType='cpf' />
            </strong>
          </th>
        </tr>
      </thead>
      <tbody>{records.map(renderRows)}</tbody>
    </table>
  )
}

Table.propTypes = {
  records: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}

export default Table
