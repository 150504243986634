import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../contexts/advancedSearch'
import { AdvancedSearchInputType } from '../models'

export function WithContext(Component: FunctionComponent<AdvancedSearchInputType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      filters,
      hasFilters,
      handleOpen,
      handleSubmit,
      clientDataState: { search, setSearch },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        filters,
        hasFilters,
        handleOpen,
        handleSubmit,
        search,
        setSearch,
      }),
      [search, filters, hasFilters, handleSubmit],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
