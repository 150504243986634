import i18next from 'i18next'

import classNames from 'classnames'
import { capitalize } from 'lodash'

import { TextField } from '@leroy-merlin-br/backyard-react'

import CustomCard from '../../../../components/Card'
import NativeDatePicker from '../../../../components/Date'

import { formatCNPJ } from '../../../../helpers/masks'
import { decimalValue, yearMonthDay } from '../../../../helpers/format'

import { useProviderAnalysisContext } from '../../context'
import MozaicIcon from '../../../../components/MozaicIcon'

function CompanyInfo() {
  const {
    providerInfo,
    providerLogo,
    providerNoHasLogo,
    loadingProviderLogo,
    loadingProviderInfo,
  } = useProviderAnalysisContext()

  return (
    <div id='company-info-container'>
      <CustomCard
        title='options.registration.provider'
        iconNode={<MozaicIcon icon='PartnerWorker24' />}
      >
        <section className='info'>
          <section
            className={classNames({
              'company-logo': true,
              skeleton: loadingProviderLogo,
              'empty-logo': providerNoHasLogo,
            })}
          >
            <img src={providerLogo} alt='company logo' />
          </section>

          <section className='content-right'>
            <div className='line-1'>
              <section
                className={classNames({
                  'cpnj-text-field': true,
                  skeleton: loadingProviderInfo,
                })}
              >
                <TextField
                  value={formatCNPJ(providerInfo.servcPrvdrFedrlTaxIdNr) || ''}
                  label={i18next.t('field.name.company.number')}
                  placeholder={i18next.t('field.name.company.number')}
                  autoComplete='off'
                  id=''
                  disabled
                />
              </section>

              <section className='major-content'>
                <section
                  className={classNames({
                    'average-rating-text-field': true,
                    skeleton: loadingProviderInfo,
                  })}
                >
                  <TextField
                    value={decimalValue(providerInfo.servcPrvdrAvgGrade) || ''}
                    label={capitalize(i18next.t('serviceProvider.averageRating'))}
                    placeholder={capitalize(i18next.t('serviceProvider.averageRating'))}
                    autoComplete='off'
                    id=''
                    disabled
                  />
                </section>
                <section
                  className={classNames({
                    'register-date-picker': true,
                    skeleton: loadingProviderInfo,
                  })}
                >
                  <NativeDatePicker
                    label={i18next.t('serviceProvider.registerDate')}
                    name='native-picker'
                    id='native-picker'
                    value={yearMonthDay(providerInfo.servcPrvdrCreatDt) || ''}
                    disabled
                  />
                </section>
              </section>
            </div>
            <div
              className={classNames({
                'line-2': true,
                skeleton: loadingProviderInfo,
              })}
            >
              <TextField
                value={providerInfo.servcPrvdrTrdNm || ''}
                label={capitalize(i18next.t('field.name.fantasy.name.only'))}
                placeholder={capitalize(i18next.t('field.name.fantasy.name.only'))}
                autoComplete='off'
                id=''
                className=''
                disabled
              />
            </div>
            <div
              className={classNames({
                'line-3': true,
                skeleton: loadingProviderInfo,
              })}
            >
              <TextField
                value={providerInfo.servcPrvdrNm || ''}
                label={capitalize(i18next.t('company.info.name'))}
                placeholder={capitalize(i18next.t('company.info.name'))}
                autoComplete='off'
                id=''
                className=''
                disabled
              />
            </div>
          </section>
        </section>

      </CustomCard>
    </div>
  )
}

export default CompanyInfo
