import {
  BACK_STATUS_WITH_OCCURRENCE_ERROR,
  BACK_STATUS_WITH_OCCURRENCE_REQUEST,
  BACK_STATUS_WITH_OCCURRENCE_SUCCESS,
  CANCEL_SERVICE_ORDER_ERROR,
  CANCEL_SERVICE_ORDER_REQUEST,
  CANCEL_SERVICE_ORDER_SUCCESS,
  CLEAR_SERVICE_ORDER_LIST,
  CLEAR_SERVICE_ORDER_RESPONSE,
  CLEAR_SERVICE_ORDER_REWORK,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR,
  DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS,
  DELETE_SERVC_ORD_ATCHMT_ERROR,
  DELETE_SERVC_ORD_ATCHMT_REQUEST,
  DELETE_SERVC_ORD_ATCHMT_SUCCESS,
  FETCH_SERVC_ORD_ATCHMT_ERROR,
  FETCH_SERVC_ORD_ATCHMT_REQUEST,
  FETCH_SERVC_ORD_ATCHMT_SUCCESS,
  FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS,
  FETCH_SERVICE_ORDERS_ERROR,
  FETCH_SERVICE_ORDERS_FROM_MENU,
  FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE,
  FETCH_SERVICE_ORDERS_REQUEST,
  FETCH_SERVICE_ORDERS_SUCCESS,
  FETCH_SERVICE_ORDER_IMAGES_SUCCESS,
  FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS,
  FIND_SHORT_LINK_ERROR,
  FIND_SHORT_LINK_REQUEST,
  FIND_SHORT_LINK_SUCCESS,
  GENERATE_OCCURRENCE_ERROR,
  GENERATE_OCCURRENCE_REQUEST,
  GENERATE_OCCURRENCE_SUCCESS,
  GET_SERVICE_ORDER_ERROR,
  GET_SERVICE_ORDER_FROM_LIST_ERROR,
  GET_SERVICE_ORDER_REQUEST,
  GET_SERVICE_ORDER_SUCCESS,
  IS_BACK_ORDER,
  LINK_SERVICE_ORDERS_FILTERS,
  MERGE_WITH_SERVICE_ORDER,
  RESET_EDIT_SERVICE_ORDER,
  RESET_SERVICE_ORDERS,
  SAVE_FILTERS,
  SAVE_SERVC_ORD_ATCHMT_ERROR,
  SAVE_SERVC_ORD_ATCHMT_REQUEST,
  SAVE_SERVC_ORD_ATCHMT_SUCCESS,
  SAVE_SO_SCROLL_POSITION,
  SEND_MSG_CONFORMITY_ERROR,
  SEND_MSG_CONFORMITY_REQUEST,
  SEND_MSG_CONFORMITY_SUCCESS,
  SEND_MSG_TERMS_ERROR,
  SEND_MSG_TERMS_REQUEST,
  SEND_MSG_TERMS_SUCCESS,
  SERVC_ORD_BUDGETS_ACCEPTED_OPTION,
  SERVC_ORD_BUDGETS_DENIED_OPTION,
  SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR,
  SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED,
  SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED,
  SET_SERVICE_ORDER,
  SHOULD_RESTORE_SCROLL,
  UPDATE_OS_MENU_SHORTCUT_ERROR,
  UPDATE_OS_MENU_SHORTCUT_REQUEST,
  UPDATE_OS_MENU_SHORTCUT_SUCCESS,
  UPDATE_SERVC_ORD_OBSERVATION_ERROR,
  UPDATE_SERVC_ORD_OBSERVATION_REQUEST,
  UPDATE_SERVC_ORD_OBSERVATION_RESET,
  UPDATE_SERVC_ORD_OBSERVATION_SUCCESS,
  UPDATE_SERVICE_ORDER_ERROR,
  UPDATE_SERVICE_ORDER_REQUEST,
  UPDATE_SERVICE_ORDER_SUCCESS,
  VIEW_SERVICE_ORDER_FROM_LIST,
} from '../../actionTypes/serviceOrder'

const DEFAULT_STATE = {
  list: {
    records: [],
    error: null,
    isFetching: false,
    filter: {},
  },
  get: {
    record: {},
    error: null,
    isFetching: false,
    disabled: false,
  },
  edit: {
    record: {},
    error: null,
    success: null,
    isUpdating: false,
  },
  isFromMenu: false,
  isBackOrder: false,
  call: {
    record: {},
    error: null,
    isFetching: false,
  },
  filters: {
    filter: {},
  },
  scroll: {
    scrollPosition: 0,
    restoreScroll: false,
  },
  changeObsResult: {
    success: null,
    error: null,
    isFetching: false,
  },
  atchmt: {
    list: [],
    message: null,
    isFetching: false,
    isSaving: false,
    isDeleting: false,
  },
  qrcode: {
    shortLink: {
      isFetching: false,
      data: null,
      error: null,
    },
    sendMsg: {
      isFetching: false,
      success: null,
      error: null,
    },
  },
  atchmtBudgetClient: {
    list: [],
    message: null,
    isFetching: false,
    isSaving: false,
    isDeleting: false,
  },
  atchmtBudgetStore: {
    list: [],
    message: null,
    isFetching: false,
    isSaving: false,
    isDeleting: false,
  },
  optionBudgetAccepted: null,
  measurementFiles: [],
  boxBudgetDistributedSelected: false,
  bidMode: {
    success: null,
    error: null,
  },
}

const record = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SEND_MSG_TERMS_REQUEST:
    case SEND_MSG_CONFORMITY_REQUEST:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          sendMsg: {
            isFetching: true,
            success: null,
            error: null,
          },
        },
      }

    case SEND_MSG_TERMS_SUCCESS:
    case SEND_MSG_CONFORMITY_SUCCESS:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          sendMsg: {
            isFetching: false,
            success: true,
            error: null,
          },
        },
      }

    case SEND_MSG_TERMS_ERROR:
    case SEND_MSG_CONFORMITY_ERROR:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          sendMsg: {
            isFetching: false,
            success: null,
            error: action.payload,
          },
        },
      }

    case FIND_SHORT_LINK_REQUEST:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          shortLink: {
            isFetching: true,
            data: null,
            error: null,
          },
        },
      }

    case FIND_SHORT_LINK_SUCCESS:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          shortLink: {
            isFetching: false,
            data: action.payload,
            error: null,
          },
        },
      }

    case FIND_SHORT_LINK_ERROR:
      return {
        ...state,
        qrcode: {
          ...state.qrcode,
          shortLink: {
            isFetching: false,
            data: null,
            error: action.payload,
          },
        },
      }

    case FETCH_SERVC_ORD_ATCHMT_REQUEST:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isFetching: true,
          message: null,
        },
      }

    case FETCH_SERVC_ORD_ATCHMT_SUCCESS:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isFetching: false,
          message: null,
          list: action.payload,
        },
      }

    case FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS:
      return {
        ...state,
        measurementFiles: action.payload,
      }

    case FETCH_SERVC_ORD_ATCHMT_ERROR:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isFetching: false,
          message: action.payload,
          list: [],
        },
      }

    case SAVE_SERVC_ORD_ATCHMT_REQUEST:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isSaving: true,
        },
      }

    case SAVE_SERVC_ORD_ATCHMT_SUCCESS:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isSaving: false,
          message: action.payload.message,
        },
      }

    case SAVE_SERVC_ORD_ATCHMT_ERROR:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isSaving: false,
          message: action.payload,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_REQUEST:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isDeleting: true,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_SUCCESS:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isDeleting: false,
          message: action.payload.message,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_ERROR:
      return {
        ...state,
        atchmt: {
          ...state.atchmt,
          isDeleting: false,
          message: action.payload,
        },
      }

    case FETCH_SERVICE_ORDERS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case FETCH_SERVICE_ORDERS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: null,
          records: action.payload,
          filter: action.filter,
        },
      }

    case FETCH_SERVICE_ORDERS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          records: [],
          error: action.payload,
          filter: action.filter,
        },
      }

    case UPDATE_SERVICE_ORDER_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: true,
        },
      }

    case UPDATE_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: null,
          record: action.payload,
        },
        get: {
          record: action.payload,
        },
      }

    case UPDATE_SERVICE_ORDER_ERROR:
      return {
        ...state,
        edit: {
          ...state.edit,
          isUpdating: false,
          error: action.payload,
          record: {},
        },
      }

    case RESET_SERVICE_ORDERS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          records: [],
          error: null,
          filter: {},
        },
      }

    case RESET_EDIT_SERVICE_ORDER:
      return {
        ...state,
        edit: {
          isUpdating: false,
          error: null,
          record: {},
        },
      }

    case SAVE_FILTERS:
      return {
        ...state,
        filters: {
          filter: action.payload,
        },
      }

    case IS_BACK_ORDER:
      return {
        ...state,
        isBackOrder: action.payload,
      }

    case GET_SERVICE_ORDER_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          isFetching: true,
        },
      }

    case GET_SERVICE_ORDER_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          record: action.payload.length ? action.payload[0] : {},
          isFetching: false,
          error: null,
          disabled: false,
        },
        atchmtBudgetClient: {
          ...state.atchmtBudgetClient,
          list: action.payload.length ? action.payload[0].listAtchmtClient : [],
        },
        atchmtBudgetStore: {
          ...state.atchmtBudgetStore,
          list: action.payload.length ? action.payload[0].listAtchmtStore : [],
        },
      }

    case GET_SERVICE_ORDER_ERROR:
      return {
        ...state,
        get: {
          ...state.get,
          record: {},
          isFetching: false,
          error: action.payload,
          disabled: false,
        },
      }

    case SET_SERVICE_ORDER:
      const listAtchmtClient = action.payload?.listAtchmtClient || []
      const listClient = listAtchmtClient?.length ? listAtchmtClient : null

      const listAtchmtStore = action.payload?.listAtchmtStore || []
      const listStore = listAtchmtStore?.length ? listAtchmtStore : null

      return {
        ...state,
        get: {
          ...state.get,
          record: action.payload,
        },
        atchmtBudgetClient: {
          ...state.atchmtBudgetClient,
          list: listClient,
        },
        atchmtBudgetStore: {
          ...state.atchmtBudgetStore,
          list: listStore,
        },
        optionBudgetAccepted: null,
      }

    case MERGE_WITH_SERVICE_ORDER:
      return {
        ...state,
        get: {
          ...state.get,
          record: {
            ...state.get.record,
            ...action.payload,
          },
        },
      }

    case GET_SERVICE_ORDER_FROM_LIST_ERROR:
      return {
        ...state,
      }

    case VIEW_SERVICE_ORDER_FROM_LIST:
      const i = action.index
      const r = state.list.records[i]

      return {
        ...state,
        get: {
          record: r,
          error: null,
          isFetching: false,
          disabled: true,
        },
        edit: {
          record: {},
          error: null,
          isUpdating: false,
        },
      }

    case CLEAR_SERVICE_ORDER_RESPONSE:
      return {
        ...state,
        edit: {
          record: {},
          error: null,
          isUpdating: false,
        },
        list: {
          records: [],
          error: null,
          isFetching: false,
          filter: {},
        },
      }

    case CLEAR_SERVICE_ORDER_REWORK:
      return {
        ...state,
        duplicate: {
          record: {},
          error: null,
          isUpdating: false,
        },
        technicalVisit: {
          record: {},
          error: null,
          isUpdating: false,
        },
      }

    case FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          record: {
            ...state.get.record,
            previousSO: action.payload,
          },
        },
      }

    case FETCH_SERVICE_ORDER_IMAGES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          record: {
            ...state.get.record,
            images: action.payload,
          },
        },
      }

    case FETCH_SERVICE_ORDERS_FROM_MENU:
      return {
        ...state,
        isFromMenu: true,
      }

    case FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE:
      return {
        ...state,
        isFromMenu: false,
      }

    case LINK_SERVICE_ORDERS_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filter: action.payload,
        },
      }
    case BACK_STATUS_WITH_OCCURRENCE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case BACK_STATUS_WITH_OCCURRENCE_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.list.records[action.payload.index].servcOrdStusCd = action.payload.servcOrdItemStusCd

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      }

    case BACK_STATUS_WITH_OCCURRENCE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.payload,
        },
      }

    case UPDATE_OS_MENU_SHORTCUT_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case UPDATE_OS_MENU_SHORTCUT_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.list.records[action.payload.index] = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      }

    case UPDATE_OS_MENU_SHORTCUT_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.payload,
        },
      }

    case CANCEL_SERVICE_ORDER_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case CANCEL_SERVICE_ORDER_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.list.records[action.payload.index] = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      }

    case CANCEL_SERVICE_ORDER_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.payload,
        },
      }

    case CLEAR_SERVICE_ORDER_LIST:
      return {
        ...state,
        list: {
          records: [],
          error: null,
          isFetching: false,
          filter: {},
        },
      }

    case SAVE_SO_SCROLL_POSITION:
      return {
        ...state,
        scroll: {
          ...state.scroll,
          scrollPosition: action.payload,
        },
      }

    case SHOULD_RESTORE_SCROLL:
      return {
        ...state,
        scroll: {
          ...state.scroll,
          restoreScroll: action.payload,
        },
      }

    case GENERATE_OCCURRENCE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case GENERATE_OCCURRENCE_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.list.records[action.payload.index] = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
        },
      }

    case GENERATE_OCCURRENCE_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.payload,
        },
      }

    case UPDATE_SERVC_ORD_OBSERVATION_RESET:
      return {
        ...state,
        changeObsResult: {
          ...state.changeObsResult,
          isFetching: false,
          success: null,
          error: null,
        },
      }

    case UPDATE_SERVC_ORD_OBSERVATION_REQUEST:
      return {
        ...state,
        changeObsResult: {
          ...state.changeObsResult,
          isFetching: true,
          success: null,
          error: null,
        },
      }

    case UPDATE_SERVC_ORD_OBSERVATION_SUCCESS:
      return {
        ...state,
        changeObsResult: {
          ...state.changeObsResult,
          isFetching: false,
          success: action.payload,
          error: null,
        },
      }

    case UPDATE_SERVC_ORD_OBSERVATION_ERROR:
      return {
        ...state,
        changeObsResult: {
          ...state.changeObsResult,
          isFetching: false,
          error: action.payload,
          success: null,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS:
      return {
        ...state,
        atchmtBudgetClient: {
          ...state.atchmtBudgetClient,
          list: action.payload,
          isDeleting: false,
          message: action.payload.message,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR:
      return {
        ...state,
        atchmtBudgetClient: {
          ...state.atchmtBudgetClient,
          isDeleting: false,
          message: action.payload,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS:
      return {
        ...state,
        atchmtBudgetStore: {
          ...state.atchmtBudgetStore,
          list: action.payload,
          isDeleting: false,
          message: action.payload.message,
        },
      }

    case DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR:
      return {
        ...state,
        atchmtBudgetStore: {
          ...state.atchmtBudgetStore,
          isDeleting: false,
          message: action.payload,
        },
      }

    case SERVC_ORD_BUDGETS_ACCEPTED_OPTION:
      return {
        ...state,
        optionBudgetAccepted: 'true',
      }

    case SERVC_ORD_BUDGETS_DENIED_OPTION:
      return {
        ...state,
        optionBudgetAccepted: 'false',
      }

    case SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR:
      return {
        ...state,
        optionBudgetAccepted: null,
      }

    case SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED:
      return {
        ...state,
        boxBudgetDistributedSelected: true,
      }

    case SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED:
      return {
        ...state,
        boxBudgetDistributedSelected: false,
      }
    default:
      return state
  }
}

export default record
