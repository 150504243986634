import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export interface ServiceOrderAttachment {
  atchmtCloudFullPathTxt: string
  atchmtCloudShortPathTxt: string
  atchmtDocmntTyp?: any
  atchmtDocmntTypDesc?: string
  atchmtFullPathTxt: string
  creatTs: string
  fileTyp: string
  lastUpdtUserCd?: string
  servcOrdAtchmtDcmntIdNr: string
  servcOrdCd: string
  servcOrdItemAtachmtId: number
  servcOrdItemAtchmtClsfctnCd: number
  servcOrdItemAtchmtDesc: string
  servcOrdSeq: number
}

export async function getServiceOrderAttachments(
  servcOrdSeq: number,
  fileTyp: 'img' | 'pdf' | '' = '',
): Promise<ServiceOrderAttachment[]> {
  return (
    await new API_REQUEST(user.dsToken, store.dispatch).get(API_URL.ORDER_SERVC_ATTACHMENT, {
      servcOrdSeq,
      fileTyp,
    })
  ).data
}

export async function getServiceOrderAttachmentsDocuments(
  servcOrdSeq: number,
  servcOrdItemAtchmtClsfctnCd: Array<number>,
): Promise<ServiceOrderAttachment[]> {
  return (
    await new API_REQUEST(user.dsToken, store.dispatch).get(API_URL.FIND_ORDER_SERVC_ATTACHMENT, {
      servcOrdSeq,
      servcOrdItemAtchmtClsfctnCd,
    })
  ).data
}
