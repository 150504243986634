import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import DateSelect from '../../../components/DateSelect'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import InputNumber from '../../../components/utils/InputNumber'
import Row from '../../../components/utils/Row'
import Preview from '../../../components/utils/preview/Link'
import { WithForm } from '../../../helpers/useForm/withForm'
import ServiceModal from './ServiceModal'
import SlotSelect from './SlotSelect'

class ServicesListItem extends Component {
  state = {
    services: [],
    showModal: false,
    daysDisabled: 1,
    disabledDateToChoose: true,
  }

  componentDidMount() {
    const { pyxisInformations } = this.props

    if (pyxisInformations && Object.keys(pyxisInformations).length > 0) {
      this.updateByPyxis()
    }
  }

  componentDidUpdate(prevProps) {
    const { pdvRequest, setFormValue, pyxisInformations } = this.props

    if (pdvRequest && pdvRequest !== prevProps.pdvRequest) {
      setFormValue({
        scheduleDate: moment(pdvRequest.scheduleDate),
        scheduleShift: pdvRequest.shiftSelected,
      })
    }

    if (
      pyxisInformations &&
      Object.keys(pyxisInformations).length > 0 &&
      pyxisInformations !== prevProps.pyxisInformations
    ) {
      this.updateByPyxis()
    }
  }

  updateByPyxis() {
    const { pyxisInformations, index } = this.props
    this.getDaysDisableByServiceGroup(pyxisInformations.serviceGroupCodeList[index])
    if (pyxisInformations.serviceGroupCodeList && pyxisInformations.serviceGroupCodeList[index]) {
      this.updateFileName(pyxisInformations.serviceGroupCodeList[index])

      const services = []

      if (pyxisInformations.serviceList) {
        pyxisInformations.serviceList.forEach((servc) => {
          services.push({
            code: servc.serviceCode,
            desc: servc.serviceDescription,
            quantity: servc.serviceQuantity
              ? this.maskValueforSubmit(
                  servc.serviceQuantity
                    .toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(',', ''),
                )
              : '0.00',
            value: this.maskValueforSubmit(
              servc.serviceValue
                .toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                .replace(',', ''),
            ),
            associatedProductCode: servc.serviceAssociatedProductCode,
            associatedProductName: servc.serviceDescription,
            associatedProductQuantity: servc.serviceAssociatedProductQuantity
              ? this.maskValueforSubmit(
                  servc.serviceAssociatedProductQuantity
                    .toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .replace(',', ''),
                )
              : '0.00',
            status: '00',
            sequence: 0,
          })
        })
      }

      this.setState({ services, disabledDateToChoose: false }, () => {
        this.updateValue()
        this.updateAllValues()
      })
    }
  }

  updateFileName = (fileName) => {
    this.setState({ fileName })
  }

  handleChange = (field, value) => {
    const { setFormValue } = this.props

    setFormValue({ [field]: value })

    this.updateAllValues()
  }

  getDaysDisableByServiceGroup = (code) => {
    const {
      auth: {
        setupParameters: { disableDaysAfterCreatingByServiceGroup },
      },
      sendDaysDisabled,
    } = this.props
    const index = String(disableDaysAfterCreatingByServiceGroup).indexOf(`${code}=`)
    if (index === -1) {
      this.setState({ daysDisabled: 1 })
      sendDaysDisabled(1)
    } else {
      let days = String(disableDaysAfterCreatingByServiceGroup).substring(index)
      days = days.substring(days.indexOf('=') + 1)
      days = days.substring(0, days.indexOf(';') === -1 ? 10 : days.indexOf(';'))
      this.setState({ daysDisabled: Number(days) + 1 })
      sendDaysDisabled(Number(days) + 1)
    }
  }

  updateAllValues = () => {
    setTimeout(() => {
      const { updateValues, values, index } = this.props
      const { services } = this.state

      const newValues = {
        value: values.value || null,
        scheduleDate: values.scheduleDate || null,
        scheduleShift: values.scheduleShift || null,
        services,
      }

      updateValues(index, newValues)
    }, 80)
  }

  getTomorrow = () => {
    const { daysDisabled } = this.state
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + daysDisabled)
    tomorrow.setHours(0, 0, 0, 0)
    return tomorrow
  }

  maskValueforSubmit = (value) => {
    const { t } = this.props

    if (value) {
      const formattedValue = value.replace(
        new RegExp(`\\${t('delimiter.currency')}|\\${t('separator.currency')}`, 'g'),
        '',
      )
      const decimalIndex = formattedValue.length - 2
      return `${formattedValue.substring(0, decimalIndex)}.${formattedValue.substring(
        decimalIndex,
      )}`
    }
    return '0.00'
  }

  maskNumber(value, currency = false) {
    const { t } = this.props
    return (
      (currency ? `${t('option.currency')} ` : '') +
      parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    )
  }

  onModalSubmit = (values) => {
    const { services } = this.state

    services.push({
      code: values.serviceCode,
      desc: values.serviceDesc,
      quantity: values.serviceQuantity ? this.maskValueforSubmit(values.serviceQuantity) : '0.00',
      value: this.maskValueforSubmit(values.serviceValue),
      associatedProductCode: values.serviceAssociatedProductCode,
      associatedProductName: values.serviceAssociatedProductName,
      associatedProductQuantity: values.serviceAssociatedProductQuantity
        ? this.maskValueforSubmit(values.serviceAssociatedProductQuantity)
        : '0.00',
      status: '00',
      sequence: 0,
    })

    this.setState({ services, showModal: false }, () => {
      this.updateValue()
      this.updateAllValues()
    })
  }

  removeService(i) {
    const { services } = this.state
    services.splice(i, 1)
    this.setState({ services }, () => {
      this.updateValue()
      this.updateAllValues()
    })
  }

  updateValue() {
    const { setFormValue } = this.props
    const { services } = this.state

    if (services.length === 0) {
      setFormValue({ value: '' })
    } else {
      let val = 0

      services.forEach((service) => {
        val += parseFloat(service.value) * parseFloat(service.quantity)
      })

      val = val
        .toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace(',', '')

      setFormValue({ value: val })
    }
  }

  disableCalendar = () => {
    const { disabledDateToChoose } = this.state
    return disabledDateToChoose
  }

  getDaysDisabled = () => {
    const { daysDisabled } = this.state
    const { getDaysDisabled } = this.props
    getDaysDisabled(daysDisabled)
  }

  renderModal = () => {
    const { t, values, user, dispatch, issuerPlant } = this.props

    const { showModal } = this.state

    if (!showModal) return

    return (
      <ServiceModal
        closeModal={() => this.setState({ showModal: false })}
        t={t}
        onSubmit={this.onModalSubmit}
        plntCd={issuerPlant}
        user={user}
        dispatch={dispatch}
      />
    )
  }

  renderTable() {
    const { t } = this.props
    const { services } = this.state

    if (!services || services.length === 0) return

    return (
      <div className='itens-container'>
        <table>
          <thead>
            <tr>
              <th width='18' />
              <th className='align-left'>{t('servcPrc.servcCd')}</th>
              <th className='align-left'>{t('servcOrd.services.desc')}</th>
              <th className='align-center'>{t('service.order.detatails.qtt')}</th>
              <th className='align-center'>{t('servcOrd.service.group.unit')}</th>
              <th className='align-center'>{t('servcOrd.service.group.sub')}</th>
              <th className='align-center'>{t('option.delete')}</th>
              <th width='18' />
            </tr>
          </thead>
          <tbody>
            {services.map((service, i) => {
              const subtotal = parseFloat(service.quantity) * parseFloat(service.value)
              return (
                <tr key={i}>
                  <td width='18' />
                  <td className='align-left'>{service.code}</td>
                  <td className='align-left'>{service.desc}</td>
                  <td className='align-center'>{this.maskNumber(service.quantity)}</td>
                  <td className='align-center'>{this.maskNumber(service.value)}</td>
                  <td className='align-center'>{this.maskNumber(subtotal)}</td>
                  <td className='align-center'>
                    <a role='button' onClick={() => this.removeService(i)}>
                      <i className='glyph glyph-trash-can' />
                    </a>
                  </td>
                  <td width='18' />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  checkModal = () => {
    const { t, issuerPlant } = this.props

    if (!issuerPlant) {
      alert(t('createServcOrd.selectIssuerPlantAndServiceGroupCode'))
    } else {
      this.setState({ showModal: true })
    }
  }

  isDayBlocked = (day) => {
    const { auth } = this.props

    const daysOfWeekDisabled = auth.setupParameters.disabledCalendarDays

    const numberDayWeek = day.day() + 1

    let enable = 'false'
    if (String(daysOfWeekDisabled).indexOf(numberDayWeek) !== -1) {
      enable = String(daysOfWeekDisabled).substring(
        String(daysOfWeekDisabled).indexOf(numberDayWeek),
      )
      enable = enable.substring(enable.indexOf('=') + 1)
      enable = enable.substring(0, enable.indexOf(';') === -1 ? 10 : enable.indexOf(';'))
    }

    return enable === 'true'
  }

  render() {
    const {
      t,
      user,
      user: { funcaoAcesso },
      auth,
      dispatch,
      fields,
      values,
      count,
      data,
    } = this.props

    const { fileName } = this.state

    let valueError = null

    if (data.errors.includes('value') && (!values.value || values.value === '0.00')) {
      valueError = !values.value
        ? t('servcOrd.group.value.invalid')
        : t('servcOrd.group.value.zero')
    }

    return (
      <div className='services-list-item'>
        <Row>
          <Col xs={12} className='group-index'>
            <b>{`${t('filter.field.service.group')} ${count}`} </b>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <Field
              {...fields.scheduleDate}
              error={
                data.errors.indexOf('scheduleDate') !== -1 && !values.scheduleDate
                  ? t('validation.message.is.required')
                  : null
              }
              validate
              className='scheduleDate'
            >
              <label className='label group-label'>
                {t('customer.service.order.reschedule.date')}
              </label>
              <DateSelect
                id='scheduleDate'
                value={values.scheduleDate}
                onChange={(e) => this.handleChange('scheduleDate', e)}
                placeholder={t('servcOrd.service.group.date.placeholder')}
                isOutsideRange={(day) => day.isBefore(this.getTomorrow())}
                isDayBlocked={this.isDayBlocked}
                disabled={this.disableCalendar()}
              />
            </Field>
          </Col>
          <Col xs={12} md={3}>
            <Field
              {...fields.scheduleShift}
              error={
                data.errors.indexOf('scheduleShift') !== -1 && !values.scheduleShift
                  ? t('validation.message.is.required')
                  : null
              }
              validate
            >
              <label className='label group-label'>
                {t('customer.service.order.reschedule.shift')}
              </label>
              <SlotSelect
                id='scheduleShift'
                {...fields.scheduleShift.input}
                slots={null}
                t={t}
                onChange={(e) => this.handleChange('scheduleShift', e ? e.value : null)}
                auth={auth}
                isClearable={false}
              />
            </Field>
          </Col>
          {fileName && funcaoAcesso.inFuncao77 && (
            <Col xs={12} md={6}>
              <Preview
                t={t}
                className='button button-primary button-full align-center'
                children={t('serviceGroup.download.termsAndConditions')}
                filePath={fileName}
                token={user.dsToken}
                dispatch={dispatch}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} className='margin-top'>
            <b>{t('servcOrd.service.group.itens')}</b>
          </Col>
          <Col xs={12} className='margin-top'>
            <button type='button' className='button button-primary' onClick={this.checkModal}>
              {t('servcOrd.services.create')}
            </button>
          </Col>
        </Row>
        {this.renderTable()}
        {this.renderModal()}
      </div>
    )
  }
}

ServicesListItem.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  values: PropTypes.object,
  setFormValue: PropTypes.func,
  index: PropTypes.number,
  count: PropTypes.number,
  issuerPlant: PropTypes.any,
  updateValues: PropTypes.func,
  data: PropTypes.object,
  pdvRequest: PropTypes.object,
  pyxisInformations: PropTypes.object,
  sendDaysDisabled: PropTypes.func,
  getDaysDisabled: PropTypes.func,
}

const FIELDS = {
  value: '',
  scheduleDate: null,
  scheduleShift: 'M',
}

export default WithForm(FIELDS)(ServicesListItem)
export { ServicesListItem }
