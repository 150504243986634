/* eslint-disable no-underscore-dangle */
import LanguageConfig from '../../configs/Language'
import User from '../User'

import store from '../../redux/store'
import { logoutUser } from '../../redux/actions/auth/logoutUser'
import { CHANGE_TOKEN, SESSION_EXPIRED } from '../../redux/actionTypes/auth'
import { isNaN } from 'lodash'

export const ApiKey = 'zQHJnLxKqAHoBiPQpEQav7UGYQjXfeCX'

const user = new User()
const prefix = 'i18next_res_'
const language = new LanguageConfig(prefix)

/*
 * Class para lidar com as requests para API
 */
class ApiRequest {
  constructor(token, dispatch, metadata) {
    this.token = user.currentUser?.dsToken || token
    this.dispatch = dispatch
    this.metadata = metadata
  }

  /**
   * Requisição POST
   * @param {String} endpoint = endpoint da API
   * @params {Object} params = parametros a serem enviados para API
   * @return {Promise} response
   */
  post(endpoint, params) {
    return fetch(endpoint, {
      method: 'POST',
      headers: this.headers(),
      body: JSON.stringify(params),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Requisição PATCH
   * @param {String} endpoint = endpoint da API
   * @params {Object} params = parametros a serem enviados para API
   * @return {Promise} response
   */
  patch(endpoint, params) {
    return fetch(endpoint, {
      method: 'PATCH',
      headers: this.headers(),
      body: JSON.stringify(params),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Requisição GET
   * @param {String} endpoint = endpoint da API
   * @return {Promise} response
   */
  get(endpoint, query, abortController = null) {
    const queryParams = this.createQueryParams(query)

    if (queryParams) {
      // eslint-disable-next-line no-param-reassign
      endpoint = `${endpoint}?${queryParams}`
    }

    return fetch(endpoint, {
      method: 'GET',
      headers: this.headers(),
      signal: abortController ? abortController.signal : undefined,
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Requisição DELETE
   * @param {String} endpoint = endpoint da API
   * @return {Promise} response
   */
  delete(endpoint, query) {
    const queryParams = this.createQueryParams(query)

    if (queryParams) {
      // eslint-disable-next-line no-param-reassign
      endpoint = `${endpoint}?${queryParams}`
    }

    return fetch(endpoint, {
      method: 'DELETE',
      headers: this.headers(),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  getFile(endpoint) {
    let dsToken = this.token
    if (user && user.currentUser && user.currentUser.dsToken) {
      dsToken = user.currentUser.dsToken
    }

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: dsToken,
        ApiKey,
        'Content-Language': language.userLanguage,
      },
    }).then((response) => response)
  }

  /**
   * Requisição PUT
   * @param {String} endpoint = endpoint da API
   * @params {Object} params = parametros a serem enviados para API
   * @return {Promise} response
   */
  put(endpoint, params) {
    return fetch(endpoint, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify(params),
    })
      .then((response) => this.checkExpiredSession(response))
      .then((response) => this.handleMetadata(response))
      .then((response) => this.handleData(response))
      .then((response) => this.handleResponse(response))
      .then((response) => this.updateToken(response))
  }

  /**
   * Configura headers para requesição
   * @return {Object} headers
   */
  headers() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ApiKey,
      'Content-Language': language.userLanguage,
    }

    if (this.token) {
      headers.Authorization = this.token
    }

    if (this.metadata) {
      headers._offset = this.metadata.page
      if (this.metadata.size) headers._limit = this.metadata.size
    }

    return headers
  }

  /**
   * Verifica o status da resposta.
   * @return {Boolean}
   */
  checkStatus(response) {
    const status = response.cdStatus

    return status >= 200 && status < 299
  }

  /**
   * Set http status code in the body if necessary
   * @return {Promise} response
   */
  handleData = async (response) => {
    const responseData = await response.json()

    if (!responseData.cdStatus) {
      responseData.cdStatus = response.status
    }

    return responseData
  }

  /**
   * Tratamento de erros da requisição.
   * @return {Promise} response
   */
  handleResponse(response) {
    if (response.data || this.checkStatus(response)) {
      if (this.responseMetadata) {
        response.metadata = this.responseMetadata
      }

      return response
    }

    return Promise.reject(response)
  }

  updateToken(response) {
    if (response.dsToken || (response.data && response.data.dsToken)) {
      const dsToken = response.dsToken || response.data.dsToken
      let tokenExpirationTime = response.tokenExpirationTime || response.data.tokenExpirationTime

      if (user.currentUser) {
        const newUser = user.currentUser
        newUser.dsToken = dsToken

        if (tokenExpirationTime === undefined) {
          tokenExpirationTime = user.currentUser.tokenExpirationTime
        }

        newUser.tokenExpirationTime = tokenExpirationTime
        user.currentUser = newUser
      }

      if (this.dispatch && dsToken) {
        this.dispatch({
          type: CHANGE_TOKEN,
          payload: {
            dsToken,
            tokenExpirationTime,
          },
        })
      }
    }

    return response
  }

  /**
   * Tratamento de sessão expirada.
   * @return {Promise} response
   */
  async checkExpiredSession(response) {
    if (response.status === 401) {
      const { auth } = store.getState()

      const payload = await logoutUser(auth.setupParameters.pingoneEnable)

      store.dispatch({ type: SESSION_EXPIRED })
      store.dispatch(payload)
    }

    return response
  }

  /*
   * Cria parametros de query
   * @param query {Object}
   * @return {String} query
   * ex.:
   * query = { name: 'username', age: '18' }
   * queryParams 'name=username&age=18'
   */
  createQueryParams(query) {
    if (!query || Object.keys(query).length < 1) {
      return
    }

    const esc = encodeURIComponent

    const queryParams = Object.keys(query)
      .map((k) => `${esc(k)}=${esc(query[k])}`)
      .join('&')

    // eslint-disable-next-line consistent-return
    return queryParams
  }

  handleMetadata(response) {
    const offset = parseInt(response.headers.get('_offset'), 10)
    const limit = parseInt(response.headers.get('_limit'), 10)
    const pages = parseInt(response.headers.get('pages'), 10)
    const total = parseInt(response.headers.get('total'), 10)

    // NOTE: We need to check if the values are NaN because the headers
    //       can return a string of null e.g. "null" and be parsed as a number,
    //       creating NaN values.
    const pageCount = isNaN(pages) ? 0 : pages
    const page = isNaN(offset) ? 1 : offset
    const totalCount = isNaN(total) ? 0 : total
    const size = isNaN(limit) ? 10 : limit

    this.responseMetadata = { page, pageCount, size, totalCount }

    return response
  }
}

export default ApiRequest
