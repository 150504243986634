import i18next from 'i18next'
import MozaicButton from '../../../../../../../../../components/MozaicButton'

type ConfirmFooterButtonsType = {
  handleLeft: Function
  handleRight: Function
  leftBtnLabel: string
  rightBtnLabel: string
  actionButton?: string
}

function ConfirmFooterButtons(props: ConfirmFooterButtonsType) {
  const { handleLeft, handleRight, leftBtnLabel, rightBtnLabel, actionButton } = props

  const leftBtnLabelTrans = i18next.t(leftBtnLabel)
  const rightBtnLabelTrans = i18next.t(rightBtnLabel)

  const actionBtnLeft = actionButton === 'left' ? undefined : 'bordered'
  const actionBtnRight = actionButton === 'right' ? undefined : 'bordered'

  return (
    <footer className='confirm-footer-buttons'>
      <MozaicButton variant={actionBtnLeft} onClick={() => handleLeft()}>
        {' '}
        {leftBtnLabelTrans}
      </MozaicButton>
      <MozaicButton variant={actionBtnRight} onClick={() => handleRight()}>
        {rightBtnLabelTrans}
      </MozaicButton>
    </footer>
  )
}

export default ConfirmFooterButtons
