import { forwardRef, useImperativeHandle } from 'react'
import i18next from 'i18next'

import Row from '../../../../components/utils/Row'
import Col from '../../../../components/utils/Col'
import SelectStates from './components/SelectStates'
import SelectMiddleZones from './components/SelectMiddleZones'
import SelectZonesPrimary from './components/SelectZonesPrimary'
import SelectZonesSecondary from './components/SelectZonesSecondary'

import { renderToastError } from '../../../../helpers/ToastUtils'
import { saveProviderZones } from '../../../../services/pages/ServiceProviderEdit/MacroRegionality'

import ZonesHandler from './handlers/zones'

const MacroRegionalitySection = forwardRef((props: any, ref: any) => {
  const { servcPrvdrId, viewMode } = props

  const {
    zones,
    errors,
    states,
    middleZones,
    primaryZones,
    secondaryZones,
    regionsOptions,
    primaryZonesOptions,
    secondaryZonesOptions,
    handleChangePrimaryZones,
    handleChangeRegion,
    handleChangeState,
    handleChangeSecondaryZones,
    optionLabelByValue,
    checkOptionExists,
    setError,
  } = ZonesHandler(props)

  useImperativeHandle(ref, () => ({
    handleSaveZones(id: number) {
      const body: Array<any> = []
      const selectedPrimaryZones = primaryZones
        ? zones.filter((zone) => checkOptionExists(primaryZones, zone.cdCity))
        : []
      const selectedSecondaryZones = secondaryZones
        ? zones.filter((zone) => checkOptionExists(secondaryZones, zone.cdCity))
        : []

      selectedPrimaryZones.forEach((zone) => {
        const stateNm = optionLabelByValue(states, zone.cdState)
        const servcIntrmdrNm = optionLabelByValue(middleZones, zone.microRegionId)

        body.push({
          stateCd: zone.cdState,
          stateNm,
          servcIntrmdrCd: zone.microRegionId,
          servcIntrmdrNm,
          servcRgnCd: zone.cdCity,
          servcRgnNm: zone.nmCity,
          primaryInd: 1,
        })
      })

      selectedSecondaryZones.forEach((zone) => {
        const stateNm = optionLabelByValue(states, zone.cdState)
        const servcIntrmdrNm = optionLabelByValue(middleZones, zone.microRegionId)

        body.push({
          stateCd: zone.cdState,
          stateNm,
          servcIntrmdrCd: zone.microRegionId,
          servcIntrmdrNm,
          servcRgnCd: zone.cdCity,
          servcRgnNm: zone.nmCity,
          primaryInd: 0,
        })
      })

      return saveProviderZones(id || servcPrvdrId, body)
    },
    validate() {
      if (states?.length) {
        if (!middleZones?.length) {
          setError({ middleZones: i18next.t('serviceProvider.regions.error.middlezones') })
          renderToastError(i18next.t('default.field.validation.error'))
          return false
        }

        if (!primaryZones?.length) {
          setError({ primaryZones: i18next.t('serviceProvider.regions.error.primaryzones') })
          renderToastError(i18next.t('default.field.validation.error'))
          return false
        }
      }
      return true
    },
  }))

  const primarySelectDisabled = !middleZones?.length
  const secondarySelectDisabled = !primaryZones?.length

  return (
    <Row className='margin-bottom-small'>
      <Col xs={12}>
        <Col xs={6}>
          <SelectStates value={states} onChange={handleChangeState} disabled={viewMode} />
        </Col>
        <Col xs={6}>
          <SelectMiddleZones
            options={regionsOptions}
            value={middleZones}
            onChange={handleChangeRegion}
            error={errors.middleZones}
            disabled={!states?.length || viewMode}
          />
        </Col>
        <Col xs={6}>
          <SelectZonesPrimary
            zones={primaryZonesOptions}
            value={primaryZones}
            onChange={handleChangePrimaryZones}
            error={errors.primaryZones}
            disabled={primarySelectDisabled || viewMode}
          />
        </Col>
        <Col xs={6}>
          <SelectZonesSecondary
            zones={secondaryZonesOptions}
            value={secondaryZones}
            onChange={handleChangeSecondaryZones}
            error={errors.secondaryZones}
            disabled={secondarySelectDisabled || viewMode}
          />
        </Col>
      </Col>
    </Row>
  )
})

export default MacroRegionalitySection
