import i18next from 'i18next'

import MozaicBadge from '../../../../../../../../components/MozaicBadge'
import { TagsType } from '../../../../../../../../models/Project'

function Tags({ loadingTags, tags }: TagsType) {
  if (loadingTags)
    return (
      <div id='project-tags'>
        <div className='order-tag-skeleton skeleton' />
        <div className='order-tag-skeleton skeleton' />
        <div className='order-tag-skeleton skeleton' />
      </div>
    )

  if (tags?.length === 0) return <></>

  return (
    <div id='project-tags'>
      {tags.map((tag, key) => (
        <MozaicBadge text={i18next.t(tag.labelKey)} key={`tag-${key}`} theme={tag.style} />
      ))}
    </div>
  )
}

export default Tags
