import PropTypes from 'prop-types'

function ServiceOrderListDocuments(props) {
  const { values, t } = props

  const renderRows = (document, index) => (
    <tr key={index}>
      <td className='align-left'>{document.numDocVendaVa}</td>
      <td className='align-left'>{document.numDocVendaSap}</td>
      <td className='align-left'>{document.numDocNfse}</td>
    </tr>
  )

  return (
    <div>
      <legend className='margin-bottom-small'>{t('serviceOrders.documents')}</legend>

      <table className='table table-zebra margin-top-double table-layout-fixed'>
        <thead>
          <tr>
            <th className='align-left'>{t('serviceOrders.numVaDocument')}</th>
            <th className='align-left'>{t('serviceOrders.numSapDocument')}</th>
            <th className='align-left'>{t('serviceOrders.numNfseDocument')}</th>
          </tr>
        </thead>
        <tbody>{values.map(renderRows)}</tbody>
      </table>
    </div>
  )
}

ServiceOrderListDocuments.propTypes = {
  values: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}

export default ServiceOrderListDocuments
