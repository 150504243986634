import moment from 'moment-timezone'

export function decimalValue(value) {
  const str = Number(value || 0).toFixed(2)
  const newValue = str?.toString().replace('.', ',')
  return newValue
}

export const monthAndYearDate = (date) => (!date ? '-' : moment(date).format('MM/YYYY'))

export const yearMonthDay = (date) => (!date ? '-' : moment(date).format('YYYY-MM-DD'))

export function getHourAndMinutes(date) {
  const dateFromTime = moment(date)

  return !date ? '-' : dateFromTime.format('HH:mm')
}

export function getDayAndMonth(date) {
  return !date ? '-' : moment(date).locale('pt-br').format('DD/MM')
}

export function unitValueText(number) {
  const newDecimalValue = decimalValue(number)
  return `${newDecimalValue} un.`
}

export const buildDateWithTime = (date) =>
  new Date(date)
    ?.toLocaleString()
    ?.replace(/[,PMAM]/g, '')
    ?.trim()

export const formatToTitleCase = (value) => {
  let formattedValue = ''
  value
    .toLowerCase()
    .split(' ')
    .forEach((word) => (formattedValue += `${word.charAt(0).toUpperCase()}${word.slice(1)}${' '}`))
  return formattedValue.trim()
}
