import { useEffect } from 'react'
import PropTypes from 'prop-types'
import API_URL from '../../core/Envs/endpoints'
import logo from '../../assets/icons/logo.svg'
import MozaicButton from '../../components/MozaicButton'
import { withTranslation } from 'react-i18next'

import { UPDATE_CURRENT_ROUTE } from '../../redux/actionTypes/menu'
import store from '../../redux/store'

function PingOneRedirect(props) {
  const { t } = props

  const redirectUser = () => {
    window.location.href = API_URL.LOGIN_PINGONE
  }

  useEffect(() => {
    store.dispatch({ type: UPDATE_CURRENT_ROUTE, payload: '/login' })
  }, [])

  return (
    <main id='ping-one-login-container'>
      <section className='box'>
        <header className='banner'>
          <span className='title'>{t('Boas-vindas')}</span>
          <span className='subtitle'>{t('app.name.services-portal')}</span>
        </header>

        <section className='body'>
          <div className='img-logos'>
            <img className='image' src={logo} alt='' />
          </div>
          <MozaicButton onClick={() => redirectUser()}>{t('loginActivity.enter')}</MozaicButton>
        </section>
      </section>
    </main>
  )
}

PingOneRedirect.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(PingOneRedirect)
