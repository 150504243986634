import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { downloadTechReport } from '../../redux/actions/serviceOrder'
import store from '../../redux/store'

function DownloadFiles({ match }) {
  const { servcOrdCd } = match.params
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [fileName, setFileName] = useState(servcOrdCd)

  const Download = () => {
    let fileNameWithExt = ''
    downloadTechReport(store.dispatch, servcOrdCd)
      .then((resp) => {
        const contentDispositionHeader = resp.headers.get('content-disposition')
        if (contentDispositionHeader) {
          fileNameWithExt = contentDispositionHeader.split(';')[1].split('=')[1].replaceAll('"', '')

          setFileName(fileNameWithExt)
        }

        return resp.blob()
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(resp)
        const link = document.createElement('a')

        link.href = url
        link.download = fileNameWithExt

        document.body.appendChild(link)

        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        )

        document.body.removeChild(link)
        setUrl(url)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!url && loading) Download()
  }, [])

  return (
    <div className='download-techreport-files'>
      <BreadcrumbBar title={i18next.t('page.laudo.title')} />
      <div className='body-files'>
        {loading ? (
          <>
            <div className='loading-text'>{i18next.t('serviceOrder.download.wait')}</div>
            <a className='link' onClick={Download}>
              {i18next.t('serviceOrder.download.handle')}
            </a>
          </>
        ) : (
          <a className='link' href={url} download={fileName}>
            {i18next.t('serviceOrder.download.again')}
          </a>
        )}
      </div>
    </div>
  )
}

DownloadFiles.propTypes = {
  match: PropTypes.object,
}

export default DownloadFiles
