import { Button } from '@mozaic-ds/react'
import i18next from 'i18next'
import history from '../../helpers/history'

import './index.scss'

export interface BreadcrumbBarButton {
  label: string
  onClick: () => void
  theme?: 'neutral' | 'primary' | 'primary-02' | 'danger'
  variant?: 'solid' | 'bordered'
}

interface BreadcrumbBarProps {
  readonly title: string
  readonly goBack?: () => void
  readonly buttons?: BreadcrumbBarButton[]
}

/**
 * @description reusable component to show title page and go back button
 */

function BreadcrumbBar({ title, goBack, buttons }: BreadcrumbBarProps) {
  function handleGoBack() {
    if (typeof goBack === 'function') goBack()
    else history.goBack()
  }

  return (
    <div id='breadcrumb-bar'>
      {goBack ? (
        <button className='back-button' onClick={handleGoBack} onKeyDown={handleGoBack}>
          {' '}
          <span className='arrow-back'>
            <svg
              width='4'
              height='8'
              viewBox='0 0 4 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.94879 0.483429L1.85282 3.82171C1.7868 3.928 1.7868 4.072 1.85282 4.17829L3.93303 7.51771C4.05817 7.71886 3.93401 8 3.72116 8H2.56527C2.48151 8 2.40268 7.95314 2.35242 7.87429L0.0495169 4.17829C-0.0165057 4.072 -0.0165057 3.928 0.0495169 3.82171L2.35242 0.126857C2.40268 0.0468571 2.48151 0 2.56527 0L3.73693 0C3.95175 0 4.07591 0.282286 3.94879 0.483429Z'
                fill='#554F52'
              />
            </svg>
          </span>
          <span className='go-back'>{i18next.t('back.text')}</span>
          <span className='dot'>
            <svg
              width='4'
              height='4'
              viewBox='0 0 4 4'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='2' cy='2' r='2' fill='#554F52' />
            </svg>
          </span>{' '}
        </button>
      ) : null}

      <span className='title'>{title}</span>

      {buttons && (
        <div className='buttons'>
          {buttons?.map((button) => (
            <Button
              key={button.label}
              theme={button.theme}
              variant={button.variant}
              className='buttons-item'
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export default BreadcrumbBar
