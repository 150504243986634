import { memo, FormEvent, useEffect, useState } from 'react'

import { TextField } from '@leroy-merlin-br/backyard-react'
import MozaicIcon from '../MozaicIcon'

interface SearchFieldProps {
  onSearch: (value: string) => void
  onChange: (value: string) => void
  placeholder: string
  label: string
  value: string
}

function SearchField({
  value,
  onSearch = () => {},
  onChange = () => {},
  ...rest
}: SearchFieldProps) {
  const [search, setValue] = useState(value)

  function handleChange(event: FormEvent<HTMLInputElement>) {
    setValue((event.target as HTMLInputElement).value)
    onChange((event.target as HTMLInputElement).value)
  }

  function handleClearSearchedText() {
    setValue('')
    onChange('')
  }

  useEffect(() => {
    const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
      if (event.key === 'Enter') {
        event.preventDefault()

        onSearch(value)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [search])

  const searchIcon = () => (
    <div className='search-icon' onClick={() => onSearch(search)}>
      <MozaicIcon icon='DisplaySearch24' />
    </div>
  )

  const suffixIcons = () => (
    <>
      {search !== '' && (
        <div className='clear-filter' onClick={() => handleClearSearchedText()}>
          <MozaicIcon icon='ControlCircleCross24' />
        </div>
      )}
    </>
  )

  return (
    <div id='search-field-container'>
      <div className='max-mobile mx-auto'>
        <form>
          <TextField
            value={value}
            hasPrefix
            hasSuffix
            renderSuffix={suffixIcons}
            renderPrefix={searchIcon}
            autoComplete='off'
            type='search'
            onChange={(event) => handleChange(event)}
            {...rest}
          />
        </form>
      </div>
    </div>
  )
}

export default memo(SearchField)
