import i18next from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import User from '../../../../../../../core/User'

import { getServiceOrderAttachments } from '../../../../../../../services/pages/ServiceOrderDetail/GetServiceOrderAttachments'
import { useServiceOrderDetailContext } from '../../../../../context'

import Accordeon from '../../../../../../../components/Accordeon'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import OutlineButton from '../../../../../../../components/OutlineButton'
import Photo, {
  getExtension,
  getImage,
  getPhotoCreatedAt,
  getPhotoTitle,
  getPhotoUser,
  PhotoType,
} from '../../../../../../../components/Photo'
import AttachmentUploaderModal from '../AttachmentUploaderModal'
import LightBox from '../LightBox'

export default function Photos() {
  const { servcOrdCd } = useServiceOrderDetailContext()

  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const [photos, setPhotos] = useState<PhotoType[]>([])
  const [photosAlreadyLoaded, setPhotosAlreadyLoaded] = useState(false)

  const [lightBoxPhotoIndex, setLightBoxPhotoIndex] = useState<number | null>(null)
  const [showAttachmentUploaderModal, setShowAttachmentUploaderModal] = useState(false)

  const getPhotos = async () => {
    setPhotosAlreadyLoaded(false)
    setPhotos([])

    const photoList = await getServiceOrderAttachments(servcOrdCd, 'img')

    setPhotos(photoList)
    setPhotosAlreadyLoaded(true)
  }

  useEffect(() => {
    if (servcOrdCd && !photos.length) getPhotos()
  }, [servcOrdCd])

  const handleCloseLightBox = () => {
    setLightBoxPhotoIndex(null)
  }

  const handleCloseAttachmentUploaderModal = (attachmentIsUploaded?: boolean) => {
    attachmentIsUploaded && getPhotos()
    setShowAttachmentUploaderModal(false)
  }

  const parsedPhotos = useMemo(
    () =>
      photos.map((p) => ({
        src: getImage(p),
        title: getPhotoTitle(p),
        createdAt: getPhotoCreatedAt(p),
        user: getPhotoUser(p),
        type: getExtension(p) as 'img' | 'pdf',
      })),
    [photos],
  )

  const accordeonProps = {
    header: {
      title: i18next.t('serviceOrder.attachments.photos.photos'),
      icon: <MozaicIcon icon='Imagealt32' ml={-3} mt={1} />,
    },
  }

  const canShowEmptyPhotosList = !photos.length && photosAlreadyLoaded
  const hasAccess75 = accessFunctionList?.includes(75)
  const canShowLightBox = !!photos.length && lightBoxPhotoIndex !== null

  return (
    <div id='attachments-photos'>
      <Accordeon {...accordeonProps}>
        {!!photos.length && (
          <div className='photos'>
            {photos.map((photo, i) => (
              <Photo photo={photo} i={i} onView={setLightBoxPhotoIndex} />
            ))}
          </div>
        )}

        {canShowEmptyPhotosList && (
          <div id='attachments-photos'>
            <div className='photos-empty'>
              {i18next.t('serviceOrder.attachments.photos.emptyPhotoList')}
            </div>
          </div>
        )}

        {!photosAlreadyLoaded && (
          <div className='skeleton-box'>
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className='evaluation-item'>
                <div className='skeleton photo' />
                <div
                  className='skeleton description'
                  style={{ width: `${20 + Math.random() * 80}%` }}
                />
                <div className='skeleton sub' style={{ width: `${20 + Math.random() * 80}%` }} />
              </div>
            ))}
          </div>
        )}

        {hasAccess75 && (
          <OutlineButton
            label={i18next.t('serviceOrder.attachments.photos.addPhoto')}
            iconNode={<MozaicIcon icon='Camera24' className='icon-actions-custom' />}
            onClick={() => setShowAttachmentUploaderModal(true)}
          />
        )}
      </Accordeon>

      {canShowLightBox && (
        <LightBox
          items={parsedPhotos}
          initialIndex={lightBoxPhotoIndex}
          onClose={handleCloseLightBox}
        />
      )}

      {showAttachmentUploaderModal && (
        <AttachmentUploaderModal
          isOpen={showAttachmentUploaderModal}
          onClose={handleCloseAttachmentUploaderModal}
          fileType='img'
          allowedFileTypes={[
            { mimeType: 'image/jpg', extension: 'jpg' },
            { mimeType: 'image/jpeg', extension: 'jpeg' },
            { mimeType: 'image/png', extension: 'png' },
          ]}
        />
      )}
    </div>
  )
}
