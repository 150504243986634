import i18next from 'i18next'
import MozaicIcon from '../../../../../MozaicIcon'
import { ShowDetailsProps } from '../../../../models'

function ShowDetails(props: ShowDetailsProps) {
  const { isOpen, hideDetails, showDetails, handleClick } = props
  const rotate = isOpen ? 'rotate' : ''

  const detailsText = isOpen ? i18next.t(hideDetails) : i18next.t(showDetails)

  return (
    <div className='show-details' onClick={handleClick}>
      <span>{detailsText}</span>
      <div className={`show-details_svg ${rotate}`}>
        <MozaicIcon icon='ArrowArrowBottom32' />
      </div>
    </div>
  )
}

export default ShowDetails
