import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'

function SaleData({ service }: { service: ServiceExecutionEntity }) {
  const channel = service.slsChnlNm
  const store = service.plntNm

  return (
    <BodySection title={i18next.t('service.exec.sale.data')}>
      <FormField label={i18next.t('so.slsChnlNm.value')} value={channel} />
      <FormField label={i18next.t('bank_statement.store')} value={store} />
    </BodySection>
  )
}

export default SaleData
