import i18next from 'i18next'
import { useEffect, useState } from 'react'
import ShowDetails from '../../../../../../components/Accordeon/components/Header/components/ShowDetails'

import MozaicIcon from '../../../../../../components/MozaicIcon'
import AlertsBox from './components/AlertsBox'
import ClientBox from './components/ClientBox'
import DatesBox from './components/DatesBox'
import FooterButtons from './components/FooterButtons'
import ProviderBox from './components/ProviderBox'
import SellOriginBox from './components/SellOriginBox'
import ServiceInformationBox from './components/ServiceInformationBox'
import SortBox from './components/SortBox'
import { WithContext } from './context/indext'
import { AdvancedSearchModalType } from './models'

function AdvancedSearchModal({
  showAllDetails,
  setShowAllDetails,
  handleClose,
  handleClear,
  handleSubmit,
  show,
}: AdvancedSearchModalType) {
  const hideDetailsLabel = 'advanced.search.hide.all.extra.options'
  const showDetailsLabel = 'advanced.search.show.all.extra.options'

  const title = i18next.t('serviceOrders.searchTitle')

  const handleClickShowDetails = () => setShowAllDetails(!showAllDetails)
  const [transitionClass, setTransitionClass] = useState<string>('to-down')
  const [visibleClass, setVisibleClass] = useState<string>('')

  useEffect(() => {
    if (show) {
      setVisibleClass('visible')
      setTimeout(() => {
        setTransitionClass('to-down')
      }, 301)
    } else {
      setTransitionClass('to-up')
      setTimeout(() => {
        setVisibleClass('')
      }, 301)
    }
  }, [show])

  return (
    <div className={`custom-modal-overlay overflow-hidden ${visibleClass}`}>
      <div
        className={`custom-modal lg advanced-search-modal ${transitionClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className='modal-header'>
          <h3 onClick={handleClickShowDetails}>{title}</h3>
          <div className='modal-header--buttons'>
            <ShowDetails
              isOpen={showAllDetails}
              handleClick={handleClickShowDetails}
              hideDetails={hideDetailsLabel}
              showDetails={showDetailsLabel}
            />
            <button className='modal-close-button' onClick={() => handleClose()}>
              <MozaicIcon icon='ControlCross32' />
            </button>
          </div>
        </header>

        <section className='modal-body'>
          <ClientBox />
          <DatesBox />
          <ServiceInformationBox />
          <SellOriginBox />
          <ProviderBox />
          <AlertsBox />
          <SortBox />
        </section>

        <FooterButtons handleSubmit={handleSubmit} handleClear={handleClear} />
      </div>
    </div>
  )
}

export default WithContext(AdvancedSearchModal)
