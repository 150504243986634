import API_URL from '../core/Envs/endpoints'
import API_REQUEST from '../core/Http/FetchAdapter'

import moment from 'moment-timezone'
import User from '../core/User'
import store from '../redux/store'

const user = new User().currentUser

export async function fetchProjects(projectCd?: string) {
  const url = projectCd ? `${API_URL.PROJECT}?q=${projectCd}` : API_URL.PROJECT

  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(url, {})

    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export type ProjectExecProps = {
  osNumber: string
  description: string
  cancelTs: string | null
  closureTs: string | null
  scheduleTs: string | null
  executionTs: string | null
  completionTs: string | null
  pdvNumber: string
  servcExctnStusCd: string
}

export async function fetchProjectServicesList() {
  // eslint-disable-next-line
  await new Promise((resolve) => setTimeout(resolve, 1000))

  const mockServcExecs: ProjectExecProps[] = [
    {
      osNumber: '123936',
      description: 'Acessório de Banheiro',
      cancelTs: null,
      closureTs: moment().format(),
      scheduleTs: moment().format(),
      executionTs: moment().format(),
      completionTs: moment().format(),
      pdvNumber: '0023143998',
      servcExctnStusCd: '08',
    },
    {
      osNumber: '123936',
      description: 'Instalação Banheira',
      cancelTs: null,
      closureTs: null,
      scheduleTs: moment().format(),
      executionTs: null,
      completionTs: null,
      pdvNumber: '0023143998',
      servcExctnStusCd: '01',
    },
    {
      osNumber: '123936',
      description: 'Instalação Piso Laminado',
      cancelTs: null,
      closureTs: null,
      scheduleTs: moment().format(),
      executionTs: moment().format(),
      completionTs: null,
      pdvNumber: '0023143998',
      servcExctnStusCd: '05',
    },
    {
      osNumber: '123936',
      description: 'Remoção da Banheira',
      cancelTs: null,
      closureTs: null,
      scheduleTs: moment().format(),
      executionTs: moment().format(),
      completionTs: moment().format(),
      pdvNumber: '0023143998',
      servcExctnStusCd: '07',
    },
    {
      osNumber: '123936',
      description: 'Instalação Móvel de Banheiro',
      cancelTs: moment().format(),
      closureTs: null,
      scheduleTs: moment().format(),
      executionTs: null,
      completionTs: null,
      pdvNumber: '0023143998',
      servcExctnStusCd: '09',
    },
  ]

  return mockServcExecs
}
