import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { showSpinner, hideSpinner } from '../redux/actions/spinner'
import store from '../redux/store'

export const pushRequest = async (token, servcPrvdrId, servcPrvdrAgntId, pushText) => {
  store.dispatch(showSpinner())

  const query = {
    servcPrvdrAgntId,
    servcPrvdrId,
    pushText,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(API_URL.PUSH, query)

    return data
  } catch (error) {
    return Promise.reject(error)
  } finally {
    store.dispatch(hideSpinner())
  }
}
