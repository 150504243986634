import { useMemo } from 'react'
import i18next from 'i18next'
import { DataTableFooter, Pagination } from '@mozaic-ds/react'
import { useServiceProvidersListV2Context } from '../../../../contexts'
import { PaginationCounter } from '../../../../../../components/PaginationCounter'

/**
 * @description Renders the footer of the table with the pagination for the service providers list v2
 */

export function Footer() {
  const {
    isFetchServiceProvidersLoading,
    isFetchServiceProvidersError,
    handleFetchServiceProviders,
    filters,
    currentPage,
    pagesTotal,
    total,
  } = useServiceProvidersListV2Context()

  const isPaginationVisible = pagesTotal > 1
  const isPaginationDisabled = isFetchServiceProvidersLoading || isFetchServiceProvidersError

  const options = useMemo(
    () =>
      Array.from({
        length: pagesTotal,
      }).map((_: unknown, index: number) => ({
        value: index + 1,
        label: i18next
          .t('pagination.page.range')
          .replace('{start}', String(index + 1))
          .replace('{end}', String(pagesTotal)),
      })),
    [pagesTotal],
  )

  const handleOnChangePage = (page: number) => {
    handleFetchServiceProviders({
      page,
      fields: filters,
    })
  }

  return (
    <DataTableFooter style={{ flex: 1, justifyContent: 'space-between' }} data-testid='footer'>
      <PaginationCounter total={total} page={currentPage} perPage={10} />
      {isPaginationVisible && (
        <Pagination
          currentPage={currentPage}
          pagesTotal={pagesTotal}
          options={options}
          onChange={handleOnChangePage}
          isDisabled={isPaginationDisabled}
        />
      )}
    </DataTableFooter>
  )
}
