import { useMemo } from 'react'
import { useHistory } from 'react-router'

import usePageSize from '../../../../hooks/usePageSize'

import MozaicAccordeon from '../../../../components/MozaicAccordeon'
import ProjectRowHeader from '../ProjectRowHeader'
import MozaicIcon from '../../../../components/MozaicIcon'
import { Flex } from '@mozaic-ds/react'
import { ProjectEntity } from '../../../../models/Project'

import ServiceRow from '../ServiceRow'
import classNames from 'classnames'
import { fetchProjectServicesList } from '../../../../services/ProjectList'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ProjectRowGhost from '../ProjectRowGhost'

export const firstBreakpoint = 889

export type ProjectRowProps = {
  project: ProjectEntity
}

function ProjectRow({ project }: ProjectRowProps) {
  const { width } = usePageSize()
  const history = useHistory()

  const queryClient = useQueryClient()
  const queryKey = useMemo(() => ['servicesList', project.prjctExctnSeq], [project.prjctExctnSeq])

  const {
    data: services,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () => fetchProjectServicesList(),
    enabled: false,
  })

  function sendToMaps() {
    const {
      latitude,
      longitude,
      servcAddrStrNm,
      servcAddrNr,
      servcAddrDstrctNm,
      servcAddrCityNm,
      servcAddrStCd,
      servcAddrPstlCd,
    } = project

    if (latitude && longitude)
      return window.open(`https://maps.google.com?q=${latitude},${longitude}`)

    return window.open(
      `https://maps.google.com?q=${servcAddrStrNm}, ${servcAddrNr} - ${servcAddrDstrctNm}, ${servcAddrCityNm}-${servcAddrStCd}, ${servcAddrPstlCd}`,
    )
  }

  function sendToDetails() {
    history.push(`/projects/${project.prjctExctnSeq}`)
  }

  function onClose() {
    queryClient.setQueryData(queryKey, [])
  }

  const mobileClass = width < firstBreakpoint

  return (
    <MozaicAccordeon
      scroll={false}
      group='project-row'
      onOpen={refetch}
      onClose={onClose}
      className={classNames('project-row', { 'project-mobile': mobileClass })}
      rIcon={
        <MozaicIcon
          id='gps-icon'
          color='green-backyard'
          icon='MonochromeStoreLocation24'
          size={24}
          mr={45}
          onClick={sendToMaps}
        />
      }
      header={<ProjectRowHeader sendToDetails={sendToDetails} project={project} width={width} />}
    >
      <Flex className='project-row_body' direction='column' gap='mu100'>
        {!isFetching ? (
          services?.length &&
          services.map((service, i) => (
            <ServiceRow service={service} sendToDetails={sendToDetails} orderNumber={`0${i + 1}`} />
          ))
        ) : (
          <ProjectRowGhost mobileClass={mobileClass} />
        )}
      </Flex>
    </MozaicAccordeon>
  )
}

export default ProjectRow
