function SpinnerCircle() {
  const svgProps = {
    className: 'spinner',
    width: '50',
    height: '50',
    viewBox: '0 0 66 66',
    xmlns: 'http://www.w3.org/2000/svg',
  }

  const circleProps = {
    className: 'path',
    fill: 'none',
    strokeWidth: '6',
    strokeLinecap: 'round',
    cx: '33',
    cy: '33',
    r: '30',
  }

  return (
    <svg {...svgProps}>
      <circle {...circleProps} />
    </svg>
  )
}

export default SpinnerCircle
