import PropTypes from 'prop-types'

import Col from '../../../../components/utils/Col'
import Row from '../../../../components/utils/Row'
import Thumbnail from '../../../../components/utils/images/Thumbnail'
import MozaicIcon from '../../../../components/MozaicIcon'

function List(props) {
  const {
    onDelete,
    downloadFile,
    disabled,
    attachmentId,
    auth: { user },
    dispatch,
    attachments,
    t,
  } = props

  const hasPermissionToModifyDocuments =
    user.accessFunctionList &&
    !user?.accessFunctionList?.includes(75) &&
    user?.accessFunctionList?.includes(112)

  const hasPermissionToDownloadDocuments =
    user.accessFunctionList &&
    (user.accessFunctionList.includes(75) || user.accessFunctionList.includes(112))

  const formatDocumentName = (name) => {
    if (!name) {
      return null
    }

    return name
  }

  function renderRows(document, index) {
    return (
      <Row key={document[attachmentId]} className='table-like-row padding-horizontal'>
        <Col lg={2} xs={2} className='table-like-data padding-vertical text-align-center'>
          <Thumbnail
            filePath={document.atchmtCloudShortPathTxt}
            token={user.dsToken}
            dispatch={dispatch}
          />
        </Col>

        <Col lg={4} xs={3} className='table-like-data padding-vertical'>
          <a
            role='link'
            target='_blank'
            rel='noopener'
            onClick={() =>
              hasPermissionToDownloadDocuments &&
              downloadFile(document.atchmtCloudShortPathTxt, document.atchmtCloudShortPathTxt)
            }
          >
            {formatDocumentName(document.servcOrdItemAtchmtDesc)}
          </a>
        </Col>

        <Col xs={3} className='table-like-data padding-vertical'>
          {document.atchmtDocmntTypDesc}
        </Col>

        <Col xs={2} className='table-like-data padding-vertical'>
          {document.creatTs}
        </Col>

        {!disabled && !hasPermissionToModifyDocuments && (
          <Col xs={2} className='table-like-data padding-vertical text-align-center'>
            <a
              id={`edit-document-${index}`}
              role='button'
              onClick={() =>
                onDelete({
                  ...document,
                  servcOrdItemAtchmtClsfctnCd: [document.servcOrdItemAtchmtClsfctnCd],
                })
              }
            >
              <MozaicIcon icon='PublishTrashbin32' color='primary' mr={18} />
            </a>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <div className='table-like table-like-odd'>
      <Row className='padding align-items-center'>
        <Col lg={2} xs={2} className='text-align-center'>
          <h5 className='no-margin strong'>{t('option.image')}</h5>
        </Col>
        <Col lg={4} xs={3}>
          <h5 className='no-margin strong'>{t('option.fileName')}</h5>
        </Col>
        <Col xs={3}>
          <h5 className='no-margin strong'>{t('option.documentType')}</h5>
        </Col>
        <Col xs={2} className='text-align-center'>
          <h5 className='no-margin strong'>{t('option.addDate')}</h5>
        </Col>
        {!disabled && !hasPermissionToModifyDocuments && (
          <Col xs={2} className='table-like-data padding-vertical text-align-center'>
            <h5 className='no-margin strong'>{t('option.delete')}</h5>
          </Col>
        )}
      </Row>

      <Row className='table-like-rows'>{attachments.map(renderRows)}</Row>
    </div>
  )
}

List.propTypes = {
  attachments: PropTypes.array,
  t: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  attachmentId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default List
