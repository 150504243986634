import { useMemo } from 'react'
import i18next from 'i18next'

import FormField from '../../../../../../../../../../../components/FormField'

import { formatDecimal } from '../../../../../../../../../../../helpers/masks'
import { capitalizeString } from '../../../../../../../../../../../helpers/capitalize'
import { decimalValue, unitValueText } from '../../../../../../../../../../../helpers/format'
import { usableArea } from '../../../../../../../../../../../constants/breakpoints'
import { useUsableAreaContext } from '../../../../../../../../../../../contexts/usableArea'
import { ServiceOrderItem } from '../../../../../../../../../context/models'

interface ServiceItemProps {
  service: ServiceOrderItem
  isFetching: boolean
  isCanceled: boolean
  isFinished: boolean
}

function ServiceItem(props: ServiceItemProps) {
  const { service, isFetching, isCanceled, isFinished } = props
  const { widthContainer } = useUsableAreaContext()

  const wasRefunded =
    service.servcOrdItemQtyAcquired > 0 &&
    service.servcOrdItemQtyAcquired === service.servcOrdItemQtyReturned

  const isDashed = isCanceled || wasRefunded

  function renderSubdivisionComponents() {
    return (
      <div className='subdivision'>
        <FormField
          isFetching={isFetching}
          label={i18next.t('service.code')}
          value={service?.servcCd}
          isBold={false}
          isDashed={isDashed}
        />

        <FormField
          isFetching={isFetching}
          label={i18next.t('estimated.time')}
          value={`${service?.slots || 0} slots x ${decimalValue(service?.servcOrdItemQty)}`}
          isBold={false}
          isDashed={isDashed}
        />
      </div>
    )
  }

  const serviceQuantityAcquiredLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? capitalizeString(i18next.t('serviceOrders.acquired'))
        : i18next.t('purchased.quantity.text'),
    [widthContainer],
  )

  const serviceQuantityFulfilledLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? capitalizeString(i18next.t('serviceOrders.fulfilled'))
        : i18next.t('accomplished.quantity.text'),
    [widthContainer],
  )

  const serviceQuantityReturnedLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? capitalizeString(i18next.t('serviceOrders.returned'))
        : i18next.t('returned.quantity.text'),
    [widthContainer],
  )

  const unitSaleValueLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? capitalizeString(i18next.t('serviceOrders.service.unitary'))
        : i18next.t('unit.sale.value.text'),
    [widthContainer],
  )

  const subtotalSaleValueLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? i18next.t('servcOrd.service.group.sub')
        : i18next.t('subtotal.sale.value.text'),
    [widthContainer],
  )

  const unitTransferValueLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? capitalizeString(i18next.t('serviceOrders.service.unitary'))
        : i18next.t('unit.transfer.value'),
    [widthContainer],
  )

  const subtotalTransferValueLabel = useMemo(
    () =>
      widthContainer > usableArea.medium
        ? i18next.t('servcOrd.service.group.sub')
        : i18next.t('subtotal.transfer.amount'),
    [widthContainer],
  )

  const observationValueText = service.servcOrdItemRmrkTxt
    ? service.servcOrdItemRmrkTxt
    : i18next.t('no.has.observations.text')

  const dashStar = isDashed ? '*' : ''
  const servcOrdItemTransfer = `${formatDecimal(service?.servcOrdItemTransfer)}${dashStar}`
  const servcOrdItemTotal = `${formatDecimal(service?.servcOrdItemTotal)}${dashStar}`

  const countExec = service?.servcOrdItemQtyRelzd || 0 + (service.servcOrdItemQtyReturned || 0)
  const lessThanAcquired = countExec < service?.servcOrdItemQtyAcquired
  const fullfilledColor = isFinished && lessThanAcquired ? 'danger' : 'neutral'

  return (
    <div id='service-item-container'>
      <div className='first-col custom'>
        <div className='first-area mobile'>
          <FormField
            isFetching={isFetching}
            label={i18next.t('homepage.tab.services')}
            value={service?.servcFullDesc}
            isBold
            isDashed={isDashed}
          />

          <FormField
            isFetching={isFetching}
            label={i18next.t('serviceOrders.observations')}
            value={observationValueText as string}
            color={service?.servcOrdItemRmrkTxt ? 'neutral' : 'gray'}
            onEditField={() => {}}
          />
          <div className='item-info custom-desk'>{renderSubdivisionComponents()}</div>
        </div>
        <section className='second-area mobile'>
          <div className='item-info custom-mobile'>{renderSubdivisionComponents()}</div>
          <div className='item-info quantities'>
            <FormField
              isFetching={isFetching}
              label={serviceQuantityAcquiredLabel}
              value={unitValueText(service?.servcOrdItemQtyAcquired)}
              isDashed={isDashed}
            />

            <FormField
              isFetching={isFetching}
              label={serviceQuantityFulfilledLabel}
              value={unitValueText(service?.servcOrdItemQtyRelzd)}
              isDashed={isDashed}
              color={fullfilledColor}
            />

            <FormField
              isFetching={isFetching}
              label={serviceQuantityReturnedLabel}
              value={unitValueText(service?.servcOrdItemQtyReturned)}
              disabled={!service?.servcOrdItemQtyReturned}
              isDashed={isDashed}
            />
          </div>
        </section>
      </div>
      <div className='second-col custom'>
        <div className='item-info subtotal'>
          <FormField
            isFetching={isFetching}
            label={unitSaleValueLabel}
            value={formatDecimal(service?.servcOrdItemAmt)}
            isDashed={isDashed}
          />

          <FormField
            isFetching={isFetching}
            label={subtotalSaleValueLabel}
            value={servcOrdItemTotal}
            isBold
            isDashed={isDashed}
          />
        </div>
        <div className='item-info'>
          <FormField
            isFetching={isFetching}
            label={unitTransferValueLabel}
            value={formatDecimal(service?.servcOrdItemPrvdrPymtAmt)}
            isDashed={isDashed}
          />

          <FormField
            isFetching={isFetching}
            label={subtotalTransferValueLabel}
            value={servcOrdItemTransfer}
            isBold
            isDashed={isDashed}
          />

          {isDashed && (
            <span className='advise-after-return'>
              {i18next.t('serviceOrders.adjusted.value.after.return')}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServiceItem
