import PropTypes from 'prop-types'
import { Component, createRef } from 'react'

import Row from '../../../../components/utils/Row'

import { WithForm } from '../../../../helpers/useForm/withForm'
import DocumentForm from './Form'
import ListDocuments from './List'

class Documents extends Component {
  inputRef = createRef()

  componentDidMount() {
    const { initialValues, setFormValue, relationId } = this.props

    if (initialValues[relationId]) {
      setFormValue({ servcOrdSeq: initialValues[relationId] })
    }
  }

  shouldRenderList() {
    const { attachments } = this.props
    return attachments && attachments.length > 0
  }

  onSubmit = (values) => {
    const { relationId } = this.props

    if (values[relationId]) {
      const { onSubmit } = this.props
      return onSubmit(values)
    }
  }

  isSameFile = (attachment) => {
    const {
      values: { files },
      attachmentId,
    } = this.props
    if (files && files.length) {
      if (attachment[attachmentId] === files[0].id) {
        return true
      }
    }
    return false
  }

  resetForm = () => {
    const { setFormValue } = this.props

    setFormValue({
      files: null,
      type: '',
    })

    this.resetInputFile()
  }

  resetInputFile = () => {
    const inputRef = this.inputRef.current

    if (inputRef) {
      inputRef.value = ''
      inputRef.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  render() {
    const {
      t,
      attachments,
      closeModal,
      error,
      downloadFile,
      disabled,
      setFormValue,
      fields,
      values,
      relationId,
      register,
      attachmentId,
      auth,
      dispatch,
      servcOrdSeq,
      onDelete,
      auth: { user },
    } = this.props

    const hasPermissionToAddDocuments =
      user.accessFunctionList &&
      !user.accessFunctionList?.includes(75) &&
      user.accessFunctionList?.includes(112)

    return (
      <div>
        <Row className='padding lmi-modal-header'>
          <span>{t('option.manageDocuments')}</span>
        </Row>

        {this.shouldRenderList() ? (
          <ListDocuments
            t={t}
            attachments={attachments}
            onDelete={onDelete}
            downloadFile={downloadFile}
            attachmentId={attachmentId}
            auth={auth}
            dispatch={dispatch}
          />
        ) : (
          <div>
            <h3 className='align-center'>{t('service-orders-manage-documents-list.not-found')}</h3>
          </div>
        )}

        {!disabled && !hasPermissionToAddDocuments && (
          <DocumentForm
            t={t}
            register={register}
            onSubmit={this.onSubmit}
            closeModal={closeModal}
            error={error}
            setFormValue={setFormValue}
            fields={fields}
            values={values}
            resetForm={this.resetForm}
            inputRef={this.inputRef}
            relationId={relationId}
            auth={auth}
            dispatch={dispatch}
            servcOrdSeq={servcOrdSeq}
          />
        )}
      </div>
    )
  }
}

Documents.propTypes = {
  t: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  error: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setFormValue: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  servcOrdSeq: PropTypes.number.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  attachmentId: PropTypes.string.isRequired,
  relationId: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
}

const FIELDS = { servcOrdSeq: '', files: null, type: '' }

export default WithForm(FIELDS)(Documents)

export { Documents }
