import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../../../../context'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      orderDetails,
      serviceOrderBody,
      loadingBody,
      loadingDetails,
      reloadServiceOrder,
      occurrences,
    } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        orderDetails,
        serviceOrderBody,
        loadingBody,
        loadingDetails,
        reloadServiceOrder,
        occurrences,
      }),
      [orderDetails, serviceOrderBody, loadingBody, loadingDetails, occurrences],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
