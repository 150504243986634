import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../contexts/advancedSearch'
import { AdvancedSearchModalType } from '../models'

export function WithContext(Component: FunctionComponent<AdvancedSearchModalType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, setShowAllDetails },
      handleClose,
      handleClear,
      handleSubmit,
      show,
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        showAllDetails,
        setShowAllDetails,
        handleClose,
        handleClear,
        handleSubmit,
        show,
      }),
      [show, handleSubmit],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
