import { InfoCircle } from '@leroy-merlin-br/backyard-icons'
import { Tooltip, TooltipProps } from '@leroy-merlin-br/backyard-react'

import './index.scss'

interface PopoverInfoProps {
  title: string
  position: TooltipProps['placement']
  content: string
  iconWidth: number
  iconHeight: number
  iconColor: string
}

/**
 * @description Reusable component to show popover with message
 */

function PopoverInfo({
  title,
  position,
  content,
  iconWidth,
  iconHeight,
  iconColor,
}: PopoverInfoProps) {
  return (
    <div id='custom-popover' title={title}>
      <Tooltip content={content} placement={position}>
        <span>
          <InfoCircle color={iconColor} width={iconWidth} height={iconHeight} />
        </span>
      </Tooltip>
    </div>
  )
}
export default PopoverInfo
