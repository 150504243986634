import i18next from 'i18next'
import { Text } from '@mozaic-ds/react'

interface PaginationCounterProps {
  total: number
  page: number
  perPage: number
}

export function PaginationCounter({ total, page, perPage }: PaginationCounterProps) {
  const start = (page - 1) * perPage + 1
  let end = page * perPage
  if (end > total) end = total

  const itemsTranslationKey = total > 1 ? 'serviceOrdersAdapter.items' : 'serviceOrdersAdapter.item'

  return (
    <div id='pagination-counter'>
      {total > 0 && (
        <Text weight='semi-bold' theme='primary-02' size='s'>{`${start} - ${end} ${i18next.t(
          'of',
        )} ${total} ${i18next.t(itemsTranslationKey)}`}</Text>
      )}
    </div>
  )
}
