import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

/*
   Description component: reusable component simulate a shimmer loading on containers, divs, sections
*/

const shimmer = keyframes`
0% {
  background-position: 0% 0%;
}
100% {
  background-position: -200% 0%;
}`

const Skeleton = styled.section((props) => ({
  height: props.height || 'auto',
  width: props.width || 'auto',
  padding: props.padding,
  margin: props.margin,
  marginTop: props.marginTop,
  marginBottom: props.marginBottom,
  borderRadius: props.borderRadius,
  boxShadow: 'none',
  backgroundSize: '400% 400%',
  animation: `${shimmer} 1.2s linear infinite`,
  backgroundImage: `linear-gradient(-90deg, ${props.backgroundColor || '#ebebeb'} 0%,#f8f8f8, ${
    props.backgroundColor || '#ebebeb'
  } 100%)`,
}))

export default Skeleton
