import { HIDE_SPINNER, SHOW_SPINNER } from '../../actionTypes/spinner'

const DEFAULT_STATE = {
  visible: false,
}

const spinner = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return { ...state, visible: true }

    case HIDE_SPINNER:
      return { ...state, visible: false }

    default:
      return state
  }
}

export default spinner
