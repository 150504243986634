import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'

import ServcOrdCategoryType from '../../../../../../enums/ServiceOrder/category/serviceCategoryType'

function CategoryInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  const categoryName = ServcOrdCategoryType.getCatgryTypDesc(
    serviceOrderBody?.servcCatgryTypCd,
  ).toString()

  if (loadingBody) return <div id='category-info' className='skeleton' />

  return (
    <div id='category-info'>
      <label htmlFor=''>{i18next.t('servcCatgry.header.category')}</label>
      <section>
        <span className='category-name-text'>{i18next.t(categoryName) || '-'}</span>
      </section>
    </div>
  )
}

export default CategoryInfo
