import classNames from 'classnames'
import PropTypes from 'prop-types'
import Mask from 'vanilla-masker'
import { toPatternMultiple } from '../../../helpers/masks'

import './index.scss'

function Input(props) {
  const {
    id,
    name,
    onChange,
    type = 'text',
    mask,
    label,
    className,
    labelClassName = '',
    clearable,
    value,
    disableMultipleMask = false,
    refreshFunction,
    clearTrigger,
    allowEmpty = false,
    topLabel,
    topLabelClassName,
    valid,
    placeholder,
    ...otherProps
  } = props

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    if (mask && value) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = disableMultipleMask
        ? Mask.toPattern(value, mask)
        : toPatternMultiple(value, mask)
    }

    if (allowEmpty && !value) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = ' '
    }

    onChange(event)
  }

  const clearField = (event) => {
    event.preventDefault()

    handleChange({ target: { name, value: '' } })

    if (clearTrigger) {
      refreshFunction()
    }
  }

  const inputClass = classNames({ input: type !== 'radio' && type !== 'checkbox' }, className)

  const getId = () => id || name

  const classLabelDisabled =
    className === 'inputDisabled' && otherProps.disabled ? 'disable-label' : ''

  return (
    <div>
      {topLabel && (
        <label htmlFor={getId()} className={`top-label ${topLabelClassName} ${classLabelDisabled}`}>
          {topLabel}
        </label>
      )}

      <input
        type={type}
        style={{
          borderColor: (valid === true && 'green') || (valid === false && 'red') || '',
        }}
        id={getId()}
        name={name}
        className={inputClass}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        {...otherProps}
      />

      {label && (
        <label htmlFor={getId()} className={`label ${labelClassName}`}>
          {label}
        </label>
      )}

      {clearable && value && (
        <strong className='clearable-input-text' onClick={(event) => clearField(event)}>
          &times;
        </strong>
      )}
    </div>
  )
}

Input.propTypes = {
  clearable: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  mask: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.string,
  disableMultipleMask: PropTypes.bool,
  refreshFunction: PropTypes.func,
  clearTrigger: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  topLabel: PropTypes.string,
  valid: PropTypes.bool,
  topLabelClassName: PropTypes.string,
  placeholder: PropTypes.string,
}

export default Input
