import i18next from 'i18next'

import Accordeon from '../../../../../../../../components/Accordeon'
import DateSelectGroup from './components/DateSelectGroup'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { DatesBoxType } from '../../models/dates'
import { WithContext } from './context'

function DatesBox(props: DatesBoxType) {
  const {
    showAllDetails,
    handleToggle,
    createStartDate,
    setCreateStartDate,
    createEndDate,
    setCreateEndDate,
    scheduleStartDate,
    setScheduleStartDate,
    scheduleEndDate,
    setScheduleEndDate,
    executeStartDate,
    setExecuteStartDate,
    executeEndDate,
    setExecuteEndDate,
    finallyStartDate,
    setFinallyStartDate,
    finallyEndDate,
    setFinallyEndDate,
  } = props

  const clientDataTitle = {
    icon: <MozaicIcon icon='DisplayCalendar24' className='icon-custom' />,
    title: 'advanced.search.dates.title',
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  const createGroupProps = {
    startId: 'createStartDateId',
    startDate: createStartDate,
    setStartDate: setCreateStartDate,
    endId: 'createEndDateId',
    endDate: createEndDate,
    setEndDate: setCreateEndDate,
    label: i18next.t('serviceOrders.creationDateSimple'),
  }

  const scheduleGroupProps = {
    startId: 'scheduleStartDateId',
    startDate: scheduleStartDate,
    setStartDate: setScheduleStartDate,
    endId: 'scheduleEndDateId',
    endDate: scheduleEndDate,
    setEndDate: setScheduleEndDate,
    label: i18next.t('customer.service.order.list.title.scheduling.date'),
  }

  const executeGroupProps = {
    startId: 'executeStartDateId',
    startDate: executeStartDate,
    setStartDate: setExecuteStartDate,
    endId: 'executeEndDateId',
    endDate: executeEndDate,
    setEndDate: setExecuteEndDate,
    label: i18next.t('serviceOrderDetail.execution'),
  }

  const finallyGroupProps = {
    startId: 'finallyStartDateId',
    startDate: finallyStartDate,
    setStartDate: setFinallyStartDate,
    endId: 'finallyEndDateId',
    endDate: finallyEndDate,
    setEndDate: setFinallyEndDate,
    label: i18next.t('advanced.search.dates.finally'),
  }

  const alwaysVisibleContent = (
    <div className='date-inputs'>
      <div className='width-50'>
        <DateSelectGroup {...createGroupProps} />
      </div>
      <div className='width-50'>
        <DateSelectGroup {...scheduleGroupProps} />
      </div>
    </div>
  )

  return (
    <div className='dates-box'>
      <Accordeon
        header={clientDataTitle}
        alwaysVisibleContent={alwaysVisibleContent}
        styled={false}
        onToggle={handleToggle}
        defaultOpen={showAllDetails}
      >
        <div className='date-inputs mt-3'>
          <div className='width-50'>
            <DateSelectGroup {...executeGroupProps} />
          </div>
          <div className='width-50'>
            <DateSelectGroup {...finallyGroupProps} />
          </div>
        </div>
      </Accordeon>
    </div>
  )
}

export default WithContext(DatesBox)
