import PropTypes from 'prop-types'

function ContentHeader({ children, style }) {
  return (
    <div className='content-header' style={style}>
      <span className='header-title'>{children}</span>
    </div>
  )
}

ContentHeader.propTypes = {
  children: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default ContentHeader
