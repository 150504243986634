import { ReactNode } from 'react'
import { Heading, IHeadingProps } from '@mozaic-ds/react'

interface MozaicHeadingProps extends IHeadingProps {
  children: ReactNode
}

function MozaicHeading(props: MozaicHeadingProps) {
  const { children, ...otherProps } = props

  return <Heading {...otherProps}>{children}</Heading>
}

export default MozaicHeading
