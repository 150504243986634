import _, { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import MozaicHeading from '../../../components/MozaicHeading'
import MozaicText from '../../../components/MozaicText'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Form from '../../../components/utils/Form'
import Input from '../../../components/utils/Input'
import InputButton from '../../../components/utils/InputButton'
import Row from '../../../components/utils/Row'
import Select from '../../../components/utils/Select'
import TelephoneInput from '../../../components/utils/internationalPhoneInput/phoneInput'
import {
  ACTV_SERVC_PRVDR_IND_TYPES,
  ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES,
} from '../../../constants/config'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import {
  getPhoneNumberCountry,
  validatePhoneNumber,
} from '../../../helpers/InternationalPhoneNumberUtils'
import { getNumbersOnly } from '../../../helpers/Toolbox'
import { getCEPMask, getCNPJMask, removeMask } from '../../../helpers/masks'
import { hideSpinner, showSpinner } from '../../../redux/actions/spinner'
import store from '../../../redux/store'
import { AccountTypeSelect } from './AccountTypeSelect'
import BankSelect from './BankSelect'
import BranchSelectMultiple from './BranchSelectMultiple'
import MacroRegionalitySection from './MacroRegionalitySection'
import SpecialitiesSelect from './SpecialitiesSelect'

import './EditForm.scss'

class ServiceProviderEditForm extends Component {
  constructor(props) {
    super(props)
    this.MacroRegionalitySection = createRef()
    this.SpecialitiesSelectRef = createRef()
  }

  state = {
    lsPhones: [
      {
        valMeioComunicacao: '',
        idTipoMeioComunicacao: 1,
      },
      {
        valMeioComunicacao: '',
        idTipoMeioComunicacao: 3,
      },
      {
        valMeioComunicacao: '',
        idTipoMeioComunicacao: 4,
      },
    ],
    address: {},
    lsBranchJustification: null,
    actvServcPrvdr: '0',
    didLoad: false,
    disabledForm: false,
    disableSwitchInput: false,
  }

  lastAddressValue = ''

  componentDidMount() {
    this.checkInitialValues()
    this.checkInitialPhones()
  }

  componentDidUpdate(prevProps) {
    const { initialValues } = this.props
    const {
      initialValues: { lsPhones },
    } = prevProps

    this.checkInitialPhones(lsPhones)

    if (
      initialValues.servcPrvdrNm &&
      initialValues.servcPrvdrNm !== prevProps.initialValues.servcPrvdrNm
    ) {
      this.checkInitialValues()
    }
  }

  checkInitialPhones(previousLsPhones = null) {
    const {
      initialValues: { lsPhones },
    } = this.props
    if (!previousLsPhones || (previousLsPhones && !isEqual(lsPhones, previousLsPhones))) {
      this.setState({ lsPhones })
    }
  }

  checkInitialValues() {
    const { initialValues, setValueToField } = this.props

    Object.entries(initialValues).forEach(([key, value]) => {
      setValueToField(key, value)
    })

    this.setState({ address: initialValues.address })

    this.handleDisableFields()
  }

  handleDisableFields() {
    const { initialValues } = this.props
    const { servcPrvdrAddRgstrnCurrntStepNr, identifyRegistrationOrigin } = initialValues

    const rgstrtnPortal = identifyRegistrationOrigin === 1

    if (rgstrtnPortal) {
      const disableEdit =
        servcPrvdrAddRgstrnCurrntStepNr < 8 || servcPrvdrAddRgstrnCurrntStepNr > 11
      const disableSwitchs =
        servcPrvdrAddRgstrnCurrntStepNr >= 1 && servcPrvdrAddRgstrnCurrntStepNr <= 7

      if (disableEdit) {
        this.setState({
          disabledForm: true,
        })
      }

      if (disableSwitchs) {
        this.setState({
          disableSwitchInput: true,
        })
      }
    }
  }

  get fields() {
    const { fields } = this.props

    const newObj = {}

    Object.entries(fields).forEach(([key, value]) => {
      newObj[key] = value
    })

    return newObj
  }

  handleChange = async (event) => {
    const { name, value } = event.target

    const { setValueToField, values, resetError } = this.props
    const { address, actvServcPrvdr } = this.state

    this.fields[name].input.onChange({ target: { value } })
    resetError(name)

    if (address.hasOwnProperty(name)) {
      address[name] = value
      this.setState({ address })
    }

    if (name === 'actvServcPrvdrInd') {
      const actvServcPrvdrStatus = actvServcPrvdr === '1' ? '0' : '1'
      this.setState({ actvServcPrvdr: actvServcPrvdrStatus })
    }

    if (name === 'actvServcPrvdrOrdRecvInd') {
      setValueToField(
        'actvServcPrvdrOrdRecvInd',
        values.actvServcPrvdrOrdRecvInd === '1' ? '0' : '1',
      )
    }
  }

  handlePhoneNumberChange = (fieldName, value) => {
    /**
     * Set the given value to the specified phone number field
     */
    const { lsPhones } = this.state
    const { t, resetError } = this.props

    switch (fieldName) {
      case 'phone1':
        if (lsPhones[0] === undefined) lsPhones[0] = {}
        lsPhones[0].valMeioComunicacao = value || ''
        lsPhones[0].idTipoMeioComunicacao = 1
        this.fields.phone1.error =
          !validatePhoneNumber(value) || !value ? t('error.customer.invalid.phone') : null
        break

      case 'phone2':
        if (lsPhones[1] === undefined) lsPhones[1] = {}
        lsPhones[1].valMeioComunicacao = value || ''
        lsPhones[1].idTipoMeioComunicacao = 3
        this.fields.phone2.error =
          value && !validatePhoneNumber(value) ? t('error.customer.invalid.phone') : null
        break

      case 'phone3':
        if (lsPhones[2] === undefined) lsPhones[2] = {}
        lsPhones[2].valMeioComunicacao = value || ''
        lsPhones[2].idTipoMeioComunicacao = 4
        this.fields.phone3.error =
          value && !validatePhoneNumber(value) ? t('error.customer.invalid.phone') : null
        break
    }

    this.setState({ lsPhones })
    this.fields[fieldName].input.onChange({ target: { value } })
    resetError(fieldName)
  }

  handleSelectChange = (selected, name) => {
    const { resetError } = this.props
    const { value } = selected

    // eslint-disable-next-line no-param-reassign
    selected = selected || { value: null }

    this.fields[name].input.onChange({ target: { value } })
    resetError(name)
  }

  onFinishEdit = () => {
    const { history } = this.props

    const hasHistoryFromState = history.location.state && history.location.state.from

    if (hasHistoryFromState) {
      history.push(history.location.state.from)
    } else {
      history.push('/providers')
    }
  }

  handleBeforeSubmit = (event) => {
    const {
      auth: {
        user: { dsToken },
      },
      initialValues,
      fields,
    } = this.props

    event.preventDefault()

    if (!!initialValues.lsBranch) {
      const changedBranches = this.diffList(
        fields.lsBranch.input.value || [],
        initialValues.lsBranch,
      )

      if (changedBranches) {
        this.handleOpenJustificationModal('lsBranch')
      } else {
        this.handleSubmit(dsToken)
      }
    } else {
      this.handleSubmit(dsToken)
    }
  }

  handleOpenJustificationModal = (field) => {
    const { openJustificationModal, initialValues } = this.props

    openJustificationModal(
      initialValues.servcPrvdrId,
      { key: field, value: field },
      this.handleJustification,
    )
  }

  handleJustification = (token, justificationValues) => {
    if (justificationValues.hasOwnProperty('lsBranch')) {
      this.setState({lsBranchJustification: justificationValues.description}, () => {
        this.handleSubmit(token)
      })
    }
  }

  diffList = (l1 = [], l2 = []) =>
    l1.some((e1) => !l2.some((e2) => isEqual(e2, e1))) ||
    l2.some((e1) => !l1.some((e2) => isEqual(e2, e1)))

  handleSubmit = (token) => {
    const { initialValues, values, fields, isFormValid, serviceProvider } = this.props

    const { filesToAdd, lsBranchJustification, lsPhones } = this.state

    const allFields = {}
    Object.entries(fields).forEach(([key]) => {
      allFields[key] = fields[key]?.input?.value || ''
    })

    allFields.lsServcPrvdrMainPlntCd = allFields.lsBranch || []

    const addressToArray = [
      {
        descAddress: allFields.cnpjDescAddress,
        descComplAddress: allFields.cnpjDescComplAddress,
        descNeighborhoodAddress: allFields.cnpjDescNeighborhoodAddress,
        descReferAddress: allFields.cnpjDescReferAddress,
        initStateAddress: allFields.cnpjInitStateAddress,
        nameLocaleAddress: allFields.cnpjNameLocaleAddress,
        numAddress: allFields.cnpjNumAddress,
        numCepAddress: allFields.cnpjNumCepAddress,
        servcPrvdrAddrLatNr: allFields.cnpjAddrLatNr,
        servcPrvdrAddrLongNr: allFields.cnpjAddrLongNr,
        servcPrvdrAddressId: initialValues.address.cnpjAddressId,
        servcPrvdrId: initialValues.servcPrvdrId,
      },
    ]

    delete allFields.cnpjDescAddress
    delete allFields.cnpjDescComplAddress
    delete allFields.cnpjDescNeighborhoodAddress
    delete allFields.cnpjDescReferAddress
    delete allFields.cnpjInitStateAddress
    delete allFields.cnpjNameLocaleAddress
    delete allFields.cnpjNumAddress
    delete allFields.cnpjNumCepAddress
    delete allFields.cnpjAddrLatNr
    delete allFields.cnpjAddrLongNr

    const params = {
      ...allFields,
      address: addressToArray,
      lsBranchJustification,
      actvServcPrvdrInd: initialValues.actvServcPrvdrInd,
      lsPhones,
      servcPrvdrPaymentMargin: fields.servcPrvdrPaymentMargin.input.value || 0,
      servcPrvdrCntrctCd: serviceProvider.servcPrvdrCntrctCd,
      servcPrvdrExtrlRqsrCd: serviceProvider.servcPrvdrExtrlRqsrCd,
    }

    if (values.servcPrvdrFedrlTaxIdNr) {
      params.servcPrvdrFedrlTaxIdNr = removeMask(values.servcPrvdrFedrlTaxIdNr)
    }

    if (values.servcPrvdrMainPlntCd && values.servcPrvdrMainPlntCd.value) {
      params.servcPrvdrMainPlntCd = values.servcPrvdrMainPlntCd.value
    } else if (values.servcPrvdrMainPlntCd) {
      params.servcPrvdrMainPlntCd = values.servcPrvdrMainPlntCd
    }

    if (filesToAdd && filesToAdd.length) {
      const files = filesToAdd.map((fileToAdd) => ({
        file: fileToAdd.src.split(',').pop(),
        name: fileToAdd.descAnexo,
      }))

      params.filesToAdd = {
        atchmtDocmntTyp: filesToAdd[0].type,
        files,
      }
    }
    if (
      isFormValid &&
      this.MacroRegionalitySection.current.validate() &&
      this.SpecialitiesSelectRef.current.validate()
    ) {
      this.handleSaveAll(token, params)
    }
  }

  handleSaveAll = async (token, params) => {
    const { update } = this.props

    store.dispatch(showSpinner())

    const id = await update(token, params, false, true, false)

    if (id) {
      await Promise.all([
        this.MacroRegionalitySection.current.handleSaveZones(id),
        this.SpecialitiesSelectRef.current.save(id),
      ])

      this.onFinishEdit()
    }

    store.dispatch(hideSpinner())
  }

  handleAddressFocus = (event) => {
    this.lastAddressValue = event.target.value
  }

  handleAddressBlur = (event) => {
    if (this.lastAddressValue !== event.target.value) {
      this.clearLatLong()
    }

    this.lastAddressValue = ''
  }

  cepOnInput = async ({ target: { value } }) => {
    const postalCode = getNumbersOnly(value).substring(0, 8)

    if (postalCode.length === 8) {
      const {
        auth: { user },
        dispatch,
      } = this.props

      try {
        const { data } = await new Api(user.dsToken, dispatch).get(
          API_URL.SERVICE_PROVIDER_POSTAL_CODE,
          { postalCode },
        )

        const dneData = data.dne[0]

        if (dneData) {
          this.setCnpjNumCepAddress(dneData)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  setCnpjNumCepAddress = async (dneData) => {
    const { setValueToField } = this.props

    setValueToField('cnpjInitStateAddress', dneData.state.trim())
    setValueToField('cnpjDescAddress', dneData.streetName.trim())
    setValueToField('cnpjNameLocaleAddress', dneData.city.trim())
    setValueToField('cnpjDescNeighborhoodAddress', dneData.neighborhood.trim())
  }

  clearLatLong = () => {
    this.handleChange(this.getFakeEvent('cnpjAddrLatNr', ''))
    this.handleChange(this.getFakeEvent('cnpjAddrLongNr', ''))
  }

  getFakeEvent = (name, value) => ({ target: { name, value } })

  render() {
    const {
      t,
      auth: { user },
      dispatch,
      toggleServiceProviderModal,
      disabled,
      values,
      openJustificationModal,
      initialValues,
      index,
      disablePrvdrOs,
      setActvServcPrvdrOrdRecvInd,
      setValueToField,
      resetError,
      canEditBranchsAndGroups,
    } = this.props

    const { fields } = this

    const {
      actvServcPrvdr,
      lsPhones: phoneNumberFields,
      disabledForm,
      disableSwitchInput,
    } = this.state

    const disabledFields = disabledForm || disabled

    const phone1 = phoneNumberFields[0] !== undefined ? phoneNumberFields[0].valMeioComunicacao : ''
    const phone2 = phoneNumberFields[1] !== undefined ? phoneNumberFields[1].valMeioComunicacao : ''
    const phone3 = phoneNumberFields[2] !== undefined ? phoneNumberFields[2].valMeioComunicacao : ''

    const optional = ` ${t('editForm.optional')}`

    const editOrView = !!index
    return (
      <form className='container-fluid margin-top' onSubmit={this.handleBeforeSubmit}>
        <Row>
          <Col xs={12}>
            <MozaicHeading size='m' className='no-margin'>
              {t('serviceProvider.register')}
            </MozaicHeading>
          </Col>
        </Row>

        <Input id='servcPrvdrId' {...fields.servcPrvdrId.input} type='hidden' />

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col xs={12} md={3}>
              <InputButton
                id='actvServcPrvdrInd'
                name='actvServcPrvdrInd'
                value={fields.actvServcPrvdrInd.input}
                onChange={
                  _.isEmpty(fields.actvServcPrvdrInd.input.value)
                    ? this.handleChange
                    : () =>
                        openJustificationModal(
                          initialValues.servcPrvdrId,
                          {
                            key: 'actvServcPrvdrInd',
                            value:
                              initialValues.actvServcPrvdrInd === ACTV_SERVC_PRVDR_IND_TYPES.ACTIVE
                                ? ACTV_SERVC_PRVDR_IND_TYPES.INACTIVE
                                : ACTV_SERVC_PRVDR_IND_TYPES.ACTIVE,
                          },
                          disablePrvdrOs,
                        )
                }
                className='full-width'
                labelAdditionalClass='full-width'
                text={t('serviceProvider.prestadorServicoAtivo') + optional}
                checked={
                  initialValues.actvServcPrvdrInd
                    ? initialValues.actvServcPrvdrInd === '1'
                    : actvServcPrvdr === '1'
                }
                disabled={disabledFields || disableSwitchInput}
              />
            </Col>

            <Col xs={12} md={3}>
              <InputButton
                {...fields.actvServcPrvdrOrdRecvInd.input}
                id='actvServcPrvdrOrdRecvInd'
                name='actvServcPrvdrOrdRecvInd'
                onChange={
                  _.isEmpty(fields.actvServcPrvdrOrdRecvInd.input.value)
                    ? this.handleChange
                    : (event) =>
                        openJustificationModal(
                          values.servcPrvdrId,
                          {
                            key: 'actvServcPrvdrOrdRecvInd',
                            value: event.target.checked
                              ? ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES.ACTIVE
                              : ACTV_SERVC_PRVDR_ORD_RECV_IND_TYPES.INACTIVE,
                          },
                          setActvServcPrvdrOrdRecvInd,
                        )
                }
                className='full-width'
                labelAdditionalClass='full-width'
                text={`${t('serviceProvider.actvServcPrvdrOrdRecvInd')} ${optional}`}
                checked={initialValues.actvServcPrvdrOrdRecvInd === '1'}
                disabled={disabledFields || disableSwitchInput}
              />
            </Col>

            <Col lg={3} md={3} sm={3} xs={12}>
              <Field
                {...fields.servcPrvdrFedrlTaxIdNr}
                error={t(fields.servcPrvdrFedrlTaxIdNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrFedrlTaxIdNr'
                  {...fields.servcPrvdrFedrlTaxIdNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrFedrlTaxIdNr')}
                  mask={getCNPJMask(t)}
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col lg={3} md={3} sm={3} xs={12}>
              <Field
                {...fields.servcPrvdrCreatDt}
                error={t(fields.servcPrvdrCreatDt.error)}
                glyph='glyph glyph-calendar'
                validate
              >
                <Input
                  id='servcPrvdrCreatDt'
                  {...fields.servcPrvdrCreatDt.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.registerDate')}
                  mask='99/99/9999'
                  disabled
                />
              </Field>
            </Col>
          </Col>
        </Row>
        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Field {...fields.servcPrvdrTrdNm} error={t(fields.servcPrvdrTrdNm.error)} validate>
                <Input
                  id='servcPrvdrTrdNm'
                  {...fields.servcPrvdrTrdNm.input}
                  onChange={this.handleChange}
                  maxLength={100}
                  label={t('serviceProvider.servcPrvdrTrdNm')}
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} sm={6} xs={12}>
              <Field {...fields.servcPrvdrNm} error={t(fields.servcPrvdrNm.error)} validate>
                <Input
                  id='servcPrvdrNm'
                  {...fields.servcPrvdrNm.input}
                  onChange={this.handleChange}
                  maxLength={100}
                  label={t('serviceProvider.servcPrvdrNm')}
                  disabled={disabledFields}
                />
              </Field>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Field
                {...fields.servcPrvdrCityCoRgstrnNr}
                error={t(fields.servcPrvdrCityCoRgstrnNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrCityCoRgstrnNr'
                  {...fields.servcPrvdrCityCoRgstrnNr.input}
                  onChange={this.handleChange}
                  maxLength={15}
                  label={t('serviceProvider.servcPrvdrCityCoRgstrnNr')}
                  disabled={
                    disabledFields ||
                    !Object.keys(initialValues).some((key) => key === 'servcPrvdrCityCoRgstrnNr')
                  }
                />
              </Field>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Field
                {...fields.servcPrvdrStCoRgstrnNr}
                error={t(fields.servcPrvdrStCoRgstrnNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrStCoRgstrnNr'
                  {...fields.servcPrvdrStCoRgstrnNr.input}
                  onChange={this.handleChange}
                  maxLength={100}
                  disabled={disabledFields}
                  label={t('serviceProvider.servcPrvdrStCoRgstrnNr')}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col lg={4} md={4} sm={4} xs={12}>
              <Field {...fields.servcPrvdrMainPlntCd} validate>
                <BranchSelectMultiple
                  name='servcPrvdrMainPlntCd'
                  id='servcPrvdrMainPlntCd'
                  onChange={(e) => setValueToField('servcPrvdrMainPlntCd', e)}
                  value={fields.servcPrvdrMainPlntCd.input.value}
                  t={t}
                  user={user}
                  dispatch={dispatch}
                  disabled={disabledFields}
                  notMultiple
                  label='serviceProvider.strore.indicator'
                />
              </Field>
            </Col>
            <Col lg={4} md={4} sm={4} xs={12}>
              <Field
                {...fields.servcPrvdrEmailTxt}
                error={t(fields.servcPrvdrEmailTxt.error)}
                validate
              >
                <Input
                  id='servcPrvdrEmailTxt'
                  {...fields.servcPrvdrEmailTxt.input}
                  onChange={this.handleChange}
                  maxLength={80}
                  label={t('serviceProvider.servcPrvdrEmailTxt')}
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col lg={2} md={2} sm={2} xs={12}>
              <Field
                {...fields.servcPrvdrAcctntCd}
                error={t(fields.servcPrvdrAcctntCd.error)}
                validate
              >
                <Input
                  id='servcPrvdrAcctntCd'
                  {...fields.servcPrvdrAcctntCd.input}
                  onChange={this.handleChange}
                  maxLength={12}
                  label={t('serviceProvider.servcPrvdrAcctntCd')}
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col lg={2} md={2} sm={2} xs={12}>
              <Field {...fields.servcPrvdrAvgGrade} error={t(fields.servcPrvdrAvgGrade.error)}>
                <Input
                  id='servcPrvdrAvgGrade'
                  {...fields.servcPrvdrAvgGrade.input}
                  label={t('serviceProvider.averageRating')}
                  disabled
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col lg={6} md={6} sm={6} xs={12}>
              <Field {...fields.lsBranch} validate error={t(fields.lsBranch.error)}>
                <BranchSelectMultiple
                  {...fields.lsBranch.input}
                  name='lsBranch'
                  id='lsBranch'
                  onChange={(options) => {
                    const lsValue = options ? options.map((option) => option.value) : null
                    setValueToField(
                      'lsBranch',
                        lsValue,
                    )
                    fields.lsBranch.input.value = lsValue
                    resetError('lsBranch')
                  }}
                  value={fields.lsBranch.input.value}
                  t={t}
                  user={user}
                  dispatch={dispatch}
                  disabled={!canEditBranchsAndGroups}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} sm={6} xs={12}>
              <SpecialitiesSelect
                ref={this.SpecialitiesSelectRef}
                providerId={initialValues.servcPrvdrId}
                disabled={!canEditBranchsAndGroups}
                label='specialties'
              />
            </Col>
          </Col>
        </Row>

        <MacroRegionalitySection
          ref={this.MacroRegionalitySection}
          servcPrvdrId={initialValues.servcPrvdrId}
          viewMode={disabledFields || initialValues.servcPrvdrId === undefined}
        />

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col md={3} sm={3} xs={12}>
              <Field {...fields.phone1} error={t(fields.phone1.error)} validate>
                <TelephoneInput
                  {...fields.phone1.input}
                  id='phone1'
                  name='phone1'
                  country={getPhoneNumberCountry(phone1)}
                  value={phone1}
                  label={t('serviceProvider.phone1')}
                  onChange={(value) => this.handlePhoneNumberChange('phone1', value || '')}
                  disabled={disabledFields}
                  required
                />
              </Field>
            </Col>
            <Col md={3} sm={3} xs={12}>
              <Field {...fields.phone2} error={t(fields.phone2.error)} validate>
                <TelephoneInput
                  {...fields.phone2.input}
                  id='phone2'
                  name='phone2'
                  country={getPhoneNumberCountry(phone2)}
                  label={t('serviceProvider.phone2') + optional}
                  value={phone2}
                  disabled={disabledFields}
                  onChange={(value) => this.handlePhoneNumberChange('phone2', value || '')}
                />
              </Field>
            </Col>

            <Col md={3} sm={3} xs={12}>
              <Field {...fields.phone3} error={t(fields.phone3.error)} validate>
                <TelephoneInput
                  {...fields.phone3.input}
                  id='phone3'
                  name='phone3'
                  country={getPhoneNumberCountry(phone3)}
                  label={t('serviceProvider.phone3') + optional}
                  value={phone3}
                  disabled={disabledFields}
                  onChange={(value) => this.handlePhoneNumberChange('phone3', value || '')}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className='margin-left margin-right margin-top'>
            <MozaicText weight='semi-bold'>{t('service.provider.new.cpnj.address')}</MozaicText>
          </Col>
        </Row>
        <div className='divisor-line margin-left margin-right' />
        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col md={6} sm={6} xs={12}>
              <Field {...fields.cnpjDescAddress} error={t(fields.cnpjDescAddress.error)} validate>
                <Input
                  id='cnpjDescAddress'
                  {...fields.cnpjDescAddress.input}
                  onChange={this.handleChange}
                  maxLength={120}
                  label={t('service.provider.new.cpnj.address')}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>

            <Col md={2} sm={2} xs={12}>
              <Field {...fields.cnpjNumAddress} error={t(fields.cnpjNumAddress.error)} validate>
                <Input
                  id='cnpjNumAddress'
                  {...fields.cnpjNumAddress.input}
                  onChange={this.handleChange}
                  maxLength={10}
                  label={t('serviceProvider.numEndereco')}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>

            <Col md={4} sm={4} xs={12}>
              <Field
                {...fields.cnpjDescComplAddress}
                error={t(fields.cnpjDescComplAddress.error)}
                validate
              >
                <Input
                  id='cnpjDescComplAddress'
                  {...fields.cnpjDescComplAddress.input}
                  onChange={this.handleChange}
                  maxLength={80}
                  label={t('serviceProvider.descComplEndereco') + optional}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col md={2} sm={2} xs={12}>
              <Field
                {...fields.cnpjNumCepAddress}
                error={t(fields.cnpjNumCepAddress.error)}
                validate
              >
                <Input
                  id='cnpjNumCepAddress'
                  {...fields.cnpjNumCepAddress.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.numCepEndereco')}
                  mask={getCEPMask(t)}
                  disabled={disabledFields}
                  onInput={this.cepOnInput}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
            <Col md={4} sm={4} xs={12}>
              <Field
                {...fields.cnpjDescNeighborhoodAddress}
                error={t(fields.cnpjDescNeighborhoodAddress.error)}
                validate
              >
                <Input
                  id='cnpjDescNeighborhoodAddress'
                  {...fields.cnpjDescNeighborhoodAddress.input}
                  onChange={this.handleChange}
                  maxLength={50}
                  label={t('serviceProvider.descBairroEndereco')}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
            <Col md={4} sm={4} xs={12}>
              <Field
                {...fields.cnpjNameLocaleAddress}
                error={t(fields.cnpjNameLocaleAddress.error)}
                validate
              >
                <Input
                  id='cnpjNameLocaleAddress'
                  {...fields.cnpjNameLocaleAddress.input}
                  onChange={this.handleChange}
                  maxLength={60}
                  label={t('serviceProvider.nomeLocalidadeEndereco')}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
            <Col md={2} sm={2} xs={12}>
              <Field
                {...fields.cnpjInitStateAddress}
                error={t(fields.cnpjInitStateAddress.error)}
                validate
              >
                <Input
                  id='cnpjInitStateAddress'
                  {...fields.cnpjInitStateAddress.input}
                  onChange={this.handleChange}
                  maxLength={4}
                  label={t('serviceProvider.siglEstadoEndereco')}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Field
                {...fields.cnpjDescReferAddress}
                error={t(fields.cnpjDescReferAddress.error)}
                validate
              >
                <Input
                  id='cnpjDescReferAddress'
                  {...fields.cnpjDescReferAddress.input}
                  onChange={this.handleChange}
                  maxLength={30}
                  label={t('serviceProvider.descReferEndereco') + optional}
                  disabled={disabledFields}
                  onFocus={this.handleAddressFocus}
                  onBlur={this.handleAddressBlur}
                />
              </Field>
            </Col>
            <Col md={2} sm={6} xs={12}>
              <Field {...fields.cnpjAddrLatNr} error={t(fields.cnpjAddrLatNr.error)} validate>
                <Input
                  id='cnpjAddrLatNr'
                  {...fields.cnpjAddrLatNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrAddrLatNr')}
                  disabled
                  type='number'
                  step='0.00000001'
                  max='999999.99999999'
                  min='-999999.99999999'
                />
              </Field>
            </Col>
            <Col md={2} sm={6} xs={12}>
              <Field {...fields.cnpjAddrLongNr} error={t(fields.cnpjAddrLongNr.error)} validate>
                <Input
                  id='cnpjAddrLongNr'
                  {...fields.cnpjAddrLongNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrAddrLongNr')}
                  disabled
                  type='number'
                  step='0.00000001'
                  max='999999.99999999'
                  min='-999999.99999999'
                />
              </Field>
            </Col>
          </Col>
        </Row>

        <div className='divisor-line margin-left margin-right margin-top-double' />

        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col md={4} sm={6} xs={12}>
              <Field {...fields.servcPrvdrBankNr} error={t(fields.servcPrvdrBankNr.error)} validate>
                <BankSelect
                  t={t}
                  {...fields.servcPrvdrBankNr.input}
                  id='servcPrvdrBankNr'
                  name='servcPrvdrBankNr'
                  label='serviceProviders.choose-bank'
                  placeholder={t('serviceProviders.choose-bank')}
                  onChange={(selected) => {
                    this.handleSelectChange(selected, 'servcPrvdrBankNr')
                  }}
                  optional
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col md={2} sm={6} xs={12}>
              <Field
                {...fields.servcPrvdrBankBrnchNr}
                error={t(fields.servcPrvdrBankBrnchNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrBankBrnchNr'
                  {...fields.servcPrvdrBankBrnchNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrBankBrnchNr') + optional}
                  mask='99999999999999999999'
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col md={2} sm={6} xs={12}>
              <Field
                {...fields.servcPrvdrBankBrnchDigtNr}
                error={t(fields.servcPrvdrBankBrnchDigtNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrBankBrnchDigtNr'
                  {...fields.servcPrvdrBankBrnchDigtNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrBankBrnchDigtNr') + optional}
                  mask='99'
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col md={2} sm={6} xs={12}>
              <Field
                {...fields.servcPrvdrBankAcctNr}
                error={t(fields.servcPrvdrBankAcctNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrBankAcctNr'
                  {...fields.servcPrvdrBankAcctNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrBankAcctNr') + optional}
                  mask='99999999999999999999'
                  disabled={disabledFields}
                />
              </Field>
            </Col>

            <Col md={2} sm={6} xs={12}>
              <Field
                {...fields.servcPrvdrBankAcctDigtNr}
                error={t(fields.servcPrvdrBankAcctDigtNr.error)}
                validate
              >
                <Input
                  id='servcPrvdrBankAcctDigtNr'
                  {...fields.servcPrvdrBankAcctDigtNr.input}
                  onChange={this.handleChange}
                  label={t('serviceProvider.servcPrvdrBankAcctDigtNr') + optional}
                  mask='99'
                  disabled={disabledFields}
                />
              </Field>
            </Col>
          </Col>
        </Row>
        <Row className='margin-bottom-small'>
          <Col xs={12}>
            <Col md={4} sm={6} xs={12}>
              <Field
                {...fields.servcPrvdrBankAcctTyp}
                error={t(fields.servcPrvdrBankAcctTyp.error)}
                validate
              >
                <AccountTypeSelect
                  t={t}
                  {...fields.servcPrvdrBankAcctTyp.input}
                  id='servcPrvdrBankAcctTyp'
                  name='servcPrvdrBankAcctTyp'
                  placeholder={t('title.account.type')}
                  label='title.account.type'
                  onChange={(selected) => {
                    this.handleSelectChange(selected, 'servcPrvdrBankAcctTyp')
                  }}
                  optional
                  disabled={disabledFields}
                  value={fields.servcPrvdrBankAcctTyp.input.value}
                />
              </Field>
            </Col>
            <Col md={2} sm={6} xs={12}>
              <Field {...fields.servcPrvdrPaymentMargin} select validate>
                <Select
                  id='margin'
                  name='margin'
                  label={t('serviceProvider.margin')}
                  value={fields.servcPrvdrPaymentMargin.input.value}
                  disabled={disabledFields}
                  onChange={(options) =>
                    setValueToField('servcPrvdrPaymentMargin', options.target.value)
                  }
                >
                  <option value='' hidden />
                  <option value='0'>{t('serviceProvider.margin.zero.percent')}</option>
                  <option value='5'>{t('serviceProvider.margin.five.percent')}</option>
                  <option value='10'>{t('serviceProvider.margin.ten.percent')}</option>
                </Select>
              </Field>
            </Col>
          </Col>
        </Row>
        <Row className='margin-top-double margin-bottom padding-left-mobile padding-right-mobile'>
          <Col xs={12} md={2} className='margin-bottom'>
            <button
              className='button button-primary button-full align-center color-primary-dark'
              type='button'
              onClick={toggleServiceProviderModal}
            >
              {t('option.manageDocuments')}
            </button>
          </Col>

          {Boolean(editOrView) && (
            <Col xs={12} md={2} className='margin-bottom'>
              <Link
                className='button button-primary align-center'
                to={`/servcPrvdrTmlineEvnt?id=${index}`}
              >
                {t('servcPrvdrTmlineEvntTyp.viewLog')}
              </Link>
            </Col>
          )}

          <Col
            mdOffset={editOrView ? (disabled && !canEditBranchsAndGroups ? 6 : 4) : 6}
            xs={12}
            md={2}
          >
            <div
              role='button'
              onClick={this.onFinishEdit}
              className='button-hollow button-hollow-danger button-full align-center margin-bottom'
            >
              {t('cancel_button_label')}
            </div>
          </Col>
          {Boolean(!disabled || canEditBranchsAndGroups) && (
            <Col xs={12} md={2}>
              <button type='submit' className='button button-primary button-full align-center'>
                {editOrView ? t('save_button_label') : t('register_button_label')}
              </button>
            </Col>
          )}
        </Row>
      </form>
    )
  }
}

ServiceProviderEditForm.propTypes = {
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  setValueToField: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  toggleServiceProviderModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  openJustificationModal: PropTypes.func.isRequired,
  setActvServcPrvdrOrdRecvInd: PropTypes.func.isRequired,
  disablePrvdrOs: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  serviceProvider: PropTypes.object,
  index: PropTypes.number.isRequired,
  fieldBatchUpdate: PropTypes.func,
  isFormValid: PropTypes.bool,
  resetError: PropTypes.func.isRequired,
  canEditBranchsAndGroups: PropTypes.bool,
  history: PropTypes.object.isRequired,
}

const fields = [
  'servcPrvdrId',
  'servcPrvdrNm',
  'servcPrvdrCityCoRgstrnNr',
  'servcPrvdrStCoRgstrnNr',
  'servcPrvdrTrdNm',
  'servcPrvdrFedrlTaxIdNr',
  'servcPrvdrCreatDt',
  'lsBranch',
  'servcPrvdrAcctntCd',
  'servcPrvdrEmailTxt',
  'actvServcPrvdrInd',
  'phone1',
  'phone2',
  'phone3',
  'cnpjNumCepAddress',
  'cnpjDescAddress',
  'cnpjNumAddress',
  'cnpjDescComplAddress',
  'cnpjDescNeighborhoodAddress',
  'cnpjNameLocaleAddress',
  'cnpjInitStateAddress',
  'cnpjDescReferAddress',
  'cnpjAddrLatNr',
  'cnpjAddrLongNr',
  'servcPrvdrBankNr',
  'servcPrvdrBankAcctNr',
  'servcPrvdrBankBrnchNr',
  'servcPrvdrBankBrnchDigtNr',
  'servcPrvdrBankAcctDigtNr',
  'servcPrvdrBankAcctTyp',
  'servcPrvdrMainPlntCd',
  'servcPrvdrAvgGrade',
  'actvServcPrvdrOrdRecvInd',
  'validIntegration',
  'servcPrvdrPaymentMargin',
  'sysAcsUserId',
  'sysAcsUserLastLognTs',
  'lastLognSysVerCd',
]

export default Form({ fields })(ServiceProviderEditForm)

export { ServiceProviderEditForm }
