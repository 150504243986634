import i18next from 'i18next'

import ErrorBoundary from '../../../../../../../errors/boundaries'
import Accordeon from '../../../../../../../components/Accordeon'
import ActivatedWarranty from './components/ActivatedWarranty'
import MozaicText from '../../../../../../../components/MozaicText'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { WithContext } from './context'
import { ServiceOrderDetailStore } from '../../../../../context/models'

const accordeonHeader = {
  title: 'serviceOrders.rework.warranty',
  icon: <MozaicIcon icon='PromiseWarranty24' className='icon-custom' />,
}

function ReworkWarranty({
  showWarrantyReworkContent,
  reloadServiceOrder,
}: ServiceOrderDetailStore) {
  if (!showWarrantyReworkContent) return <></>

  return (
    <div id='warranty-rework-container'>
      <Accordeon header={accordeonHeader}>
        <ErrorBoundary
          FallbackComponent={<MozaicText>{i18next.t('fallback.page.error.subtitle')}</MozaicText>}
          resetErrorBoundary={() => reloadServiceOrder()}
        >
          <ActivatedWarranty />
        </ErrorBoundary>
      </Accordeon>
    </div>
  )
}

export default WithContext(ReworkWarranty)
