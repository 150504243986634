import { memo } from 'react'

import MozaicIcon from '../MozaicIcon'

interface EyeButtonProps {
  onClickEyeButton: Function
}

function EyeButton(props: EyeButtonProps) {
  const { onClickEyeButton } = props

  return (
    <button id='eye-button' type='button' onClick={() => onClickEyeButton()}>
      <MozaicIcon icon='DisplayView24' />
    </button>
  )
}

export default memo(EyeButton)
