import { ChangeEventHandler } from 'react'
import i18next from 'i18next'

import Accordeon from '../../../../../../../../components/Accordeon'
import SearchInput from '../../../AdvancedSearchInput'
import MozaicRadio from '../../../../../../../../components/MozaicRadio'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'
import { WithContext } from './context/indext'
import { ClientBoxType } from '../../models/client'

function ClientBox(props: ClientBoxType) {
  const { showAllDetails, handleToggle, clientSearchField, handleChangeRadio } = props

  const inputMask = (value: string) => {
    const numerical = ['orderNumer', 'serviceOrderNumber', 'phoneNumber', 'servcOrdWorksiteNumber']

    if (numerical.includes(clientSearchField)) return value.replace(/\D/g, '')
    return value
  }

  const clientDataTitle = {
    icon: <MozaicIcon icon='AccountProfileView24' className='icon-custom' />,
    title: 'advanced.search.customer.data.title',
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  const radioButtons = {
    allData: 'advanced.search.customer.data.radio.allData',
    document: 'advanced.search.customer.data.radio.document',
    orderNumer: 'advanced.search.customer.data.radio.orderNumer',
    serviceOrderNumber: 'advanced.search.customer.data.radio.serviceOrderNumber',
    name: 'advanced.search.customer.data.radio.name',
    phoneNumber: 'advanced.search.customer.data.radio.phoneNumber',
    servcOrdWorksiteNumber: 'advanced.search.customer.data.radio.worksite',
  }

  const alwaysVisibleContent = (
    <SearchInput placeholder={`${i18next.t('search')}...`} mask={inputMask} />
  )

  return (
    <div className='client-data-box'>
      <Accordeon
        header={clientDataTitle}
        alwaysVisibleContent={alwaysVisibleContent}
        styled={false}
        defaultOpen={showAllDetails}
        onToggle={handleToggle}
      >
        <div className='radio-buttons'>
          {Object.keys(radioButtons).map((button: string) => (
            <MozaicRadio
              key={button}
              id={button}
              name={button}
              value={button}
              isChecked={clientSearchField === button}
              label={i18next.t(radioButtons[button as keyof typeof radioButtons])}
              onChange={handleChangeRadio as ChangeEventHandler<HTMLInputElement>}
            />
          ))}
        </div>
      </Accordeon>
    </div>
  )
}

export default WithContext(ClientBox)
