import { ComponentClass, memo, useMemo } from 'react'
import { useForm } from '..'
import { ErrorType } from '../models'

export const WithForm = (fields: object) => (Component: ComponentClass<any>) => {
  const MemoComponent = memo(Component)

  return function (props: { initialValues?: object; error?: ErrorType }) {
    const initDefault = props?.initialValues || {}

    const initForm = useMemo(() => ({ ...fields, ...initDefault }), [props?.initialValues, fields])

    const formProps = useForm<typeof initForm>(initForm)

    return <MemoComponent {...formProps} {...props} />
  }
}
