const NEED_RESET = 'CACHE_NEED_RESET'

class CacheUtils {
  /*
   * Seta a necessidade de forçar atualização no logout
   * @param {boolean} value
   */
  set needReset(value) {
    localStorage.setItem(NEED_RESET, JSON.stringify(value))
  }

  /*
   * Recupera a necessidade de forçar atualização no logout
   * @return {boolean}
   */
  get needReset() {
    return JSON.parse(localStorage.getItem(NEED_RESET))
  }

  /*
   * Limpa, no localStoragfe, a necessidade de forçar atualização no logout
   */
  clear() {
    localStorage.removeItem(NEED_RESET)
  }
}

export default CacheUtils
