import { useCallback, useState } from 'react'

function Toggle() {
  const [showAllDetails, setShowAllDetails] = useState<boolean>(false)

  const handleToggle = useCallback(() => {
    const advancedSearchContainer = document.querySelector('#advanced-search-container')

    if (advancedSearchContainer) {
      const accordeons = advancedSearchContainer.querySelectorAll('.can-collapse')

      if (accordeons) {
        const isAllAccordeonsOpened = [...accordeons].every(
          (accordeon) => !accordeon.classList.contains('collapsed'),
        )

        const isAllAccordeonsClosed = [...accordeons].every((accordeon) =>
          accordeon.classList.contains('collapsed'),
        )

        if (isAllAccordeonsOpened && !showAllDetails) {
          setShowAllDetails(true)
        }

        if (isAllAccordeonsClosed && showAllDetails) {
          setShowAllDetails(false)
        }
      }
    }
  }, [showAllDetails, setShowAllDetails])

  return {
    showAllDetails,
    setShowAllDetails,
    handleToggle,
  }
}

export default Toggle
