export type TagStyle = 'info' | 'danger' | 'warning' | 'success' | 'neutral'

export interface TagItem {
  style: TagStyle
  labelKey: string
}

export const TAG_TYPES: { [ket: string]: TagItem } = {
  EXPIRED: { style: 'warning', labelKey: 'serviceOrders.expired' },
  PARTIALLY_DONE: { style: 'info', labelKey: 'partially.performed' },
  ATTENTION: { style: 'danger', labelKey: 'serviceOrders.attentionOccurrence' },
  LATE_ORDER: { style: 'danger', labelKey: 'serviceOrders.lateOrder' },
  WARRANTY_SERVICE: { style: 'neutral', labelKey: 'serviceOrders.warrantyService' },
  EMERGENCY_SERVICE: { style: 'danger', labelKey: 'serviceOrder.badge.emergency.service' },
  AWAITING_MEASUREMENT_SHEET: {
    style: 'warning',
    labelKey: 'serviceOrder.badge.measurement.awaiting',
  },
  VALIDATE_MEASUREMENT_SHEET: {
    style: 'warning',
    labelKey: 'serviceOrder.badge.measurement.validate',
  },
  REJECTED: { style: 'warning', labelKey: 'serviceOrders.rejected' },
  ACCEPTANCE_SALES_CONDITIONS: {
    style: 'warning',
    labelKey: 'serviceOrders.acceptanceSalesConditions',
  },
  ID_CONFORMITY: { style: 'warning', labelKey: 'serviceOrders.idConformity' },
  REWORK: { style: 'neutral', labelKey: 'serviceOrdersFragment.badge.rework' },
  NFE_SENT: { style: 'success', labelKey: 'serviceOrder.badge.nfe.sent' },
  NFE_AWAITING_SHIPMENT: { style: 'warning', labelKey: 'serviceOrder.badge.nfe.awaiting.shipment' },
  NFE_LATE: { style: 'danger', labelKey: 'serviceOrder.badge.nfe.late' },
  WARNING_OUT_OF_FLOW_PROCESS: { style: 'warning', labelKey: 'outOfFlowRequest.warnig' },
  APPROVED_OUT_OF_FLOW_PROCESS: { style: 'warning', labelKey: 'approvedOutOfFlowRequest.badge' },
  REFUSED_OUT_OF_FLOW_PROCESS: { style: 'danger', labelKey: 'refusedOutOfFlowRequest.badge' },
  RESCHEDULED_BY_CLIENT: {
    style: 'warning',
    labelKey: 'customer.service.order.reschedule.requested',
  },
  MANUAL_DISTRIBUTED: { style: 'info', labelKey: 'serviceOrdersFragment.badge.manualDistributed' },
  QUOTATION_APPROVED: { style: 'success', labelKey: 'serviceOrder.budget.approved' },
  QUOTATION_REFUSED: { style: 'danger', labelKey: 'serviceOrder.budget.refused' },
  TECHNICAL_REPORT: { style: 'warning', labelKey: 'serviceOrder.badge.technicalReport' },
  TECHNICAL_REPORT_LATE: { style: 'danger', labelKey: 'serviceOrder.badge.technicalReport.late' },
  TECHNICAL_REPORT_SENT: { style: 'info', labelKey: 'servcOrd.technicalReport.sent' },
  WAITING_RESPONSIBLE: { style: 'warning', labelKey: 'serviceOrder.badge.waitingResponsible' },
  WAITING_BUDGET: { style: 'warning', labelKey: 'serviceOrder.badge.waitingBudget' },
  RECOMMEND_INSPECTION: { style: 'info', labelKey: 'serviceOrder.badge.recommendInspection' },
  ATTETION_TO_DELIVERY: { style: 'danger', labelKey: 'serviceOrder.badge.attentionToDelivery' },
  NEGATIVE_OV: { style: 'danger', labelKey: 'serviceOrder.badge.negativeOV' },
  PROVIDER_RESCHEDULE: { style: 'warning', labelKey: 'serviceOrder.badge.providerReschedule' },
  CLIENT_RESCHEDULE_REFUSE: {
    style: 'danger',
    labelKey: 'serviceOrder.badge.clientRescheduleRefuse',
  },
  SALES_ERROR: {
    style: 'danger',
    labelKey: 'access.function141',
  },
  WCF_SIGNED_WITH_RESERVATIONS: {
    style: 'warning',
    labelKey: 'serviceOrder.badge.wcfSignedWithReservations',
  },
  EXPIRED_CUSTOMER_CONTRACT: {
    style: 'danger',
    labelKey: 'serviceOrder.badge.expiredCustomerContract',
  },
  WCF_EXPIRED: {
    style: 'warning',
    labelKey: 'serviceOrder.badge.wcfExpired',
  },
}
