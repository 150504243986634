import { useEffect, useState } from 'react'
import { pdfjs } from 'react-pdf'

import { GCloudImageRef } from '../../../../services/firestore/gCloudStorage/GCloudImageRef'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface GCloudFilePreviewProps {
  file: GCloudImageRef
}

export default function ViewIMG({ file }: GCloudFilePreviewProps) {
  const [url, setUrl] = useState(file.downloadUrl)

  useEffect(() => {
    file.getDownloadURL(true).then(() => setUrl(file.downloadUrl))
  }, [file])

  return <img src={url} />
}
