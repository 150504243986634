import PropTypes from 'prop-types'
import classNames from 'classnames'

function Grid(props) {
  const { className, fluid, children } = props

  const componentClass = classNames({
    container: !fluid,
    'container-fluid': fluid,
    [className]: className,
  })

  const newProps = {
    className: componentClass,
    children,
  }

  return <section {...newProps} />
}

Grid.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default Grid
