import { createContext, useContext, useMemo } from 'react'
import InstallerListHandlers from './handlers/installerList'
import InstallerHandlers from './handlers/installer'
import { InstallerProviderProps, InstallerStore } from './models'

const InstallerContext = createContext<InstallerStore | null>(null)

function InstallerProvider({ children }: InstallerProviderProps) {
  const {
    sms,
    mail,
    filter,
    currentPage,
    installersList,
    installersListPage,
    installersAutoComplete,
    sendSmsAppSpecialist,
    sendMail,
    deleteServiceSpecialist,
    fetchServiceSpecialistList,
    fetchServiceSpecialistAutocomplete,
    fetchServiceSpecialistPageList,
    handleClearInstallersList,
  } = InstallerListHandlers()

  const {
    installerEdit,
    installerDocs,
    installerAccess,
    getServiceSpecialist,
    getServiceSpecialistFromId,
    viewServiceSpecialistFromId,
    fetchServiceSpecialistNameAutocomplete,
    fetchServiceSpecialistFiles,
    saveServiceSpecialistFiles,
    viewServiceSpecialist,
    removeSpecialistFile,
    updateServiceSpecialist,
    removeServiceSpecialist,
    inactiveSpecialist,
    fetchSysAcsUser,
    handleClearInstaller,
  } = InstallerHandlers({ installersList })

  const result = useMemo(
    () => ({
      sms,
      mail,
      filter,
      currentPage,
      installersList,
      installersListPage,
      installersAutoComplete,
      installerEdit,
      installerDocs,
      installerAccess,
      sendSmsAppSpecialist,
      sendMail,
      deleteServiceSpecialist,
      fetchServiceSpecialistList,
      fetchServiceSpecialistAutocomplete,
      fetchServiceSpecialistPageList,
      getServiceSpecialist,
      getServiceSpecialistFromId,
      viewServiceSpecialistFromId,
      fetchServiceSpecialistNameAutocomplete,
      fetchServiceSpecialistFiles,
      saveServiceSpecialistFiles,
      viewServiceSpecialist,
      removeSpecialistFile,
      updateServiceSpecialist,
      removeServiceSpecialist,
      inactiveSpecialist,
      fetchSysAcsUser,
      handleClearInstallersList,
      handleClearInstaller,
    }),
    [
      sms,
      mail,
      filter,
      installersList,
      installersListPage,
      installersAutoComplete,
      installerEdit,
      installerDocs,
      installerAccess,
    ],
  )

  return <InstallerContext.Provider value={result as any}>{children}</InstallerContext.Provider>
}

const useInstallerContext = () => useContext(InstallerContext) as InstallerStore

export { useInstallerContext, InstallerProvider }
