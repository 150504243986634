import { ReactNode } from 'react'
import classNames from 'classnames'

type MozaicCardType = {
  title: string
  className?: string
  leftIcon: ReactNode
  rightAction?: ReactNode
  children: ReactNode
}

function MozaicCard(props: MozaicCardType) {
  const { title, className = '', leftIcon, rightAction, children } = props

  return (
    <div className={classNames('mozaic-card', { [className]: !!className })}>
      <header>
        <div className='header_left'>
          {leftIcon}
          <h2>{title}</h2>
        </div>

        {rightAction && <div className='header_right'>{rightAction}</div>}
      </header>

      <section className='body'>{children}</section>
    </div>
  )
}

export default MozaicCard
