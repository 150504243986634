import PropTypes from 'prop-types'
import { Component } from 'react'
import Modal from '../../../components/utils/Modal'
import ServicePersonDocuments from './ServicePersonDocuments'

export const SPECIALIST_DOCUMENTS = 'SPECIALIST_DOCUMENTS'
export const PROVIDER_DOCUMENTS = 'PROVIDER_DOCUMENTS'

class ServicePersonModal extends Component {
  static propTypes = {
    modalType: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    size: 'lg',
  }

  componentDidMount() {
    const { modalType } = this.props

    switch (modalType) {
      case SPECIALIST_DOCUMENTS:
      case PROVIDER_DOCUMENTS:
        this.setState({ size: 'lg' })
        break
    }
  }

  renderContent = () => {
    const { modalType, ...props } = this.props

    switch (modalType) {
      case SPECIALIST_DOCUMENTS:
        props.personId = 'idEspecialistaServico'
        props.attachmentId = 'idAnexoEspecialista'
        break
      case PROVIDER_DOCUMENTS:
        props.personId = 'servcPrvdrId'
        props.attachmentId = 'idAnexoPrestador'
        break
      default:
        break
    }

    return <ServicePersonDocuments {...props} />
  }

  render() {
    const { size } = this.state
    const { closeModal } = this.props

    return (
      <Modal closeModal={closeModal} size={size} className='lmi-modal resize-none'>
        {this.renderContent()}
      </Modal>
    )
  }
}

export default ServicePersonModal
