import { useEffect, useState } from 'react'
import {
  ServiceInformationStateType,
  StorageServiceInformationType,
  optionType,
} from '../models/serviceInformation'

function ServiceInformationState(storageFilters: StorageServiceInformationType) {
  const [status, setStatus] = useState<Array<optionType>>([])
  const [serviceType, setServiceType] = useState<optionType | null>(null)
  const [type, setType] = useState<Array<optionType>>([])
  const [category, setCategory] = useState<Array<optionType>>([])
  const [LmNumber, setLmNumber] = useState<string>('')

  useEffect(() => {
    if (storageFilters) {
      const {
        codProd,
        servcOrdPriortyCd,
        servcOrdType,
        lsStatus,
        lsServcOrdCategory,
      } = storageFilters

      if (servcOrdPriortyCd) {
        setServiceType({
          value: parseInt(servcOrdPriortyCd),
          label: servcOrdType,
        })
      }

      if (lsStatus) setStatus(lsStatus)
      if (lsServcOrdCategory) setCategory(lsServcOrdCategory)

      setLmNumber(codProd)
    }
  }, [storageFilters])

  const getDefaultState = () => ({
    lsStatus: [],
    lsServcOrdStusCd: [],
    lsCodProd: [],
    lsServcOrdCategory: [],
    lsServcOrdCategoryCd: [],
    codProd: '',
    servcOrdPriortyCd: 0,
    servcOrdType: 'so.typeFull.value',
  })

  const getCurrentState = () => ({
    lsStatus: status,
    lsServcOrdStusCd: status.map((_status) => _status.value),
    lsCodProd: type.map((type) => type.value),
    lsServcOrdCategory: category,
    lsServcOrdCategoryCd: category.map((_category) => _category.value),
    codProd: LmNumber,
    servcOrdPriortyCd: serviceType?.value || 0,
    servcOrdType: serviceType?.label || 'so.typeFull.value',
  })

  const clearState = () => {
    setStatus([])
    setServiceType(null)
    setType([])
    setCategory([])
    setLmNumber('')
  }

  return {
    status,
    serviceType,
    type,
    category,
    LmNumber,
    setStatus,
    setServiceType,
    setType,
    setCategory,
    setLmNumber,
    getCurrentState,
    getDefaultState,
    clearState,
  } as ServiceInformationStateType
}

export default ServiceInformationState
