import { createContext, useContext, useMemo } from 'react'
import { PaymentsListHandler } from './handlers/paymentsList'
import { importPaymentOcurrence } from '../../services/Payments'
import { PaymentsProviderProps, PaymentsStore } from './models'

const PaymentsContext = createContext<PaymentsStore | null>(null)

function PaymentsProvider({ children }: PaymentsProviderProps) {
  const {
    serviceOrders,
    filter,
    fetchPaymentsServiceOrders,
    paymentServiceOrderRemove,
    paymentServiceOrderSave,
    handleClear,
  } = PaymentsListHandler()

  const handleImportPayments = async (token: any, payload: any) =>
    importPaymentOcurrence(token, payload)

  const result = useMemo(
    () => ({
      serviceOrders,
      filter,
      handleClear,
      fetchPaymentsServiceOrders,
      paymentServiceOrderRemove,
      paymentServiceOrderSave,
      importPaymentOcurrence: handleImportPayments,
    }),
    [serviceOrders, filter],
  )

  return <PaymentsContext.Provider value={result}>{children}</PaymentsContext.Provider>
}

const usePaymentsContext = () => useContext(PaymentsContext) as PaymentsStore

export { usePaymentsContext, PaymentsProvider }
