import Skeleton from '../../../../../../components/Skeleton'

interface StatusIndicatorGhostProps {
  alignmentIndicator: string
}

function StatusIndicatorGhost({ alignmentIndicator }: StatusIndicatorGhostProps) {
  return (
    <div className={`status-indicator-content ${alignmentIndicator}`}>
      <section className='info'>
        <section className='quantity'>
          <Skeleton width={26} height={26} borderRadius={8} />
        </section>
        <section className='labels'>
          <span className='title'>
            <Skeleton width={60} height={12} borderRadius={4} />
          </span>
          <span className='subtitle'>
            <Skeleton width={60} height={12} borderRadius={4} />
          </span>
        </section>
      </section>
    </div>
  )
}

export default StatusIndicatorGhost
