import { ENVIRONMENT } from '../../constants/viteEnvironment'
import API_BASE from './api-base.json'

const TYPE_ENV = API_BASE[ENVIRONMENT]

if (!TYPE_ENV) {
  throw new Error(`Environment ${ENVIRONMENT} not found in API_BASE`)
}

export const {
  API_INSTALA_AUTH,
  API_INSTALA,
  API_INSTALA_PROVIDER,
  API_INSTALA_I18N,
  API_INSTALA_PARAM,
  API_INSTALA_BUDGET,
  API_INSTALA_CORE_ELASTIC,
  API_SERVICES_REGIONS,
} = TYPE_ENV
