import JQuerySoap from 'jquery.soap'

/*
 * Class para lidar com as requests para API da Five9
 */
class ApiAnew {
  request(method, data) {
    return new Promise((resolve, reject) => {
      JQuerySoap({
        url: 'https://new.anewbrasil.com.br:8098/ConnectorWS.asmx',
        appendMethodToURL: false,

        namespaceQualifier: 'tem',
        namespaceURL: 'http://tempuri.org/',

        method,

        data: {
          ...data,
        },

        success: resolve,
        error: reject,
      })
    })
  }
}

export default ApiAnew
