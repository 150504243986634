/* eslint-disable consistent-return */
/* eslint-disable default-case */
class ServcOrdFluxType {
  static FLUX_CLIENT = new ServcOrdFluxType(1, 'so.typeClient')

  static FLUX_STORE = new ServcOrdFluxType(2, 'so.typeStore')

  static FLUX_PRVDR = new ServcOrdFluxType(3, 'so.typePrvdr')

  id = ''

  value = ''

  constructor(id, value) {
    this.id = id || ''
    this.value = value || ''
  }

  static getValueWithId(id) {
    switch (id) {
      case ServcOrdFluxType.FLUX_CLIENT.id:
        return ServcOrdFluxType.FLUX_CLIENT

      case ServcOrdFluxType.FLUX_STORE.id:
        return ServcOrdFluxType.FLUX_STORE

      case ServcOrdFluxType.FLUX_PRVDR.id:
        return ServcOrdFluxType.FLUX_PRVDR
    }
  }
}

export default ServcOrdFluxType
