import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import MozaicBadge from '../../../components/MozaicBadge'
import MozaicIcon from '../../../components/MozaicIcon'
import CopyToClipboardIcon from '../../../components/utils/CopyToClipboardIcon'
import {
  ASSIGNED_TO_SERVICE_CORE,
  DOCUMENT_TYPE,
  FORMAT_DATE,
  FORMAT_DATE_TRUNC,
  ORDER_STATUS_TYPES,
} from '../../../constants/config'
import ServcOrdCategoryType from '../../../enums/ServiceOrder/category/serviceCategoryType'
import ServcOrdEvntStusType from '../../../enums/ServiceOrder/event/ServcOrdEvntStusType'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import ServiceOrderTagType from '../../../enums/ServiceOrder/tags/ServiceOrderTagType'
import ocurrencesType from '../../../enums/ocurrencesType'
import { formatNationalOrInternationalPhoneNumber } from '../../../helpers/InternationalPhoneNumberUtils'
import { isTechVisit } from '../../../helpers/isTechVisit'
import { formatCNPJ, formatCPF, formatDecimal, removeMask } from '../../../helpers/masks'
import { acceptanceSalesConditions, getOsRoutePrefix } from '../../../helpers/serviceOrderUtils'

import '../index.scss'
import './Table.scss'

const OUT_FLOW_AWAITING = 1
const OUT_FLOW_REFUSED = 3

class ServiceOrderTable extends Component {
  state = {
    showAttachBudgetModal: false,
  }

  static isCancelable(serviceOrder) {
    return (
      ServiceOrderStatusType.getStatusWithId(serviceOrder.servcOrdStusCd).hasFutureStatus(
        ServiceOrderStatusType.STATUS_CANCELADA,
      ) &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_CANCELADA.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.LOJA_ENCERRADO.id &&
      serviceOrder.idOutFlowProcess !== OUT_FLOW_AWAITING
    )
  }

  static hasOccurrenceStatus(serviceOrder, status) {
    return [...(serviceOrder.serviceOrderOccurrence || [])].some(
      (occ) => occ.servcOrdEvntStusInd === status,
    )
  }

  static getExecutionDates(serviceOrder) {
    const executionDates = { start: '', end: '' }
    const occurrences = serviceOrder.serviceOrderOccurrence || []

    for (let i = occurrences.length - 1; i >= 0; i--) {
      const soc = serviceOrder.serviceOrderOccurrence[i]

      switch (soc.indStatusOrdemServicoItem) {
        case ServiceOrderStatusType.STATUS_EM_REALIZACAO.id: //  serviceOrders.doing
        case ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id: //  serviceOrders.done
        case ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id: //  serviceOrders.partiallyDone
          executionDates.end = soc.datOcorOrdemServico
          break
        default:
          break
      }

      if (executionDates.start && executionDates.end) {
        break
      }
    }

    return executionDates
  }

  static getFormattedUrlMaps = (str) => {
    if (str && str.indexOf) {
      let pos = str.indexOf(' ')

      while (pos > -1) {
        // eslint-disable-next-line no-param-reassign
        str = str.replace(' ', '+')
        pos = str.indexOf(' ')
      }

      return str
    }
  }

  static urlMaps = (serviceOrder) => {
    if (
      serviceOrder.servcAddrStrNm !== undefined &&
      serviceOrder.servcAddrStrNm !== null &&
      serviceOrder.servcAddrNr !== undefined &&
      serviceOrder.servcAddrNr !== null &&
      serviceOrder.servcAddrCityNm !== undefined &&
      serviceOrder.servcAddrCityNm !== null &&
      serviceOrder.servcAddrStCd !== undefined &&
      serviceOrder.servcAddrStCd !== null &&
      serviceOrder.cntryNm !== undefined &&
      serviceOrder.cntryNm !== null
    ) {
      const baseUrl = 'https://www.google.com.br/maps/place/'
      const url = baseUrl.concat(
        ServiceOrderTable.getFormattedUrlMaps(serviceOrder.servcAddrStrNm),
        ',+',
        ServiceOrderTable.getFormattedUrlMaps(serviceOrder.servcAddrNr),
        '+-+',
        ServiceOrderTable.getFormattedUrlMaps(serviceOrder.servcAddrCityNm),
        ',',
        ServiceOrderTable.getFormattedUrlMaps(serviceOrder.servcAddrStCd),
        '+-+',
        ServiceOrderTable.getFormattedUrlMaps(serviceOrder.cntryNm),
      )

      return (
        <a role='button' className='color-primary' href={url} target='_blank' rel='noreferrer'>
          <MozaicIcon icon='MonochromeStoreLocation32' color='primary' size={26} />
        </a>
      )
    }
  }

  needCoordinatorAttention = (serviceOrder, funcaoAcesso) =>
    funcaoAcesso.inFuncao55 &&
    (!serviceOrder.indAttention || serviceOrder.indAttention === 0) &&
    serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.NAO_CONFORME.id &&
    serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.LOJA_ENCERRADO.id &&
    serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id

  static getFluxIcon = (serviceOrder) => {
    switch (serviceOrder.indFluxType) {
      case 1:
        return <MozaicIcon icon='House32' color='primary' size={26} />

      case 2:
        return <MozaicIcon icon='StoreLM32' color='primary' size={26} />

      case 3:
        return <MozaicIcon icon='Pallet32' color='primary' size={26} />

      default:
        return null
    }
  }

  isEditable = (serviceOrder) => {
    const { funcaoAcesso } = this.props

    const indStatus = serviceOrder.servcOrdStusCd

    if (
      indStatus === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      funcaoAcesso.inFuncao00 === 1
    ) {
      return true
    }

    if (indStatus === ServiceOrderStatusType.STATUS_AGENDADA.id && funcaoAcesso.inFuncao01 === 1) {
      return true
    }

    if (
      indStatus === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
      funcaoAcesso.inFuncao02 === 1
    ) {
      return true
    }

    if (indStatus === ServiceOrderStatusType.STATUS_ASSOCIADA.id && funcaoAcesso.inFuncao03 === 1) {
      return true
    }

    if (
      indStatus === ServiceOrderStatusType.STATUS_CONFIRMADA.id &&
      funcaoAcesso.inFuncao04 === 1
    ) {
      return true
    }

    if (
      indStatus === ServiceOrderStatusType.STATUS_EM_REALIZACAO.id &&
      funcaoAcesso.inFuncao05 === 1
    ) {
      return true
    }

    if (
      indStatus === ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id &&
      funcaoAcesso.inFuncao06 === 1
    ) {
      return true
    }

    if (
      indStatus === ServiceOrderStatusType.STATUS_REALIZADO_PARCIALMENTE.id &&
      funcaoAcesso.inFuncao07 === 1
    ) {
      return true
    }

    if (indStatus === ServiceOrderStatusType.STATUS_ENCERRADO.id && funcaoAcesso.inFuncao08 === 1) {
      return true
    }

    if (indStatus === ServiceOrderStatusType.STATUS_CANCELADA.id && funcaoAcesso.inFuncao09 === 1) {
      return true
    }

    if (indStatus === ServiceOrderStatusType.NAO_CONFORME.id && funcaoAcesso.inFuncao10 === 1) {
      return true
    }

    if (funcaoAcesso.inFuncao16 === 1) {
      return true
    }

    return false
  }

  renderServiceOrderId = (serviceOrder) => {
    const { getServiceOrder } = this.props

    const idNode = (
      <span className='heading-4' style={{ display: 'inline-block' }}>
        {serviceOrder.servcOrdCd}
      </span>
    )

    if (this.isEditable(serviceOrder)) {
      const prefix = getOsRoutePrefix()

      return (
        <Link
          role='button'
          className='color-primary'
          onClick={(e) => {
            e.preventDefault()
            getServiceOrder(serviceOrder.servcOrdCd)
          }}
          to={`${prefix}/${serviceOrder.servcOrdCd}`}
        >
          {idNode}
        </Link>
      )
    }

    return idNode
  }

  renderMenu = (serviceOrder, index) => {
    const {
      funcaoAcesso,
      getPdf,
      backStatus,
      updateOsStatusMenuShorcut,
      t,
      cancel,
      confirmModal,
      generateAttentionOccurrence,
      closeOldOrder,
      downloadFile,
      outOfFlow,
      approveOutOfFlow,
      accessFunctionList,
      auth,
      auth: { setupParameters },
      openAcceptanceSalesConditions,
      openModalQrConformTerms,
      generateManualDistributionOccurrence,
      handleClosingNoConfirmity,
      handleAssignToMe,
      handleSubmitNewBid,
      openAttachBudgetModal,
      openAttachInspectionModal,
      openSalesErrorModal,
    } = this.props

    let printerButton = false
    const editButton = false
    const viewButton = false
    let backStatusButton = false
    let cancelButton = false
    let toConfirm = false
    let makeBranchInRealization = false
    let makeBranchDone = false
    let attentionServiceCoordinator = false
    let removeAttentionServiceCoordinator = false
    let closeOldOrderButton = false
    let downloadSrvcGrpFile = false
    let outOfFlowRequest = false
    let approveOutOfFlowRequest = false
    let showQrcode = false
    let showQrcodeConformity = false
    let manualDistributionButton = false
    let closingNoConformity = false
    let bidMode = false
    let assignToMe = false

    const isFinished = [
      ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id,
      ServiceOrderStatusType.STATUS_ENCERRADO.id,
    ].includes(serviceOrder.servcOrdStusCd)

    const hasNoBudget = !serviceOrder?.serviceOrderOccurrence.some(
      (ocurrence) => ocurrence.servcOrdEvntStusInd === ocurrencesType.BUDGET_SENDED,
    )

    const showAttachBudget =
      accessFunctionList?.includes(135) &&
      isTechVisit(serviceOrder.servcCatgryTypCd) &&
      isFinished &&
      hasNoBudget

    const notFinishedYet = ![
      ServiceOrderStatusType.STATUS_ENCERRADO.id,
      ServiceOrderStatusType.STATUS_CANCELADA.id,
      ServiceOrderStatusType.NAO_CONFORME.id,
    ].includes(serviceOrder?.servcOrdStusCd)

    const allowedStores = setupParameters?.storesHighRiskOsNeedToVisit
      ? setupParameters?.storesHighRiskOsNeedToVisit?.split(',')
      : []
    const storeIncluded = allowedStores.includes(serviceOrder?.plntCd) || !allowedStores.length
    const highRiskOS = serviceOrder?.servcCategory === 'HIGH_RISK' && storeIncluded

    const showAttachInspectionReport =
      accessFunctionList?.includes(137) && highRiskOS && notFinishedYet

    const hasTagManualDstrbt =
      serviceOrder.serviceOrderTags.filter(
        (e) => e.tagType === ServiceOrderTagType.MANUAL_DISTRIBUTED.tagName,
      ).length > 0

    const hasLateOrderTag =
      serviceOrder.serviceOrderTags.filter(
        (e) => e.tagType === ServiceOrderTagType.LATE_ORDER.tagName,
      ).length > 0

    const hasTagWaitingResponsible = !!serviceOrder.serviceOrderTags.find(
      (t) => t.tagType === ServiceOrderTagType.WAITING_RESPONSIBLE.tagName,
    )

    const hasNoSalesErrorTag = !serviceOrder.serviceOrderTags.find(
      (t) => t.tagType === ServiceOrderTagType.SALES_ERROR.tagName,
    )

    const showSalesErrorModal = accessFunctionList?.includes(141) && hasNoSalesErrorTag

    const isInstallationPlusVisit =
      accessFunctionList?.includes(131) &&
      isTechVisit(serviceOrder.servcCatgryTypCd) &&
      ServiceOrderTable.hasOccurrenceStatus(serviceOrder, '94')

    const showCancelButton =
      (ServiceOrderTable.isCancelable(serviceOrder) &&
        accessFunctionList?.includes(9) &&
        accessFunctionList?.includes(63)) ||
      (ServiceOrderTable.isCancelable(serviceOrder) && isInstallationPlusVisit)

    if (setupParameters.acceptanceSalesConditions && acceptanceSalesConditions(serviceOrder)) {
      showQrcode = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            openAcceptanceSalesConditions(index, serviceOrder)
          }}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='PromiseScan24' />
          <span className='color-default-dark'>{t('serviceOrders.show.qrcode')}</span>
        </a>
      )
    }

    if (
      ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id === serviceOrder.servcOrdStusCd &&
      setupParameters.conformity &&
      !!serviceOrder.isConformity
    ) {
      showQrcodeConformity = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            openModalQrConformTerms(index, serviceOrder)
          }}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='PromiseScan24' />
          <span className='color-default-dark'>{t('serviceOrders.show.qrcodeConformity')}</span>
        </a>
      )
    }

    if (
      this.needCoordinatorAttention(serviceOrder, funcaoAcesso) &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.OUT_OF_FLOW.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_CANCELADA.id
    ) {
      attentionServiceCoordinator = (
        <a
          role='button'
          className='display-flex'
          onClick={() =>
            generateAttentionOccurrence(index, serviceOrder, ServcOrdEvntStusType.ATTENTION.id)
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='NotificationWarning24' />
          <span className='color-default-dark'>
            {t('serviceOrders.menuAttentionServiceCoordinator')}
          </span>
        </a>
      )
    }

    if (accessFunctionList?.includes(25)) {
      printerButton = (
        <a role='button' className='display-flex' onClick={() => getPdf(serviceOrder.servcOrdSeq)}>
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Pdf24' />
          <span className='color-default-dark'>{t('serviceOrders.menuGetPdf')}</span>
        </a>
      )
    }

    if (
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_ASSOCIADA.id &&
      serviceOrder.servcPrvdrCd &&
      serviceOrder.servcPrvdrAgntCd &&
      accessFunctionList?.includes(3) &&
      accessFunctionList?.includes(4)
    ) {
      toConfirm = (
        <a
          role='button'
          className='display-flex'
          id='confirm-button'
          onClick={() =>
            updateOsStatusMenuShorcut(
              index,
              serviceOrder,
              ServiceOrderStatusType.STATUS_CONFIRMADA.id,
            )
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='NotificationAvailable24' />
          <span className='color-default-dark'>{t('serviceOrders.menuToConfirm')}</span>
        </a>
      )
    }

    if (
      accessFunctionList?.includes(35) &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_AGENDADA.id &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.OUT_OF_FLOW.id
    ) {
      backStatusButton = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            confirmModal('service.order.confirm.back.status', backStatus, [
              index,
              serviceOrder.servcOrdSeq,
              serviceOrder.servcOrdStusCd,
            ])
          }}
        >
          <MozaicIcon mr={8} ml={8} mt={-3} color='primary' icon='DisplayLogOut24' />
          <span className='color-default-dark'>{t('serviceOrders.menuBackStatus')}</span>
        </a>
      )
    }

    if (showCancelButton) {
      cancelButton = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            confirmModal('service.order.confirm.cancel', cancel, [serviceOrder, index, true], {
              textArea: true,
              textAreaLabel: 'service.order.confirm.cancel.label',
              textAreaCheckIsEmpty: true,
            })
          }}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='PublishTrashbin24' />
          <span className='color-default-dark'>{t('serviceOrders.menuCancel')}</span>
        </a>
      )
    }

    if (
      !hasLateOrderTag &&
      accessFunctionList?.includes(117) &&
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id &&
      hasTagManualDstrbt
    ) {
      bidMode = (
        <a
          role='button'
          className='align-items-center'
          onClick={() => handleSubmitNewBid(serviceOrder.servcOrdSeq)}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Document32' />
          <span className='color-default-dark'>{t('serviceOrders.bid.mode')}</span>
        </a>
      )
    }

    if (
      funcaoAcesso.inFuncao58 &&
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.LOJA_AGUARDANDO.id
    ) {
      makeBranchInRealization = (
        <a
          role='button'
          className='display-flex'
          onClick={() =>
            updateOsStatusMenuShorcut(
              index,
              serviceOrder,
              ServiceOrderStatusType.LOJA_EM_REALIZACAO.id,
            )
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DisplaySearchList24' />
          <span className='color-default-dark'>{t('serviceOrders.menuBranchInRealization')}</span>
        </a>
      )
    }

    if (
      accessFunctionList?.includes(59) &&
      serviceOrder.servcOrdStusCd === ServiceOrderStatusType.LOJA_EM_REALIZACAO.id
    ) {
      makeBranchDone = (
        <a
          role='button'
          className='display-flex'
          onClick={() =>
            updateOsStatusMenuShorcut(index, serviceOrder, ServiceOrderStatusType.LOJA_REALIZADO.id)
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DisplaySearchList24' />
          <span className='color-default-dark'>{t('serviceOrders.menuBranchDone')}</span>
        </a>
      )
    }

    if (
      accessFunctionList?.includes(67) &&
      serviceOrder.indAttention &&
      serviceOrder.indAttention === 1
    ) {
      removeAttentionServiceCoordinator = (
        <a
          role='button'
          className='display-flex'
          onClick={() =>
            generateAttentionOccurrence(
              index,
              serviceOrder,
              ServcOrdEvntStusType.REMOVED_ATTENTION.id,
            )
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DisplaySearchList24' />
          <span className='color-default-dark'>
            {t('serviceOrders.menuRemoveAttentionServiceCoordinator')}
          </span>
        </a>
      )
    }

    if (accessFunctionList?.includes(66) && serviceOrder.oldOrder) {
      closeOldOrderButton = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            confirmModal('service.order.confirm.closed', closeOldOrder, [serviceOrder, index], {
              textArea: true,
              textAreaLabel: 'service.order.confirm.cancel.label',
              textAreaCheckIsEmpty: true,
            })
          }}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DisplaySearchList24' />
          <span className='color-default-dark'>{t('service.orders.menu.cloded.old')}</span>
        </a>
      )
    }

    if (accessFunctionList?.includes(77) && serviceOrder.servcGrpFileName) {
      downloadSrvcGrpFile = (
        <a
          className='display-flex'
          role='link'
          target='_blank'
          rel='noopener'
          onClick={() => downloadFile(serviceOrder.servcGrpFileName, serviceOrder.servcGrpFileName)}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Print24' />
          <span className='color-default-dark'>
            {t('serviceGroup.download.termsAndConditions')}
          </span>
        </a>
      )
    }

    const dtCreatedOs = moment(serviceOrder.creatTs, moment.ISO_8601, true)
    const dtTagsFromCreatedOrders = moment(
      auth.setupParameters.displayTagsFromCreatedOrders,
      'L',
      true,
    )

    const dateIsBefore = dtCreatedOs.isBefore(dtTagsFromCreatedOrders)

    if (
      auth.setupParameters.serviceOrderOutOfFlowEnable &&
      accessFunctionList?.includes(86) &&
      ['00', '01'].find((number) => number === serviceOrder.servcOrdStusCd) &&
      (!serviceOrder.idOutFlowProcess || serviceOrder.idOutFlowProcess === OUT_FLOW_REFUSED) &&
      dateIsBefore
    ) {
      outOfFlowRequest = (
        <a
          role='button'
          className='display-flex'
          onClick={() =>
            outOfFlow(index, serviceOrder, ServcOrdEvntStusType.OUT_OF_FLOW_REQUEST.id)
          }
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DeliveryDeliveryPickupStore24' />
          <span className='color-default-dark'>{t('outOfFlowRequest.button')}</span>
        </a>
      )
    }

    if (
      auth.setupParameters.serviceOrderOutOfFlowEnable &&
      accessFunctionList?.includes(87) &&
      serviceOrder.idOutFlowProcess === OUT_FLOW_AWAITING
    ) {
      approveOutOfFlowRequest = (
        <a
          role='button'
          className='display-flex'
          onClick={() => approveOutOfFlow(index, serviceOrder)}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DeliveryDeliveryPickupStore24' />
          <span className='color-default-dark'>{t('approveOutOfFlowRequest.option')}</span>
        </a>
      )
    }

    if (
      accessFunctionList?.includes(97) &&
      (serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_PRE_AGENDADA.id ||
        serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_AGENDADA.id ||
        serviceOrder.servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id) &&
      !hasTagManualDstrbt
    ) {
      manualDistributionButton = (
        <a
          role='button'
          className='display-flex'
          onClick={() => {
            confirmModal(
              t('serviceorder.manual.distribution.confirm'),
              (values, i, text) => {
                generateManualDistributionOccurrence(serviceOrder, index, text)
              },
              [serviceOrder, index],
              {
                textArea: true,
                textAreaLabel: t('serviceorder.manual.distribution.reason'),
                textAreaCheckIsEmpty: true,
              },
            )
          }}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='PublishEdit24' />
          <span className='color-default-dark'>{t('serviceorder.manual.distribution.text')}</span>
        </a>
      )
    }

    if (
      accessFunctionList &&
      accessFunctionList?.includes(109) &&
      serviceOrder.servcOrdPriortyCd &&
      serviceOrder.servcOrdStusCd !== ServiceOrderStatusType.STATUS_ENCERRADO.id
    ) {
      closingNoConformity = (
        <a
          role='button'
          className='display-flex'
          onClick={() => handleClosingNoConfirmity(serviceOrder.servcOrdSeq)}
        >
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='NotificationWarning24' />
          <span className='color-default-dark'>{t('closing.non.conformity')}</span>
        </a>
      )
    }

    if (accessFunctionList && accessFunctionList?.includes(136) && hasTagWaitingResponsible) {
      assignToMe = (
        <a role='button' className='display-flex' onClick={() => handleAssignToMe(serviceOrder)}>
          <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='AccountProfileView24' />
          <span className='color-default-dark'>
            {t('serviceOrder.popupMenu.assignToResponsible')}
          </span>
        </a>
      )
    }

    const attachmentBudget = showAttachBudget ? (
      <a
        role='button'
        className='display-flex'
        onClick={() => openAttachBudgetModal(serviceOrder.servcOrdSeq)}
      >
        <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Document32' />
        <span className='color-default-dark'>{t('serviceOrder.context.menu.attach.budget')}</span>
      </a>
    ) : (
      false
    )

    const attachInspectionReport = showAttachInspectionReport ? (
      <a
        role='button'
        className='display-flex'
        onClick={() => openAttachInspectionModal(serviceOrder.servcOrdSeq)}
      >
        <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Document32' />
        <span className='color-default-dark'>
          {t('serviceOrder.context.menu.attach.inspection')}
        </span>
      </a>
    ) : (
      false
    )

    const salesError = showSalesErrorModal ? (
      <a
        role='button'
        className='display-flex'
        onClick={() => openSalesErrorModal(serviceOrder.servcOrdSeq, serviceOrder.servcOrdStusCd)}
      >
        <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='Document32' />
        <span className='color-default-dark'>{t('access.function141')}</span>
      </a>
    ) : (
      false
    )

    return (
      <div className='tooltip-menu' style={{ width: 240 }}>
        {attentionServiceCoordinator}
        {attachmentBudget}
        {attachInspectionReport}
        {printerButton}
        {toConfirm}
        {backStatusButton}
        {cancelButton}
        {bidMode}
        {editButton}
        {viewButton}
        {makeBranchInRealization}
        {makeBranchDone}
        {removeAttentionServiceCoordinator}
        {closeOldOrderButton}
        {downloadSrvcGrpFile}
        {outOfFlowRequest}
        {approveOutOfFlowRequest}
        {showQrcode}
        {showQrcodeConformity}
        {manualDistributionButton}
        {closingNoConformity}
        {assignToMe}
        {salesError}
      </div>
    )
  }

  mountTag = (tag, t) => {
    const status = ServiceOrderTagType.getStatusByTagName(tag.tagType)
    if (status.tagName) {
      return <MozaicBadge className={status.className} text={t(status.translation)} />
    }
  }

  resolveWorksiteCounter = (total) => {
    const { t } = this.props
    const service = 'service.order.details.service' // Service
    const services = 'homepage.tab.services' // Services
    const selectedLabel = total === 1 ? service : services
    return `(${total || 0} ${t(selectedLabel)})`
  }

  resolvecustIdNr(value) {
    const { t } = this.props
    if (value) {
      const newValue = value.replace(/[^\d]/g, '')
      return newValue.length <= 11 ? formatCPF(newValue, t) : formatCNPJ(newValue, t)
    }
  }

  renderRows = (serviceOrder, index) => {
    const { t, makeCall, serviceOrderShowAmount, accessFunctionList, handleSelectWorksite } =
      this.props

    const { custMobilePhonNr, custBusnsPhonNr, custHmPhonNr } = serviceOrder

    const formattedBusinessPhone = formatNationalOrInternationalPhoneNumber(t, custBusnsPhonNr)
    const formattedMobilePhone = formatNationalOrInternationalPhoneNumber(t, custMobilePhonNr)
    const formattedHomePhone = formatNationalOrInternationalPhoneNumber(t, custHmPhonNr)

    const executionDates = ServiceOrderTable.getExecutionDates(serviceOrder)

    const tags = serviceOrder.serviceOrderTags

    const showResponsibleName =
      serviceOrder.prjctCpcNm ||
      !!serviceOrder.serviceOrderTags.find(
        (t) => t.tagType === ServiceOrderTagType.WAITING_RESPONSIBLE.tagName,
      )

    const responsibleName =
      serviceOrder.prjctCpcNm === ASSIGNED_TO_SERVICE_CORE
        ? t('serviceOrder.assignToResponsible.assignedToServiceCore')
        : serviceOrder.prjctCpcNm || ''

    return (
      <tr key={index}>
        <td className='column-order-services'>
          {this.renderServiceOrderId(serviceOrder, index)}
          <div>
            <span className='noselect'>{`${t('serviceOrders.order')}: `}</span>
            <span>{serviceOrder.vaSlsOrdNr}</span>
          </div>
          <div className='worksite-container-order-services flex flex-column'>
            <span>Worksite:</span>
            <a
              role='button'
              className='color-primary'
              onClick={() => handleSelectWorksite(serviceOrder.servcOrdWorksiteNumber)}
            >
              <u>{serviceOrder.servcOrdWorksiteNumber}</u>
            </a>
            <span>{this.resolveWorksiteCounter(serviceOrder.sameWorksiteServiceCount)}</span>
          </div>
          <div className='noselect'>
            {ServiceOrderTable.urlMaps(serviceOrder)}
            {ServiceOrderTable.getFluxIcon(serviceOrder)}
          </div>
        </td>
        <td className='column-order-services noselect'>
          <div className='heading-6 noselect'>{`${t('serviceOrders.status')}: ${t(
            ORDER_STATUS_TYPES[serviceOrder.servcOrdStusCd],
          )}`}</div>
          <div className='heading-6 noselect'>{`${t('serviceOrders.channel')}: ${t(
            serviceOrder.slsChnlNm,
          )}`}</div>
          <div className='heading-6 noselect'>{`${t('serviceOrders.store')}: ${t(
            serviceOrder.plntNm,
          )}`}</div>

          {tags && tags.map((tag) => this.mountTag(tag, t))}
        </td>

        <td className='column-order-services noselect'>
          <div className='heading-6'>
            {t('serviceOrders.category')}{' '}
            {t(ServcOrdCategoryType.getCatgryTypDesc(serviceOrder.servcCatgryTypCd))}
          </div>
          <div className='color-default-dark'>
            <span>{`${t('serviceOrders.provider')}:`}</span>{' '}
            {serviceOrder.servcPrvdrTrdNm || serviceOrder.servcPrvdrNm || ''}
          </div>
          <div className='color-default-dark noselect'>
            <span className='color-default-dark'>{`${t(
              'serviceOrders.nomeEspecialistaServico',
            )}:`}</span>{' '}
            {serviceOrder.servcPrvdrAgntNm || ''}
          </div>
          {showResponsibleName && (
            <div className='color-default-dark noselect'>
              <span className='color-default-dark'>{`${t('serviceOrder.responsibleName')}:`}</span>{' '}
              {responsibleName}
            </div>
          )}
        </td>

        <td className='column-order-services noselect'>
          <div className='heading-5'>
            {formatDecimal(
              serviceOrderShowAmount ? serviceOrder.servcOrdAmt : serviceOrder.servcOrdTtlVl,
              t,
            )}
          </div>
        </td>

        <td className='column-order-services noselect'>
          <div className='heading-5'>{`${
            serviceOrder.serviceAmt ? serviceOrder.serviceAmt : ''
          }`}</div>
        </td>

        <td className='column-order-services noselect'>
          <div>
            <span className='color-default-dark'>{`${t(
              'serviceOrders.creationDateSimple',
            )}: `}</span>
            {serviceOrder.creatTs ? moment(serviceOrder.creatTs).format(FORMAT_DATE) : ''}
          </div>
          <div>
            <span className='color-default-dark'>{`${t('delivery.date.text')}: `}</span>
            {serviceOrder.servcOrdPrmsdDlvryDt
              ? moment(serviceOrder.servcOrdPrmsdDlvryDt).format(FORMAT_DATE_TRUNC)
              : ''}
          </div>
          <div>
            <span className='color-default-dark'>{`${t(
              'serviceOrders.schedulingDateSimple',
            )}: `}</span>
            {serviceOrder.servcOrdSchdlgTs
              ? `${moment(serviceOrder.servcOrdSchdlgTs).format(FORMAT_DATE_TRUNC)} - ${t(
                  serviceOrder.dsSlotKey,
                )}`
              : ''}
          </div>
          <div>
            <span className='color-default-dark'>{`${t(
              'serviceOrders.billingDateSimple',
            )}: `}</span>{' '}
            {serviceOrder.servcOrdInvcIssuDt
              ? moment(serviceOrder.servcOrdInvcIssuDt).format(FORMAT_DATE)
              : ''}
          </div>
          <div>
            <span className='color-default-dark'>{`${t(
              'serviceOrders.executionDateStart',
            )}: `}</span>
            {serviceOrder.servcXecutnStrtDt
              ? moment(serviceOrder.servcXecutnStrtDt).format(FORMAT_DATE)
              : executionDates.start}
          </div>
          <div>
            <span className='color-default-dark'>{`${t('serviceOrders.executionDateEnd')}: `}</span>
            {serviceOrder.servcXecutnEndDt
              ? moment(serviceOrder.servcXecutnEndDt).format(FORMAT_DATE)
              : executionDates.end}
          </div>
        </td>

        <td className='column-order-services'>
          <div className='heading-4 uppercase noselect'>{serviceOrder.custFullNm}</div>
          <div className='heading-5 uppercase'>
            <span className='noselect'>{t(DOCUMENT_TYPE[serviceOrder.custIdTyp])}: </span>
            {this.resolvecustIdNr(serviceOrder?.custIdNr)}
          </div>
          <div>
            <span className='color-default-dark noselect'>{`${t('serviceOrders.phone1')}: `}</span>

            <a
              className='color-primary'
              onClick={() => makeCall(removeMask(formattedMobilePhone), serviceOrder)}
            >
              {formattedMobilePhone}
            </a>
            {accessFunctionList && accessFunctionList?.includes(98) && (
              <CopyToClipboardIcon text={formattedMobilePhone} t={t} />
            )}
          </div>
          <div>
            <span className='color-default-dark noselect'>{`${t('serviceOrders.phone3')}: `}</span>
            <a
              className='color-primary'
              onClick={() => makeCall(removeMask(formattedHomePhone), serviceOrder)}
            >
              {formattedHomePhone}
            </a>
            {accessFunctionList && accessFunctionList?.includes(98) && (
              <CopyToClipboardIcon text={formattedHomePhone} t={t} />
            )}
          </div>
          <div>
            <span className='color-default-dark noselect'>{`${t('serviceOrders.phone2')}: `}</span>
            <a
              className='color-primary'
              onClick={() => makeCall(removeMask(formattedBusinessPhone), serviceOrder)}
            >
              {formattedBusinessPhone}
            </a>
            {accessFunctionList && accessFunctionList?.includes(98) ? (
              <CopyToClipboardIcon text={formattedBusinessPhone} t={t} />
            ) : (
              ''
            )}
          </div>
        </td>

        <td
          className='column-order-services noselect'
          style={{ textAlign: 'right', verticalAlign: 'top' }}
        >
          <Tooltip
            trigger='click'
            position='left'
            interactive
            html={this.renderMenu(serviceOrder, index)}
            interactiveBorder={12}
            distance={0}
          >
            <a role='button' className='padding display-block'>
              <MozaicIcon mr={8} ml={5} mt={-3} color='primary' icon='DisplayOptions32' />
            </a>
          </Tooltip>
        </td>
      </tr>
    )
  }

  render() {
    const { serviceOrders, t } = this.props

    return (
      <table className='responsive-table table table-bordered margin-top-small'>
        <thead>
          <tr className='color-primary noselect'>
            <th>
              <a>
                <h5 className='margin-top-small margin-bottom-small'>
                  {t('serviceOrders.serviceOrder')}
                </h5>
              </a>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>{t('serviceOrders.status')}</h5>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>
                {t('serviceOrders.serviceGroup')}
              </h5>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>{t('serviceOrders.value')}</h5>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>
                {t('serviceOrders.serviceAmount')}
              </h5>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>{t('serviceOrders.dates')}</h5>
            </th>
            <th>
              <h5 className='margin-top-small margin-bottom-small'>{t('serviceOrders.client')}</h5>
            </th>
            <th style={{ textAlign: 'center' }} />
          </tr>
        </thead>
        <tbody>{serviceOrders.map(this.renderRows)}</tbody>
      </table>
    )
  }
}

ServiceOrderTable.propTypes = {
  generateManualDistributionOccurrence: PropTypes.func.isRequired,
  serviceOrders: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  funcaoAcesso: PropTypes.object,
  getPdf: PropTypes.func.isRequired,
  backStatus: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  makeCall: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  updateOsStatusMenuShorcut: PropTypes.func.isRequired,
  generateAttentionOccurrence: PropTypes.func.isRequired,
  closeOldOrder: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  serviceOrderShowAmount: PropTypes.bool.isRequired,
  outOfFlow: PropTypes.func,
  approveOutOfFlow: PropTypes.func,
  accessFunctionList: PropTypes.array,
  auth: PropTypes.object,
  openAcceptanceSalesConditions: PropTypes.func.isRequired,
  openModalQrConformTerms: PropTypes.func.isRequired,
  openSalesErrorModal: PropTypes.func,
  handleClosingNoConfirmity: PropTypes.func.isRequired,
  handleAssignToMe: PropTypes.func.isRequired,
  handleSelectWorksite: PropTypes.func.isRequired,
  handleSubmitNewBid: PropTypes.func,
  openAttachBudgetModal: PropTypes.func,
  openAttachInspectionModal: PropTypes.func,
  getServiceOrder: PropTypes.func,
}

export default ServiceOrderTable
