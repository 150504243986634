import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import DateSelect from '../../../components/DateSelect'
import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Row from '../../../components/utils/Row'
import { useServiceOrderContext } from '../../../contexts/serviceOrder'
import { renderToastWarn } from '../../../helpers/ToastUtils'
import { colourStyles } from '../../../helpers/selectStyle'
import { useForm } from '../../../helpers/useForm'
import ShiftSelect from './ShiftSelect'

const FIELDS = {
  reason: null,
  description: '',
  shift: '',
  date: null,
  sameProvider: true,
}

const validate = (value, key) => {
  if (value === undefined || value === null || value === '') {
    return 'serviceOrder.warranty.{field}.validation'.replace('{field}', key)
  }

  if (key === 'description' && value.length < 10) {
    return 'serviceOrder.warranty.error.description'
  }

  return null
}

function Warranty() {
  const {
    t,
    auth,
    serviceOrder,
    serviceOrder: { servcOrdSeq },
    auth: { user },
    warrantyActivation,
    afterUpdate,
    goToServiceOrder,
    reasons,
  } = useServiceOrderContext()

  const { values, fields, canSubmit, register, setFormValue } = useForm(FIELDS)
  const { reason, description, date, shift, sameProvider } = values

  const { prvdrIsReceivingOrders } = serviceOrder

  const [triggered, setTriggered] = useState(false)

  useEffect(() => {
    if (serviceOrder.prvdrIsReceivingOrders === false) {
      setFormValue({ sameProvider: false })
    }

    register('sameProvider', { required: true })
  }, [])

  useEffect(() => {
    if (prvdrIsReceivingOrders && prvdrIsReceivingOrders === false) {
      setFormValue({ sameProvider: false })
    } else if (prvdrIsReceivingOrders && prvdrIsReceivingOrders === true) {
      setFormValue({ sameProvider: true })
    }
  }, [prvdrIsReceivingOrders])

  function cancelWarranty() {
    Object.keys(values).forEach((field) => {
      if (field === 'sameProvider' && !serviceOrder.prvdrIsReceivingOrders) return
      setFormValue({ [field]: undefined })
    })

    setTriggered(false)
  }

  function handleSubmit() {
    if ((description || '').length < 10 || !(description || '').match(/[a-zA-Z]/g)) {
      renderToastWarn(t('serviceOrder.warranty.error.description'))
    } else {
      const params = {
        servcOrdWrntDesc: description,
        scheduledDate: moment(date, true).format(),
        scheduledTurn: shift,
        reasonId: reason.value,
        servcOrdOrignSeq: servcOrdSeq,
        isSameProvider: sameProvider,
      }

      warrantyActivation(user.dsToken, params).then(() => afterUpdate())
    }
  }

  const options = reasons
    .filter(
      ({ actvOrdEvntTypRsnInd, servcOrdEvntTyp }) =>
        actvOrdEvntTypRsnInd === '1' && servcOrdEvntTyp === 6,
    )
    .map(({ servcOrdEvntTypRsnDesc, servcOrdEvntTypRsnId }) => ({
      label: servcOrdEvntTypRsnDesc,
      value: servcOrdEvntTypRsnId,
    }))

  const hasWarranty = !!serviceOrder.warrantyRemainingDays
  const activatedWarranty = !!serviceOrder.warrantyOrign
  const numberOs = !!serviceOrder.warrantyOrign && serviceOrder.warrantyOrign.servcOrdCd
  const enableCreateNewWarranty = hasWarranty && user.accessFunctionList?.includes(119)

  return (
    <div className='warranty-activation'>
      <Row>
        <Col xs={12} className='title-section no-padding-side'>
          <h1>{t('serviceOrders.warranty')}</h1>
        </Col>
      </Row>
      {hasWarranty ? (
        <Row>
          <Col xs={12} className='validation-text'>
            <span>
              {t('serviceOrder.warranty.text')
                .replace('{date}', moment(serviceOrder.warrantyMaxDt).format('L'))
                .replace('{remaining}', serviceOrder.warrantyRemainingDays)}
            </span>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} className='no-warranty-text'>
            <span>{t('serviceOrder.noWarranty')}</span>
          </Col>
        </Row>
      )}
      {triggered || activatedWarranty ? (
        <>
          <Row>
            <Col xs={8}>
              <Field
                {...fields.reason}
                error={t(fields.reason.error)}
                className='no-padding no-margin no-glyph'
              >
                <label className='label'>{t('serviceOrder.outOfFlow.reason')}</label>
                <Select
                  {...register('reason', {
                    parser: (e) => e,
                    getter: (e) =>
                      activatedWarranty
                        ? options.find(
                            ({ label }) => label === serviceOrder.warrantyOrign.reasonDesc,
                          )
                        : options.find(({ value }) => value === e),
                    required: true,
                    validate,
                  })}
                  options={options}
                  placeholder={t('serviceOrder.litigation.select.reason')}
                  classNamePrefix='react-select'
                  styles={colourStyles}
                  isDisabled={activatedWarranty}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <MozaicTextArea
                {...register('description', {
                  getter: (e) =>
                    activatedWarranty ? serviceOrder.warrantyOrign.servcOrdWrntDesc : e,
                  required: true,
                  validate,
                })}
                error={t(fields.description.error)}
                isValid={!fields.description.error}
                isInvalid={fields.description.error}
                label={t('serviceOrders.reason.description')}
                className='warranty-activation-label textarea-custom'
                placeholder={t('serviceOrders.reason.description')}
                isDisabled={activatedWarranty}
                maxLength={40960}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Field {...fields.date} error={t(fields.date.error)} className='warrantyDateSelect'>
                <label className='label'>{t('filter.field.scheduling.date')}</label>
                <DateSelect
                  {...register('date', {
                    parser: (e) => e,
                    getter: (e) =>
                      activatedWarranty ? moment(serviceOrder.warrantyOrign.scheduledDate) : e,
                    required: true,
                    validate,
                  })}
                  placeholder={t('servcOrd.service.group.date.placeholder')}
                  disabled={activatedWarranty}
                />
              </Field>
            </Col>
            <Col xs={3}>
              <Field {...fields.shift} error={t(fields.shift.error)}>
                <label className='label'>{t('serviceOrders.schedule.shift')}</label>
                <ShiftSelect
                  {...register('shift', {
                    parser: (e) => e.value,
                    getter: () =>
                      activatedWarranty ? serviceOrder.warrantyOrign.scheduledTurn : values.shift,
                    required: true,
                    validate,
                  })}
                  t={t}
                  auth={auth}
                  isClearable={false}
                  disabled={activatedWarranty}
                />
              </Field>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Field
                {...fields.sameProvider}
                validate={false}
                hint={
                  !serviceOrder.prvdrIsReceivingOrders && !activatedWarranty
                    ? t('serviceOrders.sameProvider.unavailable')
                    : ''
                }
                disabled={activatedWarranty || !serviceOrder.prvdrIsReceivingOrders}
              >
                <div>
                  <label className='label'>{t('serviceOrders.sameProvider.select')}</label>
                </div>
                <div>
                  <span className='check-input'>
                    <input
                      checked={
                        (activatedWarranty
                          ? serviceOrder.warrantyOrign.isSameProvider
                          : values.sameProvider) === true
                      }
                      onChange={() => setFormValue({ sameProvider: true })}
                      type='radio'
                      name='field.name.yes'
                      id='field.name.yes'
                      className='warranty-radio'
                      disabled={activatedWarranty || !serviceOrder.prvdrIsReceivingOrders}
                    />
                    <label
                      {...(!activatedWarranty &&
                        serviceOrder.prvdrIsReceivingOrders && {
                          className: 'cursor-pointer',
                          onClick: () => setFormValue({ sameProvider: true }),
                        })}
                    >
                      {t('field.name.yes')}
                    </label>
                  </span>
                  <span className='check-input'>
                    <input
                      checked={
                        (activatedWarranty
                          ? serviceOrder.warrantyOrign.isSameProvider
                          : values.sameProvider) === false
                      }
                      onChange={() => setFormValue({ sameProvider: false })}
                      type='radio'
                      name='field.name.no'
                      id='field.name.no'
                      className='warranty-radio'
                      disabled={activatedWarranty || !serviceOrder.prvdrIsReceivingOrders}
                    />
                    <label
                      {...(!activatedWarranty &&
                        serviceOrder.prvdrIsReceivingOrders && {
                          className: 'cursor-pointer',
                          onClick: () => setFormValue({ sameProvider: false }),
                        })}
                    >
                      {t('field.name.no')}
                    </label>
                  </span>
                </div>
              </Field>
            </Col>
          </Row>
          {activatedWarranty ? (
            <Row className='footer-button'>
              <Col xs={4}>
                <button
                  className='button-hollow button-hollow-primary button-full'
                  onClick={() => goToServiceOrder(numberOs)}
                >
                  {t('warrantyActivated.serviceOrder.goToNew').replace('{serviceOrder}', numberOs)}
                </button>
              </Col>
              <Col xs={4}>
                <span>
                  {t('warrantyActivated.serviceOrder.date')
                    .replace('{date}', moment(serviceOrder.warrantyOrign.creatTs).format('L'))
                    .replace('{time}', moment(serviceOrder.warrantyOrign.creatTs).format('LTS'))}
                </span>
              </Col>
            </Row>
          ) : (
            <Row className='footer-button'>
              <Col xs={4}>
                <button
                  className='button-hollow button-hollow-primary button-full'
                  onClick={() => cancelWarranty()}
                >
                  {t('serviceOrders.cancelWarranty')}
                </button>
              </Col>
              <Col xs={4}>
                <button
                  className='button button-primary button-full'
                  onClick={() => handleSubmit()}
                  disabled={!canSubmit}
                >
                  {t('serviceOrders.confirmWarranty')}
                </button>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row>
          {enableCreateNewWarranty && (
            <Col xs={2}>
              <button
                className='button-hollow button-hollow-primary warranty-create-button button-full'
                onClick={() => setTriggered(true)}
              >
                {t('serviceOrders.createWarranty')}
              </button>
            </Col>
          )}
        </Row>
      )}
    </div>
  )
}

export default Warranty
