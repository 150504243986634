import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import store from '../../redux/store'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

interface SendContractParams {
  providerId: number
  contractSent: boolean
}

export function sendContractServiceProvider(
  params: SendContractParams,
): Promise<ADEOResponse<any>> {
  return new API_REQUEST(user.currentUser.dsToken, store.dispatch).post(
    API_URL.SENT_CONTRACT,
    params,
  )
}
