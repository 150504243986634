import Client from './components/Client'
import Project from './components/Project'
import ErrorBoundary from '../../../../errors/boundaries'
import { useProjectDetailsContext } from '../../context'

function Resume() {
  const { getProjectDetails } = useProjectDetailsContext()

  return (
    <div id='project-resume'>
      <ErrorBoundary resetErrorBoundary={getProjectDetails}>
        <Project />
        <Client />
      </ErrorBoundary>
    </div>
  )
}

export default Resume
