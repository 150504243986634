import { useState } from 'react'
import i18next from 'i18next'
import Modal from '../../../components/Modal'
import MozaicButton from '../../../components/MozaicButton'
import {
  ResponsibleType,
  assignToResponsible,
} from '../../../services/pages/ServiceOrderDetail/AssignToResponsible'
import { ServiceOrderEntity } from '../../../models/ServiceOrder'

interface AssignToMeModalProps {
  closeModal: Function
  serviceOrder: ServiceOrderEntity
  reloadServiceOrder: Function
}

function AssignToMeModal(props: AssignToMeModalProps) {
  const { closeModal, serviceOrder, reloadServiceOrder } = props

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAssign = async (responsibleType: ResponsibleType) => {
    setIsSubmitting(true)
    await assignToResponsible(serviceOrder?.servcOrdSeq!, responsibleType)

    closeModal()
    setIsSubmitting(false)

    await reloadServiceOrder()
  }

  return (
    <div className='pop-up-modal'>
      <Modal
        size='lg'
        isOpen
        onClose={() => closeModal()}
        className='no-margin-header'
        closeInOverlay={false}
        contentLabel={i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.title')}
      >
        <h4>{i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.content')}</h4>

        <footer>
          <MozaicButton
            variant='ghost'
            theme='primary-02'
            onClick={() => closeModal()}
            isDisabled={isSubmitting}
          >
            {i18next.t('button.label.cancel')}
          </MozaicButton>

          <MozaicButton
            onClick={() => handleAssign(ResponsibleType.loggedUser)}
            isDisabled={isSubmitting}
          >
            {i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.assignToMe')}
          </MozaicButton>

          <MozaicButton
            onClick={() => handleAssign(ResponsibleType.serviceCore)}
            isDisabled={isSubmitting}
          >
            {i18next.t('serviceOrder.popupMenu.assignToResponsible.modal.assignToServiceCore')}
          </MozaicButton>
        </footer>
      </Modal>
    </div>
  )
}

export default AssignToMeModal
