/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useEffect, useState } from 'react'
import { TAG_TYPES, TagItem } from '../../../enums/ServiceOrder/tags/tag-types'
import { ProjectDetailsProviderType, ProjectEntity } from '../../../models/Project'
import { ServiceExecutionEntity } from '../../../models/ServiceExecution'
import {
  fetchProjectDetails,
  fetchProjectServices,
  fetchProjectTags,
} from '../../../services/Project'

const ProjectDetailsContext = createContext<ProjectDetailsProviderType | null>(null)

function ProjectDetailsProvider({ children, projectID }: any) {
  const [loadingProject, setLoadingProject] = useState<boolean>(false)
  const [project, setProject] = useState<ProjectEntity | null>(null)

  const [loadingServices, setLoadingServices] = useState<boolean>(false)
  const [services, setServices] = useState<ServiceExecutionEntity[] | null>([])

  const [loadingTags, setLoadingTags] = useState<boolean>(false)
  const [tags, setTags] = useState<TagItem[]>([])

  async function getProjectDetails() {
    setLoadingProject(true)

    const projectDetails = await fetchProjectDetails(projectID)

    setProject(projectDetails)
    setLoadingProject(false)
  }

  async function getOrderTags() {
    setLoadingTags(true)

    const projectTags = await fetchProjectTags()

    const parsedTags = projectTags
      .filter((t) => TAG_TYPES[t.tagType])
      .map((t) => TAG_TYPES[t.tagType])

    setTags(parsedTags)
    setLoadingTags(false)
  }

  async function getProjectExecutions() {
    setLoadingServices(true)

    const projectServices = await fetchProjectServices(projectID)

    setServices(projectServices)
    setLoadingServices(false)
  }

  async function reloadProject() {
    const reqBody = await getProjectDetails()
    const reqTags = await getOrderTags()
    const reqExecs = await getProjectExecutions()

    await Promise.all([reqBody, reqTags, reqExecs])

    return true
  }

  useEffect(() => {
    reloadProject()
  }, [])

  const serviceOrderDetailValues: ProjectDetailsProviderType = {
    loadingTags,
    tags,
    loadingProject,
    project,
    loadingServices,
    services,
    projectID,
    getProjectExecutions,
    getProjectDetails,
  }

  return (
    <ProjectDetailsContext.Provider value={serviceOrderDetailValues}>
      {children}
    </ProjectDetailsContext.Provider>
  )
}

const useProjectDetailsContext = () =>
  useContext(ProjectDetailsContext) as ProjectDetailsProviderType

export { ProjectDetailsContext, ProjectDetailsProvider, useProjectDetailsContext }
