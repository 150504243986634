import PropTypes from 'prop-types'
import { Component } from 'react'
import Modal from '../../../components/utils/Modal'
import BudgetsUploadFile from './modalFormBudgets/BudgetsUploadFile'

class BudgetsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      size: 'md',
    }
  }

  renderContent = (props) => {
    const newProps = {
      ...props,
    }
    return <BudgetsUploadFile {...newProps} />
  }

  render() {
    const { size } = this.state
    const { className, closeModal, showCloseButton } = this.props

    return (
      <Modal
        closeModal={closeModal}
        size={size}
        showCloseButton={showCloseButton}
        className={className}
      >
        {this.renderContent(this.props)}
      </Modal>
    )
  }
}

BudgetsModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.any,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  auth: PropTypes.object,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.object,
  action: PropTypes.func,
  sendOrder: PropTypes.func,
  serviceOrder: PropTypes.object,
  deleteAtchmt: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  blockButtonRemove: PropTypes.bool.isRequired,
}

export default BudgetsModal
