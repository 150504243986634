import PropTypes from 'prop-types'
import { Component } from 'react'
import Modal from '../../../components/utils/Modal'
import ServiceOrderAddOccurrence from './ServiceOrderAddOccurrence'
import ServiceOrderAlterAttrAndFone from './ServiceOrderAlterAttrAndFone'
import ServiceOrderLaudo from './ServiceOrderLaudo'
import ServiceOrderListDocuments from './ServiceOrderListDocuments'
import ServiceOrderObservation from './ServiceOrderObservation'
import ServiceOrderPreScheduled from './ServiceOrderPreScheduled'
import ServiceOrderRescheduling from './ServiceOrderRescheduling'
import ServiceOrderWorksite from './ServiceOrderWorksite'
import ServiceOrderDocuments from './documents/Documents'

const ADD_OCCURRENCE = 'ADD_OCCURRENCE'
const LIST_DOCUMENTS = 'LIST_DOCUMENTS'
const VIEW_OBSERVATION = 'VIEW_OBSERVATION'
const LAUDO = 'LAUDO'
const ALTER_INFO = 'ALTER_INFO'
const DOCUMENTS = 'DOCUMENTS'
const RESCHEDULING = 'RESCHEDULING'
const WORKSITE = 'WORKSITE'
const PRE_RESCHEDULED = 'PRE_RESCHEDULED'
const HUSBAND_MODAL = 'HUSBAND_MODAL'

class ServiceOrderModal extends Component {
  constructor(props) {
    super(props)

    const { modalType } = this.props

    let size
    switch (modalType) {
      case ADD_OCCURRENCE:
      case LIST_DOCUMENTS:
        size = 'sm'
        break

      case ALTER_INFO:
      case LAUDO:
        size = 'lg'
        break

      case VIEW_OBSERVATION:
      case DOCUMENTS:
        size = 'md'
        break
      case RESCHEDULING:
        size = 'sm'
        break
      case WORKSITE:
        size = 'md'
        break
      case PRE_RESCHEDULED:
        size = 'sm'
        break
      case HUSBAND_MODAL:
        size = 'md'
        break
    }

    this.state = {
      size,
    }
  }

  renderContent = () => {
    const { props } = this

    switch (props.modalType) {
      case ADD_OCCURRENCE:
        return <ServiceOrderAddOccurrence {...props} />

      case LIST_DOCUMENTS:
        return <ServiceOrderListDocuments {...props} />

      case VIEW_OBSERVATION:
        return <ServiceOrderObservation {...props} />

      case LAUDO:
        return <ServiceOrderLaudo {...props} />

      case ALTER_INFO:
        return <ServiceOrderAlterAttrAndFone {...props} />

      case DOCUMENTS:
        return <ServiceOrderDocuments {...props} />

      case RESCHEDULING:
        return <ServiceOrderRescheduling {...props} />

      case WORKSITE:
        return <ServiceOrderWorksite {...props} />

      case PRE_RESCHEDULED:
        return <ServiceOrderPreScheduled {...props} />

    }
  }

  render() {
    const { size } = this.state
    const { className, closeModal, showCloseButton } = this.props

    return (
      <Modal
        closeModal={closeModal}
        size={size}
        showCloseButton={showCloseButton}
        className={className}
      >
        {this.renderContent()}
      </Modal>
    )
  }
}

ServiceOrderModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.any,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.any,
  auth: PropTypes.object.isRequired,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.object,
  action: PropTypes.func,
  sendOrder: PropTypes.func,
  serviceOrder: PropTypes.object,
}

export default ServiceOrderModal
