/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */

import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import MozaicText from '../../../../components/MozaicText'

function SubMenuItems({ subMenuItems, onClickSubmenuItem, isSubMenuOpen, currentMenuLabel }) {
  const handleOnClick = (item) => (item.onClick ? item.onClick() : onClickSubmenuItem(item))

  const NewTabLink = (item) => {
    const { label, url, newTab } = item

    if (!url) {
      return (
        <MozaicText title={label} onClick={() => handleOnClick(item)}>
          {label}
        </MozaicText>
      )
    }

    if (newTab) {
      return (
        <a
          href={url}
          title={label}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleOnClick(item)}
        >
          <MozaicText>{label}</MozaicText>
        </a>
      )
    }
    return (
      <Link to={url} title={label} onClick={() => handleOnClick(item)}>
        <MozaicText>{label}</MozaicText>
      </Link>
    )
  }

  return (
    <ul
      className={classnames('submenu-items', {
        'submenu-is-open': isSubMenuOpen,
      })}
    >
      {subMenuItems.map((item) => {
        if (!item.render) return null
        return (
          <li
            key={item.label}
            className={classnames('submenu-item', {
              'is-active': item.label === currentMenuLabel,
            })}
          >
            {NewTabLink(item)}
          </li>
        )
      })}
    </ul>
  )
}

SubMenuItems.propTypes = {
  subMenuItems: PropTypes.array.isRequired,
  onClickSubmenuItem: PropTypes.func.isRequired,
  currentMenuLabel: PropTypes.string,
  isSubMenuOpen: PropTypes.bool,
}

export default SubMenuItems
