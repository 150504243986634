import MozaicIcon from '../MozaicIcon'

interface NativeDatePickerProps {
  id: string
  name: string
  label: string
  value: string
  disabled: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 * @description Reusable component to picker dates
 */

function NativeDatePicker({
  id,
  name,
  label,
  value,
  disabled,
  onChange,
}: NativeDatePickerProps): JSX.Element {
  return (
    <div className='native-datepicker'>
      <label htmlFor={name} className='label'>
        {label}
      </label>
      <div className='flex-between'>
        <input
          type='date'
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <MozaicIcon icon='DisplayCalendar32' />
      </div>
    </div>
  )
}

export default NativeDatePicker
