import classNames from 'classnames'
import { CSSProperties, MouseEventHandler, useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import EditButton from '../EditButton'

export const matchValue = (fullText: string, matchingText: string) => {
  const escapeRegexCharacters = (s: string) => s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

  const regex = new RegExp(
    matchingText
      .replace(/[^a-z\s\d]/gi, '')
      .split('')
      .map(escapeRegexCharacters)
      .join('[^a-zsd]?'),
    'ig',
  )

  return ReactHtmlParser(fullText?.replace(regex, '<b>$&</b>'))
}

const colors = {
  neutral: 'text-neutral',
  danger: 'text-danger',
  green: 'text-green',
  gray: 'text-gray',
  link: 'text-link',
}

const sizes = {
  small: 'size-sm',
  medium: 'size-md',
  large: 'size-lg',
}

type FormFieldType = {
  value: any
  matchingText?: string
  label: any
  isFetching?: boolean
  isBold?: boolean
  isDashed?: boolean
  disabled?: boolean
  className?: string
  sx?: CSSProperties
  onEditField?: () => void | undefined
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined
  color?: keyof typeof colors
  size?: keyof typeof sizes
}

function FormField({
  isBold = false,
  isDashed = false,
  disabled = false,
  sx = {},
  size = 'medium',
  color = 'neutral',
  className = '',
  label,
  value,
  matchingText,
  isFetching,
  onClick = undefined,
  onEditField,
}: FormFieldType) {
  const weightClass = isBold ? 'font-heavy' : ''
  const dashClass = isDashed ? 'text-dashed' : ''
  const styleClasses = `${colors[color]} ${sizes[size]} ${weightClass} ${dashClass}`

  const formatedValue = useMemo(
    () => (matchingText ? matchValue(value, matchingText) : value),
    [value, matchingText],
  )

  if (isFetching) return <div style={sx} className='form-field skeleton' />

  return (
    <div style={sx} className={classNames(`form-field`, { disabled, [className]: !!className })}>
      <label>{label}</label>

      <section className={styleClasses}>
        <span className='value' onClick={onClick}>
          {formatedValue}
        </span>
        {onEditField && <EditButton onClickEditButton={onEditField} showTitle={false} />}
      </section>
    </div>
  )
}

export default FormField
