import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'

import store from '../../redux/store'
import User from '../../core/User'

const user = new User().currentUser

export async function fetchProviderData(filters) {
  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.PROVIDER_EVENT_ANALYSIS_FILTER,
      filters,
    )
    return data
  } catch (error) {
    console.error('error when fetch provider list filters', error)
  }
}
