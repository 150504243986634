import PropTypes from 'prop-types'
import { Component } from 'react'

import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Row from '../../../components/utils/Row'
import { WithForm } from '../../../helpers/useForm/withForm'

class ServiceOrderObservation extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { setFormValue, onChange } = this.props

    setFormValue({ servcOrdRmrkTxt: e.target.value })
    onChange(e)
  }

  render() {
    const { t, fields, disabled, closeModal, onSubmit, register } = this.props

    return (
      <form>
        <Row>
          <Col xs={12} className='margin-bottom'>
            <legend className='margin-top margin-bottom-small'>
              {t('serviceOrders.observation')}
            </legend>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className='margin-bottom'>
            <Field {...fields.servcOrdRmrkTxt}>
              <MozaicTextArea
                {...register('servcOrdRmrkTxt', { setter: this.handleChange })}
                className='textarea-custom'
                rows={6}
                isDisabled={disabled}
              />
            </Field>
          </Col>
        </Row>

        <Row className='padding-top padding-bottom-double'>
          <Col mdOffset={4} md={3} xs={6} className='align-right'>
            <button
              type='button'
              className='button color-danger button-text-only'
              onClick={closeModal}
            >
              {t('cancel_button_label')}
            </button>
          </Col>

          <Col md={5} xs={6}>
            <button type='button' className='button button-primary button-full' onClick={onSubmit}>
              {t('save_button_label')}
            </button>
          </Col>
        </Row>
      </form>
    )
  }
}

ServiceOrderObservation.propTypes = {
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
}

const FIELDS = { servcOrdRmrkTxt: '' }

export default WithForm(FIELDS)(ServiceOrderObservation)

export { ServiceOrderObservation }
