import i18next from 'i18next'
import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

export const getReasonsList = async (params = {}) => {
  try {
    const data = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.FIND_REASONS_LIST,
      params,
    )

    return data
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
