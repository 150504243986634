import { ADEOResponse } from '../../models/AdeoResponse'
import API_URL from '../../core/Envs/endpoints'
import Fetch from '../../core/Http/FetchAdapter'
import store from '../../redux/store'
import User from '../../core/User'

const user = new User()

export const deleteServiceProvider = (id: number): Promise<ADEOResponse> => {
  const params = {
    servcPrvdrId: id,
    requestOriginPortal: 'SERVICES',
  }

  return new Fetch(user.currentUser.dsToken, store.dispatch).post(
    API_URL.DELETE_SERVICE_PROVIDER,
    params,
  )
}
