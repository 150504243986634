import { Component } from 'react'
import PropTypes from 'prop-types'
import send from '../../../../assets/icons/send.svg'

export class MessageInput extends Component {
  input = null

  inputOnKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.sendMessage()
    }
  }

  sendMessage = () => {
    if (this.input.value) {
      const { sendMessages } = this.props

      sendMessages(this.input.value)
      this.input.value = ''
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className='chat-send'>
        <input
          className='chat-send-input'
          type='text'
          placeholder={t('chat.writeMessage')}
          ref={(ref) => (this.input = ref)}
          onKeyPress={this.inputOnKeyPress}
        />

        <a className='chat-send-button' onClick={this.sendMessage}>
          <img src={send} alt='' />
        </a>
      </div>
    )
  }
}

MessageInput.propTypes = {
  t: PropTypes.func.isRequired,
  sendMessages: PropTypes.func.isRequired,
}

export default MessageInput
