import { Component } from 'react'
import PropTypes from 'prop-types'

import Row from '../../../components/utils/Row'
import Grid from '../../../components/utils/Grid'

class Notes extends Component {
  render() {
    const { t, note, name } = this.props

    return (
      <Grid fluid className='separator-top no-padding-top no-padding-side'>
        <>
          <h1>{name || t('serviceOrders.observations')}</h1>
        </>

        <Row className='min-padding'>
          <div>
            <span>{note}</span>
          </div>
        </Row>
      </Grid>
    )
  }
}

Notes.propTypes = {
  t: PropTypes.func.isRequired,
  note: PropTypes.string.isRequired,
  name: PropTypes.string,
}

export default Notes
