import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { AlertBoxType } from '../../../models/alerts'

export function WithContext(Component: FunctionComponent<AlertBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, handleToggle },
      alertsState: {
        indValidateMeasurementSheet,
        setIndValidateMeasurementSheet,
        acceptanceServiceConditions,
        setAcceptanceServiceConditions,
        indWaitingTechReport,
        setIndWaitingTechReport,
        indWaitingLateTechReport,
        setIndWaitingLateTechReport,
        indRunningRework,
        setIndRunningRework,
        indWaitingMeasurementSheet,
        setIndWaitingMeasurementSheet,
        indAlertServices,
        setIndAlertServices,
        sosOutOfFlow,
        setSosOutOfFlow,
        manualDistributed,
        setManualDistributed,
        servcOnTime,
        setServcOnTime,
        serviceOrderHasAttachments,
        setServiceOrderHasAttachments,
        sosPendingBudget,
        setSosPendingBudget,
        sosWaitingQuotation,
        setSosWaitingQuotation,
        conformity,
        setConformity,
        waitingResponsible,
        setWaitingResponsible,
        assignedToMe,
        setAssignedToMe,
        waitingBudget,
        setWaitingBudget,
        recommendInspection,
        setRecommendInspection,
        negativeOv,
        setNegativeOv,
        providerReschedule,
        setProviderReschedule,
        clientRescheduleRefuse,
        setClientRescheduleRefuse,
        attentionToDelivery,
        setAttentionToDelivery,
        salesError,
        setSalesError,
      },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        indValidateMeasurementSheet,
        setIndValidateMeasurementSheet,
        acceptanceServiceConditions,
        setAcceptanceServiceConditions,
        indWaitingTechReport,
        setIndWaitingTechReport,
        indWaitingLateTechReport,
        setIndWaitingLateTechReport,
        indRunningRework,
        setIndRunningRework,
        indWaitingMeasurementSheet,
        setIndWaitingMeasurementSheet,
        indAlertServices,
        setIndAlertServices,
        sosOutOfFlow,
        setSosOutOfFlow,
        manualDistributed,
        setManualDistributed,
        servcOnTime,
        setServcOnTime,
        serviceOrderHasAttachments,
        setServiceOrderHasAttachments,
        sosPendingBudget,
        setSosPendingBudget,
        sosWaitingQuotation,
        setSosWaitingQuotation,
        conformity,
        setConformity,
        waitingResponsible,
        setWaitingResponsible,
        assignedToMe,
        setAssignedToMe,
        waitingBudget,
        setWaitingBudget,
        recommendInspection,
        setRecommendInspection,
        negativeOv,
        setNegativeOv,
        providerReschedule,
        setProviderReschedule,
        clientRescheduleRefuse,
        setClientRescheduleRefuse,
        attentionToDelivery,
        setAttentionToDelivery,
        salesError,
        setSalesError,
        showAllDetails,
        handleToggle,
      }),
      [
        indValidateMeasurementSheet,
        acceptanceServiceConditions,
        indWaitingTechReport,
        indWaitingLateTechReport,
        indRunningRework,
        indWaitingMeasurementSheet,
        indAlertServices,
        sosOutOfFlow,
        manualDistributed,
        servcOnTime,
        serviceOrderHasAttachments,
        sosPendingBudget,
        sosWaitingQuotation,
        conformity,
        waitingResponsible,
        assignedToMe,
        waitingBudget,
        recommendInspection,
        attentionToDelivery,
        negativeOv,
        providerReschedule,
        clientRescheduleRefuse,
        showAllDetails,
        salesError,
      ],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
