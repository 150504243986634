import ApiRequest from '../../../core/Http/FetchAdapter'
import {
  FETCH_I18N_KEYS_ERROR,
  FETCH_I18N_KEYS_SUCCESS,
  I18N_KEYS_REQUEST,
} from '../../actionTypes/i18n'

import API_URL from '../../../core/Envs/endpoints'

const requestI18nKeys = () => ({
  type: I18N_KEYS_REQUEST,
})

const fetchI18nKeysSuccess = (data) => ({
  type: FETCH_I18N_KEYS_SUCCESS,
  payload: data,
})

const fetchI18nKeysError = (error) => ({
  type: FETCH_I18N_KEYS_ERROR,
  payload: error,
})

const fetchI18nKeys = (userLanguage) => async (dispatch) => {
  dispatch(requestI18nKeys())

  const params = {
    dsI18n: userLanguage,
  }

  try {
    const {
      data: { lsKeys },
    } = await new ApiRequest().post(API_URL.LOCALES_LIST, params)

    dispatch(fetchI18nKeysSuccess(lsKeys))

    return lsKeys
  } catch (error) {
    dispatch(fetchI18nKeysError(error))
  }
}

export default fetchI18nKeys
