import i18next from 'i18next'
import moment, { isMoment, Moment } from 'moment-timezone'
import DateSelect, { anchorDirection } from '../../../../../../../../../../components/DateSelect'

type DatesBoxType = {
  startDate: Moment | null
  setStartDate: Function
  endDate: Moment | null
  setEndDate: Function
  startId: string
  endId: string
  label: string
}

function DateSelectGroup(props: DatesBoxType) {
  const { startDate, setStartDate, endDate, setEndDate, startId, endId, label } = props

  const instalaFirstDate = moment('2019-01-01').startOf('day')

  const startDateBlocked = (day: Moment) => {
    if (!isMoment(endDate)) return false

    const refDate = endDate.startOf('day')
    const loopDate = day.startOf('day')

    return +loopDate < +instalaFirstDate || +loopDate > +refDate
  }

  const endDateBlocked = (day: Moment) => {
    if (!isMoment(startDate)) return false

    const refDate = startDate.startOf('day')
    const loopDate = day.startOf('day')

    return +loopDate < +refDate
  }

  const startDayHighlighted = (day: Moment) => {
    if (!isMoment(startDate)) return false

    const refDate = startDate.startOf('day')
    const loopDate = day.startOf('day')

    return +loopDate === +refDate
  }

  const endDayHighlighted = (day: Moment) => {
    if (!isMoment(endDate)) return false

    const refDate = endDate.startOf('day')
    const loopDate = day.startOf('day')

    return +loopDate === +refDate
  }

  const isOutsideRange = () =>
    startId !== 'scheduleStartDateId'
      ? (day: Moment) => +day.startOf('day') > +moment().startOf('day')
      : () => false

  const startDateProps = {
    id: startId,
    value: startDate,
    onChange: setStartDate,
    label,
    placeholder: i18next.t('advanced.search.date.select.label.start'),
    isDayBlocked: startDateBlocked,
    isDayHighlighted: startDayHighlighted,
    isOutsideRange: isOutsideRange(),
    highlightToday: true,
  }

  const endDateProps = {
    id: endId,
    value: endDate,
    onChange: setEndDate,
    label: '-',
    placeholder: i18next.t('advanced.search.date.select.label.end'),
    anchorDirection: 'right' as anchorDirection,
    isDayBlocked: endDateBlocked,
    isDayHighlighted: endDayHighlighted,
    isOutsideRange: isOutsideRange(),
    highlightToday: true,
  }

  return (
    <>
      <DateSelect {...startDateProps} />
      <DateSelect {...endDateProps} />
    </>
  )
}

export default DateSelectGroup
