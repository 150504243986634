import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { pushRequest } from '../../services/Push'

export type PushStore = {
  push: any
  pushRequest: Function
  pushClear: Function
}

type PushProviderProps = {
  children: ReactNode
}

const PushContext = createContext<PushStore | null>(null)

const defaultState = {
  record: {},
  error: null,
  isUpdating: false,
}

function PushProvider({ children }: PushProviderProps) {
  const [push, setPush] = useState(defaultState)

  const handlePushRequest = async (
    token: any,
    servcPrvdrId: number,
    servcPrvdrAgntId: number,
    pushText: string,
  ) => {
    try {
      const newPush = await pushRequest(token, servcPrvdrId, servcPrvdrAgntId, pushText)

      setPush({ ...defaultState, record: newPush })
    } catch (error: any) {
      setPush({ ...defaultState, error })
    }
  }

  const pushClear = () => setPush(defaultState)

  const result = useMemo(
    () => ({
      push,
      pushRequest: handlePushRequest,
      pushClear,
    }),
    [push],
  )

  return <PushContext.Provider value={result}>{children}</PushContext.Provider>
}

const usePushContext = () => useContext(PushContext) as PushStore

export { usePushContext, PushProvider }
