import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import store from '../../../redux/store'
import User from '../../../core/User'

export const getFirebaseConfigs = async () => {
  const user = new User().currentUser

  try {
    const response = await new API_REQUEST(user?.dsToken, store?.dispatch).get(
      API_URL.GET_FIREBASE_PARAMETERS,
      {},
    )

    return response.data
  } catch (error) {
    console.error('error when fetch firebase configs', error)
  }
}
