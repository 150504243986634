import i18next from 'i18next'

import FormField from '../../../../../../../../../components/FormField'
import ErrorBoundary from '../../../../../../../../../errors/boundaries/index'

import { capitalizeString } from '../../../../../../../../../helpers/capitalize'
import ServcOrdCategoryType from '../../../../../../../../../enums/ServiceOrder/category/serviceCategoryType'
import MozaicText from '../../../../../../../../../components/MozaicText'
import { WithContext } from '../../context'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'

function DetailsOrder({
  orderDetails,
  serviceOrderBody,
  loadingBody,
  loadingDetails,
}: ServiceOrderDetailStore) {
  const categoryName = ServcOrdCategoryType.getCatgryTypDesc(
    orderDetails?.servcCatgryTypCd,
  ).toString()

  const storeName = serviceOrderBody?.plntNm?.replace(
    i18next.t('so.plntNm.value')?.toLocaleUpperCase(),
    '',
  )

  const isFetching = loadingBody && loadingDetails

  return (
    <div id='details-order-container'>
      <ErrorBoundary
        FallbackComponent={<MozaicText>{i18next.t('fallback.page.error.subtitle')}</MozaicText>}
        resetErrorBoundary={() => {}}
      >
        <section className='row-custom'>
          <div className='col-two'>
            <FormField
              isFetching={isFetching}
              label={i18next.t('servcCatgry.header.category')}
              value={i18next.t(categoryName) || '-'}
              isBold={false}
            />
          </div>
        </section>
        <section className='row-custom'>
          <div className='col-one'>
            <FormField
              isFetching={isFetching}
              label={i18next.t('serviceOrders.channel')}
              value={orderDetails?.slsChnlNm}
              isBold={false}
            />{' '}
          </div>
          <div className='col-two'>
            <FormField
              isFetching={isFetching}
              label={i18next.t('filter.field.service.store')}
              value={capitalizeString(storeName as string) || '-'}
              isBold={false}
            />{' '}
          </div>
        </section>
      </ErrorBoundary>
    </div>
  )
}

export default WithContext(DetailsOrder)
