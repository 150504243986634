import { ChangeEventHandler } from 'react'
import { IRadioProps, Radio } from '@mozaic-ds/react'

interface MozaicRadioProps extends IRadioProps {
  label: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

function MozaicRadio(props: MozaicRadioProps) {
  const { label, onChange } = props

  const handleChange = (e: any) => onChange(e.target.value)

  const newProps = { ...props, onChange: handleChange }

  return (
    <Radio id='custom-mozaic-radio' {...newProps}>
      {label}
    </Radio>
  )
}

export default MozaicRadio
