import { Flex } from '@mozaic-ds/react'
import { ServiceRowGhost, ServiceRowGhostMobile } from '../ServiceRowGhost'

function ProjectRowGhost({ mobileClass }: { mobileClass: boolean }) {
  if (mobileClass)
    return (
      <Flex direction='column' gap='mu125'>
        <ServiceRowGhostMobile />
        <ServiceRowGhostMobile />
        <ServiceRowGhostMobile />
        <ServiceRowGhostMobile />
      </Flex>
    )

  return (
    <Flex direction='column' gap='mu125'>
      <ServiceRowGhost />
      <ServiceRowGhost />
      <ServiceRowGhost />
      <ServiceRowGhost />
    </Flex>
  )
}

export default ProjectRowGhost
