import i18next from 'i18next'
import API_URL from '../core/Envs/endpoints'
import API_REQUEST from '../core/Http/FetchAdapter'
import User from '../core/User'
import store from '../redux/store'

import { getIdValues } from '../helpers/array'
import { capitalizeString } from '../helpers/capitalize'

const user = new User().currentUser

export function getStoresByClusterId(arr, coreCd) {
  const clusterItem = arr?.find((cluster) => cluster.value === coreCd)

  return clusterItem?.stores || []
}

export function alreadyClusterStoreAreSelected(cluster, clustersOptions, stores) {
  const clusterStores = getStoresByClusterId(clustersOptions, cluster?.value)

  const clusterStoresIds = getIdValues(clusterStores, 'code')

  const storesIds = getIdValues(stores, 'value')

  const allFounded = clusterStoresIds.every((id) => storesIds.includes(id))

  return allFounded ? cluster : -1
}

export function clusterStoreAllSelected(arr, stores, clustersArr) {
  const clusterStoresAreSelected = []

  for (const cluster of arr) {
    const allStoresSelected = alreadyClusterStoreAreSelected(cluster, clustersArr, stores)

    clusterStoresAreSelected.push(allStoresSelected)
  }

  const filteredClusters = clusterStoresAreSelected.filter((item) => item !== -1)

  return filteredClusters
}

export async function fetchStores() {
  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(API_URL.FIND_STORES)
    return data
  } catch (error) {
    console.error('error when fetch stores', error)
  }
}

export async function fetchStoresClusters() {
  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.FIND_STORES_CLUSTERS,
    )
    return data
  } catch (error) {
    console.error('error when fetch stores with clusters', error)
  }
}

const storeText = i18next.t('filter.field.service.store')

function handleSortByAlphabeticalOrder(branches) {
  const ordenedList = branches?.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))

  return ordenedList
}

export function handleResolveStoreLabel(name) {
  const replacedText = `${storeText?.toUpperCase()} `
  const label = name?.replace(replacedText, '')

  return capitalizeString(label)
}

function handleResolveStoresOptions(stores) {
  const options = (stores || []).map((store) => ({
    value: store.id,
    label: handleResolveStoreLabel(store.name),
    name: store.name,
    checked: true,
    isIndeterminate: false,
    clusters:
      store?.coreCd || store?.coreName ? [{ coreCd: store?.coreCd, name: store?.coreName }] : [],
  }))

  return handleSortByAlphabeticalOrder(options)
}

function handleResolveStores(stores) {
  const options = (stores || []).map((store) => ({
    value: store.id,
    label: handleResolveStoreLabel(store.name),
    name: store.name,
    checked: true,
    isIndeterminate: false,
  }))

  return handleSortByAlphabeticalOrder(options)
}

export async function getStores() {
  const result = await fetchStores()

  const solvedData = await handleResolveStores(result)

  return solvedData
}

export async function getStoresOptions() {
  const result = await fetchStoresClusters()

  const solvedData = handleResolveStoresOptions(result)

  return solvedData
}
