import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import AwsImage from '../../../../components/utils/images/AwsImage'
import AwsVideo from '../../../../components/utils/video/AwsVideo'

import { osCodesToLink } from '../../../../helpers/serviceOrderUtils'

import { MESSAGE_DEVICE, MESSAGE_TYPE } from '../../../../constants/config'

export class Message extends Component {
  constructor(props) {
    super(props)
    this.msgRef = createRef()
  }

  static renderMessageTime(time) {
    return (
      <div className='chat-message-time-box'>
        <span className='chat-message-time'>{moment(time).format('LT')}</span>
      </div>
    )
  }

  renderMessageContent = (message) => {
    switch (message.type) {
      // eslint-disable-next-line default-case-last
      default:
      case MESSAGE_TYPE.TEXT:
        return this.renderText(message.message)
      case MESSAGE_TYPE.IMAGE:
        return this.renderImage(message.message)
      case MESSAGE_TYPE.VIDEO:
        return this.renderVideo(message.message)
    }
  }

  renderText = (message) => (
    <div className='chat-message-text-box'>
      <span className='chat-message-text'>{osCodesToLink(message)}</span>
    </div>
  )

  openLightbox = (url) => {
    const { openLightbox } = this.props
    if (this.msgRef.current.state.thumb !== false) {
      openLightbox(url, this.msgRef.current.state.thumb)
    }
  }

  renderImage = (imgUrl) => {
    const {
      dispatch,
      auth: { user },
    } = this.props

    return (
      <div className='chat-message-image-box' onClick={() => this.openLightbox(imgUrl)}>
        <AwsImage
          className='chat-message-image'
          filePath={this.getPath(imgUrl)}
          token={user.dsToken}
          dispatch={dispatch}
          ref={this.msgRef}
        />
        <div className='chat-message-time-shadow' />
      </div>
    )
  }

  renderVideo = (videoUrl) => {
    const {
      t,
      dispatch,
      auth: { user },
    } = this.props

    return (
      <div className='chat-message-video-box'>
        <AwsVideo
          className='chat-message-video'
          filePath={this.getPath(videoUrl)}
          token={user.dsToken}
          dispatch={dispatch}
          t={t}
        />
        <div className='chat-message-time-shadow' />
      </div>
    )
  }

  getPath = (url) => {
    if (url && url.startsWith('http')) {
      const decodeUrl = decodeURIComponent(url.substring(0, url.indexOf('?')))
      return decodeUrl.substring(decodeUrl.indexOf('chat'))
    }
    if (url && url.includes(' - ')) {
      return url.split(' - ')[1]
    }

    return url
  }

  render() {
    const { auth, message } = this.props

    let device = MESSAGE_DEVICE.WEB_BRANCH
    if (auth.user?.funcaoAcesso?.inFuncao17 && auth.user?.funcaoAcesso?.inFuncao17 === 1) {
      device = MESSAGE_DEVICE.WEB
    }

    const ownership = message.device === device ? 'mine' : 'other'
    const cls = (message.type || MESSAGE_TYPE.TEXT) === MESSAGE_TYPE.TEXT ? 'text' : 'media'

    return (
      <div className={`chat-message chat-message-${ownership} chat-message-${cls}`}>
        <div className='chat-message-content'>
          {this.renderMessageContent(message)}

          {Message.renderMessageTime(message.createdAt)}
        </div>
      </div>
    )
  }
}

Message.propTypes = {
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  openLightbox: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default Message
