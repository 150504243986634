/**
 * Created by develcode03 on 24/10/18.
 */

import { RESET_PAGES } from '../../actionTypes/pagination'

const resetPage = () => ({
  type: RESET_PAGES,
})

export default resetPage
