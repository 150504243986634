import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import Col from '../../../components/utils/Col'
import Input from '../../../components/utils/Input'
import Modal from '../../../components/utils/Modal'
import Row from '../../../components/utils/Row'

class ServiceProviderAdvancedSearch extends Component {
  renderOptions() {
    const { serviceProviders } = this.props
    return serviceProviders.map(this.createOptions)
  }

  createOptions(serviceProvider) {
    return {
      value: `${serviceProvider.servcPrvdrId}`,
      label: serviceProvider.servcPrvdrTrdNm,
    }
  }

  getValue = (options, servcPrvdrCd) => {
    if (servcPrvdrCd) {
      return options.find((option) => parseInt(option.value) === servcPrvdrCd)
    }
    return null
  }

  render() {
    const {
      t,
      filters,
      handleInputChange,
      handleCloseServiceProviderAdvancedSearch,
      clearFields,
      handleSubmit,
      value,
      label,
      onChange,
      placeholder,
      id,
      onInputChange,
      serviceProviders,
    } = this.props

    const options = this.renderOptions()

    const ServiceProviderAdvancedSearch = (
      <div>
        <h2>{t('serviceOrders.searchTitle')}</h2>
        <div>
          <div className='bordered'>
            <Col xs={6} className='field-section field-with-label'>
              <Input
                name='servcPrvdrNm'
                id='servcPrvdrNm'
                value={filters.servcPrvdrNm}
                onChange={handleInputChange}
                label={t('serviceOrders.servcPrvdrNm')}
                className={filters.servcPrvdrNm.length > 0 ? 'has-value' : ''}
              />
              <p className='description-label'>{t('serviceOrders.servcPrvdrNmLabel')}</p>
            </Col>

            <Col xs={6} className='field-section field-with-label'>
              <Input
                name='servcPrvdrTrdNm'
                id='servcPrvdrTrdNm'
                value={filters.servcPrvdrTrdNm}
                onChange={handleInputChange}
                label={t('serviceOrders.servcPrvdrTrdNm')}
                className={filters.servcPrvdrTrdNm.length > 0 ? 'has-value' : ''}
              />
              <p className='description-label'>{t('serviceOrders.servcPrvdrTrdNmLabel')}</p>
            </Col>

            <Col xs={6} className='field-section field-with-label'>
              <Input
                name='servcPrvdrFedrlTaxIdNr'
                id='servcPrvdrFedrlTaxIdNr'
                value={filters.servcPrvdrFedrlTaxIdNr}
                onChange={handleInputChange}
                label={t('serviceOrders.servcPrvdrFedrlTaxIdNr')}
                className={filters.servcPrvdrFedrlTaxIdNr.length > 0 ? 'has-value' : ''}
              />
              <p className='description-label'>{t('serviceOrders.servcPrvdrFedrlTaxIdNrLabel')}</p>
            </Col>

            <Row />

            {serviceProviders.length > 0 && (
              <div>
                <Col xs={6} className='field-section field-with-label'>
                  <Select
                    className='override-button'
                    placeholder={placeholder}
                    value={this.getValue(options, value)}
                    onChange={onChange}
                    options={options}
                    label={label}
                    id={id}
                    name={id}
                    isClearable={false}
                    onInputChange={onInputChange}
                  />
                </Col>

                <Col xs={6}>
                  <button
                    type='button'
                    onClick={handleCloseServiceProviderAdvancedSearch}
                    className='margin-top button button-primary button-full'
                  >
                    {t('serviceOrders.confirm')}
                  </button>
                </Col>
              </div>
            )}

            <Col xs={12}>
              <button
                type='button'
                onClick={handleSubmit}
                className='margin-top button button-primary button-full'
              >
                {t('serviceOrders.searchButton')}
              </button>
            </Col>
            <Col xs={12} className='text-align-center margin-top margin-bottom'>
              <button className='button-only-text' type='button' onClick={clearFields}>
                {t('serviceOrders.clearFields')}
              </button>
            </Col>
          </div>
        </div>
      </div>
    )

    return (
      <div className='advanced-input-search'>
        <Modal
          closeModal={handleCloseServiceProviderAdvancedSearch}
          size='small'
          children={ServiceProviderAdvancedSearch}
        />
      </div>
    )
  }
}

ServiceProviderAdvancedSearch.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  clearFields: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCloseServiceProviderAdvancedSearch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  serviceProviders: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
}

export default ServiceProviderAdvancedSearch
