import i18next from 'i18next'
import moment from 'moment-timezone'
import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import { ClearIcon } from '../../../../../../helpers/react-select-backyard-styles'

import MozaicTextArea from '../../../../../../components/MozaicTextArea'
import { _shifts as shifts } from '../../types'

type JustifyInputType = {
  readonly selectedPeriod: any
  readonly selectedDate: any
  readonly onChange: Function
  readonly value: string
}

function JustifyInput({ onChange, value, selectedDate, selectedPeriod }: JustifyInputType) {
  const { serviceOrder }: any = useServiceOrderContext()

  const shiftCd = shifts[serviceOrder?.idSlotApplication as keyof typeof shifts]
  const currentSchedule = moment(serviceOrder?.servcOrdSchdlgTs).startOf('day')

  const disabled = +currentSchedule === +selectedDate && shiftCd === selectedPeriod?.value

  const label = i18next.t('serviceOrders.distribute.justification')
  const placeholder = i18next.t('justificationModal.desc.placeholder')

  const handleClear = () => {
    if (!disabled) onChange({ target: { value: '' } })
  }

  const suffixIcons = () => (
    <>
      {value && (
        <div className='clear-filter' onClick={handleClear}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  return (
    <div className='distribute-justify-input'>
      <MozaicTextArea
        id='justify'
        htmlFor='justify'
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        isDisabled={disabled}
      />
      {suffixIcons()}
    </div>
  )
}

export default JustifyInput
