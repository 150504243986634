import Api from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'
import { showSpinner, hideSpinner } from '../redux/actions/spinner'

export const checkWorksiteQuantity = (worksiteNumber) => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState()
  const query = {
    servcOrdWorksiteNumber: worksiteNumber,
  }
  return new Api(user.dsToken, dispatch)
    .get(API_URL.COUNT_TOTAL_WORKSITE, query)
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
}

export const removeFromCurrentWorksite = (servcOrdSeq) => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState()
  const query = {
    servcOrdSeq,
  }
  return new Api(user.dsToken, dispatch)
    .delete(API_URL.REMOVE_FROM_CURRENT, query)
    .then(dispatch(showSpinner()))
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
    .finally(() => dispatch(hideSpinner()))
}

export const moveToExistingWorksite = (servcOrdSeq, newWorksite) => (dispatch, getState) => {
  const {
    auth: { user },
  } = getState()
  const query = {
    servcOrdSeq,
    servcOrdWorksiteNumber: newWorksite,
  }
  return new Api(user.dsToken, dispatch)
    .post(API_URL.MOVE_TO_EXISTING, query)
    .then(dispatch(showSpinner()))
    .then((response) => response.data)
    .catch((error) => {
      throw error
    })
    .finally(() => dispatch(hideSpinner()))
}
