import i18next from 'i18next'
import {
  CartesianGrid,
  LineChart as Chart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { usableArea } from '../../constants/breakpoints'
import { useUsableAreaContext } from '../../contexts/usableArea'
import ErrorBoundaryLineChart from '../../errors/boundaries/components/LineChart'
import Skeleton from '../Skeleton'

interface LineChartProps {
  data?: any[]
  strokeDasharray?: string
  domain?: number[]
  tickCount?: number
  XAxisDataKey: string
  legendVerticalAlign?: string
  legendHeight?: number
  lines: any
  loading?: boolean
  onResetBoundary: () => void
}

/**
 * @description Reusable component to show line graph
 */

function LineChart({
  data = [],
  strokeDasharray = '1 3',
  domain = [3, 5],
  tickCount = 1,
  XAxisDataKey,
  legendVerticalAlign = 'top',
  legendHeight = 36,
  lines = [],
  loading = false,
  onResetBoundary = () => {},
}: LineChartProps) {
  const { widthContainer } = useUsableAreaContext()

  const isMobile = widthContainer < usableArea.medium

  if (loading) return <Skeleton height={400} borderRadius={8} backgroundColor='#EEEEF0' />

  return (
    <ErrorBoundaryLineChart onReset={onResetBoundary}>
      <section id='line-chart-container'>
        <ResponsiveContainer height='99%' width='99%' aspect={3}>
          <Chart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: isMobile ? -25 : -15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray={strokeDasharray} />
            <XAxis dataKey={i18next.t(XAxisDataKey)} interval='preserveStartEnd' />
            <YAxis tickCount={tickCount} type='number' domain={domain} />
            <Tooltip />
            {/* @ts-ignore */}
            <Legend verticalAlign={legendVerticalAlign} height={legendHeight} />
            {lines.map((line) => (
              <Line
                name={line.label}
                type={line.type}
                dataKey={i18next.t(line.dataKey)}
                stroke={line.stroke}
                activeDot={lines.activeDot}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </section>
    </ErrorBoundaryLineChart>
  )
}

export default LineChart
