import { useEffect, useState } from 'react'
import i18next from 'i18next'
import Select, { ActionMeta, ValueType } from 'react-select'

import { colourStyles } from '../../../../../helpers/selectStyle'
import Field from '../../../../../components/utils/Field'
import { SelectZonesType } from '../models'

function SelectZonesSecondary(props: SelectZonesType) {
  const { value, zones, disabled = false, onChange } = props

  const [field, setField] = useState({
    input: {
      name: 'field',
      value: '',
    },
    error: null,
    touched: false,
    initialValue: '',
  })

  useEffect(() => {
    setField((prevField) => ({
      ...prevField,
      input: {
        ...prevField.input,
        value,
      },
    }))
  }, [value])

  const handleChange = (selected: ValueType<any, true>, type: ActionMeta<any>) =>
    onChange(selected, type)
  const id = i18next.t('serviceProvider.regions.secondary.zones')

  return (
    <Field {...field} validate>
      <label htmlFor='secondaryZones' className='label up-label'>
        {id}
      </label>
      <Select
        id='secondaryZones'
        name={id}
        label={id}
        placeholder={id}
        classNamePrefix='react-select'
        value={value}
        styles={colourStyles}
        options={zones}
        isDisabled={disabled}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        onChange={handleChange}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        resetValue={[]}
        isMulti
        isClearable
      />
    </Field>
  )
}

export default SelectZonesSecondary
