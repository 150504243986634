import i18next from 'i18next'
import { Loader } from '@mozaic-ds/react'

import MozaicButton from '../../../../../../../../../components/MozaicButton'
import Modal from '../../../../../../../../../components/Modal'

interface DeleteModalProps {
  onClose: () => void
  isOpen: boolean
  isDeleting: boolean
  onDelete: () => void
}

export function DeleteModal({ isOpen, onClose, isDeleting, onDelete }: DeleteModalProps) {
  return (
    <Modal
      size='md'
      contentLabel='attention'
      isOpen={isOpen}
      closeInOverlay={false}
      onClose={onClose}
    >
      <div id='observations-modal'>
        <h4>{i18next.t('file.field.remove.file.modal.title')}</h4>

        <div className='footer-buttons'>
          <MozaicButton variant='ghost' theme='neutral' onClick={onClose}>
            {i18next.t('cancel.label')}
          </MozaicButton>
          <MozaicButton onClick={onDelete} isDisabled={isDeleting} theme='danger'>
            {isDeleting ? (
              <Loader size='s' data-testid='loader' />
            ) : (
              `${i18next.t('custEval.question1.answer1')}, ${i18next.t('communicated.Delete')}`
            )}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}
