import { findInsertionPoint, genericObjectComparator } from '../../../helpers/Toolbox'
import {
  CLEAR_MESSAGES,
  RETRIEVE_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_SUCCESS,
} from '../../actionTypes/messages'

//* *@deprecated */
export const userMessageItems = (state, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      const { uid, displayName, message, device, createdAt, isRead, type, userType, indSystem } =
        action
      return [
        ...state,
        {
          uid,
          displayName,
          message,
          device,
          createdAt,
          isRead,
          type,
          userType,
          indSystem,
        },
      ]
    case SEND_MESSAGE_SUCCESS:
    case SEND_MESSAGE_ERROR:
    default:
      return state
  }
}

const userMessageInitialState = {
  isSending: false,
  isTyping: false,
  items: [],
}

export const userMessage = (state = userMessageInitialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return {
        ...state,
        isSending: true,
        items: userMessageItems(state.items, action),
      }
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        isSending: false,
        hasError: false,
      }
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}

function retrieveMessageHandler(state, messageItem) {
  const newState = [...state]
  const insertionPoint = findInsertionPoint(
    newState,
    messageItem,
    genericObjectComparator('createdAt'),
  )
  newState.splice(insertionPoint, 0, messageItem)
  return newState
}

export const messages = (state = [], action) => {
  const {
    createdAt,
    uid,
    displayName,
    message,
    device,
    isRead,
    messageType,
    key,
    userType,
    indSystem,
  } = action
  const messageItem = {
    createdAt,
    uid,
    displayName,
    message,
    device,
    isRead,
    type: messageType,
    key,
    userType,
    indSystem,
  }

  switch (action.type) {
    case RETRIEVE_MESSAGE:
      return retrieveMessageHandler(state, messageItem)
    case CLEAR_MESSAGES:
      return []
    default:
      return state
  }
}
