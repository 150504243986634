import classNames from 'classnames'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Cell, Column, HeaderCell, Table as TableContent } from 'rsuite-table'
import { usableArea } from '../../../../constants/breakpoints'
import { useProviderAnalysisListContext } from '../../../../contexts/providerAnalysis'
import { useUsableAreaContext } from '../../../../contexts/usableArea'
import { formatDate } from '../../../../helpers/validations'

function Table({
  results,
  orderBy,
  orderBySense,
  selectedColumns,
  handleSortColumn,
  noHasResults,
  tableLoading,
}) {
  const { widthContainer } = useUsableAreaContext()

  const [lazyIndex, setLazyIndex] = useState(100)

  useEffect(() => {
    setLazyIndex(100)
  }, [results])

  function checkLastRow(x, y) {
    const tbBodySize = 39 * lazyIndex

    if (y >= tbBodySize) {
      setLazyIndex(lazyIndex + 100)
    }
  }

  const columnKeys = useMemo(
    () => selectedColumns.reduce((acc, column) => ({ ...acc, [column.value]: true }), {}),
    [selectedColumns],
  )
  const slicedResults = useMemo(
    () => results.slice(0, lazyIndex),
    [results, noHasResults, orderBy, orderBySense, lazyIndex],
  )
  const heightTable = widthContainer > usableArea.medium ? 750 : 655
  const fixedColumnsOneAndTwo = widthContainer > usableArea.medium

  if (noHasResults)
    return (
      <span className='provider-analysis-table-empty-data'>
        {i18next.t('serviceSpecialists-list.not-found')}
      </span>
    )

  if (results.length === 0 && !tableLoading) return <div />

  if (results.length === 0 && tableLoading)
    return <div id='provider-analysis-table' className={classNames({ skeleton: tableLoading })} />

  return (
    <div id='provider-analysis-table'>
      <TableContent
        className='table-content'
        data={slicedResults}
        height={heightTable}
        onScroll={checkLastRow}
        sortType={orderBySense}
        sortColumn={orderBy}
        onSortColumn={handleSortColumn}
        shouldUpdateScroll={false}
      >
        <Column width={70} align='center' fixed={fixedColumnsOneAndTwo}>
          <HeaderCell />
          <Cell dataKey='id'>{(_, rowKey) => <span>{`${rowKey + 1}`}</span>}</Cell>
        </Column>

        <Column width={310} sortable fixed={fixedColumnsOneAndTwo}>
          <HeaderCell>{i18next.t('field.name.fantasyName')}</HeaderCell>
          <Cell dataKey='commercialName'>
            {(rowData) => (
              <Link className='link' to={`/provider/analysis/${rowData.servcPrvdrId}`}>
                {rowData.commercialName}
              </Link>
            )}
          </Cell>
        </Column>

        {columnKeys?.CNPJ && (
          <Column width={190}>
            <HeaderCell>{i18next.t('company.info.cnpj')}</HeaderCell>
            <Cell dataKey='federalNumber' />
          </Column>
        )}

        {columnKeys?.registerDate && (
          <Column width={120} sortable>
            <HeaderCell>{i18next.t('registration.date.text')}</HeaderCell>
            <Cell dataKey='registerDate'>{(rowData) => formatDate(rowData.registerDate)}</Cell>
          </Column>
        )}

        {columnKeys?.isActive && (
          <Column width={90} sortable className='align-rows'>
            <HeaderCell>{i18next.t('menu.link.register')}</HeaderCell>
            <Cell dataKey='orderReceipt' />
          </Column>
        )}

        {columnKeys?.receivingOS && (
          <Column width={120} sortable className='align-rows'>
            <HeaderCell>{i18next.t('receiving')}</HeaderCell>
            <Cell dataKey='receivingOS' />
          </Column>
        )}

        {columnKeys?.ratingServices && (
          <Column width={120} align='center' sortable>
            <HeaderCell>{i18next.t('evaluation.services.text')}</HeaderCell>
            <Cell dataKey='ratingServices' />
          </Column>
        )}

        {columnKeys?.ratingInstallers && (
          <Column width={120} align='center' sortable>
            <HeaderCell>{i18next.t('evaluation.specialists.abbreviated.text')}</HeaderCell>
            <Cell dataKey='ratingInstallers' />
          </Column>
        )}

        {columnKeys?.done && (
          <Column width={120} align='center' sortable>
            <HeaderCell>{i18next.t('performed.finished.abbreviated.text')}</HeaderCell>
            <Cell dataKey='done' />
          </Column>
        )}

        {columnKeys?.distributedTotal && (
          <Column width={120} sortable className='align-rows'>
            <HeaderCell>{i18next.t('distributed.total.text')}</HeaderCell>
            <Cell dataKey='distributedTotal' />
          </Column>
        )}

        {columnKeys?.distributedAutomatic && (
          <Column width={120} align='center' sortable>
            <HeaderCell>{i18next.t('distributed.automatically.abbreviated.text')}</HeaderCell>
            <Cell dataKey='distributedAutomatic' />
          </Column>
        )}

        {columnKeys?.distributedManual && (
          <Column width={120} align='center' sortable>
            <HeaderCell>{i18next.t('distributed.manually.abbreviated.text')}</HeaderCell>
            <Cell dataKey='distributedManual' />
          </Column>
        )}

        {columnKeys?.accepted && (
          <Column width={120} align='center' sortable className='align-column-basic'>
            <HeaderCell>{i18next.t('accepted.text')}</HeaderCell>
            <Cell dataKey='accepted' />
          </Column>
        )}

        {columnKeys?.rescheduling && (
          <Column width={150} align='center' sortable className='align-column-basic'>
            <HeaderCell>{i18next.t('rescheduling')}</HeaderCell>
            <Cell dataKey='rescheduling' />
          </Column>
        )}

        {columnKeys?.refused && (
          <Column width={120} align='center' sortable className='align-column-basic'>
            <HeaderCell>{i18next.t('refused.text')}</HeaderCell>
            <Cell dataKey='refused' />
          </Column>
        )}

        {columnKeys?.expired && (
          <Column width={120} align='center' sortable className='align-column-basic'>
            <HeaderCell>{i18next.t('expired.text')}</HeaderCell>
            <Cell dataKey='expired' />
          </Column>
        )}

        {columnKeys?.notConform && (
          <Column width={100} sortable className='align-rows'>
            <HeaderCell>{i18next.t('not.conformity.text')}</HeaderCell>
            <Cell dataKey='notConform' />
          </Column>
        )}

      </TableContent>
    </div>
  )
}

Table.propTypes = {
  results: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderBySense: PropTypes.string.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  handleSortColumn: PropTypes.func.isRequired,
  noHasResults: PropTypes.bool.isRequired,
  tableLoading: PropTypes.bool.isRequired,
}

function TableWithContext(props) {
  const {
    results,
    orderBy,
    orderBySense,
    selectedColumns,
    handleSortColumn,
    noHasResults,
    tableLoading,
  } = useProviderAnalysisListContext()

  const newProps = useMemo(
    () => ({
      results,
      orderBy,
      selectedColumns,
      orderBySense,
      handleSortColumn,
      noHasResults,
      tableLoading,
    }),
    [results, orderBy, orderBySense, selectedColumns, handleSortColumn, noHasResults, tableLoading],
  )

  return <Table {...props} {...newProps} />
}

export default TableWithContext
