import { Badge } from '@mozaic-ds/react'

interface MozaicBadgeProps {
  theme?: 'info' | 'success' | 'danger' | 'warning' | 'neutral'
  text: string
  className?: string
}

function MozaicBadge(props: MozaicBadgeProps) {
  const { theme, text, className } = props

  return (
    <Badge className={className} theme={theme}>
      {text}
    </Badge>
  )
}

export default MozaicBadge
