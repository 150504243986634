import i18next from 'i18next'
import propTypes from 'prop-types'
import { useEffect, useState } from 'react'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { fetchBudgets as getBudgets } from '../../services/Budgets'
import AlertsBudgets from './components/AlertsBudgets'
import { WithContext } from './context'

function DashboardBudget({ stores, branchesLabel, handleSubmitDefault }) {
  const [list, setList] = useState([])

  const parsedStores = stores?.filter((store) => store.checked) || []

  const fetchBudgets = async () => {
    const budgets = await getBudgets(parsedStores)

    setList(budgets)
  }

  useEffect(() => {
    fetchBudgets()
  }, [branchesLabel])

  const findWithFilter = (filters) => {
    handleSubmitDefault(filters)
  }

  return (
    <section>
      <BreadcrumbBar title={i18next.t('lmi.budget')} />

      <div className='padding-left'>
        <AlertsBudgets list={list} findWithFilter={findWithFilter} />
      </div>
    </section>
  )
}

DashboardBudget.propTypes = {
  stores: propTypes.array,
  branchesLabel: propTypes.string,
  handleSubmitDefault: propTypes.func,
}

export default WithContext(DashboardBudget)
