import { useState } from 'react'
import { Field, IFieldProps, IPasswordInputProps, PasswordInput } from '@mozaic-ds/react'

import MozaicIcon, { colors } from '../MozaicIcon'

function MozaicPasswordInput(props: IPasswordInputProps & IFieldProps & { canShow: boolean }) {
  const { label, help, error, id = 'password-input', canShow, ...otherProps } = props
  const [eyeColor, setEyeColor] = useState<keyof typeof colors>('black')

  const toggleInputType = () => {
    const input = document.getElementById(id) as any

    setEyeColor(input.type === 'password' ? 'primary' : 'black')
    input.type = input.type === 'password' ? 'text' : 'password'
  }

  return (
    <div id='mozaic-password'>
      <Field label={label} error={error} help={help}>
        <div className='display-flex'>
          <PasswordInput {...otherProps} id={id} />
          {canShow && (
            <div className='eye-icon'>
              <MozaicIcon icon='DisplayView32' color={eyeColor} onClick={() => toggleInputType()} />
            </div>
          )}
        </div>
      </Field>
    </div>
  )
}
export default MozaicPasswordInput
