import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import MozaicText from '../../../components/MozaicText'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Form from '../../../components/utils/Form'
import Input from '../../../components/utils/Input'
import Row from '../../../components/utils/Row'

import MozaicIcon from '../../../components/MozaicIcon'
import * as worksiteActions from '../../../services/Worksite'

function ServiceOrderWorksite({
  t,
  fields,
  closeModal,
  onSubmit,
  mode,
  newWorksiteNumber,
  currentWorksiteNumber,
  onChangeWorksiteMode,
  handleChangeWorksiteValue,
  checkWorksiteQuantity,
  currentWorksiteQuantity,
  resetWorksiteValues,
}) {
  const [worksiteExists, setWorksiteExists] = useState(true)
  const [focusedInput, setFocusedInput] = useState(false)

  useEffect(() => {
    resetWorksiteValues()
  }, [])

  const resolveWorksiteNumber = () => {
    if (mode === 'move') return newWorksiteNumber
    return currentWorksiteNumber
  }

  const handleCheckWorksiteExists = async () => {
    const value = await checkWorksiteQuantity(newWorksiteNumber)
    setFocusedInput(() => false)
    setWorksiteExists(() => value > 0)
  }

  const shouldDisableRemove = () => !currentWorksiteQuantity || +currentWorksiteQuantity <= 1

  const activeSubmitButton = () => {
    if (mode === 'remove' && !shouldDisableRemove()) return true
    if (+newWorksiteNumber === +currentWorksiteNumber || focusedInput) return false
    if (mode === 'move' && worksiteExists) return true
    return false
  }

  const worksiteError = () =>
    mode === 'move' && !worksiteExists && t('serviceOrder.worksite.invalid')

  const worksiteHint = () =>
    mode === 'move' && worksiteExists && t('serviceOrder.worksite.type.existing')

  const searchWorksiteExistsByEnter = (e) => {
    if (e.key === 'Enter') handleCheckWorksiteExists()
  }

  return (
    <div className='worksite-modal'>
      <header>
        <h4 className='flex-between'>
          <MozaicText className='title'> {t('serviceOrder.worksite.change')}</MozaicText>
          <MozaicIcon icon='ControlCross32' onClick={closeModal} />
        </h4>
        <div className='content'>
          <MozaicText>{t('serviceOrder.worksite.desc1')}</MozaicText>
          <MozaicText>{t('serviceOrder.worksite.desc2')}</MozaicText>
        </div>
      </header>
      <section>
        <span className='title-checkbox'>{t('serviceOrder.worksite.change.options')}</span>
        <Row className='flex'>
          <Field className='margin-left' {...fields.moveWorkisite}>
            <label className='clickable'>
              <Input
                type='radio'
                onChange={() => onChangeWorksiteMode('move')}
                checked={mode === 'move'}
                label={t('serviceOrder.worksite.chage.option.move.existing')}
                labelClassName='checkboxes'
              />
            </label>
          </Field>
          {currentWorksiteQuantity > 1 && (
            <Field className='margin-left' {...fields.removeWorksite}>
              <label className='clickable'>
                <Input
                  type='radio'
                  onChange={() => onChangeWorksiteMode('remove')}
                  checked={mode === 'remove'}
                  label={t('serviceOrder.worksite.chage.option.remove')}
                  labelClassName='checkboxes'
                />
              </label>
            </Field>
          )}
        </Row>
        <br />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Field
              input={{ value: worksiteExists }}
              validate={mode === 'move'}
              error={worksiteError()}
              hint={worksiteHint()}
            >
              <Input
                onKeyPress={searchWorksiteExistsByEnter}
                onFocus={() => setFocusedInput(() => true)}
                onBlur={() => handleCheckWorksiteExists()}
                value={resolveWorksiteNumber()}
                topLabel={t('serviceOrder.worksite.number')}
                maxLength={18}
                disabled={mode === 'remove'}
                onChange={({ target: { value } }) =>
                  handleChangeWorksiteValue(value.replace(/[^0-9]/g, ''))
                }
                topLabelClassName='title-workisite-number'
              />
            </Field>
          </Col>
        </Row>
      </section>
      <br />
      <section className='content-buttons'>
        <button
          className='modal-button margin-right-double primary button-hollow button-hollow-primary'
          onClick={closeModal}
        >
          {t('serviceOrders.change.os.to.reschedule.cancel.button')}
        </button>

        <button
          disabled={!activeSubmitButton()}
          className='modal-button button button-primary'
          onClick={onSubmit}
        >
          {t('button.label.save')}
        </button>
      </section>
    </div>
  )
}

ServiceOrderWorksite.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  newWorksiteNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentWorksiteNumber: PropTypes.number,
  currentWorksiteQuantity: PropTypes.number,
  onChangeWorksiteMode: PropTypes.func.isRequired,
  handleChangeWorksiteValue: PropTypes.func.isRequired,
  checkWorksiteQuantity: PropTypes.func.isRequired,
  resetWorksiteValues: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
}

const fields = ['removeWorksite', 'moveWorkisite', 'numberWorksite']

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...worksiteActions,
    },
    dispatch,
  )

const ServiceOrderWorksiteWithT = withTranslation()(ServiceOrderWorksite)

const ServiceOrderWorksiteConnected = connect(null, mapDispatchToProps)(ServiceOrderWorksiteWithT)

export default Form({ fields })(ServiceOrderWorksiteConnected)
