class ServcOrdCategoryType {
  static INSTALATION = new ServcOrdCategoryType(1, 'serviceOrders.category.type.installation')

  static TECHNICAL_VISIT = new ServcOrdCategoryType(
    2,
    'serviceOrders.category.type.technical.visit',
  )

  static TECHNICAL_VISIT_PLANNED_FURNITURE = new ServcOrdCategoryType(
    3,
    'serviceOrders.category.type.technical.visit.planned.furniture',
  )

  static PLANNED_FURNITURE_INSTALLATION = new ServcOrdCategoryType(
    4,
    'serviceOrders.category.type.planned.furniture.installation',
  )

  static STORE_SERVICE = new ServcOrdCategoryType(5, 'serviceOrders.category.type.store.service')

  static EMERGENCY_SERVICE = new ServcOrdCategoryType(
    6,
    'serviceOrders.category.type.emergency.service',
  )

  static RECURRING_SERVICE = new ServcOrdCategoryType(
    7,
    'serviceOrders.category.type.recurring.service',
  )

  static HOURLY_SERVICE = new ServcOrdCategoryType(8, 'serviceOrders.category.type.hourly.service')

  static CUSTOM_FURNITURE_FREIGHT = new ServcOrdCategoryType(
    9,
    'serviceOrders.category.type.custom.furniture.freight',
  )

  static BUDGET = new ServcOrdCategoryType(10, 'serviceOrders.category.type.budget')

  static WARRANTY = new ServcOrdCategoryType(11, 'serviceOrders.category.type.warranty2')

  static DISPLACEMENT = new ServcOrdCategoryType(12, 'serviceOrders.category.type.displacement')

  static INSTALLATION_PLUS_VISIT = new ServcOrdCategoryType(
    14,
    'service.order.subcategory.installation.plus.visit',
  )

  static HOGAMI = new ServcOrdCategoryType(15, 'service.order.subcategory.type.hogami')

  static TECHNICAL_VISIT_HIGH_POTENTIAL = new ServcOrdCategoryType(
    16,
    'serviceOrders.category.type.technical.visit.high.potential',
  )

  static CLAIM = new ServcOrdCategoryType(17, 'serviceOrders.category.type.claim')

  id = ''

  value = ''

  constructor(id, value) {
    this.id = id || ''
    this.value = value || ''
  }

  static getCatgryTypDesc(id) {
    switch (id) {
      case ServcOrdCategoryType.INSTALATION.id:
        return ServcOrdCategoryType.INSTALATION.value
      case ServcOrdCategoryType.TECHNICAL_VISIT.id:
        return ServcOrdCategoryType.TECHNICAL_VISIT.value
      case ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.id:
        return ServcOrdCategoryType.TECHNICAL_VISIT_PLANNED_FURNITURE.value
      case ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.id:
        return ServcOrdCategoryType.PLANNED_FURNITURE_INSTALLATION.value
      case ServcOrdCategoryType.STORE_SERVICE.id:
        return ServcOrdCategoryType.STORE_SERVICE.value
      case ServcOrdCategoryType.EMERGENCY_SERVICE.id:
        return ServcOrdCategoryType.EMERGENCY_SERVICE.value
      case ServcOrdCategoryType.HOURLY_SERVICE.id:
        return ServcOrdCategoryType.HOURLY_SERVICE.value
      case ServcOrdCategoryType.RECURRING_SERVICE.id:
        return ServcOrdCategoryType.RECURRING_SERVICE
      case ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT.id:
        return ServcOrdCategoryType.CUSTOM_FURNITURE_FREIGHT
      case ServcOrdCategoryType.BUDGET.id:
        return ServcOrdCategoryType.BUDGET.value
      case ServcOrdCategoryType.WARRANTY.id:
        return ServcOrdCategoryType.WARRANTY.value
      case ServcOrdCategoryType.DISPLACEMENT.id:
        return ServcOrdCategoryType.DISPLACEMENT.value
      case ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.id:
        return ServcOrdCategoryType.INSTALLATION_PLUS_VISIT.value
      case ServcOrdCategoryType.HOGAMI.id:
        return ServcOrdCategoryType.HOGAMI.value
      case ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.id:
        return ServcOrdCategoryType.TECHNICAL_VISIT_HIGH_POTENTIAL.value
      case ServcOrdCategoryType.CLAIM.id:
        return ServcOrdCategoryType.CLAIM.value
      default:
        return ''
    }
  }
}

export default ServcOrdCategoryType
