import i18next from 'i18next'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import Accordion from '../../../../../../../components/Accordeon'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { SHIFT_CODES } from '../../../../../../../constants/config'
import User from '../../../../../../../core/User'
import { useServiceOrderDetailContext } from '../../../../../context'
import './index.scss'
import { dailyReportsItems } from './mocks/items'
import { HistoricDailyReport } from './models/historic-daily-report'

const user = new User()?.currentUser

export default function HistoricDailyReports() {
  const { serviceOrderBody } = useServiceOrderDetailContext()

  const [dailyReports, setDailyReports] = useState<HistoricDailyReport[]>([])

  const [loading, setLoading] = useState(false)

  const getDailyReports = () => {
    // TODO: Call API
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setDailyReports(serviceOrderBody?.servcOrdSeq === 115785 ? dailyReportsItems : [])
    }, 1000)
  }

  useEffect(() => {
    if (serviceOrderBody) {
      getDailyReports()
    }
  }, [serviceOrderBody])

  const getShiftLabel = (shift: string) => i18next.t((SHIFT_CODES as any)[shift])

  const getContentText = (report: HistoricDailyReport) => {
    const translation = i18next.t(`serviceOrder.historic.dailyReports.${report.type}`)
    return `${report.date} - ${getShiftLabel(report.shift)} (${translation})`
  }

  const accordionHeader = {
    title: 'serviceOrder.historic.dailyReports.header',
    icon: <MozaicIcon icon='Document24' />,
  }

  const canRender =
    user?.accessFunctionList?.includes(138) &&
    serviceOrderBody &&
    [115785, 115786].includes(serviceOrderBody?.servcOrdSeq) // TODO: temporary condition

  if (!canRender) return <></>

  if (loading) return <div id='historic-daily-reports' className='skeleton' />

  return (
    <div id='historic-daily-reports'>
      <Accordion header={accordionHeader}>
        {!loading && !dailyReports.length && (
          <div className='empty-results'>
            {i18next.t('serviceOrder.historic.dailyReports.emptyResults')}
          </div>
        )}

        {dailyReports.map((report, i) => (
          <div key={i} className='daily-report'>
            <div className='daily-report-col'>
              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.order')}
                </div>
                <div className='item-content'>{report.order}</div>
              </div>

              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.dateAndShift')}
                </div>
                <div className='item-content'>{getContentText(report)}</div>
              </div>

              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.provider')}
                </div>
                <div className='item-content'>{report.provider}</div>
              </div>

              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.installer')}
                </div>
                <div className='item-content'>{report.installer}</div>
              </div>
            </div>

            <div className='daily-report-col'>
              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.description')}
                </div>
                <div className='item-content'>{report.description || '-'}</div>
              </div>

              <div className='item'>
                <div className='item-label'>
                  {i18next.t('serviceOrder.historic.dailyReports.photos')}
                </div>
                <div className='item-content'>
                  {report.photos.map((photo, i) => (
                    <div key={`photo-${i}`} className='item-content-photo'>
                      <img src={photo.data} alt={i.toString()} />
                      <span>{moment(photo.datetime).format('DD/MM/YYYY HH:mm')}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Accordion>
    </div>
  )
}
