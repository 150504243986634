import MozaicIcon from '../../../../components/MozaicIcon'
import store from '../../../../redux/store'
import { toggleMenu } from '../../../../redux/actions/menu'

function HamburgerMenu() {
  return (
    <div id='hamburger-menu-trigger' onClick={() => store.dispatch(toggleMenu())}>
      <MozaicIcon icon='DisplayMenu48' color='black' size={28} />
    </div>
  )
}

export default HamburgerMenu
