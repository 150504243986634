import { memo } from 'react'

import MozaicIcon from '../../../../components/MozaicIcon'
import { useSideNavContext } from '../../../../contexts/sidenav'

/*
  Description component: container usage on app bar to open details of user
*/
function Profile() {
  const { handleToggleProfileInfo } = useSideNavContext()

  return (
    <div id='profile'>
      <section className='content-image'>
        <MozaicIcon
          icon='AccountProfileView32'
          color='black'
          size={40}
          onClick={handleToggleProfileInfo}
        />
      </section>
    </div>
  )
}

export default memo(Profile)
