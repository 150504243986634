import PropTypes from 'prop-types'
import { Component } from 'react'

import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Row from '../../../components/utils/Row'
import { WithForm } from '../../../helpers/useForm/withForm'

class ServiceOrderLaudo extends Component {
  render() {
    const { t, fields, disabled, register } = this.props

    return (
      <form>
        <Row>
          <Col xs={12} className='margin-bottom'>
            <legend className='margin-top margin-bottom-small'>{t('serviceOrders.laudo')}</legend>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className='margin-bottom'>
            <Field {...fields.descLaudoTecnico}>
              <MozaicTextArea
                {...register('descLaudoTecnico')}
                className='resize-none'
                label={t('serviceOrders.laudo')}
                rows={6}
                isDisabled={disabled}
              />
            </Field>
          </Col>
        </Row>
      </form>
    )
  }
}

ServiceOrderLaudo.propTypes = {
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
}

const FIELDS = { descLaudoTecnico: '' }

export default WithForm(FIELDS)(ServiceOrderLaudo)

export { ServiceOrderLaudo }
