import i18next from 'i18next'

import CustomCard from '../../../../../../../components/Card'

import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { WithContext } from './context'

function Observations({ pyxisDesc, loadingObs }: any) {
  return (
    <>
      {pyxisDesc !== '' && (
        <div id='observations-container'>
          <CustomCard
            styled
            title={i18next.t('serviceOrders.pyxisDesc')}
            iconNode={<MozaicIcon icon='PublishEdit24' />}
          >
            {loadingObs ? (
              <span className='observation-text shimmer-effect' />
            ) : (
              <span className='observation-text'>{pyxisDesc || ''}</span>
            )}
          </CustomCard>
        </div>
      )}
    </>
  )
}

export default WithContext(Observations)
