import { useEffect, useMemo, useState } from 'react'
import Storage from '../../../services/firestore/storage'

type Ocurrence = {
  buCd: number
  cntryCd: string
  creatTs: string
  creatUserCd: string
  mobileUserCd: string
  nomUserRegistroOcor: string
  servcOrdEvntSeq: number
  servcOrdEvntStusInd: string
  servcOrdItemEvntDesc: string
  servcOrdItemEvntId: number
  servcOrdItemStusCd: string
  servcOrdSchdlgTs: any
  servcOrdSeq: number
  servcPrvdrAgntCd: number
  servcPrvdrCd: number
  servcXecutnStrtDt: string
}

function AudioPlayer({ ocurrence, storage }: { ocurrence: Ocurrence; storage: Storage }) {
  const [file, setFile] = useState<Blob>()
  const shortPath = ocurrence.servcOrdItemEvntDesc

  const url = useMemo(() => (file ? URL.createObjectURL(file) : ''), [file])

  useEffect(() => {
    if (shortPath) storage.getFile(shortPath).then((blob) => setFile(blob))
  }, [shortPath])

  if (!url) return <audio />

  return (
    <audio controls>
      <source src={url} type='audio/mpeg' />
      Your browser does not support the audio element.
    </audio>
  )
}

export default AudioPlayer
