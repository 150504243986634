import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

const user = new User().currentUser

export async function deleteServiceOrderAttachment(servcOrdItemAtachmtId: number): Promise<any> {
  const { cdStatus } = await new API_REQUEST(user.dsToken, store.dispatch).delete(
    API_URL.DELETE_ORDER_SERVC_ATTACHMENT,
    { servcOrdItemAtachmtId },
  )

  return cdStatus
}
