import MozaicBadge from '../MozaicBadge'

interface CounterContainerProps {
  text: number | string
  theme: 'info' | 'success' | 'danger' | 'warning' | 'neutral'
}

function CounterContainer({ text, theme }: CounterContainerProps) {
  const counterText = Number(text) > 99 ? `+99` : String(text)

  return <MozaicBadge className='badge-circle' text={counterText} theme={theme} />
}

export default CounterContainer
