import { useEffect, useState } from 'react'
import { Flex } from '@mozaic-ds/react'
import { toast } from '@leroy-merlin-br/backyard-react'
import Skeleton from 'react-loading-skeleton'

import { GCloudFileRef } from '../../../../../../../../../services/firestore/gCloudStorage/GCloudFileRef'
import { GCloudImageRef } from '../../../../../../../../../services/firestore/gCloudStorage/GCloudImageRef'

import GCloudFileLegacy from '../../../../../../../../../components/GCloudFileLegacy'
import { DeleteModal } from '../DeleteModal'
import GCloudFileView from '../../../../../../../../../components/GCloudFileView'
import { deleteServiceOrderAttachment } from '../../../../../../../../../services/orders/deleteServiceOrderAttachment'
import { fetchServiceOrderAttachments } from '../../../../../../../../../services/orders/fetchServiceOrderAttachments'
import { ServiceOrderAttachmentsEnum } from '../../../../../../../../../enums/ServiceOrder/ServiceOrderAttachmentsEnum'
import { useServiceOrderDetailContext } from '../../../../../../../context'

import { AttachmentV2 } from '../../../../../../../../../services/firestore/gCloudStorage/models'
import { getFileRef } from '../../../../../../../../../services/firestore/gCloudStorage/fetchAttachmentsV2'
import User from '../../../../../../../../../core/User'

const user = new User()

export type File = GCloudFileRef | GCloudImageRef

const ACCESS_FUNCTION_TO_DELETE_FILES_IN_TECH_VISIT_PLANNED_FURNITURE = 107

interface MeasurementSheetFilesProps {
  filesWereSent: boolean
  filesWereRejected: boolean | undefined
  onUpdateFileStateFinished: (hasMeasurementSheet: boolean) => void
}

export function MeasurementSheetFiles({
  filesWereSent,
  filesWereRejected,
  onUpdateFileStateFinished,
}: MeasurementSheetFilesProps) {
  const [isLoadingMeasurementSheetFiles, setIsLoadingMeasurementSheetFiles] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [files, setFiles] = useState<Array<File>>([])
  const [fileToView, setFileToView] = useState<number | null>(null)
  const [fileToDelete, setFileToDelete] = useState<File | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)

  const { servcOrdCd } = useServiceOrderDetailContext()

  const hasPermissionToDeleteFiles = user.hasAccessFunction(
    ACCESS_FUNCTION_TO_DELETE_FILES_IN_TECH_VISIT_PLANNED_FURNITURE,
  )

  const canDeleteFiles = hasPermissionToDeleteFiles && !filesWereRejected

  const hasFiles = files.length > 0

  const getMeasurementSheetFiles = async () => {
    try {
      setIsLoadingMeasurementSheetFiles(true)

      const response = await fetchServiceOrderAttachments({
        servcOrdSeq: servcOrdCd,
        servcOrdItemAtchmtClsfctnCd: [ServiceOrderAttachmentsEnum.MEASUREMENT_SHEET],
        actvServcOrdItemAtchmtInd: 1,
        downloadFiles: false,
      })

      const fileRefs = response?.data?.map((file: AttachmentV2) => getFileRef(file))

      if (fileRefs?.length) {
        setFiles(fileRefs)
        onUpdateFileStateFinished(true)
      } else {
        onUpdateFileStateFinished(false)
      }
    } catch (error: any) {
      console.error('Error to fetch measurement files', error)
      onUpdateFileStateFinished(false)
    } finally {
      setIsLoadingMeasurementSheetFiles(false)
    }
  }

  useEffect(() => {
    getMeasurementSheetFiles()
  }, [])

  const removeMeasumentSheetFileFromState = (measurementSheetId: number) => {
    const newFiles = files.filter((f) => f.attachment.servcOrdItemAtachmtId !== measurementSheetId)
    setFiles(newFiles)
    return newFiles
  }

  const handleOnClickConfirmDelete = async () => {
    try {
      if (fileToDelete) {
        setIsDeleting(true)
        const response = await deleteServiceOrderAttachment({
          servcOrdCd: fileToDelete.attachment.servcOrdSeq,
          servcOrdItemAtachmtId: fileToDelete.attachment.servcOrdItemAtachmtId,
        })
        toast.success('', response.message?.dsMessage, '')
        // Prevents the file from being opened after deletion
        setFileToView(null)
        const files = removeMeasumentSheetFileFromState(
          fileToDelete.attachment.servcOrdItemAtachmtId,
        )
        onUpdateFileStateFinished(files.length > 0)
        setIsDeleteModalOpen(false)
      }
    } catch (error) {
      console.error('Error to delete measurement sheet file', error)
    } finally {
      setIsDeleting(false)
    }
  }

  const handleOnClickDeleteButton = (file: File) => {
    setIsDeleteModalOpen(true)
    setFileToDelete(file)
  }

  if (!filesWereSent) return <></>

  return (
    <Flex gap='mu100' paddingTop='mu100' paddingBottom='mu100'>
      {isLoadingMeasurementSheetFiles ? (
        <Flex
          marginVertical='mu100'
          justifyContent='center'
          alignItems='center'
          gap='mu100'
          wrap='wrap'
          className='measurement-files-loader'
        >
          <Skeleton height={200} width={200} />
          <Skeleton height={200} width={200} />
          <Skeleton height={200} width={200} />
        </Flex>
      ) : (
        <>
          {hasFiles &&
            files.map((file, i) => (
              <GCloudFileLegacy
                key={file.attachment.servcOrdItemAtachmtId}
                file={file}
                canDelete={canDeleteFiles}
                onDeleteRequest={handleOnClickDeleteButton}
                onClick={() => setFileToView(i)}
              />
            ))}

          {fileToDelete && isDeleteModalOpen && (
            <DeleteModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              isDeleting={isDeleting}
              onDelete={handleOnClickConfirmDelete}
            />
          )}

          {fileToView !== null && (
            <GCloudFileView
              files={files}
              canDelete={canDeleteFiles}
              fileIndex={fileToView}
              handleClose={() => setFileToView(null)}
              onDelete={handleOnClickDeleteButton}
            />
          )}
        </>
      )}
    </Flex>
  )
}
