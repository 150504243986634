import { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import Row from './Row'
import Col from './Col'
import Form from './Form'
import MozaicTextArea from '../MozaicTextArea'

class PushModal extends Component {
  constructor(props) {
    super(props)
    this.handleSendPush = this.handleSendPush.bind(this)
  }

  handleSendPush() {
    const { sendPush, values, idEspecialistaServico, idPrestadorServico } = this.props

    sendPush(idPrestadorServico, idEspecialistaServico, values.pushText)
  }

  render() {
    const { t, fields, closeModal } = this.props

    return (
      <Modal closeModal={closeModal} size='sm'>
        <form>
          <Row>
            <Col xs={12} className='margin-bottom'>
              <legend className='margin-top margin-bottom-small'>{t('pushModal')}</legend>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className='margin-bottom'>
              <MozaicTextArea
                {...fields.pushText.input}
                className='textarea-custom'
                type='textarea'
                id='pushText'
                placeholder={t('pushModal.pushText')}
                rows={6}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6} className='button-align'>
              <button
                className='button-hollow button-hollow-danger button-full align-center'
                type='button'
                onClick={closeModal}
              >
                {t('cancel_button_label')}
              </button>
            </Col>

            <Col xs={6} md={6} className='button-align'>
              <button
                className='button button-primary button-full'
                type='button'
                onClick={this.handleSendPush}
              >
                {t('pushModal')}
              </button>
            </Col>
          </Row>
        </form>
      </Modal>
    )
  }
}

PushModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendPush: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  idEspecialistaServico: PropTypes.number,
  idPrestadorServico: PropTypes.number,
}

const fields = ['pushText']

export default Form({ fields })(PushModal)

export { PushModal }
