import Api from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { renderToastError } from '../helpers/ToastUtils'
import { showSpinner, hideSpinner } from '../redux/actions/spinner'
import store from '../redux/store'

export const requestService = (newInfo, callback = () => {}) => {
  const params = {
    ...newInfo,
  }

  return new Api(null, store.dispatch)
    .post(API_URL.CUSTOMER_REQUEST_SERVICE, params)
    .then(store.dispatch(showSpinner()))
    .then(
      () => {
        callback()
      },
      (err) => {
        renderToastError(err.message.dsMessage)
      },
    )
    .finally(() => store.dispatch(hideSpinner()))
}

export const fetchPdvs = (filters) => {
  const { auth } = store.getState()
  const params = {
    ...filters,
    lsPlants: auth?.user?.lsPlants,
  }

  return new Api(null, store.dispatch)
    .post(API_URL.FETCH_CUSTOMER_PDVS, params)
    .then(store.dispatch(showSpinner()))
    .then(
      (response) => response.data,
      (err) => {
        renderToastError(err.message.dsMessage)
        return err
      },
    )
    .finally(() => store.dispatch(hideSpinner()))
}
