import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import store from '../../redux/store'
import { ADEOResponse } from '../../models/AdeoResponse'

/**
 * @description Approve or reprove measurement sheet file for a service order
 */

export const approvalMeasurementSheetFile = async (payload: {
  servcOrdSeq: number
  servcOrdStusCd: string
  measurementSheetApproved: boolean | null
  measurementSheetMessage: string
}): Promise<ADEOResponse<{ message: string }>> =>
  new API_REQUEST(null, store.dispatch).put(API_URL.PUT_MEASUREMENT_SHEET_APPROVAL, payload)
