import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'
import store from '../redux/store'

export const findProviderAgentAbsence = async (user, filters) => {
  const query = {
    ...filters,
    cntryCd: user.cntryCd,
    buCd: user.buCd,
  }

  try {
    const { data } = await new API(user.dsToken, store.dispatch).post(
      API_URL.SERVC_PRVDR_AGNT_ABSNC_PERD_FIND,
      query,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
