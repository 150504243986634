import moment, { isMoment } from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import RemoveModal from '../../components/utils/ConfirmModal'
import FeedbackError from '../../components/utils/FeedbackError'
import FeedbackSuccess from '../../components/utils/FeedbackSuccess'
import Grid from '../../components/utils/Grid'
import Notification from '../../components/utils/Notification'
import Row from '../../components/utils/Row'
import Pagination from '../../components/utils/pagination/Pagination'
import { FORMAT_DATE_TRUNC, ORDER_STATUS_TYPE } from '../../constants/config'
import { formatDecimalNoCurrency, removeMask } from '../../helpers/masks'
import { isEmpty } from '../../helpers/validations'
import * as serviceOrderActions from '../../redux/actions/serviceOrder'
import Form from './components/NfseModal'
import ServiceOrderTable from './components/Table'
import AdvancedSearch from './components/advancedSearch/AdvancedSearch'
import { WithContext } from './context'

class PaymentsList extends Component {
  constructor(props) {
    super(props)
    this.initialState()
  }

  initialState = () => {
    const { filter } = this.props

    const newFilter = { ...this.initialFields(), ...filter }

    this.state = {
      filters: newFilter,
      nfseModal: false,
      removeModal: false,
      idSelectedOS: null,
      serviceOrder: null,
      hasFiltered: false,
      isEdition: false,

      plntCd: '',
      filterOrderNr: '',
      filterOrderNote: '',
      error: null,
      success: null,
    }
  }

  initialFields = () => ({
    servcOrdCd: '',
    plntCd: '',
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
    hasFilters: true,
    inLinkedOrder: false,
    servcPrvdrCd: null,
    genericSearch: '',
  })

  beforeFetchPaymentsServiceOrders = () => {
    const { filters } = this.state

    const newFilters = { ...filters }
    const { filterOrderNr, plntCd } = this.state

    const {
      auth: { user },
    } = this.props

    let newPlntCd

    if (plntCd) {
      newPlntCd = [plntCd]
    } else if (!this.hasFunction17()) {
      newPlntCd = [...user.lsPlants]
    }

    newFilters.servcOrdCd = filterOrderNr
    newFilters.plntCd = newPlntCd
    newFilters.servcOrdStusCd = ORDER_STATUS_TYPE.CLOSED
    newFilters.nfseAnalysis = newFilters.nfseAnalysis && newFilters.nfseAnalysis.value
    newFilters.nfAnalysisSituation =
      newFilters.nfAnalysisSituation && newFilters.nfAnalysisSituation.value
    newFilters.nfAnalysisPaymentStatus =
      newFilters.nfAnalysisPaymentStatus && newFilters.nfAnalysisPaymentStatus.value
    newFilters.status = newFilters.status && newFilters.status.value
    newFilters.genericSearch = newFilters.genericSearch && newFilters.genericSearch
    newFilters.hasFunction17 = this.hasFunction17()
    newFilters.startDate = isMoment(newFilters.startDate)
      ? newFilters.startDate.startOf('day')
      : null
    newFilters.endDate = isMoment(newFilters.endDate) ? newFilters.endDate.startOf('day') : null

    /* istanbul ignore else */
    if (newFilters.servcPrvdrFedrlTaxIdNr) {
      newFilters.servcPrvdrFedrlTaxIdNr = removeMask(newFilters.servcPrvdrFedrlTaxIdNr)
    }

    return newFilters
  }

  componentDidMount() {
    const { filter } = this.props

    let page = 1

    if (filter && filter.hasOwnProperty('page')) {
      page = filter.page
    }

    this.handleFetchPaymentsServiceOrders(page)
  }

  hasFunction17 = () => {
    const {
      auth: { user },
    } = this.props
    return user?.funcaoAcesso?.inFuncao17 === 1
  }

  handleFetchPaymentsServiceOrders = (page, servcOrdSeq) => {
    const {
      fetchPaymentsServiceOrders,
      auth: { user },
    } = this.props

    const filters = this.beforeFetchPaymentsServiceOrders()

    fetchPaymentsServiceOrders(user.dsToken, filters, page).then(() =>
      this.setState({ idSelectedOS: servcOrdSeq }),
    )

    this.clearErrors()
  }

  handleSubmit = (values) => {
    const filters = { ...values }
    filters.hasFilters = true

    this.setState(
      (state) => ({
        filters: {
          ...filters,
          servcPrvdrFedrlTaxIdNr: state.filters.servcPrvdrFedrlTaxIdNr,
          genericSearch: state.filters.genericSearch,
        },
        hasFiltered: true,
        isEdition: false,
      }),
      () => {
        this.handleFetchPaymentsServiceOrders()
      },
    )
  }

  renderNotification = () => {
    const { t } = this.props
    return (
      <section style={{ width: '98.3%' }}>
        <Notification
          className='notification-warning margin-top'
          message={t('serviceOrders-list.not-found')}
        />
      </section>
    )
  }

  handlePaymentServiceOrderRemove = () => {
    const {
      paymentServiceOrderRemove,
      auth: { user },
    } = this.props
    const { serviceOrder } = this.state

    paymentServiceOrderRemove(user.dsToken, serviceOrder, this.handleFetchPaymentsServiceOrders)
      .then((success) => this.setState({ success }))
      .catch((error) => this.setState({ error }))
  }

  addServiceOrder = (serviceOrder, index) => {
    const { idSelectedOS } = this.state
    const { serviceOrders } = this.props

    // eslint-disable-next-line no-param-reassign
    serviceOrder.checked = true

    serviceOrders[index] = serviceOrder

    this.setState({ serviceOrders })

    /* istanbul ignore else */
    if (!idSelectedOS) {
      this.addNfFilters(serviceOrder)
    }
  }

  addNfFilters = (serviceOrder) => {
    const { filters } = this.state

    filters.servcPrvdrCd = serviceOrder.servcPrvdrCd
    filters.idOrdemServicoSelecionada = serviceOrder.servcOrdSeq
    filters.servcPrvdrNm = serviceOrder.servcPrvdrTrdNm

    this.setState({ filters })

    this.handleFetchPaymentsServiceOrders(1, serviceOrder.servcOrdSeq)
  }

  addFilterRltn = (serviceOrder) => {
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          inLinkedOrder: true,
          servcPrvdrCd: serviceOrder.servcPrvdrCd,
        },
      }),
      () => {
        this.handleFetchPaymentsServiceOrders(1, serviceOrder.servcOrdSeq)
      },
    )
  }

  removeServiceOrder = (serviceOrder, index) => {
    const { serviceOrders } = this.props

    serviceOrders[index] = {
      ...serviceOrder,
      checked: false,
    }

    this.setState({ serviceOrders })

    if (this.clearBlanks() === 0) {
      this.removeNfFilters()
    }
  }

  removeNfFilters = () => {
    const { filters } = this.state

    filters.servcPrvdrCd = ''
    filters.idOrdemServicoSelecionada = null
    filters.servcPrvdrNm = ''

    this.setState({ idSelectedOS: null, filters })
  }

  newNfse = () => {
    this.setState({ serviceOrder: null, isEdition: false })
    this.tougleNfseModal()
  }

  tougleNfseModal = () => {
    const { nfseModal } = this.state

    this.setState({ nfseModal: !nfseModal })
  }

  closeNfseModal = () => {
    const { isEdition } = this.state

    this.tougleNfseModal()
    if (isEdition) {
      this.setState({ isEdition: false })
      this.removeAll()
    }
  }

  removeAll = () => {
    const { serviceOrders } = this.props

    serviceOrders.map((serviceOrder, index) => {
      serviceOrders[index] = {
        ...serviceOrder,
        checked: false,
      }
    })

    this.setState({ checkedAll: false })
  }

  tougleRemoveModal = (serviceOrder) => {
    const { removeModal } = this.state

    this.setState({ removeModal: !removeModal, serviceOrder })
  }

  tougleEditRemoveModal = () => {
    const { removeModal } = this.state

    this.tougleNfseModal()
    this.setState({ removeModal: !removeModal })
  }

  onChangeGenericSearch = (genericSearch) => {
    this.setState((state) => ({
      ...state,
      filters: { ...state.filters, genericSearch },
    }))
  }

  renderNfseModal = () => {
    const { nfseModal, serviceOrder, isEdition } = this.state
    const { t } = this.props

    if (!nfseModal) {
      return
    }

    let initialValues = {
      inRework: this.getInRework(),
    }

    /* istanbul ignore else */
    if (serviceOrder) {
      if (serviceOrder.servcOrdInvcNr) {
        initialValues = {
          buCd: serviceOrder.buCd,
          cntryCd: serviceOrder.cntryCd,
          servcPrvdrCd: serviceOrder.servcPrvdrCd,
          numNfse: serviceOrder.servcOrdInvcNr || '',
          datEmissNfse: serviceOrder.servcOrdInvcIssuDt
            ? moment(serviceOrder.servcOrdInvcIssuDt).format(FORMAT_DATE_TRUNC)
            : '',
          valNfse: formatDecimalNoCurrency(serviceOrder.servcOrdInvcAmt, t),
          indEnvioPgto: serviceOrder.servcOrdInvcPymtInd || '',
          inRework: serviceOrder.servcOrdStusCd === ORDER_STATUS_TYPE.CLOSED,
          servcOrdCd: serviceOrder.servcOrdCd,
          servcOrdInvcCd: serviceOrder.servcOrdInvcCd,
        }
      } else if (serviceOrder.servcOrdStusCd === ORDER_STATUS_TYPE.CLOSED) {
        initialValues = {
          indEnvioPgto: '2',
          inRework: serviceOrder.servcOrdStusCd === ORDER_STATUS_TYPE.CLOSED,
          servcOrdCd: serviceOrder.servcOrdCd,
        }
      }
    }

    return (
      <Form
        t={t}
        closeModal={this.closeNfseModal}
        saveNfse={this.handleSaveNfse}
        isEdition={isEdition}
        initialValues={initialValues}
        removeServiceOrder={this.tougleEditRemoveModal}
      />
    )
  }

  renderRemoveModal = () => {
    const { removeModal, serviceOrder } = this.state
    const { t } = this.props

    if (!removeModal) {
      return
    }

    return (
      <RemoveModal
        t={t}
        closeModal={this.tougleRemoveModal}
        action={this.handlePaymentServiceOrderRemove}
        messageHeader={t('payments.remove.invoice.linkage.header')}
        message={t('payments.remove.invoice.linkage.message')
          .replace('{0}', serviceOrder.servcOrdInvcNr || '')
          .replace('{1}', serviceOrder.servcOrdCd)}
        submitText={t('payments.remove.invoice.linkage.confirm')}
      />
    )
  }

  handleSaveNfse = (values) => {
    const {
      auth: { user },
      paymentServiceOrderSave,
      serviceOrders,
    } = this.props

    const listServiceOrder = []
    for (let i = 0; i < serviceOrders.length; i++) {
      if (serviceOrders[i] && serviceOrders[i].checked) {
        listServiceOrder.push(serviceOrders[i])
      }
    }

    if (values.indEnvioPgto === '2') {
      // eslint-disable-next-line no-param-reassign
      values.numNfse = 0
    }

    const nfServiceOrder = {
      ...values,
      serviceOrders: listServiceOrder,
    }
    const { filters } = this.state

    filters.servcPrvdrCd = ''
    filters.idOrdemServicoSelecionada = null

    this.setState({
      idSelectedOS: null,
      filters,
    })

    paymentServiceOrderSave(user.dsToken, nfServiceOrder, this.handleFetchPaymentsServiceOrders)
      .then((success) => this.setState({ success }))
      .catch((error) => this.setState({ error }))
  }

  renderFeedbackError = () => {
    const { error } = this.props

    if (error) {
      return <FeedbackError className='margin-top margin-bottom' message={error} />
    }
  }

  renderFeedbackSuccess = () => {
    const { success } = this.props

    if (success) {
      return <FeedbackSuccess className='margin-top margin-bottom' message={success} />
    }
  }

  clearBlanks = () => {
    const { serviceOrders } = this.props

    const listServiceOrder = []
    for (let i = 0; i < serviceOrders.length; i++) {
      if (serviceOrders[i] && serviceOrders[i].checked) {
        listServiceOrder.push(serviceOrders[i])
      }
    }

    return listServiceOrder.length
  }

  getInRework = () => {
    const { serviceOrders } = this.props

    for (let i = 0; i < serviceOrders.length; i++) {
      if (
        serviceOrders[i] &&
        serviceOrders[i].checked &&
        serviceOrders[i].servcOrdStusCd !== ORDER_STATUS_TYPE.CLOSED
      ) {
        return false
      }
    }

    return true
  }

  setServcPrvdrFedrlTaxIdNr = (servcPrvdrFedrlTaxIdNr) => {
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        servcPrvdrFedrlTaxIdNr,
        genericSearch: servcPrvdrFedrlTaxIdNr,
      },
    }))
  }

  handleChangeDate = (e, field) => {
    this.setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        [field]: e,
      },
    }))
  }

  onChangeBranch = (plntCd) => {
    this.setState({ plntCd })
  }

  onChangeNrOrder = (orderNr) => {
    this.setState({ filterOrderNr: orderNr })
  }

  clearErrors = () => {
    this.setState({ success: null, error: null })
  }

  render() {
    const { dispatch, t, serviceOrders, pages, current, count, auth, onClickTableOrder } =
      this.props

    const { filters, isEdition, filterOrderNr, plntCd, filterOrderNote } = this.state

    return (
      <div id='payments-container'>
        <BreadcrumbBar title={t('lmi.payments')} />

        <Grid className='payments-container_body'>
          <Row>
            <h2>{t('lmi.payments')}</h2>
            <h4>{t('payments.filters')}</h4>
          </Row>

          <Row>
            <AdvancedSearch
              t={t}
              auth={auth}
              count={count}
              dispatch={dispatch}
              initialValues={filters}
              handleChangeDate={this.handleChangeDate}
              servcPrvdrNmNFS={filters.servcPrvdrNm || ''}
              handleSubmit={this.handleSubmit}
              onChangeNrOrder={this.onChangeNrOrder}
              filterOrderNr={filterOrderNr}
              filterOrderNote={filterOrderNote}
              plntCd={plntCd}
              onChangeBranch={this.onChangeBranch}
              onChangeGenericSearch={this.onChangeGenericSearch}
              setServcPrvdrFedrlTaxIdNr={this.setServcPrvdrFedrlTaxIdNr}
              genericSearch={filters.genericSearch}
            />
          </Row>

          {this.renderFeedbackError()}
          {this.renderFeedbackSuccess()}

          <Row>
            {isEmpty(serviceOrders) ? (
              this.renderNotification()
            ) : (
              <ServiceOrderTable
                serviceOrders={serviceOrders}
                t={t}
                paymentServiceOrderRemove={this.tougleRemoveModal}
                addServiceOrder={this.addServiceOrder}
                removeServiceOrder={this.removeServiceOrder}
                newNfse={this.newNfse}
                auth={auth}
                addFilterRltn={this.addFilterRltn}
                disableNewNfse={this.clearBlanks() === 0 || (this.clearBlanks() === 1 && isEdition)}
                onClickTableOrder={onClickTableOrder}
                onChangeNrOrder={this.onChangeNrOrder}
                onChangeBranch={this.onChangeBranch}
                onChangeServcPrvdrFedrlTaxIdNr={this.setServcPrvdrFedrlTaxIdNr}
              />
            )}
          </Row>

          {pages > 1 && (
            <div className='margin-top margin-bottom-double'>
              <div className='align-center'>
                <Pagination
                  getPage={this.handleFetchPaymentsServiceOrders}
                  pages={pages}
                  current={current}
                  count={count}
                />
              </div>
            </div>
          )}
        </Grid>

        {this.renderNfseModal()}
        {this.renderRemoveModal()}
      </div>
    )
  }
}

PaymentsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pages: PropTypes.number,
  count: PropTypes.number,
  current: PropTypes.number,
  serviceOrders: PropTypes.array,
  fetchPaymentsServiceOrders: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  filter: PropTypes.object,
  paymentServiceOrderRemove: PropTypes.func.isRequired,
  paymentServiceOrderSave: PropTypes.func.isRequired,
  error: PropTypes.any,
  success: PropTypes.any,
  onClickTableOrder: PropTypes.func,
}

const mapStateToProps = ({ paginateReducer, auth }) => ({
  auth,
  pages: paginateReducer.pages,
  count: paginateReducer.count,
  current: paginateReducer.current,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...serviceOrderActions }, dispatch)

const PaymentsListTranslated = withTranslation()(WithContext(PaymentsList))

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsListTranslated)

export { PaymentsList as ServiceOrderList }
