import store from '../../redux/store'
import FetchAdapter from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import { ADEOResponse } from '../../models/AdeoResponse'

const user = new User()

export const deleteInstaller = (servcPrvdrAgntId: number): Promise<ADEOResponse<any>> =>
  new FetchAdapter(user.currentUser?.dsToken, store.dispatch).post(
    API_URL.DELETE_SERVICE_SPECIALIST,
    { servcPrvdrAgntId },
  )
