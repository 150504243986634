import PropTypes from 'prop-types'
import { Component } from 'react'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'

class AwsImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      base64: props.base64Ready,
      disabled: !props.base64Ready,
    }
  }

  componentDidMount() {
    const { base64Ready } = this.props
    if (!base64Ready) {
      this.getBase64()
    }
  }

  componentDidUpdate(prevProps) {
    const { filePath } = this.props

    if (filePath !== prevProps.filePath) {
      this.getBase64()
    }
  }

  getData = async (token, filePath, dispatch) => {
    let base64 = null
    await new Api(token, dispatch).get(API_URL.AMAZON_AWS_GET_FILE, { filePath }).then(
      (response) => {
        base64 = response.data
        return response.data
      },
      (error) => {
        console.error('Faleid base64 image', error)
        return ''
      },
    )
    return base64
  }

  getBase64 = async () => {
    const { filePath, token, dispatch } = this.props
    this.setState({ disabled: true })
    const base64 = await this.getData(token, filePath, dispatch)
    if (base64) {
      this.setState({ disabled: false })
    }
    this.setState({ base64 })
  }

  render() {
    const { className, children, downloadPdf, pdfName } = this.props
    const { base64, disabled } = this.state
    const downloadHref = downloadPdf
      ? `data:application/pdf;base64,${base64}`
      : `data:application/octet-stream;base64,${base64}`

    return (
      <a
        className={`${disabled ? 'disabled' : ''} ${className}`}
        download={pdfName}
        href={downloadHref}
      >
        {children}
      </a>
    )
  }
}

AwsImage.propTypes = {
  base64Ready: PropTypes.string,
  filePath: PropTypes.string,
  className: PropTypes.string,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.any,
  downloadPdf: PropTypes.bool,
  pdfName: PropTypes.string,
}

export default AwsImage
