export const ocurrencesType = {
  REFUSED: '1',
  EXPIRED: '2',
  NO_STARTED: '3',
  ON_MY_WAY: '4',
  ATTENTION: '5',
  REMOVED_ATTENTION: '6',
  REWORK_DESCRIPTION: '7',
  CUST_CONFIRMATION: '8',
  CUST_SCHEDULED: '9',
  CUST_NEED_HELP: '10',
  AUTOMATIC_SURVEY_SATISFACTION: '11',
  PARTIALLY_DONE: '12',
  SMS_AUTOMATIC_CONFIRMATION: '13',
  SMS_AUTOMATIC_EVALUATION: '14',
  ANSWER_OF_THE_BACKOFFICE: '15',
  ANSWER_OF_THE_CUSTOMER: '16',
  MANUAL_SATISFACTION_SURVEY: '17',
  ACCEPTED: '18',
  SO_WITHOUT_EVALUATION_CLOSED: '19',
  SO_AUTOMATIC_DISTRIBUTED: '20',
  EMAIL_RESCHEDULE: '21',
  SMS_RESCHEDULE: '22',
  RESCHEDULED_SERVICE_ORDER_EDITION: '23',
  EMAIL_CONFIRMATION: '24',
  EMAIL_EVALUATION: '25',
  BUDGET_PENDING: '26',
  QUOTE_WITHOUT_RESPONSE: '27',
  QUOTATION_APPROVED: '28',
  QUOTATION_REFUSED: '29',
  AWAITING_APPROVAL_OUT_OF_FLOW: '30',
  APPROVED_OUT_OF_FLOW: '31',
  REFUSED_OUT_OF_FLOW: '32',
  NOT_CONFORM_WITHOUT_TECH_VISIT: '33',
  AWAITING_ACCEPTANCE_SALES_CONDITIONS: '34',
  SMS_ACCEPTANCE_SALES_CONDITIONS: '35',
  EMAIL_ACCEPTANCE_SALES_CONDITIONS: '36',
  ACCEPTANCE_SALES_CONDITIONS: '37',
  AWAITING_OS_IN_CONFORMITY: '38',
  CONFIRMED_OS_IN_CONFORMITY: '39',
  AWAITING_OS_IN_CONFORMITY_SMS: '40',
  AWAITING_OS_IN_CONFORMITY_EMAIL: '41',
  QUOTATION_REFUSED_BY_EXPIRATION_JOB: '42',
  SHOULD_TECHNICAL_VISIT_APPEAR: '43',
  ACCEPTED_OS_IN_CONFORMITY_EMAIL: '44',
  SO_WITHOUT_CONFORMITY_CLOSED: '45',
  CUSTOMER_RESCHEDULE_CONFIRMATION: '46',
  OCCURRENCE_AMOUNT_ACCEPTED: '47',
  REFUSAL_PROCESS: '48',
  UNNACOMPLISHED_SERVICE: '49',
  SO_STARTED: '50',
  SO_CLOSED: '51',
  MANUAL_DISTRIBUTION: '52',
  STATUS_MODIFY: '53',
  SHIFT_MODIFY: '54',
  DATE_MODIFY: '55',
  ADDRES_MODIFY: '56',
  SO_MANUALLY_DISTRIBUTED: '57',
  SMS_AUTOMATIC_BUDGET: '58',
  EMAIL_AUTOMATIC_BUDGET: '59',
  TECHNICAL_VISIT_SHOULD_APPEAR_IN_THE_REPORT: '60',
  SEND_NFE: '61',
  REMOVED_NFE: '62',
  SO_CANCELED_WHEN_NO_SERVICE_QUANTITY_IN_ORDER: '63',
  SO_REOPENED: '65',
  SEND_NFE_INTERMEDIATION: '66',
  PAYMENT_MADE: '67',
  MEASUREMENT_SHEET_SENT: '68',
  MEASUREMENT_SHEET_APPROVED: '70',
  MEASUREMENT_SHEET_REPROVED: '71',
  AGENT_RESCHEDULE_REQUESTED: '72',
  CUSTOMER_ACCEPT_RESCHEDULE: '73',
  CUSTOMER_ACCEPT_RESCHEDULE_ADVANCE_DATE: '74',
  CUSTOMER_REFUSE_RESCHEDULE: '75',
  QUOTE_AVAILABLE: '76',
  CLOSE_NON_CONFORMING: '77',
  STORE_ORDER_EXPIRED: '78',
  EMERGENCY_DISTRIBUTION: '79',
  PAYMENT_CONFIRMATION: '80',
  STORE_FLOW: '81',
  WORKSITE_MODIFY: '82',
  SLOT_MAX_QUANTITY: '83',
  MSG_EVNT_STUS: '85',
  AUDIO_EVNT_STUS: '86',
  WARRANTY_TRIGGERED: '87',
  WARRANTY_FLOW: '88',
  RESCHEDULED_BY_CLIENT: '89',
  AUTOMATICALLY_SCHEDULED: '90',
  BID: '91',
  NO_PAYMENT: '92',
  REFUSED_BID: '93',
  INSTALLATION_TECHNICAL_VISIT: '94',
  REFUSAL_AFTER_ACCEPTANCE_OVER_TWO_DAYS: '95',
  REFUSAL_AFTER_ACCEPTANCE_LESS_TWO_DAYS: '96',
  TECHNICAL_REPORT_SEND: '97',
  BUDGET_SENDED: '99',
}

export default ocurrencesType
