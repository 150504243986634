import i18next from 'i18next'

import Worksite from './components/Worksite'
import BackToReschedule from './components/BackToSchedule'
import BackToPreSchedule from './components/BackToPreSchedule'
import SchedulingDate from './components/SchedulingDate'
import Status from './components/StatusInfo'
import ServiceOrderTags from './components/Tags'
import PdvInfo from './components/PdvInfo'
import SequencialOrderInfo from './components/SequencialOrderInfo'
import ZoneInfo from './components/ZoneInfo'
import CategoryInfo from './components/CategoryInfo'
import ClientInfo from './components/ClientInfo'
import TotalValue from './components/TotalValue'
import DeliveryDate from './components/DeliveryDate'
import InstallerInfo from './components/InstallerInfo'
import ProviderInfo from './components/ProviderInfo'
import DistributeService from './components/DistributeService'
import ConfirmOS from './components/ConfirmOS'

import ErrorFallback from '../../../../errors/fallbacks'
import ErrorBoundary from '../../../../errors/boundaries'
import CustomCard from '../../../../components/Card'

import PopupMenu from './components/PopupMenu'
import MozaicIcon from '../../../../components/MozaicIcon'
import { WithContext } from './context'
import { SummaryMainType } from './models'
import SellerInfo from './components/SellerInfo'

function SummaryMain({ serviceOrderBody, reloadServiceOrder }: SummaryMainType) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetErrorBoundary={() => reloadServiceOrder()}
    >
      <div id='summary-main-container'>
        <CustomCard
          styled
          title={i18next.t('accordeon.header.sample1')}
          iconNode={<MozaicIcon icon='ListStandard24' size={25} mr={-5} />}
        >
          <header>
            <div className='order-infos'>
              <div className='sequencials'>
                <SequencialOrderInfo />
                <PdvInfo />
              </div>

              <Worksite />
            </div>

            <div className='content-button'>
              <PopupMenu />
            </div>
          </header>

          <hr />

          <section className='summary-row'>
            <section className='summary-column column'>
              <div className='summary-row'>
                <div className='summary-column'>
                  <ClientInfo />
                </div>
                <div className='summary-column'>
                  <SellerInfo />
                </div>
              </div>

              <div className='summary-row'>
                <div className='summary-column'>
                  <TotalValue />
                </div>
                <div className='summary-column'>
                  <DeliveryDate />
                </div>
              </div>
            </section>

            <section className='summary-column column'>
              <CategoryInfo />
            </section>
          </section>

          <section className='summary-row top-padding'>
            <section className='summary-column column'>
              <SchedulingDate />
              <Status />

              <div className='status-actions'>
                <BackToReschedule />
                <BackToPreSchedule />
                <ConfirmOS />
              </div>
            </section>

            <section className='summary-column column'>
              <ZoneInfo />
              <ProviderInfo />
              <InstallerInfo />
              {serviceOrderBody && <DistributeService statusCd={serviceOrderBody.servcOrdStusCd} />}
            </section>
          </section>

          <ServiceOrderTags />
        </CustomCard>
      </div>
    </ErrorBoundary>
  )
}

export default WithContext(SummaryMain)
