import { IBadgeProps } from '@mozaic-ds/react'
import ServiceOrderStatusType from '../enums/ServiceOrder/status/ServiceOrderStatusType'
import { ServiceOrderEntity } from './ServiceOrder'

export type ServiceExecutionItem = {
  nomeProd: string
  codProd: string
  qtdProdOrdemServico: number
  valProdOrdemServico: number
  valTotal: number
  servcOrdSeq: number
  idOrdemServicoItem: number
  codProdAssociado: string
  nomeProdAssociado: string
  qtdProdAssociado: number
  datInclusaoReg: string
  datAlteracaoReg: string
  codUserAlteracaoReg: string
  numItemVendaAssistida: number
  servcOrdItemPrvdrPymtAmt: number
  slots: number
  servcOrdItemQtyAcquired: number
  servcCatgryTypCd: number
  servcFullDesc: string
  servcOrdItemQty: number
  servcOrdItemAmt: number
  servcResaleProdQty: number
  servcOrdItemQtyReturned: number
  servcOrdItemQtyRelzd: number
}

export interface ServiceExecutionEntity extends ServiceOrderEntity {
  serviceOrderTags: { serviceOrderStatusId: string; tagType: string }[]
  serviceOrderItem: ServiceExecutionItem[]
  servcXecutnEndDt: string
  servcXecutnStrtDt: string
}

export type ServicesHeaderType = {
  service: ServiceExecutionEntity
  index: number
}

export type ServiceExecutionType = {
  services: ServiceExecutionEntity[]
  loadingServices: boolean
  getProjectExecutions: Function
}

export const statusBadges = {
  [ServiceOrderStatusType.STATUS_AGENDADA.id]: {
    theme: 'neutral' as IBadgeProps['theme'],
    text: ServiceOrderStatusType.STATUS_AGENDADA.value,
  },
}
