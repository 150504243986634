import i18next from 'i18next'
import MozaicButton from '../../../../../../../../components/MozaicButton'

type FooterButtonsType = {
  handleClear: Function
  handleSubmit: Function
}

function FooterButtons(props: FooterButtonsType) {
  const { handleClear, handleSubmit } = props

  const cancelLabel = i18next.t('button.clean')
  const submitLabel = i18next.t('search')

  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='bordered' onClick={() => handleClear()}>
        {cancelLabel}
      </MozaicButton>
      <MozaicButton
        onClick={() => {
          handleSubmit()
        }}
      >
        {submitLabel}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
