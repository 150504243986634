import i18next from 'i18next'
import User from '../../../../../../core/User'

import EditButton from '../../../../../../components/EditButton'
import BusinessUnitType from '../../../../../../enums/ServiceOrder/user/BusinessUnitType'

type BuCdType = {
  readonly handleOpenBuCdModal: () => void
}

function BuCd({ handleOpenBuCdModal }: BuCdType) {
  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const haveToShow = accessFunctionList?.includes(143)

  const valueText = `${user.buCd} - ${i18next.t(
    BusinessUnitType.getBUByBuCd(user.buCd).translation,
  )}`

  return (
    <>
      {haveToShow && (
        <div id='bu-cd'>
          <section className='section-bu-cd'>
            <span> {valueText} </span>
            <EditButton onClickEditButton={handleOpenBuCdModal} />
          </section>
        </div>
      )}
    </>
  )
}

export default BuCd
