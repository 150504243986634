import { useState } from 'react'
import i18next from 'i18next'
import moment from 'moment-timezone'
import classnames from 'classnames'

import WarrantyModal from '../WarrantyModal'
import OutlineButton from '../../../../../../../../../components/OutlineButton'

import { WithContext } from '../../context'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'

function TriggerWarranty({ warranty, loadingWarranty }: ServiceOrderDetailStore) {
  const [show, setShow] = useState<boolean>(false)

  const handleCloseModal = () => setShow(false)
  const handleOpenModal = () => setShow(true)
  const onSubmit = () => setShow(false)

  const remainingDays = warranty?.remainingDays || 0
  const maxDate = moment(warranty?.maxDate).format('L')
  const warrantyDate = `${maxDate} (${remainingDays} ${i18next.t('days.remaining')})`

  if (!remainingDays)
    return (
      <div className={classnames('trigger-info warranty-expired', { skeleton: loadingWarranty })}>
        <span>{`${i18next.t('order.detail.warranty.modal.expired.text')} `}</span>
        <strong>{maxDate}</strong>
      </div>
    )

  return (
    <div id='trigger-warranty-container'>
      <div className={classnames('trigger-info', { skeleton: loadingWarranty })}>
        <span>{`${i18next.t('customer.service.order.details.infos.warranty')}: `}</span>
        <strong>{warrantyDate}</strong>
      </div>

      <OutlineButton label={i18next.t('serviceOrders.createWarranty')} onClick={handleOpenModal} />

      {show && <WarrantyModal onClose={handleCloseModal} onSubmit={onSubmit} show={show} />}
    </div>
  )
}

export default WithContext(TriggerWarranty)
