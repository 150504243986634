import { FunctionComponent, memo, useMemo } from 'react'
import { useServiceOrderDetailContext } from '../../../../../../context'

export function WithContext(Component: FunctionComponent<any>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      serviceOrderBody,
      loadingBody,
      loadingDetails,
      reloadServiceOrder,
      occurrences,
      fetchOccurrences,
      setOccurrences,
    } = useServiceOrderDetailContext()

    const newProps = useMemo(
      () => ({
        serviceOrderBody,
        loadingBody,
        loadingDetails,
        reloadServiceOrder,
        occurrences,
        fetchOccurrences,
        setOccurrences,
      }),
      [serviceOrderBody, loadingBody, loadingDetails],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
