import { Select } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import store from '../../redux/store'

/**
 * @description Reusable component to select period by month or year
 */

function SelectPeriod({ label, value, placeholder, optionType, disabled = false, onChange }) {
  const currentYear = new Date().getFullYear()
  const { auth } = store.getState()

  function handleGenerateArrayOfYears() {
    const max = new Date().getFullYear()
    const startYear = auth.setupParameters.servcPrvdrAnalysisStartYear
    const analysisStartYear = max - startYear

    const min = max - analysisStartYear
    const years = []

    for (let index = max; index >= min; index--) {
      years.push(index)
    }

    return years
  }

  function handleGenerateArrayOfMonths() {
    const months = []
    const currentMonth = new Date().getMonth()

    for (let index = 0; index <= currentMonth; index++) {
      months.push(new Date(2010, index).toLocaleString('pt-BR', { month: 'long' }))
    }

    return months
  }

  function handleGetMonthByName(month) {
    const months = [
      '',
      'janeiro',
      'fevereiro',
      'marco',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    const monthIndex = months.indexOf(month)

    return monthIndex?.toString().padStart(2, '0')
  }

  function handleResolveYearlyOptions() {
    const yearsOptions = []
    const yearsArray = handleGenerateArrayOfYears()

    yearsArray.forEach((year) => {
      yearsOptions.push({
        label: year,
        value: year,
      })
    })

    yearsOptions.unshift({
      label: i18next.t('last.twelve.months.text'),
      value: 0,
    })

    return yearsOptions
  }

  function handleAggregateMonths(year, monthsOptions, array = moment.months()) {
    ;(array || []).reverse().forEach((month) => {
      if (month === 'março') {
        // eslint-disable-next-line no-param-reassign
        month = month.replace('ç', 'c')
      }

      monthsOptions.push({
        label: `${i18next.t(month)}/${year}`,
        value: `${handleGetMonthByName(month)}/${year}`,
      })
    })
  }

  function handleResolveMonthlyOptions() {
    const monthsOptions = []
    const monthsOfCurrentYear = handleGenerateArrayOfMonths()

    const yearsArray = handleGenerateArrayOfYears()

    for (const year of yearsArray) {
      if (year === currentYear) {
        handleAggregateMonths(year, monthsOptions, monthsOfCurrentYear)
      } else {
        handleAggregateMonths(year, monthsOptions)
      }
    }

    monthsOptions.unshift({
      label: i18next.t('last.thirty.days'),
      value: 0,
    })

    return monthsOptions
  }

  function handleResolveOptions() {
    if (optionType === 'monthly') {
      return handleResolveMonthlyOptions()
    }

    return handleResolveYearlyOptions()
  }

  const options = handleResolveOptions()

  return (
    <Select
      label={label}
      placeholder={placeholder}
      defaultValue={0}
      value={value || 0}
      options={options}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

SelectPeriod.propTypes = {
  optionType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export default SelectPeriod
