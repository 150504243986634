import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import store from '../../redux/store'

import { AttachmentV2 } from '../firestore/gCloudStorage/models'
import { ADEOResponse } from '../../models/AdeoResponse'
import { ServiceOrderAttachmentsEnum } from '../../enums/ServiceOrder/ServiceOrderAttachmentsEnum'

interface fetchServiceOrderAttachmentsProps {
  servcOrdSeq: number
  servcOrdItemAtchmtClsfctnCd: [ServiceOrderAttachmentsEnum]
  actvServcOrdItemAtchmtInd: 1 | 0
  downloadFiles: boolean
}

/**
 * @description Fetch attachments of a service order
 */

export const fetchServiceOrderAttachments = async (
  payload: fetchServiceOrderAttachmentsProps,
): Promise<ADEOResponse<Omit<AttachmentV2[], 'atchmtDocmntTyp' | 'atchmtDocmntTypDesc'>>> =>
  new API_REQUEST(null, store.dispatch).get(API_URL.FIND_ORDER_SERVC_ATTACHMENT, payload)
