import User from '../core/User'
import history from './history'

const user = new User()

export function userAllowedToEnter(osCategory: number) {
  const { allowedCategories } = user.currentUser

  if (!allowedCategories.includes(osCategory)) history.push('/no-permissions')
}

// Missing categoryCd in firebase conversations (migration on build)
export function userAllowedToSee() {
  // const { allowedCategories } = user.currentUser

  // return allowedCategories.includes(osCategory)

  return true
}
