/* eslint-disable react/prop-types */

import i18next from 'i18next'
import MozaicButton from '../../../../components/MozaicButton'

function ErrorFallbackI18n({ error, resetErrorBoundary }) {
  return (
    <div role='alert' id='fallback-i18n'>
      <p className='subtitle'>{i18next.t('fallback.page.error.subtitle')}</p>
      <p className='message'>{error.message}</p>
      <MozaicButton variant='bordered' onClick={resetErrorBoundary}>
        {i18next.t('fallback.page.text.button')}
      </MozaicButton>
    </div>
  )
}
export default ErrorFallbackI18n
