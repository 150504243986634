import { FunctionComponent, memo, useMemo } from 'react'
import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { ServiceInformationBoxType } from '../../../models/serviceInfo'

export function WithContext(Component: FunctionComponent<ServiceInformationBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      toggleState: { showAllDetails, handleToggle },
      serviceInformationState: {
        status,
        setStatus,
        category,
        setCategory,
        LmNumber,
        setLmNumber,
        litigy,
        setLitigy,
      },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        status,
        setStatus,
        category,
        setCategory,
        LmNumber,
        setLmNumber,
        litigy,
        setLitigy,
        showAllDetails,
        handleToggle,
      }),
      [status, category, LmNumber, litigy, showAllDetails],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
