import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { renderToastWarn } from '../../../helpers/ToastUtils'
import { WithForm } from '../../../helpers/useForm/withForm'
import MozaicTextArea from '../../MozaicTextArea'
import Col from '../Col'
import Field from '../Field'
import Modal from '../Modal'

import './index.scss'

class JustificationModal extends PureComponent {
  componentDidUpdate(prevProps) {
    const { initialValues, setFormValue } = this.props

    if (prevProps.initialValues.field !== initialValues.field) {
      setFormValue({ field: initialValues.field, description: '' })
    }
  }

  handleOnSubmit = () => {
    const { fieldKey, onSubmit, values, validation, t } = this.props

    if (validation) {
      const result = this.handleValidation(values.description)
      if (!result) {
        return renderToastWarn({ message: t('textValidation') })
      }
    }

    const newValues = {
      [fieldKey]: values.field,
      description: values.description,
    }

    onSubmit(newValues)
  }

  handleValidation = (text) => {
    const atLeastOneAlfhabeticLetter = /[a-zA-Z]/
    // Tests if text have at least one alphabetic letter and text length is greater than 10
    if (RegExp(atLeastOneAlfhabeticLetter).test(text) && text.length >= 10) {
      return true
    }
  }

  render() {
    const { closeModal, fields, fieldKey, t, values, register } = this.props

    return (
      <Modal className='justification-modal' closeModal={closeModal} size='sm'>
        {/* <form onSubmit={this.handleOnSubmit}> */}
        <Col xs={12}>
          <h3>{t(`justificationModal.${fieldKey}.title.${values.field}`)}</h3>
        </Col>

        <Col xs={12}>
          <p>{t(`justificationModal.${fieldKey}.text.${values.field}`)}</p>
        </Col>

        <Col xs={12}>
          <Field {...fields.description}>
            <MozaicTextArea
              {...register('description', { required: true, validate: (v) => v.length >= 10 })}
              className='margin-top-negative margin-bottom resize-none textarea-custom'
              placeholder={t('justificationModal.desc.placeholder')}
            />
          </Field>
        </Col>

        <Col xs={12} md={6} className='margin-bottom'>
          <button
            className='button-hollow button-hollow-primary button-full'
            onClick={closeModal}
            type='button'
          >
            {t(`justificationModal.${fieldKey}.cancel.${values.field}`)}
          </button>
        </Col>

        <Col xs={12} md={6}>
          <button
            type='button'
            className='button button-primary button-full'
            disabled={!values.description}
            onClick={() => this.handleOnSubmit()}
          >
            {t(`justificationModal.${fieldKey}.confirm.${values.field}`)}
          </button>
        </Col>
        {/* </form> */}
      </Modal>
    )
  }
}
JustificationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fieldKey: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorFn: PropTypes.func,
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  validation: PropTypes.bool,
  register: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
}

const FIELDS = { field: '', description: '' }

export default WithForm(FIELDS)(JustificationModal)

export { JustificationModal }
