import i18next from 'i18next'
import moment from 'moment-timezone'
import DatePicker from '../../../../../../components/DateSelect'
import store from '../../../../../../redux/store'

import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import { useRescheduleContext } from '../../context'

type DateSelectType = {
  value: any
  onChange: Function
}

function DateSelect(props: DateSelectType) {
  const { onChange, value } = props
  const { serviceOrder }: any = useServiceOrderContext()
  const { allowedDates } = useRescheduleContext()

  const setupParameters = store.getState().auth.setupParameters
  const hasDeliveryDate =
    setupParameters?.distributeServiceBlockBeforeDelivery && serviceOrder?.servcOrdPrmsdDlvryDt

  const deliveryDate = serviceOrder?.servcOrdPrmsdDlvryDt
    ? moment(serviceOrder.servcOrdPrmsdDlvryDt).startOf('day')
    : null
  const deliveryTime = deliveryDate ? +deliveryDate : 0

  const today = moment().startOf('day')
  const todayTime = +today

  const isDeliveryDate = (day: any) => {
    if (serviceOrder?.servcOrdPrmsdDlvryDt) {
      const currentDate = day.startOf('day')
      const isDayHighlighted = currentDate.diff(deliveryDate, 'days') === 0

      return isDayHighlighted
    }
    return false
  }

  const isOutsideRange = (day: any) => {
    const currentTime = +day.startOf('day')
    const compareTodayDate = currentTime >= +todayTime
    const compareDeliveryDate = hasDeliveryDate ? currentTime >= +deliveryTime : true

    return !(
      compareDeliveryDate &&
      compareTodayDate &&
      allowedDates.includes(day.format('YYYY-MM-DD'))
    )
  }

  const labelText = i18next.t('serviceOrders.date')

  return (
    <div className='date-select-body'>
      <DatePicker
        id='date-select-reschedule'
        value={value}
        label={labelText}
        onChange={onChange}
        placeholder={labelText}
        isOutsideRange={isOutsideRange}
        isDayHighlighted={isDeliveryDate}
      />
    </div>
  )
}

export default DateSelect
