import i18next from 'i18next'
import { RefObject, useRef } from 'react'
import MozaicIcon from '../../../../../../../../../components/MozaicIcon'
import OutlineButton from '../../../../../../../../../components/OutlineButton'
import { ATTACHMENT_CLASSIFICATION } from '../../../../../../../../../constants/config'
import { fileToBase64WithBuffer } from '../../../../../../../../../helpers/Toolbox'

const idTypeFileStore = ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_STORE
const idTypeFileClient = ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER

export default function BudgetFiles({
  hideButtonRemove,
  downloadFile,
  handleDeleteFile,
  documentsClient,
  documentsStore,
  onSubmitUploadBudgetFile,
  blockUpload,
}: any) {
  const inputFileStoreRef = useRef<HTMLInputElement>(null)
  const inputFileClientRef = useRef<HTMLInputElement>(null)

  const handleDeleteFileConfirm = (file: any) => {
    if (window.confirm(i18next.t('servc.ord.budget.component.title.exclude.file'))) {
      // eslint-disable-next-line no-param-reassign
      file.actvServcOrdItemAtchmtInd = 0
      handleDeleteFile(file)
    }
  }

  const onHandleSelectFile = async (event: any, idTypeFile: number) => {
    event.preventDefault()
    const inputFiles = event.target.files
    let files = []

    if (inputFiles && inputFiles.length) {
      const filesPromises = Array.from(inputFiles).map(async (inputFile: any) => {
        const result = await fileToBase64WithBuffer(inputFile)
        let preName = null
        if (idTypeFile === ATTACHMENT_CLASSIFICATION.BUDGET_ATTACHMENT_CUSTOMER) {
          preName = i18next.t('budgets.app.details.file.name.client')
        } else {
          preName = i18next.t('budgets.app.details.file.name.installer')
        }
        return {
          name: preName + inputFile.name,
          file: result,
          fileIndicador: idTypeFile,
        }
      })
      files = await Promise.all(filesPromises)
      onSubmitUploadBudgetFile(files)
    }
  }

  const renderFile = (key: string, file: any) => {
    const renderSuccessBtn = hideButtonRemove && documentsClient.length === documentsStore.length
    return (
      <div key={key} className='so-budget-file'>
        <span
          className='so-budget-file-download'
          onClick={() => downloadFile(file.atchmtCloudShortPathTxt, file.atchmtCloudShortPathTxt)}
        >
          {file.servcOrdItemAtchmtDesc}
        </span>
        {hideButtonRemove === false && (
          <div className='so-budget-file-delete'>
            <MozaicIcon
              icon='PublishTrashbin24'
              className='icon-actions-custom'
              onClick={() => handleDeleteFileConfirm(file)}
            />
          </div>
        )}
        {renderSuccessBtn && (
          <div className='so-budget-file-success'>
            <MozaicIcon icon='NotificationCircleAvailable24' className='icon-actions-custom' />
          </div>
        )}
      </div>
    )
  }

  const renderFiles = (
    label: string,
    inputFileRef: RefObject<HTMLInputElement>,
    documents: any,
    idTypeFile: number,
  ) => (
    <>
      <OutlineButton
        classname='margin-bottom'
        label={i18next.t(label)}
        onClick={() => inputFileRef.current?.click()}
        isDisabled={blockUpload}
      >
        <input
          id={`file-budget-input-${idTypeFile}`}
          ref={inputFileRef}
          accept='.pdf'
          type='file'
          style={{ display: 'none' }}
          onChange={(e) => onHandleSelectFile(e, idTypeFile)}
          onClick={(event) => {
            // eslint-disable-next-line no-param-reassign
            ;(event.target as HTMLInputElement).value = ''
          }}
        />
      </OutlineButton>
      {documents.map((f: any, i: number) => renderFile(`file-${idTypeFile}${i}`, f))}
    </>
  )

  return (
    <div className='row'>
      <div className='col-xs-4 budget-file-col-first'>
        {renderFiles(
          'servc.ord.budget.component.title.client.button',
          inputFileClientRef,
          documentsClient,
          idTypeFileClient,
        )}
      </div>

      <div className='col-xs-4 budget-file-col-last'>
        {renderFiles(
          'servc.ord.budget.component.title.store.button',
          inputFileStoreRef,
          documentsStore,
          idTypeFileStore,
        )}
      </div>
    </div>
  )
}
