import { Filters, ServiceOnTimeAlert } from '../models'
import Indicator from './Indicator'

export default class ServiceOnTimeIndicator extends Indicator {
  alert: ServiceOnTimeAlert

  constructor(alert: ServiceOnTimeAlert, filters: Filters) {
    super(alert, filters)

    this.alert = alert
  }

  get subIndicators() {
    return this.alert.indicators
  }

  get parsedIndicator() {
    const data = parseFloat(this.indicator)

    if (data && !isNaN(data)) return `${data.toFixed(1)}%`

    return ' - '
  }

  getBgColor(breakpoints: string) {
    const parsedBreakpoints = breakpoints.split(';')

    const greenContentMin = parseInt(parsedBreakpoints[0], 10)
    const defaultMin = parseInt(parsedBreakpoints[1], 10)
    const indicator = parseInt(this.indicator)

    if (indicator >= greenContentMin) return 'green-content'
    if (indicator >= defaultMin) return 'default'
    if (indicator < defaultMin) return 'red-content'
  }
}
