/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react'

const ServiceOrderContext = createContext({})

interface ServiceOrderProviderProps {
  children: React.ReactNode
  formSectionProp: any
  oldStatus: string
}

function ServiceOrderProvider({ children, formSectionProp, oldStatus }: ServiceOrderProviderProps) {
  const [orderServices, setOrderServices] = useState([])
  const [currentServicesDuration, setCurrentServicesDuration] = useState(0)

  const resetServiceOrder = () => {
    history.go(0)
  }

  return (
    <ServiceOrderContext.Provider
      value={{
        ...formSectionProp,
        oldStatus,
        orderServices,
        setOrderServices,
        currentServicesDuration,
        setCurrentServicesDuration,
        resetServiceOrder,
      }}
    >
      {children}
    </ServiceOrderContext.Provider>
  )
}
const useServiceOrderContext = () => useContext(ServiceOrderContext)

export { ServiceOrderContext, ServiceOrderProvider, useServiceOrderContext }
