import moment from 'moment-timezone'
import i18next from 'i18next'
import DatePicker from '../../../../../../../../../../../components/DateSelect'

type DateSelectType = {
  value: any
  onChange: Function
}

function DateSelect(props: DateSelectType) {
  const { onChange, value } = props

  const today = moment().startOf('day')
  const todayTime = +today

  const isOutsideRange = (day: any) => {
    const currentTime = +day.startOf('day')
    const compareTodayDate = currentTime >= +todayTime

    return !compareTodayDate
  }

  const labelText = i18next.t('customer.service.order.details.scheduling.date')
  const placeholder = i18next.t('empleTempPrfl.date')

  return (
    <div className='date-select-body'>
      <DatePicker
        id='date-select-warranty'
        value={value}
        label={labelText}
        onChange={onChange}
        placeholder={placeholder}
        isOutsideRange={isOutsideRange}
      />
    </div>
  )
}

export default DateSelect
