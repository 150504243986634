import i18next from 'i18next'

import BodySection from '../BodySection'
import MozaicText from '../../../../../../../../../../components/MozaicText'
import MozaicButton from '../../../../../../../../../../components/MozaicButton'

import { Flex } from '@mozaic-ds/react'

function Observations() {
  return (
    <div className='service-observation'>
      <BodySection title={i18next.t('field.name.note')}>
        <Flex direction='column' gap='mu075'>
          <MozaicText className='observations'>
            Lorm ipsum dolor sit amet, Lorm ipsum dolor sit amet Lorm ipsum dolor sit amet
          </MozaicText>

          <MozaicButton variant='bordered' size='s'>
            Adicionar observações
          </MozaicButton>
        </Flex>
      </BodySection>
    </div>
  )
}

export default Observations
