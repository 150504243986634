import classnames from 'classnames'
import i18next from 'i18next'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'

import ItemReport, { ItemReportProps } from './components/ItemReport'
import ItemReportSkeleton from './components/ItemReportSkeleton'

import FormField from '../../../../../../../../../components/FormField'
import OutlineButton from '../../../../../../../../../components/OutlineButton'
import ReworkValueModal from '../ReworkValueModal'

import ServiceOrderStatusType from '../../../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import { formatDecimal } from '../../../../../../../../../helpers/masks'
import { getOsRoutePrefix } from '../../../../../../../../../helpers/serviceOrderUtils'
import { getTechinicalReportImages } from '../../../../../../../../../services/pages/ServiceOrderDetail/GetTechinicalReportImages'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'
import { WithContext } from './context'

function ActivatedWarranty({ serviceOrder, isFetching }: ServiceOrderDetailStore) {
  const [reportImages, setReportImages] = useState<ItemReportProps[]>([])
  const [showBudgetModal, setShowBudgetModal] = useState<boolean>(false)
  const [isFetchingTechinicalReportImages, setIsFetchingTechinicalReportImages] =
    useState<boolean>(false)

  async function handleFetchTechinicalReportImages() {
    setIsFetchingTechinicalReportImages(true)
    const data = await getTechinicalReportImages(serviceOrder?.servcOrdSeq as number)
    if (data) setReportImages(data)
    setIsFetchingTechinicalReportImages(false)
  }

  useEffect(() => {
    handleFetchTechinicalReportImages()
  }, [serviceOrder?.servcOrdSeq])

  const urlPrefix = useMemo(() => getOsRoutePrefix(), [])

  const osNumber = !!serviceOrder?.warranty && serviceOrder?.warranty?.servcOrdOrignSeq

  const handleGoToServiceOrder = () => {
    window.location.replace(window.location.origin.concat(`${urlPrefix}/${osNumber || '0'}`))
  }

  const loadingFiles = isFetching || isFetchingTechinicalReportImages
  const statusCd = serviceOrder?.servcOrdStusCd || ''
  const canOpenNewBudget = [
    ServiceOrderStatusType.STATUS_CONFIRMADA.id,
    ServiceOrderStatusType.STATUS_EM_REALIZACAO.id,
    ServiceOrderStatusType.STATUS_ASSOCIADA.id,
    ServiceOrderStatusType.STATUS_DISTRIBUIDA.id,
    ServiceOrderStatusType.STATUS_AGENDADA.id,
    ServiceOrderStatusType.STATUS_PRE_AGENDADA.id,
  ].includes(statusCd)

  return (
    <div id='activated-warranty-rework-container'>
      {showBudgetModal && <ReworkValueModal setModalState={setShowBudgetModal} />}

      <FormField
        isFetching={isFetching}
        label={i18next.t('serviceOrder.outOfFlow.reason')}
        value={serviceOrder?.warranty?.reasonDesc || '-'}
        isBold={false}
      />

      <FormField
        isFetching={isFetching}
        label={i18next.t('description.label')}
        value={serviceOrder?.warranty?.servcOrdWrntDesc || '-'}
        isBold={false}
      />

      <hr />
      <section className='warranty-rework-info'>
        <span className='attention-message'>{i18next.t('warrantyServiceOrder.message')}</span>

        <section className='report-content'>
          <span className='title'>{i18next.t('technical.reports.text')}</span>

          {reportImages.length === 0 &&
            loadingFiles &&
            [1, 2].map((item: number) => <ItemReportSkeleton key={item} />)}

          {reportImages.map((item) => (
            <>
              <ItemReport {...item} />
              <hr />
            </>
          ))}

          {reportImages.length === 0 && !loadingFiles && (
            <span className='no-has-reports'>{i18next.t('no.file.sended.text')}.</span>
          )}
        </section>

        <FormField
          isFetching={isFetching}
          label={i18next.t('warranty.rework.total.value')}
          value={formatDecimal(serviceOrder?.warranty?.reworkValue) || '-'}
          isBold={false}
        />

        <OutlineButton
          isDisabled={!canOpenNewBudget}
          label={i18next.t('add.new.value.budget')}
          classname={classnames({ skeleton: isFetching })}
          onClick={() => setShowBudgetModal(true)}
        />
      </section>
      <hr />

      <section className='activated-footer'>
        <button
          className={classnames({ 'backyard-button': true, skeleton: isFetching })}
          onClick={() => handleGoToServiceOrder()}
        >
          <span>
            {i18next
              .t('warrantyActivated.serviceOrder.goToOrign')
              .replace('{serviceOrder}', String(osNumber || 0))}
          </span>
        </button>

        <div className={classnames({ 'triggered-in': true, skeleton: isFetching })}>
          <span>{i18next.t('warranty.triggered.in')}:</span>{' '}
          <strong>{moment(serviceOrder?.warranty?.creatTs).format('L LTS')}</strong>
        </div>
      </section>
    </div>
  )
}

export default WithContext(ActivatedWarranty)
