import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from '../../../components/utils/Select'

function SlotSelect(props) {
  const { slots, onChange, value, t, disabled } = props

  const renderOptions = (item) => (
    <option key={item.idSlotApplication} value={item.idSlotApplication}>
      {item.dsSlot}
    </option>
  )

  return (
    <Select
      id='idSlotApplication'
      name='idSlotApplication'
      label={t('serviceOrders.scheduleTime')}
      value={value || ''}
      onChange={onChange}
      disabled={disabled}
    >
      <option value='' />

      {slots.map(renderOptions)}
    </Select>
  )
}

SlotSelect.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  slots: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
}

/*
 * Conecta componente ao I18next
 */
const SlotSelectTranslated = withTranslation()(SlotSelect)

export default SlotSelectTranslated

export { SlotSelect }
