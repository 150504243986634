import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import i18next from 'i18next'

import User from '../../../core/User'

import store from '../../../redux/store'
import { toast } from '@leroy-merlin-br/backyard-react'

const user = new User().currentUser

export type ParamsType = {
  servcOrdSeq: number
  idOrdemServicoItem: number
  descOcorOrdemServico: string
  servcOrdEvntStusInd: string
  idPrestadorServico?: number
  idEspecialistaServico?: number
  indStatusOrdemServicoItem: string
  indStatusOrdemServico: string
}

export const generateOcurrence = async (payload: ParamsType) => {
  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.CREATE_OCCURRENCE_SIMPLE,
      payload,
    )

    toast.success(i18next.t('success.text'), i18next.t('bank.save.success'), '')

    return response.data
  } catch (error) {
    return error
  }
}
