import firestoreAdapter from '../firebase'
import { GCloudFileRef } from './GCloudFileRef'
import { AttachmentV2, FileRefType } from './models'

export const minifiedPrefix = '250x250'

export class GCloudImageRef extends GCloudFileRef {
  private thumb: FileRefType

  constructor(attachment: AttachmentV2) {
    super(attachment)

    const { thumbShortPath } = GCloudImageRef.splitImg(attachment)

    this.thumb = firestoreAdapter.storage.ref(thumbShortPath)
  }

  static splitImg(attachment: AttachmentV2) {
    const splitPath = attachment.atchmtCloudShortPathTxt.split('/')
    const fullName = splitPath?.pop()

    const splitName = fullName?.split('.')
    const ext = splitName?.pop()

    const path = splitPath.join('/')
    const nameNoExt = splitName?.join()

    const thumbName = `${nameNoExt}_${minifiedPrefix}.${ext}`
    const thumbShortPath = `${path}/${thumbName}`

    return { fullName, nameNoExt, thumbName, thumbShortPath, ext }
  }

  getFile = async (original = false) => {
    const url = await this.getDownloadURL(original)

    const res = await fetch(url)
    const blob = await res.blob()

    return blob
  }

  getDownloadURL = async (original = false) => {
    if (this.downloadUrl) return this.downloadUrl

    try {
      const url = !original ? await this.thumb.getDownloadURL() : await this.ref.getDownloadURL()

      if (original) this.downloadUrl = url

      return url
    } catch (err) {
      if (!original) {
        const url = await this.ref.getDownloadURL()

        if (original) this.downloadUrl = url

        return Promise.resolve(url)
      }

      return Promise.reject(err)
    }
  }
}
