import API_URL from '../core/Envs/endpoints'
import Api from '../core/Http/FetchAdapter'
import store from '../redux/store'

import i18next from 'i18next'
import { renderToastError, renderToastSuccessMsg, renderToastWarn } from '../helpers/ToastUtils'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'

export const toastMessage = (editResult) => {
  const listItems = editResult.map((so) => (
    <li key={so.sourceOrderCode}>{`${i18next.t('serviceOrders.serviceOrder')}: ${
      so.sourceOrderCode
    }`}</li>
  ))

  renderToastSuccessMsg(
    <div>
      <span>{editResult[0].message}</span>
      <ul>{listItems}</ul>
    </div>,
  )

  return true
}

export const createServcOrd = async (token, values, callbackSuccess = false) =>
  new Api(token, store.dispatch)
    .post(API_URL.CREATE_SERVC_ORD, values)
    .then(store.dispatch(showSpinner()))
    .then(({ data }) => {
      store.dispatch(hideSpinner())

      if (callbackSuccess) {
        callbackSuccess(data[0])
        toastMessage(data)
      }

      return data[0]
    })
    .catch((error) => {
      renderToastError(error)
      store.dispatch(hideSpinner())

      return Promise.reject(error)
    })

export const getPyxisInformations = (token, values) =>
  new Api(token, store.dispatch)
    .get(API_URL.GET_PYXIS_INFORMATIONS, values)
    .then(store.dispatch(showSpinner()))
    .then(
      (response) => response,
      (error) => renderToastWarn(error),
    )
    .finally(() => store.dispatch(hideSpinner()))
