import i18next from 'i18next'
// @ts-ignore
import Select from 'react-select'
import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../../../../helpers/react-select-backyard-styles'
import { useWarrantyContext } from '../../context'

type PeriodSelectType = {
  selectedDate: any
  onChange: (value: any) => void
  value: any
  compareTodayDate: boolean
}

function PeriodSelect(props: PeriodSelectType) {
  const { value, onChange, selectedDate, compareTodayDate } = props
  const { periods } = useWarrantyContext()

  const label = i18next.t('serviceOrders.schedule.shift')
  const placeholder = i18next.t('needHelp.scheduling.shift')
  const disabled = !selectedDate || !compareTodayDate
  const hasSelectedPeriod = value ? 'hasSelectedPeriod' : ''

  return (
    <div className={`period-select-distribute ${hasSelectedPeriod}`}>
      <label className='period-select-label' htmlFor='period-select'>
        {label}
      </label>

      <Select
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={periods}
        resetValue={[]}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        isDisabled={disabled}
        name='period-select'
        inputId='period-select'
      />
    </div>
  )
}

export default PeriodSelect
