import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'

export interface DocumentType {
  atchmtDocmntTyp: number
  atchmtDocmntTypDesc: string
}

const user = new User().currentUser

export async function getServiceOrderDocumentTypes(): Promise<DocumentType[]> {
  return new API_REQUEST(user.dsToken, store.dispatch)
    .get(API_URL.FIND_DOCUMENT_TYPES_LIST, null)
    .then((response) => response.data)
    .catch((error) => console.error(error))
}
