import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'

function Quantities({ service }: { service: ServiceExecutionEntity }) {
  const item = service?.serviceOrderItem?.at(0)
  const quantity = item?.qtdProdOrdemServico || '0'
  const refunded = item?.servcOrdItemQtyReturned || '0'
  const executed = item?.servcOrdItemQtyRelzd || '0'
  const slot = `${item?.slots} min`

  return (
    <BodySection title={i18next.t('quantities')}>
      <FormField label={i18next.t('sold')} value={quantity} />
      <FormField label={i18next.t('refunded')} value={refunded} />
      <FormField label={i18next.t('executed')} value={executed} />
      <FormField label={i18next.t('slot.total')} value={slot} />
    </BodySection>
  )
}

export default Quantities
