import i18next from 'i18next'
// @ts-ignore
import Select from 'react-select'
import {
  DropdownIndicator,
  ClearIndicator,
} from '../../../../../../../../../../../helpers/react-select-backyard-styles'

import { ProviderType, optionType } from '../../types'
import { useWarrantyContext } from '../../context'

type ReasonSelectType = {
  onChange: (value: any) => void
  value: optionType | null
}

function ReasonSelect(props: ReasonSelectType) {
  const { onChange, value } = props
  const { reasons } = useWarrantyContext()

  const disabled = false
  const showProvidersHint = false

  const label = i18next.t('pwa.order.refure.reason')
  const placeholder = i18next.t('serviceOrder.warranty.reason.validation')
  const errorHint = i18next.t('select.noOptionsMessage')

  const disabledClass = disabled ? '--disabled' : ''
  const hasContentClass = value ? 'hasContent' : ''

  return (
    <div className={`reason-select-warranty ${hasContentClass} ${disabledClass}`}>
      <label className='provider-select-label' htmlFor='reason-select'>
        {label} {showProvidersHint && <label className='error-hint'>- {errorHint}</label>}
      </label>

      <Select
        value={value as any}
        options={reasons}
        isOptionDisabled={(option: ProviderType) => option.disabled}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        components={{ ClearIndicator, DropdownIndicator }}
        clearValueText={i18next.t('option.removeItem')}
        noOptionsMessage={() => i18next.t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        resetValue={[]}
        isClearable
        menuPosition='fixed'
        inputId='reason-select'
        name='reason-select'
      />
    </div>
  )
}

export default ReasonSelect
