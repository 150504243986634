import { ServicesHeaderType } from '../../../../../../../../../models/ServiceExecution'

import MozaicAccordeon from '../../../../../../../../../components/MozaicAccordeon'
import ServiceDetails from './ServiceDetails'
import ServiceHeader from './ServiceHeader'
import Observations from './Observations'
import Attachments from './Attachments'
import Ocurrences from './Ocurrences'

function ServiceExecution({ service, index }: ServicesHeaderType) {
  return (
    <MozaicAccordeon
      className='service-execution'
      group='service-execution'
      header={<ServiceHeader index={index} service={service} />}
    >
      <ServiceDetails service={service} />
      <Observations />
      <Ocurrences />
      <Attachments />
    </MozaicAccordeon>
  )
}

export default ServiceExecution
