import i18next from 'i18next'
import moment from 'moment-timezone'
import { useMemo } from 'react'

import { format, formatCPF, formatProjectId } from '../../../../helpers/masks'
import { useQueryContext } from '../../context'

import { Flag, Flex } from '@mozaic-ds/react'
import { ProjectEntity } from '../../../../models/Project'

import FormField from '../../../../components/FormField'
import { firstBreakpoint } from '../ProjectRow'

type ProjectHeaderProps = {
  project: ProjectEntity
  queryParams: any
  servicesLabel: string
  sendToDetails: () => void
}

function ProjectHeaderMobile({
  project,
  queryParams,
  servicesLabel,
  sendToDetails,
}: ProjectHeaderProps) {
  return (
    <Flex className='project-row-header' alignItems='center' justifyContent='center'>
      <Flex direction='column' className='full-width' gap='mu100'>
        <Flex className='full-width'>
          <FormField
            label={i18next.t('entity.project')}
            value={formatProjectId(project.prjctExctnSeq)}
            matchingText={queryParams.q}
            color='link'
            onClick={sendToDetails}
          />

          <div style={{ width: '100%' }}>
            <Flag theme='dark' className='right-icon'>
              {`${i18next.t('bank_statement.status')}`}
            </Flag>
          </div>
        </Flex>

        <Flex>
          <FormField
            label={i18next.t('evaluate.provider.initial.nav.services')}
            matchingText={queryParams.q}
            value={servicesLabel}
          />
        </Flex>

        <Flex>
          <FormField
            label={i18next.t('parameters.dscreatTs')}
            value={moment(project.creatTs).format('L')}
            matchingText={queryParams.q}
          />

          <FormField
            label={i18next.t('payments.executionDates.end')}
            value={project?.prjctExctnEndDt ? moment(project.prjctExctnEndDt).format('L') : '-'}
            matchingText={queryParams.q}
          />
        </Flex>

        <Flex>
          <FormField
            label={i18next.t('conformity.osData.subtitle')}
            matchingText={queryParams.q}
            value={project.custFullNm}
          />
        </Flex>

        <Flex>
          <FormField
            label={i18next.t('agntBackOfficeCsv.servcPrvdrFedrlTaxIdNr')}
            value={formatCPF(project.custFiscalId)}
            matchingText={queryParams.q}
          />

          <FormField
            label={i18next.t('advanced.search.customer.data.radio.phoneNumber')}
            value={format(project?.custMobilePhonNr?.substring(3), '(99) 99999-9999')}
            matchingText={queryParams.q}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

function ProjectHeaderDesktop({
  project,
  queryParams,
  servicesLabel,
  sendToDetails,
}: ProjectHeaderProps) {
  return (
    <Flex className='project-row-header' alignItems='center' justifyContent='center'>
      <Flex direction='column' className='full-width' gap='mu100'>
        <Flex>
          <FormField
            label={i18next.t('entity.project')}
            value={formatProjectId(project.prjctExctnSeq)}
            matchingText={queryParams.q}
            color='link'
            onClick={sendToDetails}
            sx={{ width: '30%', minWidth: '150px' }}
          />

          <FormField
            label={
              <Flex className='full-width' gap='mu500'>
                {i18next.t('evaluate.provider.initial.nav.services')}
                <Flag theme='dark' className='right-icon'>
                  {`${i18next.t('bank_statement.status')}`}
                </Flag>
              </Flex>
            }
            matchingText={queryParams.q}
            value={servicesLabel}
            sx={{ width: '70%', minWidth: '250px' }}
          />

          <FormField
            label={i18next.t('conformity.osData.subtitle')}
            matchingText={queryParams.q}
            value={project.custFullNm}
            sx={{ width: '200%' }}
          />
        </Flex>

        <Flex>
          <FormField
            label={i18next.t('parameters.dscreatTs')}
            value={moment(project.creatTs).format('L')}
            matchingText={queryParams.q}
            sx={{ width: '30%', minWidth: '150px' }}
          />

          <FormField
            label={i18next.t('payments.executionDates.end')}
            value={project?.prjctExctnEndDt ? moment(project.prjctExctnEndDt).format('L') : '-'}
            matchingText={queryParams.q}
            sx={{ width: '70%', minWidth: '250px' }}
          />

          <FormField
            label={i18next.t('agntBackOfficeCsv.servcPrvdrFedrlTaxIdNr')}
            value={formatCPF(project.custFiscalId)}
            matchingText={queryParams.q}
          />

          <FormField
            label={i18next.t('advanced.search.customer.data.radio.phoneNumber')}
            value={format(project?.custMobilePhonNr?.substring(3), '(99) 99999-9999')}
            matchingText={queryParams.q}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default function ProjectRowHeader({
  sendToDetails,
  project,
  width,
}: {
  sendToDetails: () => void
  project: ProjectEntity
  width: number
}) {
  const queryParams = useQueryContext()

  const servicesCount = useMemo(() => (Math.random() * 10).toFixed(), [project])

  const servicesLabel =
    servicesCount === '1'
      ? `${servicesCount} ${i18next.t('entity.service')}`
      : `${servicesCount} ${i18next.t('entities.service')}`

  if (width < firstBreakpoint)
    return (
      <ProjectHeaderMobile
        project={project}
        queryParams={queryParams}
        servicesLabel={servicesLabel}
        sendToDetails={sendToDetails}
      />
    )

  return (
    <ProjectHeaderDesktop
      project={project}
      queryParams={queryParams}
      servicesLabel={servicesLabel}
      sendToDetails={sendToDetails}
    />
  )
}
