import { FunctionComponent, memo, useMemo } from 'react'

import { useAdvancedSearchContext } from '../../../../../../../../../contexts/advancedSearch'
import { SellOriginBoxType } from '../../../models/sellOrigin'

export function WithContext(Component: FunctionComponent<SellOriginBoxType>) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      sellOriginState: { stores, setStores, channel, setChannel },
    } = useAdvancedSearchContext()

    const newProps = useMemo(
      () => ({
        stores,
        setStores,
        channel,
        setChannel,
      }),
      [stores, channel],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
