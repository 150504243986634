import { Grid } from '@leroy-merlin-br/lm-instala-components'
import { Button, Field, TextInput, View } from '@mozaic-ds/react'
import i18n from 'i18next'
import { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import MozaicReactSelect from '../../../components/MozaicReactSelect'
import { StoresSelectV2 } from '../../../components/StoresSelectV2'
import InstallersListContext from '../context'
import { getUrlFilters } from '../utils/FilterQueryParamsUtils'

export interface FilterValues {
  q: string
  active: '' | '0' | '1'
  receivingOrder: '' | '0' | '1'
  stores: string[]
}

const { Row, Col } = Grid

/**
 * installers list filter
 */
function Filter() {
  const { setFilter, setPage, onExport, isLoading } = useContext(InstallersListContext)
  const form = useForm<FilterValues>()

  useEffect(() => {
    const filter = getUrlFilters()

    if (filter.q) {
      form.setValue('q', filter.q)
    }
    if (filter.active) {
      form.setValue('active', filter.active)
    }
    if (filter.receivingOrder) {
      form.setValue('receivingOrder', filter.receivingOrder)
    }
    if (filter.stores.length > 0) {
      form.setValue('stores', filter.stores)
    }
  }, [])

  const onSubmit = () => {
    const values = form.getValues()
    setFilter(values)
    setPage(1)
  }

  const yesNoOptions = [
    { label: i18n.t('option.active'), value: '1' },
    { label: i18n.t('option.inactive'), value: '0' },
  ]
  return (
    <View className='filter-section' marginVertical='mu200'>
      <Row columnGap='150' rowGap='250'>
        <Col sizeXl='3' sizeMd='4'>
          <Field label={i18n.t('cnpjNameAndCpf.label')} style={{ flex: 1 }}>
            <TextInput
              placeholder={i18n.t('cnpjNameAndCpf.label')}
              {...form.register('q')}
              onKeyDown={(e) => (e.key === 'Enter' ? onSubmit() : null)}
            />
          </Field>
        </Col>
        <Col sizeXl='2' sizeMd='4'>
          <Field
            label={i18n.t('serviceSpecialists.indEspecialistaServicoAtivo')}
            style={{ flex: 1 }}
          >
            <Controller
              control={form.control}
              name='active'
              defaultValue=''
              render={({ field: { onChange, value } }) => (
                <MozaicReactSelect
                  options={yesNoOptions}
                  placeholder={i18n.t('option.all')}
                  value={yesNoOptions.find((opt) => opt.value === value)}
                  onChange={(e: any) => onChange(e?.value || '')}
                  className='active-select-filter'
                />
              )}
            />
          </Field>
        </Col>
        <Col sizeXl='2' sizeMd='4'>
          <Field label={i18n.t('installer.receipt.service.orders.text')} style={{ flex: 1 }}>
            <Controller
              control={form.control}
              name='receivingOrder'
              defaultValue=''
              render={({ field: { onChange, value } }) => (
                <MozaicReactSelect
                  options={yesNoOptions}
                  placeholder={i18n.t('option.all')}
                  value={yesNoOptions.find((opt) => opt.value === value)}
                  onChange={(e: any) => onChange(e?.value || '')}
                  className='receiving-order-select-filter'
                />
              )}
            />
          </Field>
        </Col>
        <Col sizeXl='2' sizeMd='4'>
          <Field label={i18n.t('serviceProvider.servcPrvdrMainPlntCd')} style={{ flex: 1 }}>
            <Controller
              control={form.control}
              name='stores'
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <StoresSelectV2
                  onChange={(e: any) => onChange(e ? e.map((opt: any) => opt.value) : [])}
                  isMulti
                  value={value}
                  closeMenuOnSelect={false}
                  placeholder={i18n.t('option.all')}
                  className='stores-select-filter'
                />
              )}
            />
          </Field>
        </Col>
        <Col sizeXl='2' sizeMd='2' size='6' className='col-btn col-btn-filter'>
          <Button width='full' onClick={onSubmit} isDisabled={isLoading}>
            {i18n.t('filter_button_label')}
          </Button>
        </Col>
        <Col sizeXl='2' sizeMd='2' size='6' className='col-btn'>
          <Button
            width='full'
            variant='bordered'
            onClick={() => {
              onExport()
            }}
            isDisabled={isLoading}
          >
            {i18n.t('option.export')}
          </Button>
        </Col>
      </Row>
    </View>
  )
}

export default Filter
