import i18next from 'i18next'
import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'
import { formatToTitleCase } from '../../../helpers/format'
import { ASSIGNED_TO_SERVICE_CORE } from '../../../constants/config'

export enum ResponsibleType {
  loggedUser = 'loggedUser',
  serviceCore = 'serviceCore',
}

const user = new User().currentUser

export const assignToResponsible = async (
  servcOrdSeq: number,
  responsibleType: ResponsibleType,
) => {
  const responsibleBody = {
    [ResponsibleType.loggedUser]: () => ({
      prjctCpcNm: formatToTitleCase(user?.fullName),
      prjctCpcLdap: user.username,
    }),
    [ResponsibleType.serviceCore]: () => ({
      prjctCpcNm: ASSIGNED_TO_SERVICE_CORE,
      prjctCpcLdap: null,
    }),
  }

  try {
    const { cdStatus } = await new API_REQUEST(user.dsToken, store.dispatch).patch(
      `${API_URL.UPDATE_OS_PARTIAL}/${servcOrdSeq}`,
      responsibleBody[responsibleType](),
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('serviceOrder.assignToMe.success.message'),
      '',
    )

    return cdStatus
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
