import { memo } from 'react'

import HamburgerMenu from './HamburgerMenu'
import Profile from './Profile'
import Badge from './Badge'
import AdvancedSearchContainer from './AdvancedSearchContainer'
import LocationContainer from './Location'
import LogoContainer from './Logo'
import AdvancedSearchMobile from './AdvancedSearchContainer/components/AdvancedSearchInputMobile'

function SideNav() {
  return (
    <div id='header-container'>
      <header id='side-nav-container'>
        <HamburgerMenu />
        <LogoContainer />
        <Badge />
        <AdvancedSearchContainer />
        <LocationContainer />
        <Profile />
      </header>
      <AdvancedSearchMobile />
    </div>
  )
}

export default memo(SideNav)
