import { Component } from 'react'
import PropTypes from 'prop-types'

import Images from '../../../components/utils/Images'
import Row from '../../../components/utils/Row'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'

import { ServiceImageType } from '../../../helpers/serviceOrderUtils'

class ServiceOrderImages extends Component {
  renderImages() {
    const { t, images, startPhoto } = this.props
    let imagesWithCaptions = images
    if (startPhoto) {
      imagesWithCaptions = images.map((img) => ({
        ...img,
        imgCaption:
          img.servcOrdItemAtchmtClsfctnCd === ServiceImageType.START
            ? t('imageSubtitles.label.serviceStart')
            : img.servcOrdItemAtchmtClsfctnCd === ServiceImageType.END
            ? t('imageSubtitles.label.serviceEnd')
            : img.servcOrdItemAtchmtClsfctnCd === ServiceImageType.NOT_CONFORM
            ? t('imageSubtitles.label.serviceCompletion.nonCompliant')
            : img.servcOrdItemAtchmtClsfctnCd === ServiceImageType.REPORT
            ? t('imageSubtitles.label.report')
            : null,
      }))
    }

    return <Images t={t} images={imagesWithCaptions} isServiceStart={startPhoto} />
  }

  renderMessage() {
    const { t } = this.props

    return t('serviceOrders.photos.empty')
  }

  render() {
    const { images, label } = this.props

    return (
      <Grid fluid className='padding-bottom-double'>
        <Row>
          <Col xs={12} className='no-padding-side'>
            <h1>{label}</h1>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className='no-padding-side'>
            {images.length ? this.renderImages() : this.renderMessage()}
          </Col>
        </Row>
      </Grid>
    )
  }
}

ServiceOrderImages.propTypes = {
  t: PropTypes.func.isRequired,
  images: PropTypes.array,
  label: PropTypes.string.isRequired,
  startPhoto: PropTypes.bool,
}
export default ServiceOrderImages
