/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import { Component, cloneElement } from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    // Space to add an error log service.
  }

  render() {
    const { FallbackComponent, children, onError } = this.props
    const { hasError } = this.state

    if (hasError) return cloneElement(FallbackComponent, { onError })

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
  FallbackComponent: PropTypes.object,
  onError: PropTypes.any,
}

export default ErrorBoundary
