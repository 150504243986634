import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGOUT_COMPLETE,
  ACCESS_NOT_ALLOWED,
  ACCESS_ALLOWED,
  SESSION_EXPIRED,
  CHANGE_TOKEN,
  SETUP_PARAMETERS_ERROR,
  SETUP_PARAMETERS_SUCCESS,
  SIGNIN_FIREBASE_SUCCESS,
} from '../../actionTypes/auth'

const DEFAULT_STATE = {
  isAuthenticating: false,
  isAuthenticated: false,
  user: null,
  tokenExpiration: null,
  setupParameters: {},
  error: null,
  message: null,
  logout: false,
  redirectUrl: '/',
  signinFirebaseSuccess: false,
}

function authenticate(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SETUP_PARAMETERS_SUCCESS:
      return {
        ...state,
        setupParameters: action.payload,
      }

    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        message: 'Autenticando...',
        error: null,
      }

    case LOGIN_SUCCESS:
      const user = action.payload

      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        user,
        error: null,
        message: 'Autenticação realizada com sucesso',
        logout: false,
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        error: action.payload,
        message: 'Falha na autenticação',
      }

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        error: null,
        message: 'Logout realizado com sucesso',
        logout: true,
      }

    case ACCESS_NOT_ALLOWED:
      return {
        ...state,
        error: 'Esse usuário não tem acesso a essa funcionalidade',
        isFunctionAllowed: false,
      }

    case ACCESS_ALLOWED:
      return {
        ...state,
        isFunctionAllowed: true,
        error: null,
      }

    case LOGOUT_COMPLETE:
      return {
        ...state,
        isAuthenticated: false,
      }

    case SESSION_EXPIRED:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        user: null,
        error: { message: { dsMessage: 'session.expired' } },
        message: 'Sua sessão expirou!',
      }

    case CHANGE_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          payload: {
            dsToken: action.payload.dsToken,
            tokenExpirationTime: action.payload.tokenExpirationTime,
          },
          dsToken: action.payload.dsToken,
        },
        tokenExpiration: action.payload,
      }

    case SETUP_PARAMETERS_ERROR:
      return {
        ...state,
        error: 'Erro nos dados de configuração do firebase',
      }

    case SIGNIN_FIREBASE_SUCCESS:
      return {
        ...state,
        signinFirebaseSuccess: true,
      }
    default:
      return state
  }
}

export default authenticate
