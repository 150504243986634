import ReactSelect, { Props } from 'react-select'
import classNames from 'classnames'
import i18n from 'i18next'

import './index.scss'

export interface MozaicReactSelectOption {
  label: string
  value: string | number | null
  isDisabled?: boolean
}

export interface MozaicReactSelectProps extends Props<MozaicReactSelectOption, boolean> {
  className?: string
  isInvalid?: boolean
  isValid?: boolean
  hasValue?: boolean
  isMulti?: boolean
}

/**
 * wrapper to react-select in mozaic styles
 */
function MozaicReactSelect({
  className = '',
  isInvalid,
  isValid,
  noOptionsMessage,
  placeholder = i18n.t('pwa.common.select'),
  isClearable = true,
  ...props
}: MozaicReactSelectProps) {
  const getDefaultNoOptionsMessage = () => i18n.t('select.noOptionsMessage')

  return (
    <div
      className={classNames('mozaic-react-select', className, {
        isInvalid,
        isValid,
      })}
    >
      <ReactSelect
        noOptionsMessage={noOptionsMessage ?? getDefaultNoOptionsMessage}
        placeholder={placeholder}
        isClearable={isClearable}
        classNamePrefix='react-select-prefix'
        {...props}
      />
    </div>
  )
}

export default MozaicReactSelect
