import { ComponentClass, memo, useMemo } from 'react'
import { useParams } from 'react-router'
import { useInstallerContext } from '../../../contexts/installer'
import { useProviderContext } from '../../../contexts/provider'
import { useForm } from '../../../helpers/useForm'
import { useReasons } from '../../../hooks/business/useReasons'

export const FIELDS = {
  servcPrvdrCd: '',
  servcPrvdrNm: '',
  servcPrvdrAgntCd: '',
  servcPrvdrAgntNm: '',
  servcOrdStusCd: '',
  servcOrdSchdlgTs: '',
  date: '',
  plntServcCalndrCd: '',
  idSlot: '',
  idSlotApplication: '',
  dsSlot: '',
  justificativa: '',
  custBusnsPhonNr: '',
  custHmPhonNr: '',
  custMobilePhonNr: '',
  servcAddrStrNm: '',
  servcAddrNr: '',
  servcAddrCmplmtryTxt: '',
  servcAddrDstrctNm: '',
  servcAddrPstlCd: '',
  servcAddrCityNm: '',
  servcAddrStCd: '',
  cntryNm: '',
  servcAddrRefPointDesc: '',
  phone1: '',
  phone2: '',
  phone3: '',
  custEmailTxt: '',
  answer: '',
  servcPrvdrTrdNm: '',
  servcOrdEvltdInd: '',
  evaluationFlow: '',
  undoneServiceReason: '',
  selectedReason: '',
  servcCatgryTypCd: '',
  ibgeCode: '',
  servcPrvdrPlntCd: '',
}

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { values, fields, register, setField, setFormValue } = useForm(FIELDS)

    const params = useParams()

    const { fetchUnnacomplishedReasonsList, unnacomplishedReasonsList, fetchReasonsList, reasons } =
      useReasons()

    const { fetchServiceSpecialistList, installersList } = useInstallerContext()

    const { providersAutoCompleteList, fetchServiceProviderAutocomplete } = useProviderContext()

    const newProps = useMemo(
      () => ({
        values,
        fields,
        params,
        register,
        setField,
        setFormValue,
        fetchUnnacomplishedReasonsList,
        fetchReasonsList,
        reasons,
        unnacomplishedReasonsList,
        fetchServiceSpecialistList,
        installersList,
        serviceProviders: providersAutoCompleteList,
        fetchServiceProviderAutocomplete,
      }),
      [
        fetchServiceProviderAutocomplete,
        unnacomplishedReasonsList,
        installersList,
        reasons,
        values,
        fields,
        params,
        register,
        setField,
        setFormValue,
      ],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
