import { useEffect, useState } from 'react'
import i18next from 'i18next'

import { HistoricOccurrence } from '../../../../../../../../../ServiceOrderDetail/components/Routes/Historic/components/HistoricOccurrences/models/historic-occurrence'
import { fetchProjectOcurrences } from '../../../../../../../../../../services/Project'

import BodySection from '../BodySection'
import OcurrencesTable from '../../../../../../../../../../components/OcurrencesTable'

function Ocurrences() {
  const [occurrences, setOcurrences] = useState<HistoricOccurrence[]>([])

  const handleFetchOcurrences = async () => {
    const newOcurrences = await fetchProjectOcurrences(118631)

    setOcurrences(newOcurrences)
  }

  useEffect(() => {
    handleFetchOcurrences()
  }, [])

  return (
    <BodySection title={`${i18next.t('historic.text')}/${i18next.t('serviceOrders.occurrences')}`}>
      <div className='service-ocurrences'>
        <OcurrencesTable occurrences={occurrences} />
      </div>
    </BodySection>
  )
}

export default Ocurrences
