import i18next from 'i18next'
import { RadioGroup } from '@leroy-merlin-br/backyard-react'

type JustifyInputType = {
  onChange: Function
  value: string
}

function ModeSelect(props: JustifyInputType) {
  const { onChange, value } = props

  const handleChange = (e: any) => {
    onChange(e.target.value === 'true')
  }

  const options: any = [
    {
      children: i18next.t('option.yes'),
      value: 'true',
      disabled: false,
    },
    {
      children: i18next.t('option.no'),
      value: 'false',
      disabled: false,
    },
  ]

  const label = `${i18next.t('serviceOrders.sameProvider.select')}?`

  return (
    <section className='mode-select-warranty'>
      <label className='mode-select-label'>{label}</label>
      <RadioGroup
        label={i18next.t('data.aggregation.text')}
        name='radio-group'
        value={value}
        options={options}
        onChange={(e) => handleChange(e)}
      />
    </section>
  )
}

export default ModeSelect
