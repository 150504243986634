import PropTypes from 'prop-types'
import classNames from 'classnames'

function Col(props) {
  const {
    xs,
    xsOffset,
    xsPull,
    xsPush,
    sm,
    smOffset,
    smPull,
    smPush,
    md,
    mdOffset,
    mdPull,
    mdPush,
    lg,
    lgOffset,
    lgPull,
    lgPush,
    xl,
    xlOffset,
    xlPull,
    xlPush,
    className,
    children,
    style,
  } = props

  const parseClass = (cls) => {
    if (cls && cls % 1 !== 0) {
      return `${cls}`.replace('.', '_')
    }

    return cls
  }

  const sizeClasses = classNames({
    [`col-xs-${parseClass(xs)}`]: xs,
    [`col-xs-offset-${parseClass(xsOffset)}`]: xsOffset,
    [`col-xs-pull-${parseClass(xsPull)}`]: xsPull,
    [`col-xs-push-${parseClass(xsPush)}`]: xsPush,
    [`col-sm-${parseClass(sm)}`]: sm,
    [`col-sm-offset-${parseClass(smOffset)}`]: smOffset,
    [`col-sm-pull-${parseClass(smPull)}`]: smPull,
    [`col-sm-push-${parseClass(smPush)}`]: smPush,
    [`col-md-${parseClass(md)}`]: md,
    [`col-md-offset-${parseClass(mdOffset)}`]: mdOffset,
    [`col-md-pull-${parseClass(mdPull)}`]: mdPull,
    [`col-md-push-${parseClass(mdPush)}`]: mdPush,
    [`col-lg-${parseClass(lg)}`]: lg,
    [`col-lg-offset-${parseClass(lgOffset)}`]: lgOffset,
    [`col-lg-pull-${parseClass(lgPull)}`]: lgPull,
    [`col-lg-push-${parseClass(lgPush)}`]: lgPush,
    [`col-xl-${parseClass(xl)}`]: xl,
    [`col-xl-offset-${parseClass(xlOffset)}`]: xlOffset,
    [`col-xl-pull-${parseClass(xlPull)}`]: xlPull,
    [`col-xl-push-${parseClass(xlPush)}`]: xlPush,
  })

  const componentClass = classNames(sizeClasses, { col: !sizeClasses }, { [className]: className })

  const newProps = {
    className: componentClass,
    children,
  }

  return <section style={style} {...newProps} />
}

Col.propTypes = {
  xs: PropTypes.number,
  xsOffset: PropTypes.number,
  xsPull: PropTypes.number,
  xsPush: PropTypes.number,
  sm: PropTypes.number,
  smOffset: PropTypes.number,
  smPull: PropTypes.number,
  smPush: PropTypes.number,
  md: PropTypes.number,
  mdOffset: PropTypes.number,
  mdPull: PropTypes.number,
  mdPush: PropTypes.number,
  lg: PropTypes.number,
  lgOffset: PropTypes.number,
  lgPull: PropTypes.number,
  lgPush: PropTypes.number,
  xl: PropTypes.number,
  xlOffset: PropTypes.number,
  xlPull: PropTypes.number,
  xlPush: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  style: PropTypes.string,
}

export default Col
