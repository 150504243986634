import { createContext, useContext, useMemo } from 'react'
import { ProviderStore, ProviderProviderProps } from './models'
import ProviderListHandlers from './handlers/providerList'
import ProviderHandlers from './handlers/provider'

const ProviderContext = createContext<ProviderStore | null>(null)

function ProviderProvider({ children }: ProviderProviderProps) {
  const {
    fetchServiceProviderNameAutocomplete,
    fetchServiceProviderAutocomplete,
    handleClearProviderList,
    handleDeleteProvider,
    handleFetchProviders,
    inactiveProvider,
    sendSmsApp,
    providersAutoCompleteList,
    providersList,
    currentPage,
    filters,
  } = ProviderListHandlers()

  const {
    handleFetchProvider,
    handleFetchProviderFiles,
    handleSaveProviderFiles,
    handleRemoveProviderFile,
    handleUpdateProvider,
    handleActiveProviderToReceiveOs,
    handleClearProvider,
    providerFiles,
    provider,
  } = ProviderHandlers()

  const result = useMemo(
    () => ({
      filters,
      currentPage,
      provider,
      providerFiles,
      providersList,
      providersAutoCompleteList,
      handleClearProviderList,
      handleClearProvider,
      fetchServiceProviderAutocomplete,
      fetchServiceProviderNameAutocomplete,
      handleActiveProviderToReceiveOs,
      handleDeleteProvider,
      handleRemoveProviderFile,
      handleFetchProviderFiles,
      handleSaveProviderFiles,
      handleUpdateProvider,
      handleFetchProviders,
      handleFetchProvider,
      inactiveProvider,
      sendSmsApp,
    }),
    [filters, provider, providersList, providerFiles, providersAutoCompleteList],
  )

  return <ProviderContext.Provider value={result}>{children}</ProviderContext.Provider>
}

const useProviderContext = () => useContext(ProviderContext) as ProviderStore

export { useProviderContext, ProviderProvider }
