import { useEffect, useRef, useMemo, useState } from 'react'
import i18next from 'i18next'
import { RefType, defaultProps, Title } from '../utils'

export function useCoreClientChat() {
  const [chatSolved, setChatSolved] = useState(false)
  const chatRef = useRef<RefType>(null)

  const chatProps = useMemo(
    () => ({
      ...defaultProps,
      header: (
        <Title
          before={i18next.t('field.core')}
          after={i18next.t('conformity.osData.subtitle')}
          solved={chatSolved}
        />
      ),
      onOpen: () => {
        chatRef.current?.chatRef.markAsRead()
        chatRef.current?.handleScroll()
      },
      defaultOpen: true,
    }),
    [chatSolved, chatRef],
  )

  useEffect(() => {
    const chatGCPRef = chatRef.current?.chatRef
    chatGCPRef?.onSnapshot((newChat) => setChatSolved(newChat.solved))
  }, [chatRef])

  return {
    chatRef,
    chatProps,
    chatSolved,
  }
}
