import { useState } from 'react'
import User from '../../../core/User'

import { USER_TYPE } from '../../../constants/config'
import { Conversation } from '../models'

import CustomerChat from '../../../services/firestore/customerChat/conversation'
import { CustomerChatServiceType } from '../../../services/firestore/customerChat/customerChat'
import { sendChatNotifications } from '../../../services/firestore/repository/sendChatNotifications'

function ConversationHandlers(chatRepository: CustomerChatServiceType) {
  const [hasOpenedClientConversation, setHasOpenedClientConversation] = useState(false)
  const [openedClientConversation, setOpenedClientConversation] = useState<string>('')

  const chatUsecases = CustomerChat(chatRepository)
  const user = new User().currentUser

  async function handleResolveConversation(conversation: Conversation, solved: boolean) {
    await chatUsecases.resolveConversation(conversation, solved)
  }

  async function handleSendChatNotifications(conversation: Conversation, message: string) {
    const params = {
      chatMessageUserType: USER_TYPE.CUSTOMER,
      displayName: user.fullName,
      message,
      servcOrdSeq: conversation.servcOrdCd,
    }

    await sendChatNotifications(params)
  }

  async function handleUpdateConversation(conversation: Conversation, message?: string) {
    await handleSendChatNotifications(conversation, message as string)
    await chatUsecases.updateConversation(conversation, message)
  }

  function handleReadClientMessages(serviceOrdCd: string) {
    chatUsecases.readClientMessages(serviceOrdCd)
  }

  function handleSetClientConversation(serviceOrdCd: string) {
    handleReadClientMessages(serviceOrdCd)

    setOpenedClientConversation(serviceOrdCd)
    setHasOpenedClientConversation(true)
  }

  function handleClear() {
    setOpenedClientConversation('')
    setHasOpenedClientConversation(false)
  }

  return {
    handleReadClientMessages,
    handleResolveConversation,
    handleUpdateConversation,
    handleSetClientConversation,
    handleClearConversation: handleClear,
    openedClientConversation,
    hasOpenedClientConversation,
  }
}

export default ConversationHandlers
