import { Component } from 'react'
import PropTypes from 'prop-types'
import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

class AwsVideo extends Component {
  state = {
    base64: null,
  }

  componentDidMount() {
    this.renderVideo()
  }

  getFile = async (token, filePath, dispatch) => {
    let base64 = ''
    await new Api(token, dispatch).get(API_URL.AMAZON_AWS_GET_FILE, { filePath }).then(
      (response) => {
        base64 = response.data
        return response.data
      },
      (error) => {
        console.error('Faleid to find video', error)
        return ''
      },
    )

    return base64
  }

  renderVideo = async () => {
    const { filePath, token, dispatch, data } = this.props

    if (data) {
      this.setState({ base64: data })
    } else {
      const base64 = await this.getFile(token, filePath, dispatch)
      this.setState({ base64 })
    }
  }

  render() {
    const { base64 } = this.state
    return (
      base64 && (
        <video className='lightbox-video' controls>
          <source type='video/webm' src={`data:video/webm;base64, ${base64}`} />
          <source type='video/mp4' src={`data:video/mp4;base64, ${base64}`} />
        </video>
      )
    )
  }
}

AwsVideo.propTypes = {
  filePath: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.string,
}

export default AwsVideo
