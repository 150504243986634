import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import store from '../../../redux/store'
import User from '../../../core/User'
import { ADEOResponse } from '../../../models/AdeoResponse'
import { Speciality } from '../../specialities/fetchSpecialities'

const user = new User().currentUser

export const updateProviderSpecialistSpecialities = async (
  servcPrvdrAgntId: number,
  specialitiesIds: Speciality['specialityCd'][],
): Promise<ADEOResponse<any>> =>
  new API_REQUEST(user.dsToken, store.dispatch).patch(
    API_URL.UPDATE_SERVICE_SPECIALIST_SPECIALITIES,
    {
      servcPrvdrAgntId,
      specialitiesIds,
    },
  )

export const updateProviderSpecialistSpecialitiesWithPriority = async (
  servcPrvdrAgntId: number,
  specialities: Speciality['specialityCd'][],
): Promise<ADEOResponse<any>> =>
  new API_REQUEST(user.dsToken, store.dispatch).patch(
    API_URL.UPDATE_SERVICE_SPECIALIST_SPECIALITIES,
    {
      servcPrvdrAgntId,
      specialities,
    },
  )

export const findProviderSpecialistSpecialitiesIds = async (
  servcPrvdrAgntId: number,
): Promise<ADEOResponse<string[]>> =>
  new API_REQUEST(user.dsToken, store.dispatch).get(
    API_URL.FIND_SERVICE_SPECIALIST_SPECIALITIES_IDS,
    {
      servcPrvdrAgntId,
    },
  )

export const findProviderSpecialistSpecialities = async (
  servcPrvdrAgntId: number,
): Promise<ADEOResponse<any>> =>
  new API_REQUEST(user.dsToken, store.dispatch).get(
    API_URL.FIND_SERVICE_SPECIALIST_SPECIALITIES.replace(
      '##servcPrvdrAgntId##',
      servcPrvdrAgntId.toString(),
    ),
    {},
  )
