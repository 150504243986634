import i18next from 'i18next'
import MozaicIcon from '../../../../components/MozaicIcon'

const randomStroke = ['#8884d8', '#82ca9d']

export const accordeonFilter = {
  title: 'provider.detail.accordeon.filter.title',
  icon: <MozaicIcon icon='Document32' />,
}

export const checkChartType = (filter) => {
  const currentValue = filter.data.information

  return currentValue === 'ratingServices' || currentValue === 'ratingInstallers'
    ? 'lineChart'
    : 'barChart'
}

export const chartData = (results, keys) => {
  const dataSet = keys.informationToCompare
    ? results.map((result) => ({
        [keys.agregationField]: result[keys.agregationField],
        [keys.information]: parseFloat(result[keys.information]),
        [keys.informationToCompare]: parseFloat(result[keys.informationToCompare]),
      }))
    : results.map((result) => ({
        [keys.agregationField]: result[keys.agregationField],
        [keys.information]: parseFloat(result[keys.information]),
      }))

  return dataSet
}

export const chartLines = (keys) => {
  const lines = [
    {
      label: i18next.t(`provider.detail.information.${keys.information}`),
      dataKey: keys.information,
      stroke: randomStroke[0],
      type: 'monotone',
    },
  ]

  if (keys.informationToCompare) {
    lines.push({
      label: i18next.t(`provider.detail.information.${keys.informationToCompare}`),
      dataKey: keys.informationToCompare,
      stroke: randomStroke[1],
      type: 'monotone',
    })
  }

  return lines
}

export const chartBars = (keys) => {
  const bars = [
    {
      label: i18next.t(`provider.detail.information.${keys.information}`),
      dataKey: keys.information,
      fill: randomStroke[0],
    },
  ]

  if (keys.informationToCompare) {
    bars.push({
      label: i18next.t(`provider.detail.information.${keys.informationToCompare}`),
      dataKey: keys.informationToCompare,
      fill: randomStroke[1],
    })
  }

  return bars
}
