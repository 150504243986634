import PropTypes from 'prop-types'
import { Component } from 'react'
import Col from '../../../components/utils/Col'
import Grid from '../../../components/utils/Grid'
import Row from '../../../components/utils/Row'
import { WithForm } from '../../../helpers/useForm/withForm'
import OccurrencesList from './OccurrencesList'
import ServiceOrderModal from './ServiceOrderModal'

class Occurrences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      newOccurrenceAuthorization: 91,
    }

    this.sendOccurrence = this.sendOccurrence.bind(this)
    this.openAddOccurrenceModal = this.openAddOccurrenceModal.bind(this)
    this.closeAddOccurrenceModal = this.closeAddOccurrenceModal.bind(this)
    this.renderAddOccurrenceModal = this.renderAddOccurrenceModal.bind(this)
  }

  sendOccurrence(event) {
    event.preventDefault()

    const {
      values,
      onSubmit,
      auth: { user },
    } = this.props

    onSubmit(user.dsToken, values)
    this.closeAddOccurrenceModal()
  }

  openAddOccurrenceModal() {
    this.setState({ modalOpen: true })
  }

  closeAddOccurrenceModal() {
    this.setState({ modalOpen: false })
  }

  renderAddOccurrenceModal() {
    const { modalOpen } = this.state
    const { t, values, fields, register, setFormValue } = this.props

    if (!modalOpen) {
      return
    }

    const props = {
      t,
      modalType: 'ADD_OCCURRENCE',
      closeModal: this.closeAddOccurrenceModal,
      disabled: false,
      className: 'add-occurrence-modal',
      showCloseButton: false,
      sendOccurrence: this.sendOccurrence,
      values,
      fields,
      register,
      setFormValue,
    }

    return <ServiceOrderModal {...props} />
  }

  checkFunction91 = () => {
    const {
      auth: { user },
    } = this.props
    const { newOccurrenceAuthorization } = this.state
    return !user.accessFunctionList?.includes(newOccurrenceAuthorization)
  }

  render() {
    const {
      t,
      occurrences,
      auth: { user },
      dispatch,
      serviceOrder,
    } = this.props

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} className='no-padding-side title-section'>
            <h1>{t('serviceOrders.occurrences')}</h1>

            <button
              className='button-hollow button-hollow-primary margin-left'
              onClick={this.openAddOccurrenceModal}
              disabled={this.checkFunction91()}
            >
              {t('serviceOrders.createNewOccurrence')}
            </button>
          </Col>
        </Row>

        {occurrences && occurrences.length ? (
          <OccurrencesList
            t={t}
            occurrences={occurrences}
            serviceOrder={serviceOrder}
            user={user}
            dispatch={dispatch}
          />
        ) : null}

        {this.renderAddOccurrenceModal()}
      </Grid>
    )
  }
}

Occurrences.propTypes = {
  t: PropTypes.func.isRequired,
  occurrences: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  auth: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
}

const FIELDS = { servcOrdSeq: '', descOcorOrdemServico: '' }

export default WithForm(FIELDS)(Occurrences)

export { Occurrences }
