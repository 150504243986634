import i18next from 'i18next'
import { ServiceOrderAttachment } from '../../services/pages/ServiceOrderDetail/GetServiceOrderAttachments'
import { ServiceImageType } from '../../helpers/serviceOrderUtils'

export type PhotoType = ServiceOrderAttachment

export const getPhotoTitle = (photo: PhotoType) => {
  const attachmentImageType = {
    [ServiceImageType.START]: i18next.t('imageSubtitles.label.serviceStart'),
    [ServiceImageType.END]: i18next.t('imageSubtitles.label.serviceEnd'),
    [ServiceImageType.NOT_CONFORM]: i18next.t(
      'imageSubtitles.label.serviceCompletion.nonCompliant',
    ),
    [ServiceImageType.REPORT]: i18next.t('imageSubtitles.label.report'),
  }

  return (
    (photo.servcOrdItemAtchmtClsfctnCd && attachmentImageType[photo.servcOrdItemAtchmtClsfctnCd]) ||
    photo.atchmtDocmntTypDesc ||
    photo.servcOrdItemAtchmtDesc
  )
}

export const getPhotoCreatedAt = (photo: PhotoType) => photo.creatTs
export const getPhotoUser = (photo: PhotoType) =>
  photo.lastUpdtUserCd
    ? `${i18next.t('serviceOrder.attachments.photos.user')}: ${photo.lastUpdtUserCd}`
    : ''

export const getImage = (photo: PhotoType) => `data:image;base64,${photo.servcOrdAtchmtDcmntIdNr}`
export const getExtension = (photo: PhotoType) => photo.fileTyp

type PhotoProps = {
  onView?: Function
  photo: ServiceOrderAttachment
  size?: number
  i?: number
}

export default function Photo({ photo, size, onView = () => {}, i = 0 }: PhotoProps) {
  return (
    <div className='photo' style={{ width: size }}>
      {photo.servcOrdAtchmtDcmntIdNr ? (
        <div
          className='photo__image'
          style={{ backgroundImage: `url(${getImage(photo)})`, width: size, height: size }}
          onClick={() => onView(i)}
        />
      ) : (
        <div className='skeleton photo__skeleton' />
      )}

      <div className='photo__content'>
        <div className='description'>
          <div className='description__title' title={getPhotoTitle(photo)}>
            {getPhotoTitle(photo)}
          </div>
          <div className='description__created-at' title={getPhotoCreatedAt(photo)}>
            {getPhotoCreatedAt(photo)}
          </div>
          <div className='description__user' title={getPhotoUser(photo)}>
            {getPhotoUser(photo)}
          </div>
        </div>
      </div>
    </div>
  )
}
