import PropTypes from 'prop-types'

function Notification({ message, className }) {
  return <div className={`notification ${className}`}>{message}</div>
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Notification
