import i18next from 'i18next'
import PropTypes from 'prop-types'
import { Cell, Column, HeaderCell, Table as TableContent } from 'rsuite-table'
import { usableArea } from '../../../../constants/breakpoints'
import { useProviderAnalysisListContext } from '../../../../contexts/providerAnalysis'
import { useUsableAreaContext } from '../../../../contexts/usableArea'

function Table({ filter, keys }) {
  const { sortColumn, sortType, handleSortColumn } = useProviderAnalysisListContext()

  const { widthContainer } = useUsableAreaContext()
  const heightTable = widthContainer > usableArea.medium ? 450 : 355

  const width = keys.informationToCompare ? '360px' : '240px'

  if (filter.results.length === 0) return <></>

  return (
    <div id='provider-analysis-table'>
      <TableContent
        className='table-content'
        data={filter.results || []}
        height={heightTable}
        onSortColumn={handleSortColumn}
        sortColumn={sortColumn}
        sortType={sortType}
        virtualized
        style={{ width, margin: 'auto', boxShadow: 'none' }}
      >
        <Column width={120} sortable>
          <HeaderCell>{i18next.t('serviceSpecialists.register')}</HeaderCell>
          <Cell dataKey={keys.agregationField} />
        </Column>
        <Column width={120} align='center' sortable>
          <HeaderCell>{i18next.t(`provider.detail.information.${keys.information}`)}</HeaderCell>
          <Cell dataKey={keys.information} />
        </Column>
        {keys.informationToCompare && (
          <Column width={120} align='center' sortable>
            <HeaderCell>
              {i18next.t(`provider.detail.information.${keys.informationToCompare}`)}
            </HeaderCell>
            <Cell dataKey={keys.informationToCompare} />
          </Column>
        )}
      </TableContent>
    </div>
  )
}

Table.propTypes = {
  filter: PropTypes.object.isRequired,
  keys: PropTypes.object.isRequired,
}

export default Table
