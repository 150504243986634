import { CSSProperties } from 'react'
import { ITextProps, Text } from '@mozaic-ds/react'

interface MozaicTextType extends ITextProps {
  ml?: number
  mr?: number
  mt?: number
  mb?: number
}

function MozaicText(props: MozaicTextType) {
  const { children, ml = 1, mr, mt = 4, mb = 4, ...otherProps } = props

  const textStyle: CSSProperties = {
    marginTop: mt ? `${mt}px` : undefined,
    marginLeft: ml ? `${ml}px` : undefined,
    marginRight: mr ? `${mr}px` : undefined,
    marginBottom: mb ? `${mb}px` : undefined,
  }

  return (
    <div style={textStyle}>
      <Text {...otherProps}>{children}</Text>
    </div>
  )
}

export default MozaicText
