import API from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import store from '../../redux/store'

export const fetchOsStatuses = async (token, statusCurrent) => {
  const query = { idCurrentStatus: statusCurrent }

  try {
    const response = await new API(token, store.dispatch).get(
      API_URL.FIND_SERVICE_ORDER_STATUSES,
      query,
    )

    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}
