import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

function PublicRoute({ component, ...rest }) {
  return <Route {...rest} component={component} />
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default PublicRoute
