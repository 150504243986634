import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { FORMAT_DATE_TRUNC } from '../../../constants/config'
import { formatCNPJ, formatDateTrunc, formatDecimalNoCurrency } from '../../../helpers/masks'
import MozaicBadge from '../../../components/MozaicBadge'
import { getOsRoutePrefix } from '../../../helpers/serviceOrderUtils'

class ServiceOrderTable extends Component {
  servcOrdPaymentDateTitle(date) {
    const { t } = this.props

    const paymentDate = moment(date)
    const today = moment()

    const dateTitle = paymentDate.isBefore(today)
      ? t('payments.servcOrdPaymentDate')
      : t('payments.servcOrdPaymentPrevDate')

    return dateTitle
  }

  renderTables = (serviceOrder, index) => {
    const { t } = this.props

    return (
      <table className='table table-border-bottom margin-top-small' style={{ width: '98.3%' }}>
        <tbody key={index}>
          <tr>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.servcOrdCd')}</div>
              <a
                href={`${getOsRoutePrefix()}/${serviceOrder.servcOrdCd}`}
                target='_blank'
                role='button'
                className='color-primary'
                rel='noreferrer'
              >
                <span className='heading-4 strong' style={{ display: 'inline-block' }}>
                  {serviceOrder.servcOrdCd}
                </span>
              </a>
            </td>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.vaSlsOrdNr')} </div>

              <div className='heading-5 strong'>{serviceOrder.vaSlsOrdNr || '-'}</div>
            </td>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.executionDates.end')}</div>
              <div className='heading-5 strong'>
                {serviceOrder.servcOrdEndDt
                  ? moment(serviceOrder.servcOrdEndDt).format(FORMAT_DATE_TRUNC)
                  : '-'}
              </div>
            </td>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.plntNm')}</div>
              <div className='heading-5 strong'>{serviceOrder.plntNm || '-'}</div>
            </td>
          </tr>

          <tr>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.tradingName')}</div>
              <div className='heading-5 strong'>
                {serviceOrder.servcPrvdrTrdNm ? serviceOrder.servcPrvdrTrdNm : '-'}
              </div>
            </td>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.cnpj')}</div>
              <div className='heading-5 strong'>
                {formatCNPJ(serviceOrder.servcPrvdrFedrlTaxIdNr, t)}
              </div>
            </td>
            <td valign='top' className='align-itens-td'>
              <div>{t('payments.amount')}</div>
              <div className='heading-5 strong'>
                R$ {formatDecimalNoCurrency(serviceOrder.servcOrdPrvdrPymtAmt, t) || '-'}
              </div>
            </td>
          </tr>
          <tr>
            <td className='align-itens-td'>
              <div>{t('payments.situationNF')}</div>
              <div className='heading-5'>
                <MozaicBadge
                  text={serviceOrder.descriptionNFSituation}
                  theme={serviceOrder.descriptionColorNFSituation === 'red' ? 'danger' : 'success'}
                  className='badge-margin'
                />
              </div>
            </td>
            <td className='align-itens-td'>
              <div>{t('payments.situationNFPay')}</div>
              <div className='heading-5'>
                <MozaicBadge
                  text={serviceOrder.descriptionNFPayment}
                  theme={
                    serviceOrder.descriptionColorNFPayment === 'yellow' ? 'warning' : 'success'
                  }
                  className='badge-margin'
                />
              </div>
            </td>

            <td className='align-itens-td'>
              <div>{t('payments.servcPrvdrNfDate')}</div>
              <div className='heading-5 strong'>
                {serviceOrder.servcPrvdrNfDate
                  ? formatDateTrunc(serviceOrder.servcPrvdrNfDate)
                  : '-'}
              </div>
            </td>
            <td className='align-itens-td'>
              <div>{t('payments.servcOrdNfDate')}</div>
              <div className='heading-5 strong'>
                {serviceOrder.servcOrdNfDate ? formatDateTrunc(serviceOrder.servcOrdNfDate) : '-'}
              </div>
            </td>
          </tr>
          <tr>
            <td className='align-itens-td'>
              <div>{this.servcOrdPaymentDateTitle(serviceOrder.servcOrdPaymentDate)}</div>
              <div className='heading-5 strong'>
                {serviceOrder.servcOrdPaymentDate
                  ? formatDateTrunc(serviceOrder.servcOrdPaymentDate)
                  : '-'}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const { serviceOrders } = this.props

    return <div className='content-table'>{serviceOrders.map(this.renderTables)}</div>
  }
}

ServiceOrderTable.propTypes = {
  serviceOrders: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}

export default ServiceOrderTable
