import { ADEOResponse } from '../../models/AdeoResponse'
import API_URL from '../../core/Envs/endpoints'
import Fetch from '../../core/Http/FetchAdapter'
import store from '../../redux/store'
import User from '../../core/User'

const user = new User()

interface FetchServiceProvidersParams {
  page?: number
  size?: number
  fields?: any
}

export const fetchServiceProviders = async ({
  page = 1,
  size = 10,
  fields = {},
}: FetchServiceProvidersParams = {}): Promise<ADEOResponse<any>> => {
  const metadata = {
    page,
    size,
  }

  const query = {
    ...fields,
    requestOriginPortal: 'SERVICES',
  }

  return new Fetch(user.currentUser.dsToken, store.dispatch, metadata).post(
    API_URL.SERVICE_PROVIDER_FIND,
    query,
  )
}
