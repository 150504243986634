import { useMemo } from 'react'
import { serviceOnTimeData } from '../../../../constants/alerts'
import store from '../../../../redux/store'
import ServiceOnTimeIndicator from './components/ServiceOnTimeIndicator'

interface ServiceOnTimeProps {
  class: string
}

function ServiceOnTime({ class: classText }: ServiceOnTimeProps) {
  const parameters = useMemo(() => store.getState().auth.setupParameters, [])
  const data = useMemo(() => serviceOnTimeData(parameters), [parameters])

  return (
    <div id='service-on-time-container'>
      {data.map(
        (alert) =>
          alert.render && (
            <ServiceOnTimeIndicator
              showPopover
              alert={alert}
              key={alert.type}
              classText={classText}
            />
          ),
      )}
    </div>
  )
}

export default ServiceOnTime
