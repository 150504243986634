import store from '../redux/store'
import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { getPages } from '../constants/pagination'
import { hideSpinner, showSpinner } from '../redux/actions/spinner'
import { renderToastSuccessMsg } from '../helpers/ToastUtils'
import i18next from 'i18next'

export const fetchInstallersList = async (token, filter) => {
  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.FIND_SERVICE_SPECIALIST_LIST,
      filter,
    )

    return data
  } catch (err) {
    return err
  }
}

export const fetchInstallersDistributionList = async (token, filter) => {
  try {
    const { data } = await new API(token, store.dispatch).post(
        API_URL.FIND_WORKER_MANUAL_DISTRIBUTION,
        filter,
    )

    return data
  } catch (err) {
    return err
  }
}

export const fetchInstallersListPage = async (token = null, filter, page = 1) => {
  store.dispatch(showSpinner())

  const pagination = {
    page,
    size: 10,
  }

  const query = {
    ...filter,
    servcPrvdrAgntId: filter.idPrestadorServico,
    actvServcPrvdrAgntInd: filter.indEspecialistaServicoAtivo,
    servcPrvdrAgntNm: filter.nomeEspecialistaServico,
    lsPlntCd: (filter.lsPlntCd || []).map((it) => it.value),
  }

  try {
    const { metadata, data } = await new API(token, store.dispatch, pagination).post(
      API_URL.FIND_SERVICE_SPECIALIST_LIST,
      query,
    )

    store.dispatch(getPages(metadata))

    return data
  } catch (err) {
    console.log(err)
    return err
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchInstallersAutoComplete = async (token, partialText, onSuccess) => {
  const params = {
    partialText,
  }

  const { data } = await new API(token, store.dispatch).get(
    API_URL.FIND_SERVICE_SPECIALIST_NAME_AUTOCOMPLETE,
    params,
  )

  onSuccess(data)

  return data
}

export const sendMail = async (token, values) => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SERVICE_SPECIALIST_SEND_MAIL,
      values,
    )

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const sendSmsAppSpecialist = async (token, values) => {
  store.dispatch(showSpinner())

  const query = {
    ...values,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.SEND_SMS_APP_SPECIALIST,
      query,
    )

    renderToastSuccessMsg(i18next.t('service.specialist.sendpush.success'))

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const deleteServiceSpecialist = async (token, value) => {
  store.dispatch(showSpinner())

  const fields = {
    servcPrvdrAgntId: value.idEspecialistaServico,
  }

  try {
    const { data } = await new API(token, store.dispatch).post(
      API_URL.DELETE_SERVICE_SPECIALIST,
      fields,
    )

    return data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}
