import classnames from 'classnames'
import PropTypes from 'prop-types'

import InstalaImg from '../../../../assets/icons/instala.svg'
import LogoImg from '../../../../assets/icons/logomark.svg'
import MozaicIcon from '../../../../components/MozaicIcon'

function MenuHeader({ isMenuOpen, toggleMenu }) {
  return (
    <header id='menu-header'>
      <div className='menu-header-desktop'>
        <div className='header-icon'>
          <img src={LogoImg} alt='Instala' />
        </div>
        {isMenuOpen && (
          <img
            className={classnames({
              'fade-in': isMenuOpen,
            })}
            src={InstalaImg}
            alt='Instala'
          />
        )}
      </div>

      <button
        name='toggle-menu'
        data-testid='toggle-menu'
        className='toggle-menu'
        type='button'
        onClick={toggleMenu}
      >
        <div>
          <MozaicIcon icon='ArrowArrowLeft48' />
        </div>
      </button>
    </header>
  )
}

MenuHeader.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

export default MenuHeader
