import { useCallback, useState } from 'react'

import useEventListener from './useEventListener'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

import { menuOpenedWidth, menuClosedWidth } from '../constants/menu'
import { usableArea } from '../constants/breakpoints'

let uniqueCall = null
let timer = false

function useElementSize(isOpen) {
  const [ref, setRef] = useState(null)
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })
  const widthWindow = window?.innerWidth || 0

  const getWidthContainer = () => {
    const isOpenAndDesktop = isOpen && widthWindow >= usableArea.large
    const isClosedAndDesktop = !isOpen && widthWindow >= usableArea.large

    const openedMenuDecrement = size.width - menuOpenedWidth
    const closedMenuDecrement = size.width - menuClosedWidth

    if (isOpenAndDesktop) return openedMenuDecrement
    if (isClosedAndDesktop) return closedMenuDecrement
    return size.width
  }

  const widthContainer = getWidthContainer()

  const handleSize = useCallback(() => {
    clearTimeout(uniqueCall)

    timer = false

    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    })
  }, [isOpen, ref?.offsetHeight, ref?.offsetWidth])

  useIsomorphicLayoutEffect(() => {
    if (!timer) {
      timer = true

      uniqueCall = setTimeout(() => {
        handleSize()
      }, 200)
    }
  }, [isOpen, ref?.offsetHeight, ref?.offsetWidth])

  useEventListener('resize', () => handleSize())

  return [setRef, widthContainer, handleSize, size]
}

export default useElementSize
