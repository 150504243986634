import i18next from 'i18next'
import moment, { isMoment } from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import DateSelect from '../../../../components/DateSelect'
import Col from '../../../../components/utils/Col'
import Field from '../../../../components/utils/Field'
import Input from '../../../../components/utils/Input'
import Row from '../../../../components/utils/Row'
import { colourStyles } from '../../../../helpers/selectStyle'
import { WithForm } from '../../../../helpers/useForm/withForm'
import store from '../../../../redux/store'
import BranchSelect from '../../../CreateServiceOrder/components/BranchSelect'

function Spacer() {
  return <hr className='spacer' />
}

class AdvancedSearch extends Component {
  beforeHandleSubmit = (event) => {
    const { values, handleSubmit } = this.props
    event.preventDefault()
    handleSubmit(values)
  }

  hasFunction17 = () => {
    const {
      auth: { user },
    } = this.props
    return user?.funcaoAcesso?.inFuncao17 === 1 || (user.lsPlants && user.lsPlants.length > 1)
  }

  getNfseAnalysisOptions = () => {
    const { t } = this.props
    return [
      { value: null, label: t('payments.option.all') },
      { value: 0, label: t('payments.invoice.send') },
      { value: 1, label: t('payments.filter.invoice.sent') },
    ]
  }

  getNfseAnalysisPaymentStatusOptions = () => {
    const { t } = this.props
    return [
      { value: null, label: t('payments.option.all') },
      { value: 0, label: t('payments.payment.pay') },
      { value: 1, label: t('payments.payment.paid') },
    ]
  }

  handleBranch = (option) => {
    const { onChangeBranch } = this.props
    if (option && option.value) {
      onChangeBranch(option.value)
      return
    }
    onChangeBranch('')
  }

  handleChangeNrOrder = ({ target: { value } }) => {
    const { onChangeNrOrder } = this.props
    const newValue = value.replace(/[^0-9]/g, '')

    onChangeNrOrder(newValue)
  }

  blockDatesAfterToday = (day) => {
    const today = moment().startOf('day')
    const currentDate = day.startOf('day')
    const setupParameters = store.getState().auth.setupParameters

    if (setupParameters?.displayTagsFromCreatedOrders) {
      if (+currentDate < +moment(setupParameters?.displayTagsFromCreatedOrders, 'L').startOf('day'))
        return true
    }

    return +currentDate > +today
  }

  startDateBlocked = (day) => {
    const { initialValues } = this.props

    const today = initialValues.endDate?.startOf('day')
    const currentDate = day.startOf('day')

    return +currentDate > +today
  }

  endDateBlocked = (day) => {
    const { initialValues } = this.props

    const today = initialValues.startDate?.startOf('day')
    const currentDate = day.startOf('day')

    return +currentDate < +today
  }

  render() {
    const {
      auth: { user },
      count,
      dispatch,
      t,
      fields,
      register,
      values,
      setFormValue,
      filterOrderNr,
      plntCd,
      onChangeGenericSearch,
      genericSearch,
      handleChangeDate,
    } = this.props

    const disabledExportCsv = count === 0

    const startDateLabel = i18next.t(
      `${i18next.t('payments.executionDates.end')} - ${i18next.t(
        'advanced.search.date.select.label.start',
      )}`,
    )

    const endDateLabel = i18next.t(
      `${i18next.t('payments.executionDates.end')} - ${i18next.t(
        'advanced.search.date.select.label.end',
      )}`,
    )

    return (
      <form onSubmit={this.beforeHandleSubmit}>
        <section className='filter-container'>
          <Row className='advanced-search-row'>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              {' '}
              <Field
                {...fields.servcOrdCd}
                className='filter-item'
                defaultActive={!!filterOrderNr}
                validate
              >
                <Input
                  {...register('servcOrdCd')}
                  value={filterOrderNr}
                  label={t('payments.serviceOrder')}
                  labelClassName='labelservcOrdCd'
                  className='payments-input'
                  onChange={(e) => this.handleChangeNrOrder(e)}
                />
              </Field>
            </Col>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <Field
                {...fields.genericSearch}
                className='filter-item'
                defaultActive={!!genericSearch}
                validate
              >
                <Input
                  {...register('genericSearch')}
                  value={genericSearch}
                  label={t('payments.genericSearch')}
                  className='payments-input'
                  onChange={(event) => onChangeGenericSearch(event.target.value)}
                />
              </Field>
            </Col>

            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <DateSelect
                {...register('startDate', {
                  parser: (e) => {
                    const value = isMoment(e) ? e : null
                    handleChangeDate(value, 'startDate')
                    return value
                  },
                })}
                label={startDateLabel}
                placeholder={startDateLabel}
                isOutsideRange={this.blockDatesAfterToday}
                isDayBlocked={this.startDateBlocked}
              />
            </Col>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <DateSelect
                {...register('endDate', {
                  parser: (e) => {
                    const value = isMoment(e) ? e : null
                    handleChangeDate(value, 'endDate')
                    return value
                  },
                })}
                label={endDateLabel}
                placeholder={endDateLabel}
                isOutsideRange={this.blockDatesAfterToday}
                isDayBlocked={this.endDateBlocked}
              />
            </Col>
          </Row>
          <Row className='advanced-search-row'>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              {this.hasFunction17() && (
                <Field {...fields.plntCd} className='filter-item'>
                  <BranchSelect
                    id='plntCd'
                    name='plntCd'
                    hasLabel
                    onChange={(value) => this.handleBranch(value)}
                    placeholder={t('serviceOrder.store')}
                    t={t}
                    user={user}
                    value={plntCd}
                    dispatch={dispatch}
                    className='select-branch'
                  />
                </Field>
              )}
            </Col>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <Field {...fields.nfAnalysisSituation} className='filter-item'>
                <Select
                  defaultValue={[{ value: null, label: t('payments.option.all') }]}
                  options={this.getNfseAnalysisOptions()}
                  placeholder={t('payments.situationNF')}
                  styles={colourStyles}
                  value={values.nfAnalysisSituation}
                  onChange={(value) => setFormValue({ nfAnalysisSituation: value })}
                />
                <label htmlFor='period' className='select-box-label-payments'>
                  {t('payments.situationNF')}
                </label>
              </Field>
            </Col>
            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <Field {...fields.nfAnalysisPaymentStatus} className='filter-item'>
                <Select
                  defaultValue={[{ value: null, label: t('payments.option.all') }]}
                  options={this.getNfseAnalysisPaymentStatusOptions()}
                  placeholder={t('payments.situationNFPay')}
                  styles={colourStyles}
                  value={values.nfAnalysisPaymentStatus}
                  onChange={(value) => setFormValue({ nfAnalysisPaymentStatus: value })}
                />
                <label htmlFor='period' className='select-box-label-payments'>
                  {t('payments.situationNFPay')}
                </label>
              </Field>
            </Col>

            <Col sm={12} lg={3} xs={12} className='margin-top-small'>
              <div className='filter-item'>
                <button className='button-hollow button-hollow-primary button-full filter-button'>
                  {t('filter_button_label')}
                </button>
              </div>
            </Col>
          </Row>

          <Row className='advanced-search-row'>
            <Col sm={12} lg={12} xs={12} className=''>
              <Spacer />
            </Col>
          </Row>

          <Row className='advanced-search-row'>
            <Col sm={12} lg={6} xs={12} className='margin-top-small'>
              <div className=' margin-top display-flex align-items-center strong italic'>
                {count > 0 && `${t('payments.filtered.1')} ${count} ${t('payments.filtered.2')}`}
              </div>
            </Col>
            <Col sm={12} lg={3} xs={12} className='margin-top-small' />
          </Row>

          <Row className='advanced-search-row'>
            <Col sm={12} lg={12} xs={12}>
              <Spacer />
            </Col>
          </Row>
        </section>
      </form>
    )
  }
}

AdvancedSearch.propTypes = {
  handleChangeDate: PropTypes.func,
  count: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFormValue: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
  onChangeNrOrder: PropTypes.func,
  onChangeNote: PropTypes.func,
  filterOrderNr: PropTypes.any,
  plntCd: PropTypes.string,
  onChangeBranch: PropTypes.func,
  genericSearch: PropTypes.string,
  onChangeGenericSearch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
}

const FIELDS = {
  servcOrdCd: '',
  plntCd: '',
  nfAnalysisSituation: undefined,
  nfAnalysisPaymentStatus: undefined,
  endDate: null,
  startDate: null,
  hasFilters: false,
  genericSearch: '',
}

export default WithForm(FIELDS)(AdvancedSearch)

export { AdvancedSearch }
