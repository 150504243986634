import i18next from 'i18next'

import { useServiceOrderDetailContext } from '../../../../context'
import User from '../../../../../../core/User'

function SellerInfo() {
  const { serviceOrderBody, loadingBody } = useServiceOrderDetailContext()

  if (loadingBody) return <div id='seller-info' className='skeleton' />
  let sellerName = '-'
  if (serviceOrderBody?.creationOperatorFirstName) {
    sellerName = `${serviceOrderBody?.creationOperatorFirstName} ${serviceOrderBody?.creationOperatorLastName}`
  }

  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList
  const showSellerInfo = accessFunctionList?.includes(146)

  return showSellerInfo ? (
    <div id='seller-info'>
      <label htmlFor=''>{i18next.t('service.order.details.seller')}</label>
      <section>
        <span className='seller-name-text'>{sellerName}</span>
      </section>
    </div>
  ) : null
}

export default SellerInfo
