import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'

import store from '../../../redux/store'
import { hideSpinner, showSpinner } from '../../../redux/actions/spinner'

const user = new User().currentUser

export const moveToExistingWorksite = async (servcOrdSeq: number, newWorksite: number) => {
  store.dispatch(showSpinner())

  const query = {
    servcOrdSeq,
    servcOrdWorksiteNumber: newWorksite,
  }

  try {
    const response = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.MOVE_TO_EXISTING,
      query,
    )
    return response.data
  } catch (error) {
    return error
  } finally {
    store.dispatch(hideSpinner())
  }
}
