import { memo } from 'react'
import i18next from 'i18next'
import { useAdvancedSearchContext } from '../../../../../../../../contexts/advancedSearch'

import Accordeon from '../../../../../../../../components/Accordeon'
import ColumnSelect from './components/ColumnSelect'
import DirectionRadioGroup from './components/DirectionRadioGroup'
import MozaicIcon from '../../../../../../../../components/MozaicIcon'

function SortBox() {
  const {
    toggleState: { showAllDetails, handleToggle },
    sortState: { column, setColumn, direction, setDirection },
  } = useAdvancedSearchContext()

  const title = {
    title: i18next.t('advanced.search.sort.results.title'),
    icon: <MozaicIcon icon='AfterSalesPayback24' className='icon-custom' />,
    hideDetails: 'advanced.search.hide.extra.options',
    showDetails: 'advanced.search.show.extra.options',
  }

  return (
    <div className='sort-box'>
      <Accordeon header={title} styled={false} onToggle={handleToggle} defaultOpen={showAllDetails}>
        <div className='input-group'>
          <ColumnSelect value={column} onChange={setColumn} />
          <DirectionRadioGroup value={direction} onChange={setDirection} />
        </div>
      </Accordeon>
    </div>
  )
}

export default memo(SortBox)
