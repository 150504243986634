import { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from './Select'
import { connect } from 'react-redux'

import Api from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'

class DocumentTypeSelect extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.array,
    controlled: PropTypes.bool,
  }

  state = {
    data: [],
    error: null,
    loading: false,
  }

  componentDidMount() {
    const { controlled } = this.props

    if (!controlled) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_DOCUMENT_TYPES_LIST)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess = (data) => {
    this.setState({ data, loading: false, error: null })
  }

  onFetchError = (error) => {
    this.setState({ error, loading: false, data: [] })
  }

  renderOptions = (item) => (
    <option key={item.atchmtDocmntTyp} value={item.atchmtDocmntTyp}>
      {item.atchmtDocmntTypDesc}
    </option>
  )

  render() {
    const { id, name, t, controlled, data: parentData, ...props } = this.props
    const { data, loading } = this.state

    const label = loading ? t('option.loading') : t('documentType.select')
    const options = controlled ? parentData : data

    return (
      <Select id={id || name} name={name} label={label} {...props}>
        <option value='' />

        {options.map(this.renderOptions)}
      </Select>
    )
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
})

/*
 * Conecta componente ao I18next
 */
const DocumentTypeSelectTranslated = withTranslation()(DocumentTypeSelect)

/*
 * Export componente conectado ao redux
 */
export default connect(mapStateToProps)(DocumentTypeSelectTranslated)

/*
 * Export somente do componente
 */
export { DocumentTypeSelect }
