/* eslint-disable react/jsx-no-constructed-context-values */
import i18next from 'i18next'
import moment from 'moment-timezone'
import { createContext, useContext, useEffect, useState } from 'react'
import API_URL from '../../../../../core/Envs/endpoints'
import Api from '../../../../../core/Http/FetchAdapter'

import { REASONS_TYPES } from '../../../../../constants/config'
import { Reason, _shifts } from '../../../../../models/Distribute'
import { getReasonsList } from '../../../../../services/pages/ServiceOrderDetail/getReasonsList'

type RescheduleContextType = {
  periods: Array<any>
  allowedDates: Array<any>
  reasons: Reason[]
}

type RescheduleProviderType = {
  children: any
  serviceOrder: any
  selectedDate: any
  selectedPeriod: any
  setSelectedDate: any
  setSelectedPeriod: any
  show: boolean
}

const RescheduleContext = createContext<RescheduleContextType | null>(null)

function RescheduleProvider(props: RescheduleProviderType) {
  const {
    children,
    serviceOrder,
    selectedDate,
    setSelectedDate,
    setSelectedPeriod,
    selectedPeriod,
    show,
  } = props

  const [allowedDates, setAllowedDates] = useState<Array<any>>([])
  const [periods, setPeriods] = useState<Array<any>>([])
  const [dates, setDates] = useState<Array<any>>([])
  const [reasons, setReasons] = useState<Reason[]>([])

  const currentSchedule = moment(serviceOrder?.servcOrdSchdlgTs).startOf('day')

  const currentSlot = {
    label: i18next.t(serviceOrder?.dsSlot),
    value: _shifts[serviceOrder?.idSlotApplication as keyof typeof _shifts],
  }

  const parseSlots = (slots: any) =>
    slots.map((slot: any) => ({
      label: slot.dsSlot,
      value: slot.idSlot,
    }))

  const onChangeDate = (date: any) => {
    const dateFormated = moment(date).format('YYYY-MM-DD')
    const { slots } = dates.find((date) => (date.date === dateFormated ? date : false))
    const parsedSlots = parseSlots(slots)

    setPeriods(parsedSlots)
  }

  const fetchDatesSuccess = async (data: any) => {
    const allowedDates = data.map((date: any) => date.date)

    let dateFormated: any = null

    if (currentSchedule) dateFormated = currentSchedule.format('YYYY-MM-DD')

    const dateSelected = data.find((item: any) => item.date === dateFormated)

    if (!dateSelected) {
      allowedDates.push(dateFormated)
      data.push({
        date: dateFormated,
        slots: [
          {
            idSlotApplication: serviceOrder?.idSlotApplication,
            idSlot: serviceOrder?.idSlot,
            dsSlot: serviceOrder?.dsSlot,
          },
        ],
      })
    }
    setAllowedDates(allowedDates)
    setDates(data)
  }

  const loadReasons = async () => {
    const list: Reason[] = (await getReasonsList()).data
    const filteredReasons = list.filter(
      (r) => r.actvOrdEvntTypRsnInd === '1' && r.servcOrdEvntTyp === REASONS_TYPES.RESCHEDULE,
    )
    setReasons(filteredReasons)
  }

  const loadCalendarDates = async () => {
    const day = currentSchedule.format('DD')
    const year = currentSchedule.format('YYYY')
    const month = currentSchedule.format('MM')
    const query = {
      dtStart: `${day}/${month}/${year}`,
      idCalendarApplication: serviceOrder?.plntServcCalndrCd,
    }

    try {
      const response = await new Api().get(API_URL.GET_SCHEDULE_DATES, query)
      fetchDatesSuccess(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (serviceOrder && show) loadCalendarDates()
    loadReasons()
  }, [serviceOrder, show])

  useEffect(() => {
    if (dates.length && !selectedDate) setSelectedDate(currentSchedule)
  }, [dates])

  useEffect(() => {
    if (periods.length) {
      if (selectedPeriod) setSelectedPeriod(periods[0])
      if (!selectedPeriod) setSelectedPeriod(currentSlot)
    }
  }, [periods])

  useEffect(() => {
    if (selectedDate) onChangeDate(selectedDate)
  }, [selectedDate])

  return (
    <RescheduleContext.Provider value={{ allowedDates, periods, reasons }}>
      {children}
    </RescheduleContext.Provider>
  )
}

const useRescheduleContext = () => useContext(RescheduleContext) as RescheduleContextType

export { RescheduleContext, RescheduleProvider, useRescheduleContext }
