import { TextField } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { useState } from 'react'
import Mask from 'vanilla-masker'
import MozaicButton from '../../../../../../../../../components/MozaicButton'
import MozaicText from '../../../../../../../../../components/MozaicText'
import Modal from '../../../../../../../../../components/utils/Modal'
import User from '../../../../../../../../../core/User'
import {
  renderToastError,
  renderToastSuccessMsg,
} from '../../../../../../../../../helpers/ToastUtils'
import { ServiceOrderEntity } from '../../../../../../../../../models/ServiceOrder'
import { addReworkValue } from '../../../../../../../../../redux/actions/serviceOrder'
import store from '../../../../../../../../../redux/store'
import { WithContext } from './context'

import ClearIcon from '../../../../../../../../../assets/icons/clear-circle.svg?react'

type ReworkValueModalType = {
  setModalState: Function
  serviceOrder: ServiceOrderEntity
  reloadServiceOrder: Function
}

const user = new User().currentUser

function ReworkValueModal({
  serviceOrder,
  reloadServiceOrder,
  setModalState,
}: ReworkValueModalType) {
  const servcOrdSeq = serviceOrder?.servcOrdSeq
  const maskedZero = Mask.toMoney(0, { unit: 'R$' })
  const reworkValue = serviceOrder?.warranty?.reworkValue
  const currentValue = reworkValue?.toFixed(parseInt(i18next.t('size.currency'))) || ''
  const currentMaskedValue = Mask.toMoney(currentValue, { unit: 'R$' })

  const [value, setValue] = useState<string | any>(currentMaskedValue)

  const handleSubmit = async () => {
    const newValue = value.match(/[0-9]/g).join('') / 100

    try {
      const submit = addReworkValue(user.dsToken, servcOrdSeq, {
        reworkValue: newValue,
      })
      await submit(store.dispatch)

      renderToastSuccessMsg(i18next.t('warrantyActivated.reworkValue.success'))

      await reloadServiceOrder()
    } catch (error: any) {
      renderToastError(error.message.dsMessage)
    }

    setModalState(false)
  }

  const warrantyText = i18next.t('warranty.rework.modal.info.text')
  const confirmText = i18next.t('customer.service.order.confirm.button')
  const modalTitle = i18next.t('warranty.rework.modal.title')
  const fieldLabel = i18next.t('warranty.rework.modal.input.label')
  const cancelText = i18next.t('button.label.cancel')

  const maskedValue = Mask.toMoney(value, { unit: 'R$' })

  const disableSubmit = currentMaskedValue === maskedValue

  const suffixIcons = () => (
    <>
      {value !== 0 && value !== maskedZero && (
        <div className='clear-filter' onClick={() => setValue(0)}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  return (
    <Modal
      className='new-budget-modal'
      title={modalTitle}
      closeModal={() => setModalState(false)}
      size='sm'
      showCloseButton
    >
      <div className='row'>
        <div className='col-xs-12 mt-3'>
          <MozaicText>{warrantyText}</MozaicText>
        </div>
        <div className='col-xs-12 mt-3'>
          <TextField
            maxLength={15}
            value={maskedValue}
            label={fieldLabel}
            autoComplete='off'
            onChange={(event: any) => setValue(event.target.value)}
            hasSuffix
            renderSuffix={suffixIcons}
          />
        </div>
        <div className='col-xs-12 mt-3 action-buttons'>
          <MozaicButton variant='ghost' theme='primary-02' onClick={() => setModalState(false)}>
            {cancelText}
          </MozaicButton>
          <MozaicButton isDisabled={disableSubmit} onClick={() => handleSubmit()}>
            {confirmText}
          </MozaicButton>
        </div>
      </div>
    </Modal>
  )
}

export default WithContext(ReworkValueModal)
