import PropTypes from 'prop-types'
import { Component } from 'react'
import Lightbox from 'react-images'

import './index.scss'

class Images extends Component {
  state = {
    lightboxIsOpen: false,
    lightboxCurrentImage: 0,
  }

  lightboxSetIndex = (lightboxCurrentImage) => {
    this.setState({
      lightboxIsOpen: true,
      lightboxCurrentImage,
    })
  }

  lightboxToggle = () => {
    const { lightboxIsOpen } = this.state
    this.setState({ lightboxIsOpen: !lightboxIsOpen })
  }

  lightboxPrev = () => {
    const { lightboxCurrentImage } = this.state
    this.setState({ lightboxCurrentImage: lightboxCurrentImage - 1 })
  }

  lightboxNext = () => {
    const { lightboxCurrentImage } = this.state
    this.setState({ lightboxCurrentImage: lightboxCurrentImage + 1 })
  }

  getUrl = ({ servcOrdAtchmtDcmntIdNr }) => `url(data:image;base64,${servcOrdAtchmtDcmntIdNr})`

  getSrc = ({ servcOrdAtchmtDcmntIdNr }) => `data:image;base64, ${servcOrdAtchmtDcmntIdNr}`

  renderLightBox = () => {
    const { lightboxIsOpen, lightboxCurrentImage } = this.state

    const { images, t } = this.props

    return (
      <Lightbox
        images={
          images
            ? images.map((img) => ({
                src: `data:image/png;base64,${img.servcOrdAtchmtDcmntIdNr}`,
              }))
            : []
        }
        isOpen={lightboxIsOpen}
        currentImage={lightboxCurrentImage}
        onClose={this.lightboxToggle}
        onClickPrev={this.lightboxPrev}
        onClickNext={this.lightboxNext}
        onClickThumbnail={this.lightboxSetIndex}
        imageCountSeparator={t('option.of')}
        showThumbnails
        spinnerColor='green'
        backdropClosesModal
        showCloseButton={false}
      />
    )
  }

  renderThumbnails = (image, index) => {
    const { isServiceStart } = this.props
    // eslint-disable-next-line no-param-reassign
    image.src = this.getSrc(image)
    return (
      <div className='div-thumbnail'>
        <a
          className='image-thumbnail'
          key={image.servcOrdItemAtachmtId}
          style={{ backgroundImage: this.getUrl(image) }}
          onClick={() => {
            this.lightboxSetIndex(index)
          }}
        />
        {isServiceStart && image.imgCaption ? (
          <h6 className='image-label-align'>{image.imgCaption}</h6>
        ) : (
          <h6 className='image-label-align'>
            <br />
          </h6>
        )}
      </div>
    )
  }

  render() {
    const { images } = this.props
    return (
      <div className='images-thubnails'>
        {(images || []).map(this.renderThumbnails)}
        {this.renderLightBox()}
      </div>
    )
  }
}

Images.propTypes = {
  t: PropTypes.func.isRequired,
  images: PropTypes.array,
  isServiceStart: PropTypes.bool,
}

export default Images
