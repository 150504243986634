// Service Orders
export const FETCH_SERVICE_ORDERS_REQUEST = 'FETCH_SERVICE_ORDERS_REQUEST'
export const FETCH_SERVICE_ORDERS_SUCCESS = 'FETCH_SERVICE_ORDERS_SUCCESS'
export const FETCH_SERVICE_ORDERS_ERROR = 'FETCH_SERVICE_ORDERS_ERROR'
export const UPDATE_SERVICE_ORDER_REQUEST = 'UPDATE_SERVICE_ORDER_REQUEST'
export const UPDATE_SERVICE_ORDER_SUCCESS = 'UPDATE_SERVICE_ORDER_SUCCESS'
export const UPDATE_SERVICE_ORDER_ERROR = 'UPDATE_SERVICE_ORDER_ERROR'
export const GET_SERVICE_ORDER_REQUEST = 'GET_SERVICE_ORDER_REQUEST'
export const GET_SERVICE_ORDER_SUCCESS = 'GET_SERVICE_ORDER_SUCCESS'
export const GET_SERVICE_ORDER_ERROR = 'GET_SERVICE_ORDER_ERROR'
export const SET_SERVICE_ORDER = 'SET_SERVICE_ORDER'
export const MERGE_WITH_SERVICE_ORDER = 'MERGE_WITH_SERVICE_ORDER'
export const VIEW_SERVICE_ORDER_FROM_LIST = 'VIEW_SERVICE_ORDER_FROM_LIST'
export const GET_SERVICE_ORDER_FROM_LIST_ERROR = 'GET_SERVICE_ORDER_FROM_LIST_ERROR'
export const RESET_SERVICE_ORDERS = 'RESET_SERVICE_ORDERS'
export const RESET_EDIT_SERVICE_ORDER = 'RESET_EDIT_SERVICE_ORDER'
export const CLEAR_SERVICE_ORDER_RESPONSE = 'CLEAR_SERVICE_ORDER_RESPONSE'
export const FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS = 'FETCH_SERVICE_ORDER_WITH_REPORT_SUCCESS'
export const FETCH_SERVICE_ORDER_IMAGES_SUCCESS = 'FETCH_SERVICE_ORDER_IMAGES_SUCCESS'
export const FETCH_SERVICE_ORDERS_FROM_MENU = 'FETCH_SERVICE_ORDERS_FROM_MENU'
export const FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE = 'FETCH_SERVICE_ORDERS_FROM_SOMEWHERE_ELSE'
export const CLEAR_SERVICE_ORDER_LIST = 'CLEAR_SERVICE_ORDER_LIST'
export const SAVE_FILTERS = 'SAVE_FILTERS'
export const IS_BACK_ORDER = 'IS_BACK_ORDER'
export const SAVE_SO_SCROLL_POSITION = 'SAVE_SO_SCROLL_POSITION'
export const SHOULD_RESTORE_SCROLL = 'SHOULD_RESTORE_SCROLL'
export const GENERATE_OCCURRENCE_REQUEST = 'GENERATE_OCCURRENCE_REQUEST'
export const GENERATE_OCCURRENCE_SUCCESS = 'GENERATE_OCCURRENCE_SUCCESS'
export const GENERATE_OCCURRENCE_ERROR = 'GENERATE_OCCURRENCE_ERROR'
export const RECORD_CALL_REQUEST = 'RECORD_CALL_REQUEST'
export const RECORD_CALL_SUCCESS = 'RECORD_CALL__SUCCESS'
export const RECORD_CALL_ERROR = 'RECORD_CALL_ERROR'
export const CLEAR_SERVICE_ORDER_REWORK = 'CLEAR_SERVICE_ORDER_REWORK'
export const FIND_SHORT_LINK_REQUEST = 'FIND_SHORT_LINK_REQUEST'
export const FIND_SHORT_LINK_SUCCESS = 'FIND_SHORT_LINK_SUCCESS'
export const FIND_SHORT_LINK_ERROR = 'FIND_SHORT_LINK_ERROR'
export const SEND_MSG_TERMS_REQUEST = 'SEND_MSG_TERMS_REQUEST'
export const SEND_MSG_TERMS_SUCCESS = 'SEND_MSG_TERMS_SUCCESS'
export const SEND_MSG_TERMS_ERROR = 'SEND_MSG_TERMS_ERROR'
export const SEND_MSG_CONFORMITY_REQUEST = 'SEND_MSG_CONFORMITY_REQUEST'
export const SEND_MSG_CONFORMITY_SUCCESS = 'SEND_MSG_CONFORMITY_SUCCESS'
export const SEND_MSG_CONFORMITY_ERROR = 'SEND_MSG_CONFORMITY_ERROR'

// Service Order attchments
export const FETCH_SERVC_ORD_ATCHMT_REQUEST = 'FETCH_SERVC_ORD_ATCHMT_REQUEST'
export const FETCH_SERVC_ORD_ATCHMT_SUCCESS = 'FETCH_SERVC_ORD_ATCHMT_SUCCESS'
export const FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS = 'FETCH_SERVC_ORD_MEASUREMENT_FILES_SUCCESS'
export const FETCH_SERVC_ORD_ATCHMT_ERROR = 'FETCH_SERVC_ORD_ATCHMT_ERROR'
export const SAVE_SERVC_ORD_ATCHMT_REQUEST = 'SAVE_SERVC_ORD_ATCHMT_REQUEST'
export const SAVE_SERVC_ORD_ATCHMT_SUCCESS = 'SAVE_SERVC_ORD_ATCHMT_SUCCESS'
export const SAVE_SERVC_ORD_ATCHMT_ERROR = 'SAVE_SERVC_ORD_ATCHMT_ERROR'
export const DELETE_SERVC_ORD_ATCHMT_REQUEST = 'DELETE_SERVC_ORD_ATCHMT_REQUEST'
export const DELETE_SERVC_ORD_ATCHMT_SUCCESS = 'DELETE_SERVC_ORD_ATCHMT_SUCCESS'
export const DELETE_SERVC_ORD_ATCHMT_ERROR = 'DELETE_SERVC_ORD_ATCHMT_ERROR'
export const DELETE_SERVC_ORD_ATCHMT_BUDGET_REQUEST = 'DELETE_SERVC_ORD_ATCHMT_BUDGET_REQUEST'
export const DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS =
  'DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS'
export const DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR =
  'DELETE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR'
export const DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS =
  'DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS'
export const DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR =
  'DELETE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR'
export const SAVE_SERVC_ORD_ATCHMT_BUDGET_REQUEST = 'SAVE_SERVC_ORD_ATCHMT_BUDGET_REQUEST'
export const SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS =
  'SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_SUCCESS'
export const SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR = 'SAVE_SERVC_ORD_ATCHMT_BUDGET_CLIENT_ERROR'
export const SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS =
  'SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_SUCCESS'
export const SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR = 'SAVE_SERVC_ORD_ATCHMT_BUDGET_STORE_ERROR'
export const SERVC_ORD_BUDGETS_ACCEPTED_OPTION = 'SERVC_ORD_BUDGETS_ACCEPTED_OPTION'
export const SERVC_ORD_BUDGETS_DENIED_OPTION = 'SERVC_ORD_BUDGETS_DENIED_OPTION'
export const SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR = 'SERVC_ORD_BUDGETS_DENIED_OPTION_CLEAR'
export const SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED = 'SERVC_ORD_BUDGETS_DISTRIBUTED_SELECTED'
export const SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED =
  'SERVC_ORD_BUDGETS_DISTRIBUTED_NOT_SELECTED'

// Service Order Statuses
export const FETCH_SERVICE_ORDER_STATUSES_REQUEST = 'FETCH_SERVICE_ORDER_STATUSES_REQUEST'
export const FETCH_SERVICE_ORDER_STATUSES_SUCCESS = 'FETCH_SERVICE_ORDER_STATUSES_SUCCESS'
export const FETCH_SERVICE_ORDER_STATUSES_ERROR = 'FETCH_SERVICE_ORDER_STATUSES_ERROR'

// Service Orders Occurrences
export const CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST = 'CREATE_SERVICE_ORDER_OCCURRENCE_REQUEST'
export const CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS = 'CREATE_SERVICE_ORDER_OCCURRENCE_SUCCESS'
export const CREATE_SERVICE_ORDER_OCCURRENCE_ERROR = 'CREATE_SERVICE_ORDER_OCCURRENCE_ERROR'

// menu Shortcut
export const UPDATE_OS_MENU_SHORTCUT_REQUEST = 'UPDATE_OS_MENU_SHORTCUT_REQUEST'
export const UPDATE_OS_MENU_SHORTCUT_SUCCESS = 'UPDATE_OS_MENU_SHORTCUT_SUCCESS'
export const UPDATE_OS_MENU_SHORTCUT_ERROR = 'UPDATE_OS_MENU_SHORTCUT_ERROR'

export const BACK_STATUS_WITH_OCCURRENCE_REQUEST = 'BACK_STATUS_WITH_OCCURRENCE_REQUEST'
export const BACK_STATUS_WITH_OCCURRENCE_SUCCESS = 'BACK_STATUS_WITH_OCCURRENCE_SUCCESS'
export const BACK_STATUS_WITH_OCCURRENCE_ERROR = 'BACK_STATUS_WITH_OCCURRENCE_ERROR'

export const LINK_SERVICE_ORDERS_FILTERS = 'LINK_SERVICE_ORDERS_FILTERS'
export const CANCEL_SERVICE_ORDER_REQUEST = 'CANCEL_SERVICE_ORDER_REQUEST'

export const CANCEL_SERVICE_ORDER_SUCCESS = 'CANCEL_SERVICE_ORDER_SUCCESS'
export const CANCEL_SERVICE_ORDER_ERROR = 'CANCEL_SERVICE_ORDER_ERROR'
export const UPDATE_SERVC_ORD_OBSERVATION_RESET = 'UPDATE_SERVC_ORD_OBSERVATION_RESET'

export const UPDATE_SERVC_ORD_OBSERVATION_REQUEST = 'UPDATE_SERVC_ORD_OBSERVATION_REQUEST'
export const UPDATE_SERVC_ORD_OBSERVATION_SUCCESS = 'UPDATE_SERVC_ORD_OBSERVATION_SUCCESS'
export const UPDATE_SERVC_ORD_OBSERVATION_ERROR = 'UPDATE_SERVC_ORD_OBSERVATION_ERROR'
