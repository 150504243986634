import { View } from '@mozaic-ds/react'
import i18next from 'i18next'
import { Page } from '../../components/Page'
import { DataTable } from './components/Datatable'
import { Filters } from './components/Filters'
import { Header } from './components/Header'
import { ServiceProvidersListV2Provider } from './contexts'

/**
 * @description Service Providers List V2 page
 */

function ServiceProvidersListV2() {
  return (
    <Page title={i18next.t('list.of.providers')}>
      <ServiceProvidersListV2Provider>
        <Header />
        <Filters />
        <View paddingTop='mu200'>
          <DataTable />
        </View>
      </ServiceProvidersListV2Provider>
    </Page>
  )
}

export default ServiceProvidersListV2
