import { RadioGroup } from '@leroy-merlin-br/backyard-react'
import i18next from 'i18next'
import { Moment } from 'moment-timezone'
import { useEffect, useState } from 'react'
import MozaicCheckBox from '../../../../../../components/MozaicCheckBox'
import { useServiceOrderContext } from '../../../../../../contexts/serviceOrder'
import User from '../../../../../../core/User'
import ServiceOrderTagType from '../../../../../../enums/ServiceOrder/tags/ServiceOrderTagType'
import store from '../../../../../../redux/store'
import { useDistributeContext } from '../../context'
import { DistributeModeOptions } from '../../types'

type JustifyInputType = {
  selectedDate: Moment
  onChange: Function
  value: string
  compareTodayDate: boolean
  compareDeliveryDate: boolean
  providersOutsideAreaValue: boolean
  onChangeProvidersOutsideAreaValue: (checked: boolean) => void
}

const user = new User().currentUser

function ModeSelect(props: JustifyInputType) {
  const {
    onChange,
    value,
    selectedDate,
    compareTodayDate,
    compareDeliveryDate,
    providersOutsideAreaValue,
    onChangeProvidersOutsideAreaValue,
  } = props

  const setupParameters = store.getState().auth.setupParameters

  const { loadProviderOptions, setInstallers, setProviders } = useDistributeContext()
  const { serviceOrder }: any = useServiceOrderContext()
  const { accessFunctionList } = user

  const [providersOutsideAreaCheckBox, setProvidersOutsideAreaCheckBox] =
    useState(providersOutsideAreaValue)

  const hasTagManual = serviceOrder.serviceOrderTags.some(
    (tag: any) => tag.tagType === ServiceOrderTagType.MANUAL_DISTRIBUTED.tagName,
  )
  const hasBidOption = hasTagManual && accessFunctionList.includes(117)

  const disabled = !(compareDeliveryDate && compareTodayDate && selectedDate)

  const verifyMacroRegionalityParam = () => {
    const states = (setupParameters.macroRegionalStates as string)?.split(';') || []
    return serviceOrder?.servcAddrStCd && states.includes(serviceOrder.servcAddrStCd)
  }

  const showProvidersOutsideAreaCheckbox =
    value === DistributeModeOptions.manual &&
    verifyMacroRegionalityParam() &&
    accessFunctionList.includes(134)

  const options = [
    hasBidOption && {
      children: i18next.t('service.detail.distribute.modal.mode.option1'),
      value: DistributeModeOptions.bid,
      disabled,
    },
    false && {
      children: i18next.t('service.detail.distribute.modal.mode.option2'),
      value: DistributeModeOptions.matchmaking,
      disabled,
    },
    {
      children: i18next.t('service.detail.distribute.modal.mode.option3'),
      value: DistributeModeOptions.manual,
      disabled,
    },
  ].filter(Boolean)

  useEffect(() => {
    setProvidersOutsideAreaCheckBox(false)
    onChangeProvidersOutsideAreaValue(false)
  }, [])

  const handleChange = (e: any) => {
    setProvidersOutsideAreaCheckBox(false)
    onChangeProvidersOutsideAreaValue(false)

    if (e.target.value === DistributeModeOptions.manual) {
      loadProviderOptions()
    } else {
      setInstallers([])
      setProviders([])
    }

    onChange(e)
  }

  const handleChangeProvidersOutsideArea = () => {
    const checked = !providersOutsideAreaCheckBox
    loadProviderOptions(undefined, undefined, checked)
    setProvidersOutsideAreaCheckBox(checked)
    onChangeProvidersOutsideAreaValue(checked)
  }

  const label = `${i18next.t('service.detail.distribute.modal.mode')}:`

  return (
    <section className='mode-select'>
      <label className='mode-select-label'>{label}</label>
      <RadioGroup
        label={i18next.t('data.aggregation.text')}
        name='radio-group'
        value={value}
        options={options}
        onChange={(e) => handleChange(e)}
      />

      {showProvidersOutsideAreaCheckbox && (
        <MozaicCheckBox
          isChecked={providersOutsideAreaCheckBox}
          label={i18next.t('service.detail.distribute.modal.showProvidersOutsideArea')}
          onChange={handleChangeProvidersOutsideArea}
        />
      )}
    </section>
  )
}

export default ModeSelect
