import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { IS_PRODUCTION } from '../../constants/viteEnvironment'
import ErrorFallback from '../fallbacks/container'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    if (IS_PRODUCTION) {
      console.log({ error, errorInfo })
    }
  }

  render() {
    const { FallbackComponent = ErrorFallback, resetErrorBoundary, children } = this.props
    const { hasError, error } = this.state

    if (hasError) return <FallbackComponent error={error} resetErrorBoundary={resetErrorBoundary} />

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  FallbackComponent: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
}

export default ErrorBoundary
