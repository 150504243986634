import { useMemo, useState } from 'react'
import {
  fetchUnnacomplishedReasonsList,
  fetchReasonsList as getReasonsList,
} from '../../services/Reasons'

export type ReasonsType = {
  fetchUnnacomplishedReasonsList: Function
  unnacomplishedReasonsList: Array<any>
  fetchReasonsList: Function
  reasons: Array<any>
  loadingUnfinish: boolean
  loadingReasons: boolean
}

export function useReasons() {
  const [unfinishReasons, setUnfinishReasons] = useState([])
  const [loadingUnfinish, setLoadingUnfinish] = useState(false)

  const [reasons, setReasons] = useState([])
  const [loadingReasons, setLoadingReasons] = useState(false)

  const fetchUnfinishReasonsList = async () => {
    setLoadingUnfinish(true)

    const reasons = await fetchUnnacomplishedReasonsList()
    setUnfinishReasons(reasons)

    setLoadingUnfinish(false)

    return reasons
  }

  const fetchReasonsList = async (
    token = null,
    page = 1,
    query = {},
    size = true,
    pagination = true,
  ) => {
    setLoadingReasons(true)

    const reasons = await getReasonsList(token, page, query, size, pagination)
    setReasons(reasons)

    setLoadingReasons(false)

    return reasons
  }

  const result = useMemo(
    () => ({
      fetchUnnacomplishedReasonsList: fetchUnfinishReasonsList,
      unnacomplishedReasonsList: unfinishReasons,
      loadingUnfinish,
      fetchReasonsList,
      loadingReasons,
      reasons,
    }),
    [reasons, unfinishReasons],
  )

  return result
}
