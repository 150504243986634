import AdvancedSearchInput from './components/AdvancedSearchInput'
import AdvancedSearchModal from './components/AdvancedSearchModal'

/*
  Description component: container fixed on app bar to search service orders by filters
*/
function AdvancedSearchContainer() {
  return (
    <div id='advanced-search-container'>
      <div className='advanced-search-input-desktop'>
        <AdvancedSearchInput />
      </div>
      <AdvancedSearchModal />
    </div>
  )
}

export default AdvancedSearchContainer
