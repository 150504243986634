import PropTypes from 'prop-types'
import { useForm } from '../../../../helpers/useForm'

import MozaicButton from '../../../../components/MozaicButton'
import MozaicPasswordInput from '../../../../components/MozaicPasswordInput'
import { TextField } from '@leroy-merlin-br/backyard-react'

const fields = { dsLogin: '', dsPassword: '' }

function LoginForm(props) {
  const { t, loginUser, error } = props

  const { values, register } = useForm(fields)
  const { dsLogin, dsPassword } = values

  const dsLoginError = error?.fields?.filter((field) => field.naField === 'dsLogin') || []
  const dsPasswordError = error?.fields?.filter((field) => field.naField === 'dsPassword') || []

  const handleSubmit = () => {
    const values = [{ dsLogin, dsPassword }]

    loginUser(values)
  }

  return (
    <div id='form-login-container'>
      <TextField
        {...register('dsLogin', { required: true })}
        hint={dsLoginError[0]?.dsDetail}
        label='Usuário'
        placeholder={t('Digite o seu LDAP')}
      />

      <MozaicPasswordInput
        {...register('dsPassword', { required: true })}
        error={dsPasswordError[0]?.dsDetail}
        label={t('pwa.login.password.label')}
        placeholder={t('pwa.login.password.placeholder')}
        canShow
      />

      <MozaicButton onClick={() => handleSubmit()}>
        {t('useraccess.login.login_button')}
      </MozaicButton>
    </div>
  )
}

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  error: PropTypes.array,
}

export default LoginForm

export { LoginForm }
