import i18next from 'i18next'
import classNames from 'classnames'

import ComplementaryProduct from './components/ComplementaryProduct'
import AssociatedProduct, { ResaleProd } from './components/AssociatedProduct'
import TableFooter from './components/Footer'
import ServiceItem from './components/ServiceItem'
import MozaicText from '../../../../../../../../../components/MozaicText'
import ErrorBoundary from '../../../../../../../../../errors/boundaries'
import ServiceOrderStatusType from '../../../../../../../../../enums/ServiceOrder/status/ServiceOrderStatusType'

import { usableArea } from '../../../../../../../../../constants/breakpoints'
import { useUsableAreaContext } from '../../../../../../../../../contexts/usableArea'
import { HusbandRentProvider } from './components/HusbandRent/context'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'
import { WithContext } from '../../context'

const headerItens = [
  { hide: false, label: 'options.registration.services' },
  { hide: true, label: 'servcOrd.evaluation.tableHeadQuantity' },
  { hide: true, label: 'serviceOrders.value' },
  { hide: true, label: 'serviceOrders.valueTransfer' },
]

function DetailsTable({
  orderDetails,
  serviceOrderBody,
  loadingBody,
  loadingDetails,
  reloadServiceOrder,
}: ServiceOrderDetailStore) {
  const { widthContainer } = useUsableAreaContext()

  const servicesList = orderDetails?.listServcOrdItem || []
  const servicesComplementaryList = orderDetails?.listServcOrdAuxlryResaleProd || []
  const isFetching = loadingBody && loadingDetails
  const isCanceled = serviceOrderBody?.servcOrdStusCd === ServiceOrderStatusType.STATUS_CANCELADA.id
  const isFinished =
    (serviceOrderBody?.servcOrdStusCd || 0) >=
    ServiceOrderStatusType.STATUS_REALIZADO_COM_SUCESSO.id

  const isDesktop = widthContainer > usableArea.medium

  return (
    <HusbandRentProvider>
      <div id='details-table-container'>
        <ErrorBoundary
          FallbackComponent={<MozaicText>{i18next.t('fallback.page.error.subtitle')}</MozaicText>}
          resetErrorBoundary={() => reloadServiceOrder()}
        >
          <header>
            {headerItens.map((item, key) => (
              <div key={key} className='table-header-item'>
                <span className={classNames({ 'hide-mobile': item.hide })}>
                  {i18next.t(item.label)}
                </span>
              </div>
            ))}
          </header>

          <div className='details-table-body'>
            {servicesList.map((service, i) => (
              <span key={service.servcCd + service.servcOrdItemNr}>
                <ServiceItem
                  service={service}
                  isFetching={isFetching}
                  isCanceled={isCanceled}
                  isFinished={isFinished}
                />
                {i + 1 !== servicesList.length && <hr className='divider-products' />}
              </span>
            ))}

            {servicesList.map((service, i) => (
              <span key={service.servcOrdItemNr}>
                {i === 0 && !!service.servcResaleProdCd && <hr className='divider-table' />}
                <AssociatedProduct
                  service={service as ResaleProd}
                  isCanceled={isCanceled}
                  isDesktop={isDesktop}
                />
                {i + 1 !== servicesList.length && !!service.servcResaleProdCd && (
                  <hr className='divider-products' />
                )}
              </span>
            ))}

            {!!servicesComplementaryList.length && (
              <>
                <hr className='divider-products' />
                {servicesComplementaryList.map((service, i) => (
                  <span key={service.auxlryResaleProdCd}>
                    <ComplementaryProduct
                      service={service}
                      key={service.servcOrdItemNr}
                      servcOrdItemQtyReturned={service.servcOrdItemQtyReturned}
                      servcOrdItemQtyAcquired={service.servcOrdItemQtyAcquired}
                      isCanceled={isCanceled}
                      isDesktop={isDesktop}
                    />
                    {i + 1 !== servicesComplementaryList.length && (
                      <hr className='divider-products' />
                    )}
                  </span>
                ))}
              </>
            )}

            <hr className='divider-table' />
          </div>

          <TableFooter isCanceled={isCanceled} />

        </ErrorBoundary>
      </div>
    </HusbandRentProvider>
  )
}

export default WithContext(DetailsTable)
