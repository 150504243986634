import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'

function ProductData({ service }: { service: ServiceExecutionEntity }) {
  const item = service?.serviceOrderItem?.at(0)

  const lmNumber = item?.idOrdemServicoItem
  const lmDesc = item?.nomeProdAssociado
  const productAmt = item?.servcOrdItemQtyAcquired

  return (
    <BodySection title={i18next.t('service.exec.product.data')} className='product-data'>
      <FormField label={i18next.t('service.exec.lm.product')} value={lmNumber} />
      <FormField label={i18next.t('service.exec.lm.desc')} value={lmDesc} />
      <FormField label={i18next.t('sold.quantity')} value={productAmt} />
    </BodySection>
  )
}

export default ProductData
