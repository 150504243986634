import i18next from 'i18next'

import { Flag, Flex } from '@mozaic-ds/react'
import FormField from '../../../../components/FormField'
import ServiceOrderStatusType from '../../../../enums/ServiceOrder/status/ServiceOrderStatusType'

type ServiceRowProps = {
  queryParams: any
  service: any
  orderNumber: string
  onClickPDV: () => void
  onClickOS: () => void
  dateField: () => { label: string; value: string }
}

function Badge({ orderNumber }: { orderNumber: string }) {
  return (
    <div className='title'>
      <div className='number-badge'>{orderNumber}</div>
    </div>
  )
}

export function ServiceRowBodyDesktop({
  service,
  queryParams,
  orderNumber,
  onClickPDV,
  onClickOS,
  dateField,
}: ServiceRowProps) {
  const statusLabel = i18next.t(
    ServiceOrderStatusType.getStatusWithId(service.servcExctnStusCd).value,
  )

  return (
    <Flex className='full-width service-row' gap='mu075'>
      <Badge orderNumber={orderNumber} />

      <FormField
        label={i18next.t('filter.field.os.number')}
        value={service.osNumber}
        onClick={onClickOS}
        matchingText={queryParams.q}
        color='link'
        sx={{ width: '20%', minWidth: '100px' }}
      />

      <FormField
        className='service-name'
        label={i18next.t('needHelp.service')}
        value={service.description}
        matchingText={queryParams.q}
        sx={{ width: '80%', minWidth: '150px' }}
      />

      <FormField
        matchingText={queryParams.q}
        sx={{ width: '25%', minWidth: '100px' }}
        {...dateField()}
      />

      <FormField
        label={i18next.t('pdv.text')}
        value={parseInt(service.pdvNumber).toString()}
        onClick={onClickPDV}
        matchingText={queryParams.q}
        color='link'
        sx={{ width: '25%', minWidth: '100px' }}
      />

      <Flag theme='dark' className='right-icon' variant='bordered' title={statusLabel}>
        {statusLabel}
      </Flag>
    </Flex>
  )
}

export function ServiceRowBodyMobile({
  service,
  queryParams,
  orderNumber,
  onClickPDV,
  onClickOS,
  dateField,
}: ServiceRowProps) {
  const statusLabel = i18next.t(
    ServiceOrderStatusType.getStatusWithId(service.servcExctnStusCd).value,
  )

  return (
    <Flex className='full-width service-row' gap='mu075' wrap='wrap'>
      <Flex className='full-width' gap='mu075'>
        <Badge orderNumber={orderNumber} />

        <FormField
          className='service-name'
          label={i18next.t('needHelp.service')}
          value={service.description}
          matchingText={queryParams.q}
          sx={{ width: '80%', minWidth: '150px' }}
        />

        <Flag theme='dark' variant='bordered' title={statusLabel} style={{ width: '100px' }}>
          {statusLabel}
        </Flag>
      </Flex>

      <Flex className='full-width' gap='mu075'>
        <FormField
          label={i18next.t('filter.field.os.number')}
          value={service.osNumber}
          onClick={onClickOS}
          matchingText={queryParams.q}
          color='link'
        />

        <FormField matchingText={queryParams.q} {...dateField()} />

        <FormField
          label={i18next.t('serviceOrder.accumulatedReport.vaSlsOrdNr')}
          value={parseInt(service.pdvNumber).toString()}
          onClick={onClickPDV}
          matchingText={queryParams.q}
          color='link'
          sx={{ maxWidth: 'fit-content' }}
        />
      </Flex>
    </Flex>
  )
}
