import i18next from 'i18next'
import MozaicButton from '../../../../../../../components/MozaicButton'

type FooterButtonsType = {
  handleSubmit: Function
  handleClose: Function
  justify: string
  isSubmiting: boolean
  confirmText?: string
}

function FooterButtons(props: FooterButtonsType) {
  const {
    handleSubmit,
    handleClose,
    justify,
    isSubmiting,
    confirmText = i18next.t('serviceOrders.menuToConfirm'),
  } = props

  const disabled = justify.trim().length < 10 || isSubmiting

  return (
    <footer className='footer-buttons'>
      <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClose()}>
        {i18next.t('button.label.cancel')}
      </MozaicButton>
      <MozaicButton onClick={() => handleSubmit()} isDisabled={disabled}>
        {confirmText}
      </MozaicButton>
    </footer>
  )
}

export default FooterButtons
