import i18next from 'i18next'
import PropTypes from 'prop-types'
import {
  Bar,
  CartesianGrid,
  BarChart as Chart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { usableArea } from '../../constants/breakpoints'
import { useUsableAreaContext } from '../../contexts/usableArea'
import ErrorBoundaryBarChart from '../../errors/boundaries/components/BarChart'
import Skeleton from '../Skeleton'

/**
 * @description Reusable component to show bar graph
 */

function BarChart({
  containerHeight = 400,
  data = [],
  strokeDasharray = '1 3',
  domain = [0, 'auto'],
  XAxisDataKey,
  legendVerticalAlign = 'top',
  legendHeight = 36,
  bars = [],
  loading = false,
  onResetBoundary = () => {},
}) {
  const { widthContainer } = useUsableAreaContext()
  const isMobile = widthContainer < usableArea.medium

  if (loading) return <Skeleton height={400} borderRadius={8} backgroundColor='#EEEEF0' />

  return (
    <ErrorBoundaryBarChart onReset={onResetBoundary}>
      <section id='bar-char-container'>
        <ResponsiveContainer>
          <Chart
            height={containerHeight}
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: isMobile ? -25 : -15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray={strokeDasharray} />
            <XAxis dataKey={i18next.t(XAxisDataKey)} interval='preserveStartEnd' />
            <YAxis type='number' domain={domain} />
            <Tooltip />
            <Legend verticalAlign={legendVerticalAlign} height={legendHeight} />

            {bars.map((bar) => (
              <Bar
                name={bar.label}
                dataKey={i18next.t(bar.dataKey)}
                fill={bar.fill}
                key={i18next.t(bar.dataKey)}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </section>
    </ErrorBoundaryBarChart>
  )
}

BarChart.propTypes = {
  containerHeight: PropTypes.number,
  data: PropTypes.array,
  strokeDasharray: PropTypes.string,
  domain: PropTypes.array,
  XAxisDataKey: PropTypes.string,
  legendVerticalAlign: PropTypes.string,
  legendHeight: PropTypes.number,
  bars: PropTypes.array,
  loading: PropTypes.bool,
  onResetBoundary: PropTypes.func,
}

export default BarChart
