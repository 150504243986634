import { memo, useMemo } from 'react'
import i18next from 'i18next'

import MozaicCard from '../../../../../../../components/MozaicCard'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import ServiceExecution from './components/ServiceExecution'
import ErrorBoundary from '../../../../../../../errors/boundaries'

import { ServiceExecutionType } from '../../../../../../../models/ServiceExecution'
import { useProjectDetailsContext } from '../../../../../context'

function Services({ services, loadingServices, getProjectExecutions }: ServiceExecutionType) {
  const projectServices = {
    title: i18next.t('project.services'),
    leftIcon: <MozaicIcon icon='Drillalt48' size={24} mr={-2} />,
  }

  if (loadingServices)
    return (
      <div id='project-services'>
        <MozaicCard {...projectServices}>
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
          <div className='services-execution skeleton' />
        </MozaicCard>
      </div>
    )

  return (
    <div id='project-services'>
      <ErrorBoundary resetErrorBoundary={getProjectExecutions}>
        <MozaicCard {...projectServices}>
          <div className='services-execution'>
            {services.map((service, i) => (
              <ServiceExecution
                index={i + 1}
                service={service}
                key={`${service.servcOrdSeq}-${i}`}
              />
            ))}
          </div>
        </MozaicCard>
      </ErrorBoundary>
    </div>
  )
}

const MemoComponent = memo(Services)

function ServicesWC(props: any) {
  const { services, loadingServices, getProjectExecutions } = useProjectDetailsContext()

  const newProps = useMemo(
    () => ({ services, loadingServices, getProjectExecutions }),
    [services, loadingServices],
  )

  return <MemoComponent {...newProps} {...props} />
}

export default ServicesWC
