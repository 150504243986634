import PropTypes from 'prop-types'
import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import MozaicIcon from '../../../components/MozaicIcon'

function CardSpecificConditions({ translate, dataOS, isSpecificConditions }) {
  const [showSpecificConditions, setShowSpecificConditions] = useState(isSpecificConditions)

  const serviceName = dataOS.serviceName ? dataOS.serviceName : ''
  const spanClass = showSpecificConditions && 'invert'

  return (
    <div className='conditions-headers'>
      <div className='conditions-contanier'>
        <div className='conditions-box'>
          <strong>
            {translate('conditions.specific.label').replace('#service group name#', serviceName)}
          </strong>
        </div>
        <div className='conditions-box'>
          <div className='conditions-box-button'>
            <span
              className='conditions-box-button-label bold'
              onClick={() => setShowSpecificConditions(!showSpecificConditions)}
            >
              {showSpecificConditions ? (
                <b>{translate('conditions.hide.details')}</b>
              ) : (
                <b>{translate('conditions.show.details')}</b>
              )}
              <MozaicIcon icon='ArrowArrowBottom24' className={`conditions-down ${spanClass}`} />
            </span>
          </div>
          {showSpecificConditions && (
            <div>
              {dataOS.inclddMaterial && (
                <strong>
                  {translate('serviceOrder.acceptancePdf.content.includedMaterial.title')}
                </strong>
              )}
              {dataOS.inclddMaterial && ReactHtmlParser(dataOS.inclddMaterial)}
              {dataOS.inclddNotMaterial && (
                <strong>{translate('serviceOrder.acceptancePdf.content.toAcquire.title')}</strong>
              )}
              {dataOS.inclddNotMaterial && ReactHtmlParser(dataOS.inclddNotMaterial)}
              {dataOS.inclddServcTxt && (
                <strong>
                  {translate('serviceOrder.acceptancePdf.content.includedServices.title')}
                </strong>
              )}
              {dataOS.inclddServcTxt && ReactHtmlParser(dataOS.inclddServcTxt)}
              {dataOS.inclddNotServcTxt && (
                <strong>{translate('serviceOrder.acceptancePdf.content.excluded.title')}</strong>
              )}
              {dataOS.inclddNotServcTxt && ReactHtmlParser(dataOS.inclddNotServcTxt)}
              {dataOS.conditionsOfApplication && (
                <strong>
                  {translate('serviceOrder.acceptancePdf.application.conditions.title')}
                </strong>
              )}
              {dataOS.conditionsOfApplication && ReactHtmlParser(dataOS.conditionsOfApplication)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

CardSpecificConditions.propTypes = {
  translate: PropTypes.func,
  dataOS: PropTypes.any,
  isSpecificConditions: PropTypes.bool.isRequired,
}

export default CardSpecificConditions
