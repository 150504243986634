import { hideSpinner } from '../spinner'

import localforage from 'localforage'
import User from '../../../core/User'
import CacheUtils from '../../../helpers/CacheUtils'
import store from '../../store'

import {
  ACCESS_ALLOWED,
  ACCESS_NOT_ALLOWED,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from '../../actionTypes/auth'

const user = new User()

export const requestLogin = () => ({ type: LOGIN_REQUEST })

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
})

export const loginError = (data) => ({
  type: LOGIN_FAILURE,
  payload: data,
})

export const redirectPage = (destiny) => {
  window.location.href = destiny
  return false
}

export const allowAccess = () => ({
  type: ACCESS_ALLOWED,
})

export const notAllowAccess = () => ({
  type: ACCESS_NOT_ALLOWED,
})

export function checkRedirectUrl() {
  if (user.currentUser?.username) {
    const accessFunction = user.currentUser.accessFunctionList

    if (accessFunction?.includes(130)) return '/cockpit'
    if (accessFunction?.inFuncao26 === 1) return '/serviceOrder'

    return '/home'
  }
  return '/login'
}

const restoreLastSession = (currentUser) => {
  const lastUser = localStorage.getItem('last_user_logged') || ''

  if (lastUser !== currentUser.username) {
    localStorage.removeItem('lastRouteAccessed')
    sessionStorage.removeItem('advanced_search_filters')

    return redirectPage('/cockpit')
  }

  return true
}

export const onFetchUserSuccess = async ({ data: currentUser }, dispatch, redirectUrl) => {
  CacheUtils.needReset = true

  store.dispatch(hideSpinner())
  store.dispatch(loginSuccess(currentUser))

  user.currentUser = currentUser

  if (restoreLastSession(currentUser)) {
    if (redirectUrl) return redirectPage(redirectUrl)
    if (currentUser?.funcaoAcesso?.inFuncao26 === 1) return redirectPage('/serviceOrder')

    return redirectPage('/cockpit')
  }
}

export const onFetchUserError = (error, dispatch) => {
  user.clearCache()
  localforage.clear()

  dispatch(loginError(error))
  dispatch(hideSpinner())
}
