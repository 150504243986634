import { ComponentClass, memo, useMemo } from 'react'
import { useInstallerContext } from '../../../contexts/installer'

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const { viewServiceSpecialist, getServiceSpecialistFromId } = useInstallerContext()

    const newProps = useMemo(
      () => ({
        viewServiceSpecialist,
        getServiceSpecialistFromId,
      }),
      [viewServiceSpecialist, getServiceSpecialistFromId],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
