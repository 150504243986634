import API_REQUEST from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'
import _ from 'lodash'

import User from '../core/User'

import store from '../redux/store'

const user = new User().currentUser

export async function fetchClusters() {
  try {
    const { data } = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.FETCH_CORES_BY_USER,
    )

    return data
  } catch (error) {
    console.error('error when fetch clusters', error)
  }
}

export async function fetchClustersOptions() {
  const result = await fetchClusters()

  const options = result?.map((cluster) => ({
    value: cluster.coreCd,
    label: cluster.name,
    stores: cluster.stores,
    checked: true,
    isIndeterminate: false,
    disabled: false,
  }))

  return _.orderBy(options, (item) => parseInt(item.label), 'asc')
}
