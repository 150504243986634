import i18next from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import CustomCard from '../../../../../../components/Card'
import MozaicIcon from '../../../../../../components/MozaicIcon'
import SearchField from '../../../../../../components/SearchField'
import ConversationItem from './components/Item'
import SkeletonItem from './components/SkeletonItem'

import { Conversation } from '../../../../../../contexts/customerChat/models'
import { getRandomNumber } from '../../../../../../helpers/random'

import { useFirestoreContext } from '../../../../../../contexts/customerChat'

function Messages({
  conversationList,
  lazyIndex,
}: {
  conversationList: Conversation[]
  lazyIndex: number
}) {
  const list = useMemo(
    () => (conversationList.length ? conversationList.slice(0, lazyIndex) : []),
    [conversationList, lazyIndex],
  )

  if (!conversationList.length) return <></>

  return (
    <>
      {list.map((conversation: Conversation) => (
        <ConversationItem
          conversation={conversation}
          key={`${conversation.servcOrdCd}-${getRandomNumber(1, 999999999)}`}
        />
      ))}
    </>
  )
}

type ConversationListType = {
  conversationList: Array<Conversation>
  searchField: string
  loadingConversations: boolean
  countNoResolvedConversations: number
  handleSetSearchField: Function
  fetchMessagesBySearchText: Function
}

function ConversationList(props: ConversationListType) {
  const {
    conversationList,
    searchField,
    loadingConversations,
    countNoResolvedConversations,
    handleSetSearchField,
    fetchMessagesBySearchText,
  } = props

  const [lazyIndex, setLazyIndex] = useState<number>(20)

  useEffect(() => {
    setLazyIndex(20)
  }, [conversationList])

  function checkLastRow() {
    const el = document.getElementsByClassName('conversation-item-container')[lazyIndex - 1]
    if (el) {
      const rect = el.getBoundingClientRect()
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        setLazyIndex(lazyIndex + 20)
      }
    }
  }

  return (
    <div id='conversation-list-container'>
      <CustomCard
        title={i18next.t('client.chat.title')}
        iconNode={<MozaicIcon icon='AccountProfileView24' />}
        counterValue={countNoResolvedConversations}
      >
        <SearchField
          value={searchField}
          onChange={(value) => handleSetSearchField(value)}
          onSearch={() => fetchMessagesBySearchText(searchField)}
          placeholder={i18next.t('client.chat.search.field.placeholder')}
          label={i18next.t('client.chat.search.field.label')}
        />

        <section className='area' id='area-messages' onScroll={() => checkLastRow()}>
          {loadingConversations && !conversationList.length && <SkeletonItem />}

          <Messages conversationList={conversationList} lazyIndex={lazyIndex} />

          {!loadingConversations && !conversationList.length && (
            <span className='no-has-conversations'>
              {i18next.t('chat.communication.empty.list')}
            </span>
          )}
        </section>
      </CustomCard>
    </div>
  )
}

export default function ConversationWithContext(props: any) {
  const {
    searchField,
    loadingConversations,
    countNoResolvedConversations,
    handleSetSearchField,
    fetchMessagesBySearchText,
  } = useFirestoreContext()

  const newProps = useMemo(
    () => ({
      searchField,
      loadingConversations,
      countNoResolvedConversations,
      handleSetSearchField,
      fetchMessagesBySearchText,
    }),
    [
      searchField,
      loadingConversations,
      countNoResolvedConversations,
      handleSetSearchField,
      fetchMessagesBySearchText,
    ],
  )

  return <ConversationList {...newProps} {...props} />
}
