import Api from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User()

interface AgentPendingDocuments {
  servcPrvdrAgntId: number
  servcPrvdrAgntNm: string
  pendingDocuments: string[]
}

export interface ProviderPendingDocuments {
  servcPrvdrId: number
  pendingDocuments: string[]
  agents: AgentPendingDocuments[]
  hasNoOwnerAgents: boolean
}

/**
 * fetch provider ans its agents pendind documents
 * @param servcPrvdrId
 * @returns Promise
 */
export const fetchProviderPendingDocuments = (
  servcPrvdrId: number,
  abortController: AbortController,
): Promise<ProviderPendingDocuments> =>
  new Api(user.currentUser?.dsToken, store.dispatch)
    .get(
      API_URL.GET_PROVIDER_PENDING_DOCUMENTS.replace('{id}', String(servcPrvdrId)),
      {},
      abortController as any,
    )
    .then((response) => {
      if (response?.data) {
        return response.data as ProviderPendingDocuments
      }
      return Promise.reject()
    })
