import i18next from 'i18next'
import { useEffect, useState } from 'react'
import Grid from '../../../../../../../../../components/utils/Grid'

type AcceptedBudgetsProps = {
  readonly refuseBudgetsReasons?: Array<any>
  readonly onChangeAnswer: Function
  readonly budgetAccepted: Function
  readonly defaultSelected: Object
  readonly selectedDeniedBudget: Object
  readonly serviceOrderOccurrenceQuotationRefusedByExpiration: any
}

export default function AcceptedBudgets({
  refuseBudgetsReasons,
  onChangeAnswer,
  budgetAccepted,
  defaultSelected,
  selectedDeniedBudget,
  serviceOrderOccurrenceQuotationRefusedByExpiration,
}: AcceptedBudgetsProps) {
  const [selected, setSelected] = useState<any>({ data: null })
  const [selectedReason, setSelectedReason] = useState<any>(null)
  const [isToRenderNegativeAnswerReasons, setIsToRenderNegativeAnswerReasons] =
    useState<boolean>(false)

  useEffect(() => {
    setSelected({ data: defaultSelected })
  }, [defaultSelected])

  useEffect(() => {
    setSelectedReason(selectedDeniedBudget)
  }, [selectedDeniedBudget])

  const handleOptionAccepted = (option: any) => {
    if (option === selected.data) {
      setSelected({ data: null })
      // eslint-disable-next-line no-param-reassign
      option = null
    } else {
      setSelected({ data: option })
    }

    if (option) {
      budgetAccepted(option)
      setIsToRenderNegativeAnswerReasons(false)
      return
    }

    setIsToRenderNegativeAnswerReasons(true)
  }

  useEffect(() => {
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      handleOptionAccepted(false)
    }
  }, [])

  const getClassName = (type: any) => {
    if (type) {
      return `option ${selected.data === true ? 'selected-active' : 'no-selected'}`
    }
    return `option ${selected.data === false ? 'selected-active' : 'no-selected'}`
  }

  const getClassNameSubtitle = (type: any) => {
    if (type) {
      return `text-sub-option ${selected.data === true ? 'visible' : 'no-visible'}`
    }
    return `text-sub-option ${selected.data === false ? 'visible' : 'no-visible'}`
  }

  const renderOptions = (reason: any) => {
    const checked = selectedReason === reason.servcOrdEvntTypRsnId
    const updateSelectedReason = (selectedReason: any) => {
      onChangeAnswer(selectedReason)
      setSelectedReason(selectedReason)
    }
    const optionClass = `option ${checked ? 'selected-active' : 'no-selected'}`
    return (
      <div
        key={`reason_${reason.servcOrdEvntTypRsnId}`}
        className={optionClass}
        onClick={() => updateSelectedReason(reason.servcOrdEvntTypRsnId)}
      >
        <p className={`text-option ${checked ? 'text-option-active' : ''}`}>
          {reason.servcOrdEvntTypRsnDesc}
        </p>
      </div>
    )
  }

  const getAcceptedOptionNoSubtitle = () => {
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      return serviceOrderOccurrenceQuotationRefusedByExpiration.servcOrdItemEvntDesc
    }
    return i18next.t('servc.ord.budget.component.accepted.option.no.subtitle')
  }

  const renderNegativeAnswerReasons = () => (
    <div className='body-so-budget-accepted-negative'>
      <span className='title'>{i18next.t('custEval.reasonOptions')}</span>
      <div className='body no-selected'>{refuseBudgetsReasons?.map(renderOptions)}</div>
    </div>
  )

  const getClassNameBody = () => {
    if (
      serviceOrderOccurrenceQuotationRefusedByExpiration !== null &&
      serviceOrderOccurrenceQuotationRefusedByExpiration !== undefined
    ) {
      return 'body block no-selected'
    }
    return 'body no-selected'
  }

  return (
    <div>
      <Grid fluid>
        <div className='body-so-budget-accepted'>
          <span className='title'>{i18next.t('servc.ord.budget.component.accepted.subtile')}</span>
          <div className={getClassNameBody()}>
            <div className={getClassName(true)} onClick={() => handleOptionAccepted(true)}>
              <p className={`text-option ${selected.data ? 'text-option-active' : ''}`}>
                {i18next.t('servc.ord.budget.component.accepted.option.yes')}
              </p>
              <p className={getClassNameSubtitle(true)}>
                {i18next.t('servc.ord.budget.component.accepted.option.yes.subtitle')}
              </p>
            </div>
            <div className={getClassName(false)} onClick={() => handleOptionAccepted(false)}>
              <p className={`text-option ${selected.data === false ? 'text-option-active' : ''}`}>
                {i18next.t('servc.ord.budget.component.accepted.option.no')}
              </p>
              <p className={getClassNameSubtitle(false)}>{getAcceptedOptionNoSubtitle()}</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid fluid>{isToRenderNegativeAnswerReasons && renderNegativeAnswerReasons()}</Grid>
    </div>
  )
}
