import { TOGGLE_MENU, CLOSE_MENU, UPDATE_CURRENT_ROUTE } from '../../actionTypes/menu'

const DEFAULT_STATE = {
  isOpen: false,
  currentRoute: '',
}

const menu = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, isOpen: !state.isOpen }

    case CLOSE_MENU:
      return { ...state, isOpen: false }

    case UPDATE_CURRENT_ROUTE:
      return { ...state, currentRoute: action.payload }

    default:
      return state
  }
}

export default menu
