import { ADEOResponse } from '../../models/AdeoResponse'
import User from '../../core/User'
import API_URL from '../../core/Envs/endpoints'
import Fetch from '../../core/Http/FetchAdapter'
import store from '../../redux/store'
import { StoreType } from '../../models/StoreClustersGroups'

const user = new User()

/**
 * @description Fetch stores from API endpoint
 */

export const fetchStores = (): Promise<ADEOResponse<StoreType[]>> => {
  const url = API_URL.FIND_STORES
  return new Fetch(user.currentUser.dsToken, store.dispatch).get(url.toString(), null)
}
