import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import store from '../../../redux/store'
import User from '../../../core/User'

const user = new User().currentUser

export const saveProviderZones = async (servcPrvdrId: string, zones: Array<any>) => {
  if (servcPrvdrId !== undefined) {
    const targetURL = API_URL.PROVIDER_ZONES.replace('{servcPrvdrId}', servcPrvdrId)

    try {
      return new API_REQUEST(user.dsToken, store.dispatch).post(targetURL, zones)
    } catch (error) {
      return error
    }
  }
}

export const getProviderZones = async (servcPrvdrId: string) => {
  const targetURL = API_URL.PROVIDER_ZONES.replace('{servcPrvdrId}', servcPrvdrId)
  try {
    const resp = await new API_REQUEST(user.dsToken, store.dispatch).get(targetURL, {})
    return resp.data
  } catch (error) {
    return error
  }
}

export const getZonesOptions = async (middleZones: Array<any>) => {
  const targetURL = API_URL.FIND_REGIONS
  const body = {
    microRegionIdList: middleZones.map((zone) => zone.value),
  }

  try {
    const resp = await fetch(targetURL, {
      headers: {
        'content-type': 'application/json',
        Authorization: user.dsToken,
      },
      method: 'POST',
      body: JSON.stringify(body),
    })
    const json = await resp.json()
    return json
  } catch (error) {
    return error
  }
}

export const getRegionsOptions = async (states: Array<any>) => {
  const uf = states.map((state) => state.value).join(',')
  const targetURL = `${API_URL.FIND_MICRO_REGIONS}?uf=${uf}`

  try {
    const resp = await fetch(targetURL, {
      headers: {
        Authorization: user.dsToken,
      },
    })
    const json = await resp.json()
    return json
  } catch (error) {
    return error
  }
}

export const getMacroRegionStates = async () => {
  try {
    const resp = await new API_REQUEST(user.dsToken, store.dispatch).get(
      API_URL.FIND_MACRO_REGION_STATES,
      {},
    )
    return resp.data.map((state: any) => ({
      value: state.stateCd,
      label: state.stateNm,
    }))
  } catch (error) {
    return error
  }
}
