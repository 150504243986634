import { ServiceOrderAttachmentsEnum } from '../../../../enums/ServiceOrder/ServiceOrderAttachmentsEnum'

export type FileMetadata = {
  type: string
  bucket: string
  generation: string
  metageneration: string
  fullPath: string
  name: string
  size: number
  timeCreated: string
  updated: string
  md5Hash: string
  contentEncoding: string
}

export interface FileRefType extends Object {
  name: string
  bucket: string
  fullPath: string
  getFile: () => Promise<Blob>
  getMetadata: () => Promise<FileMetadata>
  getDownloadURL: () => Promise<string>
}

export type StorageRef = {
  put: (file: File) => Promise<any>
  child: (path: string) => any
  listAll: () => Promise<{ items: FileRefType[] }>
  getMetadata: () => Promise<any>
}

export interface uploadAttachmentV2 {
  servcOrdSeq: number
  file: File
  atchmtDocmntTyp: string
  atchmtDocmntTypDesc: string
  isPortalService: boolean
  type: 'img' | 'pdf'
}

export interface AttachmentV2 {
  atchmtCloudFullPathTxt: string
  atchmtCloudShortPathTxt: string
  creatTs: string
  fileTyp: string
  lastUpdtUserCd: string
  servcOrdAtchmtDcmntIdNr: string
  servcOrdCd: number
  servcOrdItemAtachmtId: number
  servcOrdItemAtchmtClsfctnCd: ServiceOrderAttachmentsEnum
  servcOrdItemAtchmtDesc: string
  servcOrdSeq: number
  atchmtDocmntTyp: number
  atchmtDocmntTypDesc: string
}

export const extToMime = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
}

export const imgExts = ['png', 'jpg', 'jpeg']
