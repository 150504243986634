import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'

import AwsLightbox from '../../../../components/utils/images/AwsLightbox'
import Message from './Message'

export class MessageList extends Component {
  state = {
    lightboxIsOpen: false,
    lightboxSrc: null,
    lightboxCurrentImage: 0,
    base64Img: null,
  }

  lightboxToggle = () => {
    const { lightboxIsOpen } = this.state
    this.setState({ lightboxIsOpen: !lightboxIsOpen })
  }

  currentDate = ''

  chatContainer = null

  componentDidMount() {
    const { setChunkQty } = this.props

    setChunkQty(Math.floor(this.chatContainer.scrollHeight / 40)) // 40 é a alutra aproximada de uma mensagem curta
  }

  componentDidUpdate(prevProps) {
    const { messages } = this.props

    let scrollToBottom = false

    const newMessages = messages
    const newMessagesLen = newMessages.length

    // Senão, se tiver mensagens...
    if (newMessagesLen) {
      const oldMessages = prevProps.messages
      const oldMessagesLen = oldMessages.length

      // ...elas não forem da mesma quantidade
      if (newMessagesLen !== oldMessagesLen) {
        const lastNewMessage = newMessages[newMessagesLen - 1]
        const lastOldMessage = oldMessages[oldMessagesLen - 1]

        // ...e não tiver uma mensagem antiga, ou a última mensagem diferir de hora, faz scroll
        scrollToBottom = !lastOldMessage || lastNewMessage.createdAt !== lastOldMessage.createdAt
      }
    }

    if (scrollToBottom) {
      this.messagesEnd.scrollIntoView({
        behavior: 'smooth',
        inline: 'end',
        block: 'end',
      })
    }

    this.chatContainer.scrollTop = this.chatContainer.scrollHeight
  }

  renderDate = (date) => {
    const { humanizeDate } = this.props

    return (
      <div className='chat-message-box'>
        <div className='chat-message chat-message-date'>{humanizeDate(date, true)}</div>
      </div>
    )
  }

  openLightbox = (src, base64Img) => {
    this.setState({ lightboxIsOpen: true, lightboxSrc: src, base64Img })
  }

  renderRows = (message, index) => {
    const { auth, t, openServiceOrderListFiltered, dispatch } = this.props

    const msgDate = moment(message.createdAt)
    let printDate = false

    if (!this.currentDate || !this.currentDate.isSame(msgDate, 'day')) {
      this.currentDate = msgDate
      printDate = true
    }

    return (
      <div key={message.createdAt}>
        {printDate && this.renderDate(this.currentDate)}

        <div className='chat-message-box'>
          <Message
            key={index}
            auth={auth}
            t={t}
            message={message}
            openLightbox={this.openLightbox}
            openServiceOrderListFiltered={openServiceOrderListFiltered}
            dispatch={dispatch}
          />
        </div>
      </div>
    )
  }

  render() {
    const { messages, onChatScroll, t } = this.props

    const { lightboxIsOpen, base64Img } = this.state

    this.currentDate = ''

    return (
      <div
        className='chat-messages'
        onScroll={(evt) => onChatScroll(evt.target)}
        ref={(el) => {
          this.chatContainer = el
        }}
      >
        {messages.map(this.renderRows)}

        <div
          ref={(el) => {
            this.messagesEnd = el
          }}
        />

        <AwsLightbox
          isOpen={lightboxIsOpen}
          onClose={this.lightboxToggle}
          spinnerColor='green'
          imageCountSeparator={t('option.of')}
          backdropClosesModal
          showCloseButton={false}
          data={base64Img}
        />
      </div>
    )
  }
}

MessageList.propTypes = {
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  openServiceOrderListFiltered: PropTypes.func.isRequired,
  setChunkQty: PropTypes.func.isRequired,
  humanizeDate: PropTypes.func.isRequired,
  onChatScroll: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
}

export default MessageList
