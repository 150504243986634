import store from '../redux/store'
import API from '../core/Http/FetchAdapter'
import API_URL from '../core/Envs/endpoints'

import { showSpinner, hideSpinner } from '../redux/actions/spinner'
import { GET_PAGES } from '../redux/actionTypes/pagination'

const getPages = (metadata = {}) => ({
  type: GET_PAGES,
  pages: metadata.pageCount,
  current: metadata.page,
  count: metadata.totalCount,
})

export const fetchUnnacomplishedReasonsList = async () => {
  store.dispatch(showSpinner())

  try {
    const { data } = await new API(null, store.dispatch).get(
      API_URL.FIND_UNNACOMPLISHED_REASONS,
      {},
    )

    return data
  } catch (err) {
    return err
  } finally {
    store.dispatch(hideSpinner())
  }
}

export const fetchReasonsList = async (
  token = null,
  page = 1,
  query = {},
  size = true,
  pagination = true,
  spinner = true,
) => {
  if (spinner) store.dispatch(showSpinner())

  const pags = {
    page,
    ...(size && { size: 10 }),
  }

  try {
    const { data, metadata } = await new API(token, store.dispatch, pags).get(
      API_URL.FIND_REASONS_LIST,
      query,
    )

    if (pagination) store.dispatch(getPages(metadata))

    return data
  } catch (err) {
    return err
  } finally {
    if (spinner) store.dispatch(hideSpinner())
  }
}
