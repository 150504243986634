import classnames from 'classnames'
import i18next from 'i18next'
import moment from 'moment-timezone'
import { useMemo } from 'react'

import FormField from '../../../../../../../../../components/FormField'

import { SHIFT_CODES } from '../../../../../../../../../constants/config'
import { getOsRoutePrefix } from '../../../../../../../../../helpers/serviceOrderUtils'
import { ServiceOrderDetailStore } from '../../../../../../../context/models'
import { WithContext } from '../../context'

function ActivatedWarranty({ warranty, loadingWarranty }: ServiceOrderDetailStore) {
  const urlPrefix = useMemo(() => getOsRoutePrefix(), [])

  const osNumber = warranty?.warrantyServcOrdCd

  const handleGoToServiceOrder = () => {
    window.location.replace(window.location.origin.concat(`${urlPrefix}/${osNumber}`))
  }

  const remainingDays = warranty?.remainingDays || 0
  const maxDate = moment(warranty?.maxDate).format('L')
  const warrantyDate = `${maxDate} (${remainingDays} ${i18next.t('days.remaining')})`

  return (
    <div id='activated-warranty-container'>
      {remainingDays ? (
        <div className={classnames({ 'activated-info': true, skeleton: loadingWarranty })}>
          <span>{i18next.t('customer.service.order.details.infos.warranty')}:</span>{' '}
          <strong>{warrantyDate}</strong>
        </div>
      ) : (
        <div className={classnames('trigger-info warranty-expired', { skeleton: loadingWarranty })}>
          <span>{`${i18next.t('order.detail.warranty.modal.expired.text')} `}</span>
          <strong>{maxDate}</strong>
        </div>
      )}

      <FormField
        isFetching={loadingWarranty}
        label={i18next.t('serviceOrder.outOfFlow.reason')}
        value={warranty?.reason || '-'}
        isBold={false}
      />

      <FormField
        isFetching={loadingWarranty}
        label={i18next.t('description.label')}
        value={warranty?.description || '-'}
        isBold={false}
      />

      <section className='activated-details'>
        <div className='scheduling'>
          <FormField
            isFetching={loadingWarranty}
            label={i18next.t('customer.service.order.details.scheduling.date')}
            value={moment(warranty?.scheduledDate).format('L') || '-'}
            isBold={false}
          />
          <FormField
            isFetching={loadingWarranty}
            label={i18next.t('customer.service.order.reschedule.shift')}
            value={i18next.t(SHIFT_CODES[warranty?.scheduledTurn as 'M' | 'A' | 'E']) || '-'}
            isBold={false}
          />
        </div>
        <FormField
          isFetching={loadingWarranty}
          label={i18next.t('serviceOrders.sameProvider.select')}
          value={warranty?.sameProvider ? i18next.t('option.yes') : i18next.t('option.no') || '-'}
          isBold={false}
        />
      </section>

      <section className='activated-footer'>
        {osNumber && (
          <button
            className={classnames({ 'backyard-button': true, skeleton: loadingWarranty })}
            onClick={() => handleGoToServiceOrder()}
          >
            <span>
              {i18next
                .t('warranty.activated.service.order.go.to.new')
                .replace('{serviceOrder}', String(osNumber))}
            </span>
          </button>
        )}

        <div className={classnames({ 'triggered-in': true, skeleton: loadingWarranty })}>
          <span>{i18next.t('warranty.triggered.in')}:</span>{' '}
          <strong>{moment(warranty?.callDate).format('L LTS')}</strong>
        </div>
      </section>
    </div>
  )
}

export default WithContext(ActivatedWarranty)
