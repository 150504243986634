import i18next from 'i18next'
import { useEffect, useState } from 'react'
// @ts-ignore
import Select from 'react-select'

import { CHANNEL_TYPES } from '../../constants/config'
import { colourStyles } from '../../helpers/selectStyle'

type optionType = {
  value: any
  label: string
}

type ServiceTypeSelectType = {
  onChange: Function
  value: optionType | null
}

function ServiceTypeSelect(props: ServiceTypeSelectType) {
  const { onChange, value } = props
  const [options, setOptions] = useState<Array<any>>([])

  const createOptions = () => {
    const options: Array<any> = []

    Object.keys(CHANNEL_TYPES).forEach((item) => {
      const index = item as keyof typeof CHANNEL_TYPES

      options.push({
        value: item,
        label: i18next.t(CHANNEL_TYPES[index]),
      })
    })

    setOptions(options)
  }

  useEffect(() => {
    createOptions()
  }, [])

  const handleChange = (e: any) => {
    onChange(e)
  }

  const label = i18next.t('serviceOrders.channel')
  const placeholder = `${i18next.t('search')} ${i18next.t('all').toLowerCase()}`
  const footer = i18next.t('rating.error')

  return (
    <div className='channel-select'>
      <label className='react-select--label' htmlFor='channel-select'>
        {label}
      </label>

      <Select
        value={value}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        name='channel-select'
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        resetValue={null}
        styles={colourStyles}
        isClearable
        menuPosition='absolute'
      />

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default ServiceTypeSelect
