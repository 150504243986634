import i18next from 'i18next'

import FormField from '../../../../components/FormField'
import { Flex } from '@mozaic-ds/react'

export function ServiceRowGhost() {
  return (
    <Flex className='full-width service-row' gap='mu100'>
      <FormField label='' value='' isFetching sx={{ width: '50px', height: '45px' }} />

      <FormField
        label={i18next.t('filter.field.os.number')}
        value=''
        isFetching
        sx={{ width: '20%', height: '45px' }}
      />

      <FormField
        className='service-name'
        label={i18next.t('needHelp.service')}
        value=''
        isFetching
        sx={{ width: '20%', height: '45px' }}
      />

      <FormField
        label={i18next.t('filter.field.os.number')}
        value=''
        isFetching
        sx={{ width: '20%', height: '45px' }}
      />

      <FormField sx={{ width: '20%', height: '45px' }} isFetching value='' label='' />

      <FormField
        label={i18next.t('pdv.text')}
        value=''
        isFetching
        sx={{ width: '20%', height: '45px' }}
      />
    </Flex>
  )
}

export function ServiceRowGhostMobile() {
  return (
    <Flex className='full-width service-row' gap='mu100' wrap='wrap'>
      <Flex className='full-width' gap='mu075'>
        <FormField label='' value='' isFetching sx={{ width: '10%', height: '45px' }} />

        <FormField
          className='service-name'
          label={i18next.t('needHelp.service')}
          isFetching
          value=''
          sx={{ width: '60%', height: '45px' }}
        />

        <FormField label='' value='' isFetching sx={{ width: '30%', height: '45px' }} />
      </Flex>

      <Flex className='full-width' gap='mu075'>
        <FormField
          label={i18next.t('filter.field.os.number')}
          isFetching
          value=''
          color='link'
          sx={{ height: '45px' }}
        />

        <FormField isFetching label='' value='' sx={{ height: '45px' }} />

        <FormField
          label={i18next.t('serviceOrder.accumulatedReport.vaSlsOrdNr')}
          value=''
          isFetching
          sx={{ height: '45px' }}
        />
      </Flex>
    </Flex>
  )
}
