import ErrorBoundary from '../../../../../../../errors/boundaries'
import MozaicCard from '../../../../../../../components/MozaicCard'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import { Flex } from '@mozaic-ds/react'

function Warranty() {
  return (
    <ErrorBoundary>
      <div id='project-warranty'>
        <MozaicCard
          title='Garantia'
          leftIcon={<MozaicIcon icon='AfterSalesAfterSalesService24' size={24} />}
        >
          <Flex paddingLeft='mu150' paddingRight='mu150' justifyContent='space-between'>
            Nothing here yet
          </Flex>
        </MozaicCard>
      </div>
    </ErrorBoundary>
  )
}

export default Warranty
