import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'
import store from '../../../redux/store'
import User from '../../../core/User'
import { Speciality } from '../../specialities/fetchSpecialities'
import { ADEOResponse } from '../../../models/AdeoResponse'

const user = new User().currentUser

type updateParams = {
  servcPrvdrId: string
  specialitiesIds: Speciality['specialityCd'][]
}

export const updateProviderSpecialities = async (payload: updateParams) =>
  new API_REQUEST(user.dsToken, store.dispatch).patch(API_URL.UPDATE_PROVIDER_SPECIALITIES, payload)

export const fetchSpecialitiesIdsByProvider = (servcPrvdrId: string) =>
  new API_REQUEST(user.dsToken, store.dispatch).get(API_URL.FIND_PROVIDER_SPECIALITIES_IDS, {
    servcPrvdrId,
  })

export const findProviderSpecialities = async (
  servcPrvdrId: string,
): Promise<ADEOResponse<Speciality['specialityCd'][]>> =>
  new API_REQUEST(user.dsToken, store.dispatch).get(API_URL.FIND_PROVIDER_SPECIALITIES_IDS, {
    servcPrvdrId,
  })
