export const usableArea = {
  small: 360,
  medium: 679,
  large: 849,
}

export const screen = {
  small: 360,
  medium: 710,
  large: 959,
  xlarge: 1366,
}
