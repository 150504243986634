import { ComponentClass, memo, useEffect, useMemo } from 'react'
import { usePaymentsContext } from '../../../contexts/payments'

export function WithContext(Component: ComponentClass) {
  const MemoComponent = memo(Component)

  return function (props: any) {
    const {
      fetchPaymentsServiceOrders,
      paymentServiceOrderRemove,
      paymentServiceOrderSave,
      serviceOrders,
      handleClear,
      filter,
    } = usePaymentsContext()

    useEffect(() => handleClear, [])

    const newProps = useMemo(
      () => ({
        fetchPaymentsServiceOrders,
        paymentServiceOrderRemove,
        paymentServiceOrderSave,
        serviceOrders,
        filter,
      }),
      [serviceOrders, filter],
    )

    return <MemoComponent {...newProps} {...props} />
  }
}
