import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { colourStyles } from '../../../helpers/selectStyle'

class BranchSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      branchs: [],
      error: null,
      loading: false,
      value: undefined,
    }
  }

  componentDidMount() {
    const { user, dispatch } = this.props

    this.setState({ loading: true })

    return new Api(user.dsToken, dispatch)
      .get(API_URL.FIND_STORES_ALL)
      .then((response) => this.onFetchSuccess(response.data))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(branchs) {
    const options = branchs.map((branch) => ({
      value: branch.id,
      label: branch.name,
    }))

    this.setState({ branchs: options, loading: false, error: null })
  }

  onFetchError(error) {
    this.setState({ error, loading: false, branchs: [] })
  }

  value = () => {
    const { value, notMultiple } = this.props
    const { branchs, value: valueState } = this.state

    if (notMultiple) {
      return value && value.value
        ? branchs.filter((option) => value.value === option.value)
        : branchs.filter((option) => value === option.value)
    }

    return valueState
      ? branchs.filter((option) => valueState.includes(option))
      : value
      ? branchs.filter((option) => value.includes(option.value))
      : ''
  }

  render() {
    const {
      id,
      name,
      onChange,
      t,
      disabled = false,
      notMultiple,
      optional,
      label,
      onBlur,
    } = this.props
    const { branchs, loading } = this.state

    let placeholder = loading ? t('option.loading') : t('serviceProviders.stores')
    placeholder = label ? t(label) : placeholder
    if (optional) {
      placeholder += t('editForm.optional')
    }

    return (
      <div>
        <Select
          id={id}
          name={name}
          value={this.value()}
          onChange={(event) => {
            this.setState({ value: event })
            onChange(event)
          }}
          placeholder={' '}
          options={branchs}
          isClearable
          resetValue={[]}
          clearValueText={t('option.removeItem')}
          noOptionsMessage={() => t('select.noOptionsMessage')}
          classNamePrefix='react-select'
          isMulti={!notMultiple}
          closeMenuOnSelect={notMultiple || false}
          styles={colourStyles}
          isDisabled={disabled}
          onBlur={() => onBlur && onBlur()}
        />
        <label htmlFor={id} className='label dateInputLabel'>
          {placeholder}
        </label>
      </div>
    )
  }
}

BranchSelect.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  notMultiple: PropTypes.bool,
  optional: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
}

export default BranchSelect
