import { memo } from 'react'

import logoHorizontal from '../../../../assets/icons/logo-horizontal.svg'
import logoHorizontalComplete from '../../../../assets/icons/logo-instala-horizontal-green-complete.svg'

/*
  Description component: container usage on app bar to show logo of instala
*/
function LogoContainer() {
  return (
    <div id='logo-instala-container'>
      <img src={logoHorizontal} alt='horizontal logo' className='logo-instala-horizontal' />
      <img
        src={logoHorizontalComplete}
        alt='complete horizontal logo'
        className='logo-instala-horizontal-green-complete'
      />
    </div>
  )
}

export default memo(LogoContainer)
