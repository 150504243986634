import { useState } from 'react'
import moment, { isMoment } from 'moment-timezone'
import i18next from 'i18next'

import Modal from '../../../../../../components/Modal'
import DateSelect from './components/DateSelect'
import PeriodSelect from './components/PeriodSelect'
import JustifyInput from './components/JustifyInput'
import FooterButtons from './components/FooterButtons'

import store from '../../../../../../redux/store'

import { Text } from '@leroy-merlin-br/backyard-react'
import { RescheduleProvider } from './context'
import { useServiceOrderDetailContext } from '../../../../context'
import { RescheduleService } from '../../../../../../services/pages/ServiceOrderDetail/RescheduleService'
import { hideSpinner, showSpinner } from '../../../../../../redux/actions/spinner'
import ReasonSelect from './components/ReasonSelect'
import { shifts } from '../../../../../../models/Distribute'

type RescheduledServiceType = {
  show: boolean
  setShow: Function
}

function RescheduledService(props: RescheduledServiceType) {
  const { show, setShow } = props
  const [reason, setReason] = useState<any>(null)
  const [justify, setJustify] = useState('')
  const [selectedDate, setSelectedDate] = useState<any>(null)
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null)
  const { serviceOrderBody, reloadServiceOrder }: any = useServiceOrderDetailContext()

  const { auth } = store.getState()
  const { setupParameters } = auth

  const handleJustifyText = (e: any) => setJustify(e.target.value)
  const handleChangeDate = (e: any) => {
    if (!isMoment(e)) return setSelectedDate(null)

    const newDate = e.startOf('day')
    if (+newDate === +selectedDate?.startOf('day')) return false

    setSelectedDate(newDate)
    setReason(null)
  }

  const handleChangePeriod = (e: any) => {
    if (e?.value === selectedPeriod?.value) return false

    setSelectedPeriod(e)
    setReason(null)
  }

  const handleClose = () => {
    setShow(false)

    setReason(null)
    setJustify('')
    setSelectedDate(null)
    setSelectedPeriod(null)
  }

  const handleSubmit = async () => {
    store.dispatch(showSpinner())

    const justification = reason ? `${reason.value}${justify ? ` - ${justify}` : ''}` : null

    const form = {
      servcOrdSeq: serviceOrderBody?.servcOrdSeq,
      servcOrdSchdlgTs: selectedDate.format(),
      servcOrdSchdlgShiftCd: shifts[selectedPeriod.value],
      justification,
    }

    const response = await RescheduleService(form, true)

    if (response.cdStatus === 200) {
      handleClose()
      await reloadServiceOrder()
    }

    store.dispatch(hideSpinner())
  }

  const modalTitle = 'customer.service.order.details.scheduling.date'
  const modalSubtitle = `${i18next.t('service.detail.reschedule.modal.subtitle')}.`

  const deliveryDate = moment(serviceOrderBody.servcOrdPrmsdDlvryDt).startOf('day')

  const hasDeliveryDate =
    setupParameters?.distributeServiceBlockBeforeDelivery && serviceOrderBody?.servcOrdPrmsdDlvryDt

  const deliveryTime = deliveryDate ? +deliveryDate : 0

  const today = moment().startOf('day')
  const todayTime = +today
  const currentTime = selectedDate ? +selectedDate.startOf('day') : 0

  const compareTodayDate = currentTime >= +todayTime
  const compareDeliveryDate = hasDeliveryDate ? currentTime >= +deliveryTime : true

  const providerProps = {
    serviceOrder: serviceOrderBody,
    selectedDate,
    selectedPeriod,
    setSelectedDate: handleChangeDate,
    setSelectedPeriod: handleChangePeriod,
    show,
  }

  return (
    <div id='reschedule-service'>
      <RescheduleProvider {...providerProps}>
        {show && (
          <Modal
            size='lg'
            isOpen={show}
            onClose={handleClose}
            className='no-margin-header'
            closeInOverlay={false}
            contentLabel={modalTitle}
          >
            <div className='reschedule-modal-content'>
              <Text noMargin>{modalSubtitle}</Text>

              <div className='flex-date-selects'>
                <div className='date-select'>
                  <DateSelect value={selectedDate} onChange={handleChangeDate} />
                </div>

                <div className='period-select'>
                  <PeriodSelect
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    selectedDate={selectedDate}
                    compareTodayDate={compareTodayDate}
                    compareDeliveryDate={compareDeliveryDate}
                  />
                </div>
              </div>

              <ReasonSelect
                value={reason}
                onChange={setReason}
                selectedDate={selectedDate}
                selectedPeriod={selectedPeriod}
              />

              <JustifyInput
                value={justify}
                onChange={handleJustifyText}
                selectedPeriod={selectedPeriod}
                selectedDate={selectedDate}
              />
            </div>

            <FooterButtons
              reason={reason}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              selectedDate={selectedDate}
              selectedPeriod={selectedPeriod}
            />
          </Modal>
        )}
      </RescheduleProvider>
    </div>
  )
}

export default RescheduledService
