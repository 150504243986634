import API_REQUEST from '../../core/Http/FetchAdapter'
import API_URL from '../../core/Envs/endpoints'
import User from '../../core/User'
import store from '../../redux/store'
import { ADEOResponse } from '../../models/AdeoResponse'
import ProviderEntity from '../../models/Provider'

const user = new User()

interface StoreApprovalServiceProviderRegistrationParams {
  providerId: ProviderEntity['servcPrvdrId']
  approved: boolean
  justificationText: string
}

export function storeApprovalServiceProviderRegistration(
  params: StoreApprovalServiceProviderRegistrationParams,
): Promise<ADEOResponse> {
  return new API_REQUEST(user.currentUser.dsToken, store.dispatch).post(
    API_URL.SAVE_STORE_APPROVAL,
    params,
  )
}
