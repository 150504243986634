import { useState } from 'react'
import i18next from 'i18next'
import User from '../../../../../../../core/User'

import CustomCard from '../../../../../../../components/Card'
import MozaicIcon from '../../../../../../../components/MozaicIcon'
import OutlineButton from '../../../../../../../components/OutlineButton'

import NewOccurrenceModal from './components/NewOccurrenceModal'
import OcurrencesTable from '../../../../../../../components/OcurrencesTable'

import { useServiceOrderDetailContext } from '../../../../../context'

export default function HistoricOccurrences() {
  const { fetchOccurrences, occurrences } = useServiceOrderDetailContext()
  const [showNewOccurrenceModal, setShowNewOccurrenceModal] = useState(false)

  const user = new User().currentUser
  const accessFunctionList = user?.accessFunctionList

  const handleCloseNewOccurrenceModal = (newOccurrenceCreated?: boolean) => {
    setShowNewOccurrenceModal(false)
    newOccurrenceCreated && fetchOccurrences()
  }

  const hasAccess91 = accessFunctionList?.includes(91)

  return (
    <div id='historic-occurrences'>
      <CustomCard
        styled
        title={i18next.t('serviceOrder.historic.occurrences.historic')}
        iconNode={<MozaicIcon icon='TimeTimeDecline32' />}
      >
        <div className='header-actions'>
          <div className='new-occurrence'>
            {hasAccess91 && (
              <OutlineButton onClick={() => setShowNewOccurrenceModal(true)}>
                {i18next.t('serviceOrder.historic.occurrences.registerNewOccurrence')}
              </OutlineButton>
            )}
          </div>
        </div>

        <OcurrencesTable occurrences={occurrences} />
      </CustomCard>

      {showNewOccurrenceModal && (
        <NewOccurrenceModal
          isOpen={showNewOccurrenceModal}
          onClose={handleCloseNewOccurrenceModal}
        />
      )}
    </div>
  )
}
