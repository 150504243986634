import i18next from 'i18next'
import { ClearIcon } from '../../../../../../../../../../../helpers/react-select-backyard-styles'
import MozaicTextArea from '../../../../../../../../../../../components/MozaicTextArea'

type JustifyInputType = {
  onChange: Function
  value: string
}

function JustifyInput(props: JustifyInputType) {
  const { onChange, value } = props

  const handleClear = () => {
    onChange({ target: { value: '' } })
  }

  const suffixIcons = () => (
    <>
      {value && (
        <div className='clear-filter' onClick={handleClear}>
          <ClearIcon />
        </div>
      )}
    </>
  )

  const label = i18next.t('communicated.Desc')
  const errorHintLabel = i18next.t('serviceOrder.warranty.description.validation')
  const placeholder = i18next.t('serviceOrder.litigation.type.desc')

  const errorHint = value.trim().length < 10

  const isValid = !errorHint && !!value.trim().length
  const isInvalid = errorHint

  return (
    <div className='justify-input-warranty'>
      <MozaicTextArea
        id='justify'
        label={label}
        value={value}
        isValid={isValid}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        maxLength={40960}
      />
      {suffixIcons()}

      {errorHint && <label className='error-hint'>{errorHintLabel}</label>}
    </div>
  )
}

export default JustifyInput
