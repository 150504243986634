export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export function getIdValues(items, key) {
  const listIds = []

  items.forEach((item) => {
    listIds.push(item[key])
  })

  return listIds
}
