import PropTypes from 'prop-types'
import { Component } from 'react'
import Select from 'react-select'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { colourStyles } from '../../../helpers/selectStyle'

class BranchSelectMultiple extends Component {
  state = {
    options: [],
    error: null,
    loading: false,
    localValues: [],
  }

  componentDidMount() {
    const { initialValues, servcPrvdrId } = this.props
    this.setState({ localValues: initialValues.lsBranches }, () => {
      this.findStores(servcPrvdrId)
    })
  }

  componentDidUpdate(prevProps) {
    const { initialValues, servcPrvdrId } = this.props
    if (
      initialValues.idEspecialistaServico &&
      initialValues.idEspecialistaServico !== prevProps.initialValues.idEspecialistaServico
    ) {
      this.setState({ localValues: initialValues.lsBranches }, () => {
        this.findStores(initialValues?.idPrestadorServico)
      })
    }

    if (
      servcPrvdrId &&
      initialValues?.idPrestadorServico !== servcPrvdrId &&
      servcPrvdrId !== prevProps.servcPrvdrId
    ) {
      this.setState({ localValues: [] }, () => {
        this.findStores(servcPrvdrId)
      })
    }
  }

  findStores = (servcPrvdrId) => {
    const { user, dispatch } = this.props

    /* istanbul ignore else */
    if (servcPrvdrId) {
      this.setState({ loading: true })

      const params = {
        servcPrvdrId,
      }

      return new Api(user.dsToken, dispatch)
        .get(API_URL.FIND_PROVIDER_STORES, params)
        .then((response) => this.onFetchSuccess(response.data))
        .catch((error) => this.onFetchError(error))
    }
    this.handleChange([])
    this.setState({ options: [], localValues: [] })
  }

  onFetchSuccess = (data) => {
    const { providerLinkedToAgent } = this.props
    const { localValues } = this.state

    const options = data.map((item) => ({
      value: item.id,
      label: `${item.id} - ${item.name}`,
    }))

    if (providerLinkedToAgent) {
      if (localValues && localValues.length > 0) {
        this.handleChange(localValues)
      } else {
        this.handleChange(options)
      }
    }

    this.setState({ options, loading: false, error: null })
  }

  onFetchError = (error) => {
    this.setState({ error, loading: false, data: [] })
  }

  handleChange = (value) => {
    const { onChange } = this.props

    /* istanbul ignore else */
    if (onChange) {
      onChange({ target: { value } })
    }
  }

  render() {
    const { style, t, placeholder, value, ...props } = this.props
    const { options, loading } = this.state

    let label = placeholder || t('serviceOrders.store')
    label = loading ? t('option.loading') : label

    return (
      <Select
        {...props}
        onChange={this.handleChange}
        placeholder={label}
        options={options}
        isClearable
        resetValue={[]}
        clearValueText={t('option.removeItem')}
        noOptionsMessage={() => t('select.noOptionsMessage')}
        classNamePrefix='react-select'
        isMulti
        closeMenuOnSelect={false}
        styles={style || colourStyles}
        value={value}
      />
    )
  }
}
BranchSelectMultiple.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  servcPrvdrId: PropTypes.string,
  value: PropTypes.any,
  providerLinkedToAgent: PropTypes.bool,
  initialValues: PropTypes.object,
}
export default BranchSelectMultiple
