import { keyBy, pickBy } from 'lodash'

import {
  FETCH_CHAT_LIST_REQUEST,
  FETCH_CHAT_LIST_SUCCESS,
  FETCH_CHAT_LIST_ERROR,
  FETCH_NOTIFICATIONS_ON_FIREBASE_SUCCESS,
  FILTER_CHAT_LIST,
  UPDATE_CHAT_LIST,
} from '../../actionTypes/chat'

const DEFAULT_STATE = {
  list: {
    records: {},
    filter: '',
    error: null,
    isFetching: false,
  },
  autocomplete: {
    records: [],
    error: null,
    isFetching: false,
  },
}

//* *@deprecated */
const list = (state = DEFAULT_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case FETCH_CHAT_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }

    case FETCH_CHAT_LIST_SUCCESS:
      const msg = payload.filter((e) => e && e.uid)
      const records = keyBy(msg, (chatRecord) => chatRecord.uid)

      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: null,
          records,
        },
      }

    case FETCH_CHAT_LIST_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          records: {},
          error: payload,
        },
      }

    case FETCH_NOTIFICATIONS_ON_FIREBASE_SUCCESS:
      const chatRecord = state.list.records[payload.uid]

      return {
        ...state,
        list: {
          ...state.list,
          records: {
            ...state.list.records,
            [payload.uid]: {
              ...chatRecord,
              unreadQty: (chatRecord.unreadQty || 0) + payload.qty,
            },
          },
        },
      }

    case FILTER_CHAT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          filter: payload,
        },
      }

    case UPDATE_CHAT_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          records: {
            ...state.list.records,
            [payload.uid]: {
              ...state.list.records[payload.uid],
              ...payload.values,
            },
          },
        },
      }

    default:
      return state
  }
}

export const getChatList = (state) => {
  const { list } = state.chatReducer
  const { filter } = list
  let filteredRecords = list.records

  if (filter !== '') {
    filteredRecords = pickBy(
      filteredRecords,
      (value) => value.dsChat.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
    )
  }

  return filteredRecords
}

export const getUnreadMessages = (state) => {
  const { list } = state.chatReducer
  let counter = 0

  Object.keys(list.records).forEach((key) => {
    const record = list.records[key]
    counter += record.unreadQty || 0
  })

  return counter
}

export default list
