import { memo } from 'react'
import i18next from 'i18next'
import BreadcrumbBar from '../../components/BreadcrumbBar'

import { UsableAreaProviderConnected as UsableArea } from '../../contexts/usableArea'
import { formatServiceOrderNumber } from '../../helpers/masks'
import MozaicIcon from '../../components/MozaicIcon'
import Project from './components/Routes/Project'
import Chat from './components/Routes/Chat'
import Attachments from './components/Routes/Attachments'
import Ocurrences from './components/Routes/Ocurrences'
import Tabs from '../../components/Tabs'
import { ProjectDetailsProvider } from './context'

function ProjectsDetail(props: any) {
  const {
    match: {
      params: { projectID },
    },
  } = props

  const routes = [
    {
      path: '/service',
      label: i18next.t('entity.project'),
      icon: <MozaicIcon icon='BookStore24' size={22} />,
      component: <Project />,
    },
    {
      path: '/chat',
      label: i18next.t('lmi.chat'),
      icon: <MozaicIcon icon='CommentCommunity32' size={22} />,
      component: <Chat />,
    },
    {
      path: '/attachments',
      label: i18next.t('attachments.text'),
      icon: <MozaicIcon icon='Imagealt48' size={22} />,
      component: <Attachments />,
    },
    {
      path: '/ocurrences',
      label: i18next.t('serviceOrders.occurrences'),
      icon: <MozaicIcon icon='TimeRead32' size={22} />,
      component: <Ocurrences />,
    },
  ]

  return (
    <>
      <BreadcrumbBar
        title={`${i18next.t('entity.project')} ${formatServiceOrderNumber(projectID)}`}
      />

      <UsableArea className='projects-mobile'>
        <ProjectDetailsProvider projectID={projectID}>
          <div id='projects-page'>
            <Tabs routes={routes} style='mozaic' />
          </div>
        </ProjectDetailsProvider>
      </UsableArea>
    </>
  )
}

export default memo(ProjectsDetail)
