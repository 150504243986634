const migrations = {
  2: async () => {},
}

export const runMigration = async (v, customerChat) => {
  const migration = migrations[v]

  if (migration)
    migration(customerChat).then(() => {
      customerChat.doc('version').update({ version: v })

      const _migration = migrations[v + 1]

      if (_migration) runMigration(v + 1, customerChat)
    })
}
