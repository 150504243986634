import SummaryMain from '../../SummaryMain'
import Documents from './components/Documents'
import Photos from './components/Photos'

export default function Attachments() {
  return (
    <div id='attachments-container'>
      <SummaryMain />
      <Photos />
      <Documents />
    </div>
  )
}
