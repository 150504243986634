import i18next from 'i18next'

import BodySection from '../../BodySection'
import FormField from '../../../../../../../../../../../components/FormField'
import { ServiceExecutionEntity } from '../../../../../../../../../../../models/ServiceExecution'
import { formatDecimalNoCurrency } from '../../../../../../../../../../../helpers/masks'

function Values({ service }: { service: ServiceExecutionEntity }) {
  const item = service?.serviceOrderItem?.at(0)
  const productAmt = item?.servcOrdItemQtyAcquired || 0
  const paymentAmt = item?.servcOrdItemPrvdrPymtAmt || 0

  const unitAmt = `${i18next.t('currencySymbol')} ${formatDecimalNoCurrency(
    (service.servcOrdAmt * 100) / productAmt,
  )}`

  const totalAmt = `${i18next.t('currencySymbol')} ${formatDecimalNoCurrency(
    service.servcOrdAmt * 100,
  )}`
  const unitTransferAmt = `${i18next.t('currencySymbol')} ${formatDecimalNoCurrency(
    paymentAmt * 100,
  )}`
  const totalTransferAmt = `${i18next.t('currencySymbol')} ${formatDecimalNoCurrency(
    paymentAmt * 100 * productAmt,
  )}`

  return (
    <BodySection title={i18next.t('prices.valid')}>
      <FormField label={i18next.t('sell.unit')} value={unitAmt} />
      <FormField label={i18next.t('sell.total')} value={totalAmt} />
      <FormField label={i18next.t('transfer.unit')} value={unitTransferAmt} />
      <FormField label={i18next.t('transfer.total')} value={totalTransferAmt} />
    </BodySection>
  )
}

export default Values
