import User from '../../../core/User'
import ServiceOrderStatusType from '../../../enums/ServiceOrder/status/ServiceOrderStatusType'
import { fetchInstallersDistributionList } from '../../InstallerList'
import { isNil } from 'lodash'

const user = new User().currentUser

export const fetchInstallers = async (serviceOrder: any, providerCd: string, scheduleDate: any, shiftCd: string) => {
  const { servcOrdStusCd } = serviceOrder

  let filter: any = {
    schdlDt: scheduleDate.format('YYYY-MM-DDTh:mm:ss'),
    shiftCd: shiftCd || serviceOrder?.servcOrdSchdlgShiftCd,
    servcPrvdrId: providerCd,
    servcOrdSeq: serviceOrder.servcOrdSeq,
    showUnavailable: true,
  }

  // Se o status é diferente de 00 e 01 enviar novos filtros
  if (
    servcOrdStusCd &&
    servcOrdStusCd !== ServiceOrderStatusType.STATUS_PRE_AGENDADA.id &&
    servcOrdStusCd !== ServiceOrderStatusType.STATUS_AGENDADA.id &&
    !(
      servcOrdStusCd === ServiceOrderStatusType.STATUS_DISTRIBUIDA.id &&
      serviceOrder.servcPrvdrAgntCd === null
    )
  ) {
    filter = {
      schdlDt: scheduleDate.format('YYYY-MM-DDTh:mm:ss'),
      servcPrvdrId: serviceOrder.providerCd,
      servcPrvdrAgntId: serviceOrder.servcPrvdrAgntCd,
    }
  }

  if (!isNil(providerCd)) {
    const installers = await fetchInstallersDistributionList(user.dsToken, filter)
    return installers
  }
  return []
}
