import { ChangeEventHandler } from 'react'
import { CheckBox, ICheckBoxProps } from '@mozaic-ds/react'

interface MozaicCheckBoxProps extends ICheckBoxProps {
  label: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

function MozaicCheckBox(props: MozaicCheckBoxProps) {
  const { label, onChange, isIndeterminate, isChecked } = props

  const indeterminate = isIndeterminate === null ? true : isIndeterminate

  const handleChange = () => onChange(!isChecked as any)
  const newProps = { ...props, onChange: handleChange, isIndeterminate: indeterminate }

  return <CheckBox {...newProps}>{label}</CheckBox>
}

export default MozaicCheckBox
