import i18next from 'i18next'
// @ts-ignore
import Select, { ValueType } from 'react-select'
import { colourStyles } from '../../helpers/selectStyle'

type Option = {
  value: any
  label: string
}

type BackyardSelectType = {
  onChange: Function
  value: ValueType<Option, false>
  options: Array<Option>
  label?: string
  placeholder?: string
  hint?: string
  id?: string
}

function BackyardSelect(props: BackyardSelectType) {
  const { onChange, value, options, label, placeholder, hint, id } = props

  const handleChange = (e: any) => {
    onChange(e)
    if (e === null) onChange(null)
  }

  const selectedClass = value ? 'has-value' : ''

  return (
    <div className={`backyard-select ${selectedClass}`}>
      {label && <label className='react-select--label'>{label}</label>}

      <Select
        id={id}
        value={value}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        styles={colourStyles}
        closeMenuOnSelect
        blurInputOnSelect={false}
        isClearable
        menuPosition='fixed'
      />

      {hint && <label className='react-select--footer'>{hint}</label>}
    </div>
  )
}

export default BackyardSelect
