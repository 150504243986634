import { createContext } from 'react'
import { Installer } from '../../../services/installers/fetchInstallersList'
import { FilterValues } from '../components/Filter'

export interface InstallersListContextProps {
  installers: Installer[]
  page: number
  pages: number
  total: number
  isLoading: boolean
  filter: FilterValues
  setPage: (page: number) => void
  setFilter: (filter: FilterValues) => void
  onExport: () => void
  setInstallers: (installers: Installer[]) => void
}

const InstallersListContext = createContext<InstallersListContextProps>({
  installers: [],
  page: 1,
  pages: 1,
  total: 0,
  isLoading: false,
  filter: { q: '', active: '', receivingOrder: '', stores: [] },
  setPage: () => null,
  setFilter: () => null,
  onExport: () => null,
  setInstallers: () => null,
})

InstallersListContext.displayName = 'InstallersListContext'

export default InstallersListContext
