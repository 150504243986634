import i18next from 'i18next'
import { useEffect, useState } from 'react'
// @ts-ignore
import Select from 'react-select'

import API_URL from '../../core/Envs/endpoints'
import Api from '../../core/Http/FetchAdapter'
import User from '../../core/User'
import { colourStyles } from '../../helpers/selectStyle'
import { Category } from '../../models/Category'
import store from '../../redux/store'

const user = new User().currentUser

type optionType = {
  value: any
  label: string
}

type CategorySelectType = {
  onChange: Function
  value: Array<optionType>
}

function CategorySelect(props: CategorySelectType) {
  const { onChange, value } = props
  const [options, setOptions] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchCategories = async () => {
    setLoading(true)

    const { data: serviceList } = await new Api(user.dsToken, store.dispatch).get(
      API_URL.SUBCATEGORIES_FETCH_FILTERED_ITENS,
      { page: 1, size: 9999 },
    )

    const options = serviceList.map((category: Category) => ({
      value: category.servcCatgryTypCd,
      label: category.servcCatgryTypDesc,
    }))

    setOptions(options)
    setLoading(false)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const handleChange = (e: any) => {
    onChange(e)
    if (e === null) onChange([])
  }

  const label = i18next.t('communicates.header.table.category')
  const placeholder = `${i18next.t('search')} ${i18next.t('all.text').toLowerCase()}`
  const footer = i18next
    .t('advanced.search.select.footer.m')
    .replace('{type}', i18next.t('provider.portal.category').toLowerCase())

  return (
    <div className='category-select'>
      <label className='react-select--label'>{label}</label>

      <Select
        value={value}
        options={options}
        onChange={handleChange}
        isDisabled={loading}
        placeholder={placeholder}
        clearValueText={i18next.t('option.removeItem')}
        classNamePrefix='react-select'
        resetValue={[]}
        styles={colourStyles}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        isClearable
        isMulti
        menuPosition='absolute'
      />

      <label className='react-select--footer'>{footer}</label>
    </div>
  )
}

export default CategorySelect
