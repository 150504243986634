/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import * as messageReducers from './messages'
import auth from './auth'
import i18nReducer from './I18n'
import spinnerReducer from './spinner'
import menuReducer from './menu'
import newCockpitReducer from './newCockpit'
import serviceOrderReducer from './serviceOrder'
import paginateReducer from './pagination'
import serviceOccurrenceReducer from './serviceOccurrence'
import serviceOrderMailReducer from './serviceOrderMail'
import chatReducer from './chat'
import publicServcOrdReducer from './publicServcOrd'
import servcPrvdrSchdlSgsttnReducer from './servcPrvdrSchdlSgsttn'

const rootReducer = combineReducers({
  auth,
  i18nReducer,
  menuReducer,
  newCockpitReducer,
  spinnerReducer,
  paginateReducer,
  serviceOrderReducer,
  serviceOccurrenceReducer,
  serviceOrderMailReducer,
  chatReducer,
  servcPrvdrSchdlSgsttnReducer,
  publicServcOrdReducer,
  userMessage: messageReducers.userMessage,
  messages: messageReducers.messages,
})

export default rootReducer
