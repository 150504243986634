import i18next from 'i18next'
import { useMemo } from 'react'

import { ChevronDownSolid } from '@leroy-merlin-br/backyard-icons'
import { Select, TextField } from '@leroy-merlin-br/backyard-react'
import { MultiSelect } from 'react-multi-select-component'
import {
  getIsActiveOptions,
  getReceivingOsOptions,
} from '../../../../contexts/providerAnalysis/utils'

import Accordeon from '../../../../components/Accordeon'
import MultiSelectClusters, { clearButton } from '../../../../components/MultiSelectClusters'
import MultiSelectStore from '../../../../components/MultiSelectStore'
import SelectPeriod from '../../../../components/SelectPeriod'
import Spacer from '../../../../components/Spacer'

import { useProviderAnalysisListContext } from '../../../../contexts/providerAnalysis'

import MozaicButton from '../../../../components/MozaicButton'
import MozaicIcon from '../../../../components/MozaicIcon'
import MozaicRadio from '../../../../components/MozaicRadio'

function ChevronDown() {
  return <ChevronDownSolid className='css-1opf1jk-input__suffix' color='#BAB6BC' />
}

function Filter({
  periodType,
  stores,
  storesOptions,
  clustersOptions,
  clusters,
  period,
  disabledStores,
  disabledClusters,
  companyInfo,
  results,
  selectAllStores,
  selectAllClusters,
  handleChangeStores,
  handleChangeClusters,
  handleChangeCompanyInfo,
  handleChangePeriod,
  handleChosePeriod,
  handleClearFilters,
  handleFilterData,
  isActive,
  setIsActive,
  receivingOs,
  setReceivingOs,
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
}: any) {
  const totalResults = useMemo(
    () => i18next.t('filtered.results').replace('{count}', results.length),
    [results],
  )
  const isActiveOptions = useMemo(() => getIsActiveOptions(), [])
  const receivingOsOptions = useMemo(() => getReceivingOsOptions(), [])

  const accordeonProps = {
    title: i18next.t('filters.text'),
    icon: <MozaicIcon icon='DisplayFilter24' className='icon-custom' />,
  }

  return (
    <section id='provider-analysis-list-filter'>
      <Accordeon header={accordeonProps}>
        <section className='content'>
          <section className='line-1'>
            <section className='period'>
              <section className='radio-group-period'>
                <span className='css-fat8q2-label'>{i18next.t('data.aggregation.text')}</span>
                <section className='radio-groups'>
                  <MozaicRadio
                    name='radio-group-period'
                    value='yearly'
                    onChange={handleChosePeriod}
                    isChecked={periodType === 'yearly'}
                    label={i18next.t('yearly.text')}
                  />
                  <MozaicRadio
                    name='radio-group-period'
                    value='monthly'
                    onChange={handleChosePeriod}
                    isChecked={periodType === 'monthly'}
                    label={i18next.t('monthly.text')}
                  />
                </section>
              </section>

              <SelectPeriod
                optionType={periodType}
                onChange={handleChangePeriod}
                value={period}
                label={i18next.t('select.period.label.text')}
                placeholder={i18next.t('select.period.placeholder.text')}
              />
            </section>
            <section className='company'>
              <TextField
                value={companyInfo}
                label={i18next.t('parcial.input.text.provider.label')}
                placeholder={i18next.t('parcial.input.text.provider.placeholder')}
                onChange={(event) => handleChangeCompanyInfo(event)}
                autoComplete='off'
                id='comapany-info-input'
              />
            </section>
          </section>

          <section className='line-2'>
            <section className='store-clusters'>
              <MultiSelectStore
                label={i18next.t('branches.text')}
                onChange={(e: any) => handleChangeStores(e)}
                value={stores}
                options={storesOptions}
                disabled={disabledStores}
                hasSelectAll={selectAllStores}
              />

              <MultiSelectClusters
                label={i18next.t('lmi.cores')}
                onChange={(e: any) => handleChangeClusters(e)}
                value={clusters}
                options={clustersOptions}
                disabled={disabledClusters}
                hasSelectAll={selectAllClusters}
              />
            </section>
          </section>

          <section className='line-3'>
            <section className='is-active-select'>
              <Select
                label={i18next.t('menu.link.register')}
                options={isActiveOptions}
                value={isActive}
                placeholder={i18next.t('order.by.input.placeholder')}
                onChange={(event: any) => setIsActive(event.target.value)}
              />
            </section>

            <section className='receiving-os-select'>
              <Select
                label={i18next.t('serviceProvider.actvServcPrvdrOrdRecvInd')}
                options={receivingOsOptions}
                value={receivingOs}
                placeholder={i18next.t('order.by.input.placeholder')}
                onChange={(event: any) => setReceivingOs(event.target.value)}
              />
            </section>

            <section className='columns-select'>
              <span className='css-fat8q2-label'>{i18next.t('columns')}</span>
              <MultiSelect
                labelledBy='.css-fat8q2-label'
                disableSearch
                options={columnsOptions}
                value={selectedColumns}
                onChange={setSelectedColumns}
                ClearSelectedIcon={clearButton()}
                ArrowRenderer={ChevronDown}
                overrideStrings={{
                  selectSomeItems: i18next.t('columns.placeholder'),
                  search: i18next.t('search.text'),
                  allItemsAreSelected: i18next.t('all.text'),
                  selectAll: i18next.t('all.text'),
                  selectAllFiltered: i18next.t('all.text'),
                  noOptions: i18next.t('all.text'),
                }}
                hasSelectAll
              />
            </section>
          </section>

          <Spacer background={'#EEEEF0' as any} margin='0px' className='custom-spacer' />

          <section className='footer-buttons'>
            {results.length > 0 ? <span className='total'>{totalResults}</span> : <div />}

            <section className='buttons'>
              <MozaicButton variant='ghost' theme='primary-02' onClick={() => handleClearFilters()}>
                {i18next.t('button.label.reset.filters')}
              </MozaicButton>
              <MozaicButton onClick={() => handleFilterData()}>
                {i18next.t('button.label.submit.filters')}
              </MozaicButton>
            </section>
          </section>
        </section>
      </Accordeon>
    </section>
  )
}

function FilterWithContext(props: any) {
  const {
    periodType,
    stores,
    storesOptions,
    clustersOptions,
    clusters,
    orderBy,
    orderBySense,
    period,
    disabledStores,
    disabledClusters,
    companyInfo,
    results,
    selectAllStores,
    selectAllClusters,
    setOrderBySense,
    handleChangeOrderBy,
    handleChangeStores,
    handleChangeClusters,
    handleChangeCompanyInfo,
    handleChangePeriod,
    handleChosePeriod,
    handleClearFilters,
    handleFilterData,
    isActive,
    setIsActive,
    receivingOs,
    setReceivingOs,
    columnsOptions,
    selectedColumns,
    setSelectedColumns,
  } = useProviderAnalysisListContext()

  const newProps = useMemo(
    () => ({
      setOrderBySense,
      periodType,
      stores,
      storesOptions,
      clustersOptions,
      clusters,
      orderBy,
      orderBySense,
      period,
      disabledStores,
      disabledClusters,
      companyInfo,
      results,
      selectAllStores,
      selectAllClusters,
      handleChangeOrderBy,
      handleChangeStores,
      handleChangeClusters,
      handleChangeCompanyInfo,
      handleChangePeriod,
      handleChosePeriod,
      handleClearFilters,
      handleFilterData,
      isActive,
      setIsActive,
      receivingOs,
      setReceivingOs,
      columnsOptions,
      selectedColumns,
      setSelectedColumns,
    }),
    [
      periodType,
      stores,
      storesOptions,
      clustersOptions,
      clusters,
      orderBy,
      orderBySense,
      period,
      disabledStores,
      disabledClusters,
      companyInfo,
      results,
      selectAllStores,
      selectAllClusters,
      isActive,
      receivingOs,
      columnsOptions,
      selectedColumns,
      setSelectedColumns,
    ],
  )

  return <Filter {...props} {...newProps} />
}

export default FilterWithContext
