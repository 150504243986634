import { Component } from 'react'
import PropTypes from 'prop-types'
import Row from '../../../components/utils/Row'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Preview from '../../../components/utils/preview/Link'
import DateSelect from '../../../components/DateSelect'
import ServiceModalV2 from './ServiceModalV2'
import SlotSelect from '../../CreateServiceOrder/components/SlotSelect'
import moment from 'moment-timezone'

class ServicesListItemV2 extends Component {
  state = {
    fileName: null,
    showModal: false,
    disabledDateToChoose: true,
  }

  componentDidMount() {
    const { pyxisInformations } = this.props

    if (pyxisInformations && Object.keys(pyxisInformations).length > 0) {
      this.updateByPyxis()
    }
  }

  componentDidUpdate(prevProps) {
    const { pdvRequest, setFormValue } = this.props

    if (pdvRequest && pdvRequest !== prevProps.pdvRequest) {
      setFormValue({
        scheduleDate: moment(pdvRequest.scheduleDate),
        scheduleShift: pdvRequest.shiftSelected,
      })
    }
  }

  updateFileName = (fileName) => {
    this.setState({ fileName })
  }

  getTomorrow = () => {
    const { daysDisabled } = this.props
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + daysDisabled)
    tomorrow.setHours(0, 0, 0, 0)
    return tomorrow
  }

  maskValueforSubmit = (value) => {
    const { t } = this.props

    if (value) {
      const formattedValue = value.replace(
        new RegExp(`\\${t('delimiter.currency')}|\\${t('separator.currency')}`, 'g'),
        '',
      )
      const decimalIndex = formattedValue.length - 2
      return `${formattedValue.substring(0, decimalIndex)}.${formattedValue.substring(
        decimalIndex,
      )}`
    }
    return '0.00'
  }

  maskNumber(value, currency = false) {
    const { t } = this.props
    return (
      (currency ? `${t('option.currency')} ` : '') +
      parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    )
  }

  onModalSubmit = (values) => {
    const { servicesList, updateServices } = this.props

    const servicesListNew = [...servicesList]

    servicesListNew.push({
      code: values.serviceCode,
      desc: values.serviceDesc,
      quantity: values.serviceQuantity ? this.maskValueforSubmit(values.serviceQuantity) : '0.00',
      value: this.maskValueforSubmit(values.serviceValue),
      associatedProductCode: values.serviceAssociatedProductCode,
      associatedProductName: values.serviceAssociatedProductName,
      associatedProductQuantity: values.serviceAssociatedProductQuantity
        ? this.maskValueforSubmit(values.serviceAssociatedProductQuantity)
        : '0.00',
      status: '00',
      sequence: 0,
    })

    this.setState({ showModal: false }, () => {
      updateServices(servicesListNew)
    })
  }

  disableCalendar = () => {
    const { disabledDateToChoose } = this.state
    return disabledDateToChoose
  }

  renderModal = () => {
    const { t, user, dispatch, issuerPlant } = this.props

    const { showModal } = this.state

    if (!showModal) return

    return (
      <ServiceModalV2
        closeModal={() => this.setState({ showModal: false })}
        t={t}
        onSubmit={this.onModalSubmit}
        plntCd={issuerPlant}
        user={user}
        dispatch={dispatch}
      />
    )
  }

  renderTable() {
    const { t, servicesList, removeService } = this.props

    if (!servicesList || servicesList.length === 0) return

    return (
      <div className='itens-container'>
        <table>
          <thead>
            <tr>
              <th width='18' />
              <th className='align-left'>{t('servcPrc.servcCd')}</th>
              <th className='align-left'>{t('servcOrd.services.desc')}</th>
              <th className='align-center'>{t('service.order.detatails.qtt')}</th>
              <th className='align-center'>{t('servcOrd.service.unit')}</th>
              <th className='align-center'>{t('servcOrd.service.sub')}</th>
              <th className='align-center'>{t('option.delete')}</th>
              <th width='18' />
            </tr>
          </thead>
          <tbody>
            {servicesList.map((service, i) => {
              const subtotal = parseFloat(service.quantity) * parseFloat(service.value)
              return (
                <tr key={i}>
                  <td width='18' />
                  <td className='align-left'>{service.code}</td>
                  <td className='align-left'>{service.desc}</td>
                  <td className='align-center'>{this.maskNumber(service.quantity)}</td>
                  <td className='align-center'>{this.maskNumber(service.value)}</td>
                  <td className='align-center'>{this.maskNumber(subtotal)}</td>
                  <td className='align-center'>
                    <a role='button' onClick={() => removeService(i)}>
                      <i className='glyph glyph-trash-can' />
                    </a>
                  </td>
                  <td width='18' />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  checkModal = () => {
    this.setState({ showModal: true })
  }

  isDayBlocked = (day) => {
    const { auth } = this.props

    const daysOfWeekDisabled = auth.setupParameters.disabledCalendarDays

    const numberDayWeek = day.day() + 1

    let enable = 'false'
    if (String(daysOfWeekDisabled).indexOf(numberDayWeek) !== -1) {
      enable = String(daysOfWeekDisabled).substring(
        String(daysOfWeekDisabled).indexOf(numberDayWeek),
      )
      enable = enable.substring(enable.indexOf('=') + 1)
      enable = enable.substring(0, enable.indexOf(';') === -1 ? 10 : enable.indexOf(';'))
    }

    return enable === 'true'
  }

  render() {
    const {
      t,
      user,
      user: { funcaoAcesso },
      auth,
      dispatch,
      fields,
      values,
      handleChange,
    } = this.props

    const { fileName } = this.state

    return (
      <div className='services-list-item'>
        <Row>
          <Col xs={12} md={4}>
            <Field
              {...fields.scheduleDate}
              error={
                values.errors.indexOf('scheduleDate') !== -1 && !values.scheduleDate
                  ? t('validation.message.is.required')
                  : null
              }
              validate
              className='scheduleDate'
            >
              <label className='label group-label'>
                {t('customer.service.order.reschedule.date')}
              </label>
              <DateSelect
                id='scheduleDate'
                value={values.scheduleDate}
                onChange={(e) => handleChange('scheduleDate', e)}
                placeholder={t('servcOrd.service.group.date.placeholder')}
                isOutsideRange={(day) => day.isBefore(this.getTomorrow())}
                isDayBlocked={this.isDayBlocked}
              />
            </Field>
          </Col>
          <Col xs={12} md={4}>
            <Field
              {...fields.scheduleShift}
              error={
                values.errors.indexOf('scheduleShift') !== -1 && !values.scheduleShift
                  ? t('validation.message.is.required')
                  : null
              }
              validate
            >
              <label className='label group-label'>
                {t('customer.service.order.reschedule.shift')}
              </label>
              <SlotSelect
                id='scheduleShift'
                {...fields.scheduleShift.input}
                slots={null}
                t={t}
                onChange={(e) => handleChange('scheduleShift', e ? e.value : null)}
                auth={auth}
                isClearable={false}
              />
            </Field>
          </Col>
          {fileName && funcaoAcesso.inFuncao77 && (
            <Col xs={12} md={6}>
              <Preview
                t={t}
                className='button button-primary button-full align-center'
                children={t('serviceGroup.download.termsAndConditions')}
                filePath={fileName}
                token={user.dsToken}
                dispatch={dispatch}
              />
            </Col>
          )}
        </Row>

        {this.renderTable()}

        <Row>
          <Col xs={12} className='margin-top-double'>
            <button type='button' className='button button-primary' onClick={this.checkModal}>
              {t('servcOrd.services.create')}
            </button>
          </Col>
        </Row>
        {this.renderModal()}
      </div>
    )
  }
}

ServicesListItemV2.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  values: PropTypes.object,
  setFormValue: PropTypes.func,
  issuerPlant: PropTypes.any,
  updateServices: PropTypes.func,
  removeService: PropTypes.func,
  servicesList: PropTypes.array,
  pdvRequest: PropTypes.object,
  pyxisInformations: PropTypes.object,
  getFileName: PropTypes.func.isRequired,
  daysDisabled: PropTypes.number.isRequired,
  handleChange: PropTypes.func,
}

export default ServicesListItemV2
export { ServicesListItemV2 }
