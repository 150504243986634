import i18next from 'i18next'
import { toast } from '@leroy-merlin-br/backyard-react'

import API_REQUEST from '../../../core/Http/FetchAdapter'
import API_URL from '../../../core/Envs/endpoints'

import User from '../../../core/User'
import store from '../../../redux/store'

const user = new User().currentUser

type ParamsType = {
  servcOrdWrntDesc: string
  scheduledDate: string
  scheduledTurn: string
  reasonId: string
  servcOrdOrignSeq: number
  isSameProvider: boolean
}

export const warrantyActivation = async (params: ParamsType) => {
  try {
    const resp = await new API_REQUEST(user.dsToken, store.dispatch).post(
      API_URL.WARRANTY_ACTIVATION,
      params,
    )

    toast.success(
      i18next.t('success.text'),
      i18next.t('serviceOrders.occurrences.servcOrdEvntStusInd.87'),
      '',
    )

    return resp
  } catch (error: any) {
    toast.error('', error?.message?.dsMessage || i18next.t('dao.save.error'), '')
    return console.error(error)
  }
}
