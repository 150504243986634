import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Component } from 'react'
import MozaicHeading from '../../../components/MozaicHeading'
import MozaicText from '../../../components/MozaicText'
import MozaicTextArea from '../../../components/MozaicTextArea'
import Col from '../../../components/utils/Col'
import Field from '../../../components/utils/Field'
import Input from '../../../components/utils/Input'
import Row from '../../../components/utils/Row'
import AwsLightbox from '../../../components/utils/images/AwsLightbox'
import TelephoneInput from '../../../components/utils/internationalPhoneInput/phoneInput'
import API_URL from '../../../core/Envs/endpoints'
import Api from '../../../core/Http/FetchAdapter'
import { validatePhoneNumber } from '../../../helpers/InternationalPhoneNumberUtils'
import { renderToastError } from '../../../helpers/ToastUtils'
import { getCEPMask, getCPFMask, removeMask } from '../../../helpers/masks'
import { WithForm } from '../../../helpers/useForm/withForm'
import { isEmpty } from '../../../helpers/validations'
import { hideSpinner, showSpinner } from '../../../redux/actions/spinner'
import BranchSelect from '../../CreateServiceOrder/components/BranchSelect'
import ServiceListItemV2 from './ServiceListItemV2'
import BusinessUnitType from '../../../enums/ServiceOrder/user/BusinessUnitType'

const FIELDS = {
  customerFiscalId: '',
  customerName: '',
  customerEmail: '',
  customerPhoneNumber: '',
  customerPhoneNumber2: '',
  customerPhoneNumber3: '',
  customerAddressCountry: '',
  customerAddressState: '',
  customerAddressCity: '',
  customerAddressStreetName: '',
  customerAddressStreetNumber: '',
  customerAddressPostalCode: '',
  customerDistrict: '',
  customerAddressComplement: '',
  customerAddressReference: '',
  issuerPlant: '',
  issuerPlantName: '',
  scheduleDate: null,
  scheduleShift: 'M',
  note: '',
  servicesList: [],
  errors: [],
}

class CreateServcOrdEditFormV2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      serviceAccessoryProducts: [],
      fileName: null,
      customerPhoneNumber: '',
      customerPhoneNumber2: '',
      customerPhoneNumber3: '',
      apiAddress: {},
      isDisabled: true,
      lightboxOpen: false,
      coupon: false,
      daysDisabled: props.auth.setupParameters.disabledDaysGlobal || 2,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeIssuerPlant = this.handleChangeIssuerPlant.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.clearFields = this.clearFields.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const { fields, values } = props
    const { customerPhoneNumber } = state

    const servicesList = values.servicesList

    const requiredFields = [
      'scheduleShift',
      'issuerPlant',
      'customerName',
      'customerAddressPostalCode',
      'customerAddressStreetName',
      'customerAddressStreetNumber',
    ]
    const isInvalid = requiredFields.some((field) => isEmpty(fields[field].input.value))

    const hasAnyEmptyPhone = !isEmpty(customerPhoneNumber || '')
    const hasAnyInvalidPhone = !(
      !isEmpty(customerPhoneNumber || '') && !validatePhoneNumber(customerPhoneNumber)
    )
    let hasItens = false

    if (servicesList.length > 0) {
      servicesList.forEach((service) => {
        if (
          !isEmpty(service.code) &&
          !isEmpty(service.value) &&
          !isEmpty(service.quantity) &&
          service.quantity !== '0.00'
        ) {
          hasItens = true
        }
      })
    }

    const hasValidMail = !fields.customerEmail.error

    return {
      isDisabled:
        isInvalid || !hasAnyEmptyPhone || !hasAnyInvalidPhone || !hasItens || !hasValidMail,
    }
  }

  componentDidMount() {
    this.clearFields()
  }

  componentDidUpdate(prevProps) {
    const { pyxisInformations, setFormValue, pdvRequest } = this.props

    if (pdvRequest && pdvRequest !== prevProps.pdvRequest) {
      this.updateByPdvRequest()
    }

    if (
      pyxisInformations &&
      pyxisInformations !== prevProps.pyxisInformations &&
      pyxisInformations.cdStatus === 200
    ) {
      if ((pyxisInformations.data.serviceList || []).length > 0) {
        this.addPyxisServices(pyxisInformations.data.serviceList)
      }

      Object.keys(pyxisInformations.data).forEach((field) => {
        if (!['value', 'customerAddressStreetNumber'].includes(field)) {
          setFormValue({ [field]: pyxisInformations.data[field] })
        }
      })

      if (pyxisInformations.data.customerAddressStreetNumber) {
        setFormValue({
          customerAddressStreetNumber: pyxisInformations.data.customerAddressStreetNumber.substring(
            0,
            10,
          ),
        })
      }
    }
  }

  getTotalValue() {
    const { values } = this.props

    if (values.servicesList.length === 0) {
      return 0
    }

    // Sum all values of services
    let val = 0
    values.servicesList.forEach((service) => {
      val += parseFloat(service.value) * parseFloat(service.quantity)
    })
    return val
      .toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(',', '')
  }

  addPyxisServices = (pyxisServicesList) => {
    const servicesList = []

    // Mapping pyxis services to our services
    pyxisServicesList.forEach((servc) => {
      servicesList.push({
        code: servc.serviceCode,
        desc: servc.serviceDescription,
        quantity: servc.serviceQuantity
          ? this.maskValueforSubmit(
              servc.serviceQuantity
                .toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                .replace(',', ''),
            )
          : '0.00',
        value: this.maskValueforSubmit(
          servc.serviceValue
            .toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            .replace(',', ''),
        ),
        associatedProductCode: servc.serviceAssociatedProductCode,
        associatedProductName: servc.serviceDescription,
        associatedProductQuantity: servc.serviceAssociatedProductQuantity
          ? this.maskValueforSubmit(
              servc.serviceAssociatedProductQuantity
                .toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                .replace(',', ''),
            )
          : '0.00',
        status: '00',
        sequence: 0,
      })
    })

    // Update our services list
    this.updateServices(servicesList)
  }

  updateByPdvRequest() {
    const { pdvRequest, servcRqstCd, setFormValue, user, dispatch } = this.props

    setFormValue({
      sourceOrderNumber: servcRqstCd,
      customerFiscalId: pdvRequest.custFiscalId,
      customerName: pdvRequest.custName,
      customerEmail: pdvRequest.custEmail,
    })

    this.handlePhoneNumberChange(
      'customerPhoneNumber',
      `+55${pdvRequest.custMobilePhone.replace(/\D/g, '')}`,
    )

    if (pdvRequest.custPhone) {
      setTimeout(() => {
        this.handlePhoneNumberChange(
          'customerPhoneNumber2',
          `+55${pdvRequest.custPhone.replace(/\D/g, '')}`,
        )
      }, 100)
    }

    setFormValue({
      customerAddressCountry: 'Brasil',
      customerAddressState: pdvRequest.servcAddrStCd,
      customerAddressCity: pdvRequest.servcAddrCityNm,
      customerAddressStreetName: pdvRequest.servcAddrStrNm,
      customerAddressStreetNumber: pdvRequest.servcAddrNr,
      customerAddressPostalCode: pdvRequest.servcAddrPstlCd,
      customerDistrict: pdvRequest.servcAddrDstrctNm,
      customerAddressComplement: pdvRequest.servcAddrCmplmtryTxt,
      customerAddressReference: pdvRequest.servcAddrRefPointDesc,
      note: pdvRequest.serviceDescription,
    })

    if (pdvRequest.plntCd) {
      new Api(user.dsToken, dispatch).get(API_URL.FIND_STORES).then((response) => {
        if (Array.isArray(response.data)) {
          const plant = response.data.find((plnt) => plnt.id === pdvRequest.plntCd)

          if (plant) {
            setFormValue({
              issuerPlant: pdvRequest.plntCd,
              issuerPlantName: plant.name,
            })
          }
        }
      })
    }
  }

  maskValueforSubmit = (value) => {
    const { t } = this.props

    if (value) {
      const formattedValue = value.replace(
        new RegExp(`\\${t('delimiter.currency')}|\\${t('separator.currency')}`, 'g'),
        '',
      )
      const decimalIndex = formattedValue.length - 2

      return `${formattedValue.substring(0, decimalIndex)}.${formattedValue.substring(
        decimalIndex,
      )}`
    }
    return '0.00'
  }

  handleChange(event) {
    const { name, value } = event.target
    const { setFormValue, t } = this.props

    const payload = {
      [name]: value,
    }

    if (name === 'serviceOrderNumber') payload.note = t('budget') + value

    setFormValue(payload)
  }

  handleFormChange = (field, value) => {
    const { setFormValue } = this.props
    setFormValue({ [field]: value })
  }

  handlePhoneNumberChange = (phone, value) => {
    const { fields, setFormValue, t } = this.props
    const { customerPhoneNumber, customerPhoneNumber2, customerPhoneNumber3 } = this.state

    const phoneValidationTypes = {
      customerPhoneNumber(value) {
        return (
          validatePhoneNumber(value) &&
          ![customerPhoneNumber2, customerPhoneNumber3].includes(value)
        )
      },
      customerPhoneNumber2(value) {
        return (
          validatePhoneNumber(customerPhoneNumber) &&
          (value ? validatePhoneNumber(value) : true) &&
          !(value !== '' && [customerPhoneNumber, customerPhoneNumber3].includes(value))
        )
      },
      customerPhoneNumber3(value) {
        return (
          validatePhoneNumber(customerPhoneNumber) &&
          (value ? validatePhoneNumber(value) : true) &&
          !(value !== '' && [customerPhoneNumber, customerPhoneNumber2].includes(value))
        )
      },
    }

    const validationFunction = phoneValidationTypes[phone]
    const hasError = !validationFunction(value)

    if (hasError) {
      fields[phone].error = t('cust.invalid.phone')
    } else {
      fields[phone].error = null
    }

    this.setState({ [phone]: value })
    setFormValue({ [phone]: value })
  }

  handleChangeIssuerPlant(value) {
    const { setFormValue, values } = this.props

    // eslint-disable-next-line no-param-reassign
    value = value || { value: null }

    setFormValue({
      issuerPlant: value.value,
      issuerPlantName: value.label,
    })

    values.issuerPlant = value.value
    values.issuerPlantName = value.label
  }

  handleSubmit = () => {
    const { onSubmit, values } = this.props

    if (this.validate()) {
      const arrayToSave = [this.buildOrder(values.servicesList)]
      onSubmit(arrayToSave).then(() => this.clearFields())
    }
  }

  validate() {
    const { t, setFormValue, values } = this.props
    const servicesList = values.servicesList

    let valid = true

    const toValidate = ['value', 'code', 'quantity']

    let count = 1

    const errors = []

    servicesList.forEach((service) => {
      // eslint-disable-next-line no-param-reassign
      service.errors = []

      toValidate.forEach((field) => {
        if (!service[field]) {
          service.errors.push(field)
          valid = false
        }
      })

      if (service.value === '0.00') {
        service.errors.push('value')
      }

      if (service.errors.length > 0) {
        renderToastError(`${t('servcOrd.invalid')} ${count}`)
      }

      count++
    })

    if (
      values.scheduleDate === null ||
      values.scheduleDate === undefined ||
      values.scheduleDate === ''
    ) {
      errors.push('scheduleDate')
      valid = false
    }
    if (
      values.scheduleShift === null ||
      values.scheduleShift === undefined ||
      values.scheduleShift === ''
    ) {
      errors.push('scheduleShift')
      valid = false
    }

    setFormValue({ servicesList, errors })

    return valid
  }

  buildOrder(servicesList) {
    const {
      values,
      servcRqstCd,
      pdvRequest,
      auth: {
        user: { buCd },
      },
    } = this.props

    const {
      customerPhoneNumber,
      customerPhoneNumber2,
      customerPhoneNumber3,
      daysDisabled,
      serviceAccessoryProducts,
    } = this.state

    const isPdv = servcRqstCd && pdvRequest

    const toSave = {
      header: {
        companyCode: BusinessUnitType.getBUByBuCd(buCd).companyCode,
        issuerPlant: values.issuerPlant,
        issuerPlantName: values.issuerPlantName,
        sourceOrderNumber: isPdv ? servcRqstCd : values.sourceOrderNumber,
        channel: isPdv ? 'PDV' : 'Loja',
        status: '00',
        value: this.maskValueforSubmit(this.getTotalValue()),
        note: values.note,
        servcOrdSeqBudget: values.serviceOrderNumber,
      },
      customer: {
        customerIDBUC: '0000000000',
        customerIDSource: removeMask(values.customerFiscalId),
        customerType: 'F',
        name: values.customerName,
        documentType: 1,
        fiscalId: values.customerFiscalId,
        email: values.customerEmail,
        phones: [
          {
            defaultPhone: true,
            phoneNumber: customerPhoneNumber,
            phoneType: 1,
          },
          {
            defaultPhone: false,
            phoneNumber: customerPhoneNumber2,
            phoneType: 3,
          },
          {
            defaultPhone: false,
            phoneNumber: customerPhoneNumber3,
            phoneType: 2,
          },
        ],
        serviceAddress: {
          streetName: values.customerAddressStreetName,
          streetNumber: values.customerAddressStreetNumber,
          postalCode: values.customerAddressPostalCode,
          city: values.customerAddressCity,
          state: values.customerAddressState,
          country: values.customerAddressCountry,
          supplement: values.customerAddressComplement,
          note: values.customerAddressReference,
          neighborhood: values.customerDistrict,
        },
      },
      schedule: {
        calendarEventID: '',
        calendarEventReservationID: '',
        date: moment(values.scheduleDate, 'L').format(),
        shift: values.scheduleShift,
        disableDays: daysDisabled,
      },
    }

    if (!_.isEmpty(servicesList)) {
      toSave.services = servicesList.map((s) => ({ ...s, errors: undefined }))
    }

    if (this.hasAuxProducts()) {
      toSave.serviceAccessoryProducts = serviceAccessoryProducts
    }

    return toSave
  }

  clearFields() {
    const { setFormValue, fields } = this.props

    setFormValue({ ...FIELDS })

    fields.customerPhoneNumber.error = null
    fields.customerPhoneNumber2.error = null
    fields.customerPhoneNumber3.error = null

    this.setState({
      fileName: null,
      customerPhoneNumber: '',
      customerPhoneNumber2: '',
      customerPhoneNumber3: '',
    })
  }

  getPyxisInformations = () => {
    const {
      values: { issuerPlant, sourceOrderNumber },
      auth: { user },
      getPyxisInformations,
    } = this.props

    const values = {
      issuerPlant,
      sourceOrderNumber,
    }
    getPyxisInformations(user.dsToken, values)
    setTimeout(() => {
      const { fields } = this.props
      if (fields.customerPhoneNumber.input.value) {
        this.handlePhoneNumberChange('customerPhoneNumber', fields.customerPhoneNumber.input.value)
      } else if (fields.customerPhoneNumber2.input.value) {
        this.handlePhoneNumberChange(
          'customerPhoneNumber2',
          fields.customerPhoneNumber2.input.value,
        )
      } else if (fields.customerPhoneNumber3.input.value) {
        this.handlePhoneNumberChange(
          'customerPhoneNumber3',
          fields.customerPhoneNumber3.input.value,
        )
      }
    }, 500)
  }

  hasServices = () => {
    const { services } = this.state
    return services && services.length > 0
  }

  hasAuxProducts = () => {
    const { serviceAccessoryProducts } = this.state
    return serviceAccessoryProducts && serviceAccessoryProducts.length > 0
  }

  handleCEPChange = (postalCode) => {
    if (postalCode && String(postalCode).length === 9) {
      this.searchPostalCode(postalCode)
    } else {
      this.setState({ apiAddress: {} })
    }
  }

  searchPostalCode = (postalCode) => {
    const { dispatch, t, setFormValue } = this.props

    return new Api(null, dispatch)
      .get(API_URL.PUBLIC_SERVICE_PROVIDER_POSTAL_CODE, {
        postalCode: postalCode.replace('-', ''),
      })
      .then(
        (response) => {
          if (response.data && response.data.dne.length > 0 && response.data.dne[0].IBGEcode) {
            const apiAddress = response.data.dne[0]
            const addrObj = {}

            Object.entries(apiAddress).forEach(([key, value]) => (apiAddress[key] = value.trim()))

            if (apiAddress.streetName) addrObj.servcAddrStrNm = apiAddress.streetName
            else addrObj.servcAddrStrNm = ''
            if (apiAddress.state) addrObj.servcAddrStCd = apiAddress.state
            else addrObj.servcAddrStCd = ''
            if (apiAddress.neighborhood) addrObj.servcAddrDstrctNm = apiAddress.neighborhood
            else addrObj.servcAddrDstrctNm = ''
            if (apiAddress.city) addrObj.servcAddrCityNm = apiAddress.city
            else addrObj.servcAddrCityNm = ''

            this.setState({ apiAddress: addrObj })

            setFormValue({
              customerAddressStreetName: addrObj.servcAddrStrNm,
              customerAddressState: addrObj.servcAddrStCd,
              customerDistrict: addrObj.servcAddrDstrctNm,
              customerAddressCity: addrObj.servcAddrCityNm,
            })
          }
        },
        () => {
          renderToastError(t('customer.service.order.address.edit.invalid.postal.code'))
        },
      )
  }

  renderPyxisButton = () => {
    const { auth, t, values } = this.props
    const { setupParameters } = auth

    if (setupParameters.canSearchFromSalesOrder) {
      return (
        <Row className='margin-top-double margin-bottom'>
          <Col lg={6} md={6} xs={6}>
            <button
              type='button'
              className='button button-primary button-full'
              onClick={() => this.getPyxisInformations()}
              disabled={!values.issuerPlant || !values.sourceOrderNumber}
            >
              {t('pyxis.getInformations')}
            </button>
          </Col>
        </Row>
      )
    }
  }

  openCoupon = (filePath) => {
    const { t, dispatch } = this.props
    const { coupon } = this.state

    if (!coupon) {
      dispatch(showSpinner())
      new Api(null, dispatch)
        .get(API_URL.CUSTOMER_FETCH_IMG, { filePath })
        .then((res) => fetch(`data:image/png;base64,${res.data}`))
        .then((res) => res.blob())
        .then((res) =>
          this.setState({
            coupon: URL.createObjectURL(res),
            lightboxOpen: true,
          }),
        )
        .catch(() => renderToastError(t('application.fatal.error')))
        .finally(() => dispatch(hideSpinner()))
    } else {
      this.setState({ lightboxOpen: true })
    }
  }

  renderLightBox() {
    const { t } = this.props
    const { lightboxOpen, coupon } = this.state

    return (
      <AwsLightbox
        isOpen={lightboxOpen}
        onClose={() => this.setState({ lightboxOpen: false })}
        spinnerColor='green'
        imageCountSeparator={t('option.of')}
        backdropClosesModal
        showCloseButton
        data={coupon}
        showImageCount={false}
      />
    )
  }

  updateServiceValue() {
    const { setFormValue, values } = this.props
    const servicesList = values.servicesList

    if (servicesList.length !== 0) {
      let val = 0

      servicesList.forEach((service) => {
        val += parseFloat(service.value) * parseFloat(service.quantity)
      })

      val = val
        .toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace(',', '')

      setFormValue({ value: val })
    }
  }

  updateServices = (servicesList) => {
    const { setFormValue } = this.props
    setFormValue({ servicesList })
    this.updateServiceValue()
  }

  removeService = (i) => {
    const { values } = this.props
    values.servicesList.splice(i, 1)
    this.updateServices(values.servicesList)
  }

  render() {
    const {
      t,
      fields,
      user,
      values,
      dispatch,
      auth,
      pdvRequest,
      servcRqstCd,
      pyxisInformations,
      register,
      setFormValue,
    } = this.props

    const {
      customerPhoneNumber,
      customerPhoneNumber2,
      customerPhoneNumber3,
      apiAddress,
      daysDisabled,
      isDisabled,
    } = this.state

    const MAX_LENGHT = 255

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <MozaicHeading size='m' className='no-margin-top margin-bottom'>
              {t('serviceSpecialists.register')}
            </MozaicHeading>
          </Col>
        </Row>
        <div className='padding-left-small'>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <MozaicText>{t('servcOrd.pyxisInformations')}</MozaicText>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.issuerPlant} error={t(fields.issuerPlant.error)}>
                <BranchSelect
                  {...register('issuerPlant')}
                  onChange={this.handleChangeIssuerPlant}
                  t={t}
                  user={user}
                  dispatch={dispatch}
                  disabled={this.hasServices()}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field {...fields.sourceOrderNumber} error={t(fields.sourceOrderNumber.error)}>
                <Input
                  {...register('sourceOrderNumber')}
                  label={t('servcOrd.sourceOrderNumber')}
                  onChange={this.handleChange}
                  disabled={this.hasServices()}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.serviceOrderNumber} error={t(fields.serviceOrderNumber.error)}>
                <Input
                  {...register('serviceOrderNumber')}
                  label={t('payments.filter.order.placeholder')}
                  onChange={this.handleChange}
                  disabled={this.hasServices()}
                />
              </Field>
            </Col>
          </Row>
          {this.renderPyxisButton()}
          <Row>
            <Col lg={12} md={12} xs={12} className='margin-top'>
              <MozaicText>{t('servcOrd.customer')}</MozaicText>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerFiscalId} error={t(fields.customerFiscalId.error)}>
                <Input
                  {...register('customerFiscalId')}
                  label={t('servcOrd.customer.fiscalId')}
                  onChange={this.handleChange}
                  mask={getCPFMask(t)}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerName} error={t(fields.customerName.error)}>
                <Input
                  {...register('customerName')}
                  label={t('servcOrd.customer.name')}
                  onChange={this.handleChange}
                  maxLength='70'
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerEmail} error={t(fields.customerEmail.error)} validate>
                <Input
                  {...register('customerEmail')}
                  label={t('servcOrd.customer.email')}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerPhoneNumber}
                error={t(fields.customerPhoneNumber.error)}
                validate
              >
                <TelephoneInput
                  {...register('customerPhoneNumber')}
                  label={t('customer.service.order.details.mobile.phone')}
                  value={customerPhoneNumber}
                  onChange={(value) =>
                    this.handlePhoneNumberChange('customerPhoneNumber', value || '')
                  }
                  required
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerPhoneNumber2}
                error={t(fields.customerPhoneNumber2.error)}
                validate
              >
                <TelephoneInput
                  {...register('customerPhoneNumber2')}
                  value={customerPhoneNumber2}
                  label={t('customer.service.order.details.home.phone')}
                  onChange={(value) =>
                    this.handlePhoneNumberChange('customerPhoneNumber2', value || '')
                  }
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerPhoneNumber3}
                error={t(fields.customerPhoneNumber3.error)}
                validate
              >
                <TelephoneInput
                  {...register('customerPhoneNumber3')}
                  value={customerPhoneNumber3}
                  label={t('customer.service.order.details.business.phone')}
                  onChange={(value) =>
                    this.handlePhoneNumberChange('customerPhoneNumber3', value || '')
                  }
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12} className='margin-top-double'>
              <MozaicText>{t('servcOrd.customer.serviceAddress')}</MozaicText>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressPostalCode}
                error={t(fields.customerAddressPostalCode.error)}
              >
                <Input
                  {...register('customerAddressPostalCode')}
                  label={t('servcOrd.customer.serviceAddress.postalCode')}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.handleCEPChange(e.target.value)
                  }}
                  mask={getCEPMask(t)}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressCountry}
                error={t(fields.customerAddressCountry.error)}
              >
                <Input
                  {...register('customerAddressCountry')}
                  label={t('servcOrd.customer.serviceAddress.country')}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerAddressState} error={t(fields.customerAddressState.error)}>
                <Input
                  {...register('customerAddressState')}
                  label={t('servcOrd.customer.serviceAddress.state')}
                  disabled={!!apiAddress.servcAddrStCd}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerAddressCity} error={t(fields.customerAddressCity.error)}>
                <Input
                  {...register('customerAddressCity')}
                  label={t('servcOrd.customer.serviceAddress.city')}
                  disabled={!!apiAddress.servcAddrCityNm}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressStreetName}
                error={t(fields.customerAddressStreetName.error)}
              >
                <Input
                  {...register('customerAddressStreetName')}
                  label={t('servcOrd.customer.serviceAddress.streetName')}
                  disabled={!!apiAddress.servcAddrStrNm}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressStreetNumber}
                error={t(fields.customerAddressStreetNumber.error)}
              >
                <Input
                  {...register('customerAddressStreetNumber')}
                  label={t('servcOrd.customer.serviceAddress.streetNumber')}
                  onChange={this.handleChange}
                  maxLength='10'
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field {...fields.customerDistrict} error={t(fields.customerDistrict.error)}>
                <Input
                  {...register('customerDistrict')}
                  label={t('customer.service.order.edit.address.neighbhd')}
                  disabled={!!apiAddress.servcAddrDstrctNm}
                  onChange={this.handleChange}
                  maxLength={80}
                />
              </Field>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressComplement}
                error={t(fields.customerAddressComplement.error)}
              >
                <Input
                  {...register('customerAddressComplement')}
                  label={t('servcOrd.customer.serviceAddress.customerAddressComplement')}
                  onChange={this.handleChange}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Field
                {...fields.customerAddressReference}
                error={t(fields.customerAddressReference.error)}
              >
                <Input
                  {...register('customerAddressReference')}
                  label={t('servcOrd.customer.serviceAddress.customerAddressReference')}
                  onChange={this.handleChange}
                  maxLength={MAX_LENGHT}
                />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xl={7} xs={12}>
              <div className='order-services-list'>
                <MozaicHeading size='ss' className='margin-top-double margin-bottom-double'>
                  {t('servcOrd.services')}
                </MozaicHeading>
                <ServiceListItemV2
                  t={t}
                  auth={auth}
                  user={user}
                  dispatch={dispatch}
                  servicesList={values.servicesList}
                  issuerPlant={values.issuerPlant}
                  pyxisInformations={pyxisInformations?.data}
                  pdvRequest={pdvRequest}
                  updateServices={this.updateServices}
                  removeService={this.removeService}
                  daysDisabled={daysDisabled}
                  handleChange={this.handleFormChange}
                  setFormValue={setFormValue}
                  values={values}
                  fields={fields}
                />
              </div>
            </Col>
            <Col xs={12} />
            {pdvRequest && servcRqstCd && (
              <Col xs={12} md={3}>
                <button
                  type='button'
                  className='button-hollow button-hollow-primary button-full'
                  onClick={() => this.openCoupon(pdvRequest.filePath)}
                >
                  {t('servcOrd.see.coupon')}
                </button>
              </Col>
            )}
            {this.renderLightBox()}
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12} className='margin-top-double'>
              <MozaicText>{t('serviceOrders.observations')}</MozaicText>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <MozaicTextArea
                {...register('note')}
                id='customerObservations'
                onChange={this.handleChange}
                rows={4}
              />
            </Col>
          </Row>
          <Row className='margin-top-double margin-bottom'>
            <Col lg={6} md={6} xs={6}>
              <button
                type='submit'
                className='button button-primary button-full'
                disabled={isDisabled}
                onClick={this.handleSubmit}
              >
                {t('save_button_label')}
              </button>
            </Col>

            <Col lg={6} md={6} xs={6}>
              <button
                type='button'
                className='button button-danger button-full align-center'
                onClick={this.clearFields}
              >
                {t('serviceOrders.clearFields')}
              </button>
            </Col>
          </Row>
        </div>
      </form>
    )
  }
}

CreateServcOrdEditFormV2.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFormValue: PropTypes.func.isRequired,
  getPyxisInformations: PropTypes.func.isRequired,
  pyxisInformations: PropTypes.object,
  pyxisError: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  apiAddress: PropTypes.object,
  servcRqstCd: PropTypes.string,
  pdvRequest: PropTypes.object,
  register: PropTypes.func.isRequired,
}

export default WithForm(FIELDS)(CreateServcOrdEditFormV2)

export { CreateServcOrdEditFormV2 }
