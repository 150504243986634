import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { fetchStores } from '../../services/stores/fetchStores'
import MozaicReactSelect, {
  MozaicReactSelectOption,
  MozaicReactSelectProps,
} from '../MozaicReactSelect'

// @ts-ignore due to incompatibility with prop value
interface StoresSelectV2Props extends MozaicReactSelectProps {
  value: string[] | string
}

export function StoresSelectV2({ ...props }: Readonly<StoresSelectV2Props>) {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [options, setOptions] = useState<MozaicReactSelectOption[]>([])

  const getOptions = async () => {
    try {
      setIsLoading(true)
      setIsError(false)
      const response = await fetchStores()

      if (!response.data) return

      const storesOptions = response.data.map((store) => ({
        label: store.name,
        value: store.id,
      }))

      setOptions(storesOptions)
    } catch (error) {
      console.error('Error fetching stores options', error)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOptions()
  }, [])

  const getPlaceholder = () => {
    if (isLoading) return i18next.t('option.loading')
    if (isError) return i18next.t('option.fetch.error')
    return props.placeholder || i18next.t('option.all')
  }

  const getLoadingMessage = () => i18next.t('Carregando lojas...')

  return (
    <MozaicReactSelect
      {...props}
      options={options}
      value={
        props.isMulti
          ? options.filter((opt) =>
              (Array.isArray(props.value) ? props.value : []).includes(opt.value as any),
            )
          : options.find((opt) => opt.value === props.value)
      }
      isLoading={isLoading}
      loadingMessage={getLoadingMessage}
      isDisabled={isError || props.isDisabled}
      placeholder={getPlaceholder()}
    />
  )
}
