import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useAdvancedSearchContext } from '../../../../../../contexts/advancedSearch'
import { linkServiceOrdersFilters } from '../../../../../../redux/actions/serviceOrder'
import CPIndicator from '../../../../entities/Indicator'
import StatusIndicatorGhost from '../Ghosts/StatusIndicatorGhost'

function StatusIndicator({ filters, alert }) {
  const { handleSubmitDefault } = useAdvancedSearchContext()
  const Indicator = useMemo(() => new CPIndicator(alert, filters), [alert, filters])

  const loading = Indicator.loading

  if (loading) return <StatusIndicatorGhost />

  return (
    <div className='status-indicator-content'>
      <section className='info' onClick={() => handleSubmitDefault(Indicator.parsedFilter)}>
        <section className='quantity'>{Indicator.indicator}</section>
        <section className='labels'>
          <span className='main-label'>
            <span className='title'>{Indicator.title}</span>
          </span>
          <span className='subtitle'>{Indicator.subtitle}</span>
        </section>
      </section>
    </div>
  )
}

StatusIndicator.propTypes = {
  filters: PropTypes.object,
  alert: PropTypes.object,
}

const mapStateToProps = ({ auth, newCockpitReducer }) => ({
  auth,
  filters: newCockpitReducer.filters,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ linkServiceOrdersFilters }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StatusIndicator)
