import { ChevronDownSolid } from '@leroy-merlin-br/backyard-icons'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { fetchClustersOptions } from '../../services/Clusters'

export function clearButton() {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10.5' r='10' fill='#EEEEF0' />
      <path
        d='M11.0213 10.5L14 7.52128L12.9787 6.5L10 9.47872L7.10638 6.5L6 7.60639L8.97872 10.5L6 13.4787L7.02128 14.5L10 11.5213L12.9787 14.5L14 13.4787L11.0213 10.5Z'
        fill='#3C3738'
      />
    </svg>
  )
}

function MultiSelectClusters({
  options,
  label,
  value,
  disabled,
  hasSelectAll = true,
  disableSearch = true,
  onChange,
}) {
  const [clusters, setClusters] = useState([])

  async function handleFetchClusters() {
    const data = fetchClustersOptions()

    setClusters(data)
  }

  useEffect(() => {
    handleFetchClusters()
  }, [])

  return (
    <section id='multi-select-clusters'>
      <span className='css-fat8q2-label'>{label}</span>
      <MultiSelect
        hasSelectAll={hasSelectAll}
        disableSearch={disableSearch}
        label={label}
        onChange={onChange}
        value={value || []}
        options={options || clusters}
        ArrowRenderer={() => (
          <ChevronDownSolid className='css-1opf1jk-input__suffix' color='#BAB6BC' />
        )}
        ClearSelectedIcon={clearButton()}
        overrideStrings={{
          selectSomeItems: i18next.t('multi.select.clusters.select.some.items'),
          search: i18next.t('search.text'),
          allItemsAreSelected: i18next.t('multi.select.clusters.all.items.selected'),
          selectAll: i18next.t('multi.select.clusters.select.all'),
          selectAllFiltered: i18next.t('multi.select.clusters.select.all.filtered'),
          clearSearch: i18next.t('multi.select.clear.search'),
          clearSelected: i18next.t('multi.select.clear.selected'),
          noOptions: i18next.t('multi.select.clusters.no.options'),
        }}
        disabled={disabled}
      />
    </section>
  )
}

MultiSelectClusters.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  disableSearch: PropTypes.bool,
  onChange: PropTypes.func,
}

export default MultiSelectClusters
